import React, { memo, FC } from "react";
import { styled } from "@mui/material/styles";

const StyledSelect = styled("select")({
  width: 70,
  height: 20,
  marginLeft: 5,
});

interface AdminFactorProps {
  adminFactor: number | string;
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const AdminFactorSelect: FC<AdminFactorProps> = ({
  adminFactor,
  handleChange,
}) => {
  return (
    <StyledSelect
      onChange={handleChange}
      name="adminFactor"
      value={adminFactor}
    >
      <option value={"None"}>None</option>
      <option value={0.53}>0.53</option>
      <option value={0.54}>0.54</option>
      <option value={0.55}>0.55</option>
      <option value={0.56}>0.56</option>
      <option value={0.57}>0.57</option>
      <option value={0.58}>0.58</option>
      <option value={0.59}>0.59</option>
      <option value={0.6}>0.60</option>
      <option value={1}>1 (List Price)</option>
    </StyledSelect>
  );
};

export default memo(AdminFactorSelect);
