import React, { useMemo, useEffect, FC } from "react";
import Typography from "@mui/material/Typography";
import Selection from "./Selection";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import taggedConfigImages from "../taggedImages/taggedConfigImages";
import taggedWindowImages from "../taggedImages/taggedWindowImages";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import fractionToInt from "../util/fractionToInt";
import ReusableAccordian from "./ReusableAccordion";
import { useStore } from "../store/store";
import { useAccordion } from "../store/accordion";
import {
  initialConfigOptions,
  initialWindowOptions,
} from "../initialValues/index";
import { RowLayout, ColLayout } from "../util/accordianStyles";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)({
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledDivRow = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  alignItems: " center",
  width: "100%",
});

const findBifoldImage = (option: string, doorType: string) => {
  const images =
    doorType === "door"
      ? taggedConfigImages
      : doorType === "window"
        ? taggedWindowImages
        : [];

  var match;

  if (images.length) {
    match = images.find((img) => img.config === option);
  }

  return match;
};

const noAccess = [
  "2L OR 2R",
  "2L2R",
  "4L OR 4R",
  "6L OR 6R",
  "4L2R OR 2L4R",
  "8L OR 8R",
  "6L2R OR 2L6R",
  "4L4R",
];
const specifyPrimary = [
  "3L1R",
  "1L3R",
  "5L1R",
  "1L5R",
  "3L3R",
  "7L1R",
  "1L7R",
  "5L3R",
  "3L5R",
];

interface ConfigurationProps {
  bifoldType: string;
  editQuote: boolean;
  hideHandlesWithEvenConfigs: (access: boolean) => void;
}

interface UpdateObj {
  configuration?: string;
  exactConfig?: string;
  exactConfigImg?: string;
}

interface SelectedConfigUpdateObj extends UpdateObj {
  jamb: string;
  addScreen: string;
  screenPocket: string;
  screenColor: string;
  stilesAndRails: string;
  stileCategory: string;
  lockRail: string;
  primaryDoor?: string;
}

const ConfigurationAccordian: FC<ConfigurationProps> = ({
  bifoldType,
  editQuote,
  hideHandlesWithEvenConfigs,
}) => {
  const configOptions = useMemo(() => {
    return bifoldType === "door"
      ? initialConfigOptions
      : bifoldType === "window"
        ? initialWindowOptions
        : [];
  }, [bifoldType]);

  const addToQuote = useStore((state) => state.addToQuote);

  const unitSize = useStore((state) => state.quote.unitSize);
  const unitSizeFraction = useStore((state) => state.quote.unitSizeFraction);

  let netWidth = fractionToInt(unitSize, unitSizeFraction);

  const configObj = configOptions.find(
    (item) => item.min <= netWidth && item.max >= netWidth,
  );

  const configuration = useStore((state) => state.quote.configuration);
  const exactConfig = useStore((state) => state.quote.exactConfig);
  const primaryDoor = useStore((state) => state.quote.primaryDoor);

  const handleErrMsgChange = useStore((state) => state.handleError);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[4].expanded);

  const configImage =
    configuration && findBifoldImage(configuration, bifoldType);

  const singleImage = !/\s/.test(configuration || "");

  useEffect(() => {
    let update: UpdateObj = {};

    if (
      configObj?.options.length &&
      configuration &&
      !configObj.options.includes(configuration)
    ) {
      update["configuration"] = "";
      update["exactConfig"] = "";
    }

    if (exactConfig && exactConfig.length === 2 && configImage) {
      if (exactConfig.includes("L")) {
        update["exactConfigImg"] = configImage.image1;
      } else {
        update["exactConfigImg"] = configImage.image2;
      }

      return addToQuote(update);
    } else if (exactConfig && exactConfig.length === 4 && configImage) {
      const l = exactConfig.slice(0, 2);
      const r = exactConfig.slice(2);

      const numL = Number(l[0]);
      const numR = Number(r[0]);

      if (numL === numR || numL > numR) {
        update["exactConfigImg"] = configImage.image1;
      } else {
        update["exactConfigImg"] = configImage.image2;
      }

      return addToQuote(update);
    }
  }, [netWidth, bifoldType]);

  const handlePrimaryErr = (config: string) => {
    if (specifyPrimary.includes(config) && !primaryDoor) {
      return handleErrMsgChange("primaryDoorErr", "Select a Primary Door");
    }

    handleErrMsgChange("primaryDoorErr", "");
  };

  const selectedConfig = (config: string) => {
    let update: SelectedConfigUpdateObj = {
      jamb: "",
      addScreen: "",
      screenPocket: "",
      screenColor: "",
      stilesAndRails: "",
      stileCategory: "",
      lockRail: "",
    };

    handleErrMsgChange("jambErr", "");

    if (!config) {
      update["primaryDoor"] = "";
      update["exactConfig"] = "";
      update["exactConfigImg"] = "";
      update["configuration"] = "";
    } else if (!/\s/.test(config)) {
      //if config has no "OR"
      const image = findBifoldImage(config, bifoldType)?.image1 || null;
      handlePrimaryErr(config);

      if (image) update["exactConfigImg"] = image;
      update["primaryDoor"] = "";
      update["configuration"] = config;
      update["exactConfig"] = config;
    } else {
      update["configuration"] = config;
      update["exactConfig"] = "";
    }

    addToQuote(update);
    hideHandlesWithEvenConfigs(noAccess.includes(config));
  };

  const handleConfigClick = (side: string) => {
    const chosen = configuration ? configuration.split("OR") : null;

    if (!chosen) {
      addToQuote({ exactConfig: "" });
    } else if (side === "right") {
      let exactConfig = chosen[0].trim();

      handlePrimaryErr(exactConfig);

      addToQuote({
        exactConfig: exactConfig,
        exactConfigImg:
          configImage && "image1" in configImage ? configImage.image1 : "",
      });
    } else if (side === "left") {
      let exactConfig = chosen[1].trim();

      handlePrimaryErr(exactConfig);

      addToQuote({
        exactConfig: exactConfig,
        exactConfigImg:
          configImage && "image2" in configImage ? configImage.image2 : "",
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    addToQuote({ primaryDoor: value });
    handleErrMsgChange("primaryDoorErr", "");
  };

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="configuration"
      heading="Configuration"
      secondaryHeading="Select a configuration"
      required={false}
      required5={true}
    >
      {!configObj || configObj.options.length === 0 ? (
        <Typography>
          Note: Enter a Frame Width to view Configuration Options
        </Typography>
      ) : null}
      {configObj && configObj.options.length > 0 ? (
        <>
          <ColLayout>
            <Selection
              chosenConfig={configuration}
              selectedConfig={selectedConfig}
              typeRequired={"config"}
              displayedConfig={
                configObj?.options.length ? configObj.options : []
              }
              netWidth={netWidth}
            />
            {(noAccess.includes(configuration || "") ||
              bifoldType === "window") && (
              <RowLayout>
                <Typography style={{ color: "red" }} variant="caption">
                  * No access from exterior
                  {bifoldType !== "window" && "with this configuration"}
                </Typography>
              </RowLayout>
            )}

            {specifyPrimary.includes(exactConfig) && (
              <StyledDivRow>
                <Typography style={{ fontWeight: "bold" }}>
                  Primary Door:
                  <span style={{ color: "red", fontSize: 22 }}>*</span>
                </Typography>
                <FormControl component="fieldset">
                  <FormControlLabel
                    value="end"
                    control={
                      <Radio
                        color="primary"
                        checked={primaryDoor === "Left"}
                        onChange={handleChange}
                        value="Left"
                      />
                    }
                    label="Left"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="end"
                    control={
                      <Radio
                        color="primary"
                        checked={primaryDoor === "Right"}
                        onChange={handleChange}
                        value="Right"
                      />
                    }
                    label="Right"
                    labelPlacement="end"
                  />
                </FormControl>
              </StyledDivRow>
            )}
          </ColLayout>
          {configImage &&
            "image1" in configImage &&
            configImage?.image1 &&
            configuration && (
              <ColLayout style={{ paddingLeft: 40 }}>
                <Typography variant="caption">
                  * As viewed from exterior
                </Typography>
                {!exactConfig ? (
                  <Typography variant="body1">
                    Choose a specific configuration
                  </Typography>
                ) : (
                  <Typography style={{ color: "#04bff3" }} variant="h6">
                    You selected {exactConfig}
                  </Typography>
                )}
                <RowLayout>
                  <Grow in={true}>
                    <StyledPaper
                      style={{
                        marginRight: 20,
                        border:
                          configuration.split("OR")[0].trim() === exactConfig
                            ? "3px solid #04bff3"
                            : "",
                      }}
                      elevation={3}
                    >
                      {!singleImage ? (
                        <img
                          onClick={() => handleConfigClick("right")}
                          src={configImage.image1}
                          alt="config door"
                        />
                      ) : (
                        <img src={configImage.image1} alt="config door" />
                      )}
                    </StyledPaper>
                  </Grow>

                  {configImage?.image2 && (
                    <Grow in={true}>
                      <StyledPaper
                        style={{
                          border:
                            configuration.split("OR")[1].trim() === exactConfig
                              ? "3px solid #04bff3"
                              : "",
                        }}
                        elevation={3}
                      >
                        <img
                          onClick={() => handleConfigClick("left")}
                          src={configImage.image2}
                          alt="config door 2"
                        />
                      </StyledPaper>
                    </Grow>
                  )}
                </RowLayout>
              </ColLayout>
            )}
        </>
      ) : null}
    </ReusableAccordian>
  );
};

export default ConfigurationAccordian;
