const stopsImages = [
  { id: 1, label: "Ovolo" },
  { id: 2, label: "Square" },
  { id: 3, label: "Bevel" },
];

const woodInteriorStopsImages = [
  { id: 1, label: "Ovolo" },
  { id: 2, label: "Square" },
];

const stops = [
  {
    id: 1,
    type: "stickingStops",
    label: "Ovolo",
  },
  {
    id: 2,
    type: "stickingStops",
    label: "Square",
  },
  {
    id: 3,
    type: "stickingStops",
    label: "Bevel",
  },
];

const woodIntStops = [
  {
    id: 1,
    type: "stickingStopsInterior",
    label: "Ovolo",
  },
  {
    id: 2,
    type: "stickingStopsInterior",
    label: "Square",
  },
];

const woodExtStops = [
  {
    id: 1,
    type: "stickingStopsExterior",
    label: "Ovolo",
  },
  {
    id: 2,
    type: "stickingStopsExterior",
    label: "Square",
  },
  {
    id: 3,
    type: "stickingStopsExterior",
    label: "Bevel",
  },
];

export {
  woodExtStops,
  woodIntStops,
  stops,
  woodInteriorStopsImages,
  stopsImages,
};
