const CLOUDINARY_URL =
  "https://res.cloudinary.com/ag-millworks/image/upload/Bifold_Configs";

const taggedConfigImages = [
  {
    config: "2L OR 2R",
    image1: `${CLOUDINARY_URL}/2_2L.jpg`,
    image2: `${CLOUDINARY_URL}/2_2R.jpg`,
  },
  {
    config: "2L1R OR 1L2R",
    image1: `${CLOUDINARY_URL}/3_2L1R.jpg`,
    image2: `${CLOUDINARY_URL}/3_1L2R.jpg`,
  },
  {
    config: "3L OR 3R",
    image1: `${CLOUDINARY_URL}/3_3L.jpg`,
    image2: `${CLOUDINARY_URL}/3_3R.jpg`,
  },
  {
    config: "3L1R OR 1L3R",
    image1: `${CLOUDINARY_URL}/4_3L1R.jpg`,
    image2: `${CLOUDINARY_URL}/4_1L3R.jpg`,
  },
  {
    config: "2L2R",
    image1: `${CLOUDINARY_URL}/4_2L2R.jpg`,
    image2: "",
  },
  {
    config: "4L OR 4R",
    image1: `${CLOUDINARY_URL}/4_4L.jpg`,
    image2: `${CLOUDINARY_URL}/4_4R.jpg`,
  },
  {
    config: "4L1R OR 1L4R",
    image1: `${CLOUDINARY_URL}/5_4L1R.jpg`,
    image2: `${CLOUDINARY_URL}/5_1L4R.jpg`,
  },
  {
    config: "3L2R OR 2L3R",
    image1: `${CLOUDINARY_URL}/5_3L2R.jpg`,
    image2: `${CLOUDINARY_URL}/5_2L3R.jpg`,
  },
  {
    config: "5L OR 5R",
    image1: `${CLOUDINARY_URL}/5_5L.jpg`,
    image2: `${CLOUDINARY_URL}/5_5R.jpg`,
  },
  {
    config: "5L1R OR 1L5R",
    image1: `${CLOUDINARY_URL}/6_5L1R.jpg`,
    image2: `${CLOUDINARY_URL}/6_1L5R.jpg`,
  },
  {
    config: "4L2R OR 2L4R",
    image1: `${CLOUDINARY_URL}/6_4L2R.jpg`,
    image2: `${CLOUDINARY_URL}/6_2L4R.jpg`,
  },
  {
    config: "3L3R",
    image1: `${CLOUDINARY_URL}/6_3L3R.jpg`,
    image2: "",
  },
  {
    config: "6L OR 6R",
    image1: `${CLOUDINARY_URL}/6_6L.jpg`,
    image2: `${CLOUDINARY_URL}/6_6R.jpg`,
  },
  {
    config: "6L1R OR 1L6R",
    image1: `${CLOUDINARY_URL}/7_6L1R.jpg`,
    image2: `${CLOUDINARY_URL}/7_1L6R.jpg`,
  },
  {
    config: "5L2R OR 2L5R",
    image1: `${CLOUDINARY_URL}/7_5L2R.jpg`,
    image2: `${CLOUDINARY_URL}/7_2L5R.jpg`,
  },
  {
    config: "4L3R OR 3L4R",
    image1: `${CLOUDINARY_URL}/7_4L3R.jpg`,
    image2: `${CLOUDINARY_URL}/7_3L4R.jpg`,
  },
  {
    config: "7L OR 7R",
    image1: `${CLOUDINARY_URL}/7_7L.jpg`,
    image2: `${CLOUDINARY_URL}/7_7R.jpg`,
  },
  {
    config: "8L OR 8R",
    image1: `${CLOUDINARY_URL}/8_8L.jpg`,
    image2: `${CLOUDINARY_URL}/8_8R.jpg`,
  },
  {
    config: "7L1R OR 1L7R",
    image1: `${CLOUDINARY_URL}/8_7L1R.jpg`,
    image2: `${CLOUDINARY_URL}/8_1L7R.jpg`,
  },
  {
    config: "6L2R OR 2L6R",
    image1: `${CLOUDINARY_URL}/8_6L2R.jpg`,
    image2: `${CLOUDINARY_URL}/8_2L6R.jpg`,
  },
  {
    config: "5L3R OR 3L5R",
    image1: `${CLOUDINARY_URL}/8_5L3R.jpg`,
    image2: `${CLOUDINARY_URL}/8_3L5R.jpg`,
  },
  {
    config: "4L4R",
    image1: `${CLOUDINARY_URL}/8_4L4R.jpg`,
    image2: "",
  },
];

export default taggedConfigImages;
