const boltColor = [
  {
    id: 1,
    type: "shootBoltColor",
    label: "Bronze",
  },
  {
    id: 2,
    type: "shootBoltColor",
    label: "Brushed Chrome",
  },
  {
    id: 3,
    type: "shootBoltColor",
    label: "Black",
  },
  {
    id: 4,
    type: "shootBoltColor",
    label: "White",
  },
];

export { boltColor };
