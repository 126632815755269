import api from "./api";

async function getUser(userId: string) {
  return await api.get("/userInfo", {
    params: {
      id: userId,
      page: "Settings",
    },
  });
}

async function postOTSM(
  fixedMargin: string,
  fixedMarkup: string | null,
  otsm: boolean,
  otsmDefault?: boolean,
) {
  return await api.put("/api/applyOTSM", {
    fixedMargin,
    fixedMarkup,
    otsm,
    otsmDefault,
  });
}

async function postFixedMargin(fixedMargin: number) {
  return await api.put("/api/applyFixedMargin", { fixedMargin });
}

async function postDeliveryMarkup(deliveryMarkup: boolean) {
  return await api.put("/api/deliveryMarkup", { deliveryMarkup });
}

export { postDeliveryMarkup, getUser, postOTSM, postFixedMargin };
