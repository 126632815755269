import React, { memo } from "react";
import Typography from "@mui/material/Typography";
import Choices from "./Choices";
import ReusableAccordian from "./ReusableAccordion";
import { useStore } from "../store/store";
import { useAccordion } from "../store/accordion";
import { useRequiredMsgs } from "../store/requiredMsgs";
import { Image } from "cloudinary-react";
import SillFinish from "./SillFinish";
import { SillColor } from "./Sill/SillAccordian";
import { raisedSills } from "./Sill/defaults";
import { RowLayout, ColLayout } from "../util/accordianStyles";
import { styled } from "@mui/material/styles";

const RowLayoutSpacing = styled("div")(({ theme }) => ({
  marginBottom: 10,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  padding: theme.spacing(1, 2),
  alignSelf: "flex-end",
  marginTop: 60,
}));

const ColLayoutSpacing = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1, 2),
}));

function FPSillAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);

  const sillColorChoice = useStore((state) => state.quote.sillColor);
  const sillStyle = useStore((state) => state.quote.sillStyle);
  const exactConfig = useStore((state) => state.quote.exactConfig);
  const material = useStore((state) => state.quote.material);

  const paintPrimeChoice = useStore((state) => state.quote.paintAndPrime);
  const primeChoice = useStore((state) => state.quote.prime);
  const stainChoice = useStore((state) => state.quote.stain);
  const customPaintChoice = useStore((state) => state.quote.customPaintOrStain);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[13].expanded);

  const showSillFinish =
    paintPrimeChoice || primeChoice || stainChoice || customPaintChoice
      ? true
      : false;

  const removeSillRequireds = (type: string, label: string) => {
    switch (type) {
      case "sillStyle":
        label !== "Raised Sill" &&
          removeRequired("Sill Type", "select either Rounded or Squared Sill");
        break;
      case "sillColor":
        removeRequired("Sill Finish");
        break;
      default:
        return;
    }
  };

  const handleClick = (label: string, type: string) => {
    if (!type) return;

    const checked =
      type === "sillColor"
        ? label === sillColorChoice
        : type === "sillStyle"
          ? label === sillStyle
          : "";

    if (checked) {
      addToQuote({ [type]: "" });
    } else {
      addToQuote({ [type]: label });
      if (findRequired("Sill Type") || findRequired("Sill Finish"))
        removeSillRequireds(type, label);
    }
  };

  const fixedPanelSill =
    exactConfig === "Fixed Bi-fold"
      ? { img: "" }
      : exactConfig === "Fixed Inswing"
        ? { img: "/inswing", label: "Inswing Sill" }
        : exactConfig === "Fixed Outswing"
          ? { img: "/outswing", label: "Outswing Sill" }
          : exactConfig === "Fixed Full Bound" && material === "All Wood"
            ? { img: "/wood_fullbound", label: "Full Bound Sill" }
            : exactConfig === "Fixed Full Bound" && material !== "All Wood"
              ? { img: "/fullbound", label: "Full Bound Sill" }
              : { img: "/slider", label: "Raised SILLution" };

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="sill"
      heading="Sill Choice"
      secondaryHeading="Select sill choice"
      colOrRow="col"
    >
      {exactConfig !== "Fixed Bi-fold" ? (
        <RowLayout>
          <RowLayoutSpacing>
            <Image
              cloudName="ag-millworks"
              secure="true"
              publicId={`Sills/fixedPanels${fixedPanelSill?.img}`}
              style={{ paddingRight: 40 }}
            />

            <Choices
              wide={true}
              label={fixedPanelSill?.label || ""}
              variant="filled"
              checkMark={true}
              disabled={true}
            />
          </RowLayoutSpacing>

          <SillColor
            handleClick={handleClick}
            sillColorChoice={sillColorChoice || ""}
          />

          {showSillFinish && <SillFinish />}
        </RowLayout>
      ) : (
        <ColLayout>
          <RowLayout>
            <ColLayoutSpacing>
              <Typography style={{ margin: "auto" }} variant="subtitle1">
                Sill Types
                <span style={{ color: "red", fontSize: 20 }}>*</span>
              </Typography>
              <RowLayout>
                <Choices
                  label="Raised Sill"
                  variant="filled"
                  checkMark
                  disabled
                />
              </RowLayout>

              <RowLayout>
                {raisedSills.map((state) => (
                  <div
                    key={state.id}
                    onClick={() => handleClick(state.label, state.type)}
                  >
                    <Choices
                      label={state.label}
                      variant={
                        state.label === sillStyle ? "filled" : "outlined"
                      }
                      checkMark={state.label === sillStyle}
                      disabled={false}
                    />
                  </div>
                ))}
              </RowLayout>
            </ColLayoutSpacing>

            <SillColor
              handleClick={handleClick}
              sillColorChoice={sillColorChoice || ""}
            />

            {showSillFinish && <SillFinish />}
          </RowLayout>

          {sillStyle && (
            <RowLayout>
              <div>
                <Image
                  cloudName="ag-millworks"
                  secure="true"
                  publicId={
                    sillStyle === "Rounded Dam"
                      ? "v1692741946/Sills/fixedPanels/bifold_round.jpg"
                      : "v1692741946/Sills/fixedPanels/bifold_square.jpg"
                  }
                />
              </div>
            </RowLayout>
          )}
        </ColLayout>
      )}
    </ReusableAccordian>
  );
}

export default memo(FPSillAccordian);
