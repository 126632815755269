import React, { FC } from "react";
import { Page, Text, View, Document } from "@react-pdf/renderer";
import DealerDrawings from "./DealerDrawings/DealerDrawings";
import DrawingsCorner from "./DealerDrawings/Corner/DrawingsCorner";
import DrawingsCorner2 from "./DealerDrawings/Corner/DrawingsCorner2";
import { styles } from "./pdfComponents/Styles";
import Header from "./pdfComponents/Header";
import Page1 from "./pdfComponents/Page1";
import Disclosures from "./pdfComponents/Disclosures";
import UnitDetails from "./pdfComponents/UnitDetails";
import SummaryPage from "./pdfComponents/SummaryPage";
import sortUnits from "../util/sortUnits";
import letters from "../util/letters";
import { CustomerPDFProps } from "./GenerateCustomer";

const CustomerCost: FC<Omit<CustomerPDFProps, "setPdfErr">> = ({
  data,
  hidePricing,
  dealerLogo,
  isOrder,
  salesTax,
  poNumber,
  margin,
  withMarkup,
  addCosts,
  grandTotal,
  suggestedRetail,
  deliveryMarkup,
  dealerTax,
}) => {
  const customClad = data.units?.length
    ? data.units.some(
        (unit) => unit.exteriorCladColor === "Custom Color (upcharge applies)",
      )
    : false;

  const orderedUnits = sortUnits(data.units ? data.units : []);

  return (
    <Document>
      <Page style={{ position: "relative" }}>
        <Header
          data={data}
          customerOrDealer="CUSTOMER"
          fixed={false}
          title={isOrder ? "ORDER" : "QUOTE"}
          dealerLogo={dealerLogo}
          page1={true}
          salesOrder=""
        />

        <Page1 data={data} poNumber={poNumber} isCustomer={true} />

        <View style={styles.customerSignStyle}>
          <Text style={styles.textBold}>
            Signature: ____________________________________
          </Text>
          <Text style={styles.text}>Date: ____________</Text>
        </View>
      </Page>

      {orderedUnits?.length && hidePricing !== "Hide All Measurements"
        ? orderedUnits.map((door, index) => (
            <>
              <Page key={index} style={{ position: "relative" }}>
                <Header
                  data={data}
                  customerOrDealer="CUSTOMER"
                  fixed={false}
                  title={isOrder ? "ORDER" : "QUOTE"}
                  salesOrder=""
                />

                <UnitDetails
                  form={data}
                  door={door}
                  index={index}
                  isCustomer={true}
                />

                <View style={styles.customerSignStyle2}>
                  <Text style={styles.textBold}>
                    Signature: ____________________________________
                  </Text>
                  <Text style={styles.text}>Date: ____________</Text>
                </View>
              </Page>

              {door.doorType.includes("Corner") ? (
                <>
                  <DrawingsCorner
                    isDealer={false}
                    key={letters[index]}
                    unit={letters[index]}
                    form={data}
                    item={door}
                    salesOrder=""
                  />

                  <DrawingsCorner2
                    isDealer={false}
                    key={letters[index]}
                    unit={letters[index]}
                    form={data}
                    item={door}
                    salesOrder=""
                  />
                </>
              ) : (
                <DealerDrawings
                  isDealer={false}
                  key={letters[index]}
                  unit={letters[index]}
                  form={data}
                  item={door}
                />
              )}
            </>
          ))
        : null}

      <Page wrap>
        <Header
          data={data}
          customerOrDealer="CUSTOMER"
          summaryTitle="PRICING"
          fixed={true}
          title={isOrder ? "ORDER SUMMARY" : "QUOTE SUMMARY"}
          salesOrder=""
        />

        <SummaryPage
          hidePricing={hidePricing}
          data={data}
          orderedUnits={orderedUnits || []}
          suggestedRetail={suggestedRetail}
          grandTotal={grandTotal}
          codOrTerms={""}
          isCustomer={true}
          margin={margin || 1}
          withMarkup={withMarkup}
          addCosts={addCosts}
          salesTax={salesTax}
          customClad={customClad}
          deliveryMarkup={deliveryMarkup}
          dealerTax={dealerTax || 0}
          factor={0}
        />
      </Page>

      <Page style={{ position: "relative" }}>
        <Header
          data={data}
          customerOrDealer="CUSTOMER"
          fixed={false}
          title="DISCLOSURES"
          salesOrder=""
        />

        <Disclosures isDealer={false} />

        <View style={styles.customerSignStyle3}>
          <Text style={styles.textBold}>
            Signature: ____________________________________
          </Text>
          <Text style={styles.text}>Date: ____________</Text>
        </View>
      </Page>
    </Document>
  );
};

export default CustomerCost;
