const baseURL =
  "https://res.cloudinary.com/ag-millworks/image/upload/pdfDrawings/sectionView";

export function swingJambLegImg(
  swingDirection: string,
  material: string,
  swingType: string,
  sidelite: string,
  hinge: string,
  screenType: string,
  screen: string,
  screenLayout: string,
  screenPocket: string,
  sideliteSide?: string,
) {
  const direction = swingDirection.toLowerCase();
  const materialLabel = material !== "All Wood" ? "" : "WOOD_";
  const type = swingType === "Pair Swing" ? "PAIR" : `${hinge}_SINGLE`;

  const singleSidelite = hinge === "LH" ? "_LEFT_SL" : "_RIGHT_SL";
  let sl =
    swingType === "Pair Swing" && sidelite && sidelite.includes("Double")
      ? "_2xSL"
      : swingType === "Single Swing" && sidelite && sidelite.includes("Double")
        ? "_2xSL"
        : sidelite && sidelite !== "none" && !sidelite.includes("Double")
          ? singleSidelite
          : "";

  var scr = "";

  const singleWithSingleSidelite =
    swingType === "Single Swing" &&
    sidelite === "Add Single Sidelite" &&
    sideliteSide === "LOCK"
      ? true
      : false;

  if (singleWithSingleSidelite) {
    sl = hinge === "LH" ? "_RIGHT_SL" : "_LEFT_SL";
  }

  if (swingDirection !== "Inswing" && swingType === "Pair Swing" && screen) {
    if (sidelite === "none") {
      scr =
        screenType?.includes("Centor") &&
        (screen.includes("Double") || screen.includes("Combo"))
          ? "_2x_CENTOR_SCR"
          : screenType?.includes("Centor") && screenLayout.includes("Left")
            ? "_LEFT_CENTOR_SCR"
            : screenType?.includes("Centor") && screenLayout.includes("Right")
              ? "_RIGHT_CENTOR_SCR"
              : !screenPocket
                ? "_SCR"
                : screenLayout?.includes("Compress Left") &&
                    !screenPocket.includes("Double")
                  ? "_LEFT_PKT_SCR"
                  : screenLayout?.includes("Compress Right") &&
                      !screenPocket.includes("Double")
                    ? "_RIGHT_PKT_SCR"
                    : screenPocket.includes("Double") && material === "All Wood"
                      ? "_2x_PKT_SCR"
                      : screenPocket.includes("Double") &&
                          material !== "All Wood"
                        ? "_2xPKT_SCR"
                        : "";
    } else {
      scr =
        screenType?.includes("Centor") &&
        (screen.includes("Double") || screen.includes("Combo"))
          ? "_2x_CENTOR_SCR"
          : screenType?.includes("Centor") && screenLayout.includes("Left")
            ? "_LEFT_CENTOR_SCR"
            : screenType?.includes("Centor") && screenLayout.includes("Right")
              ? "_RIGHT_CENTOR_SCR"
              : !screenPocket && material !== "All Wood"
                ? "_2xSCR"
                : !screenPocket && material === "All Wood"
                  ? "_SCR"
                  : screenPocket &&
                      !screenPocket.includes("Double Pocket") &&
                      hinge === "LH"
                    ? "_LEFT_PKT_SCR"
                    : screenPocket &&
                        !screenPocket.includes("Double Pocket") &&
                        hinge === "RH"
                      ? "_RIGHT_PKT_SCR"
                      : screenPocket.includes("Double Pocket") &&
                          material === "All Wood"
                        ? "_2x_PKT_SCR"
                        : screenPocket.includes("Double Pocket") &&
                            material !== "All Wood"
                          ? "_2xPKT_SCR"
                          : "";
    }
  } else if (
    swingDirection !== "Inswing" &&
    swingType === "Single Swing" &&
    screen
  ) {
    scr =
      screenType?.includes("Centor") &&
      (screen.includes("Double") || screen.includes("Combo"))
        ? "_2x_CENTOR_SCR"
        : screenType?.includes("Centor") && screenLayout.includes("Left")
          ? "_LEFT_CENTOR_SCR"
          : screenType?.includes("Centor") && screenLayout.includes("Right")
            ? "_RIGHT_CENTOR_SCR"
            : screen && !screenPocket
              ? "_SCR"
              : screen && screenPocket && hinge === "LH"
                ? "_LEFT_PKT_SCR"
                : screen && screenPocket && hinge === "RH"
                  ? "_RIGHT_PKT_SCR"
                  : "";
  }

  const cloudinaryID = `${baseURL}/swings/jambLeg/${direction}/${materialLabel}${type}${sl}${scr}.jpg`;

  return cloudinaryID;
}

export function bifoldJambLegImg(
  material: string,
  screenType: string,
  screen: string,
  screenLayout: string,
  screenPocket: string,
) {
  const materialLabel = material !== "All Wood" ? "PLAN_" : "WOOD_";

  let scr = "";

  if (!screen) {
    scr = "NOSCREEN";
  } else {
    scr =
      screenType?.includes("Centor") &&
      (screen.includes("Double") || screen.includes("Combo"))
        ? "DBL_CENTOR"
        : screenType?.includes("Centor") && screenLayout?.includes("Left")
          ? "LEFT_CENTOR"
          : screenType?.includes("Centor") && screenLayout?.includes("Right")
            ? "RIGHT_CENTOR"
            : !screenPocket
              ? "SCREEN"
              : screenPocket.includes("Double Pocket")
                ? "DOUBLEPOCKET"
                : !screen.includes("Double") &&
                    screenLayout &&
                    screenLayout.includes("Compress Left")
                  ? "LEFTPOCKET"
                  : !screen.includes("Double") &&
                      screenLayout &&
                      screenLayout.includes("Compress Right")
                    ? "RIGHTPOCKET"
                    : screenPocket.includes("Pocket Left")
                      ? "LEFTPOCKET"
                      : screenPocket.includes("Pocket Right")
                        ? "RIGHTPOCKET"
                        : "";
  }

  return `${baseURL}/bifolds/jambLeg/${materialLabel}${scr}.jpg`;
}

export function bifoldHeadSillImg(
  material: string,
  screenType: string,
  screen: string,
  sillStyle: string,
  stiles: string,
) {
  const materialLabel = material !== "All Wood" ? "SECTION_" : "WOOD_";

  const tradLbl =
    stiles.includes("Bottom") &&
    sillStyle.includes("Dam") &&
    !screenType?.includes("Centor")
      ? "TRAD_"
      : "";

  const scr =
    screen && screenType?.includes("Centor")
      ? "CENTOR"
      : screen
        ? "SCREEN"
        : "NOSCREEN";

  let sill = "";
  if (material === "All Wood") {
    sill =
      sillStyle === "Rounded Dam"
        ? "_ROUNDDAM"
        : sillStyle === "Squared Dam"
          ? "_SQUAREDAM"
          : `_${sillStyle.split(" ")[0].toUpperCase().trim()}`;
  } else {
    sill =
      sillStyle.includes("Dam") && !screenType?.includes("Centor") && screen
        ? ""
        : sillStyle === "Rounded Dam" &&
            (!screen || screenType?.includes("Centor"))
          ? "_ROUNDDAM"
          : sillStyle === "Squared Dam" &&
              (!screen || screenType?.includes("Centor"))
            ? "_SQUAREDAM"
            : `_${sillStyle.replace(/\s/g, "").toUpperCase()}`;
  }

  const cloudinaryID = `${baseURL}/bifolds/headSill/${materialLabel}${tradLbl}${scr}${sill}.jpg`;
  return cloudinaryID;
}

export function swingHeadSillImg(
  material: string,
  swingDirection: string,
  screenType: string,
  screen: string,
) {
  var url = "";

  if (swingDirection === "Inswing") {
    if (material === "All Wood") {
      url = `${baseURL}/swings/headSill/WOOD_INSWING.jpg`;
    } else {
      url = `${baseURL}/swings/headSill/INSWING.jpg`;
    }
  } else {
    if (material === "All Wood") {
      if (screen) {
        const screenLbl = screenType?.includes("Centor") ? "CENTOR_SCR" : "SCR";
        url = `${baseURL}/swings/headSill/OS_WOOD_${screenLbl}.jpg`;
      } else {
        url = `${baseURL}/swings/headSill/OS_WOOD_NOSCR.jpg`;
      }
    } else {
      if (screen) {
        const screenLbl = screenType?.includes("Centor")
          ? "CENTOR_SCREEN"
          : "SCREEN";
        url = `${baseURL}/swings/headSill/${screenLbl}.jpg`;
      } else {
        url = `${baseURL}/swings/headSill/NOSCREEN.jpg`;
      }
    }
  }

  return url;
}

export function screenImg(
  slidingScreen: string,
  screen: string,
  screenLayout: string,
  swingDirection: string,
  screenType: string,
  doorType: string,
) {
  if (screenType === "Centor Flat Screen/Shade") {
    const centorScr = !screen
      ? ""
      : screen.includes("Double") || screen.includes("Combo")
        ? "CENTOR_SCREEN_DOUBLE"
        : screenLayout && screenLayout.includes("Left")
          ? "CENTOR_SCREEN_LEFT"
          : screenLayout && screenLayout.includes("Right")
            ? "CENTOR_SCREEN_RIGHT"
            : "";

    return `https://res.cloudinary.com/ag-millworks/image/upload/pdfDrawings/screen/${centorScr}.png`;
  }

  const scr =
    slidingScreen || !screen || swingDirection === "Inswing"
      ? ""
      : screen?.includes("Double Screen")
        ? "DOUBLESCREENS"
        : !screen?.includes("Double Screen") &&
            screenLayout &&
            screenLayout.includes("Compress Left")
          ? "SCREENDETAILLEFT"
          : !screen?.includes("Double Screen") &&
              screenLayout &&
              screenLayout.includes("Compress Right")
            ? "SCREENDETAILRIGHT"
            : "";

  const slidingScr =
    doorType !== "Slider" || screenType !== "Sliding Screen"
      ? ""
      : slidingScreen === "Add Double Screen"
        ? "SLIDING_SCREEN_PAIR"
        : slidingScreen === "Add Screen Open Left"
          ? "SLIDING_SCREEN_LEFT"
          : slidingScreen === "Add Screen Open Right"
            ? "SLIDING_SCREEN_RIGHT"
            : "";

  const scrLabel = scr || slidingScr;

  return `https://res.cloudinary.com/ag-millworks/image/upload/pdfDrawings/screen/${scrLabel}.png`;
}

export function bifoldCornerJambLegImg(
  cornerType: string,
  material: string,
  screenType: string,
  screen: string,
  screenPocket: string,
  primary: string,
  oneSideOdd: boolean,
) {
  const materialLabel = material !== "All Wood" ? "_CLAD" : "_WOOD";

  const scr = !screen
    ? ""
    : screenType?.includes("Centor")
      ? "_CENTOR_SCR"
      : !screenPocket
        ? "_SCR"
        : screenPocket.includes("Double Pocket")
          ? "_DBL_PKT_SCR"
          : screenPocket.includes("Pocket Left")
            ? "_LEFT_PKT_SCR"
            : screenPocket.includes("Pocket Right")
              ? "_RIGHT_PKT_SCR"
              : "";

  const primaryLabel = primary === "Left" ? "_PRIMARY_LEFT" : "_PRIMARY_RIGHT";

  const handle = oneSideOdd ? "_HANDLE" : "";

  return `${baseURL}/bifoldCorners/jambLeg/${cornerType}${materialLabel}${scr}${primaryLabel}${handle}.jpg`;
}

export function fixedHeadSill(material: string, config: string, sill: string) {
  const path = "fixedPanels/headSill";
  let label = material === "All Wood" ? "wood_" : "";

  switch (config) {
    case "Fixed Bi-fold":
      const sillLabel = sill === "Rounded Dam" ? "roundSill" : "squareSill";
      label += "bifold_" + sillLabel;
      break;
    case "Fixed Slider":
      label += "slider";
      break;
    case "Fixed Inswing":
      label += "inswing";
      break;
    case "Fixed Outswing":
      label += "outswing";
      break;
    case "Fixed Full Bound":
      label += "fullbound";
      break;
    default:
      label = "";
  }

  const url = `${baseURL}/${path}/${label}.jpg`;
  return url;
}

export function fixedJambLeg(material: string, config: string) {
  const path = "fixedPanels/jambLeg";
  let label = material === "All Wood" ? "wood_" : "";

  switch (config) {
    case "Fixed Bi-fold":
      label += "bifold";
      break;
    case "Fixed Slider":
      label += "slider";
      break;
    case "Fixed Inswing":
      label += "inswing";
      break;
    case "Fixed Outswing":
      label += "outswing";
      break;
    case "Fixed Full Bound":
      label += "fullbound";
      break;
    default:
      label = "";
  }

  const url = `${baseURL}/${path}/${label}.jpg`;
  return url;
}

export function pivotJambLeg(
  hinge: string,
  sidelite: string,
  sideliteSide: string,
) {
  const hingeLbl = `IS${hinge}`;

  const sideliteLbl =
    sidelite === "Add Single Sidelite"
      ? "_1SL"
      : sidelite === "Add Double Sidelite"
        ? "_2SL"
        : "";

  const lockLbl = sideliteSide === "LOCK" ? "_LOCK" : "";

  return `${baseURL}/pivots/jambLeg/${hingeLbl}${sideliteLbl}${lockLbl}_v2.jpg`;
}

export function pivotHeadSill(
  material: string,
  hardware: string,
  movement: string,
  dropseal: string,
  sill: string,
) {
  const materialLbl = material === "All Wood" ? "WOOD_" : "";

  const hwLbl =
    hardware === "Fritsjurgens"
      ? "Fritsjurgen".toUpperCase()
      : hardware.toUpperCase();

  const actionLbl = movement.split(" ").join("_").toUpperCase();

  const dropLbl = dropseal ? "_DROPSEAL" : "";

  const sillLbl = sill?.includes("Wood") ? "_WOOD_SILL" : "";

  return `${baseURL}/pivots/headSill/${materialLbl}${hwLbl}_${actionLbl}${dropLbl}${sillLbl}.jpg`;
}
