import React, { FC, Dispatch, SetStateAction } from "react";
import Typography from "@mui/material/Typography";
import MarginMarkupToggle from "./MarginMarkupToggle";
import { styled } from "@mui/material/styles";

const StyledDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: 20,
  paddingLeft: 10,
  paddingRight: 10,
});

const StyledInputDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  flexDirection: "column",
});

const StyledSpan = styled("span")({
  position: "absolute",
  right: 5,
  top: 3,
  zIndex: 2,
  fontSize: 12,
  backgroundColor: "white",
});

const StyledInput = styled("input")({
  width: 100,
  height: 25,
  paddingLeft: 20,
});

type SetStringState = Dispatch<SetStateAction<string>>;

type SetBoolState = Dispatch<SetStateAction<boolean>>;

interface MarginInputProps {
  marginInput: string;
  setMarginInput: SetStringState;
  errMessageMargin: string;
  setErrMessage: SetStringState;
  setViewPdf: SetBoolState;
  setChangesMade: SetBoolState;
  disableMargin: boolean;
  withMarkup: boolean;
  setWithMarkup: SetBoolState;
  fixedMargin: string | null;
  fixedMarkup: string | null;
  quoteStatus: string;
}

const MarginInput: FC<MarginInputProps> = ({
  marginInput,
  setMarginInput,
  errMessageMargin,
  setErrMessage,
  setViewPdf,
  setChangesMade,
  disableMargin,
  withMarkup,
  setWithMarkup,
  fixedMargin,
  fixedMarkup,
  quoteStatus,
}) => {
  const handleMarginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const withDecimal = value.includes(".");

    if (withDecimal) {
      const decimalPlaces = value.split(".")[1].length;
      if (decimalPlaces > 3) return;
    }

    if (value.includes("-") || value.includes("+") || value.includes("e")) {
      setErrMessage("Please Enter a Positive Whole Number");
    } else {
      var marginI = parseInt(value);
      marginI > 99 && marginI
        ? setErrMessage("Must be between 0 and 99")
        : setErrMessage("");
    }

    setViewPdf(false);
    setMarginInput(value);
    setChangesMade(true);
  };

  return (
    <StyledDiv>
      <MarginMarkupToggle
        setViewPdf={setViewPdf}
        withMarkup={withMarkup}
        setWithMarkup={setWithMarkup}
        fixedMargin={fixedMargin}
        fixedMarkup={fixedMarkup}
        setMarginInput={setMarginInput}
        quoteStatus={quoteStatus}
      />

      <StyledInputDiv>
        <div style={{ position: "relative" }}>
          <StyledInput
            type="number"
            step="any"
            onChange={handleMarginChange}
            value={marginInput}
            name="marginInput"
            autoFocus
            disabled={disableMargin}
          />
          <StyledSpan>%</StyledSpan>
        </div>
        {errMessageMargin && (
          <Typography style={{ color: "red" }} variant="caption">
            {errMessageMargin}
          </Typography>
        )}
      </StyledInputDiv>
    </StyledDiv>
  );
};

export default MarginInput;
