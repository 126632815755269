import React, { useEffect, FC, useState, useMemo } from "react";
import { Button } from "@mui/material";
import AddShoppingCart from "@mui/icons-material/AddShoppingCart";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useStore } from "../store/store";
import { useAccordion } from "../store/accordion";
import { styled } from "@mui/material/styles";
import Preview from "./Canvas/Preview";
import { isMobile } from "../pages/DealerZone";

const StyledCancelButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(5),
  flexBasis: "80%",
  color: "white",
  position: "fixed",
  bottom: 120,
  right: -25,
  zIndex: 50,
  width: 169,
}));

const StyledSubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(5),
  flexBasis: "80%",
  color: "white",
}));

const StyledEditButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(5),
  flexBasis: "80%",
  color: "white",
  position: "fixed",
  bottom: 60,
  right: -25,
  zIndex: 50,
  width: 169,
}));

const StyledPreviewButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(5),
  flexBasis: "80%",
  // color: "#4b4b4b",
  position: "fixed",
  bottom: 0,
  right: -25,
  zIndex: 50,
  width: 169,
  fontWeight: "bold",
}));

const StyledMinimizeButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(5),
  flexBasis: "20%",
  color: "white",
}));

interface ConfigBtnsProps {
  resetValues: () => void;
  addDoorToSidebar: (withCheck?: boolean, ignoreUnitCheck?: boolean) => void;
}

function isBtnDisabled(
  errorArray: (string | undefined)[],
  requiredArray: (string | undefined)[],
) {
  var withErr = false;

  const errorArrayHasValue = errorArray.some((err) => Boolean(err) === true);

  if (errorArrayHasValue) return true;

  requiredArray.every((addon) => addon) ? (withErr = false) : (withErr = true);

  return withErr;
}

const ConfiguratorBtns: FC<ConfigBtnsProps> = ({
  resetValues,
  addDoorToSidebar,
}) => {
  const editBtn = useStore((state) => state.isEdit);

  const handleMinimize = useAccordion((state) => state.handleMinimize);
  const handleError = useStore((state) => state.handleError);

  const widthErr = useStore((state) => state.widthErr);
  const widthErrCornerRight = useStore((state) => state.widthErrCornerRight);
  const heightErr = useStore((state) => state.heightErr);
  const jambErr = useStore((state) => state.jambErr);
  const configErr = useStore((state) => state.configErr);
  const primaryDoorErr = useStore((state) => state.primaryDoorErr);
  const swingWidthErr = useStore((state) => state.swingWidthErr);
  const sideliteWidthErr = useStore((state) => state.sideliteWidthErr);
  const pivotPointErr = useStore((state) => state.pivotPointErr);

  const doorType = useStore((state) => state.quote.doorType);
  const cornerType = useStore((state) => state.quote.cornerType);
  const cornerConfigRight = useStore((state) => state.quote.cornerConfigRight);
  const rightWidth = useStore((state) => state.quote.cornerWidthRight);
  const material = useStore((state) => state.quote.material);
  const unitSize = useStore((state) => state.quote.unitSize);
  const height = useStore((state) => state.quote.height);
  const exactConfig = useStore((state) => state.quote.exactConfig);
  const woodSpecies = useStore((state) => state.quote.woodSpecies);
  const swingPanelWidth = useStore((state) => state.quote.swingPanelWidth);
  const swingDirection = useStore((state) => state.quote.swingDirection);
  const configuration = useStore((state) => state.quote.configuration);
  const pivotHardware = useStore((state) => state.quote.pivotHardware);
  const pivotAction = useStore((state) => state.quote.pivotAction);

  const stilesAndRails = useStore((state) => state.quote.stilesAndRails);

  const [showPrevBtn, setShowPrevBtn] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [showPortal, setShowPortal] = useState(false);

  useEffect(() => {
    if (doorType === "Slider") return;
    const errKey = "configErr";

    if (doorType === "Swing") {
      if (swingDirection && !exactConfig) {
        return handleError(errKey, "Please Select an Exact Unit Configuration");
      }
      return handleError(errKey, "");
    }

    if (doorType.includes("Corner")) {
      if (!cornerType) {
        return handleError(errKey, "Please Select a Corner Type");
      }

      if (!exactConfig || !cornerConfigRight) {
        return handleError(errKey, "Please Select a Unit Configuration");
      }
    } else {
      if (configuration && !exactConfig) {
        return handleError(errKey, "Please Select an Exact Unit Configuration");
      }
    }

    handleError(errKey, "");
  }, [
    doorType,
    swingDirection,
    configuration,
    exactConfig,
    cornerType,
    cornerConfigRight,
  ]);

  const errorArray =
    doorType === "Swing"
      ? [
          widthErr,
          heightErr,
          jambErr,
          configErr,
          swingWidthErr,
          sideliteWidthErr,
          primaryDoorErr,
        ]
      : doorType === "Bi-fold Zero Corner"
        ? [
            widthErr,
            heightErr,
            swingWidthErr,
            jambErr,
            configErr,
            primaryDoorErr,
            widthErrCornerRight,
          ]
        : doorType === "Pivot"
          ? [pivotPointErr, widthErr, heightErr, jambErr, configErr]
          : [
              widthErr,
              heightErr,
              jambErr,
              configErr,
              primaryDoorErr,
              swingWidthErr,
              sideliteWidthErr,
            ];

  const requiredArray = useMemo(() => {
    if (doorType === "Swing") {
      return [
        material,
        height,
        unitSize,
        exactConfig,
        woodSpecies,
        swingPanelWidth,
      ];
    }

    if (doorType === "Pivot") {
      return [
        material,
        height,
        unitSize,
        exactConfig,
        woodSpecies,
        swingPanelWidth,
        pivotHardware,
        pivotAction,
      ];
    }

    if (doorType === "Bi-fold Zero Corner") {
      return [
        material,
        height,
        unitSize,
        rightWidth,
        exactConfig,
        cornerConfigRight,
        woodSpecies,
      ];
    }

    return [material, height, unitSize, exactConfig, woodSpecies];
  }, [
    doorType,
    material,
    height,
    unitSize,
    exactConfig,
    woodSpecies,
    swingPanelWidth,
    pivotHardware,
    pivotAction,
    rightWidth,
    cornerConfigRight,
  ]);

  useEffect(() => {
    if ([...requiredArray, stilesAndRails].every((itm) => itm)) {
      setShowPrevBtn(true);
    }
  }, [requiredArray, stilesAndRails]);

  const btnDisabled = isBtnDisabled(errorArray, requiredArray);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {editBtn ? (
        <>
          <StyledCancelButton
            variant="contained"
            size="large"
            color="customGrey"
            onClick={resetValues}
          >
            Cancel Update
          </StyledCancelButton>

          <StyledEditButton
            variant="contained"
            color="primary"
            size="large"
            startIcon={<AddShoppingCart />}
            onClick={() => addDoorToSidebar(true)}
            disabled={btnDisabled}
          >
            Update Unit
          </StyledEditButton>
        </>
      ) : (
        <StyledSubmitButton
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddShoppingCart />}
          onClick={() => addDoorToSidebar(true)}
          disabled={btnDisabled}
        >
          Add to Quote
        </StyledSubmitButton>
      )}

      {showPrevBtn && (
        <>
          <StyledPreviewButton
            variant={editBtn ? "outlined" : "contained"}
            color="customGrey"
            style={{
              backgroundColor: editBtn ? "whitesmoke" : "",
              color: editBtn ? "#4b4b4b" : "white",
            }}
            size="large"
            startIcon={<VisibilityIcon />}
            onClick={
              isMobile()
                ? () => setShowPortal(true)
                : () => setOpenPreview(true)
            }
            disabled={openPreview || showPortal ? true : false}
          >
            2D Preview
          </StyledPreviewButton>

          <Preview
            open={openPreview}
            setOpen={setOpenPreview}
            showPortal={showPortal}
            setShowPortal={setShowPortal}
          />
        </>
      )}

      <StyledMinimizeButton
        variant="contained"
        size="large"
        color="customGrey"
        onClick={handleMinimize}
      >
        Minimize Tabs
      </StyledMinimizeButton>
    </div>
  );
};

export default ConfiguratorBtns;
