import React, { useState, FC, SyntheticEvent } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { blueGrey } from "@mui/material/colors";

const StyledDivCol = styled("div")({
  dislay: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: 10,
});

const StyledAccordionDetails = styled(AccordionDetails)({
  dislay: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: 10,
});

const StyledInputDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: 20,
});

const StyledTypography = styled(Typography)({
  flexBasis: "40%",
  marginRight: 10,
});

const StyledSpan = styled("span")({
  color: "red",
  fontSize: 22,
});

const StyledInput = styled("input")(({ theme }) => ({
  backgroundColor: blueGrey[50],
  border: "1px solid grey",
  borderRadius: "4px",
  height: "30px",
  width: 300,
  [theme.breakpoints.down("sm")]: {
    width: 150,
    marginLeft: 5,
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  "& .MuiAccordionSummary-content": {
    justifyContent: "center",
  },
}));

const StyledTypographySummary = styled(Typography)({
  position: "relative",
  color: "#3f51b5",
  textDecoration: "underline",
});

const StyledExpandMoreIcon = styled(ExpandMore)({
  position: "absolute",
  top: 0,
  left: 200,
});

const StyledExpandLessIcon = styled(ExpandLess)({
  position: "absolute",
  top: 0,
  left: 200,
});

const StyledAccordion = styled(Accordion)({
  width: 400,
  boxShadow: "none",
  border: 0,
});

interface DealerSalesTaxProps {
  handleAllChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  customerCity: string;
  customerZip: string;
  currentSalesTax?: number;
}

const DealerSalesTax: FC<DealerSalesTaxProps> = ({
  handleAllChange,
  customerCity,
  customerZip,
  currentSalesTax,
}) => {
  const [expand, setExpand] = useState(false);

  const handleChange = (event: SyntheticEvent, expanded: boolean) =>
    setExpand(expanded);

  return (
    <StyledDivCol>
      <StyledAccordion expanded={expand} onChange={handleChange}>
        <StyledAccordionSummary
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <StyledTypographySummary>
            Add Sales Tax to Quote?{" "}
            {!expand ? <StyledExpandMoreIcon /> : <StyledExpandLessIcon />}
          </StyledTypographySummary>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <StyledInputDiv style={{ paddingBottom: 10 }}>
            <StyledTypography variant="body2">
              Jobsite Zip Code:{" "}
              {(customerCity || customerZip) && <StyledSpan>* </StyledSpan>}
            </StyledTypography>
            <StyledInput
              maxLength={20}
              onChange={handleAllChange}
              name="customerZip"
              value={customerZip}
              placeholder="Customer Zip Code"
            />
          </StyledInputDiv>

          <StyledInputDiv style={{ paddingBottom: 0 }}>
            <StyledTypography variant="body2">
              Jobsite City:{" "}
              {(customerCity || customerZip) && <StyledSpan>* </StyledSpan>}
            </StyledTypography>
            <StyledInput
              maxLength={20}
              onChange={handleAllChange}
              name="customerCity"
              value={customerCity}
              placeholder="Customer City"
            />
          </StyledInputDiv>

          {currentSalesTax && (
            <Typography style={{ color: "green", paddingTop: 5 }}>
              Current Sales Tax: {(currentSalesTax * 100).toFixed(3)}%
            </Typography>
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
    </StyledDivCol>
  );
};

export default DealerSalesTax;
