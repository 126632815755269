const CLOUDINARY_URL = "https://res.cloudinary.com/ag-millworks/image/upload";

export const screenLayoutImages = [
  {
    label: "Double Screen",
    img: "Screens/double_screen",
  },
  {
    label: "Double Screen Outside Corner",
    img: "Screens/OUTSIDE_CORNER",
  },
  {
    label: "Double Screen Inside Corner",
    img: "Screens/INSIDE_CORNER",
  },
  {
    label: "Double Screen Outside Corner Shade",
    img: "Screens/OUTSIDE_CORNER_SHADE",
  },
  {
    label: "Double Screen Inside Corner Shade",
    img: "Screens/INSIDE_CORNER_SHADE",
  },
  {
    label: "Single Screen Compress Left",
    img: "Screens/left_screen",
  },
  {
    label: "Single Screen Compress Right",
    img: "Screens/right_screen",
  },
  {
    label: "Combo Screen Right",
    img: "Screens/combo_shade_right",
  },
  {
    label: "Combo Screen Left",
    img: "Screens/combo_shade_left",
  },
  {
    label: "Shade Right",
    img: "Screens/right_shade",
  },
  {
    label: "Shade Left",
    img: "Screens/left_shade",
  },
  {
    label: "Double Shade",
    img: "Screens/double_shade",
  },
];

export const screenColors = [
  {
    id: 1,
    type: "screenColor",
    label: "Black Extrusion",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
  {
    id: 2,
    type: "screenColor",
    label: "White Extrusion",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
];

export const shadeColors = [
  {
    id: 1,
    type: "shadeMeshColor",
    label: "Tuscany Egret",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
  {
    id: 2,
    type: "shadeMeshColor",
    label: "Tuscany Shell",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
  {
    id: 2,
    type: "shadeMeshColor",
    label: "Boston Cloud",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
];

export const singleScreenLayout = [
  {
    id: 2,
    type: "screenLayout",
    label: "Single Screen Compress Left",
    pdfLabel: "Single Compress Left",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
  {
    id: 3,
    type: "screenLayout",
    label: "Single Screen Compress Right",
    pdfLabel: "Single Compress Right",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
];

export const comboScreenLayout = [
  {
    id: 2,
    type: "screenLayout",
    label: "Left Shade/Right Screen",
    pdfLabel: "Left Shade/Right Screen",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
  {
    id: 3,
    type: "screenLayout",
    label: "Right Shade/Left Screen",
    pdfLabel: "Right Shade/Left Screen",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
];

export const shadeLayout = [
  {
    id: 2,
    type: "screenLayout",
    label: "Left Shade",
    pdfLabel: "Left Shade",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
  {
    id: 3,
    type: "screenLayout",
    label: "Right Shade",
    pdfLabel: "Right Shade",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
];

export const singlePocket = [
  {
    id: 1,
    type: "screenPocket",
    label: "Add Pocket",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
];

export const pocketDouble = [
  {
    id: 1,
    type: "screenPocket",
    label: "Add Pocket",
    variant: "default",
    checkMark: true,
    disabled: true,
  },
  {
    id: 2,
    type: "screenPocket",
    label: "Add Double Pocket",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
];

export const doublePocketOptions = [
  {
    id: 1,
    type: "screenPocket",
    label: "Add Pocket Left",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
  {
    id: 2,
    type: "screenPocket",
    label: "Add Pocket Right",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
  {
    id: 3,
    type: "screenPocket",
    label: "Add Double Pocket",
    variant: "outlined",
    checkMark: false,
    disabled: false,
  },
];

export const addScreen = [
  {
    id: 1,
    type: "addScreen",
    label: "Add Screen",
    variant: "outlined",
    checkMark: false,
  },
];

export const addDoubleScreen = [
  {
    id: 1,
    type: "addScreen",
    label: "Add Double Screen",
    variant: "outlined",
    checkMark: false,
  },
];

export const screenPocketsImages = [
  {
    label: "Add Double Pocket",
    img: "ScreenPockets/bifolds/double",
    imgWood: "ScreenPockets/bifolds/doubleWood",
    img2: `${CLOUDINARY_URL}/ScreenPockets/doubleBothPockets.jpg`,
  },
  {
    label: "Add Pocket Left",
    img: "ScreenPockets/bifolds/left",
    imgWood: "ScreenPockets/bifolds/leftWood",
    img2: `${CLOUDINARY_URL}/ScreenPockets/doubleLeftPocket.jpg`,
  },
  {
    label: "Add Pocket Right",
    img: "ScreenPockets/bifolds/right",
    imgWood: "ScreenPockets/bifolds/rightWood",
    img2: `${CLOUDINARY_URL}/ScreenPockets/doubleRightPocket.jpg`,
  },
  {
    label: "Single Screen Compress Left",
    img: "ScreenPockets/bifolds/left",
    imgWood: "ScreenPockets/bifolds/leftWood",
    img2: `${CLOUDINARY_URL}/ScreenPockets/leftPocket.jpg`,
  },
  {
    label: "Single Screen Compress Right",
    img: "ScreenPockets/bifolds/right",
    imgWood: "ScreenPockets/bifolds/rightWood",
    img2: `${CLOUDINARY_URL}/ScreenPockets/rightPocket.jpg`,
  },
];

export const cornerScreenPocketsImages = [
  {
    label: "Add Double Pocket",
    cornerType: "Outside",
    img: "ScreenPockets/bifolds/OUTSIDE_CORNER",
    imgWood: "ScreenPockets/bifolds/WOOD_OUTSIDE_CORNER",
    img2: `${CLOUDINARY_URL}/ScreenPockets/OUTSIDE_CORNER_DBL_PKT_SCR.jpg`,
  },
  {
    label: "Add Pocket Left",
    cornerType: "Outside",
    img: "ScreenPockets/bifolds/left",
    imgWood: "ScreenPockets/bifolds/leftWood",
    img2: `${CLOUDINARY_URL}/ScreenPockets/OUTSIDE_CORNER_LEFT_PKT_SCR.jpg`,
  },
  {
    label: "Add Pocket Right",
    cornerType: "Outside",
    img: "ScreenPockets/bifolds/right",
    imgWood: "ScreenPockets/bifolds/rightWood",
    img2: `${CLOUDINARY_URL}/ScreenPockets/OUTSIDE_CORNER_RIGHT_PKT_SCR.jpg`,
  },
  {
    label: "Add Double Pocket",
    cornerType: "Inside",
    img: "ScreenPockets/bifolds/INSIDE_CORNER",
    imgWood: "ScreenPockets/bifolds/WOOD_INSIDE_CORNER",
    img2: `${CLOUDINARY_URL}/ScreenPockets/INSIDE_CORNER_DBL_PKT_SCR.jpg`,
  },
  {
    label: "Add Pocket Left",
    cornerType: "Inside",
    img: "ScreenPockets/bifolds/left",
    imgWood: "ScreenPockets/bifolds/leftWood",
    img2: `${CLOUDINARY_URL}/ScreenPockets/INSIDE_CORNER_LEFT_PKT_SCR.jpg`,
  },
  {
    label: "Add Pocket Right",
    cornerType: "Inside",
    img: "ScreenPockets/bifolds/right",
    imgWood: "ScreenPockets/bifolds/rightWood",
    img2: `${CLOUDINARY_URL}/ScreenPockets/INSIDE_CORNER_RIGHT_PKT_SCR.jpg`,
  },
];

export const pocketsSwingImages = [
  {
    label: "Single Screen Compress Left",
    img: "ScreenPockets/swings/leftSwing",
    imgWood: "ScreenPockets/swings/leftSwingWood",
    img2: `${CLOUDINARY_URL}/ScreenPockets/leftPocket.jpg`,
  },
  {
    label: "Single Screen Compress Right",
    img: "ScreenPockets/swings/rightSwing",
    imgWood: "ScreenPockets/swings/rightSwingWood",
    img2: `${CLOUDINARY_URL}/ScreenPockets/rightPocket.jpg`,
  },
  {
    label: "Add Double Screen",
    img: "ScreenPockets/swings/doubleSwing",
    imgWood: "ScreenPockets/swings/doubleSwingWood",
    img2: `${CLOUDINARY_URL}/ScreenPockets/doubleBothPockets.jpg`,
  },
  {
    label: "Add Pocket Left",
    img: "ScreenPockets/swings/leftSwing",
    imgWood: "ScreenPockets/swings/leftSwingWood",
    img2: `${CLOUDINARY_URL}/ScreenPockets/doubleLeftPocket.jpg`,
  },
  {
    label: "Add Pocket Right",
    img: "ScreenPockets/swings/rightSwing",
    imgWood: "ScreenPockets/swings/rightSwingWood",
    img2: `${CLOUDINARY_URL}/ScreenPockets/doubleRightPocket.jpg`,
  },
  {
    label: "Add Double Pocket",
    img: "ScreenPockets/swings/doubleSwing",
    imgWood: "ScreenPockets/swings/doubleSwingWood",
    img2: `${CLOUDINARY_URL}/ScreenPockets/doubleBothPockets.jpg`,
  },
];

export interface PocketSliderImageObj {
  label: string;
  img: string;
  imgWood: string;
  imgPocketing: string;
  imgPocketingWood: string;
  img2: string;
}

export const pocketSliderImages = [
  {
    label: "Screen Left",
    img: "ScreenPockets/sliders/Left",
    imgWood: "ScreenPockets/sliders/Wood_Left",
    imgPocketing: "ScreenPockets/sliders/Pocketing_Left",
    imgPocketingWood: "ScreenPockets/sliders/WOOD_Pocketing_Left",
    img2: `${CLOUDINARY_URL}/ScreenPockets/leftPocket.jpg`,
  },
  {
    label: "Screen Right",
    img: "ScreenPockets/sliders/Right",
    imgWood: "ScreenPockets/sliders/Wood_Right",
    imgPocketing: "ScreenPockets/sliders/Pocketing_Right",
    imgPocketingWood: "ScreenPockets/sliders/WOOD_Pocketing_Right",
    img2: `${CLOUDINARY_URL}/ScreenPockets/rightPocket.jpg`,
  },
  {
    label: "Double Screen",
    img: "ScreenPockets/sliders/Double",
    imgWood: "ScreenPockets/sliders/Wood_Double",
    imgPocketing: "ScreenPockets/sliders/Pocketing_Double",
    imgPocketingWood: "ScreenPockets/sliders/WOOD_Pocketing_Double",
    img2: `${CLOUDINARY_URL}/ScreenPockets/doubleBothPockets.jpg`,
  },
  {
    label: "Double Screen Left Pocket",
    img: "ScreenPockets/sliders/Double_Left",
    imgWood: "ScreenPockets/sliders/Wood_Double_Left",
    imgPocketing: "",
    imgPocketingWood: "",
    img2: `${CLOUDINARY_URL}/ScreenPockets/doubleLeftPocket.jpg`,
  },
  {
    label: "Double Screen Right Pocket",
    img: "ScreenPockets/sliders/Double_Right",
    imgWood: "ScreenPockets/sliders/Wood_Double_Right",
    imgPocketing: "",
    imgPocketingWood: "",
    img2: `${CLOUDINARY_URL}/ScreenPockets/doubleRightPocket.jpg`,
  },
  {
    label: "PXXX Double Pocket Double Screen",
    img: "ScreenPockets/sliders/SINGLE_LEFT_POCKET_DOUBLE_SCREEN_POCKETS",
    imgWood:
      "ScreenPockets/sliders/WOOD_SINGLE_LEFT_POCKET_DOUBLE_SCREEN_POCKETS",
    imgPocketing:
      "ScreenPockets/sliders/SINGLE_LEFT_POCKET_DOUBLE_SCREEN_POCKETS",
    imgPocketingWood:
      "ScreenPockets/sliders/WOOD_SINGLE_LEFT_POCKET_DOUBLE_SCREEN_POCKETS",
    img2: `${CLOUDINARY_URL}/ScreenPockets/doubleBothPockets.jpg`,
  },
  {
    label: "XXXP Double Pocket Double Screen",
    img: "ScreenPockets/sliders/SINGLE_RIGHT_POCKET_DOUBLE_SCREEN_POCKETS",
    imgWood:
      "ScreenPockets/sliders/WOOD_SINGLE_RIGHT_POCKET_DOUBLE_SCREEN_POCKETS",
    imgPocketing:
      "ScreenPockets/sliders/SINGLE_RIGHT_POCKET_DOUBLE_SCREEN_POCKETS",
    imgPocketingWood:
      "ScreenPockets/sliders/WOOD_SINGLE_RIGHT_POCKET_DOUBLE_SCREEN_POCKETS",
    img2: `${CLOUDINARY_URL}/ScreenPockets/doubleBothPockets.jpg`,
  },
  {
    label: "Left Pocket Double Screen",
    img: "ScreenPockets/sliders/SINGLE_LEFT_POCKET_DOUBLE_SCREEN_POCKETS",
    imgWood:
      "ScreenPockets/sliders/WOOD_SINGLE_LEFT_POCKET_DOUBLE_SCREEN_POCKETS",
    imgPocketing:
      "ScreenPockets/sliders/SINGLE_LEFT_POCKET_DOUBLE_SCREEN_POCKETS",
    imgPocketingWood:
      "ScreenPockets/sliders/WOOD_SINGLE_LEFT_POCKET_DOUBLE_SCREEN_POCKETS",
    img2: `${CLOUDINARY_URL}/ScreenPockets/doubleLeftPocket.jpg`,
  },
  {
    label: "Right Pocket Double Screen",
    img: "ScreenPockets/sliders/SINGLE_RIGHT_POCKET_DOUBLE_SCREEN_POCKETS",
    imgWood:
      "ScreenPockets/sliders/WOOD_SINGLE_RIGHT_POCKET_DOUBLE_SCREEN_POCKETS",
    imgPocketing:
      "ScreenPockets/sliders/SINGLE_RIGHT_POCKET_DOUBLE_SCREEN_POCKETS",
    imgPocketingWood:
      "ScreenPockets/sliders/WOOD_SINGLE_RIGHT_POCKET_DOUBLE_SCREEN_POCKETS",
    img2: `${CLOUDINARY_URL}/ScreenPockets/doubleRightPocket.jpg`,
  },
];

export const addPairSwing = [
  {
    id: 1,
    type: "addScreen",
    label: "Add Screen",
    variant: "outlined",
    checkMark: false,
  },
  {
    id: 2,
    type: "addScreen",
    label: "Add Double Screen",
    variant: "outlined",
    checkMark: false,
  },
];

export const addCentorScreens = [
  {
    id: 1,
    type: "addScreen",
    label: "Add Screen",
    variant: "outlined",
    checkMark: false,
  },
  {
    id: 2,
    type: "addScreen",
    label: "Add Shade",
    variant: "outlined",
    checkMark: false,
  },
  {
    id: 3,
    type: "addScreen",
    label: "Add Combo Screen/Shade",
    variant: "outlined",
    checkMark: false,
  },
  {
    id: 4,
    type: "addScreen",
    label: "Add Double Screen",
    variant: "outlined",
    checkMark: false,
  },
  {
    id: 5,
    type: "addScreen",
    label: "Add Double Shade",
    variant: "outlined",
    checkMark: false,
  },
];

export const layoutLeft = [
  {
    id: 2,
    type: "screenLayout",
    label: "Single Screen Compress Left",
    pdfLabel: "Single Compress Left",
    variant: "default",
    checkMark: true,
    disabled: true,
  },
];

export const layoutRight = [
  {
    id: 3,
    type: "screenLayout",
    label: "Single Screen Compress Right",
    pdfLabel: "Single Compress Right",
    variant: "default",
    checkMark: true,
    disabled: true,
  },
];
