import { initialExpand, InitialExpandItm } from "../initialValues";
import noAccess from "./noAccess";
import { UnitObj } from "./quoteCartStorage";

export default function findRequired(unit: UnitObj) {
  const required: string[] = [];

  var expandDeepClone: InitialExpandItm[] = JSON.parse(
    JSON.stringify(initialExpand),
  );

  expandDeepClone.forEach((item) => (item.expanded = true));

  const paintAccordionIndex = 10;
  const stainAccordionIndex = 11;
  const finAccordionIndex = 22;
  const dlAccordionIndex = 23;
  const dlConfigAccordionIndex = 24;
  const screenAccordionIndex = 25;
  const kerfAccordionIndex = 33;

  if (unit.material !== "All Wood") {
    if (unit.exteriorCladColor?.includes("Custom") && !unit.customCladColor) {
      required.push("Custom Clad Color");
    } else if (!unit.exteriorCladColor) {
      required.push("Exterior Clad Color");
    }
  }

  if (!unit.stilesAndRails) required.push("Stiles and Rails");

  if (unit.doorType === "Pivot") {
    if (!unit.pivotPoint) required.push("Pivot Point");

    if (!unit.pivotBackPlate) required.push("Back Plate");

    if (!unit.pivotBackPlateColor) required.push("Back Plate Finish");

    if (unit.pivotMagnet && !unit.pivotMagnetColor)
      required.push("Magnet Finish");
  }

  if (
    unit.paintAndPrime === "Add Standard Paint" &&
    !unit.paintColor &&
    !unit.customPaintOrStain
  ) {
    required.push("Paint Color");
    expandDeepClone[paintAccordionIndex].require = true;
  } else if (
    unit.customPaintOrStain === "Add Custom Paint" &&
    !unit.customPaintColor
  ) {
    required.push("Custom Paint Color");
    expandDeepClone[paintAccordionIndex].require = true;
  } else {
    expandDeepClone[paintAccordionIndex].require = false;
  }

  if (
    unit.stain === "Add Standard Stain" &&
    !unit.stainColor &&
    !unit.customPaintOrStain
  ) {
    required.push("Stain Color");
    expandDeepClone[stainAccordionIndex].require = true;
  } else if (
    unit.customPaintOrStain === "Add Custom Stain" &&
    !unit.customStainColor
  ) {
    required.push("Custom Stain Color");
    expandDeepClone[stainAccordionIndex].require = true;
  } else {
    expandDeepClone[stainAccordionIndex].require = false;
  }

  if (unit.doorType.includes("Bi-fold") && !unit.headTrackColor)
    required.push("Head Track Color");

  if (
    unit.doorType === "Fixed Panel" &&
    unit.exactConfig === "Fixed Bi-fold" &&
    !unit.headTrackColor
  )
    required.push("Head Track Color");

  if (unit.doorType !== "Fixed Panel" && !unit.weatherStripColor)
    required.push("Weather Strip Color");

  if (unit.doorType === "Pivot") {
    if (!unit.handleStyle) required.push("Handle Style");
    if (unit.handleStyle === "Dallas Lever" && !unit.handleColor)
      required.push("Handle Finish");
  }

  if (
    unit.doorType !== "Fixed Panel" &&
    unit.doorType !== "Pivot" &&
    ((unit.doorType.includes("Corner") &&
      (!noAccess.includes(unit.exactConfig) ||
        !noAccess.includes(unit.cornerConfigRight || ""))) ||
      (!unit.doorType.includes("Corner") &&
        !noAccess.includes(unit.exactConfig)) ||
      unit.doorType === "Slider")
  ) {
    if (
      (unit.withHandle === "Add Handle Set" || unit.doorType === "Slider") &&
      unit.doorType !== "Bi-fold Window"
    ) {
      if (unit.handleStyle) {
        const veronaColors = [
          "Rustic Umber",
          "Satin Nickel(Resista Finish)",
          "Matte Black",
          "White",
          "Oil Rubbed Bronze *",
          "Polished Brass",
        ];
        const atlantaColors = ["Satin Nickel(Resista Finish)", "Matte Black"];
        const truthColors = [
          "Matte Black",
          "Satin Nickel PVD",
          "Satin PVD",
          "Bronze PVD",
        ];
        const otherColors = [
          "Rustic Umber",
          "Satin Nickel(Resista Finish)",
          "Matte Black",
        ];

        if (
          unit.handleStyle === "Verona" &&
          !veronaColors.includes(unit.handleColor || "")
        ) {
          required.push("Handle Finish");
        } else if (
          unit.handleStyle === "Atlanta *" &&
          !atlantaColors.includes(unit.handleColor || "")
        ) {
          required.push("Handle Finish");
        } else if (
          unit.doorType === "Slider" &&
          unit.handleStyle.includes("Truth") &&
          !truthColors.includes(unit.handleColor || "")
        ) {
          required.push("Handle Finish");
        } else if (
          unit.handleStyle !== "Verona" &&
          unit.handleStyle !== "Atlanta *" &&
          !unit.handleStyle.includes("Truth") &&
          !otherColors.includes(unit.handleColor || "")
        ) {
          required.push("Handle Finish");
        }
      } else {
        required.push("Handle Style");
      }

      if (!unit.withKey) required.push("Handle Key option");

      if (!unit.handleColor) required.push("Handle Finish");
    } else {
      if (!unit.withHandle && unit.doorType !== "Bi-fold Window")
        required.push("Handle Set");
    }
  }

  if (
    unit.doorType !== "Fixed Panel" &&
    unit.doorType !== "Pivot" &&
    parseInt(unit.height) >= 108
  ) {
    if (!unit.handleHeight) required.push("Handle Height");
  }

  if (unit.doorType === "Pivot" && parseInt(unit.height) >= 96) {
    if (!unit.handleHeight) required.push("Thumb Turn Height");
  }

  if (unit.doorType.includes("Bi-fold") && !unit.shootBoltColor)
    required.push("Shoot Bolt Color");

  if (unit.material !== "All Wood") {
    if (!unit.stickingStops) required.push("Stickings and Stops Profile");
  } else {
    if (!unit.stickingStopsExterior) required.push("Exterior Stop Profile");

    if (!unit.stickingStopsInterior) required.push("Interior Stop Profile");
  }

  if (
    !unit.hingeColor &&
    unit.doorType !== "Slider" &&
    unit.doorType !== "Fixed Panel"
  )
    required.push("Hinge Color");

  if (unit.addDL) {
    if (!unit.liteConfig) {
      required.push("Divided Lites Size");
      expandDeepClone[dlAccordionIndex].require = true;
    } else {
      expandDeepClone[dlAccordionIndex].require = false;
    }

    if (!unit.numberOfLites || !unit.dlLayout) {
      required.push("Divided Lites Configuration");
      expandDeepClone[dlConfigAccordionIndex].require = true;
    } else {
      expandDeepClone[dlConfigAccordionIndex].require = false;
    }

    if (
      unit.dlLayout === "2W1H" ||
      unit.dlLayout === "3W1H" ||
      unit.dlLayout === "4W1H"
    ) {
      if (!unit.daylightOpening) {
        required.push("Daylight Opening");
        expandDeepClone[dlConfigAccordionIndex].require = true;
      } else {
        expandDeepClone[dlConfigAccordionIndex].require = false;
      }
    }

    if (
      unit.doorType === "Swing" &&
      unit.sideLite !== "none" &&
      !unit.equalPanels &&
      unit.diffSideliteSdl
    ) {
      if (!unit.numberOfLitesSidelite || !unit.dlLayoutSidelite) {
        required.push("Sidelite Divided Lites Configuration");
        expandDeepClone[dlConfigAccordionIndex].require = true;
      } else {
        expandDeepClone[dlConfigAccordionIndex].require = false;
      }
    } else if (
      unit.doorType === "Swing" &&
      unit.sideLite !== "none" &&
      !unit.equalPanels &&
      unit.diffSideliteSdl === null
    ) {
      required.push("Sidelite Divided Lites Configuration");
      expandDeepClone[dlConfigAccordionIndex].require = true;
    }
  }

  if (!unit.spacerColor) required.push("IG Spacer Color");

  if (
    unit.screenType === "Centor Flat Screen/Shade" &&
    unit.addScreen &&
    unit.addScreen !== "Add Double Screen" &&
    unit.addScreen !== "Add Double Shade"
  ) {
    let requiredBool = false;

    if (!unit.screenLayout) {
      required.push("Screen Layout");
      requiredBool = true;
    }

    if (
      unit.addScreen === "Add Shade" ||
      unit.addScreen === "Add Combo Screen/Shade"
    ) {
      if (!unit.shadeMeshColor) {
        required.push("Shade Mesh Color");
        requiredBool = true;
      }
    }

    if (!unit.screenColor) {
      required.push("Screen Extrusion Color");
      requiredBool = true;
    }

    expandDeepClone[screenAccordionIndex].require = requiredBool;
  } else if (
    unit.addScreen &&
    unit.addScreen !== "Add Double Screen" &&
    unit.addScreen !== "Add Double Shade" &&
    unit.screenType !== "Sliding Screen"
  ) {
    let requiredBool = false;

    if (!unit.screenLayout) {
      required.push("Screen Layout");
      requiredBool = true;
    }

    if (!unit.screenColor) {
      required.push("Screen Extrusion Color");
      requiredBool = true;
    }
    expandDeepClone[screenAccordionIndex].require = requiredBool;
  } else if (
    unit.addScreen &&
    (unit.addScreen === "Add Double Screen" ||
      unit.addScreen === "Add Double Shade") &&
    unit.screenType !== "Sliding Screen"
  ) {
    let requiredBool = false;

    if (!unit.screenColor) {
      required.push("Screen Extrusion Color");
      requiredBool = true;
    }

    expandDeepClone[screenAccordionIndex].require = requiredBool;
  } else {
    expandDeepClone[screenAccordionIndex].require = false;
  }

  if (unit.doorType.includes("Bi-fold")) {
    if (unit.sillStyle === "Raised Sill") {
      required.push("select either Rounded or Squared Sill");
    } else if (!unit.sillStyle) {
      required.push("Sill Type");
    }
  }

  if (unit.doorType === "Fixed Panel" && unit.exactConfig === "Fixed Bi-fold") {
    if (!unit.sillStyle) required.push("select either Rounded or Squared Sill");
  }

  if (unit.doorType === "Slider" && unit.screenType === "Sliding Screen") {
    // if(!unit.addSlidingScreen) required.push('Screen Layout');
    if (!unit.screenColor) required.push("Screen Extrusion Color");
  }

  if (unit.doorType === "Slider" && !unit.sillStyle) required.push("Sill Type");

  if (unit.doorType === "Pivot" && !unit.sillStyle) required.push("Sill Type");

  if (
    !unit.sillColor &&
    unit.sillStyle !== "Stainless Steel Recessed *" &&
    unit.exactConfig !== "Fixed Full Bound" &&
    unit.doorType !== "Pivot"
  )
    required.push("Sill Finish");

  if (
    (unit.paintAndPrime ||
      unit.prime ||
      unit.customPaintOrStain ||
      unit.stain) &&
    !unit.sillFinish &&
    unit.exactConfig !== "Fixed Full Bound" &&
    unit.doorType !== "Pivot"
  ) {
    if (unit.doorType === "Fixed Panel") required.push("Sill Dam Finish");
    else if (
      unit.sillStyle !== "Stainless Steel Recessed *" &&
      (!unit.sillStyle || !unit.sillStyle.includes("Ultra"))
    )
      required.push("Sill Dam Finish");
  }

  if (!unit.glassType) required.push("Type of Glass");

  if (unit.glassAddon && !unit.glassAddonSide)
    required.push("Glass Surface Side");

  if (unit.jamb === "Custom Overall Jamb Width" && unit.doorType !== "Slider") {
    required.push("Custom Jamb Width");
  } else if (!unit.jamb && unit.doorType !== "Slider") {
    required.push("Jamb Width");
  }

  if (unit.flashingFin && unit.sliderPocket !== "With Pocket") {
    if (!unit.flashingFinConfig) {
      required.push("Flashing Fin Setback");
      expandDeepClone[finAccordionIndex].require = true;
    } else {
      expandDeepClone[finAccordionIndex].require = false;
    }
    if (unit.flashingFinConfig === "Custom Setback") {
      if (!unit.finSetback) {
        required.push("Flashing Fin Custom Setback");
        expandDeepClone[finAccordionIndex].require = true;
      } else {
        expandDeepClone[finAccordionIndex].require = false;
      }
    } else {
      expandDeepClone[finAccordionIndex].require = false;
    }
  } else {
    expandDeepClone[finAccordionIndex].require = false;
  }

  if (unit.kerf) {
    if (!unit.kerfConfig) {
      required.push("Kerf Setback");
      expandDeepClone[kerfAccordionIndex].require = true;
    } else {
      expandDeepClone[kerfAccordionIndex].require = false;
    }
    if (unit.kerfConfig === "Custom Setback") {
      if (!unit.kerfSetback) {
        required.push("Kerf Custom Setback");
        expandDeepClone[kerfAccordionIndex].require = true;
      } else {
        expandDeepClone[kerfAccordionIndex].require = false;
      }
    } else {
      expandDeepClone[kerfAccordionIndex].require = false;
    }
  } else {
    expandDeepClone[kerfAccordionIndex].require = false;
  }

  if (unit.doorType === "Pivot") {
    if (!unit.pivotHardware) required.push("Pivot Hardware");
    if (!unit.pivotAction) required.push("Pivot Movement");
  }

  return { required, expanded: expandDeepClone };
}
