export function findGlassQuantity(
  quantity: number,
  stiles: string,
  numberOfPanels: number,
  doorType: string,
  config: string,
  swingType: string,
  swingDirection: string,
  sidelite: string,
  sliderPocket: string,
  evenCornerSide?: boolean,
  addDL?: string,
  dlLayout?: string,
) {
  let qty = 0;
  let qtyDD = 0;

  let qtyFixed = 0;

  if (doorType === "Pivot") {
    let numHigh = 0;
    let numWide = 0;
    let pieces = 0;

    if (addDL === "Add Intermediate Rail" && dlLayout) {
      const wide = Number(dlLayout.split("")[0]);
      const high = Number(dlLayout.split("")[2]);
      numHigh = high;
      numWide = wide;
      pieces = wide * high;
    }

    qty = pieces || 1;

    sidelite.includes("Double")
      ? (qtyDD = 2)
      : sidelite.includes("Single")
        ? (qtyDD = 1)
        : (qtyDD = 0);

    //1W2H iRail
    if (sidelite && sidelite !== "none" && numHigh === 2) {
      return {
        qty: qty * Number(quantity),
        qtyTopBtm: qtyDD * 2 * numWide * Number(quantity),
      };
    }

    if (sidelite && sidelite !== "none" && numHigh) {
      return {
        qty: qty * Number(quantity),
        qtyTopBtm: qtyDD * 2 * numWide * Number(quantity),
        qtyMid: qtyDD * numWide * (numHigh - 2) * Number(quantity),
      };
    }
  } else if (doorType === "Fixed Panel") {
    qty = 1;
  } else if (doorType.includes("Bi-fold")) {
    let removeLetters = config.replace("L", "").replace("R", "");
    let arr = removeLetters.split("");

    const doubleSided = config.includes("L") && config.includes("R");

    arr.forEach((num) => {
      if (Math.abs(Number(num) % 2) !== 0 && stiles.includes("Slimline"))
        qtyDD += 1;
    });

    if (evenCornerSide && stiles.includes("Slimline")) {
      qtyDD = 1;
    }

    if (doubleSided && qtyDD > 1) {
      qtyDD = 2;
      qty = Number(numberOfPanels) - 2;
    } else {
      qty = Number(numberOfPanels) - qtyDD;
    }
  } else if (doorType === "Swing") {
    const inswingPair =
      swingType === "Pair Swing" && swingDirection === "Inswing";

    if (inswingPair) {
      qty = 1;
      qtyDD = 1;

      sidelite.includes("Double")
        ? (qtyFixed = 2)
        : sidelite.includes("Single")
          ? (qtyFixed = 1)
          : (qtyFixed = 0);

      return {
        qty: qty * Number(quantity),
        qtyDD: qtyDD * Number(quantity),
        qtyFixed: qtyFixed * Number(quantity),
      };
    }

    swingType === "Pair Swing" ? (qty = 2) : (qty = 1);

    sidelite.includes("Double")
      ? (qtyDD = 2)
      : sidelite.includes("Single")
        ? (qtyDD = 1)
        : (qtyDD = 0);
  } else if (doorType === "Slider") {
    const isDouble = config.includes("-");

    const isXX = config.includes("Primary");

    if (config.includes("O")) {
      qtyFixed = isDouble ? 2 : 1;
    }

    if (stiles.includes("Slimline")) {
      if (sliderPocket === "With Pocket") {
        const multiplier = isDouble ? 2 : 1;
        const numOfActivePanels = config
          .split("-")[0]
          .replace(/[^X^A]/g, "").length;

        if (numOfActivePanels === 1) {
          qty = 1 * multiplier;
        } else if (numOfActivePanels === 2) {
          qty = 2 * multiplier;
        } else if (numOfActivePanels === 3) {
          qty = 1 * multiplier;
          qtyDD = 2 * multiplier;
        } else if (numOfActivePanels === 4) {
          qty = 2 * multiplier;
          qtyDD = 2 * multiplier;
        }
      } else {
        if (!isXX) {
          qtyDD = isDouble ? 2 : 1;
          qty = Number(numberOfPanels) - qtyDD - qtyFixed;
        } else {
          const numOfPanels = config.split(" ")[0].split("").length;

          if (numOfPanels === 2) {
            qty = 2;
            // qtyDD = 1;
          } else if (numOfPanels === 3) {
            qty = 1;
            qtyDD = 2;
          } else if (numOfPanels === 4) {
            qty = 2;
            qtyDD = 2;
          }
        }
      }
    } else {
      if (!isXX) {
        const adder = isDouble ? 2 : 1;
        qty = config.split("-")[0].replace(/['^O^P']/g, "").length * adder;
      } else {
        qty = config.split(" ")[0].split("").length;
      }
    }
  }

  return {
    qty: qty * Number(quantity),
    qtyDD: qtyDD * Number(quantity),
    qtyFixed: qtyFixed * Number(quantity),
  };
}
