export default function findHingePlateImg(
  hardware: string,
  color: string,
  dropseal: string,
) {
  if (!hardware) return "";

  const url = "/Hinge_Plate";
  const hardwareLabel = hardware === "Fritsjurgens" ? "Frits" : "Dorma";
  let colorLabel = color === "Black" ? "Black" : "SS";
  let dropsealLabel = "";

  if (hardware === "Fritsjurgens" && dropseal) {
    colorLabel = "SS";
    dropsealLabel = "_dropseal";
  }

  return `${url}/${hardwareLabel}_${colorLabel}${dropsealLabel}`;
}
