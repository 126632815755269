import React, { FC } from "react";
import { TableCell, IconButton } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import GenerateDealer from "../../../../../quotePDF/GenerateDealer";
import { HtmlTooltip } from "../StatusTooltip";
import { QuoteObj } from "../../../../../GlobalTypes";
import { CutsheetDataObj, UserObj, Overwrite } from "../../../../../GlobalTypes";

type AdminQuoteObj = Overwrite<QuoteObj, { userId: UserObj }>;

interface ProductionPdfProps {
  row: AdminQuoteObj;
  handleViewPdf:
    | ((id: string, userId: string, quoteStatus: string) => void)
    | null;
  viewPdfId: string;
  loadingCutsheet: boolean;
  cutsheetData: CutsheetDataObj[];
}

const ProductionPdf: FC<ProductionPdfProps> = ({
  row,
  handleViewPdf,
  viewPdfId,
  loadingCutsheet,
  cutsheetData,
}) => {
  return (
    <TableCell padding="checkbox" align="center" style={{ width: 100 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {row.units && row.units.length > 0 && viewPdfId !== `${row._id}prod` ? (
          <HtmlTooltip title={"Download Production PDF"}>
            <IconButton
              onClick={() =>
                handleViewPdf && handleViewPdf(row._id, row.userId._id || "", "prod")
              }
              aria-label="download"
              size="large"
            >
              <GetAppIcon color={"primary"} />
            </IconButton>
          </HtmlTooltip>
        ) : row.units &&
          row.units.length > 0 &&
          viewPdfId === `${row._id}prod` &&
          loadingCutsheet ? (
          <div>Fetching...</div>
        ) : row.units &&
          row.units.length > 0 &&
          viewPdfId === `${row._id}prod` &&
          !loadingCutsheet ? (
          <GenerateDealer
            data={row as QuoteObj}
            series={row.seriesOverride || "Default"}
            isTable={true}
            isOrder={false}
            codOrTerms={
              "codOrTerms" in row.userId && row.userId?.codOrTerms
                ? row.userId.codOrTerms
                : ""
            }
            poNumber={row.poNumber || ""}
            region={
              "region" in row.userId && row.userId?.region
                ? row.userId.region
                : ""
            }
            dealerTax={
              "dealerTax" in row.userId && row.userId?.dealerTax
                ? row.userId.dealerTax
                : 0
            }
            factor={row.factor}
            grandTotal={row.grandTotal || "N/A"}
            suggestedRetail={row.suggestedRetailPrice || "N/A"}
            isProduction={true}
            salesOrder={row.salesOrder || ""}
            cutsheetData={cutsheetData}
          />
        ) : !row.units || !row.units.length ? (
          <HtmlTooltip title={"No Units in Quote"}>
            <IconButton aria-label="download" size="large">
              <GetAppIcon />
            </IconButton>
          </HtmlTooltip>
        ) : null}
      </div>
    </TableCell>
  );
};

export default ProductionPdf;
