import React, { memo, FC } from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { UnitObj } from "../util/quoteCartStorage";

const StyledTypography = styled(Typography)({
  display: "inline",
  fontFamily: "Arial",
});

function sizeFormat(num: string, fraction: string) {
  if (fraction) {
    return ` ${num} ${fraction}"`;
  }

  return ` ${num}"`;
}

interface QuoteListProps {
  text: UnitObj;
  checked: boolean;
}

const QuoteList: FC<QuoteListProps> = ({ text, checked }) => {
  return (
    <>
      {text.location && (
        <ListItem label="Location:" label2={` ${text.location}`} />
      )}
      <ListItem
        label={"Width:"}
        label2={
          text.doorType.includes("Corner")
            ? ` L-${sizeFormat(
                text.unitSize,
                text.unitSizeFraction || "",
              )} R-${sizeFormat(
                text.cornerWidthRight || "",
                text.cornerWidthFractionRight || "",
              )}`
            : sizeFormat(text.unitSize, text.unitSizeFraction || "")
        }
      />
      <ListItem
        label={"Height:"}
        label2={sizeFormat(text.height, text.heightFraction || "")}
      />
      {text.doorType === "Fixed Panel" ? (
        <ListItem label={"Configuration:"} label2={" " + text.exactConfig} />
      ) : text.doorType.includes("Bi-fold") ? (
        <ListItem
          label={"Configuration:"}
          label2={
            text.doorType.includes("Corner")
              ? " " +
                text.cornerType +
                " " +
                text.exactConfig +
                " " +
                text.cornerConfigRight
              : text.exactConfig
                ? " " + text.exactConfig
                : " " + text.configuration
          }
        />
      ) : text.doorType === "Slider" ? (
        <ListItem
          label={"Configuration:"}
          label2={
            text.exactConfig && " " + text.exactConfig.replace("Right", "R")
          }
        />
      ) : text.doorType === "Pivot" ? (
        <ListItem label={"Configuration:"} label2={` ${text.exactConfig}`} />
      ) : (
        <ListItem
          label={"Configuration:"}
          label2={` ${text.exactConfig.split(" ")[1]} ${text.exactConfig
            .split(" ")
            .splice(-1, 1)}`}
        />
      )}
      <ListItem label={"Wood:"} label2={` ${text.woodAbbrev}`} />
      {checked && (
        <>
          <StyledTypography
            style={{ marginLeft: 25, fontWeight: "bold" }}
            variant="body2"
            color="textPrimary"
          >
            Price:
          </StyledTypography>
          {` $${text.price}`}
        </>
      )}
    </>
  );
};

interface ListItemProps {
  label: string;
  label2: string;
}

const ListItem: FC<ListItemProps> = ({ label, label2 }) => {
  return (
    <>
      <StyledTypography
        style={{ marginLeft: 35 }}
        variant="body2"
        color="textPrimary"
      >
        {label}
      </StyledTypography>
      {label2}
      <br />
    </>
  );
};

export default memo(QuoteList);
