import React, { memo } from "react";
import {
  Typography,
  Paper,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Grow,
  SelectChangeEvent,
} from "@mui/material";
import ReusableAccordian from "./ReusableAccordion";
import { useStore } from "../store/store";
import { useAccordion } from "../store/accordion";
import { RowLayout } from "../util/accordianStyles";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)({
  "&:hover": {
    cursor: "pointer",
  },
  marginLeft: 80,
  height: 300,
});

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  width: 160,
}));

const StyledSelect = styled(Select)({
  backgroundColor: "white",
  width: 170,
});

const CLOUDINARY_URL = "https://res.cloudinary.com/ag-millworks/image/upload";

const configImg = [
  {
    type: "Fixed Bi-fold",
    url: `${CLOUDINARY_URL}/v1692036783/Fixed_Panel_Configs/bifold.jpg`,
  },
  {
    type: "Fixed Slider",
    url: `${CLOUDINARY_URL}/v1692036785/Fixed_Panel_Configs/slider.jpg`,
  },
  {
    type: "Fixed Full Bound",
    url: `${CLOUDINARY_URL}/v1692036781/Fixed_Panel_Configs/fullbound.jpg`,
  },
  {
    type: "Fixed Inswing",
    url: `${CLOUDINARY_URL}/v1692377138/Fixed_Panel_Configs/inswing.jpg`,
  },
  {
    type: "Fixed Outswing",
    url: `${CLOUDINARY_URL}/v1692377136/Fixed_Panel_Configs/outswing.jpg`,
  },
];

interface UpdateObj {
  exactConfig: string;
  sillStyle: string;
  jamb: string;
  exactConfigImg?: string;
}

const FPConfigurationAccordian = () => {
  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[4].expanded);

  const addToQuote = useStore((state) => state.addToQuote);
  const exactConfig = useStore((state) => state.quote.exactConfig);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    let update: UpdateObj = {
      exactConfig: value as string,
      sillStyle: "",
      jamb: "",
    };

    if (value) {
      const url = configImg.find((itm) => itm.type === value)?.url;
      update["exactConfigImg"] = url;
    }

    addToQuote(update);
  };

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="configuration"
      heading="Configuration"
      secondaryHeading="Select a configuration"
      required={false}
      required5={true}
    >
      <RowLayout>
        <Typography style={{ paddingRight: 20 }}>
          Type of Fixed Panel:
        </Typography>

        <StyledFormControl required variant="outlined">
          <InputLabel>Configuration</InputLabel>

          <StyledSelect
            value={exactConfig}
            onChange={handleChange}
            name="configuration"
            label="Configuration"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="Fixed Full Bound">Fixed Full Bound</MenuItem>
            <MenuItem value="Fixed Bi-fold">Fixed Bi-fold</MenuItem>
            <MenuItem value="Fixed Slider">Fixed Slider</MenuItem>
            <MenuItem value="Fixed Outswing">Fixed Outswing</MenuItem>
            <MenuItem value="Fixed Inswing">Fixed Inswing</MenuItem>
          </StyledSelect>
        </StyledFormControl>

        {exactConfig && (
          <Grow in={true}>
            <StyledPaper elevation={3}>
              <img
                src={configImg.find((itm) => itm.type === exactConfig)?.url}
                alt="config door"
              />
            </StyledPaper>
          </Grow>
        )}
      </RowLayout>
    </ReusableAccordian>
  );
};

export default memo(FPConfigurationAccordian);
