import React, { useState, FC } from "react";
import getModalStyle from "../../../util/getModalStyle";
import { Typography, Button, Modal } from "@mui/material";
import { StyledModalDiv } from "../../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const StyledDivCol = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  padding: 20,
});

interface PoModalProps {
  open: boolean;
  handleClose: () => void;
  handlePoChangeModal: (e: React.ChangeEvent<HTMLInputElement>) => void;
  po: string;
  disableChanges: boolean;
}

const PoModal: FC<PoModalProps> = ({
  open,
  handleClose,
  handlePoChangeModal,
  po,
  disableChanges,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const body = (
    <StyledModalDiv style={modalStyle}>
      <StyledDivCol>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: 20,
          }}
        >
          <Typography style={{ fontWeight: "bold" }}>PO #:</Typography>
          <input
            maxLength={20}
            style={{ width: 150, marginLeft: 10 }}
            value={po}
            onChange={(e) => handlePoChangeModal(e)}
            name="po"
            type="text"
            placeholder="PO #"
            disabled={disableChanges}
          />
        </div>
      </StyledDivCol>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={handleClose} variant="contained" color="primary">
          Ok
        </Button>
      </div>
    </StyledModalDiv>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

export default PoModal;
