import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const StyledDivImg = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-evenly",
  width: "100%",
  [theme.breakpoints.down("xl")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const StyledDivImg2 = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: 20,
});

const StyledDivCol = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "white",
  boxShadow: "4px 4px 8px #888888",
  width: "100%",
});

const StyledDivWrap = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  marginTop: 10,
  [theme.breakpoints.down("xl")]: {
    marginTop: 40,
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: 10,
  [theme.breakpoints.down("lg")]: {
    fontSize: 20,
    fontWeight: "bold",
  },
}));

const StyledImg = styled("img")({
  width: 220,
  height: 220,
  border: "1px solid grey",
});

const StyledTypographyBlue = styled(Typography)({
  color: "blue",
  paddingTop: 10,
});

const CLOUDINARY_URL =
  "https://res.cloudinary.com/ag-millworks/image/upload/dealerZoneStiles";

const stilesInfo = [
  {
    src: `${CLOUDINARY_URL}/slim_dzone.png`,
    alt: "slimline image",
    title: "SLIMLINE | Ultra-Modern",
    body1: '2-1/2" Stiles & Rails',
    body2: 'with 3-7/16" Lock Stile',
  },
  {
    src: `${CLOUDINARY_URL}/euro_dzone.png`,
    alt: "euro image",
    title: "EURO | Contemporary",
    body1: '3-7/16" Stiles & Rails',
    body2: "",
  },
  {
    src: `${CLOUDINARY_URL}/trad_dzone.png`,
    alt: "traditional image",
    title: "TRADITIONAL | Classic",
    body1: '4-3/4" Top Rail & Stiles',
    body2: 'with 7" Bottom Rail option',
  },
];

const StilesImgBanner = () => {
  return (
    <StyledDivWrap>
      <StyledDivCol>
        <StyledTypography variant="h4">
          Industry Leading Stiles and Rails
        </StyledTypography>
        <StyledDivImg>
          {stilesInfo.map((item) => (
            <StyledDivImg2 key={item.alt}>
              <StyledImg src={item.src} alt={item.alt} />
              <StyledTypographyBlue variant="body1">
                {item.title}
              </StyledTypographyBlue>

              <Typography variant="body2">{item.body1}</Typography>
              <Typography variant="body2">{item.body2}</Typography>
            </StyledDivImg2>
          ))}
        </StyledDivImg>
      </StyledDivCol>
    </StyledDivWrap>
  );
};

export default StilesImgBanner;
