import { create } from "zustand";
import { persist } from "zustand/middleware";
import { initialExpand, InitialExpandItm } from "../initialValues/index";

interface AccordionState {
  expanded: InitialExpandItm[];
  setExpandedFromEdit: (unitExpanded: InitialExpandItm[]) => void;
  handleAccordClick: (name: string) => void;
  handleMinimize: () => void;
}

export const useAccordion = create<AccordionState>()(
  persist(
    (set) => ({
      expanded: JSON.parse(JSON.stringify(initialExpand)),
      setExpandedFromEdit: (unitExpanded: InitialExpandItm[]) =>
        set((state: AccordionState) => ({
          ...state,
          expanded: unitExpanded[33]
            ? unitExpanded
            : [
                ...unitExpanded,
                { name: "kerf", expanded: true, require: false },
              ],
        })),
      handleAccordClick: (name: string) =>
        set((state: AccordionState) => ({
          ...state,
          expanded: state.expanded.map((accord: InitialExpandItm) =>
            accord.name === name
              ? { ...accord, expanded: !accord.expanded }
              : { ...accord }
          ),
        })),
      handleMinimize: () =>
        set((state: AccordionState) => ({
          ...state,
          expanded: JSON.parse(JSON.stringify(initialExpand)),
        })),
    }),
    {
      name: "accordionControl",
      partialize: (state: AccordionState) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) =>
              ![
                "handleMinimize",
                "handleAccordClick",
                "setExpandedFromEdit",
              ].includes(key)
          )
        ),
    }
  )
);
