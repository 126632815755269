import React, { FC } from "react";
import { Typography, Hidden, Divider } from "@mui/material";
import ItemizedCosts from "./ItemizedCosts";
import sortUnits from "../../util/sortUnits";
import letters from "../../util/letters";
import getCornerImgWidth from "../../util/getCornerImgWidth";
import "../CartCheck/CartCheck.css";
import noAccess from "../../util/noAccess";
import { QuoteObj } from "../../GlobalTypes";
import { styled } from "@mui/material/styles";

const StyledWrapDiv = styled("div")({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  flexBasis: "65%",
  border: "2px solid grey",
  maxHeight: "60vh",
  marginRight: 20,
  backgroundColor: "white",
});

const StyledColDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingLeft: 5,
});

const StyledColDiv2 = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const StyledRowDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledColDivEnd = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  flexDirection: "column",
  position: "relative",
});

const StyledColHidden = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  position: "absolute",
  bottom: 0,
  width: "100%",
  backgroundColor: "white",
  zIndex: 2,
  borderTop: "1px solid lightgrey",
});
const StyledHeadDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  borderBottom: "1px solid #e0e0e0",
  [theme.breakpoints.down("lg")]: {
    display: "none",
    borderBottom: "none",
  },
}));

const StyledCartLayoutDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: 10,
  paddingLeft: 20,
  paddingRight: 50,
  paddingTop: 10,
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    paddingBottom: 30,
  },
}));

const StyledOrderUnits = styled("div")({
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  paddingBottom: 15,
});

const StyledSummaryDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const StyledDivqp = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  flexBasis: "30%",
  [theme.breakpoints.down("lg")]: {
    paddingTop: 40,
  },
}));

interface DoorListProps {
  signInRole: string;
  otsm: boolean;
  quote: QuoteObj;
}

const DoorList: FC<DoorListProps> = ({ signInRole, otsm, quote }) => {
  const orderedUnits = sortUnits(quote.units || []);

  const hasDealerAccess =
    signInRole === "Dealer" ||
    signInRole === "Manager" ||
    signInRole === "Company Manager";

  return (
    <StyledWrapDiv
      style={{
        height: !hasDealerAccess && signInRole !== "Admin" ? "60vh" : "",
      }}
    >
      <StyledHeadDiv>
        <Typography
          style={{
            flexBasis: !otsm || !hasDealerAccess ? "15%" : "21%",
          }}
          variant="body2"
        >
          Quantity
        </Typography>
        {(!otsm || !hasDealerAccess) && (
          <Typography style={{ flexBasis: "15%" }} variant="body2">
            Price
          </Typography>
        )}
      </StyledHeadDiv>
      <StyledOrderUnits className="scroll">
        {orderedUnits?.map((door, index) => (
          <div key={door._id}>
            <StyledCartLayoutDiv>
              <StyledSummaryDiv>
                <Hidden xlDown>
                  <Typography style={{ paddingRight: 10 }} variant="body2">
                    {letters[index]}
                  </Typography>
                </Hidden>

                <Hidden mdDown>
                  <StyledRowDiv style={{ minWidth: 300, paddingRight: 10 }}>
                    {door.doorType.includes("Corner") ? (
                      <StyledRowDiv>
                        <img
                          style={{
                            width:
                              Number(door.numberOfPanels) >= 10
                                ? getCornerImgWidth(90, door.exactConfig)
                                : getCornerImgWidth(115, door.exactConfig),
                            height:
                              Number(door.numberOfPanels) >= 10 ? 90 : 115,
                            paddingRight: 10,
                          }}
                          src={door.exactConfigImg}
                          alt=""
                        />
                        <img
                          style={{
                            width:
                              Number(door.numberOfPanels) >= 10
                                ? getCornerImgWidth(
                                    90,
                                    door.cornerConfigRight || "",
                                  )
                                : getCornerImgWidth(
                                    115,
                                    door.cornerConfigRight || "",
                                  ),
                            height:
                              Number(door.numberOfPanels) >= 10 ? 90 : 115,
                          }}
                          src={door.exactConfigImgRightCorner}
                          alt=""
                        />
                      </StyledRowDiv>
                    ) : door.doorType === "Fixed Panel" ? (
                      <div style={{ paddingRight: 80, paddingLeft: 60 }}>
                        <img
                          style={{
                            width: 70,
                            height: 150,
                            paddingRight: 10,
                            marginLeft: 40,
                          }}
                          src={door.exactConfigImg}
                          alt=""
                        />
                      </div>
                    ) : (
                      <img
                        style={{
                          width: 250,
                          height:
                            door.doorType === "Bi-fold Window" ? 100 : 150,
                        }}
                        src={door.exactConfigImg}
                        alt=""
                      />
                    )}
                  </StyledRowDiv>
                </Hidden>
                <Hidden smUp>
                  <StyledRowDiv style={{ minWidth: 200 }}>
                    {door.doorType.includes("Corner") ? (
                      <StyledRowDiv>
                        <img
                          style={{
                            width: getCornerImgWidth(100, door.exactConfig),
                            height: 100,
                          }}
                          src={door.exactConfigImg}
                          alt=""
                        />
                        <img
                          style={{
                            width: getCornerImgWidth(
                              100,
                              door.cornerConfigRight || "",
                            ),
                            height: 100,
                          }}
                          src={door.exactConfigImgRightCorner}
                          alt=""
                        />
                      </StyledRowDiv>
                    ) : door.doorType === "Fixed Panel" ? (
                      <img
                        style={{
                          width: 55,
                          height: 120,
                          paddingRight: 10,
                          marginLeft: 40,
                        }}
                        src={door.exactConfigImg}
                        alt=""
                      />
                    ) : (
                      <img
                        style={{
                          width: 200,
                          height: door.doorType === "Bi-fold Window" ? 80 : 120,
                        }}
                        src={door.exactConfigImg}
                        alt=""
                      />
                    )}
                  </StyledRowDiv>
                </Hidden>

                <StyledColDiv>
                  {door.location && (
                    <Typography variant="body2">
                      <span style={{ fontWeight: "bold" }}>Location: </span>
                      {door.location}
                    </Typography>
                  )}
                  {door.doorType.includes("Corner") ? (
                    <StyledColDiv>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          borderBottom: "1px dashed black",
                        }}
                        variant="body2"
                      >
                        Bi-fold {door.cornerType} Corner
                      </Typography>

                      <Typography
                        style={{
                          fontWeight: "bold",
                        }}
                        variant="body2"
                      >
                        L: {door.exactConfig} {door.unitSize}
                        {!door.unitSizeFraction
                          ? '" '
                          : ` ${door.unitSizeFraction}"`}{" "}
                        R: {door.cornerConfigRight} {door.cornerWidthRight}
                        {!door.cornerWidthFractionRight
                          ? '" '
                          : ` ${door.cornerWidthFractionRight}"`}
                        X {door.height}
                        {!door.heightFraction
                          ? '" '
                          : ` ${door.heightFraction}"`}
                      </Typography>
                    </StyledColDiv>
                  ) : door.doorType === "Pivot" ? (
                    <Typography
                      style={{
                        fontWeight: "bold",
                        borderBottom: "1px dashed black",
                      }}
                      variant="body2"
                    >
                      Pivot - {door.exactConfig.replace("Pivot", "Inswing")}{" "}
                      {door.unitSize}
                      {!door.unitSizeFraction
                        ? '" '
                        : ` ${door.unitSizeFraction}"`}
                      X {door.height}
                      {!door.heightFraction ? '" ' : ` ${door.heightFraction}"`}
                    </Typography>
                  ) : (
                    <Typography
                      style={{
                        fontWeight: "bold",
                        borderBottom: "1px dashed black",
                      }}
                      variant="body2"
                    >
                      {door.doorType} - {door.exactConfig} {door.unitSize}
                      {!door.unitSizeFraction
                        ? '" '
                        : ` ${door.unitSizeFraction}"`}
                      X {door.height}
                      {!door.heightFraction ? '" ' : ` ${door.heightFraction}"`}
                      {(noAccess.includes(door.exactConfig) ||
                        door.doorType === "Bi-fold Window") && (
                        <>
                          {" "}
                          <br />{" "}
                          <span style={{ color: "red", fontSize: 12 }}>
                            *No access from exterior
                          </span>{" "}
                        </>
                      )}
                    </Typography>
                  )}
                  <Typography variant="body2">{door.material}</Typography>
                  <Typography variant="body2">
                    Wood Species -{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {door.woodAbbrev}
                    </span>
                  </Typography>
                  {door.material !== "All Wood" && (
                    <Typography variant="body2">
                      Clad Color -{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {door.exteriorCladColor?.includes("Custom")
                          ? "Custom Color"
                          : door.exteriorCladColor}
                      </span>
                    </Typography>
                  )}
                </StyledColDiv>
              </StyledSummaryDiv>
              <StyledDivqp>
                <Typography variant="body2">
                  <Hidden lgUp>
                    <Typography
                      style={{ display: "inline", fontWeight: "bold" }}
                      variant="body2"
                    >
                      QTY:{" "}
                    </Typography>
                  </Hidden>
                  {door.quantity || "1"}
                </Typography>
                {(!otsm || !hasDealerAccess) && (
                  <>
                    <StyledColDiv2>
                      <StyledColDivEnd>
                        <Typography variant="body2">
                          <Hidden lgUp>
                            <Typography
                              style={{
                                display: "inline",
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              Price:{" "}
                            </Typography>
                          </Hidden>
                          ${door.price}
                        </Typography>
                        {door.itemizedAddons?.length ? (
                          <ItemizedCosts
                            quantity={door.quantity}
                            data={door.itemizedAddons}
                            unitPrice={door.price}
                          />
                        ) : null}
                      </StyledColDivEnd>

                      {door.quantity > 1 && (
                        <Typography variant="body2">
                          (${door.DUPforEach} ea.)
                        </Typography>
                      )}
                    </StyledColDiv2>
                  </>
                )}
              </StyledDivqp>
            </StyledCartLayoutDiv>
            <Divider />
          </div>
        ))}
      </StyledOrderUnits>
      <StyledColHidden>
        <Hidden mdDown>
          <Typography style={{ fontWeight: "bold" }} variant="caption">
            (* As Viewed From Exterior)
          </Typography>
        </Hidden>

        <Hidden mdDown>
          <Typography style={{ fontWeight: "bold" }} variant="caption">
            Total Number of Units: {quote.totalUnits}
          </Typography>
          <Typography style={{ fontWeight: "bold" }} variant="caption">
            Total Number of Doors: {quote.totalPanels}
          </Typography>
        </Hidden>

        <Hidden smUp>
          <Typography style={{ fontWeight: "bold" }} variant="caption">
            # of Units: {quote.totalUnits}
          </Typography>
          <Typography style={{ fontWeight: "bold" }} variant="caption">
            # of Doors: {quote.totalPanels}
          </Typography>
        </Hidden>
      </StyledColHidden>
    </StyledWrapDiv>
  );
};

export default DoorList;
