import React, { FC } from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { convertDecimalToFraction } from "../../util/pdfFormulas/convertDecimalToFraction";
import fractionToInt from "../../util/fractionToInt";
import "../fontRegister";

const styles = StyleSheet.create({
  table: {
    // display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 2,
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader1: {
    width: "50%",
    borderStyle: "solid",
    borderColor: "black",
    borderBottomColor: "black",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: "auto",
    padding: 2,
    fontSize: 8,
    fontWeight: 500,
  },
  tableCellHeaderBold: {
    margin: "auto",
    fontFamily: "Roboto-Bold",
    fontWeight: "bold",
    padding: 2,
    fontSize: 8,
  },
  body: {
    padding: 10,
    width: 180,
  },
});

interface PivotAxisProps {
  axis: string;
  panelWidth: string;
  panelWidthFraction: string;
}

const PivotAxis: FC<PivotAxisProps> = ({
  axis,
  panelWidth,
  panelWidthFraction,
}) => {
  const pivotAxis = axis?.includes("Middle")
    ? convertDecimalToFraction(
        fractionToInt(panelWidth, panelWidthFraction) / 2,
      )
    : axis.replace(" Pivot Axis Point", "");

  return (
    <View style={styles.body}>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader1}>
            <Text style={styles.tableCellHeaderBold}>PIVOT AXIS (X)</Text>
          </View>

          <View style={styles.tableColHeader1}>
            <Text style={styles.tableCellHeader}>{pivotAxis}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default PivotAxis;
