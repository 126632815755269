const swingDirection = [
  { id: 1, type: "swingDirection", label: "Outswing" },
  { id: 2, type: "swingDirection", label: "Inswing" },
];

const swingType = [
  { id: 1, type: "swingType", label: "Single Swing" },
  { id: 2, type: "swingType", label: "Pair Swing" },
];

const sideLite = [
  { id: 1, type: "sideLite", label: "Add Single Sidelite" },
  { id: 2, type: "sideLite", label: "Add Double Sidelite" },
];

const pairSideLite = [
  { id: 1, type: "sideLite", label: "Add Double Sidelite" },
];

export { pairSideLite, sideLite, swingDirection, swingType };
