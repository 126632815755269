const CLOUDINARY_URL =
  "https://res.cloudinary.com/ag-millworks/image/upload/CutSheets";

function headJambImagesBifold(
  doorMaterial: string,
  screenType: string,
  screen: string,
) {
  const door = "BI-FOLD";

  const material = doorMaterial.includes("Clad") ? "_CLAD" : "_WOOD";

  const screenLabel = !screen
    ? ""
    : screenType?.includes("Centor")
      ? "_CENTOR"
      : "_SCREEN";

  const cloudinaryID = `${door}${material}${screenLabel}`.trim();
  return `${CLOUDINARY_URL}/headJamb/${cloudinaryID}`;
}

function sillImagesBifold(
  sill: string,
  screenType: string,
  screen: string,
  stile: string,
) {
  const door = "BI-FOLD";

  const material = "_CLAD";

  const sillLabel =
    sill === "Rounded Dam"
      ? "_ALUM_ROUND"
      : sill === "Squared Dam"
        ? "_ALUM_SQUARE"
        : sill === "Ultra Guide"
          ? "_UG"
          : sill === "ADA Ultra Guide"
            ? "_UG_ADA"
            : "";

  const screenLabel = !screen
    ? ""
    : screenType?.includes("Centor")
      ? "_CENTOR"
      : "_SCREEN";
  const tradLbl =
    sill.includes("Dam") &&
    stile.includes("Bottom") &&
    !screenType?.includes("Centor")
      ? "_TRAD"
      : "";

  const cloudinaryID =
    `${door}${material}${tradLbl}${sillLabel}${screenLabel}`.trim();

  return `${CLOUDINARY_URL}/sill/${cloudinaryID}`;
}

function jambLegImagesBifold(
  doorMaterial: string,
  screenType: string,
  screen: string,
  screenLayout: string,
  screenPocket: string,
) {
  const door = "BI-FOLD";

  const material = doorMaterial.includes("Clad") ? "_CLAD" : "_WOOD";

  const screenLabel = !screen
    ? ""
    : screenType?.includes("Centor")
      ? "_CENTOR"
      : screen &&
          screen.includes("Double") &&
          screenPocket &&
          screenPocket.includes("Double")
        ? "_SCREEN_PKT_DBL"
        : screen && !screenPocket
          ? "_SCREEN"
          : screen &&
              screenLayout &&
              screenLayout.includes("Left") &&
              screenPocket
            ? "_SCREEN_PKT_LEFT"
            : screen &&
                screenLayout &&
                screenLayout.includes("Right") &&
                screenPocket
              ? "_SCREEN_PKT_RIGHT"
              : "";

  const cloudinaryID = `${door}${material}${screenLabel}`.trim();

  return `${CLOUDINARY_URL}/jamb/${cloudinaryID}.jpg`;
}

function jambLegImagesCorner(
  cornerType: string,
  material: string,
  primaryDoor: string,
  cornerSide: string,
) {
  const materialLbl = material.includes("Clad") ? "CLAD" : "WOOD";

  const primary = `PRIMARY_${primaryDoor.toUpperCase()}`;

  const letter = cornerSide === "left" ? "A" : "B";

  const cloudinaryID =
    `${cornerType}_${materialLbl}_${primary}_${letter}`.trim();

  return `${CLOUDINARY_URL}/jamb/BifoldCorners/${cloudinaryID}.jpg`;
}

export {
  headJambImagesBifold,
  sillImagesBifold,
  jambLegImagesBifold,
  jambLegImagesCorner,
};
