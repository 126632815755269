import React, { Dispatch, SetStateAction, FC } from "react";
import Choices from "../../Choices";
import { useStore } from "../../../store/store";
import { sliderPocket } from "./defaults";
import { styled } from "@mui/material/styles";

const StyledDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

interface SliderPocketProps {
  setSliderConfig: Dispatch<SetStateAction<string>>;
}

const SliderPocket: FC<SliderPocketProps> = ({ setSliderConfig }) => {
  const addToQuote = useStore((state) => state.addToQuote);

  const sliderP = useStore((state) => state.quote.sliderPocket);

  const handleError = useStore((state) => state.handleError);

  const handleClick = (label: string) => {
    const checked = sliderP === label;

    if (checked) return;

    addToQuote({
      sliderPocket: label,
      exactConfig: "",
    });
    setSliderConfig("");
    handleError("primaryDoorErr", "");
  };

  return (
    <StyledDiv>
      {sliderPocket.map((item) => (
        <div key={item.id} onClick={() => handleClick(item.label)}>
          <Choices
            label={item.label}
            variant={item.label === sliderP ? "filled" : "outlined"}
            checkMark={item.label === sliderP}
          />
        </div>
      ))}
    </StyledDiv>
  );
};

export default SliderPocket;
