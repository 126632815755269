import React, { useState, FC } from "react";
import getModalStyle from "../../util/getModalStyle";
import {
  Select,
  Button,
  Modal,
  Typography,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { RowLayout, ColLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";
import { PaintLeadTimesObj } from "./LeadTimes";

interface ChooseLeadTimeProps {
  region: string;
  state: PaintLeadTimesObj;
  handleChange: (e: SelectChangeEvent<string>) => void;
}

interface SelectorProps extends ChooseLeadTimeProps {
  kind: string;
}

interface PaintModalProps {
  open: boolean;
  handleClose: () => void;
  handleChange: (e: SelectChangeEvent<string>) => void;
  handleSubmit: () => void;
  state: PaintLeadTimesObj;
}

const StyledModalDiv = styled("div")(({ theme }) => ({
  position: "absolute",
  width: 500,
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  textAlign: "center",
}));

const StyledRowDiv = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  paddingTop: 20,
});

const Selector: FC<SelectorProps> = ({ region, kind, state, handleChange }) => {
  const nums = Array.from(Array(21).keys());

  const key = `${region}${kind}`;

  return (
    <RowLayout>
      <Select
        size="small"
        name={key}
        value={state[key as keyof PaintLeadTimesObj] || ""}
        onChange={handleChange}
      >
        {nums.map((i) => (
          <MenuItem key={i} value={String(i)}>
            {i} Weeks
          </MenuItem>
        ))}
      </Select>
    </RowLayout>
  );
};

const ChooseLeadTime: FC<ChooseLeadTimeProps> = ({
  region,
  handleChange,
  state,
}) => {
  return (
    <ColLayout>
      <Typography style={{ fontWeight: "bold", paddingBottom: 10 }}>
        {region === "socal" ? "SoCal" : "NorCal"}
      </Typography>

      <Selector
        region={region}
        kind="Prime"
        state={state}
        handleChange={handleChange}
      />

      <Selector
        region={region}
        kind="Paint"
        state={state}
        handleChange={handleChange}
      />

      <Selector
        region={region}
        kind="Clad"
        state={state}
        handleChange={handleChange}
      />
    </ColLayout>
  );
};

const PaintLeadTimesModal: FC<PaintModalProps> = ({
  open,
  handleClose,
  handleChange,
  handleSubmit,
  state,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const body = (
    <StyledModalDiv style={modalStyle}>
      <Typography variant="h6" style={{ fontWeight: "bold" }}>
        Update Paint Lead Times
      </Typography>

      <Typography variant="caption">
        *adds additional weeks to lead time
      </Typography>

      <RowLayout style={{ justifyContent: "space-around" }}>
        <ColLayout
          style={{
            height: 220,
            alignSelf: "flex-end",
            justifyContent: "space-around",
            alignItems: "flex-end",
          }}
        >
          <div />

          <RowLayout>
            <Typography>Prime:</Typography>
          </RowLayout>

          <RowLayout>
            <Typography>Paint/Stain:</Typography>
          </RowLayout>

          <RowLayout>
            <Typography>Custom Clad:</Typography>
          </RowLayout>
        </ColLayout>

        <ChooseLeadTime
          region="socal"
          handleChange={handleChange}
          state={state}
        />

        <ChooseLeadTime
          region="norcal"
          handleChange={handleChange}
          state={state}
        />
      </RowLayout>

      <StyledRowDiv>
        <Button onClick={handleClose} color="inherit" variant="contained">
          Cancel
        </Button>

        <Button onClick={handleSubmit} variant="contained" color="primary">
          Confirm
        </Button>
      </StyledRowDiv>
    </StyledModalDiv>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

export default PaintLeadTimesModal;
