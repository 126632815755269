import React, { useState, FC, Dispatch, SetStateAction } from "react";
import { Typography, Fab } from "@mui/material";
import AdminDealerSearch from "../../AdminDealerSearch";
import ContactsIcon from "@mui/icons-material/Contacts";
import { AdminDealer, CustomerInfoState } from "./ServiceRepair";
import { UserObj } from "../../../GlobalTypes";
import { styled } from "@mui/material/styles";

const StyledInfoDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "column",
  backgroundColor: "#fafafa",
  marginTop: 10,
  border: "2px solid lightgrey",
  borderRadius: "10px",
  padding: 20,
  flexBasis: "40%",
  position: "relative",
});

const StyledInvoiceDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

const StyledInput = styled("input")({
  width: 50,
  marginLeft: 5,
});

const StyledRedSpan = styled("span")({
  color: "red",
  fontSize: 22,
});

const StyledBoldTypography = styled(Typography)({
  fontWeight: "bold",
});

const StyledBoldPadTypography = styled(Typography)({
  fontWeight: "bold",
  paddingRight: 5,
});

const StyledDealerDiv = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
});

const StyledFabDiv = styled("div")({
  position: "absolute",
  top: 20,
  right: 20,
});

interface DealerInfoProps {
  adminDealer: AdminDealer;
  handleChangeAdmin: (e: React.ChangeEvent<HTMLInputElement>) => void;
  info: CustomerInfoState;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  data: UserObj & { signInRole: string };
  isFetching: boolean;
  repairStatus: string;
  disabled: boolean;
  setAdminDealer: Dispatch<SetStateAction<AdminDealer>>;
}

const DealerInfo: FC<DealerInfoProps> = ({
  adminDealer,
  handleChangeAdmin,
  info,
  handleChange,
  data,
  isFetching,
  repairStatus,
  disabled,
  setAdminDealer,
}) => {
  const hasDealerAccess =
    data.role === "Dealer" ||
    data.role === "Manager" ||
    data.role === "Company Manager";

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const populateDealerFields = (repair: AdminDealer) => {
    setAdminDealer({
      dealer: repair?.dealer,
      dealerRep: repair.dealerRep,
      email: repair.email,
      phone: repair.phone,
    });
    handleClose();
  };

  return (
    <StyledInfoDiv>
      {(data.signInRole === "Admin" ||
        data.signInRole === "Inside Sales" ||
        data.signInRole === "Outside Sales") && (
        <>
          <AdminDealerSearch
            open={open}
            handleClose={handleClose}
            adminFillFields={populateDealerFields}
          />

          <StyledFabDiv>
            <Fab color="primary" onClick={handleOpen} size="small">
              <ContactsIcon />
            </Fab>
          </StyledFabDiv>
        </>
      )}

      <StyledBoldTypography variant="h6" style={{ paddingBottom: 10 }}>
        Dealer Info:
      </StyledBoldTypography>
      <StyledDealerDiv>
        <StyledBoldPadTypography>
          Dealer: <StyledRedSpan>*</StyledRedSpan>
        </StyledBoldPadTypography>

        {hasDealerAccess ? (
          <input
            value={!isFetching ? data.dealer : ""}
            disabled={true}
            style={{ width: 250 }}
          />
        ) : (
          <input
            value={adminDealer.dealer}
            name="dealer"
            onChange={handleChangeAdmin}
            disabled={disabled}
            style={{ width: 250 }}
          />
        )}
      </StyledDealerDiv>
      <StyledDealerDiv>
        <StyledBoldPadTypography>
          Dealer Contact Name: <StyledRedSpan>*</StyledRedSpan>
        </StyledBoldPadTypography>

        {hasDealerAccess ? (
          <input value={!isFetching ? data.dealerRep : ""} disabled={true} />
        ) : (
          <input
            value={adminDealer.dealerRep}
            name="dealerRep"
            onChange={handleChangeAdmin}
            disabled={disabled}
          />
        )}
      </StyledDealerDiv>
      <StyledDealerDiv>
        <StyledBoldPadTypography>
          Phone Number: <StyledRedSpan>*</StyledRedSpan>
        </StyledBoldPadTypography>

        {hasDealerAccess ? (
          <input value={!isFetching ? data.phone : ""} disabled={true} />
        ) : (
          <input
            value={adminDealer.phone}
            name="phone"
            onChange={handleChangeAdmin}
            disabled={disabled}
          />
        )}
      </StyledDealerDiv>
      <StyledDealerDiv>
        <StyledBoldPadTypography>
          Email:{" "}
          {data.role !== "Admin" && data.role !== "Inside Sales" && (
            <StyledRedSpan>*</StyledRedSpan>
          )}
        </StyledBoldPadTypography>
        {hasDealerAccess ? (
          <input
            value={!isFetching ? data.email : ""}
            disabled={true}
            style={{ width: 250 }}
          />
        ) : (
          <input
            value={adminDealer.email}
            name="email"
            onChange={handleChangeAdmin}
            disabled={disabled}
            style={{ width: 250 }}
          />
        )}
      </StyledDealerDiv>

      <StyledBoldTypography>
        PO Number: {!info.invoiceNumber && <StyledRedSpan>*</StyledRedSpan>}
        <StyledInput
          disabled={disabled}
          name="poNumber"
          value={info.poNumber}
          style={{ width: 80 }}
          onChange={handleChange}
        />
      </StyledBoldTypography>

      <StyledInvoiceDiv>
        <StyledBoldTypography>
          AG's INVOICE Number:{" "}
          {!info.poNumber && <StyledRedSpan>*</StyledRedSpan>}
          <StyledInput
            disabled={disabled}
            name="invoiceNumber"
            value={info.invoiceNumber}
            onChange={handleChange}
          />
        </StyledBoldTypography>
        <StyledBoldTypography>
          INVOICE Date: {!info.poNumber && <StyledRedSpan>*</StyledRedSpan>}
          <input
            disabled={disabled}
            type="date"
            name="invoiceDate"
            value={info.invoiceDate}
            style={{ width: 130, marginLeft: 5 }}
            onChange={handleChange}
          />
        </StyledBoldTypography>
      </StyledInvoiceDiv>
      <StyledInvoiceDiv>
        <StyledBoldTypography>
          Unit Line # on Order with Issue: <StyledRedSpan>*</StyledRedSpan>
          <StyledInput
            disabled={disabled}
            name="unitLineNumber"
            value={info.unitLineNumber}
            onChange={handleChange}
          />
        </StyledBoldTypography>

        {repairStatus && (
          <StyledBoldTypography>
            Service SO #:{" "}
            <StyledInput
              name="soNumber"
              value={info.soNumber}
              onChange={handleChange}
              disabled={
                repairStatus === "Completed"
                  ? true
                  : data.signInRole === "Admin" ||
                      data.signInRole === "Inside Sales"
                    ? false
                    : true
              }
              style={{ width: 130, marginLeft: 5 }}
            />
          </StyledBoldTypography>
        )}
      </StyledInvoiceDiv>
    </StyledInfoDiv>
  );
};

export default DealerInfo;
