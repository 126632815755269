const kerfChoices = {
  id: 1,
  type: "kerf",
  label: "Add Kerf",
};

const options = [
  {
    id: 1,
    type: "kerfConfig",
    label: 'Standard 3/8" Setback',
  },
  {
    id: 2,
    type: "kerfConfig",
    label: "Custom Setback",
  },
];

export { kerfChoices, options };
