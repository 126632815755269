import React, { useState, FC } from "react";
import {
  Button,
  Modal,
  Select,
  MenuItem,
  Typography,
  TextField,
  SelectChangeEvent,
} from "@mui/material";
import getModalStyle from "../../../../util/getModalStyle";
import { UseMutationResult } from "react-query";
import {
  StyledModalDiv,
  RowLayout,
  ColLayout,
} from "../../../../util/accordianStyles";

interface ArchiveModalProps {
  handleClose: () => void;
  open: boolean;
  mutation: UseMutationResult<void, unknown, void, unknown>;
  archiveReason: string;
  handleChange: (e: SelectChangeEvent<string>) => void;
  status: string;
  archiveReasonTextBox: string;
  handleChangeTextBox: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}

const ArchiveModal: FC<ArchiveModalProps> = ({
  handleClose,
  open,
  mutation,
  archiveReason,
  handleChange,
  status,
  archiveReasonTextBox,
  handleChangeTextBox,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if(event.key === "Enter"){
      return mutation.mutate()
    }
  }

  const body = (
    <StyledModalDiv style={modalStyle} onKeyDown={handleKeyDown}>
      {status === "Quote" ? (
        <Typography style={{ fontWeight: "bold" }} variant="h6">
          Would you like to archive the selected quotes?
        </Typography>
      ) : (
        <Typography style={{ fontWeight: "bold" }} variant="h6">
          Would you like to un-archive the selected quotes?
        </Typography>
      )}

      {status === "Quote" && (
        <ColLayout style={{ padding: 20 }}>
          <Typography style={{ paddingBottom: 20 }}>
            Help us improve by letting us know the reason for archiving the
            selected quotes
          </Typography>

          <Select
            value={archiveReason}
            onChange={handleChange}
            size="small"
            style={{ minWidth: 150 }}
            onKeyDown={e => e.preventDefault()}
          >
            <MenuItem value="Lost job to competitor">
              Lost job to competitor
            </MenuItem>
            <MenuItem value="Price too high">Price too high</MenuItem>
            <MenuItem value="Customer no longer looking to purchase">
              Customer no longer looking to purchase
            </MenuItem>
            <MenuItem value="Project on hold">Project on hold</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>

          {(archiveReason === "Other" ||
            archiveReason === "Lost job to competitor") && (
            <RowLayout
              style={{
                justifyContent: "flex-start",
                width: "100%",
                marginLeft: -40,
              }}
            >
              <Typography>Please Specify: </Typography>

              <TextField
                size="small"
                value={archiveReasonTextBox}
                onChange={handleChangeTextBox}
                variant="outlined"
                style={{ margin: 10, maxWidth: 150 }}
              />
            </RowLayout>
          )}
        </ColLayout>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: 20,
        }}
      >
        <Button
          onClick={handleClose}
          size="small"
          variant="contained"
          color="inherit"
        >
          Cancel
        </Button>
        <Button
          onClick={() => mutation.mutate()}
          size="small"
          variant="contained"
          color="primary"
          disabled={
            mutation.isLoading || (!archiveReason && status === "Quote")
              ? true
              : false
          }
        >
          Confirm
        </Button>
      </div>
    </StyledModalDiv>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

export default ArchiveModal;
