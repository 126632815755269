function bifoldWindowCloudinaryImg(
  config: string,
  primaryDoor: string,
  isProd: boolean,
) {
  const baseURL = "https://res.cloudinary.com/ag-millworks/image/upload/pdfDrawings";

  const specifyPrimary = ["3L1R", "1L3R", "5L1R", "1L5R", "3L3R"];

  const primaryAbbrev =
    primaryDoor === "Left" ? "_LH" : primaryDoor === "Right" ? "_RH" : "";

  const primary = specifyPrimary.includes(config) ? primaryAbbrev : "";

  const ev = `${baseURL}/elevationView/bifoldWindows/${config}.jpg`;
  const pv = !isProd
    ? `${baseURL}/planView/bifoldWindows/${config}${primary}.png`
    : `${baseURL}/planView/bifolds/production/${config}${primary}_PLAN.png`;

  return {
    ev,
    pv,
  };
}

export default bifoldWindowCloudinaryImg;
