import React, { useEffect, memo, useState, useMemo, FC } from "react";
import Typography from "@mui/material/Typography";
import ReusableAccordian from "../ReusableAccordion";
import { useStore } from "../../store/store";
import { useAccordion } from "../../store/accordion";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import JambInput from "./JambInput";
import JambOptions from "./JambOptions";
import changeOldInswingToCustom from "./changeOldInswingToCustom";
import {
  jambWithScreen,
  jambWithScreenKerf,
  jambWithScreenSwing,
  jambInswing,
  jambNoScreen,
  jambNoScreenKerf,
  jambFixedSlider,
  jambCentorScreen,
  jambCentorScreenKerf,
  jambSwingCentorScreen,
} from "./defaults";
import { RowLayout } from "../../util/accordianStyles";

const oldInswingWood = [
  '5-1/8" Overall Jamb Width',
  '6-1/16" Overall Jamb Width',
  '7" Overall Jamb Width',
  '7-15/16" Overall Jamb Width',
];

interface JambProps {
  doorType: string;
}

const JambAccordian: FC<JambProps> = ({ doorType }) => {
  const addToQuote = useStore((state) => state.addToQuote);

  const jamb = useStore((state) => state.quote.jamb);

  const exactConfig = useStore((state) => state.quote.exactConfig);
  const direction = useStore((state) => state.quote.swingDirection);
  const screenType = useStore((state) => state.quote.screenType);
  const hasScreen = useStore((state) => state.quote.addScreen);
  const hasKerf = useStore((state) => state.quote.kerf);
  const material = useStore((state) => state.quote.material);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleErrMsgChange = useStore((state) => state.handleError);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[27].expanded);

  const [showCustomInput, setShowCustom] = useState(false);

  const fractionValueJamb =
    !jamb || jamb.includes("Overall") || !jamb.split(" ")[1]
      ? ""
      : jamb.split(" ")[1];

  useEffect(() => {
    if (jamb && (!jamb.includes("Overall") || jamb.includes("Custom"))) {
      setShowCustom(true);
    }

    if (doorType !== "Swing" || direction !== "Inswing") return;

    if (
      material === "Clad Exterior/Wood Interior" ||
      !oldInswingWood.includes(jamb || "")
    )
      return;

    const num = changeOldInswingToCustom(jamb || "");
    addToQuote({ jamb: num }, "jamb");
  }, [jamb]);

  useEffect(() => {
    if (doorType !== "Swing" && exactConfig !== "Fixed Inswing") return;

    if (material === "Clad Exterior/Wood Interior") {
      setShowCustom(false);
    }
  }, [doorType, material]);

  const handleClick = (label: string, type: string) => {
    const checked = label === jamb;

    if (checked) {
      if (label.includes("Custom")) {
        setShowCustom(false);
        handleErrMsgChange("jambErr", "");
      }
      addToQuote({ jamb: "" }, "jamb");
    } else {
      addToQuote({ jamb: label }, "jamb");

      if (label.includes("Custom")) {
        material === "All Wood" && setShowCustom(true);
        type !== "inswing" && handleErrMsgChange("jambErr", "");
      } else {
        setShowCustom(false);
        handleErrMsgChange("jambErr", "");
      }

      if (findRequired("Jamb Width")) {
        if (!label.includes("Custom")) {
          removeRequired("Jamb Width", "Custom Jamb Width");
        } else {
          removeRequired("Jamb Width");
        }
      }
    }
  };

  const variant = (label: string): "filled" | "outlined" => {
    var fill: "filled" | "outlined" = "outlined";

    if (label.includes("Custom")) {
      if (showCustomInput) {
        fill = "filled";
      } else {
        fill = "outlined";
      }
    } else {
      if (label === jamb) fill = "filled";
      else fill = "outlined";
    }

    return fill;
  };

  const checkMark = (label: string) => {
    var fill = false;

    if (label.includes("Custom")) {
      if (showCustomInput) fill = true;
      else fill = false;
    } else {
      if (label === jamb) fill = true;
      else fill = false;
    }

    return fill;
  };

  let type = !hasScreen ? "noscreen" : "withscreen";

  if (
    ((doorType === "Swing" && direction === "Inswing") ||
      (doorType === "Fixed Panel" && exactConfig === "Fixed Inswing")) &&
    material === "Clad Exterior/Wood Interior"
  ) {
    type = "inswing";
  }

  const options = useMemo(() => {
    if (doorType === "Swing") {
      if (
        direction === "Inswing" &&
        material === "Clad Exterior/Wood Interior"
      ) {
        return jambInswing;
      }

      if (screenType === "Centor Flat Screen/Shade" && hasScreen) {
        return jambSwingCentorScreen;
      }

      return !hasScreen ? jambNoScreen : jambWithScreenSwing;
    }

    if (
      doorType === "Fixed Panel" &&
      exactConfig === "Fixed Inswing" &&
      material === "Clad Exterior/Wood Interior"
    ) {
      return jambInswing;
    }

    if (doorType === "Fixed Panel" && exactConfig === "Fixed Slider") {
      return jambFixedSlider;
    }

    if (screenType === "Centor Flat Screen/Shade" && hasScreen) {
      return !hasKerf ? jambCentorScreen : jambCentorScreenKerf;
    }

    if (hasKerf) {
      return !hasScreen ? jambNoScreenKerf : jambWithScreenKerf;
    }

    return !hasScreen ? jambNoScreen : jambWithScreen;
  }, [doorType, direction, exactConfig, material, hasScreen, hasKerf]);

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="jamb"
      heading="Overall Jamb Width"
      secondaryHeading="Select jamb width (inches)"
    >
      {doorType === "Swing" && !direction && (
        <RowLayout>
          <Typography>
            Note: Select a Swing Direction to view Jamb Options
          </Typography>
        </RowLayout>
      )}

      <RowLayout>
        <JambOptions
          options={options}
          handleClick={handleClick}
          type={type}
          variant={variant}
          checkMark={checkMark}
        />

        {showCustomInput && (
          <JambInput
            doorType={doorType}
            hasScreen={hasScreen ? true : false}
            screenType={screenType || ""}
            fraction={fractionValueJamb}
            jamb={
              jamb?.includes("Custom")
                ? ""
                : jamb?.split(" ")[0]
                  ? jamb.split(" ")[0]
                  : ""
            }
            hasKerf={hasKerf ? true : false}
          />
        )}
      </RowLayout>
    </ReusableAccordian>
  );
};

export default memo(JambAccordian);
