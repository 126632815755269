import React, { FC } from "react";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import UnitDetails from "./pdfComponents/UnitDetails";
import Header from "./pdfComponents/Header";
import { loopUnits } from "./DealerCost";
import sortUnits from "../util/sortUnits";
import { Image } from "cloudinary-react";
import { Button, Typography } from "@mui/material";
import { QuoteObj } from "../GlobalTypes";
import { styled } from "@mui/material/styles";

const StyledDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  justifyContent: "space-around",
  alignItems: "center",
  marginTop: 100,
  paddingTop: 20,
  paddingBottom: 20,
});

const StyledTypography = styled(Typography)({
  fontWeight: "bold",
  padding: 20,
  paddingBottom: 40,
});

const StyledCloudinaryImage = styled(Image)({
  width: 300,
  height: 100,
  paddingBottom: 20,
});

interface DetailsProps {
  data: QuoteObj;
}

const Details: FC<DetailsProps> = ({ data }) => {
  const withSalesOrder = data.salesOrder || "";

  const { bifoldSeries2 } = loopUnits(data.hingeSeries || 0, data.units || []);

  const orderedUnits = sortUnits(data.units || []);

  return (
    <Document title="Shipping Label">
      {orderedUnits && orderedUnits.length
        ? orderedUnits.map((door, index) => (
            <>
              <Page key={index}>
                <Header
                  data={data}
                  customerOrDealer="DEALER"
                  fixed={false}
                  title="ORDER"
                  salesOrder={withSalesOrder}
                />

                <UnitDetails
                  form={data}
                  series={data.seriesOverride || ""}
                  door={door}
                  index={index}
                  bifoldSeries2={bifoldSeries2}
                  isCustomer={false}
                />
              </Page>
            </>
          ))
        : ""}
    </Document>
  );
};

const GenerateShipLabels: FC<DetailsProps> = ({ data }) => {
  return (
    <StyledDiv>
      <StyledCloudinaryImage
        cloudName="ag-millworks"
        secure="true"
        publicId="AGlogoBlack"
      />

      <StyledTypography variant="h6">
        Click Below for Unit Details
      </StyledTypography>

      <PDFDownloadLink
        document={<Details data={data} />}
        fileName="details.pdf"
        style={{ textDecoration: "none" }}
      >
        <Button variant="contained" color="primary" size="large">
          View
        </Button>
      </PDFDownloadLink>
    </StyledDiv>
  );
};

export default GenerateShipLabels;
