const sliderPocket = [
  {
    id: 1,
    type: "sliderPocket",
    label: "With Pocket",
  },
  {
    id: 2,
    type: "sliderPocket",
    label: "No Pocket",
  },
];

export { sliderPocket };
