import React, { memo, FC } from "react";
import Badge from "@mui/material/Badge";
import { ShoppingCart } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const StyledBadge = styled(Badge)({
  position: "absolute",
  right: 20,
  top: 20,
});

interface CartBadgeProps {
  numOfItems: number;
}

const CartBadge: FC<CartBadgeProps> = ({ numOfItems }) => {
  return (
    <StyledBadge badgeContent={numOfItems} color="primary">
      <ShoppingCart />
    </StyledBadge>
  );
};

export default memo(CartBadge);
