export function findStopsImage(
  door: string,
  material: string,
  isSDL: boolean,
  cladSdlShape: string,
  woodInt: string,
  woodExt: string,
  liteConfig: string,
): string {
  if (door === "Pivot" && !isSDL) {
    const liteLbl = liteConfig === '5 1/4"' ? "5.25" : "2.5";
    const materialLbl = material.includes("Clad") ? "CLAD" : "WOOD";
    const stopLbl =
      materialLbl === "CLAD"
        ? cladSdlShape.toUpperCase()
        : `${woodInt.toUpperCase()}_${woodExt.toUpperCase()}`;

    return `Intermediate_Rail/${liteLbl}_${materialLbl}_${stopLbl}`;
  }

  if (material.includes("Clad") && cladSdlShape) {
    return `SDL/${cladSdlShape.toLowerCase()}sdl`;
  }

  if (material === "All Wood" && woodInt && woodExt) {
    if (isSDL) {
      return `SDL/wood${woodInt}${woodExt}`;
    } else {
      return `TDL/${woodInt.toLowerCase()}${woodExt}`;
    }
  }

  return "";
}
