import React, { useEffect, useCallback, FC } from "react";
import HandleAccordian from "./Handle/HandleAccordian";
import WoodAccordian from "./Wood/WoodAccordian";
import ExteriorCladAccordian from "./ExteriorClad/ExteriorCladAccordian";
import UnitSizeAccordian from "./UnitSizeAccordian";
import ConfigurationAccordian from "./ConfigurationAccordian";
import QuantityAccordian from "./QuantityAccordian";
import SillAccordian from "./Sill/SillAccordian";
import SwingDirectionAccordian from "./SwingConfiguration/SwingDirection/SwingDirectionAccordian";
import GlassAccordian from "./Glass/GlassAccordian";
import ShootBoltAccordian from "./Shootbolt/ShootBoltAccordian";
import LiteAccordian from "./lites/LiteAccordian";
import StilesRailsAccordian from "./StilesRails/StilesRailsAccordian";
import MaterialAccordian from "./Material/MaterialAccordian";
import HeadTrackColorAccordian from "./HeadTrack/HeadTrackColorAccordian";
import FinAccordian from "./Fin/FinAccordian";
import HingeAccordian from "./Hinge/HingeAccordian";
import StickStopsAccordian from "./StickStops/StickStopsAccordian";
import PaintPrimeAccordian from "./PrimePaint/PrimePaintAccordian";
import StainAccordian from "./Stain/StainAccordian";
import StripAccordian from "./Strip/StripAccordian";
import SpacerAccordian from "./Spacer/SpacerAccordian";
import HeightAccordian from "./HeightAccordian";
import HandleHeightAccordian from "./HandleHeight/HandleHeightAccordian";
import JambAccordian from "./Jamb/JambAccordian";
import { animateScroll as scroll, Element } from "react-scroll";
import Location from "./Location";
import AddNotes from "./AddNotes";
import usePersistedState from "../hooks/usePersistedState";
import SliderConfigAccordian from "./Sliders/SliderConfigAccordian";
import SliderWidthAccordian from "./Sliders/SliderWidthAccordian";
import AutomationAccordian from "./Sliders/Automation/AutomationAccordian";
import SliderScreenAccordian from "./Sliders/SliderScreen/SliderScreenAccordian";
import SliderSillAccordian from "./Sliders/SliderSill/SliderSillAccordian";
import ConfiguratorBtns from "./ConfiguratorBtns";
import RequiredMsgLayout from "./RequiredMsgLayout";
import ErrorMsgs from "./ErrorMsgs";
import KerfAccordian from "./Kerf/KerfAccordian";
import BifoldCornerAccordian from "./Corner/BifoldCornerAccordian";
import BifoldCornerConfigAccordian from "./Corner/BifoldCornerConfigAccordian";
import CornerUnitSizeAccordian from "./Corner/CornerUnitSizeAccordian";
import FPConfigurationAccordian from "./FPConfigurationAccordian";
import PivotConfigAccordian from "./PivotConfig/PivotConfigAccordian";
import FPWidthAccordian from "./FPWidthAccordian";
import FPSillAccordian from "./FPSillAccordian";
import PivotPointAccordian from "./PivotPoint/PivotPointAccordian";
import PivotHardwareAccordian from "./PivotHardware/PivotHardwareAccordian";
import PivotMagnetAccordian from "./PivotMagnet/PivotMagnetAccordian";
import PivotBackPlateAccordian from "./PivotBackPlate/PivotBackPlateAccordian";
import PivotSillAccordian from "./PivotSill/PivotSillAccordian";
import PivotHandleAccordian from "./PivotHandle/PivotHandleAccordian";
import PivotDropsealAccordian from "./Dropseal/PivotDropsealAccordian";
import BrioOrCentorAccordian from "./ScreenType/BrioOrCentorAccordian";
import { useStore } from "../store/store";

interface AccordianWrapperProps {
  resetValues: () => void;
  doorType: string;
  editQuote: boolean;
  submitPressed: boolean;
  addDoorToSidebar: (withCheck?: boolean, ignoreUnitCheck?: boolean) => void;
}

const AccordianWrapper: FC<AccordianWrapperProps> = ({
  resetValues,
  doorType,
  editQuote,
  submitPressed,
  addDoorToSidebar,
}) => {
  const materialChoice = useStore((state) => state.quote.material);

  const editBtn = useStore((state) => state.isEdit);

  const exactConfig = useStore((state) => state.quote.exactConfig);
  const sliderP = useStore((state) => state.quote.sliderPocket);
  const sliderPocketing = sliderP === "With Pocket";

  const woodSpecies = useStore((state) => state.quote.woodSpecies);
  const paintOptionShown = !woodSpecies
    ? ""
    : woodSpecies && woodSpecies.includes("Paint-Grade")
      ? "paint"
      : "stain";

  const [isHandleHeightShown, setIsHandleHeightShown] = usePersistedState(
    "isHandleHeightShown",
    false,
    submitPressed,
    editQuote,
  );

  const [hideHandles, setHideHandles] = usePersistedState(
    "hideHandles",
    false,
    submitPressed,
    editQuote,
  );

  const [sliderConfig, setSliderConfig] = usePersistedState(
    "sliderConfig",
    "",
    submitPressed,
    editQuote,
  );

  const isExtCladShown = materialChoice?.includes("Clad") ? true : false;

  useEffect(() => {
    if (!submitPressed) return;
    scroll.scrollToTop();
  }, [submitPressed]);

  const showHandleHeight = useCallback((isHeightOver9ft: boolean) => {
    setIsHandleHeightShown(isHeightOver9ft);
  }, []);

  const hideHandlesWithEvenConfigs = useCallback(
    (isEvenConfigs: boolean) => setHideHandles(isEvenConfigs),
    [],
  );

  return (
    <div style={{ width: "100%" }}>
      {editBtn && <RequiredMsgLayout />}

      <Location editQuote={editQuote} submitPressed={submitPressed} />

      {doorType === "Bi-fold Zero Corner" && <BifoldCornerAccordian />}

      <MaterialAccordian />

      <WoodAccordian doorType={doorType} />

      {doorType === "Swing" && (
        <SwingDirectionAccordian
          editQuote={editQuote}
          submitPressed={submitPressed}
        />
      )}

      {(doorType === "Bi-fold" || doorType === "Bi-fold Window") && (
        <UnitSizeAccordian
          bifoldType={doorType?.includes("Window") ? "window" : "door"}
        />
      )}

      {doorType === "Fixed Panel" && <FPWidthAccordian />}

      {doorType === "Bi-fold Zero Corner" && <CornerUnitSizeAccordian />}

      {doorType === "Pivot" && (
        <PivotConfigAccordian
          editQuote={editQuote}
          submitPressed={submitPressed}
        />
      )}

      {doorType === "Slider" && (
        <SliderWidthAccordian setSliderConfig={setSliderConfig} />
      )}

      <HeightAccordian
        doorType={doorType}
        showHandleHeight={showHandleHeight}
      />

      {(doorType === "Bi-fold" || doorType === "Bi-fold Window") && (
        <ConfigurationAccordian
          bifoldType={doorType?.includes("Window") ? "window" : "door"}
          editQuote={editQuote}
          hideHandlesWithEvenConfigs={hideHandlesWithEvenConfigs}
        />
      )}

      {doorType === "Bi-fold Zero Corner" && (
        <BifoldCornerConfigAccordian
          hideHandlesWithEvenConfigs={hideHandlesWithEvenConfigs}
        />
      )}

      {doorType === "Fixed Panel" && <FPConfigurationAccordian />}

      {doorType === "Slider" && (
        <SliderConfigAccordian
          sliderConfig={sliderConfig}
          setSliderConfig={setSliderConfig}
          editQuote={editQuote}
          submitPressed={submitPressed}
        />
      )}

      {doorType === "Pivot" && <PivotHardwareAccordian />}

      <QuantityAccordian />

      <Element name="Exterior Clad Color" />
      <Element name="Custom Clad Color" />

      {isExtCladShown && <ExteriorCladAccordian />}

      <Element name="Stiles and Rails" />

      {materialChoice && (
        <StilesRailsAccordian
          doorType={doorType}
          evenConfig={doorType === "Fixed Panel" ? true : hideHandles}
        />
      )}

      <Element name="Pivot Point" />

      {doorType === "Pivot" && <PivotPointAccordian />}

      <Element name="Paint Color" />

      <Element name="Custom Paint Color" />

      {paintOptionShown === "paint" && <PaintPrimeAccordian />}

      <Element name="Stain Color" />

      {paintOptionShown && paintOptionShown !== "paint" && <StainAccordian />}

      <Element name="Head Track Color" />

      {(doorType?.includes("Bi-fold") ||
        (doorType === "Fixed Panel" && exactConfig === "Fixed Bi-fold")) && (
        <HeadTrackColorAccordian />
      )}

      <Element name="Weather Strip Color" />

      {doorType !== "Fixed Panel" && <StripAccordian />}

      <Element name="Handle Style" />
      <Element name="Handle Finish" />
      <Element name="Handle Key option" />
      <Element name="Handle Set" />

      <Element name="Back Plate" />
      <Element name="Back Plate Finish" />

      {!hideHandles &&
        doorType !== "Bi-fold Window" &&
        doorType !== "Fixed Panel" &&
        doorType !== "Pivot" && <HandleAccordian doorType={doorType} />}

      {doorType === "Pivot" && (
        <>
          <PivotHandleAccordian />
          <PivotBackPlateAccordian />
          <PivotDropsealAccordian />
        </>
      )}

      <Element name="Handle Height" />
      <Element name="Thumb Turn Height" />

      {isHandleHeightShown && doorType !== "Fixed Panel" && (
        <HandleHeightAccordian doorType={doorType} />
      )}

      <Element name="Shoot Bolt Color" />

      {doorType?.includes("Bi-fold") && <ShootBoltAccordian />}

      <Element name="Exterior Stop Profile" />
      <Element name="Interior Stop Profile" />
      <Element name="Stickings and Stops Profile" />

      <StickStopsAccordian />

      <Element name="Hinge Color" />

      {doorType !== "Slider" && doorType !== "Fixed Panel" && (
        <HingeAccordian doorType={doorType} />
      )}

      <Element name="Divided Lites Size" />

      {materialChoice && <LiteAccordian doorType={doorType} />}

      <Element name="Daylight Opening" />
      <Element name="Divided Lites Configuration" />

      <Element name="Sidelite Daylight Opening" />
      <Element name="Sidelite Divided Lites Configuration" />

      <Element name="IG Spacer Color" />

      <SpacerAccordian />

      {doorType === "Slider" ? (
        <SliderScreenAccordian />
      ) : doorType !== "Fixed Panel" && doorType !== "Pivot" ? (
        <BrioOrCentorAccordian
          doorType={doorType}
          editQuote={editQuote}
          submitPressed={submitPressed}
        />
      ) : null}

      <Element name="Sill Finish" />
      <Element name="Sill Dam Finish" />
      <Element name="select either Rounded or Squared Sill" />
      <Element name="Sill Type" />

      {doorType === "Fixed Panel" &&
      exactConfig === "Fixed Full Bound" ? null : doorType === "Pivot" ? (
        <PivotSillAccordian />
      ) : doorType !== "Slider" && doorType !== "Fixed Panel" ? (
        <SillAccordian doorType={doorType} />
      ) : doorType === "Fixed Panel" ? (
        <FPSillAccordian />
      ) : (
        <SliderSillAccordian
          editQuote={editQuote}
          submitPressed={submitPressed}
        />
      )}

      <Element name="Magnet Finish" />

      {doorType === "Pivot" && <PivotMagnetAccordian />}

      <Element name="Glass Surface Side" />

      <GlassAccordian />

      <Element name="Kerf Custom Setback" />
      <Element name="Kerf Setback" />
      <KerfAccordian />

      <Element name="Custom Jamb Width" />
      <Element name="Jamb Width" />
      {doorType !== "Slider" && <JambAccordian doorType={doorType} />}

      <Element name="Flashing Fin Custom Setback" />
      <Element name="Flashing Fin Setback" />
      {!sliderPocketing && <FinAccordian />}

      {doorType === "Slider" && <AutomationAccordian />}

      <AddNotes editQuote={editQuote} submitPressed={submitPressed} />

      <ErrorMsgs />

      <ConfiguratorBtns
        resetValues={resetValues}
        addDoorToSidebar={addDoorToSidebar}
      />
    </div>
  );
};

export default AccordianWrapper;
