import React, { useState, FC, Dispatch, SetStateAction } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Menu,
  MenuItem,
  Switch,
  IconButton,
  FormGroup,
  FormControlLabel,
  Select,
  Typography,
  Divider,
  FormControl,
  Tooltip,
  SelectChangeEvent,
} from "@mui/material";
import {
  postToggle,
  postToggleMarkup,
  postHideCustomer,
  postSeriesOverride,
} from "../../api/OrderSummaryApi";
import { styled } from "@mui/material/styles";

const StyledDivCol = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "&:hover": {
    background: "none",
  },
});

const StyledMenuItemCol = styled(MenuItem)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "&:hover": {
    background: "none",
  },
});

const StyledDivColText = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: 2,
});

const StyledMenuItemMarginCol = styled(MenuItem)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: 10,
  marginTop: 10,
  "&:hover": {
    background: "none",
  },
});

const StyledMenuItem = styled(MenuItem)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledDivSeries = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

type SetStateString = Dispatch<SetStateAction<string>>;

type SetStateBool = Dispatch<SetStateAction<boolean>>;

interface SettingsProps {
  setChangesMade: SetStateBool;
  marginInput: string;
  setViewPdf: SetStateBool;
  fixedMargin: string;
  fixedMarkup: string;
  setMarginInput: SetStateString;
  setOverride: SetStateBool;
  override: boolean;
  setHideCustomer: SetStateString;
  hideCustomer: string;
  quoteId: string;
  otsm: boolean;
  markupChecked: boolean;
  series: string;
  setSeries: SetStateString;
}

const Settings: FC<SettingsProps> = ({
  setChangesMade,
  marginInput,
  setViewPdf,
  fixedMargin,
  fixedMarkup,
  setMarginInput,
  setOverride,
  override,
  setHideCustomer,
  hideCustomer,
  quoteId,
  otsm,
  markupChecked,
  series,
  setSeries,
}) => {
  const role = JSON.parse(sessionStorage.getItem("role") || JSON.stringify(""));

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.ChangeEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setOverride(checked);

    if (markupChecked) {
      if (!checked && fixedMarkup && marginInput !== fixedMarkup) {
        setMarginInput(fixedMarkup);
        setChangesMade(true);
        setViewPdf(false);
      }
      await postToggleMarkup(checked, quoteId);
    } else {
      if (!checked && fixedMargin && marginInput !== fixedMargin) {
        setMarginInput(fixedMargin);
        setChangesMade(true);
        setViewPdf(false);
      }
      await postToggle(checked, quoteId);
    }
  };

  const handleChange = async (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setHideCustomer(value);
    setViewPdf(false);

    const dbValue = value === "Show All" ? "" : value;
    await postHideCustomer(dbValue, quoteId);
  };

  const handleChangeSeries = async (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setSeries(value);
    setViewPdf(false);

    await postSeriesOverride(value, quoteId);
  };

  const switchText =
    fixedMargin && !markupChecked
      ? "Margin"
      : fixedMarkup && markupChecked
        ? "Markup"
        : "Margin";

  return (
    <div style={{ marginBottom: 10 }}>
      <Tooltip title="Customer PDF Settings" placement="top">
        <IconButton onClick={handleClick} color="primary" size="large">
          <SettingsIcon />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <StyledMenuItemCol style={{ marginBottom: 10 }}>
          <StyledDivCol>
            <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
              Customer PDF Pricing Options
            </Typography>

            <StyledDivColText>
              <Typography variant="subtitle1" style={{ fontSize: 15 }}>
                These options will only affect the Customer PDF on the Customer
              </Typography>
              <Typography variant="subtitle1" style={{ fontSize: 15 }}>
                Summary page.
              </Typography>
            </StyledDivColText>

            <FormControl size="small">
              <Select
                value={hideCustomer}
                onChange={handleChange}
                variant="outlined"
                margin="dense"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  // getContentAnchorEl: null,
                }}
              >
                <StyledMenuItem value="Show All">
                  Show Unit and Additional Options Pricing
                </StyledMenuItem>
                <StyledMenuItem value="Hide Itemized Pricing">
                  Only Show Unit Pricing
                </StyledMenuItem>
                <StyledMenuItem value="Hide All Pricing">
                  Remove All Pricing
                </StyledMenuItem>
                <StyledMenuItem value="Hide All Measurements">
                  Hide All Measurements
                </StyledMenuItem>
              </Select>
            </FormControl>
          </StyledDivCol>
        </StyledMenuItemCol>

        <StyledDivCol style={{ marginBottom: 10 }}>
          <StyledDivColText>
            <Typography style={{ fontSize: 14 }} variant="subtitle2">
              <span style={{ fontStyle: "italic", fontSize: 14 }}>
                "Show Unit and Additional Options Pricing"{" "}
              </span>
              will show all pricing
            </Typography>
            <Typography style={{ fontSize: 14 }} variant="subtitle2">
              breakdowns. (Default)
            </Typography>
          </StyledDivColText>

          <StyledDivColText>
            <Typography variant="subtitle2" style={{ fontSize: 14 }}>
              <span style={{ fontStyle: "italic", fontSize: 14 }}>
                "Only Show Unit Pricing"{" "}
              </span>
              will remove pricing for any additional
            </Typography>
            <Typography style={{ fontSize: 14 }} variant="subtitle2">
              options.
            </Typography>
          </StyledDivColText>

          <StyledDivColText>
            <Typography variant="subtitle2" style={{ fontSize: 14 }}>
              <span style={{ fontStyle: "italic", fontSize: 14 }}>
                "Remove All Pricing"{" "}
              </span>
              will remove unit totals, additional option costs,
            </Typography>
            <Typography style={{ fontSize: 14 }} variant="subtitle2">
              and grand totals.
            </Typography>
          </StyledDivColText>

          <StyledDivColText>
            <Typography style={{ fontSize: 14 }} variant="subtitle2">
              <span style={{ fontStyle: "italic", fontSize: 14 }}>
                "Hide All Measurements"{" "}
              </span>
              will remove all unit dimensions
            </Typography>
          </StyledDivColText>
        </StyledDivCol>

        {otsm || (!fixedMargin && !fixedMarkup) ? null : (
          <>
            <Divider />
            <StyledMenuItemMarginCol>
              <StyledDivCol>
                <Typography
                  style={{ fontWeight: "bold", fontSize: 18, margin: 5 }}
                >
                  Fixed {switchText} Override
                </Typography>

                <Typography
                  variant="subtitle2"
                  style={{ margin: 5, fontSize: 14 }}
                >
                  Override your {switchText.toLowerCase()} for this particular
                  quote.
                </Typography>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={override}
                        onChange={handleToggle}
                      />
                    }
                    label={`Override Fixed ${switchText}`}
                  />
                </FormGroup>
              </StyledDivCol>
            </StyledMenuItemMarginCol>
          </>
        )}

        {role === "Admin" && (
          <StyledDivSeries>
            <Typography
              variant="subtitle1"
              style={{ fontSize: 15, paddingRight: 40, fontWeight: "bold" }}
            >
              Series Override:{" "}
            </Typography>

            <Select
              value={series}
              onChange={handleChangeSeries}
              variant="outlined"
              margin="dense"
              size="small"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                // getContentAnchorEl: null
              }}
            >
              <StyledMenuItem value="Default">Default</StyledMenuItem>
              <StyledMenuItem value="Series 1">Series 1</StyledMenuItem>
              <StyledMenuItem value="Series 2">Series 2</StyledMenuItem>
            </Select>
          </StyledDivSeries>
        )}
      </Menu>
    </div>
  );
};

export default Settings;
