import React, { FC } from "react";
import { Button } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { styled } from "@mui/material/styles";

const StyledDivRow = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "50%",
});

const StyledButtonUpdate = styled(Button)({
  color: "white",
  marginBottom: 60,
  paddingTop: -20,
});

const StyledButtonReturn = styled(Button)({
  color: "white",
  marginBottom: 60,
  paddingTop: -20,
  marginLeft: 20,
});

interface UpdateQuoteBtnProps {
  isBtnDisabled: boolean;
  handleUpdateQuote: () => void;
  returnToConfig: () => void;
}

const UpdateQuoteBtn: FC<UpdateQuoteBtnProps> = ({
  isBtnDisabled,
  handleUpdateQuote,
  returnToConfig,
}) => {
  return (
    <StyledDivRow>
      <StyledButtonUpdate
        disabled={isBtnDisabled}
        onClick={handleUpdateQuote}
        color="primary"
        size="large"
        variant="contained"
      >
        Update Quote
      </StyledButtonUpdate>
      <StyledButtonReturn
        onClick={returnToConfig}
        size="large"
        color="customGrey"
        variant="contained"
      >
        Return to Configurator{" "}
        <NavigateNextIcon style={{ display: "inline" }} />
      </StyledButtonReturn>
    </StyledDivRow>
  );
};

export default UpdateQuoteBtn;
