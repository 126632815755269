import React, { useState, useEffect, FC } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Select,
  Divider,
  Typography,
  Checkbox,
  FormControlLabel,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useStore } from "../store/store";
import { Image, Transformation } from "cloudinary-react";
import { FixesObj } from "../util/checkForCommonHardware";

const Row = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  padding: 10,
  width: "100%",
});

const RowCenter = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

const RowBetween = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 10,
  width: "100%",
});

const Col = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "space-between",
  width: "100%",
  padding: 10,
});

const ColStart = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  paddingLeft: 10,
  paddingRight: 10,
});

const ColCenter = styled("div")({
  padding: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: 10,
});

const CustomSelect = styled(Select)({
  "& .MuiOutlinedInput-input": {
    padding: "5px 10px",
  },
  height: 30,
  width: "60%",
});

const CustomDialogTitle = styled(DialogTitle)({
  fontWeight: "bold",
  fontSize: 20,
  backgroundColor: "#ecedf1",
  borderBottom: "2px solid black",
});

const CustomText = styled(Typography)({
  color: "blue",
  fontWeight: "bold",
  paddingBottom: 20,
});

const StyledTypography = styled(Typography)({
  paddingRight: 10,
  fontWeight: "bold",
});

interface UnitCheckModalProps {
  open: boolean;
  handleClose: () => void;
  addToCart: (checkCart: boolean, ignoreCheck?: boolean) => void;
  nonMatches: FixesObj[];
}

const UnitCheckModal: FC<UnitCheckModalProps> = ({
  open,
  handleClose,
  addToCart,
  nonMatches,
}) => {
  const addToQuote = useStore((state) => state.addToQuote);

  const [choices, setChoices] = useState<{ [key: string]: string }>({});

  const [checked, setChecked] = useState(false);

  const [disable, setDisable] = useState(true);

  const handleUpdate = async () => {
    addToQuote(choices);
    handleClose();
    addToCart(true, checked);
  };

  const handleNoUpdate = () => {
    addToCart(false, checked);
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) =>
    setChecked(e.target.checked);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const { name, value } = event.target;
    setChoices({ ...choices, [name as string]: value as string });

    if (choices[`${name}`] !== value) {
      setDisable(false);
    }
  };

  useEffect(() => {
    if (!nonMatches.length) return;
    const obj: { [key: string]: string } = {};

    nonMatches.forEach((item: FixesObj) => {
      if (item.objKey) obj[item.objKey as string] = item.choice as string;
      if (item.objKey2) obj[item.objKey2 as string] = item.choice2 as string;
      if (item.objKey3) obj[item.objKey3 as string] = item.choice3 as string;
      if (item.objKey4) obj[item.objKey4 as string] = item.choice4 as string;
    });
    setChoices(obj);
  }, [nonMatches]);

  const body = (
    <Col>
      <CustomDialogTitle>
        <RowCenter>
          <RowCenter style={{ flexBasis: "35%", paddingRight: 20 }}>
            <Image cloudName="ag-millworks" publicId="millie_75" secure="true">
              <Transformation width="65" height="65" crop="scale" />
            </Image>
            <ColCenter style={{ alignItems: "flex-start" }}>
              <Typography>Hi I'm Millie,</Typography>
              <Typography>your AG Assistant...</Typography>
            </ColCenter>
          </RowCenter>

          <Typography style={{ fontWeight: "bold" }} variant="h6">
            Continue with the Following Finish Choices?
          </Typography>
        </RowCenter>
      </CustomDialogTitle>

      <DialogContent style={{ padding: 0 }} dividers>
        <Col>
          {nonMatches.length
            ? nonMatches.map((item, index) => (
                <div key={item.label}>
                  {(item.label === "Clad" ||
                    item.label === "Handle" ||
                    item.label === "Shoot Bolt") &&
                    index !== 0 && (
                      <Divider style={{ padding: 2 }} flexItem={true} />
                    )}
                  <RowBetween>
                    <Col>
                      <Row>
                        <StyledTypography
                          style={{ paddingRight: 10, fontWeight: "bold" }}
                        >
                          {item.label}
                        </StyledTypography>
                        <CustomSelect
                          value={choices[item.objKey as keyof typeof choices]}
                          name={item.objKey}
                          onChange={handleChange}
                          variant="outlined"
                        >
                          {item.fix?.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      </Row>

                      {item.label2 && (
                        <Row>
                          <StyledTypography>{item.label2}</StyledTypography>
                          <CustomSelect
                            value={
                              choices[item.objKey2 as keyof typeof choices]
                            }
                            name={item.objKey2}
                            onChange={handleChange}
                            variant="outlined"
                          >
                            {item.fix2?.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </Row>
                      )}

                      {item.label3 && (
                        <Row>
                          <StyledTypography>{item.label3}</StyledTypography>
                          <CustomSelect
                            value={
                              choices[item.objKey3 as keyof typeof choices]
                            }
                            name={item.objKey3}
                            onChange={handleChange}
                            variant="outlined"
                          >
                            {item.fix3?.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </Row>
                      )}

                      {item.label4 && (
                        <Row>
                          <StyledTypography>{item.label4}</StyledTypography>
                          <CustomSelect
                            value={
                              choices[item.objKey4 as keyof typeof choices]
                            }
                            name={item.objKey4}
                            onChange={handleChange}
                            variant="outlined"
                          >
                            {item.fix4?.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </Row>
                      )}
                    </Col>

                    <Divider flexItem={true} orientation="vertical" />

                    {item.recommendList ? (
                      <ColStart>
                        <CustomText>Recommended Pairing</CustomText>
                        <Typography>
                          {
                            item.recommendList[
                              choices[item.objKey as keyof typeof choices]
                            ]
                          }
                        </Typography>
                      </ColStart>
                    ) : item.recommend ? (
                      <ColStart>
                        <Typography
                          style={{
                            color: "blue",
                            fontWeight: "bold",
                            paddingBottom: 20,
                          }}
                        >
                          Recommended Pairing
                        </Typography>
                        <Typography>{item.recommend}</Typography>
                        {item.recommend2 && (
                          <>
                            <Typography>OR</Typography>
                            <Typography>{item.recommend2}</Typography>
                          </>
                        )}
                        {item.recommend3 && (
                          <>
                            <Typography>OR</Typography>
                            <Typography>{item.recommend3}</Typography>
                          </>
                        )}
                      </ColStart>
                    ) : null}
                  </RowBetween>
                </div>
              ))
            : null}
        </Col>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-evenly" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleCheck}
              color="primary"
            />
          }
          label="Ignore Check for this Unit"
        />
        <Button onClick={handleNoUpdate} variant="contained">
          Continue Without Changing
        </Button>
        <Button
          disabled={disable}
          color="primary"
          variant="contained"
          onClick={handleUpdate}
        >
          Update and Continue
        </Button>
      </DialogActions>
    </Col>
  );

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      {Object.keys(choices).length ? body : null}
    </Dialog>
  );
};

export default UnitCheckModal;
