import React, { memo } from "react";
import ReusableAccordian from "../ReusableAccordion";
import { useStore } from "../../store/store";
import { useAccordion } from "../../store/accordion";
import { pivotSill } from "./defaults";
import Choices from "../Choices";
import { Image } from "cloudinary-react";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import { RowLayout } from "../../util/accordianStyles";

function PivotSillAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);
  const sillStyle = useStore((state) => state.quote.sillStyle);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[13].expanded);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleClick = (label: string) => {
    const checked = label === sillStyle;

    if (checked) {
      addToQuote({ sillStyle: "" });
    } else {
      addToQuote({ sillStyle: label });

      if (findRequired("Sill Type")) {
        removeRequired("Sill Type");
      }
    }
  };

  const publicId = sillStyle?.includes("Aluminum") ? "clad" : "wood";

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="sill"
      heading="ADA Sill"
      secondaryHeading="Select ADA Sill type"
      required={true}
      colOrRow="col"
    >
      <RowLayout style={{ paddingBottom: 20 }}>
        {pivotSill.map((state) => (
          <div key={state.id} onClick={() => handleClick(state.label)}>
            <Choices
              label={state.label}
              variant={state.label === sillStyle ? "filled" : "outlined"}
              checkMark={state.label === sillStyle}
              wide={true}
            />
          </div>
        ))}
      </RowLayout>

      {sillStyle && (
        <Image
          cloudName="ag-millworks"
          secure="true"
          publicId={`/Sills/pivots/${publicId}`}
        />
      )}
    </ReusableAccordian>
  );
}

export default memo(PivotSillAccordian);
