import React, { FC } from "react";
import { Page, Text, View } from "@react-pdf/renderer";
import RefKeyCorner from "./RefKeyCorner";
import LiteCorner from "./LiteCorner";
import EVCorner from "./EVCorner";
import { styles } from "../Styles";
import { Header } from "../DealerDrawings";
import { QuoteObj } from "../../../GlobalTypes";
import { UnitObj } from "../../../util/quoteCartStorage";

export interface DrawingsProps {
  isDealer?: boolean;
  form: QuoteObj;
  item: UnitObj;
  unit: string;
  salesOrder: string;
  isProduction?: boolean;
}

const DealerDrawings: FC<DrawingsProps> = ({
  isDealer,
  form,
  item,
  unit,
  salesOrder,
  isProduction,
}) => {
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed).toLocaleDateString();
  const time = new Date(timeElapsed).toLocaleTimeString();

  const signline = "${signfield:1:y:______}";
  const dateSigned = "${datefield:1:y:Date_Signed}";

  const withDividedLites = item.addDL && item.dlLayout ? true : false;

  return (
    <Page style={{ position: "relative" }}>
      <Header
        form={form}
        salesOrder={salesOrder}
        today={today}
        time={time}
        isDealer={isDealer}
        isProduction={isProduction}
      />

      <View
        style={[styles.column, { width: "100%", justifyContent: "flex-start" }]}
      >
        <View style={[styles.rowStart2, { alignItems: "flex-start" }]}>
          <View style={styles.unitNum}>
            <Text style={styles.unitNumTxt}>Unit #: {unit}</Text>
          </View>

          <View
            style={[
              styles.rowStart2,
              { alignItems: withDividedLites ? "flex-start" : "center" },
            ]}
          >
            <View style={styles.evpvCol}>
              <EVCorner item={item} side="left" />
            </View>

            <View
              style={[
                styles.column,
                {
                  width: "30%",
                  alignItems: "center",
                  position: "relative",
                  paddingTop: withDividedLites ? 20 : 0,
                },
              ]}
            >
              <RefKeyCorner item={item} side="left" />

              {withDividedLites && <LiteCorner item={item} />}
            </View>
          </View>
        </View>

        <View style={[styles.rowStart2, { alignItems: "center" }]}>
          <View style={styles.evpvCol}>
            <EVCorner item={item} side="right" />
          </View>

          <View style={[styles.column, { width: "30%" }]}>
            <RefKeyCorner item={item} side="right" />
          </View>
        </View>
      </View>

      {isDealer ? (
        <View style={styles.fixedBox}>
          <Text style={{ color: "white", fontSize: 10 }}>{signline}</Text>
          <View style={{ position: "relative" }}>
            <Text style={styles.dateSigned}>{dateSigned}</Text>
          </View>
        </View>
      ) : (
        <View
          style={[
            styles.initialsBox,
            { position: "absolute", right: -400, bottom: 10 },
          ]}
        >
          <Text style={styles.textBold}>Initials: __________</Text>
        </View>
      )}
    </Page>
  );
};

export default DealerDrawings;
