import React, { FC } from "react";
import {
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import AdminCustomLeadTime from "./AdminCustomLeadTime";
import { styled } from "@mui/material/styles";
import { blueGrey } from "@mui/material/colors";

const StyledColDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

const StyledTypography = styled(Typography)({
  flexBasis: "30%",
});

const StyledSpan = styled("span")({
  color: "red",
  fontSize: 22,
});

const StyledButton = styled(Button)({
  position: "absolute",
  left: -120,
  top: 2,
});

const StyledDivFlexSpace = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  width: "30%",
  padding: 20,
});

const StyledInputDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: 20,
});

const StyledInput = styled("input")(({ theme }) => ({
  backgroundColor: blueGrey[50],
  border: "1px solid grey",
  borderRadius: "4px",
  height: "30px",
  width: 300,
  [theme.breakpoints.down("sm")]: {
    width: 150,
    marginLeft: 5,
  },
}));

interface QuoteFormState {
  agSalesperson: string;
  salesperson: string;
  dealer: string;
  address: string;
  city: string;
  zip: string;
  email: string;
  phone: string;
  customerName: string;
  jobName: string;
  customerEmail: string;
  customerPhone: string;
  customerZip: string;
  adminCOD: string;
  checkedDealer: boolean;
  checkedJobsite: boolean;
  formNotes: string;
  leadTime: boolean;
  customLeadTime: string;
  customerCity: string;
  heardAbout: string;
  heardAboutOther: string;
}

interface DealerInfoProps {
  role?: string;
  signInRole?: string;
  handleAllChange: (
    event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
  ) => void;
  state: QuoteFormState;
  handleOpen?: () => void;
  isQuoteCreated?: boolean;
}

const DealerInfo: FC<DealerInfoProps> = ({
  role,
  signInRole,
  handleAllChange,
  state,
  handleOpen,
  isQuoteCreated,
}) => {
  return (
    <StyledColDiv>
      <StyledInputDiv>
        <StyledTypography variant="body2">
          Dealer:
          <StyledSpan>* </StyledSpan>
        </StyledTypography>
        <StyledInput
          disabled={
            role !== "Dealer" &&
            role !== "Manager" &&
            role !== "Company Manager"
              ? false
              : true
          }
          onChange={handleAllChange}
          name="dealer"
          value={state.dealer}
          placeholder="Dealer"
        />
      </StyledInputDiv>

      <StyledInputDiv>
        <StyledTypography variant="body2">
          Name:
          <StyledSpan>* </StyledSpan>
        </StyledTypography>
        <StyledInput
          disabled={
            role !== "Dealer" &&
            role !== "Manager" &&
            role !== "Company Manager"
              ? false
              : true
          }
          onChange={handleAllChange}
          name="salesperson"
          value={state.salesperson}
          placeholder="Name"
        />
      </StyledInputDiv>

      <StyledInputDiv>
        <div style={{ position: "relative", flexBasis: "30%" }}>
          <StyledTypography variant="body2">
            Email:
            <StyledSpan>* </StyledSpan>
          </StyledTypography>

          {role !== "Dealer" && !isQuoteCreated && (
            <StyledButton
              variant="contained"
              color="primary"
              size="small"
              onClick={handleOpen}
            >
              Change Rep
            </StyledButton>
          )}
        </div>

        <StyledInput
          disabled={
            role !== "Dealer" &&
            role !== "Manager" &&
            role !== "Company Manager"
              ? false
              : true
          }
          onChange={handleAllChange}
          name="email"
          value={state.email}
          placeholder="Email"
        />
      </StyledInputDiv>

      <StyledInputDiv style={{ paddingBottom: 0 }}>
        <StyledTypography style={{ paddingRight: 20 }} variant="body2">
          Phone Number:{" "}
        </StyledTypography>
        <StyledInput
          maxLength={15}
          style={{ marginRight: 50 }}
          onChange={handleAllChange}
          name="phone"
          type="tel"
          value={state.phone}
          placeholder="123-456-7891"
        />
      </StyledInputDiv>

      {signInRole !== "Dealer" &&
        signInRole !== "Manager" &&
        signInRole !== "Company Manager" && (
          <>
            <StyledDivFlexSpace>
              <StyledTypography style={{ paddingRight: 20 }} variant="body2">
                COD or Terms:
              </StyledTypography>

              <FormControl variant="outlined" margin="dense" size="small">
                <Select
                  onChange={handleAllChange}
                  name="adminCOD"
                  value={state.adminCOD}
                >
                  <MenuItem value="None">None</MenuItem>
                  <MenuItem value="Net10">Net 10</MenuItem>
                  <MenuItem value="Net30">Net 30</MenuItem>
                  <MenuItem value="1%10;Net30">1%10;Net 30</MenuItem>
                  <MenuItem value="COD (50% Deposit is required to Start Order)">
                    COD
                  </MenuItem>
                </Select>
              </FormControl>
            </StyledDivFlexSpace>
            {(signInRole === "Admin" || signInRole === "Inside Sales") && (
              <AdminCustomLeadTime
                handleChange={handleAllChange}
                customLead={state.customLeadTime}
                checked={state.leadTime}
              />
            )}
          </>
        )}
    </StyledColDiv>
  );
};

export default DealerInfo;
