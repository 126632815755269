const findMinMaxOutswing = (
  equal: boolean,
  sideLites: string,
  swingType: string,
) => {
  var min: number | null = 0;
  var max: number | null = 0;

  if (equal) {
    min =
      sideLites === "none" && swingType === "Single Swing"
        ? 26
        : sideLites === "none" && swingType === "Pair Swing"
          ? 50
          : sideLites === "Add Single Sidelite" && swingType === "Single Swing"
            ? 50.9375
            : sideLites === "Add Double Sidelite" &&
                swingType === "Single Swing"
              ? 75.8125
              : sideLites === "Add Double Sidelite" &&
                  swingType === "Pair Swing"
                ? 100.4375
                : null;

    max =
      sideLites === "none" && swingType === "Single Swing"
        ? 45
        : sideLites === "none" && swingType === "Pair Swing"
          ? 87
          : sideLites === "Add Single Sidelite" && swingType === "Single Swing"
            ? 86.9375
            : sideLites === "Add Double Sidelite" &&
                swingType === "Single Swing"
              ? 129.8125
              : sideLites === "Add Double Sidelite" &&
                  swingType === "Pair Swing"
                ? 172.4375
                : null;
  } else {
    min =
      sideLites === "none" && swingType === "Single Swing"
        ? 26
        : sideLites === "none" && swingType === "Pair Swing"
          ? 50
          : sideLites === "Add Single Sidelite" && swingType === "Single Swing"
            ? 37
            : sideLites === "Add Double Sidelite" &&
                swingType === "Single Swing"
              ? 49
              : sideLites === "Add Double Sidelite" &&
                  swingType === "Pair Swing"
                ? 74
                : null;

    max =
      sideLites === "none" && swingType === "Single Swing"
        ? 45
        : sideLites === "none" && swingType === "Pair Swing"
          ? 87
          : sideLites === "Add Single Sidelite" && swingType === "Single Swing"
            ? 87
            : sideLites === "Add Double Sidelite" &&
                swingType === "Single Swing"
              ? 130
              : sideLites === "Add Double Sidelite" &&
                  swingType === "Pair Swing"
                ? 173
                : null;
  }

  return { min, max };
};

export default findMinMaxOutswing;
