export const cladColorImgs = [
  { label: "Carbon Black", imgLabel: "CladColors/carbon" },
  { label: "Espresso Brown", imgLabel: "CladColors/espresso" },
  { label: "Sandcastle Tan", imgLabel: "CladColors/sand" },
  { label: "Linen White", imgLabel: "CladColors/linen" },
  { label: "Clear Anodized", imgLabel: "CladColors/standard" },
];

export const cladColor = [
  {
    id: 1,
    type: "exteriorCladColor",
    label: "Carbon Black",
  },
  {
    id: 2,
    type: "exteriorCladColor",
    label: "Espresso Brown",
  },
  {
    id: 3,
    type: "exteriorCladColor",
    label: "Sandcastle Tan",
  },
  {
    id: 4,
    type: "exteriorCladColor",
    label: "Linen White",
  },
  {
    id: 5,
    type: "exteriorCladColor",
    label: "Clear Anodized",
  },
  {
    id: 6,
    type: "exteriorCladColor",
    label: "Custom Color (upcharge applies)",
  },
];
