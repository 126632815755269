function debounce(
  value: any,
  cb: (obj: any) => void,
  name: string,
  timeout: number,
) {
  let timer: any;
  clearTimeout(timer);
  setTimeout(() => {
    cb({ [name]: value });
  }, timeout);
}

export default debounce;
