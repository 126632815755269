import React, { useState, FC } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import getModalStyle from "../../util/getModalStyle";
import CustomGlassSwitch from "./CustomGlassSwitch";
import { StyledModalDiv } from "../../util/accordianStyles";

interface DotsModalProps {
  open: boolean;
  handleClose: (modalType: string) => void;
  loading: boolean;
  clickFn: () => void;
  modalType: string;
  error: boolean;
  checked: boolean;
  handleCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DotsModal: FC<DotsModalProps> = ({
  open,
  handleClose,
  loading,
  clickFn,
  modalType,
  error,
  checked,
  handleCheck,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const body = (
    <StyledModalDiv style={modalStyle}>
      {modalType === "edit" ? (
        <>
          <h3>Would you like to edit this unit?</h3>
          <p>
            Please Note: Edits to a unit may remove other selected options,
            please review all options before saving any unit revisions.
          </p>
        </>
      ) : (
        <h3>Would you like to add Custom Glass?</h3>
      )}

      {error && (
        <p style={{ color: "red", fontWeight: "bold" }}>A Problem Occurred</p>
      )}

      {modalType === "glass" && (
        <CustomGlassSwitch checked={checked} handleCheck={handleCheck} />
      )}

      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          onClick={() => handleClose(modalType)}
          variant="contained"
          color="inherit"
        >
          Cancel
        </Button>
        {loading ? (
          <Button
            variant="contained"
            size="large"
            color={
              modalType === "edit" || modalType === "glass"
                ? "primary"
                : "secondary"
            }
          >
            <CircularProgress color="inherit" size={30} />
          </Button>
        ) : (
          <Button
            size="large"
            onClick={clickFn}
            variant="contained"
            color={
              modalType === "edit" || modalType === "glass"
                ? "primary"
                : "secondary"
            }
          >
            {modalType === "edit" ? "Edit" : "Confirm"}
          </Button>
        )}
      </div>
    </StyledModalDiv>
  );

  return (
    <Modal open={open} onClose={() => handleClose(modalType)}>
      {body}
    </Modal>
  );
};

export default DotsModal;
