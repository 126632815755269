//convert decimal to fraction
var gcd = function (a: number, b: number): number {
  if (!b) return a;

  return gcd(b, Math.floor(a % b));
};

export function convertDecimalToFraction(number: number, roundUp?: boolean) {
  var measure;
  var lower = roundUp
    ? Math.ceil(number * 16) / 16
    : Math.floor(number * 16) / 16;
  var wholeNumber = lower.toString().split(".")[0];
  var decimal = lower.toString().split(".")[1];
  var j = decimal ? parseFloat(`.${decimal}`) : null;

  if (!j) {
    measure = wholeNumber;
    return measure;
  }

  var len = j.toString().length - 2;

  var denominator = Math.pow(10, len);
  var numerator = j * denominator;

  var divisor = gcd(numerator, denominator);

  numerator /= divisor;
  denominator /= divisor;

  var converted = roundUp
    ? Math.ceil(numerator) + "/" + Math.ceil(denominator)
    : Math.floor(numerator) + "/" + Math.floor(denominator);
  measure = `${wholeNumber} ${converted}`;
  return measure;
}
