import React, { useState, FC } from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
  MenuItem,
  Select,
  Button,
  Typography,
  IconButton,
  SelectChangeEvent,
} from "@mui/material";
import api from "../../api/api";
import { useQuery, useQueryClient } from "react-query";
import { Image, CloudinaryContext } from "cloudinary-react";
import {
  getLeadTimes,
  getPaintLeadTimes,
  updatePaintLeadTimes,
} from "../../api/DzoneApi";
import PaintIcon from "@mui/icons-material/ImagesearchRoller";
import PaintLeadTimesModal from "./PaintLeadTimesModal";
import { styled } from "@mui/material/styles";

const StyledWrapDiv = styled("div")(({ theme }) => ({
  borderRadius: 5,
  backgroundColor: "white",
  boxShadow: "4px 4px 8px #888888",
  width: "40vw",
  marginBottom: 20,
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90vw",
  },
  position: "relative",
}));

const StyledSCDiv = styled("div")({
  border: "1px solid grey",
  borderRadius: 5,
  position: "relative",
});

const StyledSCPositionTypo = styled(Typography)({
  position: "absolute",
  left: -40,
  bottom: 0,
});

const StyledEditIcon = styled(IconButton)({
  position: "absolute",
  left: 20,
  top: 10,
});

const StyledPaintIcon = styled(IconButton)({
  position: "absolute",
  right: 20,
  bottom: 10,
});

const StyledUndoTypo = styled(Typography)({
  position: "absolute",
  left: 60,
  top: 20,
  fontWeight: "bold",
  fontSize: 14,
});

const StyledBtn = styled(Button)({
  position: "absolute",
  right: 60,
  top: 25,
  color: "white",
});

const StyledTypographyTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  [theme.breakpoints.down("lg")]: {
    fontSize: 24,
  },
}));

const StyledInput = styled("input")({
  height: 30,
  width: 100,
});

const StyledColDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
});

const StyledRowDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  padding: 20,
});

const StyledRowDiv2 = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("xl")]: {
    justifyContent: "space-around",
  },
  alignItems: "center",
  flexDirection: "row",
  marginLeft: 25,
  marginRight: 25,
  paddingLeft: 10,
  paddingRight: 10,
  [theme.breakpoints.down("md")]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
  },
}));

const StyledTypoHeader = styled(Typography)({
  textDecoration: "underline",
  paddingBottom: 10,
});

interface LeadTimeObj {
  _id?: string;
  doorType: string;
  leadTime: string;
  leadTimeNorthMonth?: string;
}

export interface PaintLeadTimesObj {
  socalPaint?: string;
  socalPrime?: string;
  socalClad?: string;
  norcalPaint?: string;
  norcalPrime?: string;
  norcalClad?: string;
}

interface LeadTimeState {
  bifold?: string;
  slider?: string;
  swing?: string;
  pivot?: string;
  bifoldNorth?: string;
  sliderNorth?: string;
  swingNorth?: string;
  pivotNorth?: string;
}

function compare(a: LeadTimeObj, b: LeadTimeObj) {
  if (a.doorType < b.doorType) {
    return -1;
  }
  if (a.doorType > b.doorType) {
    return 1;
  }
  return 0;
}

const LeadTimes = () => {
  const role = JSON.parse(sessionStorage.getItem("role") || JSON.stringify(""));

  const hasAdminAccess = role === "Admin" || role === "Inside Sales";

  const hasDealerAccess =
    role === "Dealer" || role === "Manager" || role === "Company Manager";

  const staleTime = hasDealerAccess ? 300000 : 0;

  const { data, isFetching } = useQuery(
    "LeadTimes",
    async () => {
      const response = await getLeadTimes();

      if (!hasDealerAccess && role !== "Outside Sales") {
        response.data.lead.forEach((lead: LeadTimeObj) => {
          lead.doorType === "Bi-Fold" &&
            setState((prevState) => ({
              ...prevState,
              bifoldNorth: lead.leadTimeNorthMonth,
            }));
          lead.doorType === "Slider" &&
            setState((prevState) => ({
              ...prevState,
              sliderNorth: lead.leadTimeNorthMonth,
            }));
          lead.doorType === "Swing" &&
            setState((prevState) => ({
              ...prevState,
              swingNorth: lead.leadTimeNorthMonth,
            }));
          lead.doorType === "Pivot" &&
            setState((prevState) => ({
              ...prevState,
              pivotNorth: lead.leadTimeNorthMonth,
            }));
        });
      }

      return response.data;
    },
    { staleTime, refetchOnWindowFocus: false },
  );

  const { data: paintData, isFetching: isFetchingPaint } = useQuery(
    "PaintLeadTimes",
    async () => {
      const response = await getPaintLeadTimes();

      if (!hasDealerAccess && role !== "Outside Sales") {
        let lt: PaintLeadTimesObj = {};

        for (const key in response.data) {
          lt[key as keyof PaintLeadTimesObj] = String(response.data[key]);
        }

        setPaintLead(lt);
      }

      return response.data;
    },
    { staleTime, refetchOnWindowFocus: false },
  );

  const queryClient = useQueryClient();

  const [edit, setEdit] = useState(false);

  const [state, setState] = useState<LeadTimeState>({
    bifold: "",
    slider: "",
    swing: "",
    pivot: "",
    bifoldNorth: "",
    sliderNorth: "",
    swingNorth: "",
    pivotNorth: "",
  });

  const [paintLead, setPaintLead] = useState<PaintLeadTimesObj>({
    socalPaint: "",
    socalPrime: "",
    socalClad: "",
    norcalPaint: "",
    norcalPrime: "",
    norcalClad: "",
  });

  const handleChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleChangePaint = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setPaintLead({ ...paintLead, [name]: value });
  };

  const [openPaintModal, setOpenPaintModal] = useState(false);

  const handleOpenPaintModal = () => {
    let lt: PaintLeadTimesObj = {};

    for (const key in paintData) {
      lt[key as keyof PaintLeadTimesObj] = String(paintData[key]);
    }

    setPaintLead(lt);
    setOpenPaintModal(true);
  };

  const handleClosePaintModal = () => {
    setPaintLead({
      socalPaint: "",
      socalPrime: "",
      socalClad: "",
      norcalPaint: "",
      norcalPrime: "",
      norcalClad: "",
    });
    setOpenPaintModal(false);
  };

  const changeToEditMode = () => setEdit((prevEdit) => !prevEdit);

  const handleAdminLeadTimeUpdate = () => {
    api
      .post("/api/admin/changeLeadTimes", { state })
      .then((res) => res.data && queryClient.invalidateQueries("LeadTimes"))
      .then(() => setEdit(false))
      .catch((err) => console.error(err));
  };

  const handleAdminPaintLeadTimeUpdate = async () => {
    const res = await updatePaintLeadTimes(paintLead);

    if (res.data.matchedCount) {
      handleClosePaintModal();
      return queryClient.invalidateQueries("PaintLeadTimes");
    }
  };

  const bifoldPID = "v1635811395/LeadTimes/bifoldDzone";
  const sliderPID = "v1635811397/LeadTimes/sliderDzone";
  const swingPID = "v1635811399/LeadTimes/swingDzone";
  const pivotPID = "/LeadTimes/pivotDzone_v3";

  return (
    <StyledWrapDiv>
      {hasAdminAccess && (
        <PaintLeadTimesModal
          open={openPaintModal}
          handleClose={handleClosePaintModal}
          handleChange={handleChangePaint}
          state={paintLead}
          handleSubmit={handleAdminPaintLeadTimeUpdate}
        />
      )}

      {hasAdminAccess && (
        <>
          <StyledEditIcon onClick={changeToEditMode} size="small">
            <EditIcon color="primary" />
          </StyledEditIcon>

          <StyledPaintIcon onClick={handleOpenPaintModal} size="small">
            <PaintIcon color="primary" />
          </StyledPaintIcon>
        </>
      )}
      {hasAdminAccess && edit && (
        <>
          <StyledUndoTypo>Undo</StyledUndoTypo>

          <StyledBtn
            size="small"
            onClick={handleAdminLeadTimeUpdate}
            color="customGrey"
            variant="contained"
          >
            Update
          </StyledBtn>
        </>
      )}
      <StyledRowDiv>
        <StyledTypographyTitle variant="h4">
          Current Lead Times
        </StyledTypographyTitle>
      </StyledRowDiv>
      <StyledRowDiv2>
        {!isFetching ? (
          data.lead.sort(compare).map((door: LeadTimeObj) => (
            <StyledColDiv key={door.doorType}>
              <StyledTypoHeader variant="body1">
                {door.doorType === "Bi-Fold"
                  ? "Bi-Fold Doors/Windows"
                  : `${door.doorType} Doors`}
              </StyledTypoHeader>

              {door.doorType.includes("Bi-Fold") && (
                <Image
                  cloudName="ag-millworks"
                  secure="true"
                  publicId={bifoldPID}
                />
              )}
              {door.doorType === "Slider" && (
                <Image
                  cloudName="ag-millworks"
                  secure="true"
                  publicId={sliderPID}
                />
              )}
              {door.doorType === "Swing" && (
                <Image
                  cloudName="ag-millworks"
                  secure="true"
                  publicId={swingPID}
                />
              )}
              {door.doorType === "Pivot" && (
                <Image
                  cloudName="ag-millworks"
                  secure="true"
                  publicId={pivotPID}
                />
              )}

              <StyledSCDiv>
                {hasAdminAccess && (
                  <StyledSCPositionTypo variant="subtitle1">
                    SC:
                  </StyledSCPositionTypo>
                )}

                {hasAdminAccess && edit && (
                  <StyledInput
                    onChange={handleChange}
                    value={
                      door.doorType.includes("Bi-Fold")
                        ? state.bifold
                        : door.doorType === "Slider"
                          ? state.slider
                          : door.doorType === "Swing"
                            ? state.swing
                            : door.doorType === "Pivot"
                              ? state.pivot
                              : ""
                    }
                    name={door.doorType.replace("-", "").toLowerCase()}
                    placeholder={door.leadTime}
                  />
                )}
                {(hasDealerAccess || !edit) && (
                  <Typography style={{ padding: 5 }} variant="body2">
                    {data.region === "NorCal"
                      ? `Mid ${door.leadTimeNorthMonth}`
                      : door.leadTime}
                  </Typography>
                )}
              </StyledSCDiv>

              {hasAdminAccess && (
                <>
                  <StyledSCDiv style={{ marginTop: 5 }}>
                    <StyledSCPositionTypo variant="subtitle1">
                      NC:
                    </StyledSCPositionTypo>

                    {edit && (
                      <NorthLeadTimeSelect
                        leadTime={
                          door.doorType.includes("Bi-Fold") && state.bifoldNorth
                            ? state.bifoldNorth
                            : door.doorType === "Slider" && state.sliderNorth
                              ? state.sliderNorth
                              : door.doorType === "Swing" && state.swingNorth
                                ? state.swingNorth
                                : door.doorType === "Pivot" && state.pivotNorth
                                  ? state.pivotNorth
                                  : ""
                        }
                        handleChange={handleChange}
                        name={`${door.doorType
                          .replace("-", "")
                          .toLowerCase()}North`}
                      />
                    )}
                    {!edit && (
                      <Typography style={{ padding: 5 }} variant="body2">
                        {door.leadTimeNorthMonth}
                      </Typography>
                    )}
                  </StyledSCDiv>
                </>
              )}
            </StyledColDiv>
          ))
        ) : (
          <>
            <CloudinaryContext secure="true" cloudName="ag-millworks">
              <Image publicId={bifoldPID} />
              <Image publicId={pivotPID} />
              <Image publicId={sliderPID} />
              <Image publicId={swingPID} />
            </CloudinaryContext>
          </>
        )}
      </StyledRowDiv2>

      <StyledRowDiv style={{ flexDirection: "column", padding: 10 }}>
        <Typography variant="caption">
          Note: Lead Times shown are for standard orders and are subject to
          change at anytime.
        </Typography>
        <Typography variant="caption">Does not include Paint/Stain.</Typography>
      </StyledRowDiv>
    </StyledWrapDiv>
  );
};

export default LeadTimes;

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

interface NorthLeadTimeSelectProps {
  leadTime: string;
  handleChange: (event: SelectChangeEvent<string>) => void;
  name: string;
}

const NorthLeadTimeSelect: FC<NorthLeadTimeSelectProps> = ({
  leadTime,
  handleChange,
  name,
}) => {
  return (
    <Select
      style={{ fontSize: 14, paddingLeft: 3, paddingRight: 3 }}
      value={leadTime}
      name={name}
      onChange={handleChange}
      size="small"
    >
      {months.map((month) => (
        <MenuItem key={month} value={month}>
          {month}
        </MenuItem>
      ))}
    </Select>
  );
};
