import React, { FC } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Backdrop } from "@mui/material";
import { styled } from "@mui/material/styles";

interface SpinnerProps {
  isLoading: boolean;
}

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: "#fff",
}));

const StyledDiv = styled("div")({
  position: "fixed",
  top: "50%",
  left: "50%",
});

const Spinner: FC<SpinnerProps> = ({ isLoading }) => {
  return (
    <StyledBackdrop open={isLoading}>
      <Loader />
    </StyledBackdrop>
  );
};

const Loader: FC = () => {
  return (
    <StyledDiv>
      <ScaleLoader height={100} width={10} color={"#57d5f2"} />
    </StyledDiv>
  );
};

export default Spinner;
