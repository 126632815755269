const CLOUDINARY_URL =
  "https://res.cloudinary.com/ag-millworks/image/upload/Window_Configs";

const taggedWindowImages = [
  {
    config: "2L OR 2R",
    image1: `${CLOUDINARY_URL}/2L.jpg`,
    image2: `${CLOUDINARY_URL}/2R.jpg`,
  },
  {
    config: "2L1R OR 1L2R",
    image1: `${CLOUDINARY_URL}/2L1R.jpg`,
    image2: `${CLOUDINARY_URL}/1L2R.jpg`,
  },
  {
    config: "3L OR 3R",
    image1: `${CLOUDINARY_URL}/3L.jpg`,
    image2: `${CLOUDINARY_URL}/3R.jpg`,
  },
  {
    config: "3L1R OR 1L3R",
    image1: `${CLOUDINARY_URL}/3L1R.jpg`,
    image2: `${CLOUDINARY_URL}/1L3R.jpg`,
  },
  {
    config: "2L2R",
    image1: `${CLOUDINARY_URL}/2L2R.jpg`,
    image2: "",
  },
  {
    config: "4L OR 4R",
    image1: `${CLOUDINARY_URL}/4L.jpg`,
    image2: `${CLOUDINARY_URL}/4R.jpg`,
  },
  {
    config: "4L1R OR 1L4R",
    image1: `${CLOUDINARY_URL}/4L1R.jpg`,
    image2: `${CLOUDINARY_URL}/1L4R.jpg`,
  },
  {
    config: "3L2R OR 2L3R",
    image1: `${CLOUDINARY_URL}/3L2R.jpg`,
    image2: `${CLOUDINARY_URL}/2L3R.jpg`,
  },
  {
    config: "5L OR 5R",
    image1: `${CLOUDINARY_URL}/5L.jpg`,
    image2: `${CLOUDINARY_URL}/5R.jpg`,
  },
  {
    config: "5L1R OR 1L5R",
    image1: `${CLOUDINARY_URL}/5L1R.jpg`,
    image2: `${CLOUDINARY_URL}/1L5R.jpg`,
  },
  {
    config: "4L2R OR 2L4R",
    image1: `${CLOUDINARY_URL}/4L2R.jpg`,
    image2: `${CLOUDINARY_URL}/2L4R.jpg`,
  },
  {
    config: "3L3R",
    image1: `${CLOUDINARY_URL}/3L3R.jpg`,
    image2: "",
  },
  {
    config: "6L OR 6R",
    image1: `${CLOUDINARY_URL}/6L.jpg`,
    image2: `${CLOUDINARY_URL}/6R.jpg`,
  },
];

export default taggedWindowImages;
