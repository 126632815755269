function sliderCloudinaryImg(config: string, pocketing: string) {
  const baseURL =
    "https://res.cloudinary.com/ag-millworks/image/upload/pdfDrawings";

  const withPocket = pocketing === "With Pocket" ? "pocketing" : "nonpocketing";

  const configLabel =
    withPocket === "nonpocketing" &&
    config.includes("Primary") &&
    config.includes("Left")
      ? `${config.split(" ")[0].trim()}_LH`
      : withPocket === "nonpocketing" &&
        config.includes("Primary") &&
        config.includes("Right")
      ? `${config.split(" ")[0].trim()}_RH`
      : config;

  const ev = `${baseURL}/elevationView/sliders/${withPocket}/${configLabel}_EV.jpg`;
  const pv = `${baseURL}/planView/sliders/${configLabel}_PLAN.png`;

  return {
    ev,
    pv,
  };
}

export default sliderCloudinaryImg;
