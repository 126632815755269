import React, { FC } from "react";
import { Typography } from "@mui/material";
import { useStore } from "../../store/store";
import { styled } from "@mui/material/styles";

const StyledDivCol = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
});

const StyledTypography = styled(Typography)({
  color: "#3f51b5",
  display: "inline",
  fontWeight: "bold",
});

interface SideliteProps {
  sideLites: string;
  doorPanelWidth: string;
  swingWidths: { sideliteWidth: string };
}

const Sidelites: FC<SideliteProps> = ({
  sideLites,
  doorPanelWidth,
  swingWidths,
}) => {
  const sideliteWidthErr = useStore((state) => state.sideliteWidthErr);

  return (
    <StyledDivCol>
      {sideLites !== "none" && swingWidths.sideliteWidth && doorPanelWidth && (
        <>
          <Typography>
            <StyledTypography>
              Approximate Sidelite Panel Width(Z):
            </StyledTypography>{" "}
            {swingWidths.sideliteWidth} inches
          </Typography>

          {!sideliteWidthErr && (
            <>
              {swingWidths.sideliteWidth.split(" ")[1] ? (
                <Typography style={{ paddingTop: 10 }}>
                  <StyledTypography>
                    Approximate Sidelite Day Light Opening(W):
                  </StyledTypography>{" "}
                  {`${parseInt(swingWidths.sideliteWidth.split(" ")[0]) - 5} ${
                    swingWidths.sideliteWidth.split(" ")[1]
                  }`}{" "}
                  inches
                </Typography>
              ) : swingWidths.sideliteWidth ? (
                <Typography style={{ paddingTop: 10 }}>
                  <StyledTypography>
                    Approximate Sidelite Day Light Opening(W):
                  </StyledTypography>{" "}
                  {`${parseInt(swingWidths.sideliteWidth) - 5}`} inches
                </Typography>
              ) : null}
            </>
          )}
        </>
      )}

      {sideliteWidthErr && (
        <Typography style={{ color: "red" }} variant="body2">
          {sideliteWidthErr}
        </Typography>
      )}
    </StyledDivCol>
  );
};

export default Sidelites;
