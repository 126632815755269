import React, { FC } from "react";
import { Image, Text } from "@react-pdf/renderer";
import {
  bifoldHeadSillImg,
  swingHeadSillImg,
  fixedHeadSill,
  pivotHeadSill,
} from "../../Drawings/CAD";
import { sliderHeadSillKey } from "../../Drawings/sliderCAD";
import { styles } from "./Styles";
import { UnitObj } from "../../util/quoteCartStorage";

interface HeadSillProps {
  item: UnitObj;
}

const HeadSillSection: FC<HeadSillProps> = ({ item }) => {
  if (item.doorType === "Slider") {
    const headSillImg = sliderHeadSillKey(
      item.sliderPocket || "",
      item.exactConfig,
      item.screenType || "",
      item.addScreen,
      item.addSlidingScreen,
      item.sillStyle,
      item.handleStyle,
      item.material,
    );

    return <Image style={styles.sectionImg} src={headSillImg} />;
  }

  if (item.doorType === "Pivot") {
    const headSillImg = pivotHeadSill(
      item.material,
      item.pivotHardware || "",
      item.pivotAction || "",
      item.dropseal || "",
      item.sillStyle || "",
    );

    return <Image style={{ width: "auto", height: 200 }} src={headSillImg} />;
  }

  if (item.doorType === "Swing") {
    const headSillImg = swingHeadSillImg(
      item.material,
      item.swingDirection || "",
      item.screenType || "",
      item.addScreen || "",
    );

    return <Image style={styles.sectionImg} src={headSillImg} />;
  }

  if (item.doorType.includes("Bi-fold")) {
    const headSillImg = bifoldHeadSillImg(
      item.material,
      item.screenType || "",
      item.addScreen || "",
      item.sillStyle || "",
      item.stilesAndRails || "",
    );

    const bifoldStyles = item.doorType.includes("Corner")
      ? { width: 280, height: 250 }
      : styles.sectionImg;

    return <Image style={bifoldStyles} src={headSillImg} />;
  }

  if (item.doorType === "Fixed Panel") {
    const headSillImg = fixedHeadSill(
      item.material,
      item.exactConfig,
      item.sillStyle || "",
    );

    return <Image style={styles.sectionImg} src={headSillImg} />;
  }

  return <Text />;
};

export default HeadSillSection;
