import React, { FC } from "react";
import {
  Switch,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledDivCol = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

interface AdminCustomLeadProps {
  checked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  customLead: string;
}

const AdminCustomLeadTime: FC<AdminCustomLeadProps> = ({
  checked,
  handleChange,
  customLead,
}) => {
  return (
    <StyledDivCol>
      <FormControl component="fieldset" variant="standard">
        <FormLabel>Add Custom Lead Time</FormLabel>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={checked}
              name="leadTime"
              onChange={handleChange}
            />
          }
          label={checked ? "Custom" : "Default"}
        />
      </FormControl>

      {checked && (
        <>
          <input
            placeholder="Enter Lead Time"
            value={customLead}
            name="customLeadTime"
            onChange={handleChange}
          />

          <StyledDivCol style={{ paddingTop: 10, alignItems: "flex-start" }}>
            <Typography>
              <span style={{ fontWeight: "bold" }}>NorCal Format:</span> Last
              Week of <span style={{ fontStyle: "italic" }}>Month Year</span>{" "}
              Truck
            </Typography>
            <Typography>
              <span style={{ fontWeight: "bold" }}>SoCal Format:</span> 10-12
              Weeks or 10 Weeks
            </Typography>
          </StyledDivCol>
        </>
      )}
    </StyledDivCol>
  );
};

export default AdminCustomLeadTime;
