import React, { useState, FC } from "react";
import { Typography, Button, Modal } from "@mui/material";
import getModalStyle from "../../util/getModalStyle";
import { addKD, removeKD } from "../../api/SidebarApi";
import { useQueryClient } from "react-query";
import { styled } from "@mui/material/styles";
import { StyledModalDiv } from "../../util/accordianStyles";

const StyledDiv = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  marginTop: 20,
});

interface KdModalProps {
  open: boolean;
  handleClose: () => void;
  unitId: string;
  quoteId: string;
  doorType: string;
  kd: boolean;
}

const KdModal: FC<KdModalProps> = ({
  open,
  handleClose,
  unitId,
  quoteId,
  doorType,
  kd,
}) => {
  const queryClient = useQueryClient();

  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const handleKD = async () => {
    if (!quoteId || !unitId || doorType === "Swing") return;

    const res = !kd
      ? await addKD(quoteId, unitId)
      : await removeKD(quoteId, unitId);

    if (res.data === "added") {
      return queryClient.invalidateQueries("QuoteCart");
    }
  };

  const body = (
    <StyledModalDiv style={modalStyle}>
      {!kd ? (
        <Typography variant="h5">
          Would you like to knock down the frame?
        </Typography>
      ) : (
        <Typography variant="h5">
          Would you like to <span style={{ fontWeight: "bold" }}>remove</span>{" "}
          the knock down option on this unit?
        </Typography>
      )}

      <div style={{ display: "flex", justifyContent: "center", padding: 20 }}>
        {!kd ? (
          <Typography>
            To facilitate installation in confined areas, the head jamb, sill,
            and jamb legs will be shipped detached from each other and packaged
            accordingly. Not recommended if retractable screen is selected.
          </Typography>
        ) : (
          <Typography>The frame will be shipped fully assembled.</Typography>
        )}
      </div>

      <StyledDiv>
        <Button
          onClick={handleClose}
          variant="contained"
          size="large"
          color="inherit"
        >
          Cancel
        </Button>

        <Button
          size="large"
          onClick={handleKD}
          color="primary"
          variant="contained"
        >
          Ok
        </Button>
      </StyledDiv>
    </StyledModalDiv>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

export default KdModal;
