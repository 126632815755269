import { Font } from "@react-pdf/renderer";
import RobotoBold from "./fonts/Roboto-Bold.ttf";
import Roboto from "./fonts/Roboto.ttf";

Font.register({
  family: "Roboto-Bold",
  format: "truetype",
  src: RobotoBold,
});

Font.register({
  family: "Roboto",
  format: "truetype",
  src: Roboto,
});
