import React, { memo } from "react";
import ReusableAccordian from "../ReusableAccordion";
import { useStore } from "../../store/store";
import { useAccordion } from "../../store/accordion";
import { backPlate, backPlateColors } from "./defaults";
import Choices from "../Choices";
import findImages from "./findImages";
import { Image, Transformation } from "cloudinary-react";
import { Typography } from "@mui/material";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import InfoIcon from "@mui/icons-material/Info";
import { RowLayout, ColLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const RowLayoutLeftDivide = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  borderLeft: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
}));

const RowLayoutSpace = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  padding: theme.spacing(1, 2),
}));

function PivotBackPlateAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);
  const pivotBackPlate = useStore((state) => state.quote.pivotBackPlate);
  const pivotBackPlateColor = useStore(
    (state) => state.quote.pivotBackPlateColor,
  );

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[25].expanded);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleClick = (label: string) => {
    const checked = label === pivotBackPlate;

    if (checked) {
      addToQuote({ pivotBackPlate: "" });
    } else {
      if (findRequired("Back Plate")) removeRequired("Back Plate");
      addToQuote({ pivotBackPlate: label });
    }
  };

  const handleClickColor = (label: string) => {
    const checked = label === pivotBackPlateColor;

    if (checked) {
      addToQuote({ pivotBackPlateColor: "" });
    } else {
      if (findRequired("Back Plate Finish"))
        removeRequired("Back Plate Finish");
      addToQuote({ pivotBackPlateColor: label });
    }
  };

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="screen"
      heading="Back Plate"
      secondaryHeading="Select Back Plate"
      required={true}
      colOrRow="col"
    >
      <RowLayout>
        <InfoIcon color="primary" style={{ marginRight: 10 }} />

        <Typography>Thumbturn Finish will match Back Plate Finish</Typography>
      </RowLayout>

      <RowLayout>
        <ColLayout>
          <Typography style={{ fontWeight: "bold" }}>Back Plate</Typography>

          <RowLayoutSpace>
            {backPlate.map((state) => (
              <div key={state.id} onClick={() => handleClick(state.label)}>
                <Choices
                  label={state.label}
                  variant={
                    state.label === pivotBackPlate ? "filled" : "outlined"
                  }
                  checkMark={state.label === pivotBackPlate}
                  wide={true}
                />
              </div>
            ))}
          </RowLayoutSpace>
        </ColLayout>

        <ColLayout>
          <Typography style={{ fontWeight: "bold" }}>
            Back Plate Finish
          </Typography>

          <RowLayoutLeftDivide>
            {backPlateColors.map((state) => (
              <div key={state.id} onClick={() => handleClickColor(state.label)}>
                <Choices
                  label={state.label}
                  variant={
                    state.label === pivotBackPlateColor ? "filled" : "outlined"
                  }
                  checkMark={state.label === pivotBackPlateColor}
                  wide={true}
                />
              </div>
            ))}
          </RowLayoutLeftDivide>
        </ColLayout>
      </RowLayout>

      {pivotBackPlate && (
        <RowLayout>
          <Image
            cloudName="ag-millworks"
            secure="true"
            publicId={findImages(pivotBackPlate, pivotBackPlateColor || "")}
            style={{ marginRight: 20 }}
          >
            <Transformation width="150" crop="scale" />
          </Image>

          <Image
            cloudName="ag-millworks"
            secure="true"
            publicId="PivotHardware/thumbturn"
            style={{ marginLeft: 20 }}
          >
            <Transformation width="200" crop="scale" />
          </Image>
        </RowLayout>
      )}
    </ReusableAccordian>
  );
}

export default memo(PivotBackPlateAccordian);
