import React, { FC, ReactNode } from "react";
import { AppBar } from "@mui/material";
import { styled } from "@mui/material/styles";

interface WrapperProps {
  children: ReactNode;
}

const drawerWidthSidebar = 310;

const StyledAppbar = styled(AppBar)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${drawerWidthSidebar}px)`,
    marginLeft: drawerWidthSidebar,
  },
  display: "flex",
  marginTop: 75,
}));

const WrapperOver240: FC<WrapperProps> = ({ children }) => {
  return <StyledAppbar>{children}</StyledAppbar>;
};

const WrapperUnder240: FC<WrapperProps> = ({ children }) => {
  return (
    <div style={{ alignSelf: "stretch", position: "relative" }}>{children}</div>
  );
};

export { WrapperOver240, WrapperUnder240 };
