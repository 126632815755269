import React from "react";
import Box from "@mui/material/Box";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`fullwidth-tabpanel-${index}`}
      aria-labelledby={`fullwidth-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export default TabPanel;
