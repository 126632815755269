import React, { FC } from "react";
import { TableCell, IconButton } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import GenerateDealer from "../../../../../quotePDF/GenerateDealer";
import { HtmlTooltip } from "../StatusTooltip";
import { QuoteObj, UserObj, Overwrite } from "../../../../../GlobalTypes";

type AdminQuoteObj = Overwrite<QuoteObj, { userId: UserObj }>;

interface OrderPdfProps {
  handleViewPdf:
    | ((id: string, userId: string, quoteStatus: string) => void)
    | null;
  row: AdminQuoteObj;
  viewPdfId: string;
}

const OrderPdf: FC<OrderPdfProps> = ({ handleViewPdf, row, viewPdfId }) => {
  return (
    <TableCell padding="checkbox" align="center" style={{ width: 100 }}>
      {row.units && row.units.length > 0 && viewPdfId !== `${row._id}order` ? (
        <HtmlTooltip title={"Download Order PDF"}>
          <IconButton
            onClick={() =>
              handleViewPdf &&
              handleViewPdf(row._id, row.userId?._id || "", "order")
            }
            aria-label="download"
            size="large"
          >
            <GetAppIcon color={"primary"} />
          </IconButton>
        </HtmlTooltip>
      ) : row.units &&
        row.units.length > 0 &&
        viewPdfId === `${row._id}order` ? (
        <GenerateDealer
          isTable={true}
          isOrder={true}
          data={row as QuoteObj}
          series={row.seriesOverride || "Default"}
          codOrTerms={
            "codOrTerms" in row.userId && row.userId?.codOrTerms
              ? row.userId.codOrTerms
              : ""
          }
          poNumber={row.poNumber || ""}
          region={
            "region" in row.userId && row.userId?.region
              ? row.userId.region
              : ""
          }
          dealerTax={
            "dealerTax" in row.userId && row.userId?.dealerTax
              ? row.userId.dealerTax
              : 0
          }
          factor={row.factor}
          grandTotal={row.grandTotal || "N/A"}
          suggestedRetail={row.suggestedRetailPrice || "N/A"}
          salesOrder={row.salesOrder || ""}
        />
      ) : !row.units || !row.units.length ? (
        <HtmlTooltip title={"No Units in Quote"}>
          <IconButton aria-label="download" size="large">
            <GetAppIcon />
          </IconButton>
        </HtmlTooltip>
      ) : null}
    </TableCell>
  );
};

export default OrderPdf;
