import React, {
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  FC,
} from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";
import {
  Tooltip,
  IconButton,
  FormControl,
  InputAdornment,
  InputLabel,
  Button,
  Typography,
  TextField,
  Input,
  Alert,
} from "@mui/material";
import Copyright from "../components/Copyright";
import { styled } from "@mui/material/styles";

const HtmlTooltip = styled(Tooltip)(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const StyledForm = styled("form")(({ theme }) => ({
  "& .MuiTextField-root": {
    margin: theme.spacing(1),
    width: "25ch",
  },
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

const StyledDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundImage: `url(https://res.cloudinary.com/ag-millworks/image/upload/loginBg-min_cxibg7.jpg)`,
  minHeight: "100vh",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  position: "relative",
});

const StyledDivLogin = styled("div")({
  backgroundColor: "white",
  borderRadius: 5,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: 20,
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 5,
  marginTop: 20,
  width: 350,
});

const StyledImg = styled("img")(({ theme }) => ({
  objectFit: "contain",
  width: 750,
  aspectRatio: "auto",
  paddingBottom: 40,
  marginTop: -60,
  [theme.breakpoints.down("lg")]: {
    width: 500,
  },
  [theme.breakpoints.down("md")]: {
    width: 300,
  },
}));

const StyledButton = styled(Button)({
  alignSelf: "stretch",
  paddingLeft: 5,
  paddingRight: 5,
});

const StyledLink = styled(Link)({
  textDecoration: "none",
  marginTop: 10,
  width: "100%",
});

const StyledFPButton = styled(Button)({
  width: "100%",
  paddingLeft: 5,
  paddingRight: 5,
  color: "black",
});

const StyledTypography = styled(Typography)({
  textDecoration: "underline",
  paddingTop: 5,
  color: "grey",
});

interface LoginProps {
  handleAuthChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSignIn: (
    event: React.KeyboardEvent | React.MouseEvent<HTMLButtonElement>,
  ) => void;
  email: string;
  password: string;
  errorMessage: string;
  setUser: Dispatch<SetStateAction<string>>;
  loading: boolean;
}

const Login: FC<LoginProps> = ({
  handleAuthChange,
  handleSignIn,
  email,
  password,
  errorMessage,
  setUser,
  loading,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const sessionExpired =
    JSON.parse(localStorage.getItem("session") || JSON.stringify("active")) ===
    "expired";

  const handleEnterPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSignIn(e);
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event: React.MouseEvent) =>
    event.preventDefault();

  useEffect(() => {
    sessionStorage.clear();
    setUser("");
  }, []);

  return (
    <StyledDiv>
      <StyledImg
        src="https://res.cloudinary.com/ag-millworks/image/upload/AG_Logos/blacklogolong_compressed.png"
        alt="Black Logo"
      />

      <StyledDivLogin>
        {sessionExpired && (
          <Alert
            severity="error"
            style={{ fontSize: 10.5, fontWeight: "bold", textAlign: "center" }}
          >
            Your session has expired. Please log in again
          </Alert>
        )}

        <StyledForm noValidate autoComplete="off">
          <TextField
            style={{ width: "100%" }}
            onKeyPress={handleEnterPress}
            onChange={handleAuthChange}
            name="email"
            value={email || null}
            label={"Email"}
            variant="filled"
          />
          <FormControl style={{ marginTop: "10px", paddingBottom: 20 }}>
            <InputLabel htmlFor="standard-adornment-password">
              Password
            </InputLabel>
            <Input
              id="standard-adornment-password"
              type={showPassword ? "text" : "password"}
              style={{ paddingLeft: 10 }}
              onKeyPress={handleEnterPress}
              value={password || null}
              name="password"
              onChange={handleAuthChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {errorMessage && (
            <Typography style={{ color: "red" }} variant="body2">
              {errorMessage}
            </Typography>
          )}
        </StyledForm>
        <StyledButton
          onClick={(event) => handleSignIn(event)}
          variant="contained"
          color="primary"
        >
          {loading ? "Signing In..." : "Sign In"}
        </StyledButton>
        <StyledLink to="/forgotPassword">
          <StyledFPButton variant="contained" color="inherit">
            Forgot Password?
          </StyledFPButton>
        </StyledLink>
        <HtmlTooltip
          placement="bottom"
          title="Contact your Sales Rep for more information"
        >
          <StyledTypography variant="subtitle2">
            Need an Account?
          </StyledTypography>
        </HtmlTooltip>
      </StyledDivLogin>

      <Copyright position="right" />
    </StyledDiv>
  );
};

export default Login;
