import { convertDecimalToFraction } from "../../util/pdfFormulas/convertDecimalToFraction";

function calculateDLO(
  stiles: string,
  stilesAndRails: string,
  lockRail: string,
  panelWidth: number,
  panelHeight: number,
  doorType: string,
) {
  const euroStile = 3.4375;
  const tradStile = 4.75;
  const slimStile = 2.5;

  let sideStiles: number;
  let topRail: number;

  var glassWidthDD = "";

  switch (stiles) {
    case "Traditional":
      sideStiles = tradStile;
      topRail = tradStile;
      break;
    case "Euro":
      sideStiles = euroStile;
      topRail = euroStile;
      break;
    case "Slimline":
      if (doorType === "Swing") {
        sideStiles = 5.9375;
      } else {
        sideStiles = slimStile;
      }
      topRail =
        stiles &&
        stiles.includes("Slimline") &&
        doorType &&
        doorType.includes("Fritsjurgens")
          ? euroStile
          : slimStile;
      break;
    case "Slimline Traditional":
      sideStiles = slimStile;
      topRail = slimStile;
      break;
    default:
      sideStiles = 0;
      topRail = 0;
  }

  var glassWidth =
    (stiles === "Slimline" || stiles === "Slimline Traditional") &&
    doorType === "Swing"
      ? convertDecimalToFraction(panelWidth - sideStiles)
      : convertDecimalToFraction(panelWidth - 2 * sideStiles);

  const slimTradLockRail = '2-1/2" Stiles and Rails with 4-3/4" Lock Stile';
  const slimEuroLockRail = '2-1/2" Stiles and Rails with 3-7/16" Lock Stile';
  const slimSliderLockRail =
    '2-1/2" Stiles and Top Rails with 3-7/16" Lock Stile and Bottom Rails';

  const sqFt = (panelWidth * panelHeight) / 144;

  var bottomRail =
    stilesAndRails === 'Bottom rail 7"'
      ? 7
      : stilesAndRails === 'Bottom rail 4-3/4"'
        ? tradStile
        : stilesAndRails === "Euro | Contemporary" && sqFt > 50
          ? tradStile
          : stilesAndRails === "Euro | Contemporary" &&
              doorType === "Fritsjurgens Self Closing"
            ? tradStile
            : stilesAndRails === "Euro | Contemporary" ||
                lockRail === slimSliderLockRail
              ? euroStile
              : stiles &&
                  stiles.includes("Slimline") &&
                  doorType === "Fritsjurgens Self Closing"
                ? tradStile
                : slimStile;

  var glassHeight = convertDecimalToFraction(
    panelHeight - (topRail + bottomRail),
  );

  if (
    lockRail === slimTradLockRail ||
    lockRail === slimEuroLockRail ||
    lockRail === slimSliderLockRail
  ) {
    glassWidthDD = convertDecimalToFraction(
      panelWidth - (euroStile + slimStile),
    );
  }

  return { glassHeight, glassWidth, glassWidthDD };
}

function findHandlePosition(config: string, numPanels: number) {
  const original = config;
  const numsArray: string[] = config
    .replace("L", "")
    .replace("R", "")
    .split("");

  const isEven = (num: string) => Number(num) % 2 === 0;

  if (numsArray.length === 1) {
    if (isEven(numsArray[0])) return false;

    const handleSide = original[1] === "L" ? "Right" : "Left";
    const panelWithHandle = original[1] === "L" ? numPanels : 1;
    return { numHandles: 1, handleSide, panelWithHandle };
  }

  let numEvens = 0;
  numsArray.forEach((num) => {
    if (isEven(num)) {
      numEvens++;
    }
  });

  if (numEvens === 2) return false;

  if (numEvens === 1) {
    const even = numsArray.find((num) => isEven(num));
    const odd = numsArray.find((num) => !isEven(num));
    const oddSide =
      original[original.split("").findIndex((itm: string) => itm === odd) + 1];
    const handleSide = oddSide === "L" ? "Right" : "Left";
    const panelWithHandle = oddSide === "L" ? Number(odd) : Number(even) + 1;
    return { numHandles: 1, handleSide, panelWithHandle };
  }

  //if both sides are odds and have handles eg. 3L1R
  return {
    numHandles: 2,
    handleSideLeft: "Right",
    handleSideRight: "Left",
    panelWithHandleLeft: Number(numsArray[0]),
    panelWithHandleRight: Number(numsArray[0]) + 1,
  };
}

function findHandlePositionSwing(exactConfig: string, exactConfigImg: string) {
  if (exactConfig.includes("Single")) {
    const hingeSide = exactConfig.includes("LH") ? "Left" : "Right";

    const handleSide = hingeSide === "Left" ? "Right" : "Left";

    if (exactConfig.includes("1 Sidelite")) {
      const lockside = exactConfigImg.includes("LOCK");

      if (hingeSide === "Left") {
        if (lockside) {
          return {
            numHandles: 1,
            handleSide,
            panelWithHandle: 1,
          };
        } else {
          return {
            numHandles: 1,
            handleSide,
            panelWithHandle: 2,
          };
        }
      } else {
        if (lockside) {
          return {
            numHandles: 1,
            handleSide,
            panelWithHandle: 2,
          };
        } else {
          return {
            numHandles: 1,
            handleSide,
            panelWithHandle: 1,
          };
        }
      }
    } else if (exactConfig.includes("2 Sidelite")) {
      return {
        numHandles: 1,
        handleSide,
        panelWithHandle: 2,
      };
    } else {
      return {
        numHandles: 1,
        handleSide,
        panelWithHandle: 1,
      };
    }
  } else {
    if (exactConfig.includes("2 Sidelite")) {
      return {
        numHandles: 2,
        handleSideLeft: "Right",
        handleSideRight: "Left",
        panelWithHandleLeft: 2,
        panelWithHandleRight: 3,
      };
    } else {
      return {
        numHandles: 2,
        handleSideLeft: "Right",
        handleSideRight: "Left",
        panelWithHandleLeft: 1,
        panelWithHandleRight: 2,
      };
    }
  }
}

function findHandlePositionSlider(exactConfig: string, withPocket: boolean) {
  const doubleSided = exactConfig.includes("-");

  if (doubleSided) {
    const panelWithHandleLeft = exactConfig.split("-")[0].length;
    const panelWithHandleRight = panelWithHandleLeft + 1;

    return {
      numHandles: 2,
      handleSideLeft: "Right",
      handleSideRight: "Left",
      panelWithHandleLeft,
      panelWithHandleRight,
    };
  }

  if (withPocket) {
    const numHandles = 1;
    const handleSide = exactConfig[0] === "P" ? "Right" : "Left";

    if (handleSide === "Right") {
      const panelWithHandle = exactConfig.length;
      return {
        numHandles,
        handleSide,
        panelWithHandle,
      };
    } else {
      const panelWithHandle = 1;
      return {
        numHandles,
        handleSide,
        panelWithHandle,
      };
    }
  }

  if (exactConfig.includes("Primary")) {
    const numPanels = exactConfig.split(" ")[0].length;

    return {
      numHandles: 2,
      handleSideLeft: "Left",
      handleSideRight: "Right",
      panelWithHandleLeft: 1,
      panelWithHandleRight: numPanels,
    };
  }

  const numHandles = 1;
  const handleSide = exactConfig[0] === "O" ? "Right" : "Left";

  if (handleSide === "Right") {
    const panelWithHandle = exactConfig.length;
    return {
      numHandles,
      handleSide,
      panelWithHandle,
    };
  } else {
    const panelWithHandle = 1;
    return {
      numHandles,
      handleSide,
      panelWithHandle,
    };
  }
}

function findNumPanelsBifold(config: string): number {
  if (!config) return 1;
  const numsArray = config.replace("L", "").replace("R", "").split("");
  const numPanels = numsArray.reduce((a, b) => Number(a) + Number(b), 0);
  return numPanels;
}

function findNumPanelsSlider(config: string): number {
  if (!config) return 1;
  const numsArray = config.includes("Primary")
    ? config.split(" ")[0].split("")
    : config.replace("-", "").split("");
  const numPanels = numsArray.length;
  return numPanels;
}

function findNumPanelsSwing(swingType: string, sidelite: string): number {
  const active = swingType === "Pair Swing" ? 2 : 1;
  const fixed =
    sidelite === "none" ? 0 : sidelite === "Add Single Sidelite" ? 1 : 2;
  return active + fixed;
}

function findNumPanelsPivot(sidelite: string): number {
  const fixed =
    sidelite === "none" ? 0 : sidelite === "Add Single Sidelite" ? 1 : 2;
  return 1 + fixed;
}

interface FrameDimensions {
  headJamb: number;
  sill: number;
  jambLeg: number;
}

function frameDimensions(
  doorType: string,
  scale: number,
  swingDirection: string,
  hasSliderPocket: boolean,
  hasCentor: boolean,
  doubleSided: boolean,
  rightCornerJamb?: boolean,
  primarySide?: string,
  cornerType?: string,
): FrameDimensions {
  if (doorType.includes("Corner")) {
    let jambLeg = 0;

    if (cornerType === "Inside") {
      if (hasCentor) {
        jambLeg = 3;
      } else {
        jambLeg =
          rightCornerJamb && primarySide === "Left"
            ? 2
            : rightCornerJamb && primarySide === "Right"
              ? 4.375
              : 0.9375;
      }
    } else {
      if (hasCentor) {
        jambLeg = 3;
      } else {
        jambLeg =
          rightCornerJamb && primarySide === "Left"
            ? 2.125
            : rightCornerJamb && primarySide === "Right"
              ? 4.375
              : 0.9375;
      }
    }

    return {
      headJamb: hasCentor ? 3.25 * scale : 2 * scale,
      sill: 0.9375 * scale,
      jambLeg: jambLeg * scale,
    };
  }

  if (doorType.includes("Bi-fold")) {
    return {
      headJamb: hasCentor ? 3.25 * scale : 2 * scale,
      sill: 0.9375 * scale,
      jambLeg: hasCentor ? 4 * scale : 0.9375 * scale,
    };
  }

  if (doorType === "Slider" || doorType === "Fixed Slider") {
    return {
      headJamb: hasCentor ? 3.125 * scale : 0.875 * scale,
      sill: 1 * scale,
      jambLeg:
        hasCentor && !hasSliderPocket
          ? (3.125 + 0.875) * scale
          : hasSliderPocket && hasCentor && !doubleSided
            ? 1.5625 * scale
            : hasSliderPocket
              ? 0
              : 0.9375 * scale,
    };
  }

  if (doorType === "Fixed Full Bound") {
    return {
      headJamb: 0.875 * scale,
      sill: 0.5625 * scale,
      jambLeg: 0.875 * scale,
    };
  }

  if (
    doorType === "Swing" ||
    doorType === "Fixed Outswing" ||
    doorType === "Fixed Inswing"
  ) {
    if (swingDirection === "Outswing" || doorType === "Fixed Outswing") {
      return {
        headJamb: hasCentor ? 2.5 * scale : 0.875 * scale,
        sill: 0.5625 * scale,
        jambLeg: hasCentor ? (3.125 + 0.875) * scale : 0.875 * scale,
      };
    }

    if (swingDirection === "Inswing" || doorType === "Fixed Inswing") {
      return {
        headJamb: 0.9375 * scale,
        sill: 1.125,
        jambLeg: 0.9375 * scale,
      };
    }
  }

  if (doorType === "Pivot") {
    return {
      headJamb: 0.9375 * scale,
      sill: 1.125,
      jambLeg: 0.9375 * scale,
    };
  }

  return {
    headJamb: 0,
    sill: 0,
    jambLeg: 0,
  };
}

interface PocketSliderCalc {
  pocketSize: number;
  netWidth: number;
}

function pocketSliderCalc(
  doubleSided: boolean,
  daylightWidth: number,
  panelWidth: number,
): PocketSliderCalc {
  let pocketSize = roundHalf(panelWidth + 2.375);

  function roundHalf(num: number) {
    const half = Math.ceil(num * 2) / 2;

    return half;
  }

  const netWidth = doubleSided
    ? pocketSize + pocketSize + daylightWidth
    : pocketSize + daylightWidth;

  return { pocketSize, netWidth };
}

function handleImage(
  doorType: string,
  handle: string,
  handleSide: string,
  handleColor: string,
) {
  const URL =
    "https://res.cloudinary.com/ag-millworks/image/upload/Canvas/handles";

  if (doorType === "Pivot") {
    const color = handleColor.includes("Black") ? "Black" : "Steel";
    return `${URL}/Pivot/Dallas_${handleSide}_${color}.svg`;
  }

  if (doorType === "Slider") {
    if (handle.includes("Truth")) {
      const color = handleColor.replace(" PVD", "").split(" ").join("_");
      return `${URL}/Slider/Truth_${color}.svg`;
    }

    const color = handleColor
      .replace("(Resista Finish)", "")
      .replace(" *", "")
      .split(" ")
      .join("_");

    if (handle.includes("Dallas")) {
      return `${URL}/Slider/Dallas_${handleSide}_${color}.svg`;
    } else {
      return `${URL}/Slider/Verona_${handleSide}_${color}.svg`;
    }
  }

  if (handle.includes("*")) {
    const color = handleColor
      .replace("(Resista Finish)", "")
      .split(" ")
      .join("_");
    return `${URL}/Bifold_Swings/${handle
      .replace(" *", "")
      .replace(" ", "_")}_${handleSide}_${color}.svg`;
  }

  const color = handleColor
    .replace("(Resista Finish)", "")
    .replace(" *", "")
    .split(" ")
    .join("_");

  return `${URL}/Bifold_Swings/${handle}_${handleSide}_${color}.svg`;
}

function pivotBackPlateImage(backplate: string, backplateColor: string) {
  const URL =
    "https://res.cloudinary.com/ag-millworks/image/upload/Canvas/handles/Pivot";
  return `${URL}/${backplate.split(" ").join("_")}_${backplateColor.replace(" ", "_")}.svg`;
}

function getScale(
  doorType: string,
  netWidthCornerRight: number,
  netWidth: number,
  netHeight: number,
  canvasWidthVar: number,
  canvasHeightVar: number,
  sliderPocket: string,
  pocketSliderWidth: number,
) {
  const canvasSub = 200;
  const disclaimer = 30;

  const canvasWidth = canvasWidthVar - canvasSub - disclaimer;
  const canvasHeight = canvasHeightVar - canvasSub - disclaimer;

  const isCorner = doorType.includes("Corner");

  if (isCorner) {
    const totalWidth = netWidth + netWidthCornerRight;

    if (totalWidth >= netHeight) {
      return (canvasWidth + 30) / totalWidth;
    }

    return (canvasHeight + 30) / netHeight;
  }

  if (doorType === "Slider" && sliderPocket === "With Pocket") {
    if (pocketSliderWidth >= netHeight) {
      return canvasWidth / pocketSliderWidth;
    }

    return canvasHeight / netHeight;
  }

  if (doorType === "Swing" || doorType === "Pivot") {
    if (netWidth >= netHeight) {
      return canvasWidth / netWidth;
    }

    return canvasHeight / netHeight;
  }

  if (netWidth >= netHeight) {
    return canvasWidth / netWidth;
  }

  return canvasHeight / netHeight;
}

function getGaps(
  scale: number,
  doorType: string,
  exactConfig: string,
  exactConfigImg: string,
) {
  if (doorType === "Swing") {
    if (exactConfig.includes("Outswing")) {
      if (exactConfig.includes("Single")) {
        if (exactConfig.includes("1 Sidelite")) {
          const lockside = exactConfigImg.includes("LOCK");

          if (exactConfig.includes("LH")) {
            if (lockside) {
              return {
                panel1: 0.125 * scale,
                panel2: (0.125 + 0.875) * scale,
              };
            } else {
              return {
                panel1: 0,
                panel2: (0.125 + 0.875) * scale,
              };
            }
          } else {
            //RH
            if (lockside) {
              return {
                panel1: 0,
                panel2: (0.125 + 0.875) * scale,
              };
            } else {
              return {
                panel1: 0.125 * scale,
                panel2: (0.125 + 0.875) * scale,
              };
            }
          }
        } else if (exactConfig.includes("2 Sidelite")) {
          return {
            panel1: 0,
            panel2: (0.125 + 0.875) * scale,
            panel3: (0.875 + 0.125) * scale,
          };
        } else {
          return {
            panel1: 0.125 * scale,
          };
        }
      } else {
        if (exactConfig.includes("2 Sidelite")) {
          return {
            panel1: 0,
            panel2: (0.125 + 0.875) * scale,
            panel3: (0.9375 + 0.125) * scale,
            panel4: 0.875 * scale,
          };
        } else {
          return {
            panel1: 0.125 * scale,
            panel2: (0.9375 + 0.125) * scale,
          };
        }
      }
    } else {
      //Inswing
      if (exactConfig.includes("Single")) {
        if (exactConfig.includes("1 Sidelite")) {
          const lockside = exactConfigImg.includes("LOCK");

          if (exactConfig.includes("LH")) {
            if (lockside) {
              return {
                panel1: 0.0937 * scale,
                panel2: (0.0937 + 1.875) * scale,
              };
            } else {
              return {
                panel1: 0,
                panel2: (0.0937 + 1.875) * scale,
              };
            }
          } else {
            //RH
            if (lockside) {
              return {
                panel1: 0,
                panel2: (0.0937 + 1.875) * scale,
              };
            } else {
              return {
                panel1: 0.0937 * scale,
                panel2: (0.0937 + 1.875) * scale,
              };
            }
          }
        } else if (exactConfig.includes("2 Sidelite")) {
          return {
            panel1: 0,
            panel2: (0.0937 + 1.875) * scale,
            panel3: (1.875 + 0.0937) * scale,
          };
        } else {
          return {
            panel1: 0.0937 * scale,
          };
        }
      } else {
        if (exactConfig.includes("2 Sidelite")) {
          return {
            panel1: 0,
            panel2: (0.0937 + 1.875) * scale,
            panel3: (0.9375 + 0.0937) * scale,
            panel4: 1.875 * scale,
          };
        } else {
          return {
            panel1: 0.0937 * scale,
            panel2: (0.9375 + 0.0937) * scale,
          };
        }
      }
    }
  }

  if (doorType === "Pivot") {
    if (exactConfig.includes("1 Sidelite")) {
      const lockside = exactConfigImg.includes("LOCK");

      if (exactConfig.includes("LH")) {
        if (lockside) {
          return {
            panel1: 0.125 * scale,
            panel2: (2.875 - 0.5 + 0.0937) * scale,
          };
        } else {
          return {
            panel1: 0,
            panel2: (2.875 - 0.5 + 0.0937) * scale,
          };
        }
      } else {
        //RH
        if (lockside) {
          return {
            panel1: 0,
            panel2: (2.875 - 0.5 + 0.0937) * scale,
          };
        } else {
          return {
            panel1: 0.125 * scale,
            panel2: (2.875 - 0.5 + 0.0937) * scale,
          };
        }
      }
    } else if (exactConfig.includes("2 Sidelite")) {
      if (exactConfig.includes("LH")) {
        return {
          panel1: 0,
          panel2: (2.375 - 0.5 + 0.0937) * scale,
          panel3: (2.875 - 0.5 + 0.0937) * scale,
        };
      } else {
        return {
          panel1: 0,
          panel2: (2.875 - 0.5 + 0.0937) * scale,
          panel3: (2.375 - 0.5 + 0.0937) * scale,
        };
      }
    } else {
      if (exactConfig.includes("LH")) {
        return {
          panel1: 0.125 * scale,
        };
      } else {
        return {
          panel1: 0.0937 * scale,
        };
      }
    }
  }

  if (doorType.includes("Bi-fold")) {
    return {
      panel1: 0.125 * scale,
      panel2: 0.125 * scale,
      panel3: 0.125 * scale,
      panel4: 0.125 * scale,
      panel5: 0.125 * scale,
      panel6: 0.125 * scale,
      panel7: 0.125 * scale,
      panel8: 0.125 * scale,
    };
  }

  if (doorType === "Slider") {
    const panels = {};

    if (exactConfig.includes("Primary")) {
      const numPanels = exactConfig.split(" ")[0].length;
      for (let i = 0; i < numPanels; i++) {
        panels[`panel${i + 1}`] =
          i === 0 || i + 1 === numPanels ? 0.1875 * scale : 0;
      }
      return panels;
    }

    const doubleSided = exactConfig.includes("-");

    if (exactConfig.includes("P")) {
      //pocketing
      const config = exactConfig;
      const numPanels = config.replace("-", "").length;

      if (doubleSided) {
        const firstHandle = config.split("-")[0].length;
        for (let i = 0; i < numPanels; i++) {
          panels[`panel${i + 1}`] =
            i + 1 === firstHandle + 1 ? 0.1875 * scale : 0;
        }
        return panels;
      }

      const pocketSide = exactConfig[0] === "P" ? "Left" : "Right";
      for (let i = 0; i < numPanels; i++) {
        panels[`panel${i + 1}`] =
          (i === 0 && pocketSide === "Right") ||
          (i + 1 === numPanels && pocketSide === "Left")
            ? 0.1875 * scale
            : 0;
      }
      return panels;
    }

    const numPanels = exactConfig.replace("-", "").length;

    if (doubleSided) {
      const firstHandle = exactConfig.split("-")[0].length;

      for (let i = 0; i < numPanels; i++) {
        panels[`panel${i + 1}`] =
          i + 1 === firstHandle + 1 ? 0.1875 * scale : 0;
      }
      return panels;
    }

    const fixedPanelSide = exactConfig[0] === "O" ? "Left" : "Right";

    for (let i = 0; i < numPanels; i++) {
      panels[`panel${i + 1}`] =
        (i === 0 && fixedPanelSide === "Right") ||
        (i + 1 === numPanels && fixedPanelSide === "Left")
          ? 0.1875 * scale
          : 0;
    }
    return panels;
  }

  return {
    panel1: 0,
    panel2: 0,
    panel3: 0,
    panel4: 0,
    panel5: 0,
    panel6: 0,
    panel7: 0,
    panel8: 0,
  };
}

export {
  pocketSliderCalc,
  findHandlePosition,
  findHandlePositionSwing,
  findNumPanelsBifold,
  findNumPanelsSlider,
  calculateDLO,
  findNumPanelsSwing,
  frameDimensions,
  findNumPanelsPivot,
  handleImage,
  getScale,
  getGaps,
  findHandlePositionSlider,
  pivotBackPlateImage,
};
