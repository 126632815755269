import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { ContactSupportOutlined } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { styled } from "@mui/material/styles";

const HtmlTooltip = styled(Tooltip)(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const StyledDiv = styled("div")({
  position: "fixed",
  right: 20,
  bottom: 10,
  zIndex: 1000,
});

const StyledFab = styled(Fab)({
  backgroundColor: "#57d5f2",
  fontSize: 40,
});

const StyledContactIcon = styled(ContactSupportOutlined)({
  fontSize: 40,
  color: "white",
});

const TechSupportTooltip = () => {
  return (
    <StyledDiv>
      <HtmlTooltip
        title="For Techincal Support Contact: customerservice@agmillworks.com"
        placement="top"
        enterTouchDelay={0}
      >
        <StyledFab>
          <StyledContactIcon />
        </StyledFab>
      </HtmlTooltip>
    </StyledDiv>
  );
};

export default TechSupportTooltip;
