import React, { useState, useEffect, useRef, FC } from "react";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { SelectChangeEvent } from "@mui/material";
import AccordianWrapper from "./AccordianWrapper";
import ExpiredModal from "./ExpiredModal";
import Carousel from "./Carousel";
import BifoldTypeSelect from "./BifoldTypeSelect";
import ResetWarningModal from "./ResetWarningModal";
import { ArrowDropDown } from "@mui/icons-material";
import { useStore } from "../store/store";
import { useAccordion } from "../store/accordion";
import TabPanel from "./TabPanel";
import { styled } from "@mui/material";

function a11yProps(index: string | number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const StyledRootDiv = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: "100%",
  marginTop: 20,
}));

const StyledUnitChoiceDiv = styled("div")({
  width: "100%",
  backgroundColor: "#fafafa",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledFlexCenterDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const tabArray = [
  { door: "Slider", label: "Slider Door" },
  { door: "Swing", label: "Swing Door" },
  { door: "Pivot", label: "Pivot Door" },
  { door: "Fixed Panel", label: "Fixed Panel" },
];

interface DoorTabsProps {
  windowOrDoor: string;
  setWindowOrDoor: (choice: string) => void;
  resetAllValues: () => void;
  editQuote: boolean;
  submitPressed: boolean;
  addDoorToSidebar: (withCheck?: boolean, ignoreUnitCheck?: boolean) => void;
}

const DoorTabs: FC<DoorTabsProps> = ({
  windowOrDoor,
  setWindowOrDoor,
  resetAllValues,
  editQuote,
  submitPressed,
  addDoorToSidebar,
}) => {
  const theme = useTheme();

  const editBtn = useStore((state) => state.isEdit);

  const editRef = useRef(true);

  useEffect(() => {
    if (editBtn) return;
    editRef.current = true;
  }, [editBtn]);

  const resetText = useStore((state) => state.resetModalText);
  const clearModalText = useStore((state) => state.clearModalText);

  const [modalText, setModalText] = useState("");

  const [open, setOpen] = useState(false);

  const [reservedVal, setReservedVal] = useState("");
  const doorType = useStore((state) => state.quote.doorType);

  const addToQuote = useStore((state) => state.addToQuote);

  const checkForEmptyVals = useStore((state) => state.checkForEmptyVals);

  const handleMinimize = useAccordion((state) => state.handleMinimize);

  const unitChoiceBifoldChange = (event: SelectChangeEvent<string>) => {
    if (editBtn && !doorType.includes("Bi-fold")) return null;

    setWindowOrDoor(event.target.value);
  };

  const handleBifoldChange = (event: SelectChangeEvent<string>) => {
    if (editBtn || !doorType.includes("Bi-fold")) return null;
    const { value } = event.target;

    setWindowOrDoor(value);

    addToQuote({
      doorType: value.replace("F", "f"),
      stilesAndRails: "",
      stileCategory: "",
      lockRail: "",
    });
  };

  const handleOpen = (text: string) => {
    setModalText(text);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleChange = (
    event: SelectChangeEvent<unknown> | "",
    newValue: string,
  ) => {
    if (
      (doorType.includes("Bi-fold") && newValue === "Bi-fold") ||
      doorType === newValue ||
      editBtn
    )
      return null;

    setReservedVal(newValue);

    const hasVals = checkForEmptyVals();

    if (hasVals) {
      handleOpen(
        "This action will reset your Existing Selections. Are you sure you want to continue?",
      );
    } else {
      if (newValue.includes("Bi-fold")) {
        addToQuote({
          doorType: windowOrDoor?.replace("F", "f"),
        });
      } else {
        addToQuote({ doorType: newValue });
      }
      handleMinimize();
    }
  };

  const switchTabs = () => {
    resetAllValues();
    handleMinimize();
    handleClose();
    if (reservedVal.includes("Bi-fold")) {
      return addToQuote({
        doorType: windowOrDoor?.replace("F", "f"),
      });
    }
    addToQuote({ doorType: reservedVal });
  };

  const [openResetModal, setOpenResetModal] = useState(false);
  const [resetModalText, setResetModalText] = useState<string[]>([]);

  useEffect(() => {
    if (!resetText || !resetText.length) return;

    if (editBtn && editRef.current === true) {
      editRef.current = false;
      return;
    }

    handleOpenReset(resetText);
  }, [resetText]);

  const handleOpenReset = (modalText: string[]) => {
    setOpenResetModal(true);
    setResetModalText(modalText);
  };

  const handleCloseReset = () => {
    setOpenResetModal(false);
    setResetModalText([]);
    clearModalText();
  };

  const tabIndex = doorType?.includes("Bi-fold") ? "Bi-fold" : doorType;

  const bifoldTabLabel = windowOrDoor.replace("F", "f").includes("Corner")
    ? "Bi-Fold Zero Corner"
    : windowOrDoor.replace("F", "f").includes("Window")
      ? "Bi-Fold Window"
      : "Bi-Fold Door";

  const bfoldNonFocus = (
    <StyledFlexCenterDiv>
      <Typography>{bifoldTabLabel}</Typography>
      <ArrowDropDown />
    </StyledFlexCenterDiv>
  );

  return (
    <StyledRootDiv>
      <ResetWarningModal
        open={openResetModal}
        handleClose={handleCloseReset}
        modalText={resetModalText}
      />

      {!doorType && (
        <StyledUnitChoiceDiv>
          <Carousel
            reset={resetAllValues}
            bifold={windowOrDoor}
            handleBifoldChange={unitChoiceBifoldChange}
            windowOrDoor={windowOrDoor}
          />
        </StyledUnitChoiceDiv>
      )}

      {doorType && (
        <>
          <AppBar position="static" color="default">
            <Tabs
              value={doorType}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab
                style={{
                  opacity: editBtn && !doorType.includes("Bi-fold") ? 0.4 : 1,
                }}
                value={windowOrDoor.replace("F", "f")}
                label={
                  !doorType.includes("Bi-fold") ? (
                    <>{bfoldNonFocus}</>
                  ) : (
                    <BifoldTypeSelect
                      fontSize=".875rem"
                      windowOrDoor={windowOrDoor}
                      handleChange={handleBifoldChange}
                    />
                  )
                }
                onClick={() => handleChange("", "Bi-fold")}
                {...a11yProps("Bi-fold")}
              />
              {tabArray.map((tab) => (
                <Tab
                  key={tab.door}
                  style={{
                    opacity: editBtn && doorType !== tab.door ? 0.4 : 1,
                  }}
                  value={tab.door}
                  label={tab.label}
                  onClick={() => handleChange("", tab.door)}
                  {...a11yProps(tab.door)}
                />
              ))}
            </Tabs>
          </AppBar>

          <ExpiredModal
            open={open}
            modalText={modalText}
            changeDoor={true}
            handleClose={handleClose}
            switchTabs={switchTabs}
          />

          <TabPanel dir={theme.direction} index={tabIndex} value={tabIndex}>
            <AccordianWrapper
              resetValues={resetAllValues}
              doorType={doorType}
              editQuote={editQuote}
              submitPressed={submitPressed}
              addDoorToSidebar={addDoorToSidebar}
            />
          </TabPanel>
        </>
      )}
    </StyledRootDiv>
  );
};

export default DoorTabs;
