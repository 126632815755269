const noAccess = [
  "2L",
  "2R",
  "2L2R",
  "4L",
  "4R",
  "6L",
  "6R",
  "4L2R",
  "2L4R",
  "8L",
  "8R",
  "6L2R",
  "2L6R",
  "4L4R",
];

export default noAccess;
