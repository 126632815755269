import React from "react";
import { StarBorder, MenuBook, NewReleases, Star } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Image } from "cloudinary-react";
import { styled } from "@mui/material/styles";

const StyledDivRow = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
});

const StyledAnchor = styled("a")({
  fontSize: 12,
  paddingLeft: 10,
  color: "blue",
});

const styles = {
  row: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 10,
  },
  rowDark: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "whitesmoke",
    padding: 11,
  },
  font: { fontSize: 30 },
};

const News = () => {
  return (
    <>
      {newsTopics.map((item, index) => (
        <div key={index} style={index % 2 !== 1 ? styles.rowDark : styles.row}>
          {index === 1 ? (
            <StarBorder color="primary" style={styles.font} />
          ) : index === 2 ? (
            <Image cloudName="ag-millworks" secure="true" publicId="millie30" />
          ) : index === 3 ? (
            <NewReleases color={"cyan" as "primary"} style={styles.font} />
          ) : index === 4 ? (
            <MenuBook color="primary" style={styles.font} />
          ) : (
            <Star style={{ ...styles.font, color: "#7f662d" }} />
          )}
          <StyledDivRow>
            <Typography
              style={{
                paddingLeft: 40,
                fontWeight: index === 0 ? "bold" : "normal",
              }}
            >
              {" "}
              {item.topic}
            </Typography>
            {(index === 3 || index === 0) && (
              <StyledAnchor target="_blank" rel="noreferrer" href={item.link}>
                Learn More
              </StyledAnchor>
            )}
          </StyledDivRow>
        </div>
      ))}
    </>
  );
};

const newsTopics = [
  {
    topic: "New! Centor Screen/Shade now available",
    link: "https://agmillworks.com/retractable-screens/",
  },
  {
    topic: "8ft x 10ft maximum Slider panel size",
  },
  {
    topic:
      "Virtual assistant will help catch potential mismatched options in your cart",
  },
  {
    topic: "Quote Up to 48 Inch Bi-Fold Panel Width!",
    link: "https://mailchi.mp/6b8956dea08a/bigger-doors-more-glassits-a-big-deal",
  },
  { topic: 'Check Out Our 2 1/2" Ultra-Modern Slimline Stiles' },
];

export default News;
