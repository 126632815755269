const spacerColorSdl = [
  {
    id: 1,
    type: "spacerColor",
    label: "Black",
  },
];

const spacerColor = [
  {
    id: 1,
    type: "spacerColor",
    label: "Black",
  },
  {
    id: 2,
    type: "spacerColor",
    label: "Mill",
  },
];

export { spacerColor, spacerColorSdl };
