import React, { memo } from "react";
import {
  Typography,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import Choices from "../Choices";
import ReusableAccordian from "../ReusableAccordion";
import { Image, Transformation } from "cloudinary-react";
import { useStore } from "../../store/store";
import { useAccordion } from "../../store/accordion";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import { fin, options } from "./defaults";
import Video from "../Video";
import { RowLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";

interface UpdateObj {
  flashingFin?: string;
  flashingFinConfig?: string;
  finSetback?: string;
}

const RowLayoutLeftDivide = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  borderLeft: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
}));

const RowLayoutSpace = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  padding: theme.spacing(1, 2),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 150,
  height: 20,
}));

const StyledDivRow = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
});

function FinAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);

  const finChoice = useStore((state) => state.quote.flashingFin);
  const finConfigChoice = useStore((state) => state.quote.flashingFinConfig);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[22].expanded);
  const require = useAccordion((state) => state.expanded[22].require);

  const fractionValue = useStore((state) => state.quote.finSetback);

  const screenType = useStore((state) => state.quote.screenType);
  const addScreen = useStore((state) => state.quote.addScreen);

  const isCentor = addScreen && screenType?.includes("Centor") ? true : false;

  const handleChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;

    if (!value) {
      addToQuote({ finSetback: "" });
    } else {
      addToQuote({ finSetback: value });
      if (findRequired("Flashing Fin Custom Setback"))
        removeRequired("Flashing Fin Custom Setback");
    }
  };

  const showSetbackInput =
    finChoice && finConfigChoice === "Custom Setback" ? true : false;

  const handleClickFin = (label: string) => {
    const checked = label === finChoice;

    if (checked) {
      addToQuote({
        flashingFinConfig: "",
        flashingFin: "",
      });
    } else {
      if (findRequired("Flashing Fin Custom Setback")) {
        removeRequired("Flashing Fin Setback");
        removeRequired("Flashing Fin Custom Setback");
      }

      let update: UpdateObj = {
        flashingFin: label,
        flashingFinConfig: 'Standard 1" Setback',
      };

      if (fractionValue) update["finSetback"] = "";
      addToQuote(update);
    }
  };

  const handleClickOptions = (label: string) => {
    const checked = label === finConfigChoice;

    let update: UpdateObj = { finSetback: "" };

    if (checked) {
      update["flashingFinConfig"] = "";
    } else {
      if (findRequired("Flashing Fin Custom Setback")) {
        removeRequired("Flashing Fin Setback");
      }
      update["flashingFinConfig"] = label;
    }

    addToQuote(update);
  };

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="fin"
      heading="Flashing Fin"
      secondaryHeading="Add flashing fin?"
      colOrRow="col"
      required={require ? true : false}
    >
      <Typography variant="caption">
        PLEASE NOTE: flashing fins are reccommended for proper installation
      </Typography>
      <StyledDivRow>
        <RowLayoutSpace>
          <div onClick={() => handleClickFin(fin.label)}>
            <Choices
              label={isCentor ? fin.label.replace("(A)", "(C & D)") : fin.label}
              disabled={false}
              variant={fin.label === finChoice ? "filled" : "outlined"}
              checkMark={fin.label === finChoice}
            />
          </div>
        </RowLayoutSpace>
        {finChoice && (
          <>
            <RowLayoutLeftDivide>
              {options.map((state) => (
                <div
                  key={state.id}
                  onClick={() => handleClickOptions(state.label)}
                >
                  <Choices
                    label={state.label}
                    disabled={false}
                    variant={
                      state.label === finConfigChoice ? "filled" : "outlined"
                    }
                    checkMark={state.label === finConfigChoice}
                  />
                </div>
              ))}
            </RowLayoutLeftDivide>

            {showSetbackInput && (
              <RowLayout>
                <StyledFormControl size="small">
                  <InputLabel>Setback</InputLabel>
                  <Select
                    value={fractionValue}
                    onChange={handleChange}
                    name="fractionValue"
                    label="Setback"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"1/4"}>1/4</MenuItem>
                    <MenuItem value={"5/16"}>5/16</MenuItem>
                    <MenuItem value={"3/8"}>3/8</MenuItem>
                    <MenuItem value={"7/16"}>7/16</MenuItem>
                    <MenuItem value={"1/2"}>1/2</MenuItem>
                    <MenuItem value={"9/16"}>9/16</MenuItem>
                    <MenuItem value={"5/8"}>5/8</MenuItem>
                    <MenuItem value={"11/16"}>11/16</MenuItem>
                    <MenuItem value={"3/4"}>3/4</MenuItem>
                    <MenuItem value={"13/16"}>13/16</MenuItem>
                    <MenuItem value={"7/8"}>7/8</MenuItem>
                    <MenuItem value={"15/16"}>15/16</MenuItem>
                    <MenuItem value={"1"}>1</MenuItem>
                    <MenuItem value={"1 1/16"}>1-1/16</MenuItem>
                    <MenuItem value={"1 1/8"}>1-1/8</MenuItem>
                    <MenuItem value={"1 3/16"}>1-3/16</MenuItem>
                    <MenuItem value={"1 1/4"}>1-1/4</MenuItem>
                    <MenuItem value={"1 5/16"}>1-5/16</MenuItem>
                    <MenuItem value={"1 3/8"}>1-3/8</MenuItem>
                    <MenuItem value={"1 7/16"}>1-7/16</MenuItem>
                    <MenuItem value={"1 1/2"}>1-1/2</MenuItem>
                  </Select>
                </StyledFormControl>
              </RowLayout>
            )}
          </>
        )}
      </StyledDivRow>

      {finChoice && !isCentor && (
        <RowLayout>
          <div>
            <Image
              cloudName="ag-millworks"
              secure="true"
              publicId="v1670437009/FlashingFin/fin_1inch_setback"
            >
              <Transformation height="250" width="400" crop="scale" />
            </Image>
          </div>
        </RowLayout>
      )}

      {finChoice && isCentor && (
        <RowLayout>
          <div>
            <Image
              cloudName="ag-millworks"
              secure="true"
              publicId="v1670437009/FlashingFin/fin_centor"
            >
              <Transformation height="250" width="400" crop="scale" />
            </Image>
          </div>
        </RowLayout>
      )}

      <Video src="https://www.youtube.com/watch?v=qFQ_DusKIP8" />
    </ReusableAccordian>
  );
}

export default memo(FinAccordian);
