import React, { FC } from "react";
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
});

interface MatchingTaxResultsProps {
  tax: number | string;
  handleTaxChange: (event: SelectChangeEvent<string>) => void;
  taxResults: number[];
}

const MatchingTaxResults: FC<MatchingTaxResultsProps> = ({
  tax,
  handleTaxChange,
  taxResults,
}) => {
  return (
    <StyledDiv>
      <Typography style={{ color: "red", padding: 20 }}>
        Whoops! There are multiple Sales Taxes matching that area.
      </Typography>
      <Typography style={{ color: "red", padding: 5 }}>
        Select your desired Sales Tax from the dropdown to continue.
      </Typography>

      <FormControl style={{ width: 150 }}>
        <InputLabel>
          Select Sales Tax <span style={{ color: "red", fontSize: 22 }}>*</span>
        </InputLabel>
        <Select
          native
          value={String(tax)}
          size="small"
          onChange={handleTaxChange}
          label="Select Sales Tax"
        >
          <option value="" />
          {taxResults.map((item) => (
            <option key={item} value={item}>{`${(item * 100).toFixed(
              3,
            )}%`}</option>
          ))}
        </Select>
      </FormControl>
    </StyledDiv>
  );
};

export default MatchingTaxResults;
