const findMinMaxInswing = (
  equal: boolean,
  sideLites: string,
  swingType: string,
  isPivot?: boolean,
) => {
  var min: number | null = 0;
  var max: number | null = 0;

  if (isPivot) {
    if (equal) {
      min = sideLites === "Add Single Sidelite" ? 60 : 89.875;

      max = sideLites === "Add Single Sidelite" ? 124 : 185.875;
    } else {
      min =
        sideLites === "none"
          ? 30.125
          : sideLites === "Add Single Sidelite"
            ? 60
            : 89.875;

      max =
        sideLites === "none"
          ? 98.125
          : sideLites === "Add Single Sidelite"
            ? 160
            : 221.875;
    }
  } else if (equal) {
    min =
      sideLites === "none" && swingType === "Single Swing"
        ? 26
        : sideLites === "none" && swingType === "Pair Swing"
          ? 50
          : sideLites === "Add Single Sidelite" && swingType === "Single Swing"
            ? 51.9375
            : sideLites === "Add Double Sidelite" &&
                swingType === "Single Swing"
              ? 77.8125
              : sideLites === "Add Double Sidelite" &&
                  swingType === "Pair Swing"
                ? 102.4375
                : null;

    max =
      sideLites === "none" && swingType === "Single Swing"
        ? 45
        : sideLites === "none" && swingType === "Pair Swing"
          ? 87
          : sideLites === "Add Single Sidelite" && swingType === "Single Swing"
            ? 87.9375
            : sideLites === "Add Double Sidelite" &&
                swingType === "Single Swing"
              ? 131.8125
              : sideLites === "Add Double Sidelite" &&
                  swingType === "Pair Swing"
                ? 174.4375
                : null;
  } else {
    min =
      sideLites === "none" && swingType === "Single Swing"
        ? 26
        : sideLites === "none" && swingType === "Pair Swing"
          ? 50
          : sideLites === "Add Single Sidelite" && swingType === "Single Swing"
            ? 38
            : sideLites === "Add Double Sidelite" &&
                swingType === "Single Swing"
              ? 51
              : sideLites === "Add Double Sidelite" &&
                  swingType === "Pair Swing"
                ? 76
                : null;

    max =
      sideLites === "none" && swingType === "Single Swing"
        ? 45
        : sideLites === "none" && swingType === "Pair Swing"
          ? 87
          : sideLites === "Add Single Sidelite" && swingType === "Single Swing"
            ? 88
            : sideLites === "Add Double Sidelite" &&
                swingType === "Single Swing"
              ? 132
              : sideLites === "Add Double Sidelite" &&
                  swingType === "Pair Swing"
                ? 175
                : null;
  }

  return { min, max };
};

export default findMinMaxInswing;
