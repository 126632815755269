function getBifoldKerfImage(
  screen: string,
  screenType: string,
  material: string,
) {
  if (material === "All Wood") {
    return !screen
      ? "kerf/bifold/WOOD_HEAD_JAMB_NOSCR"
      : screenType?.includes("Centor")
        ? "kerf/bifold/WOOD_HEAD_JAMB_CENTOR"
        : "kerf/bifold/WOOD_HEAD_JAMB_SCR";
  }

  return !screen
    ? "kerf/bifold/HEAD_JAMB_NOSCR"
    : screenType?.includes("Centor")
      ? "kerf/bifold/HEAD_JAMB_CENTOR"
      : "kerf/bifold/HEAD_JAMB_SCR";
}

function getSliderKerfImage(screen: string, material: string) {
  if (material === "All Wood")
    return !screen
      ? "kerf/slider/WOOD_HEAD_JAMB_WIDE"
      : "kerf/slider/WOOD_HEAD_JAMB_WIDE_SCR";

  return !screen
    ? "kerf/slider/HEAD_JAMB_WIDE"
    : "kerf/slider/HEAD_JAMB_WIDE_SCR";
}

function getSwingKerfImage(
  direction: string,
  screen: string,
  screenType: string,
  material: string,
) {
  if (direction === "Outswing") {
    if (material === "All Wood")
      return !screen
        ? "kerf/swing/WOOD_OS_NOSCR_WIDE"
        : screenType?.includes("Centor")
          ? "kerf/swing/WOOD_OS_CENTOR_WIDE"
          : "kerf/swing/WOOD_OS_SCR_WIDE";

    return !screen
      ? "kerf/swing/OS_NOSCR_WIDE"
      : screenType?.includes("Centor")
        ? "kerf/swing/OS_CENTOR_WIDE"
        : "kerf/swing/OS_SCR_WIDE";
  }

  if (material === "All Wood") return "kerf/swing/WOOD_IS_WIDE";

  return "kerf/swing/IS_WIDE";
}

function getFPKerfImage(material: string, config: string) {
  const path = "kerf/fixedPanel";
  const materialLbl = material === "All Wood" ? "wood_" : "";

  let label = "";

  switch (config) {
    case "Fixed Bi-fold":
      label = "bifold";
      break;
    case "Fixed Outswing":
      label = "outswing";
      break;
    case "Fixed Inswing":
      label = "inswing";
      break;
    case "Fixed Slider":
      label = "slider";
      break;
    default:
      label = "fullbound";
  }

  return `${path}/${materialLbl}${label}`;
}

function getPivotKerfImage(material: string) {
  if (material === "All Wood") return "kerf/pivot/wood";

  return "kerf/pivot/clad";
}

export {
  getBifoldKerfImage,
  getFPKerfImage,
  getSliderKerfImage,
  getSwingKerfImage,
  getPivotKerfImage,
};
