const glass = [
  {
    id: 1,
    type: "glassType",
    label: "LoE 366",
  },
  {
    id: 2,
    type: "glassType",
    label: "LoE 272",
  },
];

export { glass };
