const fin = {
  id: 1,
  type: "flashingFin",
  label: 'Add Flashing Fin 1 1/2" (A)',
};

const options = [
  {
    id: 1,
    type: "flashingFinConfig",
    label: 'Standard 1" Setback',
  },
  {
    id: 2,
    type: "flashingFinConfig",
    label: "Custom Setback",
  },
];

export { fin, options };
