import React, { memo, FC } from "react";
import {
  Tooltip,
  Button,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { styled } from "@mui/material/styles";

// const HtmlTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .MuiTooltip-tooltip`]: {
//     backgroundColor: "#4b4b4b",
//     color: "white",
//     fontSize: theme.typography.pxToRem(12),
//     border: "1px solid #dadde9",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     padding: 10,
//   }
// }));

const HtmlTooltip = styled(Tooltip)(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#4b4b4b",
    color: "white",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
}));

const StyledListItem = styled(ListItem)({
  borderTop: "1px solid grey",
  backgroundColor: "#57d5f2",
  height: "100%",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
});

const StyledListItemText = styled(ListItemText)({
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledTypography = styled(Typography)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledErrIcon = styled(ErrorRoundedIcon)({
  fontSize: 20,
  color: "tomato",
  backgroundColor: "white",
  borderRadius: 25,
  marginLeft: 5,
});

const StyledButton = styled(Button)({
  color: "black",
  border: "1px solid #4b4b4b",
});

interface QuoteSummaryBtnProps {
  grandTotal: string;
  disableQSum: boolean;
  editBtn: boolean;
  routeToOrderSummary: (route: boolean, checked?: boolean) => void;
}

const QuoteSummaryBtn: FC<QuoteSummaryBtnProps> = ({
  grandTotal,
  disableQSum,
  editBtn,
  routeToOrderSummary,
}) => {
  const validGrandTotal =
    grandTotal &&
    grandTotal !== "0" &&
    grandTotal !== "500" &&
    grandTotal !== "600";

  return (
    <StyledListItem>
      <StyledListItemText
        primary={
          <>
            {validGrandTotal && !disableQSum && !editBtn ? (
              <Button
                onClick={() => routeToOrderSummary(false)}
                color="customGrey"
                style={{
                  color: "white",
                }}
                size="large"
                variant="contained"
              >
                Quote Summary
              </Button>
            ) : !validGrandTotal ? (
              <HtmlTooltip
                title="Add Units to Quote to View Quote Summary"
                enterTouchDelay={0}
              >
                <StyledButton color="cyan" size="large" variant="outlined">
                  Quote Summary
                </StyledButton>
              </HtmlTooltip>
            ) : validGrandTotal && disableQSum ? (
              <HtmlTooltip
                enterTouchDelay={0}
                title={
                  <>
                    <Typography>Missing Required Information</Typography>
                    <StyledTypography>
                      (Edit Units with <StyledErrIcon />)
                    </StyledTypography>
                  </>
                }
              >
                <StyledButton color="cyan" size="large" variant="outlined">
                  Quote Summary
                </StyledButton>
              </HtmlTooltip>
            ) : editBtn ? (
              <HtmlTooltip title="Update Unit to Continue" enterTouchDelay={0}>
                <StyledButton color="cyan" size="large" variant="outlined">
                  Quote Summary
                </StyledButton>
              </HtmlTooltip>
            ) : null}
          </>
        }
      />
    </StyledListItem>
  );
};

export default memo(QuoteSummaryBtn);
