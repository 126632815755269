import React, { FC } from "react";
import { hardware, actionDorma, actionFrits } from "./defaults";
import Choices from "../Choices";
import { styled } from "@mui/material/styles";

const StyledRowDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

function showOptions(
  type: string,
  handleClick: (lbl: string) => void,
  value: string,
  pivotHardware: string,
  hideDorma?: boolean,
) {
  if (type === "hardware") {
    if (hideDorma) {
      return (
        <ConfigChoices
          options={hardware.filter((hw) => hw.label !== "Dorma")}
          value={value}
          handleClick={handleClick}
        />
      );
    }
    return (
      <ConfigChoices
        options={hardware}
        value={value}
        handleClick={handleClick}
      />
    );
  }

  if (pivotHardware === "Dorma") {
    return (
      <ConfigChoices
        options={actionDorma}
        value={value}
        handleClick={handleClick}
      />
    );
  }

  return (
    <ConfigChoices
      options={actionFrits}
      value={value}
      handleClick={handleClick}
    />
  );
}

interface OptionsObj {
  id: number;
  type: string;
  label: string;
}

interface ConfigChoicesProps {
  options: OptionsObj[];
  value: string;
  handleClick: (lbl: string) => void;
}

const ConfigChoices: FC<ConfigChoicesProps> = ({
  options,
  value,
  handleClick,
}) => {
  return (
    <StyledRowDiv>
      {options.map((state) => (
        <div key={state.id} onClick={() => handleClick(state.label)}>
          <Choices
            label={state.label}
            variant={state.label === value ? "filled" : "outlined"}
            checkMark={state.label === value}
          />
        </div>
      ))}
    </StyledRowDiv>
  );
};

export default showOptions;
