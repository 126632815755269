import React, {
  useState,
  useEffect,
  FC,
  Dispatch,
  SetStateAction,
  SyntheticEvent,
} from "react";
import { useTheme } from "@mui/material/styles";
import {
  Tabs,
  Tab,
  CircularProgress,
  Button,
  Typography,
  TextareaAutosize,
} from "@mui/material";

import DealerInfo from "./DealerInfo";
import CustomerInfo from "./CustomerInfo";
import { SuccessModal, UpdateWarningModal } from "./Modals/SuccessModal";
import ServiceTeamsBtns from "./ServiceTeamsBtns";
import Uploads from "./Uploads";
import ErrModal from "./Modals/ErrModal";
import IncludeIssue from "./IncludeIssue";

import ServiceTable from "./ServiceTable";
import ServiceImages from "./ServiceImages";
import FormNotes from "../../FormNotes";
import { useQuery } from "react-query";
import { ServiceRepairObj } from "../../../GlobalTypes";
import {
  editService,
  submitService,
  getUser,
  uploadServiceImage,
} from "../../../api/ServiceApi";
import TabPanel from "../../TabPanel";
import { RepairState } from "../../../pages/DealerZone";
import { styled } from "@mui/material/styles";

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const StyledDivRoot = styled("div")({
  backgroundColor: "#fafafa",
  width: "100%",
  marginTop: -40,
});

const StyledDivInfo = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  flexWrap: "wrap",
});

const StyledDivServiceTitle = styled("div")({
  padding: 10,
  backgroundColor: "#57d5f2",
  width: "100%",
  paddingLeft: 20,
  border: "1px solid lightgrey",
  marginTop: 20,
  boxShadow: "4px 4px 8px #888888",
  zIndex: 10,
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const StyledTypographyTitle = styled(Typography)({
  fontWeight: "bold",
  textDecoration: "underline",
  marginLeft: 100,
});

const StyledDivScroll = styled("div")({
  overflow: "auto",
  height: "70vh",
});

const StyledTab = styled(Tab)({
  textTransform: "none",
  fontWeight: "bold",
  fontSize: 16,
  border: "1px solid lightgrey",
});

const StyledDivRowSpace = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  padding: 10,
});

const StyledDivRowCenter = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledDivRowCenterWide = styled("div")({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  alignItems: "center",
  padding: 15,
});

const StyledDivCol = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const StyledTextArea = styled(TextareaAutosize)({
  marginLeft: 100,
  marginTop: 20,
  width: "85%",
  fontWeight: "bold",
  backgroundColor: "#fafafa",
});

const StyledErr = styled(Typography)({
  color: "red",
  display: "flex",
  justifyContent: "center",
  fontWeight: "bold",
  fontSize: 20,
});

const StyledButtonGroup = styled("div")({
  padding: 20,
  display: "flex",
  justifyContent: "center",
});

const StyledButtonCancel = styled(Button)({
  color: "white",
  marginRight: 10,
});

const StyledDivLoadWrap = styled("div")({
  backgroundColor: "lightgrey",
  height: "75vh",
  position: "relative",
});

const StyledCircularProgress = styled(CircularProgress)({
  position: "absolute",
  left: "45%",
  top: "50%",
});

const StyledDivCreateServ = styled("div")({
  backgroundColor: "lightgrey",
  height: "75vh",
  position: "relative",
});

const StyledButton = styled(Button)({
  position: "absolute",
  left: "45%",
  top: "50%",
});

const StyledTypoService = styled(Typography)({
  fontWeight: "bold",
  marginLeft: 20,
  paddingTop: 20,
});

export interface CustomerInfoState {
  customerName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  email: string;
  invoiceNumber: string;
  invoiceDate: string;
  issue: string;
  poNumber: string;
  unitLineNumber: string;
  soNumber: string;
  includeIssueInEmail?: string;
  createdBy?: string;
}

export interface AdminDealer {
  dealer: string;
  dealerRep: string;
  email: string;
  phone: string;
}

interface ServiceRepairProps {
  id: string;
  snNumber: number;
  setSnNumber: Dispatch<SetStateAction<number>>;
  getSN: () => void;
  repair: RepairState;
  setRepair: Dispatch<SetStateAction<RepairState>>;
}

const ServiceRepair: FC<ServiceRepairProps> = ({
  id,
  snNumber,
  setSnNumber,
  getSN,
  repair,
  setRepair,
}) => {
  const theme = useTheme();

  const { isFetching, data } = useQuery(
    "DelerInfo",
    async () => {
      const response = await getUser(id);
      return response.data;
    },
    { refetchOnWindowFocus: false },
  );

  const viewSR = (repair: ServiceRepairObj) => {
    setValue(0);
    setServiceTeam(repair.team || 0);
    setLoadUpload(0);
    setFormNotes(repair.privateNotes || "");
    setSnNumber(repair.snNumber);
    setCustomerInfo({
      customerName: repair.customerName,
      address: repair.address,
      city: repair.city,
      state: repair.state,
      zip: repair.zip,
      phone: repair.phone,
      email: repair.email || "",
      invoiceNumber: repair.invoiceNumber || "",
      invoiceDate: repair.invoiceDate || "",
      issue: repair.issue,
      poNumber: repair.poNumber || "",
      unitLineNumber: repair.unitLineNumber,
      createdBy: repair.createdBy || "",
      soNumber: repair.soNumber || "",
      includeIssueInEmail: repair.includeIssueInEmail || "",
    });
    (repair.uploadedImg1 || repair.uploadedImg2 || repair.uploadedImg3) &&
      setUploadURLs({
        u1: repair.uploadedImg1 || "",
        u2: repair.uploadedImg2 || "",
        u3: repair.uploadedImg3 || "",
      });
    setRepair({ status: repair.status, id: repair._id });
    data.role !== "Dealer" &&
      data.role !== "Manager" &&
      data.role !== "Company Manager" &&
      setAdminDealer({
        dealer: repair.dealer,
        dealerRep: repair.dealerRep,
        email: repair.dealerEmail || "",
        phone: repair.dealerPhone || "",
      });
  };

  const [adminDealer, setAdminDealer] = useState<AdminDealer>({
    dealer: "",
    dealerRep: "",
    email: "",
    phone: "",
  });

  const [error, setError] = useState(false);

  const [openErrModal, setOpenErrModal] = useState(false);

  const [submissionErr, setSE] = useState("");

  const [value, setValue] = useState(0);

  const [uploadURLs, setUploadURLs] = useState({
    u1: "",
    u2: "",
    u3: "",
  });

  const [customerInfo, setCustomerInfo] = useState<CustomerInfoState>({
    customerName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    invoiceNumber: "",
    invoiceDate: "",
    issue: "",
    poNumber: "",
    unitLineNumber: "",
    soNumber: "",
    includeIssueInEmail: "",
  });

  const [loading, setLoading] = useState(false);

  const [loadUpload, setLoadUpload] = useState(0);

  const [toggleEmail, setToggle] = useState(false);

  const [serviceTeam, setServiceTeam] = useState(0);

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) =>
    setToggle(event.target.checked);

  const resetServiceRepair = () => {
    setSnNumber(0);
    setServiceTeam(0);
    setCustomerInfo({
      customerName: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      email: "",
      invoiceNumber: "",
      invoiceDate: "",
      issue: "",
      poNumber: "",
      unitLineNumber: "",
      soNumber: "",
    });
    setUploadURLs({
      u1: "",
      u2: "",
      u3: "",
    });
    setFormNotes("");
    repair.status && setRepair({ status: "", id: "" });
    data.role !== "Dealer" &&
      data.role !== "Manager" &&
      data.role !== "Company Manager" &&
      setAdminDealer({
        dealer: "",
        dealerRep: "",
        email: "",
        phone: "",
      });
  };

  useEffect(() => {
    if (!snNumber) return;
    handleMissingFields(customerInfo);
  }, [customerInfo, snNumber]);

  const handleMissingFields = (state: CustomerInfoState) => {
    const requiredFields = [
      state.customerName,
      state.address,
      state.city,
      state.state,
      state.zip,
      state.phone,
      state.issue,
      state.unitLineNumber,
      state.includeIssueInEmail,
      state.email,
    ];
    let err = false;

    if (data.role === "Admin" || data.role === "Inside Sales") {
      requiredFields.pop();
    }

    if (requiredFields.some((field) => !field)) err = true;

    if (!state.poNumber && (!state.invoiceNumber || !state.invoiceDate))
      err = true;

    setError(err);
  };

  const handleChangeAdmin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    setAdminDealer({ ...adminDealer, [name]: e.target.value });
  };

  const handleInfoChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const name = e.target.name;
    setCustomerInfo({ ...customerInfo, [name]: e.target.value });
  };

  const handleIncludeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;
    if (!checked) return;

    setCustomerInfo({
      ...customerInfo,
      includeIssueInEmail: value,
    });
  };

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    repair.status && resetServiceRepair();
  };

  const handleUploadSubmit = async (
    event: React.ChangeEvent<HTMLInputElement>,
    imgNumber: number,
  ) => {
    setLoadUpload(imgNumber);
    const file = event.target?.files ? event.target.files[0] : null;

    if (!file) return;

    const formData = new FormData();

    formData.append("file", file);
    formData.append("public_id", `ServiceRepair/SN_${snNumber}/${imgNumber}`);

    uploadServiceImage(formData)
      .then((data) => {
        imgNumber === 1
          ? setUploadURLs({ ...uploadURLs, u1: data.data.secure_url })
          : imgNumber === 2
            ? setUploadURLs({ ...uploadURLs, u2: data.data.secure_url })
            : setUploadURLs({ ...uploadURLs, u3: data.data.secure_url });
      })
      .catch((err) => console.log(err))
      .then((upl) => setLoadUpload(0));
  };

  const createSR = async () => {
    setLoading(true);

    function handleError() {
      setLoading(false);
      setOpenErrModal(true);
      handleCloseWarnModal();
      setSE(
        "Problem Occured When Submitting Service Repair. Please Try Again.",
      );
    }

    if (
      data.role === "Dealer" ||
      data.role === "Manager" ||
      data.role === "Company Manager"
    ) {
      try {
        const serviceSubmitStatus = await submitService(
          snNumber,
          uploadURLs,
          customerInfo,
          data,
          serviceTeam,
          toggleEmail,
        );

        if (
          serviceSubmitStatus.data ===
          "A problem occured when trying to send email."
        ) {
          handleError();
          return;
        }

        setLoading(false);
        handleOpenModal();
        handleCloseWarnModal();
      } catch (err) {
        handleError();
      }
    } else {
      try {
        const serviceSubmitStatus = await submitService(
          snNumber,
          uploadURLs,
          customerInfo,
          adminDealer,
          serviceTeam,
          toggleEmail,
        );

        if (
          serviceSubmitStatus.data ===
          "A problem occured when trying to send email."
        ) {
          handleError();
          return;
        }

        setLoading(false);
        handleOpenModal();
        handleCloseWarnModal();
      } catch (err) {
        handleError();
      }
    }
  };

  const editSR = async () => {
    handleCloseWarnModal();
    setLoading(true);

    let serviceRepair;

    if (
      data.role !== "Dealer" &&
      data.role !== "Manager" &&
      data.role !== "Company Manager"
    ) {
      serviceRepair = await editService(
        serviceTeam,
        toggleEmail,
        uploadURLs,
        customerInfo,
        repair.id,
        adminDealer,
      );
    } else {
      serviceRepair = await editService(
        serviceTeam,
        toggleEmail,
        uploadURLs,
        customerInfo,
        repair.id,
      );
    }

    if (
      "error" in serviceRepair &&
      serviceRepair.error === "Service has Completed status"
    ) {
      setLoading(false);
      setMEE("Can not Update a Service with a Completed Status.");
      handleOpenModal();
    }

    if (serviceRepair.data === "Service Repair Updated") {
      setLoading(false);
      handleOpenModal();
    }

    if (serviceRepair.data === "A problem occured when trying to send email.") {
      setLoading(false);
      setMEE("A Problem Occured When Trying to Send Email.");
      handleOpenModal();
    }
  };

  const [openModal, setOpenModal] = useState(false);

  const [openModalWarn, setOpenModalWarn] = useState(false);

  const handleOpenWarnModal = () => {
    setOpenModalWarn(true);
    setToggle(false);
  };
  const handleCloseWarnModal = () => {
    setOpenModalWarn(false);
    setMEE("");
  };

  const [modalEmailErr, setMEE] = useState("");

  const handleCloseModal = () => {
    resetServiceRepair();
    setMEE("");
    setOpenModal(false);
  };

  const handleOpenModal = () => setOpenModal(true);

  const [formNotes, setFormNotes] = useState("");

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormNotes(e.target.value);
  };

  const disabled = !repair
    ? false
    : !repair.status
      ? false
      : repair.status === "Completed"
        ? true
        : data.signInRole === "Admin" || data.signInRole === "Inside Sales"
          ? false
          : true;

  return (
    <>
      <SuccessModal
        modalEmailErr={modalEmailErr}
        open={openModal}
        handleClose={handleCloseModal}
        isUpdated={repair.status === "Submitted"}
        toggleEmail={toggleEmail}
      />

      <UpdateWarningModal
        open={openModalWarn}
        handleClose={handleCloseWarnModal}
        srFunction={editSR}
        toggle={toggleEmail}
        handleToggle={handleToggle}
        loading={loading}
      />

      <ErrModal
        modalText={submissionErr}
        setOpen={setOpenErrModal}
        open={openErrModal}
      />

      <StyledDivRoot>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          style={{ backgroundColor: "#fafafa", paddingLeft: 40 }}
          aria-label="full width tabs example"
        >
          <StyledTab
            style={{ backgroundColor: value === 0 ? "white" : "#e1dfe0" }}
            label={repair.status ? "View Service Repair" : "New Request"}
            {...a11yProps(0)}
          />
          <StyledTab
            style={{ backgroundColor: value === 1 ? "white" : "#e1dfe0" }}
            label="Previous Service"
            {...a11yProps(1)}
          />
        </Tabs>

        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          style={{ border: "2px solid lightgrey" }}
        >
          {snNumber && !loading ? (
            <>
              <StyledDivServiceTitle>
                <StyledTypographyTitle variant="h6">
                  Service Repair Form
                </StyledTypographyTitle>
                <Typography>Service Number: {snNumber}</Typography>
              </StyledDivServiceTitle>
              <StyledDivScroll>
                <StyledDivRowSpace>
                  {customerInfo.createdBy && <div />}

                  <Typography>
                    <span style={{ fontWeight: "bold" }}>
                      For replacement parts please email us at:
                    </span>{" "}
                    customerservice@agmillworks.com
                  </Typography>

                  {customerInfo.createdBy && (
                    <StyledDivRowCenter>
                      <Typography style={{ fontSize: 12 }}>
                        <span style={{ fontWeight: "bold", fontSize: 12 }}>
                          Created By:
                        </span>{" "}
                        {customerInfo.createdBy}
                      </Typography>
                    </StyledDivRowCenter>
                  )}
                </StyledDivRowSpace>

                <StyledDivInfo>
                  <CustomerInfo
                    info={customerInfo}
                    handleChange={handleInfoChange}
                    disabled={disabled}
                  />
                  <DealerInfo
                    handleChangeAdmin={handleChangeAdmin}
                    adminDealer={adminDealer}
                    repairStatus={repair.status}
                    info={customerInfo}
                    handleChange={handleInfoChange}
                    data={data}
                    isFetching={isFetching}
                    disabled={disabled}
                    setAdminDealer={setAdminDealer}
                  />
                </StyledDivInfo>

                {(data.signInRole === "Admin" ||
                  data.signInRole === "Inside Sales") && (
                  <ServiceTeamsBtns
                    team={serviceTeam}
                    setTeam={setServiceTeam}
                    isEdit={repair.status ? true : false}
                    snNumber={snNumber}
                  />
                )}

                <StyledDivCol
                  style={{
                    position: "relative",
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  <Typography style={{ fontWeight: "bold" }}>
                    Service Issue
                  </Typography>
                  <StyledTextArea
                    disabled={disabled}
                    placeholder="Describe Issue..."
                    name="issue"
                    value={customerInfo.issue}
                    onChange={handleInfoChange}
                    minRows={10}
                  />
                </StyledDivCol>

                {(data.signInRole === "Admin" ||
                  data.signInRole === "Inside Sales" ||
                  data.signInRole === "Outside Sales") && (
                  <FormNotes
                    formNotes={formNotes}
                    handleChange={handleFormChange}
                    isService={true}
                    serviceId={repair.id}
                    role={data.signInRole}
                  />
                )}

                {repair.status !== "Scheduled" ? (
                  <>
                    <Uploads
                      loading={loadUpload}
                      handleSubmit={handleUploadSubmit}
                      uploadURLs={uploadURLs}
                    />

                    {repair.status &&
                      repair.id &&
                      (uploadURLs.u1 || uploadURLs.u2 || uploadURLs.u3) && (
                        <ServiceImages urls={uploadURLs} />
                      )}

                    {disabled ? null : (
                      <>
                        <StyledDivRowCenterWide>
                          <IncludeIssue
                            include={customerInfo.includeIssueInEmail || ""}
                            handleChange={handleIncludeChange}
                          />
                        </StyledDivRowCenterWide>

                        {error && snNumber && (
                          <StyledErr>All Fields Required</StyledErr>
                        )}

                        <StyledButtonGroup>
                          {repair.status && (
                            <StyledButtonCancel
                              onClick={resetServiceRepair}
                              variant="contained"
                              color="customGrey"
                            >
                              Cancel Update
                            </StyledButtonCancel>
                          )}

                          <Button
                            onClick={
                              repair.status ? handleOpenWarnModal : createSR
                            }
                            variant="contained"
                            color="primary"
                            disabled={error || repair.status === "Completed"}
                          >
                            {repair.status ? "Update" : "Submit"} Service Repair
                          </Button>
                        </StyledButtonGroup>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {(uploadURLs.u1 || uploadURLs.u2 || uploadURLs.u3) && (
                      <ServiceImages urls={uploadURLs} />
                    )}

                    {(data.signInRole === "Admin" ||
                      data.signInRole === "Inside Sales") && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            alignItems: "center",
                            padding: 15,
                          }}
                        >
                          <IncludeIssue
                            include={customerInfo.includeIssueInEmail || ""}
                            handleChange={handleIncludeChange}
                          />
                        </div>

                        {error && snNumber && (
                          <StyledErr>All Fields Required</StyledErr>
                        )}

                        <StyledButtonGroup>
                          {repair.status && (
                            <StyledButtonCancel
                              onClick={resetServiceRepair}
                              variant="contained"
                              color="customGrey"
                            >
                              Cancel Update
                            </StyledButtonCancel>
                          )}

                          <Button
                            onClick={
                              repair.status ? handleOpenWarnModal : createSR
                            }
                            variant="contained"
                            color="primary"
                            disabled={error}
                          >
                            {repair.status ? "Update" : "Submit"} Service Repair
                          </Button>
                        </StyledButtonGroup>
                      </>
                    )}
                  </>
                )}
              </StyledDivScroll>
            </>
          ) : snNumber && loading ? (
            <StyledDivLoadWrap>
              <StyledCircularProgress />
            </StyledDivLoadWrap>
          ) : (
            <StyledDivCreateServ>
              <StyledButton
                onClick={getSN}
                variant="contained"
                size="large"
                color="primary"
              >
                Create Service Repair
              </StyledButton>
            </StyledDivCreateServ>
          )}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <StyledTypoService variant="h4">Service</StyledTypoService>

          <ServiceTable viewSR={viewSR} />
        </TabPanel>
      </StyledDivRoot>
    </>
  );
};

export default ServiceRepair;
