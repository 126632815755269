import React, { useState, FC } from "react";
import GetAppIcon from "@mui/icons-material/GetApp";
import { Button } from "@mui/material";

import ExportFilterModal from "./ExportFilterModal";

interface ExportBtnProps {
  showTab: string;
  searchTerm: string;
  searchTermFilter: string;
  isSearch: boolean;
  showArchive?: string;
  showActive?: string;
}

const ExportBtn: FC<ExportBtnProps> = ({
  showTab,
  searchTerm,
  searchTermFilter,
  isSearch,
  showArchive,
  showActive,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <ExportFilterModal
        open={open}
        handleClose={handleClose}
        showTab={showTab}
        searchTerm={searchTerm}
        searchTermFilter={searchTermFilter}
        isSearch={isSearch}
        showArchive={showArchive}
        showActive={showActive}
      />
      <Button
        size="small"
        style={{
          backgroundColor: "rgb(218,218,218)",
          color: "black",
          fontSize: 11,
          width: 120,
        }}
        onClick={handleOpen}
        variant="contained"
      >
        <GetAppIcon style={{ color: "black", fontSize: 18 }} />
        Export Table
      </Button>
    </>
  );
};

export default ExportBtn;
