import React, { FC } from "react";
import { MenuItem } from "@mui/material";

interface KerfCustomOptionsProps {
  door: string;
  material?: string | null;
}

const KerfCustomOptions: FC<KerfCustomOptionsProps> = ({
  door,
  material,
}): any => {
  const bifoldOutswing: string[] = [
    "7/16",
    "1/2",
    "9/16",
    "5/8",
    "11/16",
    "3/4",
    "13/16",
    "7/8",
    "15/16",
    "1",
    "1 1/16",
    "1 1/8",
  ];
  const slider: string[] = [
    "7/16",
    "1/2",
    "9/16",
    "5/8",
    "11/16",
    "3/4",
    "13/16",
    "7/8",
    "15/16",
    "1",
    "1 1/16",
    "1 1/8",
    "1 3/16",
    "1 1/4",
  ];
  const inswingClad: string[] = ["7/16", "1/2", "9/16", "5/8", "11/16"];
  const inswingWood: string[] = ["7/16", "1/2", "9/16", "5/8"];

  if (
    door?.includes("Bi-fold") ||
    door?.includes("Outswing") ||
    door === "Fixed Full Bound"
  ) {
    return bifoldOutswing.map((item) => (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    ));
  }

  if (door?.includes("Inswing") || door === "Pivot") {
    if (material === "All Wood") {
      return inswingWood.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ));
    } else {
      return inswingClad.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ));
    }
  }

  if (door?.includes("Slider")) {
    return slider.map((item) => (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    ));
  }

  return null;
};

export default KerfCustomOptions;
