import React, { useEffect, memo, FC } from "react";
import Typography from "@mui/material/Typography";
import Choices from "../Choices";
import ReusableAccordian from "../ReusableAccordion";
import { useStore } from "../../store/store";
import { useAccordion } from "../../store/accordion";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import { Image } from "cloudinary-react";
import SillFinish from "../SillFinish";
import {
  sillColor,
  sillImgs,
  raisedSills,
  sills,
  outswingSills,
  inswingSills,
} from "./defaults";
import { RowLayout, ColLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const ColLayoutSpacing = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1, 2),
}));

const ColLayoutLeftDivider = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1, 2),
  borderLeft: `2px solid ${theme.palette.divider}`,
}));

const RowLayoutSpacing = styled("div")(({ theme }) => ({
  marginBottom: 10,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  padding: theme.spacing(1, 2),
  alignSelf: "flex-end",
  marginTop: 20,
}));

interface SillProps {
  doorType: string;
}

interface UpdateObj {
  sillFinish?: string;
  sillStyle?: string;
}

const SillAccordian: FC<SillProps> = ({ doorType }) => {
  const addToQuote = useStore((state) => state.addToQuote);

  const sillColorChoice = useStore((state) => state.quote.sillColor);
  const sillStyle = useStore((state) => state.quote.sillStyle);

  const direction = useStore((state) => state.quote.swingDirection);
  const screenType = useStore((state) => state.quote.screenType);
  const screenVal = useStore((state) => state.quote.addScreen);

  const paintPrimeChoice = useStore((state) => state.quote.paintAndPrime);
  const primeChoice = useStore((state) => state.quote.prime);
  const stainChoice = useStore((state) => state.quote.stain);
  const customPaintChoice = useStore((state) => state.quote.customPaintOrStain);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[13].expanded);

  const showRaised =
    (sillStyle === "Raised Sill" ||
      sillStyle === "Squared Dam" ||
      sillStyle === "Rounded Dam") &&
    doorType !== "Swing";

  const showSillFinish =
    !sillStyle?.includes("Ultra") &&
    (paintPrimeChoice || primeChoice || stainChoice || customPaintChoice)
      ? true
      : false;

  const isCentor = screenType?.includes("Centor");

  useEffect(() => {
    if (!screenVal || doorType === "Swing") return;
    screenVal &&
      !isCentor &&
      (sillStyle === "Raised Sill" || sillStyle === "Rounded Dam") &&
      addToQuote({ sillStyle: "Squared Dam" });
  }, [screenVal, sillStyle]);

  const removeSillRequireds = (type: string, label: string) => {
    switch (type) {
      case "sillStyle":
        label !== "Raised Sill" &&
          removeRequired("Sill Type", "select either Rounded or Squared Sill");
        break;
      case "sillColor":
        removeRequired("Sill Finish");
        break;
      default:
        return;
    }
  };

  const handleClick = (label: string, type: string) => {
    if (!type) return;

    const checked =
      type === "sillColor"
        ? label === sillColorChoice
        : type === "sillStyle"
          ? label === sillStyle
          : "";

    if (checked) {
      addToQuote({ [type]: "" }, `${type}`);
    } else {
      if (type === "sillStyle") {
        let updateObj: UpdateObj = {};

        if (label.includes("Ultra")) updateObj["sillFinish"] = "";

        if (screenVal && !isCentor && label === "Raised Sill") {
          updateObj["sillStyle"] = "Squared Dam";
          if (findRequired("Sill Type"))
            removeRequired(
              "Sill Type",
              "select either Rounded or Squared Sill",
            );
        } else {
          updateObj["sillStyle"] = label;
        }

        addToQuote(updateObj);
      } else {
        addToQuote({ sillColor: label });
      }

      if (
        findRequired("Sill Type") ||
        findRequired("Sill Finish") ||
        findRequired("select either Rounded or Squared Sill")
      ) {
        removeSillRequireds(type, label);
      }
    }
  };

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="sill"
      heading="Sill Choice"
      secondaryHeading="Select sill choice"
      colOrRow="col"
    >
      {doorType === "Swing" && (
        <>
          <Typography
            style={{ textAlign: "center", width: "70%" }}
            variant="subtitle1"
          >
            Note: For ADA Sill Please Contact Office
          </Typography>
          <RowLayout>
            <RowLayoutSpacing>
              {direction === "Inswing" && (
                <Image
                  cloudName="ag-millworks"
                  secure="true"
                  publicId="Sills/swings/inswingSill"
                />
              )}

              {direction === "Outswing" && screenVal && (
                <Image
                  cloudName="ag-millworks"
                  secure="true"
                  publicId={`Sills/swings/outswing${
                    isCentor ? "Centor" : "Screen"
                  }`}
                />
              )}

              {direction === "Outswing" && !screenVal && (
                <Image
                  cloudName="ag-millworks"
                  secure="true"
                  publicId="Sills/swings/outswingSill"
                />
              )}

              {direction === "Inswing" && (
                <Choices
                  wide={true}
                  label={inswingSills.label}
                  variant="filled"
                  checkMark={true}
                  disabled={inswingSills.disabled || false}
                />
              )}

              {direction === "Outswing" && (
                <Choices
                  label={outswingSills.label}
                  variant="filled"
                  checkMark={true}
                  disabled={outswingSills.disabled || false}
                />
              )}

              {!direction && (
                <Typography variant="body1">
                  Note: Select Swing Direction
                </Typography>
              )}
            </RowLayoutSpacing>

            <SillColor
              handleClick={handleClick}
              sillColorChoice={sillColorChoice || ""}
            />

            {showSillFinish && <SillFinish />}
          </RowLayout>
        </>
      )}

      {doorType.includes("Bi-fold") && (
        <>
          <Typography
            style={{ textAlign: "center", width: "70%" }}
            variant="subtitle1"
          >
            PLEASE NOTE: *Ultra Guide and ADA Ultra Guide are appropriate for
            extremely low-exposure environments only; no warranty for water
            penetration, wind Penetration, or airborne particles with this
            option
          </Typography>

          <ColLayout>
            <RowLayout>
              <ColLayoutSpacing>
                <Typography style={{ margin: "auto" }} variant="subtitle1">
                  Sill Types
                  <span style={{ color: "red", fontSize: 20 }}>*</span>
                </Typography>
                <RowLayout>
                  {sills.map((state) => (
                    <div
                      key={state.id}
                      onClick={() => handleClick(state.label, state.type)}
                    >
                      <Choices
                        label={state.label}
                        variant={
                          state.label === sillStyle ||
                          (state.label === "Raised Sill" &&
                            sillStyle?.includes("Dam"))
                            ? "filled"
                            : "outlined"
                        }
                        checkMark={
                          state.label === sillStyle ||
                          (state.label === "Raised Sill" &&
                            sillStyle?.includes("Dam"))
                            ? true
                            : false
                        }
                        disabled={state.disabled || false}
                      />
                    </div>
                  ))}
                </RowLayout>

                {showRaised && (
                  <RowLayout>
                    {screenVal && !isCentor ? (
                      <Choices
                        label={raisedSills[1].label}
                        variant={"filled"}
                        checkMark={true}
                        disabled={true}
                      />
                    ) : (
                      raisedSills.map((state) => (
                        <div
                          key={state.id}
                          onClick={() => handleClick(state.label, state.type)}
                        >
                          <Choices
                            label={state.label}
                            variant={
                              state.label === sillStyle ? "filled" : "outlined"
                            }
                            checkMark={state.label === sillStyle}
                            disabled={!screenVal ? false : state.disabled}
                          />
                        </div>
                      ))
                    )}
                  </RowLayout>
                )}
              </ColLayoutSpacing>

              <SillColor
                handleClick={handleClick}
                sillColorChoice={sillColorChoice || ""}
              />

              {showSillFinish && <SillFinish />}
            </RowLayout>

            {sillStyle &&
              sillImgs
                .filter((image) => image.label === sillStyle)
                .map((imageShown) => (
                  <RowLayout key={imageShown.label}>
                    <Image
                      cloudName="ag-millworks"
                      secure="true"
                      publicId={
                        screenVal && !isCentor
                          ? imageShown.imgLabelScreen
                          : screenVal && isCentor
                            ? imageShown.imgLabelCentor
                            : imageShown.imgLabel
                      }
                    />

                    {doorType.includes("Bi-fold") &&
                      sillStyle?.includes("Ultra") && (
                        <div style={{ marginLeft: 20 }}>
                          <Image
                            cloudName="ag-millworks"
                            secure="true"
                            publicId="Sills/bifolds/ultra-guide-weep"
                          />
                        </div>
                      )}
                  </RowLayout>
                ))}
          </ColLayout>
        </>
      )}
    </ReusableAccordian>
  );
};

interface SillColorProps {
  sillColorChoice: string;
  handleClick: (lbl: string, type: string) => void;
}

export const SillColor: FC<SillColorProps> = ({
  sillColorChoice,
  handleClick,
}) => {
  return (
    <ColLayoutLeftDivider>
      <Typography style={{ margin: "auto" }} variant="subtitle1">
        Sill Colors
        <span style={{ color: "red", fontSize: 20 }}>*</span>
      </Typography>
      <RowLayout>
        {sillColor.map((state) => (
          <div
            key={state.id}
            onClick={() => handleClick(state.label, state.type)}
          >
            <Choices
              label={state.label}
              variant={state.label === sillColorChoice ? "filled" : "outlined"}
              checkMark={state.label === sillColorChoice}
              disabled={state.disabled || false}
            />
          </div>
        ))}
      </RowLayout>
    </ColLayoutLeftDivider>
  );
};

export default memo(SillAccordian);
