import React, { FC, Dispatch, SetStateAction } from "react";
import { Typography, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledMarginTypo = styled(Typography)({
  position: "absolute",
  top: 21,
  right: 18,
});

const StyledMarkupTypo = styled(Typography)({
  position: "absolute",
  top: 20,
  left: 18,
});

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 79,
  height: 40,
  padding: 0,
  margin: theme.spacing(1),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .MuiSwitch-switchBase": {
    padding: 1,
    "&.Mui-checked": {
      transform: "translateX(55px)",
      color: theme.palette.common.white,
      "& + .MuiSwitch-track": {
        backgroundColor: "#57d5f2",
        opacity: 1,
        border: "none",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 22,
    height: 22,
    backgroundColor: "#7f7f7f",
  },
  "& .MuiSwitch-track": {
    borderRadius: 15,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[200],
    height: 15,
    opacity: 1,
    marginTop: 4,
    transition: theme.transitions.create(["background-color", "border"]),
  },
}));

type SetStringState = Dispatch<SetStateAction<string>>;

type SetBoolState = Dispatch<SetStateAction<boolean>>;

interface MarginMarkupProps {
  setMarginInput: SetStringState;
  setViewPdf: SetBoolState;
  withMarkup: boolean;
  setWithMarkup: SetBoolState;
  fixedMargin: string | null;
  fixedMarkup: string | null;
  quoteStatus: string;
}

const MarginMarkupToggle: FC<MarginMarkupProps> = ({
  setViewPdf,
  withMarkup,
  setWithMarkup,
  fixedMargin,
  fixedMarkup,
  setMarginInput,
  quoteStatus,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setWithMarkup(checked);
    setViewPdf(false);

    if (checked && fixedMarkup) {
      setMarginInput(fixedMarkup);
    } else if (!checked && fixedMargin) {
      setMarginInput(fixedMargin);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <StyledSwitch
        color="primary"
        style={{ marginTop: 10 }}
        checked={withMarkup}
        onChange={handleChange}
        inputProps={{ "aria-label": "my toggle" }}
        disabled={quoteStatus !== "Quote"}
      />
      {!withMarkup && (
        <StyledMarginTypo variant="caption">Margin</StyledMarginTypo>
      )}
      {withMarkup && (
        <StyledMarkupTypo variant="caption">Markup</StyledMarkupTypo>
      )}
    </div>
  );
};

export default MarginMarkupToggle;
