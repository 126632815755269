import { roundTo4 } from "../../quotePDF/CutSheets/StoryPole";

function iRailGlassHeight(
  panelHeight: number,
  stiles: string,
  pivotHardware: string,
  pivotAction: string,
  iRailSize: string,
  dlLayout: string,
  isSidelite: boolean,
) {
  const config = `${pivotHardware} ${pivotAction}`;

  const slim = 1.875;
  const euro = 2.8125;
  const tradEqual = 4.125;
  const tradNonEqual = 6.375;

  const rails = iRailSize === '5 1/4"' ? 4 : 1.25;
  const numHigh = Number(dlLayout.split("")[2]);
  const railMultiplier = numHigh - 1;
  const totalRails = rails * railMultiplier;

  function calc(topRail: number, bottomRail: number) {
    const openingHeight =
      (panelHeight - topRail - bottomRail - totalRails) / numHigh;
    return roundTo4(openingHeight);
  }

  let openingHeight = 0;

  if (stiles.includes("Bottom")) {
    if (stiles.includes("7")) {
      openingHeight = calc(tradEqual, tradNonEqual);
    } else {
      openingHeight = calc(tradEqual, tradEqual);
    }
  } else if (stiles.includes("Euro")) {
    if (config === "Fritsjurgens Self Closing") {
      openingHeight = calc(euro, tradEqual);
    } else {
      openingHeight = calc(euro, euro);
    }
  } else {
    //slimline
    if (config === "Fritsjurgens Self Closing") {
      openingHeight = calc(euro, tradEqual);
    } else if (config === "Fritsjurgens Free Swing") {
      openingHeight = calc(euro, slim);
    } else if (config === "Dorma Free Swing") {
      openingHeight = calc(slim, slim);
    }
  }

  if (!isSidelite) {
    return { openingHeight };
  }

  const sidelite = 0.25;

  const openingHeightTopBtm = roundTo4(openingHeight + sidelite);

  if (numHigh === 2) {
    return {
      openingHeight: openingHeightTopBtm,
    };
  }

  return {
    openingHeightTopBtm,
    openingHeight,
  };
}

export default iRailGlassHeight;
