function findImages(backplate: string, color: string): string {
  if (!backplate) return "";

  if (backplate === "Square Back Plate") {
    if (color === "Black") return "/PivotHardware/backplate_square_black";
    return "/PivotHardware/backplate_square_ss";
  }

  if (backplate === "Round Back Plate") {
    if (color === "Black") return "/PivotHardware/backplate_round_black";
    return "/PivotHardware/backplate_round_ss";
  }

  return "";
}

export default findImages;
