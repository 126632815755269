import React, { FC } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { SelectChangeEvent } from "@mui/material";
import { MenuItem } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import NumberFormatCustom from "./NumberFormatCustom";
import { styled } from "@mui/material/styles";
import { ColLayout } from "../util/accordianStyles";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 100,
  position: "relative",
}));

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFormHelperText-root": {
    margin: 0,
    padding: 3,
    backgroundColor: blueGrey[50],
  },
});

const Row = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledForm = styled("form")(({ theme }) => ({
  "& > *": {
    margin: theme.spacing(1),
    width: "25ch",
  },
}));

interface SizeInputProps {
  size: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sizeErr: string | undefined;
  name: string;
}

interface SizeLayoutProps extends SizeInputProps {
  fractionValue: string;
  handleFractionChange: (event: SelectChangeEvent<string>) => void;
}

const SizeLayout: FC<SizeLayoutProps> = ({
  size,
  handleChange,
  sizeErr,
  name,
  fractionValue,
  handleFractionChange,
}) => {
  return (
    <Row style={{ alignItems: !sizeErr ? "center" : "flex-start" }}>
      <SizeInput
        size={size}
        handleChange={handleChange}
        sizeErr={sizeErr}
        name={name}
      />

      <StyledFormControl
        margin="dense"
        variant={"outlined"}
        style={{ backgroundColor: "white" }}
      >
        <InputLabel>fraction</InputLabel>
        <Select
          value={fractionValue}
          onChange={handleFractionChange}
          name="fractionValue"
          label="fraction"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={"1/16"}>1/16</MenuItem>
          <MenuItem value={"1/8"}>1/8</MenuItem>
          <MenuItem value={"3/16"}>3/16</MenuItem>
          <MenuItem value={"1/4"}>1/4</MenuItem>
          <MenuItem value={"5/16"}>5/16</MenuItem>
          <MenuItem value={"3/8"}>3/8</MenuItem>
          <MenuItem value={"7/16"}>7/16</MenuItem>
          <MenuItem value={"1/2"}>1/2</MenuItem>
          <MenuItem value={"9/16"}>9/16</MenuItem>
          <MenuItem value={"5/8"}>5/8</MenuItem>
          <MenuItem value={"11/16"}>11/16</MenuItem>
          <MenuItem value={"3/4"}>3/4</MenuItem>
          <MenuItem value={"13/16"}>13/16</MenuItem>
          <MenuItem value={"7/8"}>7/8</MenuItem>
          <MenuItem value={"15/16"}>15/16</MenuItem>
        </Select>
      </StyledFormControl>
    </Row>
  );
};

const SizeInput: FC<SizeInputProps> = ({
  handleChange,
  size,
  sizeErr,
  name,
}) => {
  return (
    <StyledForm
      onSubmit={(e) => {
        e.preventDefault();
      }}
      noValidate
      autoComplete="off"
    >
      <ColLayout>
        <StyledTextField
          variant="outlined"
          style={{ backgroundColor: "white" }}
          error={sizeErr ? true : false}
          helperText={sizeErr || ""}
          required
          label={`Enter ${name === "height" ? "Height" : "Width"} (Inches)`}
          value={size}
          onChange={handleChange}
          name={name}
          id="formatted-numberformat-input"
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
        />
      </ColLayout>
    </StyledForm>
  );
};

export default SizeLayout;
