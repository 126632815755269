import React, { FC } from "react";
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledFormLabel = styled(FormLabel)({
  fontWeight: "bold",
  color: "black",
});

const StyledSpan = styled("span")({
  color: "red",
  fontWeight: "bold",
  fontSize: 22,
});

const StyledFormGroup = styled(FormGroup)({
  display: "flex",
  justifyContent: "center",
});

interface IncludeIssueProps {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  include: string;
}

const IncludeIssue: FC<IncludeIssueProps> = ({ handleChange, include }) => {
  return (
    <FormControl component="fieldset">
      <StyledFormLabel>
        Include Service Issue in email? <StyledSpan>*</StyledSpan>
      </StyledFormLabel>
      <StyledFormGroup aria-label="position" row>
        <FormControlLabel
          value="yes"
          control={
            <Checkbox
              value={"yes"}
              onChange={handleChange}
              checked={include === "yes" ? true : false}
            />
          }
          label="Yes"
          labelPlacement="start"
          style={{ paddingRight: 10 }}
        />
        <FormControlLabel
          value="no"
          control={
            <Checkbox
              value={"no"}
              onChange={handleChange}
              checked={include === "no" ? true : false}
            />
          }
          label="No"
          labelPlacement="end"
          style={{ paddingLeft: 10 }}
        />
      </StyledFormGroup>
    </FormControl>
  );
};

export default IncludeIssue;
