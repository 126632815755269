import React, { useState, useEffect, FC, SyntheticEvent } from "react";
import { Modal, Button, TextField, Autocomplete } from "@mui/material";
import getModalStyle from "../util/getModalStyle";
import api from "../api/api";
import { AdminDealer } from "./dealerZone/Service/ServiceRepair";
import { styled } from "@mui/material/styles";

const StyledDivModal = styled("div")(({ theme }) => ({
  position: "absolute",
  width: 400,
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  textAlign: "center",
}));

interface AdminDealerSearchProps {
  open: boolean;
  handleClose: () => void;
  adminFillFields: (lbl: AdminDealer) => void;
  role?: string;
}

const AdminDealerSearch: FC<AdminDealerSearchProps> = ({
  open,
  handleClose,
  adminFillFields,
  role,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const [dealers, setDealers] = useState([]);

  const [selectedDealer, setSD] = useState<AdminDealer | {}>({});

  useEffect(() => {
    let abortController: AbortController;

    (async () => {
      abortController = new AbortController();
      let signal = abortController.signal;

      const response = await api.get("/api/admin/getDealerReps", {
        signal,
      });

      setDealers(response.data);
    })();

    return () => abortController.abort();
  }, []);

  const handleChange = (
    event: SyntheticEvent<Element>,
    newValue: AdminDealer | {} | null,
  ) => setSD(!newValue ? {} : newValue);

  const body = (
    <StyledDivModal style={modalStyle}>
      {role && !role.includes("Manager") ? (
        <h3 id="simple-modal-description">Search by Dealer</h3>
      ) : (
        <h3 id="simple-modal-description">Select a Rep.</h3>
      )}

      <div style={{ padding: 20 }}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={dealers}
          sx={{ width: 300 }}
          onChange={handleChange}
          getOptionLabel={(option: AdminDealer) =>
            `${option.dealer} - ${option.dealerRep}`
          }
          renderInput={(params) => (
            <TextField
              variant="outlined"
              {...params}
              label="Dealer/Dealer Rep"
            />
          )}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button onClick={handleClose} variant="contained" color="inherit">
          Cancel
        </Button>

        <Button
          onClick={() => adminFillFields(selectedDealer as AdminDealer)}
          size="large"
          variant="contained"
          color="primary"
        >
          Confirm
        </Button>
      </div>
    </StyledDivModal>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default AdminDealerSearch;
