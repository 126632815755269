import React, { FC, Dispatch, SetStateAction } from "react";
import { Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledColStartDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "column",
  paddingTop: 10,
  paddingBottom: 10,
});

const StyledRowDiv = styled(Typography)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: 10,
  paddingBottom: 20,
});

const StyledRowCenterDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 20,
  marginLeft: 50,
});

const StyledColEndDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  flexDirection: "column",
});

const StyledInput = styled("input")({
  width: 100,
  height: 25,
  paddingLeft: 20,
  paddingRight: 20,
});

const StyledSpan = styled("span")({
  position: "absolute",
  right: 5,
  top: 2,
  zIndex: 2,
});

const StyledButton = styled(Button)({
  marginLeft: 40,
  color: "white",
});

interface FixedMarginProps {
  margin: string;
  setMargin: Dispatch<SetStateAction<string>>;
  setSuccess: Dispatch<SetStateAction<string>>;
  otsm: boolean;
  err: string;
  setErr: Dispatch<SetStateAction<string>>;
  text: string;
  handleClick: () => void;
}

const FixedMargin: FC<FixedMarginProps> = ({
  margin,
  setMargin,
  setSuccess,
  otsm,
  err,
  setErr,
  text,
  handleClick,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setSuccess("");

    const withDecimal = value.includes(".");

    if (withDecimal) {
      const decimalPlaces = value.split(".")[1].length;
      if (decimalPlaces > 3) return;
    }

    if (value.includes("-") || value.includes("+") || value.includes("e")) {
      setErr("Please Enter a Positive Whole Number");
    } else {
      var marginI = parseInt(value);
      marginI > 99 && marginI ? setErr("Must be between 0 and 99") : setErr("");
    }

    setMargin(value);
  };

  return (
    <StyledColStartDiv>
      {text === "Margin" && (
        <StyledRowDiv>
          <Typography style={{ fontWeight: "bold" }}>
            Add a fixed markup or margin to all of your quotes.
          </Typography>

          <Typography style={{ fontWeight: "normal", paddingLeft: 5 }}>
            (Prices will still be shown on the Configurator)
          </Typography>
        </StyledRowDiv>
      )}

      <StyledRowCenterDiv>
        <Typography style={{ paddingRight: 10 }}>
          Enter a Fixed {text}:
        </Typography>

        <StyledColEndDiv>
          <div style={{ position: "relative" }}>
            <StyledInput
              type="number"
              step="any"
              onChange={handleChange}
              value={margin}
              disabled={otsm}
              autoFocus
            />
            <StyledSpan>%</StyledSpan>
          </div>
          {err && (
            <Typography style={{ color: "red" }} variant="caption">
              {err}
            </Typography>
          )}
        </StyledColEndDiv>

        <StyledButton
          variant="contained"
          onClick={handleClick}
          style={{ backgroundColor: "crimson" }}
          size="small"
        >
          Remove
        </StyledButton>
      </StyledRowCenterDiv>
    </StyledColStartDiv>
  );
};

export default FixedMargin;
