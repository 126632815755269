const stripColor = [
  {
    id: 1,
    type: "weatherStripColor",
    label: "Bronze",
  },
  {
    id: 2,
    type: "weatherStripColor",
    label: "White",
  },
];

export { stripColor };
