import React, { memo, FC } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledFormControl = styled(FormControl)({
  position: "absolute",
  zIndex: 10,
  right: 5,
  top: 55,
});

export type ChangeQtyFn = (unitId: string, value: string | number) => void;

interface QuantityUpdateProps {
  changeQuantity: ChangeQtyFn;
  unitId: string;
  unitQuantity: number | string;
}

const QuantityUpdate: FC<QuantityUpdateProps> = ({
  changeQuantity,
  unitId,
  unitQuantity,
}) => {
  const handleChange = (event: SelectChangeEvent<number | string>) => {
    const { value } = event.target;
    changeQuantity(unitId, value);
  };

  const qtyOptions = Array.from({ length: 20 }, (x, i) => i + 1);

  return (
    <StyledFormControl variant="outlined" size="small">
      <Select
        onChange={handleChange}
        value={unitQuantity}
        autoWidth={true}
        inputProps={{
          sx: {
            paddingLeft: "5px",
          },
        }}
        sx={{ height: 20, fontSize: 12 }}
      >
        {qtyOptions.map((qty) => (
          <MenuItem dense={true} value={qty} key={qty}>
            Qty: {qty}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

export default memo(QuantityUpdate);
