import React, { FC } from "react";
import { Typography } from "@mui/material";
import { CustomerInfoState } from "./ServiceRepair";
import { styled } from "@mui/material/styles";

const StyledInfoDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "column",
  backgroundColor: "#fafafa",
  marginTop: 10,
  border: "2px solid lightgrey",
  borderRadius: "10px",
  padding: 20,
  flexBasis: "30%",
});

const StyledInvoiceDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

const StyledInput = styled("input")({
  width: 100,
  marginLeft: 5,
});

const StyledRedSpan = styled("span")({
  color: "red",
  fontSize: 22,
});

const StyledBoldTypography = styled(Typography)({
  fontWeight: "bold",
});

interface CustomerInfoProps {
  info: CustomerInfoState;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}

const CustomerInfo: FC<CustomerInfoProps> = ({
  info,
  handleChange,
  disabled,
}) => {
  const role = JSON.parse(sessionStorage.getItem("role") || JSON.stringify(""));

  return (
    <StyledInfoDiv>
      <StyledBoldTypography
        variant="h6"
        style={{ fontWeight: "bold", paddingBottom: 10 }}
      >
        Customer Info:
      </StyledBoldTypography>
      <StyledBoldTypography>
        Customer Name: <StyledRedSpan>*</StyledRedSpan>
        <StyledInput
          disabled={disabled}
          name="customerName"
          value={info.customerName}
          style={{ width: 120 }}
          onChange={handleChange}
        />
      </StyledBoldTypography>
      <StyledBoldTypography>
        Address: <StyledRedSpan>*</StyledRedSpan>
        <StyledInput
          disabled={disabled}
          name="address"
          value={info.address}
          onChange={handleChange}
          style={{ width: 250 }}
        />
      </StyledBoldTypography>

      <StyledInvoiceDiv>
        <StyledBoldTypography>
          City: <StyledRedSpan>*</StyledRedSpan>
          <StyledInput
            disabled={disabled}
            name="city"
            value={info.city}
            style={{ width: 120 }}
            onChange={handleChange}
          />
        </StyledBoldTypography>
        <StyledBoldTypography>
          State: <StyledRedSpan>*</StyledRedSpan>
          <StyledInput
            disabled={disabled}
            name="state"
            value={info.state}
            onChange={handleChange}
          />
        </StyledBoldTypography>
      </StyledInvoiceDiv>

      <StyledBoldTypography>
        Zip-code: <StyledRedSpan>*</StyledRedSpan>
        <StyledInput
          disabled={disabled}
          name="zip"
          value={info.zip}
          onChange={handleChange}
        />
      </StyledBoldTypography>
      <StyledBoldTypography>
        Phone Number: <StyledRedSpan>*</StyledRedSpan>
        <StyledInput
          disabled={disabled}
          name="phone"
          value={info.phone}
          onChange={handleChange}
        />
      </StyledBoldTypography>
      <StyledBoldTypography>
        Email:{" "}
        {role !== "Admin" && role !== "Inside Sales" && (
          <StyledRedSpan>*</StyledRedSpan>
        )}
        <StyledInput
          disabled={disabled}
          name="email"
          value={info.email}
          style={{ width: 250 }}
          onChange={handleChange}
        />
      </StyledBoldTypography>
    </StyledInfoDiv>
  );
};

export default CustomerInfo;
