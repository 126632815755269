import React, { memo } from "react";
import { Typography } from "@mui/material";
import Choices from "../../Choices";
import ReusableAccordian from "../../ReusableAccordion";
import { useStore } from "../../../store/store";
import { useAccordion } from "../../../store/accordion";
import { automation } from "./defaults";
import { RowLayout } from "../../../util/accordianStyles";
import LearnMore from "../../LearnMore";

function AutomationAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);

  const automationChoice = useStore((state) => state.quote.automation);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[30].expanded);

  const handleClick = (label: string) => {
    const checked = checkMark(label);
    addToQuote({ automation: !checked ? label : "" });
  };

  const variant = (label: string) =>
    label === automationChoice ? "filled" : "outlined";

  const checkMark = (label: string) => label === automationChoice;

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="automation"
      heading="Automation"
      secondaryHeading="Select Automation Choice"
      colOrRow="col"
      required={false}
    >
      <Typography style={{ fontWeight: "bold" }} variant="subtitle2">
        With Automation You Can Bring Your Doors To Life With Ease
      </Typography>
      <Typography variant="caption">
        *Automation System kit & install, By Others*
      </Typography>
      <Typography variant="caption">
        **Does not include installation fees**
      </Typography>

      <RowLayout style={{ justifyContent: "space-around", width: "30%" }}>
        <div onClick={() => handleClick(automation.label)}>
          <Choices
            label={automation.label}
            variant={variant(automation.label)}
            checkMark={checkMark(automation.label)}
          />
        </div>

        <LearnMore href="https://www.agmillworks.com/about_automation" />
      </RowLayout>
    </ReusableAccordian>
  );
}

export default memo(AutomationAccordian);
