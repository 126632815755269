import React, { useMemo, FC } from "react";
import { Typography } from "@mui/material";
import { Image } from "cloudinary-react";
import ReusableAccordian from "../ReusableAccordion";
import Choices from "../Choices";
import {
  pocketSliderImages,
  PocketSliderImageObj,
} from "../../initialValues/screens";
import { useStore } from "../../store/store";
import { useAccordion } from "../../store/accordion";
import { SliderScreenOption } from "./SliderScreen/defaults";
import { RowLayout, ColLayout } from "../../util/accordianStyles";

const group4 = ["PXXX", "PXXXX"];
const group5 = ["XXXP", "XXXXP"];

interface SliderScreenPocketProps {
  screenOptions: SliderScreenOption | undefined;
  isPocketing: boolean;
}

const SliderScreenPocketAccordian: FC<SliderScreenPocketProps> = ({
  screenOptions,
  isPocketing,
}) => {
  const addToQuote = useStore((state) => state.addToQuote);

  const chosenConfig = useStore((state) => state.quote.exactConfig);

  const screen = useStore((state) => state.quote.addScreen);
  const screenLayout = useStore((state) => state.quote.screenLayout);
  const screenPocket = useStore((state) => state.quote.screenPocket);

  let displayedPocketImg = "";

  if (!screen) {
    displayedPocketImg = "";
  } else if (screen?.includes("Double")) {
    displayedPocketImg = group4.includes(chosenConfig)
      ? "PXXX Double Pocket Double Screen"
      : group5.includes(chosenConfig)
        ? "XXXP Double Pocket Double Screen"
        : screenPocket &&
            !screenPocket.includes("Left") &&
            !screenPocket.includes("Right")
          ? "Double Screen"
          : screenPocket && screenPocket.includes("Right")
            ? "Double Screen Right Pocket"
            : screenPocket && screenPocket.includes("Left")
              ? "Double Screen Left Pocket"
              : "";
  } else {
    if (screenLayout) {
      displayedPocketImg = screenLayout.includes("Left")
        ? "Screen Left"
        : "Screen Right";
    } else {
      displayedPocketImg = "";
    }
  }

  const material = useStore((state) => state.quote.material);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expandedPocket = useAccordion((state) => state.expanded[26].expanded);

  const pocketToShow =
    screen &&
    screen.includes("Double") &&
    screenOptions &&
    screenOptions.doublePocket
      ? screenOptions.doublePocket
      : screenOptions?.pocket;

  const getCloudinaryId = (image: PocketSliderImageObj | undefined) => {
    if (!image) return "";

    if (material === "All Wood" && !isPocketing) return image.imgWood;
    if (material === "All Wood" && isPocketing) return image.imgPocketingWood;
    if (material !== "All Wood" && !isPocketing) return image.img;
    if (material !== "All Wood" && isPocketing) return image.imgPocketing;

    return "";
  };

  const handleClick = (label: string, disabled: boolean) => {
    if (disabled) return;

    const checked = label === screenPocket;

    if (checked) {
      let update = { screenPocket: "" };

      if (label.includes("Double")) {
        if (!isPocketing && chosenConfig.includes("-"))
          return addToQuote(update);

        if (!isPocketing) {
          if (chosenConfig.slice(-1) === "X") {
            update["screenPocket"] = "Add Pocket Right (upcharge applies)";
          } else {
            update["screenPocket"] = "Add Pocket Left (upcharge applies)";
          }
        }
      }

      addToQuote(update);
    } else {
      addToQuote({ screenPocket: label });
    }
  };

  const filteredPocketImg = useMemo(() => {
    if (!displayedPocketImg) return null;

    const image: PocketSliderImageObj | undefined = pocketSliderImages.find(
      (img) => img.label === displayedPocketImg,
    );
    const publicId: string = getCloudinaryId(image);

    return {
      label: image ? image.label : "",
      publicId,
      img2: image ? image.img2 : "",
    };
  }, [displayedPocketImg, material, isPocketing]);

  const pocketLabel =
    screenPocket && screenPocket.replace(" Left", "").replace(" Right", "");

  return (
    <ReusableAccordian
      expanded={expandedPocket}
      handleAccordClick={handleAccordClick}
      accordionName="screenPocket"
      heading="Screen Pocket"
      secondaryHeading="Add a screen pocket?"
      colOrRow="col"
      required={false}
    >
      <ColLayout style={{ marginLeft: 40 }}>
        <Typography variant="caption">
          *upcharge applies for each pocket added
        </Typography>

        {chosenConfig.includes("O") &&
        (displayedPocketImg === "Double Screen Right Pocket" ||
          displayedPocketImg === "Double Screen Left Pocket") ? (
          <Typography variant="caption">
            **Screen Pocket is required with this configuration to avoid
            blocking handle hardware
          </Typography>
        ) : null}
        <RowLayout>
          {pocketToShow?.map((state) => (
            <div
              key={state.id}
              onClick={() => handleClick(state.label, state.disabled || false)}
            >
              <Choices
                label={state.label}
                variant={state.label === pocketLabel ? "filled" : "outlined"}
                checkMark={state.label === pocketLabel}
                disabled={state.disabled || false}
                wide={true}
              />
            </div>
          ))}
        </RowLayout>
      </ColLayout>

      <RowLayout>
        {displayedPocketImg && filteredPocketImg?.label && (
          <RowLayout>
            <ColLayout style={{ marginRight: 20 }}>
              <Image
                secure="true"
                cloudName="ag-millworks"
                publicId={filteredPocketImg.publicId}
              />
              <Typography variant="caption">*As viewed from above</Typography>
            </ColLayout>

            <ColLayout style={{ marginLeft: 20 }}>
              <img src={filteredPocketImg.img2} alt="pocket2" />
              <Typography variant="caption">
                *As viewed from exterior
              </Typography>
            </ColLayout>
          </RowLayout>
        )}
      </RowLayout>
    </ReusableAccordian>
  );
};

export default SliderScreenPocketAccordian;
