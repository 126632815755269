const CLOUDINARY_URL =
  "https://res.cloudinary.com/ag-millworks/image/upload/Swing_Configs";

export const taggedSwingConfigs = [
  {
    swingDirection: "Outswing",
    SwingType: "Pair Swing",
    Sidelites: "Add Double Sidelite",
    label: "Outswing Pair 2 Sidelite",
    img1: `${CLOUDINARY_URL}/outswing/OSLH_PR_2xSL.jpg`,
    img2: `${CLOUDINARY_URL}/outswing/OSRH_PR_2xSL.jpg`,
    img1M: `${CLOUDINARY_URL}/outswing/measurements/OSLH_PR_2XSL-M.jpg`,
    img2M: `${CLOUDINARY_URL}/outswing/measurements/OSRH_PR_2XSL-M.jpg`,
  },
  {
    swingDirection: "Outswing",
    SwingType: "Pair Swing",
    Sidelites: "none",
    label: "Outswing Pair",
    img1: `${CLOUDINARY_URL}/outswing/OSLH_PR.jpg`,
    img2: `${CLOUDINARY_URL}/outswing/OSRH_PR.jpg`,
    img1M: `${CLOUDINARY_URL}/outswing/measurements/OSLH_PR-M.jpg`,
    img2M: `${CLOUDINARY_URL}/outswing/measurements/OSRH-PR-M.jpg`,
  },
  {
    swingDirection: "Outswing",
    SwingType: "Single Swing",
    Sidelites: "Add Single Sidelite",
    label: "Outswing Single 1 Sidelite",
    img1: `${CLOUDINARY_URL}/outswing/OSLH_1xSL.jpg`,
    img2: `${CLOUDINARY_URL}/outswing/OSRH_1xSL.jpg`,
    img3: `${CLOUDINARY_URL}/outswing/LH_SINGLE_1XSIDELITES_LOCK.jpg`,
    img4: `${CLOUDINARY_URL}/outswing/RH_SINGLE_1XSIDELITES_LOCK.jpg`,
    img1M: `${CLOUDINARY_URL}/outswing/measurements/OSLH_1xSL-M.jpg`,
    img2M: `${CLOUDINARY_URL}/outswing/measurements/OSRH_1xSL-M.jpg`,
    img3M: `${CLOUDINARY_URL}/outswing/measurements/OSLH_SINGLE_SL_LOCK.jpg`,
    img4M: `${CLOUDINARY_URL}/outswing/measurements/OSRH_SINGLE_SL_LOCK.jpg`,
  },
  {
    swingDirection: "Outswing",
    SwingType: "Single Swing",
    Sidelites: "Add Double Sidelite",
    label: "Outswing Single 2 Sidelite",
    img1: `${CLOUDINARY_URL}/outswing/OSLH_2xSL.jpg`,
    img2: `${CLOUDINARY_URL}/outswing/OSRH_2xSL.jpg`,
    img1M: `${CLOUDINARY_URL}/outswing/measurements/OSLH_2XSL-M.jpg`,
    img2M: `${CLOUDINARY_URL}/outswing/measurements/OSRH_2XSL-M.jpg`,
  },
  {
    swingDirection: "Outswing",
    SwingType: "Single Swing",
    Sidelites: "none",
    label: "Outswing Single",
    img1: `${CLOUDINARY_URL}/outswing/OSLH_A.jpg`,
    img2: `${CLOUDINARY_URL}/outswing/OSRH.jpg`,
    img1M: `${CLOUDINARY_URL}/outswing/measurements/OSLH-M.jpg`,
    img2M: `${CLOUDINARY_URL}/outswing/measurements/OSRH-M.jpg`,
  },

  {
    swingDirection: "Inswing",
    SwingType: "Pair Swing",
    Sidelites: "Add Double Sidelite",
    label: "Inswing Pair 2 Sidelite",
    img1: `${CLOUDINARY_URL}/inswing/INSWING_RH_PAIR_2XSIDELITES.jpg`,
    img2: `${CLOUDINARY_URL}/inswing/INSWING_LH_PAIR_2XSIDELITES.jpg`,
    img1M: `${CLOUDINARY_URL}/inswing/measurements/IS_PAIR_LH_2XSL.jpg`,
    img2M: `${CLOUDINARY_URL}/inswing/measurements/IS_PAIR_RH_2XSL.jpg`,
  },
  {
    swingDirection: "Inswing",
    SwingType: "Pair Swing",
    Sidelites: "none",
    label: "Inswing Pair",
    img1: `${CLOUDINARY_URL}/inswing/INSWING_RH_PAIR.jpg`,
    img2: `${CLOUDINARY_URL}/inswing/INSWING_LH_PAIR.jpg`,
    img1M: `${CLOUDINARY_URL}/inswing/measurements/IS_PAIR_LH.jpg`,
    img2M: `${CLOUDINARY_URL}/inswing/measurements/IS_PAIR_RH.jpg`,
  },
  {
    swingDirection: "Inswing",
    SwingType: "Single Swing",
    Sidelites: "Add Single Sidelite",
    label: "Inswing Single 1 Sidelite ",
    img1: `${CLOUDINARY_URL}/inswing/INSWING_RH_SINGLE_1XSIDELITES.jpg`,
    img2: `${CLOUDINARY_URL}/inswing/INSWING_LH_SINGLE_1XSIDELITES.jpg`,
    img4: `${CLOUDINARY_URL}/inswing/RH_SINGLE_1XSIDELITES_LOCK.jpg`,
    img3: `${CLOUDINARY_URL}/inswing/LH_SINGLE_1XSIDELITES_LOCK.jpg`,
    img1M: `${CLOUDINARY_URL}/inswing/measurements/IS_SINGLE_LH_SL.jpg`,
    img2M: `${CLOUDINARY_URL}/inswing/measurements/IS_SINGLE_RH_SL.jpg`,
    img3M: `${CLOUDINARY_URL}/inswing/measurements/IS_SINGLE_LH_SL_LOCK.jpg`,
    img4M: `${CLOUDINARY_URL}/inswing/measurements/IS_SINGLE_RH_SL_LOCK.jpg`,
  },
  {
    swingDirection: "Inswing",
    SwingType: "Single Swing",
    Sidelites: "Add Double Sidelite",
    label: "Inswing Single 2 Sidelite",
    img1: `${CLOUDINARY_URL}/inswing/INSWING_RH_SINGLE_2XSIDELITES.jpg`,
    img2: `${CLOUDINARY_URL}/inswing/INSWING_LH_SINGLE_2XSIDELITES.jpg`,
    img1M: `${CLOUDINARY_URL}/inswing/measurements/IS_SINGLE_LH_2XSL.jpg`,
    img2M: `${CLOUDINARY_URL}/inswing/measurements/IS_SINGLE_RH_2XSL.jpg`,
  },
  {
    swingDirection: "Inswing",
    SwingType: "Single Swing",
    Sidelites: "none",
    label: "Inswing Single",
    img1: `${CLOUDINARY_URL}/inswing/INSWING_RH_SINGLE.jpg`,
    img2: `${CLOUDINARY_URL}/inswing/INSWING_LH_SINGLE.jpg`,
    img1M: `${CLOUDINARY_URL}/inswing/measurements/IS_SINGLE_LH.jpg`,
    img2M: `${CLOUDINARY_URL}/inswing/measurements/IS_SINGLE_RH.jpg`,
  },
];

export const equalPanelsSwing = [
  {
    swingDirection: "Outswing",
    SwingType: "Pair Swing",
    Sidelites: "Add Double Sidelite",
    label: "Outswing Pair 2 Sidelite",
    img1: `${CLOUDINARY_URL}/outswing/LH_PAIR_2x_SIDELITE_EVEN.jpg`,
    img2: `${CLOUDINARY_URL}/outswing/RH_PAIR_2xSIDELITE_EVEN.jpg`,
    img1M: `${CLOUDINARY_URL}/outswing/measurements/OSLH_PR_2XSL-M.jpg`,
    img2M: `${CLOUDINARY_URL}/outswing/measurements/OSRH_PR_2XSL-M.jpg`,
  },
  {
    swingDirection: "Outswing",
    SwingType: "Single Swing",
    Sidelites: "Add Single Sidelite",
    label: "Outswing Single 1 Sidelite",
    img1: `${CLOUDINARY_URL}/outswing/SINGLE_LH_1xSIDELITE_EVEN.jpg`,
    img2: `${CLOUDINARY_URL}/outswing/SINGLE_RH_1xSIDELITE_EVEN.jpg`,
    img3: `${CLOUDINARY_URL}/outswing/LH_SINGLE_1XSIDELITES_EVEN_LOCK.jpg`,
    img4: `${CLOUDINARY_URL}/outswing/RH_SINGLE_1XSIDELITES_EVEN_LOCK.jpg`,
    img1M: `${CLOUDINARY_URL}/outswing/measurements/OSLH_1xSL-M.jpg`,
    img2M: `${CLOUDINARY_URL}/outswing/measurements/OSRH_1xSL-M.jpg`,
    img3M: `${CLOUDINARY_URL}/outswing/measurements/OSLH_SINGLE_SL_LOCK.jpg`,
    img4M: `${CLOUDINARY_URL}/outswing/measurements/OSRH_SINGLE_SL_LOCK.jpg`,
  },
  {
    swingDirection: "Outswing",
    SwingType: "Single Swing",
    Sidelites: "Add Double Sidelite",
    label: "Outswing Single 2 Sidelite",
    img1: `${CLOUDINARY_URL}/outswing/LH_SINGLE_2xSIDELITE_EVEN.jpg`,
    img2: `${CLOUDINARY_URL}/outswing/RH_SINGLE_2xSIDELITE_EVEN.jpg`,
    img1M: `${CLOUDINARY_URL}/outswing/measurements/OSLH_2XSL-M.jpg`,
    img2M: `${CLOUDINARY_URL}/outswing/measurements/OSRH_2XSL-M.jpg`,
  },
  {
    swingDirection: "Inswing",
    SwingType: "Pair Swing",
    Sidelites: "Add Double Sidelite",
    label: "Inswing Pair 2 Sidelite",
    img1: `${CLOUDINARY_URL}/inswing/LH_PAIR_2xSIDELITE_EVEN.jpg`,
    img2: `${CLOUDINARY_URL}/inswing/RH_PAIR_2xSIDELITE_EVEN.jpg`,
    img1M: `${CLOUDINARY_URL}/inswing/measurements/IS_PAIR_LH_2XSL.jpg`,
    img2M: `${CLOUDINARY_URL}/inswing/measurements/IS_PAIR_RH_2XSL.jpg`,
  },
  {
    swingDirection: "Inswing",
    SwingType: "Single Swing",
    Sidelites: "Add Single Sidelite",
    label: "Inswing Single 1 Sidelite ",
    img1: `${CLOUDINARY_URL}/inswing/LH_SINGLE_1xSIDELITE_EVEN.jpg`,
    img2: `${CLOUDINARY_URL}/inswing/RH_SINGLE_1xSIDELITE_EVEN.jpg`,
    img3: `${CLOUDINARY_URL}/inswing/LH_SINGLE_1XSIDELITES_EVEN_LOCK.jpg`,
    img4: `${CLOUDINARY_URL}/inswing/RH_SINGLE_1XSIDELITES_EVEN_LOCK.jpg`,
    img1M: `${CLOUDINARY_URL}/inswing/measurements/IS_SINGLE_LH_SL.jpg`,
    img2M: `${CLOUDINARY_URL}/inswing/measurements/IS_SINGLE_RH_SL.jpg`,
    img3M: `${CLOUDINARY_URL}/inswing/measurements/IS_SINGLE_LH_SL_LOCK.jpg`,
    img4M: `${CLOUDINARY_URL}/inswing/measurements/IS_SINGLE_RH_SL_LOCK.jpg`,
  },
  {
    swingDirection: "Inswing",
    SwingType: "Single Swing",
    Sidelites: "Add Double Sidelite",
    label: "Inswing Single 2 Sidelite",
    img1: `${CLOUDINARY_URL}/inswing/LH_SINGLE_2xSIDELITE_EVEN.jpg`,
    img2: `${CLOUDINARY_URL}/inswing/RH_SINGLE_2xSIDELITE_EVEN.jpg`,
    img1M: `${CLOUDINARY_URL}/inswing/measurements/IS_SINGLE_LH_2XSL.jpg`,
    img2M: `${CLOUDINARY_URL}/inswing/measurements/IS_SINGLE_RH_2XSL.jpg`,
  },
];
