import React, { useEffect } from "react";
import ReusableAccordian from "./ReusableAccordion";
import fractionToInt from "../util/fractionToInt";
import SizeLayout from "./SizeLayout";
import { useStore } from "../store/store";
import { useAccordion } from "../store/accordion";
import { Typography } from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import { RowLayout, ColLayout } from "../util/accordianStyles";

export default function FPWidthAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);

  const width = useStore((state) => state.quote.unitSize);
  const fraction = useStore((state) => state.quote.unitSizeFraction);

  const overall = fractionToInt(width, fraction);

  const unitSizeFraction = !fraction ? "0" : fraction;

  const handleError = useStore((state) => state.handleError);

  const widthErr = useStore((state) => state.widthErr);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[2].expanded);

  useEffect(() => {
    const max = 95;
    const min = 13;

    const message =
      overall > max
        ? `Width can not exceed ${max}"`
        : overall < min
          ? `Width must exceed ${min}"`
          : "";

    handleError("widthErr", message);
  }, [overall]);

  const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    addToQuote({ unitSize: value }, "unitSize");
  };

  const handleFractionChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    const fractionVal = value === null ? "" : value;
    addToQuote({ unitSizeFraction: fractionVal });
  };

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="unitSize"
      heading="Net Frame Width"
      secondaryHeading=" Select a Width"
      required={false}
      required5={true}
    >
      <ColLayout>
        <Typography style={{ paddingBottom: 20, marginTop: -20 }}>
          Note: We recommend Net Frame Width to be 1 inch less than the Rough
          Opening Width
        </Typography>

        <RowLayout>
          <SizeLayout
            size={width}
            handleChange={handleWidthChange}
            sizeErr={widthErr}
            name="width"
            fractionValue={unitSizeFraction}
            handleFractionChange={handleFractionChange}
          />
        </RowLayout>
      </ColLayout>
    </ReusableAccordian>
  );
}
