import { pivotPoints, pivotPoints2 } from "./defaults";

function pivotPointOptions(hardware: string, width: number) {
  if (hardware === "Fritsjurgens" && width >= 72) {
    return pivotPoints;
  }

  if (width < 28) {
    return pivotPoints2.filter((itm) => itm.id === 1);
  }

  if (width < 32) {
    return pivotPoints2.filter((itm) => itm.id <= 2);
  }

  if (width < 36) {
    return pivotPoints2.filter((itm) => itm.id <= 3);
  }

  if (hardware === "Dorma") {
    if (width > 48 && width < 56) {
      return pivotPoints2.filter((itm) => itm.id !== 1);
    }

    if (width > 56) {
      return pivotPoints2.filter((itm) => itm.id > 2);
    }
  }

  return pivotPoints2;
}

export default pivotPointOptions;
