const handleHeight = [
  {
    id: 1,
    type: "handleHeight",
    label: '36"',
    variant: "outlined",
    checkMark: false,
  },
  {
    id: 2,
    type: "handleHeight",
    label: '42"',
    variant: "outlined",
    checkMark: false,
  },
];

export { handleHeight };
