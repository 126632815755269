import React, { useState, FC, Dispatch, SetStateAction } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import getModalStyle from "../../../../util/getModalStyle";
import { StyledModalDiv } from "../../../../util/accordianStyles";

interface ErrModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  modalText: string;
}

const ErrModal: FC<ErrModalProps> = ({ open, setOpen, modalText }) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const handleClose = () => setOpen(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if(event.key === "Enter"){
      return handleClose()
    }
  }

  const body = (
    <StyledModalDiv style={modalStyle} onKeyDown={handleKeyDown}>
      <h3 id="simple-modal-description">{modalText}</h3>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={handleClose} color="primary" variant="contained">
          OK
        </Button>
      </div>
    </StyledModalDiv>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default ErrModal;
