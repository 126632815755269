import React, { useState, FC } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import getModalStyle from "../util/getModalStyle";
import { styled } from "@mui/material/styles";

const StyledDivModal = styled("div")(({ theme }) => ({
  position: "absolute",
  width: 400,
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  textAlign: "center",
}));

interface ExpiredModalProps {
  open: boolean;
  handleClose: () => void;
  modalText: string;
  changeDoor: boolean;
  switchTabs?: () => void;
}

const ExpiredModal: FC<ExpiredModalProps> = ({
  open,
  handleClose,
  modalText,
  changeDoor,
  switchTabs,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const body = (
    <StyledDivModal style={modalStyle}>
      <h3 id="simple-modal-description">{modalText}</h3>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        {changeDoor && (
          <Button onClick={handleClose} variant="contained" color="inherit">
            Cancel
          </Button>
        )}
        <Button
          onClick={!changeDoor ? handleClose : switchTabs}
          color="primary"
          variant="contained"
        >
          OK
        </Button>
      </div>
    </StyledDivModal>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default ExpiredModal;
