import React, { useState, FC } from "react";
import getModalStyle from "../../../util/getModalStyle";
import { Typography, Button, Modal, Divider } from "@mui/material";
import { ColLayout, StyledModalDiv } from "../../../util/accordianStyles";

interface EmailWarningModalProps {
  open: boolean;
  handleClose: () => void;
  handleOpenEmailModal: () => void;
}

const EmailWarningModal: FC<EmailWarningModalProps> = ({
  open,
  handleClose,
  handleOpenEmailModal,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if(event.key === "Enter"){
      return handleOpenEmailModal()
    }
  }

  const body = (
    <StyledModalDiv style={{ ...modalStyle, width: 600 }} onKeyDown={handleKeyDown}>
      <ColLayout style={{ padding: 10, marginBottom: 15 }}>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          Email Quote Directly to Customer
        </Typography>
        <Typography variant="caption">
          *email will be sent from no-reply@agmillworks.com
        </Typography>
        <Divider
          style={{ width: "100%", paddingTop: 5 }}
          orientation="horizontal"
        />
      </ColLayout>
      <Typography variant="h5">
        Would you like to continue without adding a margin/markup?
      </Typography>

      <div
        style={{ display: "flex", justifyContent: "center", paddingTop: 40 }}
      >
        <Button
          onClick={handleClose}
          variant="contained"
          color="inherit"
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleOpenEmailModal}
          variant="contained"
          color="primary"
          style={{ marginLeft: 10 }}
        >
          Continue
        </Button>
      </div>
    </StyledModalDiv>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

export default EmailWarningModal;
