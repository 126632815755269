import React, { memo } from "react";
import ReusableAccordian from "../ReusableAccordion";
import { useStore } from "../../store/store";
import { useAccordion } from "../../store/accordion";
import { dropsealOption } from "./defaults";
import Choices from "../Choices";
import { Image } from "cloudinary-react";
import fractionToInt from "../../util/fractionToInt";
import { Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Alert from "@mui/material/Alert";
import { RowLayout } from "../../util/accordianStyles";

interface UpdateObj {
  dropseal?: string;
  hingeColor?: string;
}

function PivotDropsealAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);
  const dropseal = useStore((state) => state.quote.dropseal);
  const pivotHardware = useStore((state) => state.quote.pivotHardware);

  const swingPanelWidth = useStore((state) => state.quote.swingPanelWidth);
  const swingPanelFraction = useStore(
    (state) => state.quote.swingPanelFraction,
  );

  const panelWidth = fractionToInt(swingPanelWidth || "", swingPanelFraction);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[38]?.expanded);

  const handleClick = (label: string) => {
    const checked = label === dropseal;

    if (checked) {
      addToQuote({ dropseal: "" }, "dropseal");
    } else {
      let update: UpdateObj = { dropseal: label };
      if (pivotHardware === "Fritsjurgens") {
        update["hingeColor"] = "Stainless Steel";
      }
      addToQuote(update);
    }
  };

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="dropseal"
      heading="Drop Seal"
      secondaryHeading="Add a Drop Seal"
      colOrRow="col"
      required={false}
    >
      {swingPanelWidth && panelWidth > 90 ? (
        <Alert severity="error">
          Drop Seal not available when panel width is greater than 90 inches
        </Alert>
      ) : (
        <>
          <RowLayout style={{ paddingBottom: 20 }}>
            <InfoIcon color="primary" style={{ marginRight: 10 }} />

            <Typography>
              A drop seal is a weatherstripping device at the bottom of the door
              that automatically descends when the door is closed, preventing
              drafts and enhancing insulation.
            </Typography>
          </RowLayout>

          <div
            onClick={() => handleClick(dropsealOption.label)}
            style={{ paddingBottom: 40 }}
          >
            <Choices
              label={dropsealOption.label}
              variant={
                dropsealOption.label === dropseal ? "filled" : "outlined"
              }
              checkMark={dropsealOption.label === dropseal}
              wide={true}
            />
          </div>

          <Image
            cloudName="ag-millworks"
            secure="true"
            publicId="/Dropseal/dropseal_v2"
          />
        </>
      )}
    </ReusableAccordian>
  );
}

export default memo(PivotDropsealAccordian);
