import React, { FC } from "react";
import { Typography, CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";

const StyledDivCol = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 20,
});

const StyledDivRow = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  aignItems: "center",
  width: "100%",
  padding: 10,
});

const StyledCircularProgress = styled(CircularProgress)({
  fontSize: 14,
  marginTop: 10,
});

interface UploadsProps {
  loading: number;
  handleSubmit: (
    e: React.ChangeEvent<HTMLInputElement>,
    imgNum: number,
  ) => void;
  uploadURLs: {
    u1?: string;
    u2?: string;
    u3?: string;
  };
}

const Uploads: FC<UploadsProps> = ({ loading, handleSubmit, uploadURLs }) => {
  return (
    <StyledDivCol style={{ padding: 20 }}>
      <Typography style={{ fontWeight: "bold", paddingBottom: 10 }}>
        UPLOAD UP TO 3 PHOTOS (Optional)
      </Typography>

      <StyledDivRow>
        <Uploader
          imgLoading={loading}
          label="#1 Overall Shot of the Door/Window Interior"
          imgNumber={1}
          uploadSuccess={uploadURLs.u1}
          handleSubmit={handleSubmit}
          name="u1"
        />

        <Uploader
          imgLoading={loading}
          label="#2 Overall Shot of the Door/Window Exterior"
          imgNumber={2}
          uploadSuccess={uploadURLs.u2}
          handleSubmit={handleSubmit}
          name="u2"
        />

        <Uploader
          imgLoading={loading}
          label="#3 Detail Clearly Showing the Issue"
          imgNumber={3}
          uploadSuccess={uploadURLs.u3}
          handleSubmit={handleSubmit}
          name="u3"
        />
      </StyledDivRow>
    </StyledDivCol>
  );
};

interface UploaderProps {
  imgLoading: number;
  label: string;
  imgNumber: number;
  uploadSuccess: string | undefined;
  handleSubmit: (
    e: React.ChangeEvent<HTMLInputElement>,
    imgNum: number,
  ) => void;
  name: string;
}

const Uploader: FC<UploaderProps> = ({
  imgLoading,
  label,
  imgNumber,
  uploadSuccess,
  handleSubmit,
  name,
}) => {
  return (
    <StyledDivCol>
      <Typography>{label}</Typography>
      <input
        type="file"
        name={name}
        onChange={(e) => handleSubmit(e, imgNumber)}
      />

      {uploadSuccess && imgLoading !== imgNumber && (
        <div style={{ padding: 20 }}>
          <Alert severity="success">Upload Complete</Alert>
        </div>
      )}

      {imgLoading === imgNumber && <StyledCircularProgress />}
    </StyledDivCol>
  );
};

export default Uploads;
