import React, { useState, FC, SyntheticEvent } from "react";
import {
  Tabs,
  Tab,
  Button,
  Typography,
  AppBar,
  Toolbar,
  Hidden,
  Drawer,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import { Alert } from "@mui/material";
import UploadWidget from "../components/dealerZone/Settings/UploadWidget";
import { useQuery, useQueryClient } from "react-query";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIos";
import FixedMargin from "../components/dealerZone/Settings/FixedMargin";
import OTSM from "../components/dealerZone/Settings/OTSM";
import Copyright from "../components/Copyright";
import DeliveryMarkup from "../components/dealerZone/Settings/DeliveryMarkup";
import { getUser, postOTSM } from "../api/SettingsApi";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import TabPanel from "../components/TabPanel";

const StyledDivOvHidden = styled("div")({
  position: "relative",
  overflow: "hidden",
});

const StyledReturnBtn = styled(Button)({
  position: "absolute",
  top: 40,
  left: 40,
  zIndex: 5000,
});

const StyledTabs = styled(Tabs)({
  backgroundColor: "#616161",
  height: "100vh",
  paddingTop: 100,
  width: 320,
});

const StyledTab = styled(Tab)({
  color: "white",
  width: "100%",
  fontWeight: "bold",
});

const StyledUploadTab = styled(Tab)({
  color: "white",
  fontWeight: "bold",
});

const StyledFlexDiv = styled("div")({
  display: "flex",
  position: "relative",
  minHeight: "100vh",
});

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "center",
  position: "relative",
});

const StyledLogoImg = styled("img")({
  height: 80,
  width: 300,
  margin: 10,
});

const StyledDivColStart = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "column",
  padding: 10,
  margin: 20,
});

const StyledTypography = styled(Typography)({
  textDecoration: "underline",
  paddingBottom: 20,
  fontWeight: "bold",
  alignSelf: "center",
  marginRight: 100,
});

const StyledTypography2 = styled(Typography)({
  textDecoration: "underline",
  paddingBottom: 20,
  fontWeight: "bold",
  alignSelf: "center",
});

const StyledDivColCenter = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 50,
  alignSelf: "center",
});

const StyledDivColCenterPad = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: 20,
  margin: 20,
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
  position: "absolute",
  left: 20,
}));

const drawerWidthSidebar = 310;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${drawerWidthSidebar}px)`,
    marginLeft: drawerWidthSidebar,
  },
  display: "flex",
  backgroundColor: "#fafafa",
  width: "100%",
}));

const StyledDivRoot = styled("div")({
  display: "flex",
  minHeight: "100vh",
  paddingBottom: "2rem",
  position: "relative",
  backgroundColor: "#fafafa",
  maxWidth: "100%",
  width: "100%",
});

const StyledNav = styled("nav")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    width: drawerWidthSidebar,
    flexShrink: 0,
  },
}));

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

interface SettingsProps {
  window?: (() => { document: { body: Element } }) | undefined;
}

const Settings: FC<SettingsProps> = ({ window }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const { userId, previous } = location.state;

  const { data, isFetching } = useQuery(
    "UserAccount",
    async () => {
      const response = await getUser(userId);

      response.data.fixedMargin
        ? setFixedMargin(response.data.fixedMargin)
        : setFixedMargin("");

      response.data.fixedMarkup
        ? setMarkup(response.data.fixedMarkup)
        : setMarkup("");

      if (response.data.otsm) {
        setOtsm(true);

        if (response.data.otsmDefault === "Fixed Markup") {
          setOtsmDefault(true);
        }
      }

      setDeliveryMarkup(response.data.deliveryMarkup ? "Yes" : "No");

      return response.data;
    },
    { refetchOnWindowFocus: false },
  );

  const queryClient = useQueryClient();

  const [fixedMargin, setFixedMargin] = useState("");

  const [markup, setMarkup] = useState("");

  const [err, setErr] = useState("");

  const [otsm, setOtsm] = useState(false);

  const [otsmDefault, setOtsmDefault] = useState(false);

  const [value, setValue] = useState(0);

  const [success, setSuccess] = useState("");

  const [deliveryMarkup, setDeliveryMarkup] = useState("No");

  const changeDeliveryMarkup = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setDeliveryMarkup(value);
  };

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    data.otsm && setOtsm(true);
    data.fixedMargin && setFixedMargin(data.fixedMargin);
    data.fixedMarkup && setMarkup(data.fixedMarkup);
    success && setSuccess("");
  };

  const handleClick = async () => {
    if (otsm && !fixedMargin && !markup) {
      setSuccess(
        "Fixed Margin or Markup is Required for Over the Shoulder Mode.",
      );
      return;
    }

    if (otsm && !otsmDefault && !fixedMargin) {
      setSuccess("Fixed Margin is Required.");
      return;
    }

    if (otsm && otsmDefault && !markup) {
      setSuccess("Fixed Markup is Required.");
      return;
    }

    const res = await postOTSM(fixedMargin, markup, otsm, otsmDefault);

    if (res.data === "Settings Updated") {
      queryClient.invalidateQueries("UserAccount");
      setSuccess("Your Settings Have Been Updated.");
      return queryClient.invalidateQueries("Dealer");
    }
  };

  const returnToPreviousPage = () => {
    const user = JSON.parse(
      sessionStorage.getItem("user") || JSON.stringify(""),
    );
    const backRoute = previous || "dealerZone";
    if (backRoute === "orderSummary") {
      sessionStorage.setItem("refresh", JSON.stringify(true));
    }
    navigate(`/${backRoute}/${user}`, {
      state: { ...location.state },
    });
  };

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const handleClickRemoveMargin = () => {
    setFixedMargin("");
    setSuccess("");
    if (!markup && otsm) setOtsm(false);
  };

  const handleClickRemoveMarkup = () => {
    setMarkup("");
    setSuccess("");
    if (!fixedMargin && otsm) setOtsm(false);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <StyledDivOvHidden>
      <StyledReturnBtn
        onClick={returnToPreviousPage}
        variant="contained"
        size="small"
        color="cyan"
      >
        <ArrowBackIcon style={{ fontSize: 16 }} />
        Return{" "}
        {previous === "dealerZone"
          ? "to Dealer Zone"
          : previous === "config"
            ? "to Cart"
            : previous === "orderSummary"
              ? "to Summary"
              : previous === "form"
                ? "Form"
                : ""}
      </StyledReturnBtn>

      <StyledTabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs"
        variant="fullWidth"
        // indicatorColor=""
      >
        <StyledTab
          label="Fixed Margin / Markup"
          {...a11yProps(0)}
          style={{
            backgroundColor: value === 0 ? "#4b4b4b" : "",
            color: "white",
          }}
        />
        <StyledUploadTab
          label="Upload Logo"
          {...a11yProps(1)}
          style={{
            backgroundColor: value === 1 ? "#4b4b4b" : "",
            color: "white",
          }}
        />
        <StyledUploadTab
          label="Delivery Markup"
          {...a11yProps(2)}
          style={{
            backgroundColor: value === 2 ? "#4b4b4b" : "",
            color: "white",
          }}
        />
      </StyledTabs>
    </StyledDivOvHidden>
  );

  return (
    <StyledFlexDiv>
      <StyledDivRoot>
        <StyledAppBar position="fixed">
          <StyledToolbar>
            <StyledIconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              size="large"
            >
              <MenuIcon />
            </StyledIconButton>
            <StyledLogoImg
              src="https://res.cloudinary.com/ag-millworks/image/upload/AGlogoBlack.png"
              alt=""
            />
          </StyledToolbar>
        </StyledAppBar>

        <StyledNav>
          <Hidden lgUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xlDown implementation="css">
            <Drawer variant="permanent" open>
              {drawer}
            </Drawer>
          </Hidden>
        </StyledNav>

        <TabPanel value={value} index={0}>
          <StyledDivColStart>
            <StyledTypography variant="h4">Account Settings</StyledTypography>

            <FixedMargin
              setSuccess={setSuccess}
              err={err}
              setErr={setErr}
              margin={fixedMargin}
              setMargin={setFixedMargin}
              text="Margin"
              handleClick={handleClickRemoveMargin}
              otsm={false}
            />

            <FixedMargin
              setSuccess={setSuccess}
              err={err}
              setErr={setErr}
              margin={markup}
              setMargin={setMarkup}
              text="Markup"
              handleClick={handleClickRemoveMarkup}
              otsm={false}
            />

            {(fixedMargin || markup) && (
              <OTSM
                setSuccess={setSuccess}
                otsm={otsm}
                setOtsm={setOtsm}
                otsmDefault={otsmDefault}
                setOtsmDefault={setOtsmDefault}
              />
            )}

            <StyledDivColCenter>
              <Button
                style={{ marginBottom: 20 }}
                onClick={handleClick}
                variant="contained"
                size="small"
                color="primary"
                disabled={err ? true : false}
              >
                Apply Changes
              </Button>

              {success && (
                <Alert
                  severity={
                    success && success.includes("Required")
                      ? "error"
                      : "success"
                  }
                >
                  {success}
                </Alert>
              )}
            </StyledDivColCenter>
          </StyledDivColStart>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <StyledDivColCenterPad>
            <StyledTypography2 variant="h4">Account Settings</StyledTypography2>

            <Typography>
              *Upload Logo to display on Dealer Zone and Customer PDFs*
            </Typography>
            <Typography>*Optimal Logo Size: 300 X 160 pixels*</Typography>

            {!isFetching && <UploadWidget data={data} createAcct={false} />}
          </StyledDivColCenterPad>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <DeliveryMarkup
            deliveryMarkup={deliveryMarkup}
            changeDeliveryMarkup={changeDeliveryMarkup}
          />
        </TabPanel>
      </StyledDivRoot>

      <Copyright position="right" />
    </StyledFlexDiv>
  );
};

export default Settings;
