import round from "./round";
import priceWithZeros from "./priceWithZeros";
import formatIntWithCommas from "./formatIntWithCommas";
import { AddonObj } from "./quoteCartStorage";

function getBasePrice(
  itemizedCosts: AddonObj[],
  unitPrice: string,
  qty: number,
  margin?: number,
  withMarkup?: boolean,
) {
  const addonsTotal = itemizedCosts.reduce(
    (previousValue, currentValue) => previousValue + currentValue.price,
    0,
  );

  const totalUnitPrice = Number(unitPrice.replace(",", ""));

  var basePrice = round(totalUnitPrice / qty - addonsTotal);

  if (margin)
    basePrice = !withMarkup
      ? round(basePrice / margin)
      : round(basePrice * (1 - margin + 1));

  return formatIntWithCommas(priceWithZeros(basePrice));
}

export default getBasePrice;
