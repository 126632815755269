export function sliderHeadSillKey(
  sliderPocket: string,
  exactConfig: string,
  screenType: string,
  screen?: string,
  slidingScreen?: string,
  sillStyle?: string,
  handleStyle?: string,
  material?: string,
) {
  var sliderKey;

  if (sliderPocket === "With Pocket") {
    sliderKey = `${material === "All Wood" ? "Wood_" : ""}P${exactConfig
      .split("-")[0]
      .replace("A", "X")
      .replace("P", "")}${
      sillStyle === "Stainless Steel Recessed *" ? "Track" : ""
    }${screen && screenType?.includes("Centor") ? "CentorScr" : screen ? "Scr" : ""}_HS`;
  } else if (exactConfig.includes("Primary")) {
    sliderKey = `${material === "All Wood" ? "Wood_" : ""}${
      exactConfig.split(" ")[0]
    }${sillStyle === "Stainless Steel Recessed *" ? "Track" : ""}${
      screen ? "Scr" : ""
    }_HS`;
  } else {
    const slidingScrConfigs = ["XO", "OX", "OA-XO", "OX-AO"];

    sliderKey =
      slidingScrConfigs.includes(exactConfig) && !screen && slidingScreen
        ? `${material === "All Wood" ? "Wood_" : ""}O${exactConfig
            .split("-")[0]
            .replace("A", "X")
            .replace("O", "")}SlidingScr_HS`
        : `${material === "All Wood" ? "Wood_" : ""}O${exactConfig
            .split("-")[0]
            .replace("A", "X")
            .replace("O", "")}${
            sillStyle === "Stainless Steel Recessed *" ? "Track" : ""
          }${
            screen && screenType?.includes("Centor")
              ? "Centor"
              : screen && handleStyle === "One Piece Truth (Flush Mount)"
                ? "Truth"
                : screen && handleStyle !== "One Piece Truth (Flush Mount)"
                  ? "Hoppe"
                  : ""
          }${screen ? "Scr" : ""}_HS`;
  }

  return sliderHeadSillDrawings[
    sliderKey as keyof typeof sliderHeadSillDrawings
  ];
}

export function sliderJambLegsKey(
  material: string,
  exactConfig: string,
  sliderPocket: string,
  screenType: string,
  screen: string,
  slidingScreen: string,
  screenLayout: string,
  screenPocket: string,
  handleStyle: string,
) {
  var sliderKey;

  if (exactConfig.includes("Primary")) {
    sliderKey = !screen
      ? `${
          material === "All Wood" ? "Wood_" : ""
        }${exactConfig.replaceAll(" ", "")}_JL`
      : screen.includes("Double") && screenPocket
        ? `${
            material === "All Wood" ? "Wood_" : ""
          }${exactConfig.replaceAll(" ", "")}${
            screenPocket.includes("Double")
              ? "DoublePocket"
              : screenLayout.includes("Right")
                ? "RightPocket"
                : screenLayout.includes("Left")
                  ? "LeftPocket"
                  : ""
          }_JL`
        : !screen.includes("Double") && screenPocket && screenLayout
          ? `${
              material === "All Wood" ? "Wood_" : ""
            }${exactConfig.replaceAll(" ", "")}${
              screenLayout.includes("Left") ? "LeftPocket" : "RightPocket"
            }_JL`
          : "";
  } else if (sliderPocket === "With Pocket") {
    const scr = !screen
      ? ""
      : screenType?.includes("Centor") &&
          (screen.includes("Double") || screen.includes("Combo"))
        ? "CentorDoubleScr"
        : screenType?.includes("Centor") && screenLayout.includes("Left")
          ? "CentorLeftScr"
          : screenType?.includes("Centor") && screenLayout.includes("Right")
            ? "CentorRightScr"
            : exactConfig.includes("-")
              ? "DoublePocketscr"
              : screen.includes("Double") &&
                  screenPocket &&
                  screenPocket.includes("Double")
                ? "DoublePocketscr"
                : "scr";

    const materialLabel = material === "All Wood" ? "Wood_" : "";

    sliderKey = `${materialLabel}${exactConfig.replace("-", "_")}${scr}_JL`;
  } else {
    const slidingScrConfigs = ["XO", "OX", "OA-XO", "OX-AO"];

    sliderKey =
      slidingScrConfigs.includes(exactConfig) && slidingScreen
        ? `${
            material === "All Wood" ? "Wood_" : ""
          }${exactConfig.replace("-", "_")}${
            handleStyle === "One Piece Truth (Flush Mount)" ? "truth" : "hoppe"
          }SlidingScr_JL`
        : `${
            material === "All Wood" ? "Wood_" : ""
          }${exactConfig.replace("-", "_")}${
            handleStyle === "One Piece Truth (Flush Mount)" ? "truth" : "hoppe"
          }${
            screen &&
            screenType?.includes("Centor") &&
            !screen.includes("Double") &&
            !screen.includes("Combo") &&
            screenLayout.includes("Left")
              ? "CentorLeft"
              : screen &&
                  screenType?.includes("Centor") &&
                  !screen.includes("Double") &&
                  !screen.includes("Combo") &&
                  screenLayout.includes("Right")
                ? "CentorRight"
                : screen &&
                    screenType?.includes("Centor") &&
                    (screen.includes("Double") || screen.includes("Combo"))
                  ? "CentorDouble"
                  : screen &&
                      screenPocket &&
                      screen.includes("Double") &&
                      screenPocket.includes("Double")
                    ? "DoublePocket"
                    : screen &&
                        screen.includes("Double") &&
                        screenPocket &&
                        screenPocket.includes("Left")
                      ? "LeftPocket"
                      : screen &&
                          screen.includes("Double") &&
                          screenPocket &&
                          screenPocket.includes("Right")
                        ? "RightPocket"
                        : screen &&
                            !screen.includes("Double") &&
                            screenPocket &&
                            screenLayout.includes("Left")
                          ? "LeftPocket"
                          : screen &&
                              !screen.includes("Double") &&
                              screenPocket &&
                              screenLayout.includes("Right")
                            ? "RightPocket"
                            : ""
          }${screen ? "Scr" : ""}_JL`;
  }

  return sliderJambLegDrawings[sliderKey as keyof typeof sliderJambLegDrawings];
}

const CLOUDINARY_URL =
  "https://res.cloudinary.com/ag-millworks/image/upload/pdfDrawings/sectionView/sliders";

function headSillOXURL(path: string) {
  const URL = `${CLOUDINARY_URL}/headSill/nonpocketing`;
  return `${URL}/${path}.jpg`;
}

function headSillPXURL(path: string) {
  const URL = `${CLOUDINARY_URL}/headSill/pocketing`;
  return `${URL}/${path}.jpg`;
}

function jambLegOXURL(path: string) {
  const URL = `${CLOUDINARY_URL}/jambLeg/nonpocketing`;
  return `${URL}/${path}.jpg`;
}

function jambLegPXURL(path: string) {
  const URL = `${CLOUDINARY_URL}/jambLeg/pocketing`;
  return `${URL}/${path}.jpg`;
}

export const sliderHeadSillDrawings = {
  OXSlidingScr_HS: headSillOXURL("OX-XO_SLIDING_SCR_2"),
  OX_HS: headSillOXURL("OX-XO_SILL"),
  OXCentorScr_HS: headSillOXURL("OX-XO_SILL_CENTOR"),
  OXTrackCentorScr_HS: headSillOXURL("OX_XO_TRACKS_CENTOR"),
  OXXCentorScr_HS: headSillOXURL("OXX-XXO_SILL_CENTOR"),
  OXXTrackCentorScr_HS: headSillOXURL("OXX-XXO_TRACKS_CENTOR"),
  OXXXCentorScr_HS: headSillOXURL("OXXX-XXXO_SILL_CENTOR"),
  OXXXTrackCentorScr_HS: headSillOXURL("OXXX-XXXO_TRACKS_CENTOR"),
  OXXXXCentorScr_HS: headSillOXURL("OXXXX-XXXXO_SILL_CENTOR"),
  OXXXXTrackCentorScr_HS: headSillOXURL("OXXXX-XXXXO_TRACKS_CENTOR"),
  OXHoppeScr_HS: headSillOXURL("OX-XO_SILL_HOPPE_SCREEN"),
  OXTruthScr_HS: headSillOXURL("OX-XO_SILL_TRUTH_SCREEN"),
  OXTrack_HS: headSillOXURL("OX-XO_TRACKS"),
  OXTrackHoppeScr_HS: headSillOXURL("OX-XO_TRACKS_HOPPE_SCREEN"),
  OXTrackTruthScr_HS: headSillOXURL("OX-XO_TRACKS_TRUTH_SCREEN"),
  OXXTrackTruthScr_HS: headSillOXURL("OXX-XXO_TRACKS_TRUTH_SCREEN"),
  OXXTrackHoppeScr_HS: headSillOXURL("OXX-XXO_TRACKS_HOPPE_SCREEN"),
  OXXTrack_HS: headSillOXURL("OXX-XXO_TRACKS"),
  OXXTruthScr_HS: headSillOXURL("OXX-XXO_SILL_TRUTH_SCREEN"),
  OXXHoppeScr_HS: headSillOXURL("OXX-XXO_SILL_HOPPE_SCREEN"),
  OXX_HS: headSillOXURL("OXX-XXO_SILL"),
  OXXXTrackTruthScr_HS: headSillOXURL("OXXX-XXXO_TRUTH_TRACKS_SCREEN"),
  OXXXTruthScr_HS: headSillOXURL("OXXX-XXXO_SILL_TRUTH_SCREEN"),
  OXXXHoppeScr_HS: headSillOXURL("OXXX-XXXO_SILL_HOPPE_SCREEN"),
  OXXX_HS: headSillOXURL("OXXX-XXXO_SILL"),
  OXXXTrackHoppeScr_HS: headSillOXURL("OXXX-XXXO_HOPPE_TRACKS_SCREEN"),
  OXXXTrack_HS: headSillOXURL("OXXX-XXXO_TRACKS"),
  OXXXXTruthScr_HS: headSillOXURL("OXXXX-XXXXO_TRUTH_SCREEN"),
  OXXXXTrackTruthScr_HS: headSillOXURL("OXXXX-XXXXO_TRACKS_TRUTH_SCREEN"),
  OXXXXTrackHoppeScr_HS: headSillOXURL("OXXXX-XXXXO_TRACKS_HOPPE_SCREEN"),
  OXXXXTrack_HS: headSillOXURL("OXXXX-XXXXO_TRACKS"),
  OXXXXHoppeScr_HS: headSillOXURL("OXXXX-XXXXO_SILL_HOPPE_SCREEN"),
  OXXXX_HS: headSillOXURL("OXXXX-XXXXO_SILL"),
  Wood_OXTruthScr_HS: headSillOXURL("WOOD_OX_XO_OX-XO_SILL_TRUTH_SCREEN"),
  Wood_OXHoppeScr_HS: headSillOXURL("WOOD_OX_XO_OX-XO_HOPPE_SCREEN"),
  Wood_OXCentorScr_HS: headSillOXURL("WOOD_OX-XO_SILL_CENTOR"),
  Wood_OXTrackCentorScr_HS: headSillOXURL("WOOD_OX-XO_TRACKS_CENTOR"),
  Wood_OXXCentorScr_HS: headSillOXURL("WOOD_OXX-XXO_SILL_CENTOR"),
  Wood_OXXTrackCentorScr_HS: headSillOXURL("WOOD_OXX-XXO_TRACKS_CENTOR"),
  Wood_OXXXCentorScr_HS: headSillOXURL("WOOD_OXXX-XXXO_SILL_CENTOR"),
  Wood_OXXXTrackCentorScr_HS: headSillOXURL("WOOD_OXXX-XXXO_TRACKS_CENTOR"),
  Wood_OXXXXCentorScr_HS: headSillOXURL("WOOD_OXXXX-XXXXO_SILL_CENTOR"),
  Wood_OXXXXTrackCentorScr_HS: headSillOXURL("WOOD_OXXXX-XXXXO_TRACKS_CENTOR"),
  Wood_OX_HS: headSillOXURL("WOOD_OX_XO_OX-XO"),
  Wood_OXSlidingScr_HS: headSillOXURL("WOOD_OX_XO_OX-XO_SLIDING_SCR"),
  Wood_OXTrackTruthScr_HS: headSillOXURL(
    "WOOD_OX_XO_OX-XO_TRACKS_TRUTH_SCREEN",
  ),
  Wood_OXTrackHoppeScr: headSillOXURL("WOOD_OX_XO_OX-XO_TRACKS_HOPPE_SCREEN"),
  Wood_OXTrack_HS: headSillOXURL("WOOD_OX_XO_OX-XO_TRACKS"),
  Wood_OXXTruthScr_HS: headSillOXURL("WOOD_OXX_XXO_OXX-XXO_TRUTH_SCREEN"),
  Wood_OXXHoppeScr_HS: headSillOXURL("WOOD_OXX_XXO_OXX-XXO_HOPPE_SCREEN"),
  Wood_OXX_HS: headSillOXURL("WOOD_OXX_XXO_OXX-XXO"),
  Wood_OXXTrackTruthScr_HS: headSillOXURL(
    "WOOD_OXX_XXO_OXX-XXO_TRACKS_TRUTH_SCREEN",
  ),
  Wood_OXXTrackHoppeScr_HS: headSillOXURL(
    "WOOD_OXX_XXO_OXX-XXO_TRACKS_HOPPE_SCREEN",
  ),
  Wood_OXXTrack_HS: headSillOXURL("WOOD_OXX_XXO_OXX-XXO_TRACKS"),
  Wood_OXXXHoppeScr_HS: headSillOXURL("WOOD_OXXX_XXXO_OXXX-XXXO_HOPPE_SCREEN"),
  Wood_OXXX_HS: headSillOXURL("WOOD_OXXX_XXXO_OXXX-XXXO"),
  Wood_OXXXTrackHoppeScr_HS: headSillOXURL(
    "WOOD_OXXX_XXXO_OXXX-XXXO_HOPPE_TRACKS_SCREEN",
  ),
  Wood_OXXXTrackTruthScr_HS: headSillOXURL(
    "WOOD_OXXX_XXXO_OXXX-XXXO_TRUTH_TRACKS_SCREEN",
  ),
  Wood_OXXXTrack_HS: headSillOXURL("WOOD_OXXX_XXXO_OXXX-XXXO_TRACKS"),
  Wood_OXXXTruthScr_HS: headSillOXURL("WOOD_OXXX_XXXO_OXXX-XXXO_TRUTH_SCREEN"),
  Wood_OXXXXTrackTruthScr_HS: headSillOXURL(
    "WOOD_OXXXX_XXXXO_OXXXX-XXXXO_TRACKS_TRUTH_SCREEN",
  ),
  Wood_OXXXXTrackHoppeScr_HS: headSillOXURL(
    "WOOD_OXXXX_XXXXO_OXXXX-XXXXO_TRACKS_HOPPE_SCREEN",
  ),
  Wood_OXXXXTrack_HS: headSillOXURL("WOOD_OXXXX_XXXXO_OXXXX-XXXXO_TRACKS"),
  Wood_OXXXXTruthScr_HS: headSillOXURL(
    "WOOD_OXXXX_XXXXO_OXXXX-XXXXO_TRUTH_SCREEN",
  ),
  Wood_OXXXXHoppeScr_HS: headSillOXURL(
    "WOOD_OXXXX_XXXXO_OXXXX-XXXXO_HOPPE_SCREEN",
  ),
  Wood_OXXXX_HS: headSillOXURL("WOOD_OXXXX_XXXXO_OXXXX-XXXXO"),

  XXTrackScr_HS: headSillOXURL("XX_TRACKS_SCREEN"),
  XXTrack_HS: headSillOXURL("XX_TRACKS"),
  XXScr_HS: headSillOXURL("XX_SCREEN"),
  XX_HS: headSillOXURL("XX"),
  XXX_HS: headSillOXURL("XXX"),
  XXXXTrackScr_HS: headSillOXURL("XXXX_TRACKS_SCREEN"),
  XXXXTrack_HS: headSillOXURL("XXXX_TRACKS"),
  XXXXScr_HS: headSillOXURL("XXXX_SCREEN"),
  XXXX_HS: headSillOXURL("XXXX"),
  XXXScr_HS: headSillOXURL("XXX_SCREEN"),
  XXXTrackScr_HS: headSillOXURL("XXX_TRACKS_SCREEN"),
  XXXTrack_HS: headSillOXURL("XXX_TRACKS"),
  Wood_XXXXTrack_HS: headSillOXURL("WOOD_XXXX_TRACKS"),
  Wood_XXXXScr_HS: headSillOXURL("WOOD_XXXX_SCREEN"),
  Wood_XXXX_HS: headSillOXURL("WOOD_XXXX"),
  Wood_XXTrackScr_HS: headSillOXURL("WOOD_XX_TRACKS_SCREEN"),
  Wood_XXTrack_HS: headSillOXURL("WOOD_XX_TRACKS"),
  Wood_XXScr_HS: headSillOXURL("WOOD_XX_SCREEN"),
  Wood_XX_HS: headSillOXURL("WOOD_XX"),
  Wood_XXXXTrackScr_HS: headSillOXURL("WOOD_XXXX_TRACKS_SCREEN"),
  Wood_XXXTrackScr_HS: headSillOXURL("WOOD_XXX_TRACKS_SCREEN"),
  Wood_XXXTrack_HS: headSillOXURL("WOOD_XXX_TRACKS"),
  Wood_XXXScr_HS: headSillOXURL("WOOD_XXX_SCREEN"),
  Wood_XXX_HS: headSillOXURL("WOOD_XXX"),

  XXCentorScr_HS: headSillPXURL("XX_CENTOR"),
  XXTrackCentorScr_HS: headSillPXURL("XX_TRACKS_CENTOR"),
  XXXCentorScr_HS: headSillPXURL("XXX_CENTOR"),
  XXXTrackCentorScr_HS: headSillPXURL("XXX_TRACKS_CENTOR"),
  XXXXCentorScr_HS: headSillPXURL("XXXX_CENTOR"),
  XXXXTrackCentorScr_HS: headSillPXURL("XXXX_TRACKS_CENTOR"),

  PX_HS: headSillPXURL("PX-XP"),
  PXCentorScr_HS: headSillPXURL("PX-XP_CENTOR"),
  PXTrackCentorScr_HS: headSillPXURL("PX-XP_TRACKS_CENTOR"),
  PXXCentorScr_HS: headSillPXURL("PXX-XXP_CENTOR"),
  PXXTrackCentorScr_HS: headSillPXURL("PXX-XXP_TRACKS_CENTOR"),
  PXXXCentorScr_HS: headSillPXURL("PXXX-XXXP_CENTOR"),
  PXXXTrackCentorScr_HS: headSillPXURL("PXXX-XXXP_TRACKS_CENTOR"),
  PXXXXCentorScr_HS: headSillPXURL("PXXXX-XXXXP_CENTOR"),
  PXXXXTrackCentorScr_HS: headSillPXURL("PXXXX-XXXXP_TRACKS_CENTOR"),
  PXXXXXCentorScr_HS: headSillPXURL("PXXXXX-XXXXXP_CENTOR"),
  PXXXXXTrackCentorScr_HS: headSillPXURL("PXXXXX-XXXXXP_TRACKS_CENTOR"),
  PXScr_HS: headSillPXURL("PX-XP_SCREEN"),
  PXTrack_HS: headSillPXURL("PX-XP_TRACK"),
  PXTrackScr_HS: headSillPXURL("PX-XP_TRACK_SCREEN"),
  PXXXXTrack_HS: headSillPXURL("PXXXX-XXXXP_TRACKS"),
  PXXXXTrackScr_HS: headSillPXURL("PXXXX-XXXXP_TRACKS_SCREEN"),
  PXXXXScr_HS: headSillPXURL("PXXXX-XXXXP_SCREEN"),
  PXXXX_HS: headSillPXURL("PXXXX-XXXXP"),
  PXXX_HS: headSillPXURL("PXXX-XXXP"),
  PXXXScr_HS: headSillPXURL("PXXX-XXXP_SCREEN"),
  PXXXTrack_HS: headSillPXURL("PXXX-XXXP_TRACKS"),
  PXXXTrackScr_HS: headSillPXURL("PXXX-XXXP_TRACKS_SCREEN"),
  PXXTrackScr_HS: headSillPXURL("PXX-XXP_TRACKS_SCREEN"),
  PXXTrack_HS: headSillPXURL("PXX-XXP_TRACKS"),
  PXXScr_HS: headSillPXURL("PXX-XXP_SCREEN"),
  PXX_HS: headSillPXURL("PXX-XXP"),

  Wood_XXCentorScr_HS: headSillPXURL("WOOD_XX_CENTOR"),
  Wood_XXTrackCentorScr_HS: headSillPXURL("WOOD_XX_TRACKS_CENTOR"),
  Wood_XXXCentorScr_HS: headSillPXURL("WOOD_XXX_CENTOR"),
  Wood_XXXTrackCentorScr_HS: headSillPXURL("WOOD_XXX_TRACKS_CENTOR"),
  Wood_XXXXCentorScr_HS: headSillPXURL("WOOD_XXXX_CENTOR"),
  Wood_XXXXTrackCentorScr_HS: headSillPXURL("WOOD_XXXX_TRACKS_CENTOR"),

  Wood_PXCentorScr_HS: headSillPXURL("WOOD_PX-XP_CENTOR"),
  Wood_PXTrackCentorScr_HS: headSillPXURL("WOOD_PX-XP_TRACKS_CENTOR"),
  Wood_PXXCentorScr_HS: headSillPXURL("WOOD_PXX-XXP_CENTOR"),
  Wood_PXXTrackCentorScr_HS: headSillPXURL("WOOD_PXX-XXP_TRACKS_CENTOR"),
  Wood_PXXXCentorScr_HS: headSillPXURL("WOOD_PXXX-XXXP_CENTOR"),
  Wood_PXXXTrackCentorScr_HS: headSillPXURL("WOOD_PXXX-XXXP_TRACKS_CENTOR"),
  Wood_PXXXXCentorScr_HS: headSillPXURL("WOOD_PXXXX-XXXXP_CENTOR"),
  Wood_PXXXXTrackCentorScr_HS: headSillPXURL("WOOD_PXXXX-XXXXP_TRACKS_CENTOR"),
  Wood_PXXXXXCentorScr_HS: headSillPXURL("WOOD_PXXXXX-XXXXXP_CENTOR"),
  Wood_PXXXXXTrackCentorScr_HS: headSillPXURL(
    "WOOD_PXXXXX-XXXXXP_TRACKS_CENTOR",
  ),
  Wood_PXXXXTrackScr_HS: headSillPXURL("WOOD_PXXXX-XXXXP_TRACKS_SCREEN"),
  Wood_PXXXXTrack_HS: headSillPXURL("WOOD_PXXXX-XXXXP_TRACKS"),
  Wood_PXXXXScr_HS: headSillPXURL("WOOD_PXXXX-XXXXP_SILL_SCREEN"),
  Wood_PXXXX_HS: headSillPXURL("WOOD_PXXXX-XXXXP_SILL"),
  Wood_PXXTrackScr_HS: headSillPXURL("WOOD_PXX-XXP_TRACKS_SCREEN"),
  Wood_PXXTrack_HS: headSillPXURL("WOOD_PXX-XXP_TRACKS"),
  Wood_PXXScr_HS: headSillPXURL("WOOD_PXX-XXP_SILL_SCREEN"),
  Wood_PXX_HS: headSillPXURL("WOOD_PXX-XXP_SILL"),
  Wood_PXTrackScr_HS: headSillPXURL("WOOD_PX_XP_PX-XP_TRACK_SCREEN"),
  Wood_PXScr_HS: headSillPXURL("WOOD_PX_XP_PX-XP_SILL_SCREEN"),
  Wood_PX_HS: headSillPXURL("WOOD_PX_XP_PX-XP_SILL"),
  Wood_PXTrack_HS: headSillPXURL("WOOD_PX_XP_PX-XP_TRACK"),
  Wood_PXXXTrackScr_HS: headSillPXURL("WOOD_PXXX_XXXP_PXXX-XXXP_TRACKS_SCREEN"),
  Wood_PXXXTrack_HS: headSillPXURL("WOOD_PXXX_XXXP_PXXX-XXXP_TRACKS"),
  Wood_PXXXScr_HS: headSillPXURL("WOOD_PXXX_XXXP_PXXX-XXXP_SCREEN"),
  Wood_PXXX_HS: headSillPXURL("WOOD_PXXX_XXXP_PXXX-XXXP"),
};

const sliderJambLegDrawings = {
  XOtruthCentorDoubleScr_JL: jambLegOXURL("XO_TRUTH_DOUBLE_CENTOR"),
  XOtruthCentorLeftScr_JL: jambLegOXURL("XO_TRUTH_LEFT_CENTOR"),
  XOtruthCentorRightScr_JL: jambLegOXURL("XO_TRUTH_RIGHT_CENTOR"),
  XOhoppeCentorDoubleScr_JL: jambLegOXURL("XO_HOPPE_DOUBLE_CENTOR"),
  XOhoppeCentorLeftScr_JL: jambLegOXURL("XO_HOPPE_LEFT_CENTOR"),
  XOhoppeCentorRightScr_JL: jambLegOXURL("XO_HOPPE_RIGHT_CENTOR"),
  OXtruthCentorDoubleScr_JL: jambLegOXURL("OX_TRUTH_DOUBLE_CENTOR"),
  OXtruthCentorLeftScr_JL: jambLegOXURL("OX_TRUTH_LEFT_CENTOR"),
  OXtruthCentorRightScr_JL: jambLegOXURL("OX_TRUTH_RIGHT_CENTOR"),
  OXhoppeCentorDoubleScr_JL: jambLegOXURL("OX_HOPPE_DOUBLE_CENTOR"),
  OXhoppeCentorLeftScr_JL: jambLegOXURL("OX_HOPPE_LEFT_CENTOR"),
  OXhoppeCentorRightScr_JL: jambLegOXURL("OX_HOPPE_RIGHT_CENTOR"),
  OX_AOtruthCentorDoubleScr_JL: jambLegOXURL("OX-AO_TRUTH_DOUBLE_CENTOR"),
  OX_AOtruthCentorLeftScr_JL: jambLegOXURL("OX-AO_TRUTH_LEFT_CENTOR"),
  OX_AOtruthCentorRightScr_JL: jambLegOXURL("OX-AO_TRUTH_RIGHT_CENTOR"),
  OX_AOhoppeCentorDoubleScr_JL: jambLegOXURL("OX-AO_HOPPE_DOUBLE_CENTOR"),
  OX_AOhoppeCentorLeftScr_JL: jambLegOXURL("OX-AO_HOPPE_LEFT_CENTOR"),
  OX_AOhoppeCentorRightScr_JL: jambLegOXURL("OX-AO_HOPPE_RIGHT_CENTOR"),
  OA_XOtruthCentorDoubleScr_JL: jambLegOXURL("OA-XO_TRUTH_DOUBLE_CENTOR"),
  OA_XOtruthCentorLeftScr_JL: jambLegOXURL("OA-XO_TRUTH_LEFT_CENTOR"),
  OA_XOtruthCentorRightScr_JL: jambLegOXURL("OA-XO_TRUTH_RIGHT_CENTOR"),
  OA_XOhoppeCentorDoubleScr_JL: jambLegOXURL("OA-XO_HOPPE_DOUBLE_CENTOR"),
  OA_XOhoppeCentorLeftScr_JL: jambLegOXURL("OA-XO_HOPPE_LEFT_CENTOR"),
  OA_XOhoppeCentorRightScr_JL: jambLegOXURL("OA-XO_HOPPE_RIGHT_CENTOR"),

  XXOtruthCentorDoubleScr_JL: jambLegOXURL("XXO_TRUTH_DOUBLE_CENTOR"),
  XXOtruthCentorLeftScr_JL: jambLegOXURL("XXO_TRUTH_LEFT_CENTOR"),
  XXOtruthCentorRightScr_JL: jambLegOXURL("XXO_TRUTH_RIGHT_CENTOR"),
  XXOhoppeCentorDoubleScr_JL: jambLegOXURL("XXO_HOPPE_DOUBLE_CENTOR"),
  XXOhoppeCentorLeftScr_JL: jambLegOXURL("XXO_HOPPE_LEFT_CENTOR"),
  XXOhoppeCentorRightScr_JL: jambLegOXURL("XXO_HOPPE_RIGHT_CENTOR"),
  OXXtruthCentorDoubleScr_JL: jambLegOXURL("OXX_TRUTH_DOUBLE_CENTOR"),
  OXXtruthCentorLeftScr_JL: jambLegOXURL("OXX_TRUTH_LEFT_CENTOR"),
  OXXtruthCentorRightScr_JL: jambLegOXURL("OXX_TRUTH_RIGHT_CENTOR"),
  OXXhoppeCentorDoubleScr_JL: jambLegOXURL("OXX_HOPPE_DOUBLE_CENTOR"),
  OXXhoppeCentorLeftScr_JL: jambLegOXURL("OXX_HOPPE_LEFT_CENTOR"),
  OXXhoppeCentorRightScr_JL: jambLegOXURL("OXX_HOPPE_RIGHT_CENTOR"),
  OXX_AXOtruthCentorDoubleScr_JL: jambLegOXURL("OXX-AXO_TRUTH_DOUBLE_CENTOR"),
  OXX_AXOtruthCentorLeftScr_JL: jambLegOXURL("OXX-AXO_TRUTH_LEFT_CENTOR"),
  OXX_AXOtruthCentorRightScr_JL: jambLegOXURL("OXX-AXO_TRUTH_RIGHT_CENTOR"),
  OXX_AXOhoppeCentorDoubleScr_JL: jambLegOXURL("OXX-AXO_HOPPE_DOUBLE_CENTOR"),
  OXX_AXOhoppeCentorLeftScr_JL: jambLegOXURL("OXX-AXO_HOPPE_LEFT_CENTOR"),
  OXX_AXOhoppeCentorRightScr_JL: jambLegOXURL("OXX-AXO_HOPPE_RIGHT_CENTOR"),
  OXA_XXOtruthCentorDoubleScr_JL: jambLegOXURL("OXA-XXO_TRUTH_DOUBLE_CENTOR"),
  OXA_XXOtruthCentorLeftScr_JL: jambLegOXURL("OXA-XXO_TRUTH_LEFT_CENTOR"),
  OXA_XXOtruthCentorRightScr_JL: jambLegOXURL("OXA-XXO_TRUTH_RIGHT_CENTOR"),
  OXA_XXOhoppeCentorDoubleScr_JL: jambLegOXURL("OXA-XXO_HOPPE_DOUBLE_CENTOR"),
  OXA_XXOhoppeCentorLeftScr_JL: jambLegOXURL("OXA-XXO_HOPPE_LEFT_CENTOR"),
  OXA_XXOhoppeCentorRightScr_JL: jambLegOXURL("OXA-XXO_HOPPE_RIGHT_CENTOR"),

  XXXOtruthCentorDoubleScr_JL: jambLegOXURL("XXO_TRUTH_DOUBLE_CENTOR"),
  XXXOtruthCentorLeftScr_JL: jambLegOXURL("XXXO_TRUTH_LEFT_CENTOR"),
  XXXOtruthCentorRightScr_JL: jambLegOXURL("XXXO_TRUTH_RIGHT_CENTOR"),
  XXXOhoppeCentorDoubleScr_JL: jambLegOXURL("XXXO_HOPPE_DOUBLE_CENTOR"),
  XXXOhoppeCentorLeftScr_JL: jambLegOXURL("XXXO_HOPPE_LEFT_CENTOR"),
  XXXOhoppeCentorRightScr_JL: jambLegOXURL("XXXO_HOPPE_RIGHT_CENTOR"),
  OXXXtruthCentorDoubleScr_JL: jambLegOXURL("OXXX_TRUTH_DOUBLE_CENTOR"),
  OXXXtruthCentorLeftScr_JL: jambLegOXURL("OXXX_TRUTH_LEFT_CENTOR"),
  OXXXtruthCentorRightScr_JL: jambLegOXURL("OXXX_TRUTH_RIGHT_CENTOR"),
  OXXXhoppeCentorDoubleScr_JL: jambLegOXURL("OXXX_HOPPE_DOUBLE_CENTOR"),
  OXXXhoppeCentorLeftScr_JL: jambLegOXURL("OXXX_HOPPE_LEFT_CENTOR"),
  OXXXhoppeCentorRightScr_JL: jambLegOXURL("OXXX_HOPPE_RIGHT_CENTOR"),
  OXXX_AXXOtruthCentorDoubleScr_JL: jambLegOXURL(
    "OXXX-AXXO_TRUTH_DOUBLE_CENTOR",
  ),
  OXXX_AXXOtruthCentorLeftScr_JL: jambLegOXURL("OXXX-AXXO_TRUTH_LEFT_CENTOR"),
  OXXX_AXXOtruthCentorRightScr_JL: jambLegOXURL("OXXX-AXXO_TRUTH_RIGHT_CENTOR"),
  OXXX_AXXOhoppeCentorDoubleScr_JL: jambLegOXURL(
    "OXXX-AXXO_HOPPE_DOUBLE_CENTOR",
  ),
  OXXX_AXXOhoppeCentorLeftScr_JL: jambLegOXURL("OXXX-AXXO_HOPPE_LEFT_CENTOR"),
  OXXX_AXXOhoppeCentorRightScr_JL: jambLegOXURL("OXXX-AXXO_HOPPE_RIGHT_CENTOR"),
  OXXA_XXXOtruthCentorDoubleScr_JL: jambLegOXURL(
    "OXXA-XXXO_TRUTH_DOUBLE_CENTOR",
  ),
  OXXA_XXXOtruthCentorLeftScr_JL: jambLegOXURL("OXXA-XXXO_TRUTH_LEFT_CENTOR"),
  OXXA_XXXOtruthCentorRightScr_JL: jambLegOXURL("OXXA-XXXO_TRUTH_RIGHT_CENTOR"),
  OXXA_XXXOhoppeCentorDoubleScr_JL: jambLegOXURL(
    "OXXA-XXXO_HOPPE_DOUBLE_CENTOR",
  ),
  OXXA_XXXOhoppeCentorLeftScr_JL: jambLegOXURL("OXXA-XXXO_HOPPE_LEFT_CENTOR"),
  OXXA_XXXOhoppeCentorRightScr_JL: jambLegOXURL("OXXA-XXXO_HOPPE_RIGHT_CENTOR"),

  XXXXOtruthCentorDoubleScr_JL: jambLegOXURL("XXO_TRUTH_DOUBLE_CENTOR"),
  XXXXOtruthCentorLeftScr_JL: jambLegOXURL("XXXO_TRUTH_LEFT_CENTOR"),
  XXXXOtruthCentorRightScr_JL: jambLegOXURL("XXXO_TRUTH_RIGHT_CENTOR"),
  XXXXOhoppeCentorDoubleScr_JL: jambLegOXURL("XXXO_HOPPE_DOUBLE_CENTOR"),
  XXXXOhoppeCentorLeftScr_JL: jambLegOXURL("XXXO_HOPPE_LEFT_CENTOR"),
  XXXXOhoppeCentorRightScr_JL: jambLegOXURL("XXXO_HOPPE_RIGHT_CENTOR"),
  OXXXXtruthCentorDoubleScr_JL: jambLegOXURL("OXXX_TRUTH_DOUBLE_CENTOR"),
  OXXXXtruthCentorLeftScr_JL: jambLegOXURL("OXXX_TRUTH_LEFT_CENTOR"),
  OXXXXtruthCentorRightScr_JL: jambLegOXURL("OXXX_TRUTH_RIGHT_CENTOR"),
  OXXXXhoppeCentorDoubleScr_JL: jambLegOXURL("OXXX_HOPPE_DOUBLE_CENTOR"),
  OXXXXhoppeCentorLeftScr_JL: jambLegOXURL("OXXX_HOPPE_LEFT_CENTOR"),
  OXXXXhoppeCentorRightScr_JL: jambLegOXURL("OXXX_HOPPE_RIGHT_CENTOR"),
  OXXXX_AXXXOtruthCentorDoubleScr_JL: jambLegOXURL(
    "OXXX-AXXO_TRUTH_DOUBLE_CENTOR",
  ),
  OXXXX_AXXXOtruthCentorLeftScr_JL: jambLegOXURL("OXXX-AXXO_TRUTH_LEFT_CENTOR"),
  OXXXX_AXXXOtruthCentorRightScr_JL: jambLegOXURL(
    "OXXX-AXXO_TRUTH_RIGHT_CENTOR",
  ),
  OXXXX_AXXXOhoppeCentorDoubleScr_JL: jambLegOXURL(
    "OXXX-AXXO_HOPPE_DOUBLE_CENTOR",
  ),
  OXXXX_AXXXOhoppeCentorLeftScr_JL: jambLegOXURL("OXXX-AXXO_HOPPE_LEFT_CENTOR"),
  OXXXX_AXXXOhoppeCentorRightScr_JL: jambLegOXURL(
    "OXXX-AXXO_HOPPE_RIGHT_CENTOR",
  ),
  OXXXA_XXXXOtruthCentorDoubleScr_JL: jambLegOXURL(
    "OXXA-XXXO_TRUTH_DOUBLE_CENTOR",
  ),
  OXXXA_XXXXOtruthCentorLeftScr_JL: jambLegOXURL("OXXA-XXXO_TRUTH_LEFT_CENTOR"),
  OXXXA_XXXXOtruthCentorRightScr_JL: jambLegOXURL(
    "OXXA-XXXO_TRUTH_RIGHT_CENTOR",
  ),
  OXXXA_XXXXOhoppeCentorDoubleScr_JL: jambLegOXURL(
    "OXXA-XXXO_HOPPE_DOUBLE_CENTOR",
  ),
  OXXXA_XXXXOhoppeCentorLeftScr_JL: jambLegOXURL("OXXA-XXXO_HOPPE_LEFT_CENTOR"),
  OXXXA_XXXXOhoppeCentorRightScr_JL: jambLegOXURL(
    "OXXA-XXXO_HOPPE_RIGHT_CENTOR",
  ),

  XOhoppeSlidingScr_JL: jambLegOXURL("XO_HOPPE_SLIDING_SCR"),
  XOtruth_JL: jambLegOXURL("XO_TRUTH"),
  XOtruthScr_JL: jambLegOXURL("XO_TRUTH_SCREEN"),
  XOtruthSlidingScr_JL: jambLegOXURL("XO_TRUTH_SLIDING_SCR"),
  OXtruth_JL: jambLegOXURL("OX_TRUTH"),
  OXtruthScr_JL: jambLegOXURL("OX_TRUTH_SCREEN"),
  OXtruthSlidingScr_JL: jambLegOXURL("OX_TRUTH_SLIDING_SCR"),
  XOhoppe_JL: jambLegOXURL("XO_HOPPE"),
  XOhoppeScr_JL: jambLegOXURL("XO_HOPPE_SCREEN"),
  OX_AOtruthScr_JL: jambLegOXURL("OX-AO_TRUTH_SCREEN"),
  OX_AOtruthSlidingScr_JL: jambLegOXURL("OX-AO_TRUTH_SLIDING_SCR"),
  OXhoppe_JL: jambLegOXURL("OX_HOPPE"),
  OXhoppeScr_JL: jambLegOXURL("OX_HOPPE_SCREEN"),
  OXhoppeSlidingScr_JL: jambLegOXURL("OX_HOPPE_SLIDING_SCR"),
  OA_XOtruthSlidingScr_JL: jambLegOXURL("OA-XO_TRUTH_SLIDING_SCR"),
  OX_AOhoppe_JL: jambLegOXURL("OX-AO_HOPPE"),
  OX_AOhoppeScr_JL: jambLegOXURL("OX-AO_HOPPE_SCREEN"),
  OX_AOhoppeSlidingScr_JL: jambLegOXURL("OX-AO_HOPPE_SLIDING_SCR"),
  OX_AOtruth_JL: jambLegOXURL("OX-AO_TRUTH"),
  OA_XOhoppe_JL: jambLegOXURL("OA-XO_HOPPE"),
  OA_XOhoppeScr_JL: jambLegOXURL("OA-XO_HOPPE_SCREEN"),
  OA_XOhoppeSlidingScr_JL: jambLegOXURL("OA-XO_HOPPE_SLIDING_SCR"),
  OA_XOtruth_JL: jambLegOXURL("OA-XO_TRUTH"),
  OA_XOtruthScr_JL: jambLegOXURL("OA-XO_TRUTH_SCREEN"),
  OXA_XXOhoppe_JL: jambLegOXURL("OXA-XXO_HOPPE"),
  OXA_XXOhoppeScr_JL: jambLegOXURL("OXA-XXO_HOPPE_SCR"),
  OXA_XXOtruth_JL: jambLegOXURL("OXA-XXO_TRUTH"),
  OXA_XXOtruthScr_JL: jambLegOXURL("OXA-XXO_TRUTH_SCR"),
  OXXhoppe_JL: jambLegOXURL("OXX_HOPPE"),
  OXXhoppeScr_JL: jambLegOXURL("OXX_HOPPE_SCR"),
  OXX_AXOtruthScr_JL: jambLegOXURL("OXX-AXO_TRUTH_SCR"),
  OXX_AXOtruth_JL: jambLegOXURL("OXX-AXO_TRUTH"),
  OXX_AXOhoppeScr_JL: jambLegOXURL("OXX-AXO_HOPPE_SCR"),
  OXX_AXOhoppe_JL: jambLegOXURL("OXX-AXO_HOPPE"),
  OXXtruthScr_JL: jambLegOXURL("OXX_TRUTH_SCR"),
  OXXtruth_JL: jambLegOXURL("OXX_TRUTH"),
  XXOtruthScr_JL: jambLegOXURL("XXO_TRUTH_SCR"),
  XXOtruth_JL: jambLegOXURL("XXO_TRUTH"),
  XXOhoppeScr_JL: jambLegOXURL("XXO_HOPPE_SCR"),
  XXOhoppe_JL: jambLegOXURL("XXO_HOPPE"),
  OXXXhoppeScr_JL: jambLegOXURL("OXXX_HOPPE_SCREEN"),
  OXXXhoppe_JL: jambLegOXURL("OXXX_HOPPE"),
  OXXA_XXXOtruthScr_JL: jambLegOXURL("OXXA-XXXO_TRUTH_SCREEN"),
  OXXA_XXXOtruth_JL: jambLegOXURL("OXXA-XXXO_TRUTH"),
  OXXA_XXXOhoppeScr_JL: jambLegOXURL("OXXA-XXXO_HOPPE_SCREEN"),
  OXXA_XXXOhoppe_JL: jambLegOXURL("OXXA-XXXO_HOPPE"),
  OXXX_AXXOtruthScr_JL: jambLegOXURL("OXXX-AXXO_TRUTH_SCREEN"),
  OXXX_AXXOtruth_JL: jambLegOXURL("OXXX-AXXO_TRUTH"),
  OXXX_AXXOhoppeScr_JL: jambLegOXURL("OXXX-AXXO_HOPPE_SCREEN"),
  OXXX_AXXOhoppe_JL: jambLegOXURL("OXXX-AXXO_HOPPE"),
  OXXXtruthScr_JL: jambLegOXURL("OXXX_TRUTH_SCREEN"),
  OXXXtruth_JL: jambLegOXURL("OXXX_TRUTH"),
  XXXOtruthScr_JL: jambLegOXURL("XXXO_TRUTH_SCREEN"),
  XXXOtruth_JL: jambLegOXURL("XXXO_TRUTH"),
  XXXOhoppeScr_JL: jambLegOXURL("XXXO_HOPPE_SCREEN"),
  XXXOhoppe_JL: jambLegOXURL("XXXO_HOPPE"),
  OXXXX_AXXXOhoppeScr_JL: jambLegOXURL("OXXXX-AXXXO_HOPPE_SCREEN"),
  OXXXX_AXXXOhoppe_JL: jambLegOXURL("OXXXX-AXXXO_HOPPE"),
  OXXXA_XXXXOtruthScr_JL: jambLegOXURL("OXXXA-XXXXO_TRUTH_SCREEN"),
  OXXXA_XXXXOtruth_JL: jambLegOXURL("OXXXA-XXXXO_TRUTH"),
  OXXXA_XXXXOhoppeScr_JL: jambLegOXURL("OXXXA-XXXXO_HOPPE_SCREEN"),
  OXXXA_XXXXOhoppe_JL: jambLegOXURL("OXXXA-XXXXO_HOPPE"),
  OXXXXtruthScr_JL: jambLegOXURL("OXXXX_TRUTH_SCREEN"),
  OXXXXtruth_JL: jambLegOXURL("OXXXX_TRUTH"),
  OXXXXhoppeScr_JL: jambLegOXURL("OXXXX_HOPPE_SCREEN"),
  OXXXXhoppe_JL: jambLegOXURL("OXXXX_HOPPE"),
  OXXXX_AXXXOtruthScr_JL: jambLegOXURL("OXXXX-AXXXO_TRUTH_SCREEN"),
  OXXXX_AXXXOtruth_JL: jambLegOXURL("OXXXX-AXXXO_TRUTH"),
  XXXXOtruthScr_JL: jambLegOXURL("XXXXO_TRUTH_SCREEN"),
  XXXXOtruth_JL: jambLegOXURL("XXXXO_TRUTH"),
  XXXXOhoppeScr_JL: jambLegOXURL("XXXXO_HOPPE_SCREEN"),
  XXXXOhoppe_JL: jambLegOXURL("XXXXO_HOPPE"),
  XOhoppeRightPocketScr_JL: jambLegOXURL("XO_RIGHT_PKT_SCR_HOPPE"),
  XOtruthRightPocketScr_JL: jambLegOXURL("XO_RIGHT_PKT_SCR_TRUTH"),
  OXtruthRightPocketScr_JL: jambLegOXURL("OX_RIGHT_PKT_SCR_TRUTH"),
  OX_AOhoppeDoublePocketScr_JL: jambLegOXURL("OX-AO_DOUBLE_PKT_SCR_HOPPE"),
  OX_AOtruthDoublePocketScr_JL: jambLegOXURL("OX-AO_DOUBLE_PKT_SCR_TRUTH"),
  XOtruthLeftPocketScr_JL: jambLegOXURL("XO_LEFT_PKT_SCR_TRUTH"),
  XOhoppeLeftPocketScr_JL: jambLegOXURL("XO_LEFT_PKT_SCR_HOPPE"),
  OA_XOhoppeDoublePocketScr_JL: jambLegOXURL("OA-XO_DOUBLE_PKT_SCR_HOPPE"),
  OA_XOtruthDoublePocketScr_JL: jambLegOXURL("OA-XO_DOUBLE_PKT_SCR_TRUTH"),
  OXhoppeLeftPocketScr_JL: jambLegOXURL("OX_LEFT_PKT_SCR_HOPPE"),
  OXtruthLeftPocketScr_JL: jambLegOXURL("OX_LEFT_PKT_SCR_TRUTH"),
  OXhoppeRightPocketScr_JL: jambLegOXURL("OX_RIGHT_PKT_SCR_HOPPE"),
  OXXtruthLeftPocketScr_JL: jambLegOXURL("OXX_TRUTH_LEFT_PKT"),
  OXXhoppeRightPocketScr_JL: jambLegOXURL("OXX_HOPPE_RIGHT_PKT"),
  OXXhoppeLeftPocketScr_JL: jambLegOXURL("OXX_HOPPE_LEFT_PKT"),
  OXA_XXOtruthDoublePocketScr_JL: jambLegOXURL("OXA-XXO_TRUTH_DOUBLE_PKT"),
  OXA_XXOhoppeDoublePocketScr_JL: jambLegOXURL("OXA-XXO_HOPPE_DOUBLE_PKT"),
  XXOtruthLeftPocketScr_JL: jambLegOXURL("XXO_TRUTH_LEFT_PKT"),
  XXOhoppeRightPocketScr_JL: jambLegOXURL("XXO_HOPPE_RIGHT_PKT"),
  XXOhoppeLeftPocketScr_JL: jambLegOXURL("XXO_HOPPE_LEFT_PKT"),
  OXX_AXOtruthDoublePocketScr_JL: jambLegOXURL("OXX-AXO_TRUTH_DOUBLE_PKT"),
  OXX_AXOhoppeDoublePocketScr_JL: jambLegOXURL("OXX-AXO_HOPPE_DOUBLE_PKT"),
  OXXtruthRightPocketScr_JL: jambLegOXURL("OXX_TRUTH_RIGHT_PKT"),
  XXOtruthRightPocketScr_JL: jambLegOXURL("XXO_TRUTH_RIGHT_PKT"),
  OXXXtruthLeftPocketScr_JL: jambLegOXURL("OXXX_TRUTH_LEFT_PKT"),
  OXXXhoppeRightPocketScr_JL: jambLegOXURL("OXXX_HOPPE_RIGHT_PKT"),
  OXXXhoppeLeftPocketScr_JL: jambLegOXURL("OXXX_HOPPE_LEFT_PKT"),
  OXXA_XXXOtruthDoublePocketScr_JL: jambLegOXURL("OXXA-XXXO_TRUTH_DOUBLE_PKT"),
  OXXA_XXXOhoppeDoublePocketScr_JL: jambLegOXURL("OXXA-XXXO_HOPPE_DOUBLE_PKT"),
  XXXOhoppeRightPocketScr_JL: jambLegOXURL("XXXO_HOPPE_RIGHT_PKT"),
  XXXOhoppeLeftPocketScr_JL: jambLegOXURL("XXXO_HOPPE_LEFT_PKT"),
  OXXX_AXXOtruthDoublePocketScr_JL: jambLegOXURL("OXXX-AXXO_TRUTH_DOUBLE_PKT"),
  OXXX_AXXOhoppeDoublePocketScr_JL: jambLegOXURL("OXXX-AXXO_HOPPE_DOUBLE_PKT"),
  OXXXtruthRightPocketScr_JL: jambLegOXURL("OXXX_TRUTH_RIGHT_PKT"),
  XXXOtruthRightPocketScr_JL: jambLegOXURL("XXXO_TRUTH_RIGHT_PKT"),
  XXXOtruthLeftPocketScr_JL: jambLegOXURL("XXXO_TRUTH_LEFT_PKT"),
  XXXXOtruthLeftPocketScreen_JL: jambLegOXURL("XXXXO_LEFT_POCKET_SCREEN_TRUTH"),
  XXXXOhoppeLeftPocketScreen_JL: jambLegOXURL("XXXXO_LEFT_POCKET_SCREEN_HOPPE"),
  OXXXX_AXXXOtruthDoublePocketScr_JL: jambLegOXURL(
    "OXXXX-AXXXO_TRUTH_DOUBLE_POCKET_SCREEN",
  ),
  OXXXX_AXXXOhoppeDoublePocketScr_JL: jambLegOXURL(
    "OXXXX-AXXXO_HOPPE_DOUBLE_POCKET_SCREEN",
  ),
  OXXXA_XXXXOtruthDoublePocketScr_JL: jambLegOXURL(
    "OXXXA-XXXXO_TRUTH_DOUBLE_POCKET_SCREEN",
  ),
  OXXXA_XXXXOhoppeDoublePocketScr_JL: jambLegOXURL(
    "OXXXA-XXXXO_HOPPE_DOUBLE_POCKET_SCREEN",
  ),
  OXXXXtruthRightPocketScr_JL: jambLegOXURL("OXXXX_RIGHT_POCKET_SCREEN_TRUTH"),
  OXXXXhoppeRightPocketScr_JL: jambLegOXURL("OXXXX_RIGHT_POCKET_SCREEN_HOPPE"),
  OXXXXtruthLeftPocketScr_JL: jambLegOXURL("OXXXX_LEFT_POCKET_SCREEN_TRUTH"),
  OXXXXhoppeLeftPocketScr_JL: jambLegOXURL("OXXXX_LEFT_POCKET_SCREEN_HOPPE"),
  XXXXOtruthRightPocketScr_JL: jambLegOXURL("XXXXO_RIGHT_POCKET_SCREEN_TRUTH"),
  XXXXOhoppeRightPocketScr_JL: jambLegOXURL("XXXXO_RIGHT_POCKET_SCREEN_HOPPE"),
  XXOtruthDoublePocketScr_JL: jambLegOXURL("XXO_TRUTH_DOUBLE_POCKET_SCREEN"),
  XXOhoppeDoublePocketScr_JL: jambLegOXURL("XXO_HOPPE_DOUBLE_POCKET_SCREEN"),
  OXXtruthDoublePocketScr_JL: jambLegOXURL("OXX_TRUTH_DOUBLE_POCKET_SCREEN"),
  OXXhoppeDoublePocketScr_JL: jambLegOXURL("OXX_HOPPE_DOUBLE_POCKET_SCREEN"),
  XXXOtruthDoublePocketScr_JL: jambLegOXURL("XXXO_TRUTH_DOUBLE_POCKET_SCREEN"),
  XXXOhoppeDoublePocketScr_JL: jambLegOXURL("XXXO_HOPPE_DOUBLE_POCKET_SCREEN"),
  OXXXtruthDoublePocketScr_JL: jambLegOXURL("OXXX_TRUTH_DOUBLE_POCKET_SCREEN"),
  OXXXhoppeDoublePocketScr_JL: jambLegOXURL("OXXX_HOPPE_DOUBLE_POCKET_SCREEN"),
  XXXXOtruthDoublePocketScr_JL: jambLegOXURL(
    "XXXXO_DOUBLE_POCKET_SCREEN_TRUTH",
  ),
  XXXXOhoppeDoublePocketScr_JL: jambLegOXURL(
    "XXXXO_DOUBLE_POCKET_SCREEN_HOPPE",
  ),
  OXXXXtruthDoublePocketScr_JL: jambLegOXURL(
    "OXXXX_DOUBLE_POCKET_SCREEN_TRUTH",
  ),
  OXXXXhoppeDoublePocketScr_JL: jambLegOXURL(
    "OXXXX_DOUBLE_POCKET_SCREEN_HOPPE",
  ),

  Wood_OXhoppeRightPocketScr_JL: jambLegOXURL("WOOD_OX_RIGHT_PKT_SCR_HOPPE"),
  Wood_OXtruthLeftPocketScr_JL: jambLegOXURL("WOOD_OX_LEFT_PKT_SCR_TRUTH"),
  Wood_OXhoppeLeftPocketScr_JL: jambLegOXURL("WOOD_OX_LEFT_PKT_SCR_HOPPE"),
  Wood_OA_XOtruthDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OA-XO_DOUBLE_PKT_SCR_TRUTH",
  ),
  Wood_OA_XOhoppeDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OA-XO_DOUBLE_PKT_SCR_HOPPE",
  ),
  Wood_XOtruthLeftPocketScr_JL: jambLegOXURL("WOOD_XO_LEFT_PKT_SCR_TRUTH"),
  Wood_XOhoppeLeftPocketScr_JL: jambLegOXURL("WOOD_XO_LEFT_PKT_SCR_HOPPE"),
  Wood_OX_AOtruthDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OX-AO_DOUBLE_PKT_SCR_TRUTH",
  ),
  Wood_OX_AOhoppeDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OX-AO_DOUBLE_PKT_SCR_HOPPE",
  ),
  Wood_OXtruthRightPocketScr_JL: jambLegOXURL("WOOD_OX_RIGHT_PKT_SCR_TRUTH"),
  Wood_OA_XOhoppeSlidingScr_JL: jambLegOXURL("WOOD_OA_XO_HOPPE_SLIDING_SCR"),
  Wood_OA_XOhoppeScr_JL: jambLegOXURL("WOOD_OA_XO_HOPPE_SCREEN"),
  Wood_OA_XOhoppe_JL: jambLegOXURL("WOOD_OA_XO_HOPPE"),
  Wood_XOtruthRightPocketScr_JL: jambLegOXURL("WOOD_XO_RIGHT_PKT_SCR_TRUTH"),
  Wood_XOhoppeRightPocketScr_JL: jambLegOXURL("WOOD_XO_RIGHT_PKT_SCR_HOPPE"),
  Wood_OX_AOhoppeScr_JL: jambLegOXURL("WOOD_OX_AO_HOPPE_SCREEN"),
  Wood_OX_AOhoppe_JL: jambLegOXURL("WOOD_OX_AO_HOPPE"),
  Wood_OA_XOtruthSlidingScr_JL: jambLegOXURL("WOOD_OA_XO_TRUTH_SLIDING_SCR"),
  Wood_OA_XOtruthScr_JL: jambLegOXURL("WOOD_OA_XO_TRUTH_SCREEN"),
  Wood_OA_XOtruth_JL: jambLegOXURL("WOOD_OA_XO_TRUTH"),
  Wood_OXhoppe_JL: jambLegOXURL("WOOD_OX_HOPPE"),
  Wood_OX_AOtruthSlidingScr_JL: jambLegOXURL("WOOD_OX_AO_TRUTH_SLIDING_SCR"),
  Wood_OX_AOtruthScr_JL: jambLegOXURL("WOOD_OX_AO_TRUTH_SCREEN"),
  Wood_OX_AOtruth_JL: jambLegOXURL("WOOD_OX_AO_TRUTH"),
  Wood_OX_AOhoppeSlidingScr_JL: jambLegOXURL("WOOD_OX_AO_HOPPE_SLIDING_SCR"),
  Wood_OXtruthSlidingScr_JL: jambLegOXURL("WOOD_OX_TRUTH_SLIDING_SCR"),
  Wood_OXtruthScr_JL: jambLegOXURL("WOOD_OX_TRUTH_SCREEN"),
  Wood_OXtruth_JL: jambLegOXURL("WOOD_OX_TRUTH"),
  Wood_OXhoppeSlidingScr_JL: jambLegOXURL("WOOD_OX_HOPPE_SLIDING_SCR"),
  Wood_OXhoppeScr_JL: jambLegOXURL("WOOD_OX_HOPPE_SCREEN"),
  Wood_XOtruthScr_JL: jambLegOXURL("WOOD_XO_TRUTH_SCREEN"),
  Wood_XOtruth_JL: jambLegOXURL("WOOD_XO_TRUTH"),
  Wood_XOhoppeSlidingScr_JL: jambLegOXURL("WOOD_XO_HOPPE_SLIDING_SCR"),
  Wood_XOhoppeScr_JL: jambLegOXURL("WOOD_XO_HOPPE_SCREEN"),
  Wood_XOhoppe_JL: jambLegOXURL("WOOD_XO_HOPPE"),
  Wood_XOtruthSlidingScr_JL: jambLegOXURL("WOOD_XO_TRUTH_SLIDING_SCR"),
  Wood_OXA_XXOtruthDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OXA-XXO_TRUTH_DOUBLE_SCREEN",
  ),
  Wood_OXA_XXOtruth_JL: jambLegOXURL("WOOD_OXA-XXO_TRUTH"),
  Wood_OXA_XXOhoppeScr_JL: jambLegOXURL("WOOD_OXA-XXO_HOPPE_SCR"),
  Wood_OXA_XXOhoppeDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OXA-XXO_HOPPE_DOUBLE_SCREEN",
  ),
  Wood_OXA_XXOhoppe_JL: jambLegOXURL("WOOD_OXA-XXO_HOPPE"),
  Wood_OXXhoppeRightPocketScr_JL: jambLegOXURL("WOOD_OXX_HOPPE_RIGHT_SCREEN"),
  Wood_OXXhoppeLeftPocketScr_JL: jambLegOXURL("WOOD_OXX_HOPPE_LEFT_SCREEN"),
  Wood_OXXhoppeDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OXX_HOPPE_DOUBLE_POCKET_SCREEN",
  ),
  Wood_OXXhoppe_JL: jambLegOXURL("WOOD_OXX_HOPPE"),
  Wood_OXA_XXOtruthScr_JL: jambLegOXURL("WOOD_OXA-XXO_TRUTH_SCR"),
  Wood_OXXtruthRightPocketScr_JL: jambLegOXURL("WOOD_OXX_TRUTH_RIGHT_SCREEN"),
  Wood_OXXtruthLeftPocketScr_JL: jambLegOXURL("WOOD_OXX_TRUTH_LEFT_SCREEN"),
  Wood_OXXtruthDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OXX_TRUTH_DOUBLE_POCKET_SCREEN",
  ),
  Wood_OXXtruth_JL: jambLegOXURL("WOOD_OXX_TRUTH"),
  Wood_OXXhoppeScr_JL: jambLegOXURL("WOOD_OXX_HOPPE_SCR"),
  Wood_OXX_AXOtruth_JL: jambLegOXURL("WOOD_OXX-AXO_TRUTH"),
  Wood_OXX_AXOhoppeScr_JL: jambLegOXURL("WOOD_OXX-AXO_HOPPE_SCR"),
  Wood_OXX_AXOhoppeDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OXX-AXO_HOPPE_DOUBLE_SCREEN",
  ),
  Wood_OXX_AXOhoppe_JL: jambLegOXURL("WOOD_OXX-AXO_HOPPE"),
  Wood_OXXtruthScr_JL: jambLegOXURL("WOOD_OXX_TRUTH_SCR"),
  Wood_XXOhoppeLeftPocketScr_JL: jambLegOXURL("WOOD_XXO_HOPPE_LEFT_SCREEN"),
  Wood_XXOhoppeDoublePocketScr_JL: jambLegOXURL(
    "WOOD_XXO_HOPPE_DOUBLE_POCKET_SCREEN",
  ),
  Wood_XXOhoppe_JL: jambLegOXURL("WOOD_XXO_HOPPE"),
  Wood_OXX_AXOtruthScr_JL: jambLegOXURL("WOOD_OXX-AXO_TRUTH_SCR"),
  Wood_OXX_AXOtruthDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OXX-AXO_TRUTH_DOUBLE_SCREEN",
  ),
  Wood_XXOtruthLeftPocketScr_JL: jambLegOXURL("WOOD_XXO_TRUTH_LEFT_SCREEN"),
  Wood_XXOtruthDoublePocketScr_JL: jambLegOXURL(
    "WOOD_XXO_TRUTH_DOUBLE_POCKET_SCREEN",
  ),
  Wood_XXOtruth_JL: jambLegOXURL("WOOD_XXO_TRUTH"),
  Wood_XXOhoppeScr_JL: jambLegOXURL("WOOD_XXO_HOPPE_SCR"),
  Wood_XXOhoppeRightPocketScr_JL: jambLegOXURL("WOOD_XXO_HOPPE_RIGHT_SCREEN"),
  Wood_XXOtruthScr_JL: jambLegOXURL("WOOD_XXO_TRUTH_SCR"),
  Wood_XXOtruthRightPocketScr_JL: jambLegOXURL("WOOD_XXO_TRUTH_RIGHT_SCREEN"),
  Wood_OXXA_XXXOtruthDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OXXA-XXXO_TRUTH_DOUBLE_SCREEN",
  ),
  Wood_OXXA_XXXOtruth_JL: jambLegOXURL("WOOD_OXXA-XXXO_TRUTH"),
  Wood_OXXA_XXXOhoppeScr_JL: jambLegOXURL("WOOD_OXXA-XXXO_HOPPE_SCREEN"),
  Wood_OXXA_XXXOhoppeDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OXXA-XXXO_HOPPE_DOUBLE_SCREEN",
  ),
  Wood_OXXA_XXXOhoppe_JL: jambLegOXURL("WOOD_OXXA-XXXO_HOPPE"),
  Wood_OXXXhoppeRightPocketScr_JL: jambLegOXURL("WOOD_OXXX_HOPPE_RIGHT_SCREEN"),
  Wood_OXXXhoppeLeftPocketScr_JL: jambLegOXURL("WOOD_OXXX_HOPPE_LEFT_SCREEN"),
  Wood_OXXXhoppeDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OXXX_HOPPE_DOUBLE_POCKET_SCREEN",
  ),
  Wood_OXXXhoppe_JL: jambLegOXURL("WOOD_OXXX_HOPPE"),
  Wood_OXXA_XXXOtruthScr_JL: jambLegOXURL("WOOD_OXXA-XXXO_TRUTH_SCREEN"),
  Wood_OXXXtruthRightPocketScr_JL: jambLegOXURL("WOOD_OXXX_TRUTH_RIGHT_SCREEN"),
  Wood_OXXXtruthLeftPocketScr_JL: jambLegOXURL("WOOD_OXXX_TRUTH_LEFT_SCREEN"),
  Wood_OXXXtruthDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OXXX_TRUTH_DOUBLE_POCKET_SCREEN",
  ),
  Wood_OXXXhoppeScr_JL: jambLegOXURL("WOOD_OXXX_HOPPE_SCREEN"),
  Wood_OXXXtruth_JL: jambLegOXURL("WOOD_OXXX_TRUTH"),
  Wood_OXXX_AXXOtruth_JL: jambLegOXURL("WOOD_OXXX-AXXO_TRUTH"),
  Wood_OXXX_AXXOhoppeScr_JL: jambLegOXURL("WOOD_OXXX-AXXO_HOPPE_SCREEN"),
  Wood_OXXX_AXXOhoppeDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OXXX-AXXO_HOPPE_DOUBLE_SCREEN",
  ),
  Wood_OXXX_AXXOhoppe_JL: jambLegOXURL("WOOD_OXXX-AXXO_HOPPE"),
  Wood_OXXXtruthScr_JL: jambLegOXURL("WOOD_OXXX_TRUTH_SCREEN"),
  Wood_XXXOhoppeLeftPocketScr_JL: jambLegOXURL("WOOD_XXXO_HOPPE_LEFT_SCREEN"),
  Wood_XXXOhoppeDoublePocketScr_JL: jambLegOXURL(
    "WOOD_XXXO_HOPPE_DOUBLE_POCKET_SCREEN",
  ),
  Wood_XXXOhoppe_JL: jambLegOXURL("WOOD_XXXO_HOPPE"),
  Wood_OXXX_AXXOtruthDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OXXX-AXXO_TRUTH_DOUBLE_SCREEN",
  ),
  Wood_OXXX_AXXOtruthScr_JL: jambLegOXURL("WOOD_OXXX-AXXO_TRUTH_SCREEN"),
  Wood_XXXOtruthLeftPocketScr_JL: jambLegOXURL("WOOD_XXXO_TRUTH_LEFT_SCREEN"),
  Wood_XXXOtruthDoublePocketScr_JL: jambLegOXURL(
    "WOOD_XXXO_TRUTH_DOUBLE_POCKET_SCREEN",
  ),
  Wood_XXXOtruth_JL: jambLegOXURL("WOOD_XXXO_TRUTH"),
  Wood_XXXOhoppeScr_JL: jambLegOXURL("WOOD_XXXO_HOPPE_SCREEN"),
  Wood_XXXOhoppeRightPocketScr_JL: jambLegOXURL("WOOD_XXXO_HOPPE_RIGHT_SCREEN"),
  Wood_XXXOtruthScr_JL: jambLegOXURL("WOOD_XXXO_TRUTH_SCREEN"),
  Wood_XXXOtruthRightPocketScr_JL: jambLegOXURL("WOOD_XXXO_TRUTH_RIGHT_SCREEN"),
  Wood_OXXXA_XXXXOtruthDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OXXXA-XXXXO_TRUTH_DOUBLE_POCKET_SCREEN",
  ),
  Wood_OXXXA_XXXXOtruth_JL: jambLegOXURL("WOOD_OXXXA-XXXXO_TRUTH"),
  Wood_OXXXA_XXXXOhoppeScr_JL: jambLegOXURL("WOOD_OXXXA-XXXXO_HOPPE_SCREEN"),
  Wood_OXXXA_XXXXOhoppeDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OXXXA-XXXXO_HOPPE_DOUBLE_POCKET_SCREEN",
  ),
  Wood_OXXXA_XXXXOhoppe_JL: jambLegOXURL("WOOD_OXXXA-XXXXO_HOPPE"),
  Wood_OXXXX_AXXXOtruth_JL: jambLegOXURL("WOOD_OXXXX-AXXXO_TRUTH"),
  Wood_OXXXX_AXXXOhoppeDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OXXXX-AXXXO_HOPPE_DOUBLE_POCKET_SCREEN",
  ),
  Wood_OXXXX_AXXXOhoppe_JL: jambLegOXURL("WOOD_OXXXX-AXXXO_HOPPE"),
  Wood_OXXXA_XXXXOtruthScr_JL: jambLegOXURL("WOOD_OXXXA-XXXXO_TRUTH_SCREEN"),
  Wood_OXXXX_AXXXOhoppeScr_JL: jambLegOXURL("WOOD_OXXXX-AXXXO_HOPPE_SCREEN"),
  Wood_OXXXX_AXXXOtruthDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OXXXX-AXXXO_TRUTH_DOUBLE_POCKET_SCREEN",
  ),
  Wood_OXXXX_AXXXOtruthScr_JL: jambLegOXURL("WOOD_OXXXX-AXXXO_TRUTH_SCREEN"),
  Wood_OXXXXhoppeDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OXXXX_DOUBLE_POCKET_SCREEN_HOPPE",
  ),
  Wood_OXXXXtruthDoublePocketScr_JL: jambLegOXURL(
    "WOOD_OXXXX_DOUBLE_POCKET_SCREEN_TRUTH",
  ),
  Wood_XXXXOtruthRightPocketScr_JL: jambLegOXURL(
    "WOOD_XXXXO_RIGHT_POCKET_SCREEN_TRUTH",
  ),
  Wood_XXXXOhoppeRightPocketScr_JL: jambLegOXURL(
    "WOOD_XXXXO_RIGHT_POCKET_SCREEN_HOPPE",
  ),
  Wood_XXXXOtruthLeftPocketScr_JL: jambLegOXURL(
    "WOOD_XXXXO_LEFT_POCKET_SCREEN_TRUTH",
  ),
  Wood_XXXXOhoppeLeftPocketScr_JL: jambLegOXURL(
    "WOOD_XXXXO_LEFT_POCKET_SCREEN_HOPPE",
  ),
  Wood_OXXXXtruthRightPocketScr_JL: jambLegOXURL(
    "WOOD_OXXXX_RIGHT_POCKET_SCREEN_TRUTH",
  ),
  Wood_OXXXXhoppeRightPocketScr_JL: jambLegOXURL(
    "WOOD_OXXXX_RIGHT_POCKET_SCREEN_HOPPE",
  ),
  Wood_OXXXXtruthLeftPocketScr_JL: jambLegOXURL(
    "WOOD_OXXXX_LEFT_POCKET_SCREEN_TRUTH",
  ),
  Wood_OXXXXhoppeLeftPocketScr_JL: jambLegOXURL(
    "WOOD_OXXXX_LEFT_POCKET_SCREEN_HOPPE",
  ),
  Wood_OXXXXhoppeScr_JL: jambLegOXURL("WOOD_OXXXX_HOPPE_SCREEN"),
  Wood_OXXXXhoppe_JL: jambLegOXURL("WOOD_OXXXX_HOPPE"),
  Wood_XXXXOhoppeScr_JL: jambLegOXURL("WOOD_XXXXO_HOPPE_SCREEN"),
  Wood_XXXXOhoppe_JL: jambLegOXURL("WOOD_XXXXO_HOPPE"),
  Wood_XXXXOtruthDoublePocketScr_JL: jambLegOXURL(
    "WOOD_XXXXO_DOUBLE_POCKET_SCREEN_TRUTH",
  ),
  Wood_XXXXOhoppeDoublePocketScr_JL: jambLegOXURL(
    "WOOD_XXXXO_DOUBLE_POCKET_SCREEN_HOPPE",
  ),
  Wood_OXXXXtruthScr_JL: jambLegOXURL("WOOD_OXXXX_TRUTH_SCREEN"),
  Wood_OXXXXtruth_JL: jambLegOXURL("WOOD_OXXXX_TRUTH"),
  Wood_XXXXOtruthScr_JL: jambLegOXURL("WOOD_XXXXO_TRUTH_SCREEN"),
  Wood_XXXXOtruth_JL: jambLegOXURL("WOOD_XXXXO_TRUTH"),

  Wood_XOtruthCentorDoubleScr_JL: jambLegOXURL("WOOD_XO_TRUTH_DOUBLE_CENTOR"),
  Wood_XOtruthCentorLeftScr_JL: jambLegOXURL("WOOD_XO_TRUTH_LEFT_CENTOR"),
  Wood_XOtruthCentorRightScr_JL: jambLegOXURL("WOOD_XO_TRUTH_RIGHT_CENTOR"),
  Wood_XOhoppeCentorDoubleScr_JL: jambLegOXURL("WOOD_XO_HOPPE_DOUBLE_CENTOR"),
  Wood_XOhoppeCentorLeftScr_JL: jambLegOXURL("WOOD_XO_HOPPE_LEFT_CENTOR"),
  Wood_XOhoppeCentorRightScr_JL: jambLegOXURL("WOOD_XO_HOPPE_RIGHT_CENTOR"),
  Wood_OXtruthCentorDoubleScr_JL: jambLegOXURL("WOOD_OX_TRUTH_DOUBLE_CENTOR"),
  Wood_OXtruthCentorLeftScr_JL: jambLegOXURL("WOOD_OX_TRUTH_LEFT_CENTOR"),
  Wood_OXtruthCentorRightScr_JL: jambLegOXURL("WOOD_OX_TRUTH_RIGHT_CENTOR"),
  Wood_OXhoppeCentorDoubleScr_JL: jambLegOXURL("WOOD_OX_HOPPE_DOUBLE_CENTOR"),
  Wood_OXhoppeCentorLeftScr_JL: jambLegOXURL("WOOD_OX_HOPPE_LEFT_CENTOR"),
  Wood_OXhoppeCentorRightScr_JL: jambLegOXURL("WOOD_OX_HOPPE_RIGHT_CENTOR"),
  Wood_OX_AOtruthCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OX-AO_TRUTH_DOUBLE_CENTOR",
  ),
  Wood_OX_AOtruthCentorLeftScr_JL: jambLegOXURL("WOOD_OX-AO_TRUTH_LEFT_CENTOR"),
  Wood_OX_AOtruthCentorRightScr_JL: jambLegOXURL(
    "WOOD_OX-AO_TRUTH_RIGHT_CENTOR",
  ),
  Wood_OX_AOhoppeCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OX-AO_HOPPE_DOUBLE_CENTOR",
  ),
  Wood_OX_AOhoppeCentorLeftScr_JL: jambLegOXURL("WOOD_OX-AO_HOPPE_LEFT_CENTOR"),
  Wood_OX_AOhoppeCentorRightScr_JL: jambLegOXURL(
    "WOOD_OX-AO_HOPPE_RIGHT_CENTOR",
  ),
  Wood_OA_XOtruthCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OA-XO_TRUTH_DOUBLE_CENTOR",
  ),
  Wood_OA_XOtruthCentorLeftScr_JL: jambLegOXURL("WOOD_OA-XO_TRUTH_LEFT_CENTOR"),
  Wood_OA_XOtruthCentorRightScr_JL: jambLegOXURL(
    "WOOD_OA-XO_TRUTH_RIGHT_CENTOR",
  ),
  Wood_OA_XOhoppeCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OA-XO_HOPPE_DOUBLE_CENTOR",
  ),
  Wood_OA_XOhoppeCentorLeftScr_JL: jambLegOXURL("WOOD_OA-XO_HOPPE_LEFT_CENTOR"),
  Wood_OA_XOhoppeCentorRightScr_JL: jambLegOXURL(
    "WOOD_OA-XO_HOPPE_RIGHT_CENTOR",
  ),

  Wood_XXOtruthCentorDoubleScr_JL: jambLegOXURL("WOOD_XXO_TRUTH_DOUBLE_CENTOR"),
  Wood_XXOtruthCentorLeftScr_JL: jambLegOXURL("WOOD_XXO_TRUTH_LEFT_CENTOR"),
  Wood_XXOtruthCentorRightScr_JL: jambLegOXURL("WOOD_XXO_TRUTH_RIGHT_CENTOR"),
  Wood_XXOhoppeCentorDoubleScr_JL: jambLegOXURL("WOOD_XXO_HOPPE_DOUBLE_CENTOR"),
  Wood_XXOhoppeCentorLeftScr_JL: jambLegOXURL("WOOD_XXO_HOPPE_LEFT_CENTOR"),
  Wood_XXOhoppeCentorRightScr_JL: jambLegOXURL("WOOD_XXO_HOPPE_RIGHT_CENTOR"),
  Wood_OXXtruthCentorDoubleScr_JL: jambLegOXURL("WOOD_OXX_TRUTH_DOUBLE_CENTOR"),
  Wood_OXXtruthCentorLeftScr_JL: jambLegOXURL("WOOD_OXX_TRUTH_LEFT_CENTOR"),
  Wood_OXXtruthCentorRightScr_JL: jambLegOXURL("WOOD_OXX_TRUTH_RIGHT_CENTOR"),
  Wood_OXXhoppeCentorDoubleScr_JL: jambLegOXURL("WOOD_OXX_HOPPE_DOUBLE_CENTOR"),
  Wood_OXXhoppeCentorLeftScr_JL: jambLegOXURL("WOOD_OXX_HOPPE_LEFT_CENTOR"),
  Wood_OXXhoppeCentorRightScr_JL: jambLegOXURL("WOOD_OXX_HOPPE_RIGHT_CENTOR"),
  Wood_OXX_AXOtruthCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OXX-AXO_TRUTH_DOUBLE_CENTOR",
  ),
  Wood_OXX_AXOtruthCentorLeftScr_JL: jambLegOXURL(
    "WOOD_OXX-AXO_TRUTH_LEFT_CENTOR",
  ),
  Wood_OXX_AXOtruthCentorRightScr_JL: jambLegOXURL(
    "WOOD_OXX-AXO_TRUTH_RIGHT_CENTOR",
  ),
  Wood_OXX_AXOhoppeCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OXX-AXO_HOPPE_DOUBLE_CENTOR",
  ),
  Wood_OXX_AXOhoppeCentorLeftScr_JL: jambLegOXURL(
    "WOOD_OXX-AXO_HOPPE_LEFT_CENTOR",
  ),
  Wood_OXX_AXOhoppeCentorRightScr_JL: jambLegOXURL(
    "WOOD_OXX-AXO_HOPPE_RIGHT_CENTOR",
  ),
  Wood_OXA_XXOtruthCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OXA-XXO_TRUTH_DOUBLE_CENTOR",
  ),
  Wood_OXA_XXOtruthCentorLeftScr_JL: jambLegOXURL(
    "WOOD_OXA-XXO_TRUTH_LEFT_CENTOR",
  ),
  Wood_OXA_XXOtruthCentorRightScr_JL: jambLegOXURL(
    "WOOD_OXA-XXO_TRUTH_RIGHT_CENTOR",
  ),
  Wood_OXA_XXOhoppeCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OXA-XXO_HOPPE_DOUBLE_CENTOR",
  ),
  Wood_OXA_XXOhoppeCentorLeftScr_JL: jambLegOXURL(
    "WOOD_OXA-XXO_HOPPE_LEFT_CENTOR",
  ),
  Wood_OXA_XXOhoppeCentorRightScr_JL: jambLegOXURL(
    "WOOD_OXA-XXO_HOPPE_RIGHT_CENTOR",
  ),

  Wood_XXXOtruthCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_XXXO_TRUTH_DOUBLE_CENTOR",
  ),
  Wood_XXXOtruthCentorLeftScr_JL: jambLegOXURL("WOOD_XXXO_TRUTH_LEFT_CENTOR"),
  Wood_XXXOtruthCentorRightScr_JL: jambLegOXURL("WOOD_XXXO_TRUTH_RIGHT_CENTOR"),
  Wood_XXXOhoppeCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_XXXO_HOPPE_DOUBLE_CENTOR",
  ),
  Wood_XXXOhoppeCentorLeftScr_JL: jambLegOXURL("WOOD_XXXO_HOPPE_LEFT_CENTOR"),
  Wood_XXXOhoppeCentorRightScr_JL: jambLegOXURL("WOOD_XXXO_HOPPE_RIGHT_CENTOR"),
  Wood_OXXXtruthCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OXXX_TRUTH_DOUBLE_CENTOR",
  ),
  Wood_OXXXtruthCentorLeftScr_JL: jambLegOXURL("WOOD_OXXX_TRUTH_LEFT_CENTOR"),
  Wood_OXXXtruthCentorRightScr_JL: jambLegOXURL("WOOD_OXXX_TRUTH_RIGHT_CENTOR"),
  Wood_OXXXhoppeCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OXXX_HOPPE_DOUBLE_CENTOR",
  ),
  Wood_OXXXhoppeCentorLeftScr_JL: jambLegOXURL("WOOD_OXXX_HOPPE_LEFT_CENTOR"),
  Wood_OXXXhoppeCentorRightScr_JL: jambLegOXURL("WOOD_OXXX_HOPPE_RIGHT_CENTOR"),
  Wood_OXXX_AXXOtruthCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OXXX-AXXO_TRUTH_DOUBLE_CENTOR",
  ),
  Wood_OXXX_AXXOtruthCentorLeftScr_JL: jambLegOXURL(
    "WOOD_OXXX-AXXO_TRUTH_LEFT_CENTOR",
  ),
  Wood_OXXX_AXXOtruthCentorRightScr_JL: jambLegOXURL(
    "WOOD_OXXX-AXXO_TRUTH_RIGHT_CENTOR",
  ),
  Wood_OXXX_AXXOhoppeCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OXXX-AXXO_HOPPE_DOUBLE_CENTOR",
  ),
  Wood_OXXX_AXXOhoppeCentorLeftScr_JL: jambLegOXURL(
    "WOOD_OXXX-AXXO_HOPPE_LEFT_CENTOR",
  ),
  Wood_OXXX_AXXOhoppeCentorRightScr_JL: jambLegOXURL(
    "WOOD_OXXX-AXXO_HOPPE_RIGHT_CENTOR",
  ),
  Wood_OXXA_XXXOtruthCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OXXA-XXXO_TRUTH_DOUBLE_CENTOR",
  ),
  Wood_OXXA_XXXOtruthCentorLeftScr_JL: jambLegOXURL(
    "WOOD_OXXA-XXXO_TRUTH_LEFT_CENTOR",
  ),
  Wood_OXXA_XXXOtruthCentorRightScr_JL: jambLegOXURL(
    "WOOD_OXXA-XXXO_TRUTH_RIGHT_CENTOR",
  ),
  Wood_OXXA_XXXOhoppeCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OXXA-XXXO_HOPPE_DOUBLE_CENTOR",
  ),
  Wood_OXXA_XXXOhoppeCentorLeftScr_JL: jambLegOXURL(
    "WOOD_OXXA-XXXO_HOPPE_LEFT_CENTOR",
  ),
  Wood_OXXA_XXXOhoppeCentorRightScr_JL: jambLegOXURL(
    "WOOD_OXXA-XXXO_HOPPE_RIGHT_CENTOR",
  ),

  Wood_XXXXOtruthCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_XXXXO_TRUTH_DOUBLE_CENTOR",
  ),
  Wood_XXXXOtruthCentorLeftScr_JL: jambLegOXURL("WOOD_XXXXO_TRUTH_LEFT_CENTOR"),
  Wood_XXXXOtruthCentorRightScr_JL: jambLegOXURL(
    "WOOD_XXXXO_TRUTH_RIGHT_CENTOR",
  ),
  Wood_XXXXOhoppeCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_XXXXO_HOPPE_DOUBLE_CENTOR",
  ),
  Wood_XXXXOhoppeCentorLeftScr_JL: jambLegOXURL("WOOD_XXXXO_HOPPE_LEFT_CENTOR"),
  Wood_XXXXOhoppeCentorRightScr_JL: jambLegOXURL(
    "WOOD_XXXXO_HOPPE_RIGHT_CENTOR",
  ),
  Wood_OXXXXtruthCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OXXXX_TRUTH_DOUBLE_CENTOR",
  ),
  Wood_OXXXXtruthCentorLeftScr_JL: jambLegOXURL("WOOD_OXXXX_TRUTH_LEFT_CENTOR"),
  Wood_OXXXXtruthCentorRightScr_JL: jambLegOXURL(
    "WOOD_OXXXX_TRUTH_RIGHT_CENTOR",
  ),
  Wood_OXXXXhoppeCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OXXXX_HOPPE_DOUBLE_CENTOR",
  ),
  Wood_OXXXXhoppeCentorLeftScr_JL: jambLegOXURL("WOOD_OXXXX_HOPPE_LEFT_CENTOR"),
  Wood_OXXXXhoppeCentorRightScr_JL: jambLegOXURL(
    "WOOD_OXXXX_HOPPE_RIGHT_CENTOR",
  ),
  Wood_OXXXX_AXXXOtruthCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OXXXX-AXXXO_TRUTH_DOUBLE_CENTOR",
  ),
  Wood_OXXXX_AXXXOtruthCentorLeftScr_JL: jambLegOXURL(
    "WOOD_OXXXX-AXXXO_TRUTH_LEFT_CENTOR",
  ),
  Wood_OXXXX_AXXXOtruthCentorRightScr_JL: jambLegOXURL(
    "WOOD_OXXXX-AXXXO_TRUTH_RIGHT_CENTOR",
  ),
  Wood_OXXXX_AXXXOhoppeCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OXXXX-AXXXO_HOPPE_DOUBLE_CENTOR",
  ),
  Wood_OXXXX_AXXXOhoppeCentorLeftScr_JL: jambLegOXURL(
    "WOOD_OXXXX-AXXXO_HOPPE_LEFT_CENTOR",
  ),
  Wood_OXXXX_AXXXOhoppeCentorRightScr_JL: jambLegOXURL(
    "WOOD_OXXXX-AXXXO_HOPPE_RIGHT_CENTOR",
  ),
  Wood_OXXXA_XXXXOtruthCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OXXXA-XXXXO_TRUTH_DOUBLE_CENTOR",
  ),
  Wood_OXXXA_XXXXOtruthCentorLeftScr_JL: jambLegOXURL(
    "WOOD_OXXXA-XXXXO_TRUTH_LEFT_CENTOR",
  ),
  Wood_OXXXA_XXXXOtruthCentorRightScr_JL: jambLegOXURL(
    "WOOD_OXXXA-XXXXO_TRUTH_RIGHT_CENTOR",
  ),
  Wood_OXXXA_XXXXOhoppeCentorDoubleScr_JL: jambLegOXURL(
    "WOOD_OXXXA-XXXXO_HOPPE_DOUBLE_CENTOR",
  ),
  Wood_OXXXA_XXXXOhoppeCentorLeftScr_JL: jambLegOXURL(
    "WOOD_OXXXA-XXXXO_HOPPE_LEFT_CENTOR",
  ),
  Wood_OXXXA_XXXXOhoppeCentorRightScr_JL: jambLegOXURL(
    "WOOD_OXXXA-XXXXO_HOPPE_RIGHT_CENTOR",
  ),

  XXRightPrimaryDoublePocket_JL: jambLegOXURL("XX_RH_DOUBLE_PKT_SCR"),
  XXRightPrimary_JL: jambLegOXURL("XX_RH"),
  XXLeftPrimaryRightPocket_JL: jambLegOXURL("XX_LH_RIGHT_PKT_SCR"),
  XXLeftPrimaryLeftPocket_JL: jambLegOXURL("XX_LH_LEFT_PKT_SCR"),
  XXLeftPrimaryDoublePocket_JL: jambLegOXURL("XX_LH_DOUBLE_PKT_SCR"),
  XXLeftPrimary_JL: jambLegOXURL("XX_LH"),
  XXRightPrimaryRightPocket_JL: jambLegOXURL("XX_RH_RIGHT_PKT_SCR"),
  XXRightPrimaryLeftPocket_JL: jambLegOXURL("XX_RH_LEFT_PKT_SCR"),
  XXXRightPrimary_JL: jambLegOXURL("XXX_RH"),
  XXXLeftPrimaryRightPocket_JL: jambLegOXURL("XXX_LH_RIGHT_PKT_SCREEN"),
  XXXLeftPrimaryLeftPocket_JL: jambLegOXURL("XXX_LH_LEFT_PKT_SCREEN"),
  XXXLeftPrimaryDoublePocket_JL: jambLegOXURL("XXX_LH_DOUBLE_PKT_SCREEN"),
  XXXLeftPrimary_JL: jambLegOXURL("XXX_LH"),
  XXXRightPrimaryRightPocket_JL: jambLegOXURL("XXX_RH_RIGHT_PKT_SCREEN"),
  XXXRightPrimaryLeftPocket_JL: jambLegOXURL("XXX_RH_LEFT_PKT_SCREEN"),
  XXXRightPrimaryDoublePocket_JL: jambLegOXURL("XXX_RH_DOUBLE_PKT_SCREEN"),
  XXXXRightPrimaryDoublePocket_JL: jambLegOXURL("XXXX_RH_DOUBLE_POCKET_SCR"),
  XXXXRightPrimary_JL: jambLegOXURL("XXXX_RH"),
  XXXXLeftPrimaryRightPocket_JL: jambLegOXURL("XXXX_LH_RIGHT_POCKET_SCR"),
  XXXXLeftPrimaryLeftPocket_JL: jambLegOXURL("XXXX_LH_LEFT_POCKET_SCR"),
  XXXXLeftPrimaryDoublePocket_JL: jambLegOXURL("XXXX_LH_DOUBLE_POCKET_SCR"),
  XXXXLeftPrimary_JL: jambLegOXURL("XXXX_LH"),
  XXXXRightPrimaryRightPocket_JL: jambLegOXURL("XXXX_RH_RIGHT_POCKET_SCR"),
  XXXXRightPrimaryLeftPocket_JL: jambLegOXURL("XXXX_RH_LEFT_POCKET_SCR"),
  Wood_XXRightPrimary_JL: jambLegOXURL("WOOD_XX_RH"),
  Wood_XXLeftPrimaryRightPocket_JL: jambLegOXURL("WOOD_XX_LH_RIGHT_PKT_SCR"),
  Wood_XXLeftPrimaryLeftPocket_JL: jambLegOXURL("WOOD_XX_LH_LEFT_PKT_SCR"),
  Wood_XXLeftPrimaryDoublePocket_JL: jambLegOXURL("WOOD_XX_LH_DOUBLE_PKT_SCR"),
  Wood_XXLeftPrimary_JL: jambLegOXURL("WOOD_XX_LH"),
  Wood_XXRightPrimaryRightPocket_JL: jambLegOXURL("WOOD_XX_RH_RIGHT_PKT_SCR"),
  Wood_XXRightPrimaryLeftPocket_JL: jambLegOXURL("WOOD_XX_RH_LEFT_PKT_SCR"),
  Wood_XXXRightPrimaryDoublePocket_JL: jambLegOXURL(
    "WOOD_XXX_RH_DOUBLE_PKT_SCREEN",
  ),
  Wood_XXXRightPrimary_JL: jambLegOXURL("WOOD_XXX_RH"),
  Wood_XXXLeftPrimaryRightPocket_JL: jambLegOXURL(
    "WOOD_XXX_LH_RIGHT_PKT_SCREEN",
  ),
  Wood_XXXLeftPrimaryLeftPocket_JL: jambLegOXURL("WOOD_XXX_LH_LEFT_PKT_SCREEN"),
  Wood_XXXLeftPrimaryDoublePocket_JL: jambLegOXURL(
    "WOOD_XXX_LH_DOUBLE_PKT_SCREEN",
  ),
  Wood_XXXLeftPrimary_JL: jambLegOXURL("WOOD_XXX_LH"),
  Wood_XXXRightPrimaryRightPocket_JL: jambLegOXURL(
    "WOOD_XXX_RH_RIGHT_PKT_SCREEN",
  ),
  Wood_XXXRightPrimaryLeftPocket_JL: jambLegOXURL(
    "WOOD_XXX_RH_LEFT_PKT_SCREEN",
  ),
  Wood_XXXXRightPrimaryDoublePocket_JL: jambLegOXURL(
    "WOOD_XXXX_RH_DOUBLE_POCKET_SCR",
  ),
  Wood_XXXXRightPrimary_JL: jambLegOXURL("WOOD_XXXX_RH"),
  Wood_XXXXLeftPrimaryRightPocket_JL: jambLegOXURL(
    "WOOD_XXXX_LH_RIGHT_POCKET_SCR",
  ),
  Wood_XXXXLeftPrimaryLeftPocket_JL: jambLegOXURL(
    "WOOD_XXXX_LH_LEFT_POCKET_SCR",
  ),
  Wood_XXXXLeftPrimaryDoublePocket_JL: jambLegOXURL(
    "WOOD_XXXX_LH_DOUBLE_POCKET_SCR",
  ),
  Wood_XXXXLeftPrimary_JL: jambLegOXURL("WOOD_XXXX_LH"),
  Wood_XXXXRightPrimaryRightPocket_JL: jambLegOXURL(
    "WOOD_XXXX_RH_RIGHT_POCKET_SCR",
  ),
  Wood_XXXXRightPrimaryLeftPocket_JL: jambLegOXURL(
    "WOOD_XXXX_RH_LEFT_POCKET_SCR",
  ),

  XXRightPrimaryCentorLeftScr_JL: jambLegOXURL("XX_RH_LEFT_CENTOR"),
  XXRightPrimaryCentorRightScr_JL: jambLegOXURL("XX_RH_RIGHT_CENTOR"),
  XXRightPrimaryCentorDoubleScr_JL: jambLegOXURL("XX_RH_DOUBLE_CENTOR"),
  XXLeftPrimaryCentorLeftScr_JL: jambLegOXURL("XX_LH_LEFT_CENTOR"),
  XXLeftPrimaryCentorRightScr_JL: jambLegOXURL("XX_LH_RIGHT_CENTOR"),
  XXLeftPrimaryCentorDoubleScr_JL: jambLegOXURL("XX_LH_DOUBLE_CENTOR"),

  XXXRightPrimaryCentorLeftScr_JL: jambLegOXURL("XXX_RH_LEFT_CENTOR"),
  XXXRightPrimaryCentorRightScr_JL: jambLegOXURL("XXX_RH_RIGHT_CENTOR"),
  XXXRightPrimaryCentorDoubleScr_JL: jambLegOXURL("XXX_RH_DOUBLE_CENTOR"),
  XXXLeftPrimaryCentorLeftScr_JL: jambLegOXURL("XXX_LH_LEFT_CENTOR"),
  XXXLeftPrimaryCentorRightScr_JL: jambLegOXURL("XXX_LH_RIGHT_CENTOR"),
  XXXLeftPrimaryCentorDoubleScr_JL: jambLegOXURL("XXX_LH_DOUBLE_CENTOR"),

  XXXXRightPrimaryCentorLeftScr_JL: jambLegOXURL("XXXX_RH_LEFT_CENTOR"),
  XXXXRightPrimaryCentorRightScr_JL: jambLegOXURL("XXXX_RH_RIGHT_CENTOR"),
  XXXXRightPrimaryCentorDoubleScr_JL: jambLegOXURL("XXXX_RH_DOUBLE_CENTOR"),
  XXXXLeftPrimaryCentorLeftScr_JL: jambLegOXURL("XXXX_LH_LEFT_CENTOR"),
  XXXXLeftPrimaryCentorRightScr_JL: jambLegOXURL("XXXX_LH_RIGHT_CENTOR"),
  XXXXLeftPrimaryCentorDoubleScr_JL: jambLegOXURL("XXXX_LH_DOUBLE_CENTOR"),

  Wood_XXLeftPrimaryCentorLeftScr_JL: jambLegPXURL("WOOD_XX_LH_LEFT_CENTOR"),
  Wood_XXLeftPrimaryCentorRightScr_JL: jambLegPXURL("WOOD_XX_LH_RIGHT_CENTOR"),
  Wood_XXLeftPrimaryCentorDoubleScr_JL: jambLegPXURL(
    "WOOD_XX_LH_DOUBLE_CENTOR",
  ),
  Wood_XXRightPrimaryCentorLeftScr_JL: jambLegPXURL("WOOD_XX_RH_LEFT_CENTOR"),
  Wood_XXRightPrimaryCentorRightScr_JL: jambLegPXURL("WOOD_XX_RH_RIGHT_CENTOR"),
  Wood_XXRightPrimaryCentorDoubleScr_JL: jambLegPXURL(
    "WOOD_XX_RH_DOUBLE_CENTOR",
  ),

  Wood_XXXLeftPrimaryCentorLeftScr_JL: jambLegPXURL("WOOD_XXX_LH_LEFT_CENTOR"),
  Wood_XXXLeftPrimaryCentorRightScr_JL: jambLegPXURL(
    "WOOD_XXX_LH_RIGHT_CENTOR",
  ),
  Wood_XXXLeftPrimaryCentorDoubleScr_JL: jambLegPXURL(
    "WOOD_XXX_LH_DOUBLE_CENTOR",
  ),
  Wood_XXXRightPrimaryCentorLeftScr_JL: jambLegPXURL("WOOD_XXX_RH_LEFT_CENTOR"),
  Wood_XXXRightPrimaryCentorRightScr_JL: jambLegPXURL(
    "WOOD_XXX_RH_RIGHT_CENTOR",
  ),
  Wood_XXXRightPrimaryCentorDoubleScr_JL: jambLegPXURL(
    "WOOD_XXX_RH_DOUBLE_CENTOR",
  ),

  Wood_XXXXLeftPrimaryCentorLeftScr_JL: jambLegPXURL(
    "WOOD_XXXX_LH_LEFT_CENTOR",
  ),
  Wood_XXXXLeftPrimaryCentorRightScr_JL: jambLegPXURL(
    "WOOD_XXXX_LH_RIGHT_CENTOR",
  ),
  Wood_XXXXLeftPrimaryCentorDoubleScr_JL: jambLegPXURL(
    "WOOD_XXXX_LH_DOUBLE_CENTOR",
  ),
  Wood_XXXXRightPrimaryCentorLeftScr_JL: jambLegPXURL(
    "WOOD_XXXX_RH_LEFT_CENTOR",
  ),
  Wood_XXXXRightPrimaryCentorRightScr_JL: jambLegPXURL(
    "WOOD_XXXX_RH_RIGHT_CENTOR",
  ),
  Wood_XXXXRightPrimaryCentorDoubleScr_JL: jambLegPXURL(
    "WOOD_XXXX_RH_DOUBLE_CENTOR",
  ),

  Wood_PXCentorLeftScr_JL: jambLegPXURL("WOOD_PX_LEFT_CENTOR"),
  Wood_PXCentorRightScr_JL: jambLegPXURL("WOOD_PX_RIGHT_CENTOR"),
  Wood_PXCentorDoubleScr_JL: jambLegPXURL("WOOD_PX_DOUBLE_CENTOR"),
  Wood_XPCentorLeftScr_JL: jambLegPXURL("WOOD_XP_LEFT_CENTOR"),
  Wood_XPCentorRightScr_JL: jambLegPXURL("WOOD_XP_RIGHT_CENTOR"),
  Wood_XPCentorDoubleScr_JL: jambLegPXURL("WOOD_XP_DOUBLE_CENTOR"),
  Wood_PX_APCentorLeftScr_JL: jambLegPXURL("WOOD_PX-AP_LEFT_CENTOR"),
  Wood_PX_APCentorRightScr_JL: jambLegPXURL("WOOD_PX-AP_RIGHT_CENTOR"),
  Wood_PX_APCentorDoubleScr_JL: jambLegPXURL("WOOD_PX-AP_DOUBLE_CENTOR"),
  Wood_PA_XPCentorLeftScr_JL: jambLegPXURL("WOOD_PA-XP_LEFT_CENTOR"),
  Wood_PA_XPCentorRightScr_JL: jambLegPXURL("WOOD_PA-XP_RIGHT_CENTOR"),
  Wood_PA_XPCentorDoubleScr_JL: jambLegPXURL("WOOD_PA-XP_DOUBLE_CENTOR"),

  Wood_PXXCentorLeftScr_JL: jambLegPXURL("WOOD_PXX_LEFT_CENTOR"),
  Wood_PXXCentorRightScr_JL: jambLegPXURL("WOOD_PXX_RIGHT_CENTOR"),
  Wood_PXXCentorDoubleScr_JL: jambLegPXURL("WOOD_PXX_DOUBLE_CENTOR"),
  Wood_XXPCentorLeftScr_JL: jambLegPXURL("WOOD_XXP_LEFT_CENTOR"),
  Wood_XXPCentorRightScr_JL: jambLegPXURL("WOOD_XXP_RIGHT_CENTOR"),
  Wood_XXPCentorDoubleScr_JL: jambLegPXURL("WOOD_XXP_DOUBLE_CENTOR"),
  Wood_PXX_AXPCentorLeftScr_JL: jambLegPXURL("WOOD_PXX-AXP_LEFT_CENTOR"),
  Wood_PXX_AXPCentorRightScr_JL: jambLegPXURL("WOOD_PXX-AXP_RIGHT_CENTOR"),
  Wood_PXX_AXPCentorDoubleScr_JL: jambLegPXURL("WOOD_PXX-AXP_DOUBLE_CENTOR"),
  Wood_PXA_XXPCentorLeftScr_JL: jambLegPXURL("WOOD_PXA-XXP_LEFT_CENTOR"),
  Wood_PXA_XXPCentorRightScr_JL: jambLegPXURL("WOOD_PXA-XXP_RIGHT_CENTOR"),
  Wood_PXA_XXPCentorDoubleScr_JL: jambLegPXURL("WOOD_PXA-XXP_DOUBLE_CENTOR"),

  Wood_PXXXCentorLeftScr_JL: jambLegPXURL("WOOD_PXXX_LEFT_CENTOR"),
  Wood_PXXXCentorRightScr_JL: jambLegPXURL("WOOD_PXXX_RIGHT_CENTOR"),
  Wood_PXXXCentorDoubleScr_JL: jambLegPXURL("WOOD_PXXX_DOUBLE_CENTOR"),
  Wood_XXXPCentorLeftScr_JL: jambLegPXURL("WOOD_XXXP_LEFT_CENTOR"),
  Wood_XXXPCentorRightScr_JL: jambLegPXURL("WOOD_XXXP_RIGHT_CENTOR"),
  Wood_XXXPCentorDoubleScr_JL: jambLegPXURL("WOOD_XXXP_DOUBLE_CENTOR"),
  Wood_PXXX_AXXPCentorLeftScr_JL: jambLegPXURL("WOOD_PXXX-AXXP_LEFT_CENTOR"),
  Wood_PXXX_AXXPCentorRightScr_JL: jambLegPXURL("WOOD_PXXX-AXXP_RIGHT_CENTOR"),
  Wood_PXXX_AXXPCentorDoubleScr_JL: jambLegPXURL(
    "WOOD_PXXX-AXXP_DOUBLE_CENTOR",
  ),
  Wood_PXXA_XXXPCentorLeftScr_JL: jambLegPXURL("WOOD_PXXA-XXXP_LEFT_CENTOR"),
  Wood_PXXA_XXXPCentorRightScr_JL: jambLegPXURL("WOOD_PXXA-XXXP_RIGHT_CENTOR"),
  Wood_PXXA_XXXPCentorDoubleScr_JL: jambLegPXURL(
    "WOOD_PXXA-XXXP_DOUBLE_CENTOR",
  ),

  Wood_PXXXXCentorLeftScr_JL: jambLegPXURL("WOOD_PXXXX_LEFT_CENTOR"),
  Wood_PXXXXCentorRightScr_JL: jambLegPXURL("WOOD_PXXXX_RIGHT_CENTOR"),
  Wood_PXXXXCentorDoubleScr_JL: jambLegPXURL("WOOD_PXXXX_DOUBLE_CENTOR"),
  Wood_XXXXPCentorLeftScr_JL: jambLegPXURL("WOOD_XXXXP_LEFT_CENTOR"),
  Wood_XXXXPCentorRightScr_JL: jambLegPXURL("WOOD_XXXXP_RIGHT_CENTOR"),
  Wood_XXXXPCentorDoubleScr_JL: jambLegPXURL("WOOD_XXXXP_DOUBLE_CENTOR"),
  Wood_PXXXX_AXXXPCentorLeftScr_JL: jambLegPXURL(
    "WOOD_PXXXX-AXXXP_LEFT_CENTOR",
  ),
  Wood_PXXXX_AXXXPCentorRightScr_JL: jambLegPXURL(
    "WOOD_PXXXX-AXXXP_RIGHT_CENTOR",
  ),
  Wood_PXXXX_AXXXPCentorDoubleScr_JL: jambLegPXURL(
    "WOOD_PXXXX-AXXXP_DOUBLE_CENTOR",
  ),
  Wood_PXXXA_XXXXPCentorLeftScr_JL: jambLegPXURL(
    "WOOD_PXXXA-XXXXP_LEFT_CENTOR",
  ),
  Wood_PXXXA_XXXXPCentorRightScr_JL: jambLegPXURL(
    "WOOD_PXXXA-XXXXP_RIGHT_CENTOR",
  ),
  Wood_PXXXA_XXXXPCentorDoubleScr_JL: jambLegPXURL(
    "WOOD_PXXXA-XXXXP_DOUBLE_CENTOR",
  ),

  Wood_PXXXXXCentorLeftScr_JL: jambLegPXURL("WOOD_PXXXXX_LEFT_CENTOR"),
  Wood_PXXXXXCentorRightScr_JL: jambLegPXURL("WOOD_PXXXXX_RIGHT_CENTOR"),
  Wood_PXXXXXCentorDoubleScr_JL: jambLegPXURL("WOOD_PXXXXX_DOUBLE_CENTOR"),
  Wood_XXXXXPCentorLeftScr_JL: jambLegPXURL("WOOD_XXXXXP_LEFT_CENTOR"),
  Wood_XXXXXPCentorRightScr_JL: jambLegPXURL("WOOD_XXXXXP_RIGHT_CENTOR"),
  Wood_XXXXXPCentorDoubleScr_JL: jambLegPXURL("WOOD_XXXXXP_DOUBLE_CENTOR"),
  Wood_PXXXXX_AXXXXPCentorLeftScr_JL: jambLegPXURL(
    "WOOD_PXXXXX-AXXXXP_LEFT_CENTOR",
  ),
  Wood_PXXXXX_AXXXXPCentorRightScr_JL: jambLegPXURL(
    "WOOD_PXXXXX-AXXXXP_RIGHT_CENTOR",
  ),
  Wood_PXXXXX_AXXXXPCentorDoubleScr_JL: jambLegPXURL(
    "WOOD_PXXXXX-AXXXXP_DOUBLE_CENTOR",
  ),
  Wood_PXXXXA_XXXXXPCentorLeftScr_JL: jambLegPXURL(
    "WOOD_PXXXXA-XXXXXP_LEFT_CENTOR",
  ),
  Wood_PXXXXA_XXXXXPCentorRightScr_JL: jambLegPXURL(
    "WOOD_PXXXXA-XXXXXP_RIGHT_CENTOR",
  ),
  Wood_PXXXXA_XXXXXPCentorDoubleScr_JL: jambLegPXURL(
    "WOOD_PXXXXA-XXXXXP_DOUBLE_CENTOR",
  ),

  PXCentorLeftScr_JL: jambLegPXURL("PX_LEFT_CENTOR"),
  PXCentorRightScr_JL: jambLegPXURL("PX_RIGHT_CENTOR"),
  PXCentorDoubleScr_JL: jambLegPXURL("PX_DOUBLE_CENTOR"),
  XPCentorLeftScr_JL: jambLegPXURL("XP_LEFT_CENTOR"),
  XPCentorRightScr_JL: jambLegPXURL("XP_RIGHT_CENTOR"),
  XPCentorDoubleScr_JL: jambLegPXURL("XP_DOUBLE_CENTOR"),
  PX_APCentorLeftScr_JL: jambLegPXURL("PX-AP_LEFT_CENTOR"),
  PX_APCentorRightScr_JL: jambLegPXURL("PX-AP_RIGHT_CENTOR"),
  PX_APCentorDoubleScr_JL: jambLegPXURL("PX-AP_DOUBLE_CENTOR"),
  PA_XPCentorLeftScr_JL: jambLegPXURL("PA-XP_LEFT_CENTOR"),
  PA_XPCentorRightScr_JL: jambLegPXURL("PA-XP_RIGHT_CENTOR"),
  PA_XPCentorDoubleScr_JL: jambLegPXURL("PA-XP_DOUBLE_CENTOR"),

  PXXCentorLeftScr_JL: jambLegPXURL("PXX_LEFT_CENTOR"),
  PXXCentorRightScr_JL: jambLegPXURL("PXX_RIGHT_CENTOR"),
  PXXCentorDoubleScr_JL: jambLegPXURL("PXX_DOUBLE_CENTOR"),
  XXPCentorLeftScr_JL: jambLegPXURL("XXP_LEFT_CENTOR"),
  XXPCentorRightScr_JL: jambLegPXURL("XXP_RIGHT_CENTOR"),
  XXPCentorDoubleScr_JL: jambLegPXURL("XXP_DOUBLE_CENTOR"),
  PXX_AXPCentorLeftScr_JL: jambLegPXURL("PXX-AXP_LEFT_CENTOR"),
  PXX_AXPCentorRightScr_JL: jambLegPXURL("PXX-AXP_RIGHT_CENTOR"),
  PXX_AXPCentorDoubleScr_JL: jambLegPXURL("PXX-AXP_DOUBLE_CENTOR"),
  PXA_XXPCentorLeftScr_JL: jambLegPXURL("PXA-XXP_LEFT_CENTOR"),
  PXA_XXPCentorRightScr_JL: jambLegPXURL("PXA-XXP_RIGHT_CENTOR"),
  PXA_XXPCentorDoubleScr_JL: jambLegPXURL("PXA-XXP_DOUBLE_CENTOR"),

  PXXXCentorLeftScr_JL: jambLegPXURL("PXXX_LEFT_CENTOR"),
  PXXXCentorRightScr_JL: jambLegPXURL("PXXX_RIGHT_CENTOR"),
  PXXXCentorDoubleScr_JL: jambLegPXURL("PXXX_DOUBLE_CENTOR"),
  XXXPCentorLeftScr_JL: jambLegPXURL("XXXP_LEFT_CENTOR"),
  XXXPCentorRightScr_JL: jambLegPXURL("XXXP_RIGHT_CENTOR"),
  XXXPCentorDoubleScr_JL: jambLegPXURL("XXXP_DOUBLE_CENTOR"),
  PXXX_AXXPCentorLeftScr_JL: jambLegPXURL("PXXX-AXXP_LEFT_CENTOR"),
  PXXX_AXXPCentorRightScr_JL: jambLegPXURL("PXXX-AXXP_RIGHT_CENTOR"),
  PXXX_AXXPCentorDoubleScr_JL: jambLegPXURL("PXXX-AXXP_DOUBLE_CENTOR"),
  PXXA_XXXPCentorLeftScr_JL: jambLegPXURL("PXXA-XXXP_LEFT_CENTOR"),
  PXXA_XXXPCentorRightScr_JL: jambLegPXURL("PXXA-XXXP_RIGHT_CENTOR"),
  PXXA_XXXPCentorDoubleScr_JL: jambLegPXURL("PXXA-XXXP_DOUBLE_CENTOR"),

  PXXXXCentorLeftScr_JL: jambLegPXURL("PXXXX_LEFT_CENTOR"),
  PXXXXCentorRightScr_JL: jambLegPXURL("PXXXX_RIGHT_CENTOR"),
  PXXXXCentorDoubleScr_JL: jambLegPXURL("PXXXX_DOUBLE_CENTOR"),
  XXXXPCentorLeftScr_JL: jambLegPXURL("XXXXP_LEFT_CENTOR"),
  XXXXPCentorRightScr_JL: jambLegPXURL("XXXXP_RIGHT_CENTOR"),
  XXXXPCentorDoubleScr_JL: jambLegPXURL("XXXXP_DOUBLE_CENTOR"),
  PXXXX_AXXXPCentorLeftScr_JL: jambLegPXURL("PXXXX-AXXXP_LEFT_CENTOR"),
  PXXXX_AXXXPCentorRightScr_JL: jambLegPXURL("PXXXX-AXXXP_RIGHT_CENTOR"),
  PXXXX_AXXXPCentorDoubleScr_JL: jambLegPXURL("PXXXX-AXXXP_DOUBLE_CENTOR"),
  PXXXA_XXXXPCentorLeftScr_JL: jambLegPXURL("PXXXA-XXXXP_LEFT_CENTOR"),
  PXXXA_XXXXPCentorRightScr_JL: jambLegPXURL("PXXXA-XXXXP_RIGHT_CENTOR"),
  PXXXA_XXXXPCentorDoubleScr_JL: jambLegPXURL("PXXXA-XXXXP_DOUBLE_CENTOR"),

  PXXXXXCentorLeftScr_JL: jambLegPXURL("PXXXXX_LEFT_CENTOR"),
  PXXXXXCentorRightScr_JL: jambLegPXURL("PXXXXX_RIGHT_CENTOR"),
  PXXXXXCentorDoubleScr_JL: jambLegPXURL("PXXXXX_DOUBLE_CENTOR"),
  XXXXXPCentorLeftScr_JL: jambLegPXURL("XXXXXP_LEFT_CENTOR"),
  XXXXXPCentorRightScr_JL: jambLegPXURL("XXXXXP_RIGHT_CENTOR"),
  XXXXXPCentorDoubleScr_JL: jambLegPXURL("XXXXXP_DOUBLE_CENTOR"),
  PXXXXX_AXXXXPCentorLeftScr_JL: jambLegPXURL("PXXXXX-AXXXXP_LEFT_CENTOR"),
  PXXXXX_AXXXXPCentorRightScr_JL: jambLegPXURL("PXXXXX-AXXXXP_RIGHT_CENTOR"),
  PXXXXX_AXXXXPCentorDoubleScr_JL: jambLegPXURL("PXXXXX-AXXXXP_DOUBLE_CENTOR"),
  PXXXXA_XXXXXPCentorLeftScr_JL: jambLegPXURL("PXXXXA-XXXXXP_LEFT_CENTOR"),
  PXXXXA_XXXXXPCentorRightScr_JL: jambLegPXURL("PXXXXA-XXXXXP_RIGHT_CENTOR"),
  PXXXXA_XXXXXPCentorDoubleScr_JL: jambLegPXURL("PXXXXA-XXXXXP_DOUBLE_CENTOR"),

  PA_XP_JL: jambLegPXURL("PA-XP"),
  PA_XPDoublePocketscr_JL: jambLegPXURL("PA-XP_SCREEN"),
  PX_JL: jambLegPXURL("PX"),
  PXscr_JL: jambLegPXURL("PX_SCREEN"),
  PX_AP_JL: jambLegPXURL("PX-AP"),
  PX_APDoublePocketscr_JL: jambLegPXURL("PX-AP_SCREEN"),
  XP_JL: jambLegPXURL("XP"),
  XPscr_JL: jambLegPXURL("XP_SCREEN"),
  PXXXA_XXXXP_JL: jambLegPXURL("PXXXA-XXXXP"),
  PXXXA_XXXXPDoublePocketscr_JL: jambLegPXURL("PXXXA-XXXXP_SCREEN"),
  PXXXX_JL: jambLegPXURL("PXXXX"),
  PXXXXscr_JL: jambLegPXURL("PXXXX_SCREEN"),
  PXXXX_AXXXP_JL: jambLegPXURL("PXXXX-AXXXP"),
  PXXXX_AXXXPDoublePocketscr_JL: jambLegPXURL("PXXXX-AXXXP_SCREEN"),
  XXXXP_JL: jambLegPXURL("XXXXP"),
  XXXXPscr_JL: jambLegPXURL("XXXXP_SCREEN"),
  PXXA_XXXP_JL: jambLegPXURL("PXXA-XXXP"),
  PXXA_XXXPDoublePocketscr_JL: jambLegPXURL("PXXA-XXXP_SCR"),
  PXXX_JL: jambLegPXURL("PXXX"),
  PXXXscr_JL: jambLegPXURL("PXXX_SCREEN"),
  PXXX_AXXP_JL: jambLegPXURL("PXXX-AXXP"),
  PXXX_AXXPDoublePocketscr_JL: jambLegPXURL("PXXX-AXXP_SCR"),
  XXXP_JL: jambLegPXURL("XXXP"),
  XXXPscr_JL: jambLegPXURL("XXXP_SCREEN"),
  XXPscr_JL: jambLegPXURL("XXP_SCR"),
  XXP_JL: jambLegPXURL("XXP"),
  PXX_AXPDoublePocketscr_JL: jambLegPXURL("PXX-AXP_SCR"),
  PXX_AXP_JL: jambLegPXURL("PXX-AXP"),
  PXXscr_JL: jambLegPXURL("PXX_SCR"),
  PXX_JL: jambLegPXURL("PXX"),
  PXA_XXPDoublePocketscr_JL: jambLegPXURL("PXA-XXP_SCR"),
  PXA_XXP_JL: jambLegPXURL("PXA-XXP"),
  XXXPDoublePocketscr_JL: jambLegPXURL("XXXP_DOUBLE_POCKET_SCREEN_v2"),
  PXXXDoublePocketscr_JL: jambLegPXURL("PXXX_DOUBLE_POCKET_SCREEN_v2"),
  XXXXPDoublePocketscr_JL: jambLegPXURL("XXXXP_DOUBLE_POCKET_SCREEN_v2"),
  PXXXXDoublePocketscr_JL: jambLegPXURL("PXXXX_DOUBLE_POCKET_SCREEN_v2"),

  Wood_PXXXX_AXXXPDoublePocketscr_JL: jambLegPXURL("WOOD_PXXXX-AXXXP_SCREEN"),
  Wood_PXXXX_AXXXP_JL: jambLegPXURL("WOOD_PXXXX-AXXXP"),
  Wood_PXXXA_XXXXPDoublePocketscr_JL: jambLegPXURL("WOOD_PXXXA-XXXXP_SCREEN"),
  Wood_PXXXA_XXXXP_JL: jambLegPXURL("WOOD_PXXXA-XXXXP"),
  Wood_XXXXPscr_JL: jambLegPXURL("WOOD_XXXXP_SCREEN"),
  Wood_XXXXPDoublePocketscr_JL: jambLegPXURL(
    "WOOD_XXXXP_DOUBLE_POCKET_SCREEN_v2",
  ),
  Wood_XXXXP_JL: jambLegPXURL("WOOD_XXXXP"),
  Wood_PXXXXscr_JL: jambLegPXURL("WOOD_PXXXX_SCREEN"),
  Wood_PXXXXDoublePocketscr_JL: jambLegPXURL(
    "WOOD_PXXXX_DOUBLE_POCKET_SCREEN_v2",
  ),
  Wood_PXXXX_JL: jambLegPXURL("WOOD_PXXXX"),
  Wood_PXXX_AXXP_JL: jambLegPXURL("WOOD_PXXX-AXXP"),
  Wood_PXXXscr_JL: jambLegPXURL("WOOD_PXXX_SCREEN"),
  Wood_PXXXDoublePocketscr_JL: jambLegPXURL(
    "WOOD_PXXX_DOUBLE_POCKET_SCREEN_v2",
  ),
  Wood_PXXX_JL: jambLegPXURL("WOOD_PXXX"),
  Wood_PXXA_XXXPDoublePocketscr_JL: jambLegPXURL("WOOD_PXXA-XXXP_SCR"),
  Wood_PXXX_AXXPDoublePocketscr_JL: jambLegPXURL("WOOD_PXXX-AXXP_SCR"),
  Wood_PXXA_XXXP_JL: jambLegPXURL("WOOD_PXXA-XXXP"),
  Wood_PXX_AXPDoublePocketscr_JL: jambLegPXURL("WOOD_PXX-AXP_SCR"),
  Wood_PXX_AXP_JL: jambLegPXURL("WOOD_PXX-AXP"),
  Wood_PXXscr_JL: jambLegPXURL("WOOD_PXX_SCR"),
  Wood_PXA_XXPDoublePocketscr_JL: jambLegPXURL("WOOD_PXA-XXP_SCR"),
  Wood_PXX_JL: jambLegPXURL("WOOD_PXX"),
  Wood_PXA_XXP_JL: jambLegPXURL("WOOD_PXA-XXP"),
  Wood_XXPscr_JL: jambLegPXURL("WOOD_XXP_SCR"),
  Wood_XXP_JL: jambLegPXURL("WOOD_XXP"),
  Wood_PX_APDoublePocketscr_JL: jambLegPXURL("WOOD_PX-AP_SCREEN"),
  Wood_PX_AP_JL: jambLegPXURL("WOOD_PX-AP"),
  Wood_PXscr_JL: jambLegPXURL("WOOD_PX_SCREEN"),
  Wood_PX_JL: jambLegPXURL("WOOD_PX"),
  Wood_PA_XPDoublePocketscr_JL: jambLegPXURL("WOOD_PA-XP_SCREEN"),
  Wood_PA_XP_JL: jambLegPXURL("WOOD_PA-XP"),
  Wood_XPscr_JL: jambLegPXURL("WOOD_XP_SCREEN"),
  Wood_XP_JL: jambLegPXURL("WOOD_XP"),
  Wood_XXXPscr_JL: jambLegPXURL("WOOD_XXXP_SCREEN"),
  Wood_XXXPDoublePocketscr_JL: jambLegPXURL(
    "WOOD_XXXP_DOUBLE_POCKET_SCREEN_v2",
  ),
  Wood_XXXP_JL: jambLegPXURL("WOOD_XXXP"),
};
