export function calculatePanelHeight(
  exactConfig: string,
  doorType: string,
  sillStyle: string,
  netHeight: number,
  screenType: string,
  material: string,
  swingDirection?: string,
) {
  var panelHeight = 0;

  if (doorType === "Pivot") {
    //USED TO BE 1.75
    // panelHeight = netHeight - 1.875;
    panelHeight = netHeight - 1.9375;
  } else if (doorType === "Fixed Panel") {
    if (exactConfig === "Fixed Bi-fold") {
      panelHeight = netHeight - 2 - 0.3125 - 0.3125 - 0.9375;
    } else if (exactConfig === "Fixed Slider") {
      panelHeight = netHeight - 2;
    } else if (exactConfig === "Fixed Full Bound") {
      panelHeight = netHeight - 1.75;
    } else if (exactConfig === "Fixed Inswing") {
      const jambLeg = material === "All Wood" ? 2 : 2.0625;
      panelHeight = netHeight - jambLeg;
    } else if (exactConfig === "Fixed Outswing") {
      panelHeight = netHeight - 1.5;
    }
  } else if (doorType.includes("Bi-fold")) {
    const sub = screenType?.includes("Centor") ? 3.25 : 2;

    if (sillStyle !== "Ultra Guide" && sillStyle !== "ADA Ultra Guide") {
      panelHeight = netHeight - sub - 0.3125 - 0.3125 - 0.9375;
    } else if (
      sillStyle &&
      (sillStyle === "Ultra Guide" || sillStyle === "ADA Ultra Guide")
    ) {
      panelHeight = netHeight - sub - 0.3125 - 0.4375;
    }
  } else if (doorType === "Swing") {
    const sub = screenType?.includes("Centor") ? 2.5 : 0;

    const inswingSub = material === "All Wood" ? 2.0937 : 2.2812;

    panelHeight =
      swingDirection === "Outswing"
        ? netHeight - sub - 1.75
        : swingDirection === "Inswing"
          ? netHeight - inswingSub
          : 0;
  } else if (doorType === "Slider") {
    const sub = screenType?.includes("Centor") ? 3.125 : 0;

    if (!sillStyle || sillStyle === "Raised SILLution") {
      panelHeight = netHeight - sub - 0.875 - 0.25 - 0.25 - 1;
    } else if (sillStyle && sillStyle !== "Raised SILLution") {
      panelHeight = netHeight - sub - 0.875 - 0.25 - 0.25;
    }
  }

  return panelHeight;
}
