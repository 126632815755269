const stain = [
  {
    id: 1,
    type: "stain",
    label: "Add Clear Coat",
  },
  {
    id: 2,
    type: "stain",
    label: "Add Standard Stain",
  },
  {
    id: 3,
    type: "customPaintOrStain",
    label: "Add Custom Stain",
  },
];

const stains = [
  { id: 1, type: "stainColor", label: "#225" },
  { id: 2, type: "stainColor", label: "Cimmaron" },
  { id: 3, type: "stainColor", label: "Dark Walnut" },
  { id: 4, type: "stainColor", label: "Natural" },
  { id: 5, type: "stainColor", label: "Jacobean" },
];

export { stain, stains };
