import React, { FC } from "react";
import { Switch, FormControl, FormControlLabel } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledFormControl = styled(FormControl)({
  paddingTop: 10,
  paddingBottom: 40,
});

interface CustomGlassSwitchProps {
  checked: boolean;
  handleCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomGlassSwitch: FC<CustomGlassSwitchProps> = ({
  checked,
  handleCheck,
}) => {
  return (
    <StyledFormControl>
      <FormControlLabel
        control={
          <Switch color="primary" checked={checked} onChange={handleCheck} />
        }
        label={checked ? "Custom Glass" : "No Custom Glass"}
      />
    </StyledFormControl>
  );
};

export default CustomGlassSwitch;
