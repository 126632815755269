import React, { useEffect, useState, FC } from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import getModalStyle from "../../../../util/getModalStyle";
import { getGlassOrderSheet } from "../../../../api/DzoneApi";
import { QuoteObj, UserObj, Overwrite } from "../../../../GlobalTypes";

import { styled } from "@mui/material/styles";
import { StyledModalDiv } from "../../../../util/accordianStyles";

type AdminQuoteObj = Overwrite<QuoteObj, { userId: UserObj }>;

interface GlassOrderModalProps {
  open: boolean;
  handleClose: () => void;
  quote: AdminQuoteObj[] | undefined;
}

interface CustomUnits {
  quoteNumber: number;
  salesOrder: string;
  partNumber: number;
  type?: string;
}

const StyledGlassModal = styled("div")(({ theme }) => ({
  position: "absolute",
  width: 400,
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  padding: 20,
  height: 400,
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledDivColEven = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",
  height: 200,
});

const StyledDivRowSpace = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  width: "100%",
  paddingTop: 10,
});

const StyledDivColCenter = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  margin: 10,
  height: 200,
});

const StyledDivFlex = styled("div")({
  height: 100,
  flex: "1 0 auto",
  overflow: "auto",
  margin: 10,
  border: "2px solid black",
  width: 300,
});

const StyledTypographyRed = styled(Typography)({
  color: "red",
  fontWeight: "bold",
  padding: 5,
});

const StyledButton = styled(Button)({
  backgroundColor: "#ee3727",
  // marginTop: 20,
});

const GlassOrderModal: FC<GlassOrderModalProps> = ({
  open,
  handleClose,
  quote,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const [date, setDate] = useState("");

  const [customUnits, setCustomUnits] = useState<CustomUnits[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setDate(value);
  };

  useEffect(() => {
    if (open) return;
    setDate("");
    setCustomUnits([]);
  }, [open]);

  useEffect(() => {
    if (!quote || !quote.length || !date) return;

    async function getGlassData() {
      const quoteIds = quote?.map((q) => q._id);
      try {
        if (!quoteIds) throw new Error("no quote ids");

        const res = await getGlassOrderSheet(quoteIds, date);

        if (!res.data) return;

        if (!res.data.glassData.length) {
          return setCustomUnits(res.data.customGlass);
        }

        // Convert base64-encoded buffer to Uint8Array
        const uint8Array = new Uint8Array(
          atob(res.data.glassData)
            .split("")
            .map((char) => char.charCodeAt(0)),
        );

        // Create a Blob and trigger the download
        const excelBlob = new Blob([uint8Array], {
          type: "text/csv",
        });

        const today = new Date();

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(excelBlob);
        link.download = `GlassOrder${today.toLocaleDateString("en-US")}.csv`;
        link.click();

        setCustomUnits(res.data.customGlass);
      } catch (err) {
        console.log(err);
      }
    }

    getGlassData();
  }, [quote, date]);

  const bodyCustom = (
    <StyledGlassModal style={modalStyle}>
      <StyledDivColEven>
        <StyledDivRowSpace>
          <Typography style={{ paddingRight: 20 }}>Ship Date:</Typography>
          <input value={date} onChange={handleChange} name="date" type="date" />
        </StyledDivRowSpace>

        {customUnits.length ? (
          <StyledDivColCenter>
            <Typography style={{ color: "red" }}>
              *Custom Glass (CG) and/or TDL was selected for the following units
              and will not be added to Glass Order Sheet:{" "}
            </Typography>

            <StyledDivFlex>
              {customUnits.map((item, index) => (
                <StyledTypographyRed
                  key={index}
                  variant="subtitle2"
                  style={{
                    borderBottom:
                      index + 1 !== customUnits.length ? "1px solid black" : "",
                  }}
                >
                  SO - {item.salesOrder}, {item.partNumber} ({item.type})
                </StyledTypographyRed>
              ))}
            </StyledDivFlex>
          </StyledDivColCenter>
        ) : null}

        <StyledButton onClick={handleClose} size="small" variant="contained">
          Close
        </StyledButton>
      </StyledDivColEven>
    </StyledGlassModal>
  );

  const body = (
    <StyledModalDiv style={modalStyle}>
      <StyledDivColEven>
        <StyledDivRowSpace>
          <Typography style={{ paddingRight: 20 }}>Ship Date:</Typography>
          <input value={date} onChange={handleChange} name="date" type="date" />
        </StyledDivRowSpace>

        {customUnits.length ? (
          <StyledDivColCenter>
            <Typography style={{ color: "red" }}>
              *Custom Glass (CG) and/or TDL was selected for the following units
              and will not be added to Glass Order Sheet:{" "}
            </Typography>

            <StyledDivFlex>
              {customUnits.map((item, index) => (
                <StyledTypographyRed
                  key={index}
                  variant="subtitle2"
                  style={{
                    borderBottom:
                      index + 1 !== customUnits.length ? "1px solid black" : "",
                  }}
                >
                  SO - {item.salesOrder}, {item.partNumber} ({item.type})
                </StyledTypographyRed>
              ))}
            </StyledDivFlex>
          </StyledDivColCenter>
        ) : null}

        <StyledButton onClick={handleClose} size="small" variant="contained">
          Close
        </StyledButton>
      </StyledDivColEven>
    </StyledModalDiv>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      {!customUnits.length ? body : bodyCustom}
    </Modal>
  );
};

export default GlassOrderModal;
