import React, { useState, FC } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Star, ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Typography,
  Slide,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import getModalStyle from "../../util/getModalStyle";
import { styled } from "@mui/material";

const StyledModalDiv = styled("div")(({ theme }) => ({
  position: "absolute",
  width: 400,
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  textAlign: "center",
}));

const StyledTitleDiv = styled("div")({
  padding: 20,
  backgroundColor: "#2a2a72",
  backgroundImage: "linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)",
});

const StyledTitleTypography = styled(Typography)({
  color: "white",
  fontWeight: "bold",
});

const StyledHideDiv = styled("div")({
  display: "flex",
  overflow: "hidden",
  height: 420,
  justifyContent: "center",
});

const StyledColDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: 10,
  padding: 10,
});

const StyledRowDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 10,
  paddingTop: 20,
});

const StyledRowDivCenter = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledStarIcon = styled(Star)({
  alignSelf: "flex-start",
  color: "gold",
  fontSize: 20,
});

const StyledImgWrapDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 10,
  height: 320,
});

const CLOUDINARY_URL = "https://res.cloudinary.com/ag-millworks/image/upload";

interface AgTipsProps {
  handleClose: (check: boolean) => void;
  open: boolean;
}

type Dir = "left" | "right" | "up" | "down" | undefined;

const AgTipsModal: FC<AgTipsProps> = ({ handleClose, open }) => {
  const [modalStyle] = useState(() => getModalStyle(12, 40));

  const [page, setPage] = useState(0);

  const [direction, setDir] = useState<Dir>("right");

  const handlePageChange = (newPage: number, dir: Dir) => {
    setDir(dir);
    setPage((oldPage) => oldPage + newPage);
  };

  const [checked, setChecked] = useState(false);

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) =>
    setChecked(e.target.checked);

  const body = (
    <StyledModalDiv style={modalStyle}>
      <StyledTitleDiv>
        <StyledTitleTypography variant="h5">AG Tips</StyledTitleTypography>
      </StyledTitleDiv>

      <StyledHideDiv>
        <Slide in={page === 0} direction="right">
          <div>
            {page === 0 && (
              <Tip
                text="Bi-fold Corners now available! Click the blue arrow in the configurator to switch between bifold types."
                src={`${CLOUDINARY_URL}/Bifold_Corner_Tip.gif`}
              />
            )}
          </div>
        </Slide>

        <Slide in={page === 1} direction={direction}>
          <div>
            {page === 1 && (
              <Tip
                page={1}
                text="Our virtual assistant will check for any possible mismatched options in your cart so you can easily correct them if needed."
                src={`${CLOUDINARY_URL}/millie_intro2.gif`}
              />
            )}
          </div>
        </Slide>

        <Slide in={page === 2} direction={direction}>
          <div>
            {page === 2 && (
              <Tip
                text="You can re-arrange the order of your cart using Drag and Drop"
                src={`${CLOUDINARY_URL}/Re-order_Cart.gif`}
              />
            )}
          </div>
        </Slide>

        <Slide in={page === 3} direction={direction}>
          <div>
            {page === 3 && (
              <Tip
                text="You can hide pricing entirely with Over the Shoulder Mode (OTSM) and add a fixed margin in your Account Settings"
                src={`${CLOUDINARY_URL}/tip1.jpg`}
              />
            )}
          </div>
        </Slide>

        <Slide in={page === 4} direction="left">
          <div>
            {page === 4 && (
              <Tip
                text='Remember to set printer settings to "Fit to paper" for best results when printing PDF details'
                src={`${CLOUDINARY_URL}/print_scale.jpg`}
              />
            )}
          </div>
        </Slide>
      </StyledHideDiv>

      <div>
        <StyledRowDivCenter>
          <ChevronLeft
            onClick={() => page !== 0 && handlePageChange(-1, "right")}
            style={{ fontSize: 30, opacity: page === 0 ? 0.4 : 1 }}
            color={page === 0 ? "inherit" : "primary"}
          />
          <ChevronRight
            onClick={() => page !== 4 && handlePageChange(1, "left")}
            style={{ fontSize: 30, opacity: page === 4 ? 0.4 : 1 }}
            color={page === 4 ? "inherit" : "primary"}
          />
        </StyledRowDivCenter>

        <Typography variant="subtitle2">Tip {page + 1} of 5</Typography>
      </div>

      <StyledColDiv>
        <FormGroup style={{ paddingBottom: 10 }}>
          <FormControlLabel
            control={<Checkbox onChange={handleCheck} value={checked} />}
            label="Don't Show This Tip Again"
          />
        </FormGroup>

        <Button
          color="primary"
          onClick={() => handleClose(checked)}
          variant="contained"
          size="small"
        >
          Close
        </Button>
      </StyledColDiv>
    </StyledModalDiv>
  );

  return (
    <Modal
      open={open}
      onClose={() => handleClose(checked)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        {body}
      </Slide>
    </Modal>
  );
};

interface TipProps {
  text: string;
  src: string;
  page?: number;
}

const Tip: FC<TipProps> = ({ text, src, page }) => {
  const imageStyle =
    page === 0
      ? { border: "1px solid black", width: 375, height: 310 }
      : { border: "1px solid black" };

  return (
    <>
      <StyledRowDiv>
        <StyledStarIcon />
        <Typography>{text}</Typography>
      </StyledRowDiv>

      <StyledImgWrapDiv>
        <img style={imageStyle} src={src} alt="AG Tip" />
      </StyledImgWrapDiv>
    </>
  );
};

export default AgTipsModal;
