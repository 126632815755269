import { useState, useEffect, useRef } from "react";

function usePersistedState(
  key: string,
  defaultValue: any,
  submitPressed?: boolean,
  editQuote?: boolean,
) {
  const item = localStorage.getItem(key);

  const [state, setState] = useState(() =>
    item ? JSON.parse(item) : defaultValue,
  );

  const initialRef = useRef(true);

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false;
    } else {
      if (item) {
        setState(() => JSON.parse(item));
      }
    }
  }, [editQuote]);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  useEffect(() => {
    if (!submitPressed) return;
    if (key !== "store") setState(defaultValue);
  }, [submitPressed]);

  return [state, setState];
}

export default usePersistedState;
