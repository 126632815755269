import React, { FC } from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "./Styles";
import litesCloudinaryImg from "../../Drawings/LitesLayout";
import {
  findPocketImg,
  findScreenImg,
  findSlidingScreenImg,
  findCornerPocketImg,
  findCornerScreenImg,
} from "../../Drawings/Screens";
import letters from "../../util/letters";
import getCornerImgWidth from "../../util/getCornerImgWidth";
import noAccess from "../../util/noAccess";
import { convertDecimalToFraction } from "../../util/pdfFormulas/convertDecimalToFraction";
import fractionToInt from "../../util/fractionToInt";
import { QuoteObj } from "../../GlobalTypes";
import { UnitObj } from "../../util/quoteCartStorage";
import "../fontRegister";

const specifyPrimary = [
  "3L1R",
  "1L3R",
  "5L1R",
  "1L5R",
  "3L3R",
  "7L1R",
  "1L7R",
  "5L3R",
  "3L5R",
];

interface UnitDetailsProps {
  form: QuoteObj;
  door: UnitObj;
  index: number;
  isCustomer?: boolean;
  bifoldSeries2?: boolean;
  isProduction?: boolean;
  series?: string;
}

const UnitDetails: FC<UnitDetailsProps> = ({
  form,
  door,
  index,
  isCustomer,
  bifoldSeries2,
  isProduction,
  series,
}) => {
  const screenImg = door.doorType.includes("Corner")
    ? findCornerScreenImg(
        door.addScreen || "",
        door.screenPocket || "",
        door.cornerType,
      )
    : findScreenImg(
        door.addScreen || "",
        door.screenPocket || "",
        door.screenLayout || "",
      );

  const pocketImg = door.doorType.includes("Corner")
    ? findCornerPocketImg(
        door.addScreen || "",
        door.screenPocket || "",
        door.cornerType,
      )
    : findPocketImg(
        door.addScreen || "",
        door.screenPocket || "",
        door.screenLayout || "",
      );

  const slidingScreenImg =
    door.doorType === "Slider" &&
    door.screenType === "Sliding Screen" &&
    findSlidingScreenImg(door.addSlidingScreen || "", door.exactConfig);

  const isCorner = door.doorType.includes("Corner");

  const hasExtAccess = noAccess.includes(door.exactConfig);

  const withScreen =
    door.addScreen && door.swingDirection !== "Inswing" ? true : false;

  const pocketingSlider =
    door.doorType === "Slider" && door.sliderPocket === "With Pocket"
      ? true
      : false;

  const windowOrEvenConfig =
    door.doorType === "Bi-fold Window" || (!isCorner && hasExtAccess)
      ? true
      : isCorner &&
          hasExtAccess &&
          noAccess.includes(door.cornerConfigRight || "")
        ? true
        : false;
  const noWindowAndNoEvenConfig =
    door.doorType !== "Bi-fold Window" && !isCorner && !hasExtAccess
      ? true
      : isCorner &&
          (!hasExtAccess || !noAccess.includes(door.cornerConfigRight || ""))
        ? true
        : false;

  const sideliteDiffLites =
    (door.doorType === "Swing" || door.doorType === "Pivot") &&
    door.addDL &&
    door.diffSideliteSdl &&
    door.dlLayoutSidelite
      ? true
      : false;

  const sideliteImg =
    sideliteDiffLites &&
    litesCloudinaryImg(door.dlLayoutSidelite || "", door.addDL);

  const cladStops =
    door.stickingStops && door.material !== "All Wood" ? true : false;

  const woodStops =
    door.stickingStopsExterior &&
    door.stickingStopsInterior &&
    door.material === "All Wood"
      ? true
      : false;

  const withPaint = door.paintAndPrime && !door.prime ? true : false;

  const withPrime = !door.paintAndPrime && door.prime ? true : false;

  const withCustomPaint =
    door.customPaintOrStain === "Add Custom Paint" &&
    !door.paintAndPrime &&
    !door.prime
      ? true
      : false;

  const withDividedLites =
    door.addDL && door.dlLayout && door.liteConfig ? true : false;

  const liteImg =
    withDividedLites && litesCloudinaryImg(door.dlLayout || "", door.addDL);

  const flashingFinCustomSetback =
    door.flashingFin &&
    door.flashingFinConfig === "Custom Setback" &&
    door.finSetback &&
    door.sliderPocket !== "With Pocket"
      ? true
      : false;

  const flashingFin =
    door.flashingFin &&
    (door.flashingFinConfig === 'Standard 7/8" Setback' ||
      door.flashingFinConfig === 'Standard 1" Setback') &&
    door.sliderPocket !== "With Pocket"
      ? true
      : false;

  const kerfCustomSetback =
    door.kerf && door.kerfConfig === "Custom Setback" && door.kerfSetback
      ? true
      : false;

  const kerf =
    door.kerf && door.kerfConfig === 'Standard 3/8" Setback' ? true : false;

  const swingWithSidelite =
    (door.doorType === "Swing" || door.doorType === "Pivot") &&
    door.sideLite !== "none" &&
    door.sideLiteDLO
      ? true
      : false;

  const withHandle =
    door.withHandle === "Add Handle Set" &&
    !hasExtAccess &&
    !isCorner &&
    door.doorType !== "Slider"
      ? true
      : isCorner &&
          door.withHandle === "Add Handle Set" &&
          (!hasExtAccess || !noAccess.includes(door.cornerConfigRight || ""))
        ? true
        : false;

  return (
    <>
      <View style={styles.unitDetailsContain}>
        <View style={[styles.row]}>
          <View style={[styles.column, { paddingRight: 40 }]}>
            <Text style={styles.textBold}>Project Name:</Text>

            <Text style={styles.textBold}>Unit Location:</Text>

            <Text style={styles.textBold}>Unit #:</Text>
          </View>

          <View style={styles.column}>
            {form.jobName ? (
              <Text style={styles.text}>{form.jobName}</Text>
            ) : (
              <Text style={styles.text}>None</Text>
            )}

            {door.location ? (
              <Text style={styles.text}>{door.location}</Text>
            ) : (
              <Text style={styles.text}>None</Text>
            )}

            <Text style={styles.textBold}>{letters[index]}</Text>
          </View>
        </View>

        <View
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {door.doorType === "Slider" ? (
            <Image
              style={{
                height: door.sliderPocket !== "With Pocket" ? 120 : 110,
                width: door.sliderPocket !== "With Pocket" ? 180 : 200,
              }}
              src={door.exactConfigImg}
            />
          ) : door.doorType === "Bi-fold Zero Corner" ? (
            <View
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Image
                style={{
                  height: Number(door.numberOfPanels) <= 10 ? 80 : 50,
                  width:
                    Number(door.numberOfPanels) <= 10
                      ? getCornerImgWidth(80, door.exactConfig)
                      : getCornerImgWidth(50, door.exactConfig),
                  paddingRight: 5,
                }}
                src={door.exactConfigImg}
              />
              <Image
                style={{
                  height: Number(door.numberOfPanels) <= 10 ? 80 : 50,
                  width:
                    Number(door.numberOfPanels) <= 10
                      ? getCornerImgWidth(80, door.cornerConfigRight || "")
                      : getCornerImgWidth(50, door.cornerConfigRight || ""),
                }}
                src={door.exactConfigImgRightCorner}
              />
            </View>
          ) : door.doorType === "Bi-fold Window" ? (
            <Image
              style={{ height: 80, width: 150 }}
              src={door.exactConfigImg}
            />
          ) : door.doorType === "Fixed Panel" ? (
            <Image
              style={{ height: 120, width: 50 }}
              src={door.exactConfigImg}
            />
          ) : (
            <Image
              style={{ height: 120, width: 150 }}
              src={door.exactConfigImg}
            />
          )}

          {door.doorType.includes("Corner") ? (
            <Text style={styles.text}>
              Folds Open: Bi-fold{" "}
              <Text style={[styles.text, { textDecoration: "underline" }]}>
                {door.cornerType} Corner
              </Text>{" "}
              {door.exactConfigFull} {door.exactConfigFullRightCorner} (
              {`Primary Door: ${door.primaryDoor}`})
            </Text>
          ) : door.doorType.includes("Bi-fold") &&
            specifyPrimary.includes(door.exactConfig) &&
            door.primaryDoor ? (
            <Text style={styles.text}>
              Folds Open: {door.doorType} {door.exactConfigFull} (
              {`Primary Door: ${door.primaryDoor}`})
            </Text>
          ) : door.doorType.includes("Bi-fold") ? (
            <Text style={styles.text}>
              Folds Open: {door.doorType} {door.exactConfigFull}
            </Text>
          ) : door.doorType === "Slider" ? (
            <Text style={styles.text}>Slides Open: {door.exactConfig}</Text>
          ) : door.doorType === "Swing" ? (
            <Text style={styles.text}>
              {door.swingDirection === "Outswing"
                ? "Swings Out:"
                : "Swings In:"}{" "}
              {door.exactConfig.split(" ").splice(1).join(" ")}{" "}
              {door.equalPanels ? "Equal" : ""}
            </Text>
          ) : door.doorType === "Fixed Panel" ? (
            <Text style={styles.text}>
              {door.doorType} {door.exactConfig.replace("Fixed ", "")}
            </Text>
          ) : door.doorType === "Pivot" ? (
            <Text style={styles.text}>
              {door.exactConfig} {door.equalPanels ? "Equal" : ""}
            </Text>
          ) : (
            <Text></Text>
          )}

          <Text style={styles.pageAndDate2}>As Viewed From Exterior</Text>
        </View>

        <View
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            // marginLeft: 40,
            position: "relative",
          }}
        >
          <View style={[styles.column, { paddingRight: 20 }]}>
            <Text style={styles.pageAndDate2}>U-Factor:</Text>
            <Text style={styles.pageAndDate2}>SHGC:</Text>
            {door.cpd && isProduction ? (
              <Text style={styles.pageAndDate2}>CPD#:</Text>
            ) : (
              <Text />
            )}
            {door.doorType === "Bi-fold Window" ? (
              <Text style={styles.pd3Bold}>Daily Sash:</Text>
            ) : (
              <Text style={styles.pd3Bold}>Daily Door:</Text>
            )}
          </View>

          <View style={[styles.column, { position: "relative" }]}>
            <Text style={styles.pageAndDate2}>{door.ufactor}</Text>

            <Text style={styles.pageAndDate2}>{door.shgc}</Text>

            {door.cpd && isProduction ? (
              <Text style={styles.pageAndDate2}>{door.cpd}</Text>
            ) : (
              <Text />
            )}

            {door.doorType === "Bi-fold" ? (
              <Text style={styles.pd3}>
                {noAccess.includes(door.exactConfig) ? "No" : "Yes"}
              </Text>
            ) : door.doorType === "Bi-fold Zero Corner" ? (
              <Text style={styles.pd3}>
                {noAccess.includes(door.exactConfig) &&
                noAccess.includes(door.cornerConfigRight || "")
                  ? "No"
                  : "Yes"}
              </Text>
            ) : door.doorType === "Bi-fold Window" ||
              door.doorType === "Fixed Panel" ? (
              <Text style={styles.pd3}>No</Text>
            ) : (
              <Text />
            )}

            {door.doorType === "Slider" ? (
              <Text style={styles.pd3}>
                {!door.exactConfig.includes("-")
                  ? "Yes"
                  : door.exactConfig.split("-")[0].includes("A")
                    ? "Left"
                    : door.exactConfig.split("-")[1].includes("A")
                      ? "Right"
                      : ""}
              </Text>
            ) : (
              <Text />
            )}

            {(door.doorType === "Swing" || door.doorType === "Pivot") &&
            door.exactConfig.slice(-2) === "LH" ? (
              <Text style={styles.pd3}>Left Hinged</Text>
            ) : (door.doorType === "Swing" || door.doorType === "Pivot") &&
              door.exactConfig.slice(-2) === "RH" ? (
              <Text style={styles.pd3}>Right Hinged</Text>
            ) : (
              <Text></Text>
            )}
          </View>

          <View style={styles.column}>
            {Number(door.ufactor) <= 0.3 && Number(door.shgc) <= 0.23 ? (
              <Image
                style={{ height: 40, width: 40, marginLeft: 20 }}
                src="https://res.cloudinary.com/ag-millworks/image/upload/v1635952716/title24.png"
              />
            ) : (
              <Text></Text>
            )}
          </View>
        </View>
      </View>

      <View style={styles.innerRow1}>
        <View style={styles.column}>
          <View style={styles.row}>
            <View style={styles.innerColumn1}>
              <View style={{ paddingTop: 20, position: "relative" }}>
                <Text style={styles.textBold}>Net Frame Width:</Text>

                <Text style={styles.textBold}>Net Frame Height:</Text>

                {pocketingSlider ? (
                  <Text style={styles.textBold}>Daylight Frame Width:</Text>
                ) : (
                  <Text></Text>
                )}

                {pocketingSlider ? (
                  <Text style={styles.textBold}>Per Pocket Width:</Text>
                ) : (
                  <Text></Text>
                )}

                <Text style={styles.textBold}>Rec'd RO Width:</Text>

                <Text style={styles.textBold}>Rec'd RO Height:</Text>

                {door.doorType === "Swing" || door.doorType === "Pivot" ? (
                  <Text style={styles.textBold}>Sidelite Width:</Text>
                ) : (
                  <Text></Text>
                )}

                {door.doorType === "Bi-fold Window" ? (
                  <>
                    <Text style={styles.textBold}>Sash Width:</Text>
                    <Text style={styles.textBold}>Sash Height:</Text>
                  </>
                ) : (
                  <>
                    <Text style={styles.textBold}>Panel Width:</Text>
                    <Text style={styles.textBold}>Panel Height:</Text>
                  </>
                )}

                <Text style={styles.textBold}>Panel Thickness:</Text>

                <Text style={styles.textBold}>Frame Weight:</Text>

                <Text style={styles.textBold}>Per Panel Weight:</Text>

                {door.doorType.includes("Bi-fold") ? (
                  <Text style={styles.textBold}>Dead Load Weight:</Text>
                ) : (
                  <Text></Text>
                )}
              </View>

              <View style={{ paddingTop: 20, position: "relative" }}>
                <Text style={styles.textBold}>Product:</Text>

                <Text style={styles.textBold}>Material:</Text>

                {door.material === "Clad Exterior/Wood Interior" &&
                door.exteriorCladColor ? (
                  <Text style={styles.textBold}>Clad Color:</Text>
                ) : (
                  <Text></Text>
                )}

                <Text style={styles.textBold}>Wood Species:</Text>

                <Text style={styles.textBold}>Wood Finish:</Text>

                <Text style={styles.textBold}>Quantity:</Text>

                <Text style={styles.textBold}>Configuration:</Text>

                {door.doorType === "Swing" || door.doorType === "Pivot" ? (
                  <Text style={styles.textBold}>Sidelite:</Text>
                ) : (
                  <Text></Text>
                )}

                <Text style={styles.textBold}>Stiles and Rails:</Text>

                {door.doorType !== "Slider" ? (
                  <Text style={styles.textBold}>Swing Direction:</Text>
                ) : (
                  <Text></Text>
                )}

                <Text style={styles.textBold}>Sill Type:</Text>
              </View>

              <View style={{ paddingTop: 40, position: "relative" }}>
                {flashingFin || flashingFinCustomSetback ? (
                  <>
                    <Text style={styles.textBold}>Flashing Fin:</Text>
                    <Text style={styles.textBold}>Flashing Fin Setback:</Text>
                  </>
                ) : (
                  <Text />
                )}

                {withScreen && !slidingScreenImg ? (
                  <Text style={styles.textBold}>Screen:</Text>
                ) : slidingScreenImg ? (
                  <Text style={styles.textBold}>Screen:</Text>
                ) : (
                  <Text />
                )}

                {(door.addScreen || slidingScreenImg) &&
                door.swingDirection !== "Inswing" &&
                door.screenColor ? (
                  <Text style={styles.textBold}>Screen Extrusion:</Text>
                ) : (
                  <Text />
                )}

                {door.addScreen &&
                door.screenType?.includes("Centor") &&
                door.shadeMeshColor &&
                (door.addScreen.includes("Shade") ||
                  door.addScreen === "Add Combo Screen/Shade") ? (
                  <Text style={styles.textBold}>Shade Mesh Color:</Text>
                ) : (
                  <Text />
                )}

                {withScreen && door.screenPocket ? (
                  <Text style={styles.textBold}>Screen Pocket:</Text>
                ) : (
                  <Text />
                )}

                <View style={styles.column}>
                  {slidingScreenImg ? (
                    <Image
                      style={{ width: 80, height: 40 }}
                      src={slidingScreenImg}
                    />
                  ) : (
                    <Text></Text>
                  )}

                  {pocketImg && door.swingDirection !== "Inswing" ? (
                    <Image style={{ width: 80, height: 40 }} src={pocketImg} />
                  ) : !pocketImg &&
                    screenImg &&
                    door.swingDirection !== "Inswing" ? (
                    <Image style={{ width: 80, height: 40 }} src={screenImg} />
                  ) : (
                    <Text></Text>
                  )}

                  {(door.addScreen || slidingScreenImg) &&
                  door.swingDirection !== "Inswing" ? (
                    <Text style={{ fontSize: 6, paddingLeft: 10 }}>
                      As Viewed From Exterior
                    </Text>
                  ) : (
                    <Text></Text>
                  )}
                </View>

                {(door.sliderPocket === "With Pocket" &&
                  door.exactConfig.includes("-")) ||
                (door.sliderPocket === "With Pocket" &&
                  !door.exactConfig.includes("-") &&
                  !door.addScreen?.includes("Double")) ? (
                  <Text />
                ) : pocketImg &&
                  door.sliderPocket === "With Pocket" &&
                  !door.exactConfig.includes("-") &&
                  door.addScreen?.includes("Double") ? (
                  <Text style={[styles.textBold, { marginTop: 10 }]}>
                    Net Frame Width w/ Screen Pkt:
                  </Text>
                ) : pocketImg && door.swingDirection !== "Inswing" ? (
                  <Text style={[styles.textBold, { marginTop: 10 }]}>
                    Net Frame Width w/ Pkt:
                  </Text>
                ) : (
                  <Text></Text>
                )}
              </View>
            </View>

            <View style={styles.innerColumn2}>
              <View style={{ paddingTop: 20, position: "relative" }}>
                {door.doorType.includes("Corner") ? (
                  <Text style={styles.text}>
                    <Text style={styles.textBold}>L:</Text> {door.unitSize}
                    {door.unitSizeFraction
                      ? ` ${door.unitSizeFraction}"`
                      : '"'}{" "}
                    <Text style={styles.textBold}> R:</Text>{" "}
                    {door.cornerWidthRight}
                    {door.cornerWidthFractionRight
                      ? ` ${door.cornerWidthFractionRight}"`
                      : '"'}
                  </Text>
                ) : door.sliderPocket !== "With Pocket" ? (
                  <Text style={styles.text}>
                    {door.unitSize}
                    {door.unitSizeFraction ? ` ${door.unitSizeFraction}"` : '"'}
                  </Text>
                ) : (
                  <Text style={styles.text}>
                    {door.pocketingSliderNetWidth}"
                  </Text>
                )}

                <Text style={styles.text}>
                  {door.height && door.heightFraction
                    ? `${door.height} ${door.heightFraction}"`
                    : `${door.height}"`}{" "}
                  {(door.doorType.includes("Bi-fold") &&
                    door.sillStyle?.includes("Ultra")) ||
                  (door.doorType === "Slider" &&
                    door.sillStyle?.includes("Steel")) ? (
                    <Text style={[styles.text, { color: "red" }]}>
                      (From Finished Floor)
                    </Text>
                  ) : (
                    `(From Bottom of Sill)`
                  )}
                </Text>

                {pocketingSlider ? (
                  <Text style={styles.text}>
                    {door.unitSize}
                    {door.unitSizeFraction ? ` ${door.unitSizeFraction}"` : '"'}
                  </Text>
                ) : (
                  <Text></Text>
                )}

                {pocketingSlider ? (
                  <Text style={styles.text}>{door.sliderPocketSize}"</Text>
                ) : (
                  <Text></Text>
                )}

                {door.doorType.includes("Corner") ? (
                  <Text style={styles.text}>
                    <Text style={styles.textBold}>L:</Text> {door.roWidth}"{" "}
                    <Text style={styles.textBold}> R:</Text>{" "}
                    {door.roWidthRightCorner}"
                  </Text>
                ) : (
                  <Text style={styles.text}>{door.roWidth}"</Text>
                )}

                <Text style={styles.text}>{door.roHeight}"</Text>

                {(door.doorType === "Swing" || door.doorType === "Pivot") &&
                door.sideLite !== "none" ? (
                  <Text style={styles.text}>{door.sideLiteWidth}"</Text>
                ) : (door.doorType === "Swing" || door.doorType === "Pivot") &&
                  door.sideLite === "none" ? (
                  <Text style={styles.text}>None</Text>
                ) : (
                  <Text></Text>
                )}

                {door.doorType.includes("Corner") ? (
                  <Text style={styles.text}>
                    <Text style={styles.textBold}>L:</Text> {door.panelWidth}"{" "}
                    <Text style={styles.textBold}> R:</Text>{" "}
                    {door.panelWidthRightCorner}"
                  </Text>
                ) : door.doorType !== "Swing" && door.doorType !== "Pivot" ? (
                  <Text style={styles.text}>{door.panelWidth}"</Text>
                ) : (
                  <Text style={styles.text}>
                    {door.swingPanelWidth} {door.swingPanelFraction}"
                  </Text>
                )}

                <Text style={styles.text}>{door.panelHeight}"</Text>

                <Text style={styles.text}>2 1/4"</Text>

                <Text style={styles.text}>{door.frameWeight}lbs</Text>

                {door.doorType.includes("Corner") ? (
                  <Text style={styles.text}>
                    <Text style={styles.textBold}>L:</Text>{" "}
                    {door.perPanelWeight}lbs{" "}
                    <Text style={styles.textBold}> R:</Text>{" "}
                    {door.perPanelWeightRightCorner}lbs
                  </Text>
                ) : (
                  <Text style={styles.text}>{door.perPanelWeight}lbs</Text>
                )}

                {door.doorType.includes("Bi-fold") ? (
                  <Text style={styles.text}>{door.DLW}lbs</Text>
                ) : (
                  <Text></Text>
                )}
              </View>

              <View style={{ paddingTop: 20, position: "relative" }}>
                <Text style={styles.text}>
                  {door.doorType === "Bi-fold"
                    ? "Folding Door System"
                    : door.doorType === "Bi-fold Window"
                      ? "Folding Window System"
                      : door.doorType}
                </Text>

                <Text style={styles.text}>{door.material}</Text>

                {door.material !== "All Wood" &&
                door.exteriorCladColor === "Custom Color (upcharge applies)" ? (
                  <Text style={styles.textBold}>
                    Custom{" "}
                    {door.customCladColor ? `(${door.customCladColor})` : ""}
                  </Text>
                ) : door.material !== "All Wood" &&
                  door.exteriorCladColor &&
                  door.exteriorCladColor !==
                    "Custom Color (upcharge applies)" ? (
                  <Text style={styles.text}>{door.exteriorCladColor}</Text>
                ) : (
                  <Text></Text>
                )}

                <Text style={styles.text}>{door.woodSpecies}</Text>

                {door.prime ||
                door.paintAndPrime ||
                door.stain ||
                door.customPaintOrStain ? (
                  <Text style={styles.text}>Yes</Text>
                ) : (
                  <Text style={styles.text}>No</Text>
                )}

                {door.quantity ? (
                  <Text
                    style={
                      Number(door.quantity) > 1 ? styles.textBold : styles.text
                    }
                  >
                    {door.quantity}
                  </Text>
                ) : (
                  <Text style={styles.text}>1</Text>
                )}

                {door.doorType.includes("Corner") ? (
                  <Text style={styles.text}>
                    {door.cornerType} {door.exactConfig}{" "}
                    {door.cornerConfigRight} (as viewed from exterior)
                  </Text>
                ) : door.doorType !== "Swing" ? (
                  <Text style={styles.text}>
                    {door.exactConfig} (as viewed from exterior)
                  </Text>
                ) : (
                  <Text style={styles.text}>
                    {`${
                      door.exactConfig.split(" ")[1]
                    } ${door.exactConfig.slice(-2)}`}{" "}
                    (as viewed from exterior)
                  </Text>
                )}

                {(door.doorType === "Swing" || door.doorType === "Pivot") &&
                door.sideLite !== "none" ? (
                  <Text style={styles.text}>{door.sideLite?.slice(4)}</Text>
                ) : (door.doorType === "Swing" || door.doorType === "Pivot") &&
                  door.sideLite === "none" ? (
                  <Text style={styles.text}>None</Text>
                ) : (
                  <Text></Text>
                )}

                {isProduction &&
                door.doorType === "Slider" &&
                door.stileCategory === "Euro" &&
                Number(door.sliderPanelSqft) > 50 ? (
                  <Text style={styles.text}>
                    Euro
                    <Text style={styles.textBold}> (3 inch wheels)</Text>
                  </Text>
                ) : (
                  <Text style={styles.text}>
                    {isProduction &&
                    door.doorType === "Slider" &&
                    door.stileCategory
                      ? `${door.stileCategory} (2 inch wheels)`
                      : door.stileCategory
                        ? door.stileCategory
                        : "None"}
                  </Text>
                )}

                {door.doorType === "Fixed Panel" ? (
                  <Text style={styles.text}>Fixed</Text>
                ) : door.doorType.includes("Bi-fold") && isCustomer ? (
                  <Text style={styles.text}>Outswing</Text>
                ) : door.doorType.includes("Bi-fold") &&
                  series &&
                  series !== "Default" &&
                  !isCustomer ? (
                  <Text style={styles.text}>
                    Outswing
                    <Text style={isProduction ? styles.textBold : styles.text}>
                      {`(${series})`}
                    </Text>
                  </Text>
                ) : door.doorType.includes("Bi-fold") &&
                  bifoldSeries2 &&
                  !isCustomer ? (
                  <Text style={styles.text}>
                    Outswing{" "}
                    <Text style={isProduction ? styles.textBold : styles.text}>
                      (Series 2)
                    </Text>
                  </Text>
                ) : door.doorType.includes("Bi-fold") &&
                  !bifoldSeries2 &&
                  !isCustomer ? (
                  <Text style={styles.text}>
                    Outswing{" "}
                    <Text style={isProduction ? styles.textBold : styles.text}>
                      (Series 1)
                    </Text>
                  </Text>
                ) : door.doorType === "Swing" ? (
                  <Text style={styles.text}>{door.swingDirection}</Text>
                ) : door.doorType === "Pivot" ? (
                  <Text style={styles.text}>Inswing</Text>
                ) : (
                  <Text></Text>
                )}

                {door.sillStyle === "Rounded Dam" ? (
                  <Text style={styles.text}>Raised Sill (Rounded)</Text>
                ) : door.sillStyle === "Squared Dam" ? (
                  <Text style={styles.text}>Raised Sill (Square)</Text>
                ) : door.exactConfig === "Fixed Slider" ? (
                  <Text style={styles.text}>Raised SILLution</Text>
                ) : door.exactConfig === "Fixed Full Bound" ? (
                  <Text style={styles.text}>Full Bound</Text>
                ) : door.exactConfig === "Fixed Outswing" ? (
                  <Text style={styles.text}>Outswing Sill</Text>
                ) : door.exactConfig === "Fixed Inswing" ? (
                  <Text style={styles.text}>Inswing Sill</Text>
                ) : door.doorType !== "Pivot" &&
                  door.sillStyle !== "Rounded Dam" &&
                  door.sillStyle !== "Squared Dam" ? (
                  <Text style={styles.text}>{door.sillStyle}</Text>
                ) : door.doorType === "Pivot" &&
                  door.sillStyle?.includes("Aluminum") ? (
                  <Text style={styles.text}>ADA Aluminum</Text>
                ) : door.doorType === "Pivot" &&
                  door.sillStyle?.includes("Wood") ? (
                  <Text style={styles.text}>ADA Wood</Text>
                ) : (
                  <Text style={styles.text}>None</Text>
                )}
              </View>

              <View style={{ paddingTop: 40, position: "relative" }}>
                {flashingFin || flashingFinCustomSetback ? (
                  <Text style={styles.text}>1 1/2"</Text>
                ) : (
                  <Text></Text>
                )}

                {flashingFin ? (
                  <Text style={styles.text}>1"</Text>
                ) : flashingFinCustomSetback ? (
                  <Text style={styles.textBold}>{door.finSetback}"</Text>
                ) : (
                  <Text></Text>
                )}

                {door.addScreen?.includes("Double Screen") &&
                !slidingScreenImg &&
                door.swingDirection !== "Inswing" ? (
                  <Text style={styles.text}>
                    {door.screenType?.includes("Centor")
                      ? "Double Flat"
                      : "Double Accordion"}{" "}
                    Retractable
                  </Text>
                ) : door.addScreen?.includes("Double Shade") &&
                  !slidingScreenImg &&
                  door.swingDirection !== "Inswing" ? (
                  <Text style={styles.text}>Double Shade</Text>
                ) : door.addScreen &&
                  !slidingScreenImg &&
                  door.swingDirection !== "Inswing" &&
                  door.screenLayout ? (
                  <Text style={styles.text}>
                    {door.screenType?.includes("Centor") && 
                     door.screenLayout.includes("Left")
                      ? "Single Flat Compress Left"
                      : door.screenType?.includes("Centor") && 
                        door.screenLayout.includes("Right")
                      ? "Single Flat Compress Right"
                      : door.screenLayout.includes("Left")
                      ? "Single Accordion Compress Left"
                      : door.screenLayout.includes("Right")
                      ? "Single Accordion Compress Right"
                      : ""
                    }
                  </Text>
                ) : slidingScreenImg ? (
                  <Text style={styles.text}>
                    {door.addSlidingScreen?.includes("Left")
                      ? "Left Sliding Screen"
                      : door.addSlidingScreen?.includes("Right")
                        ? "Right Sliding Screen"
                        : "Double Sliding Screen"}
                  </Text>
                ) : (
                  <Text></Text>
                )}

                {(door.addScreen || slidingScreenImg) &&
                door.swingDirection !== "Inswing" &&
                door.screenColor === "Black Extrusion" ? (
                  <Text style={styles.text}>Black</Text>
                ) : (door.addScreen || slidingScreenImg) &&
                  door.swingDirection !== "Inswing" &&
                  door.screenColor === "White Extrusion" ? (
                  <Text style={styles.text}>White</Text>
                ) : (
                  <Text />
                )}

                {door.addScreen &&
                door.screenType?.includes("Centor") &&
                door.shadeMeshColor &&
                (door.addScreen.includes("Shade") ||
                  door.addScreen === "Add Combo Screen/Shade") ? (
                  <Text style={styles.text}>{door.shadeMeshColor}</Text>
                ) : (
                  <Text />
                )}

                {withScreen && door.screenPocket?.includes("Pocket Left") ? (
                  <Text style={styles.text}>Left Pocket</Text>
                ) : withScreen &&
                  door.screenPocket?.includes("Pocket Right") ? (
                  <Text style={styles.text}>Right Pocket</Text>
                ) : withScreen &&
                  door.screenPocket?.includes("Double Pocket") ? (
                  <Text style={styles.text}>Double Pocket</Text>
                ) : withScreen &&
                  door.screenPocket?.includes("Pocket") &&
                  door.screenLayout?.includes("Left") ? (
                  <Text style={styles.text}>Left Pocket</Text>
                ) : withScreen &&
                  door.screenPocket?.includes("Pocket") &&
                  door.screenLayout?.includes("Right") ? (
                  <Text style={styles.text}>Right Pocket</Text>
                ) : (
                  <Text />
                )}

                {door.doorType.includes("Corner") &&
                pocketImg &&
                door.netFrameWidthWithPocket &&
                withScreen ? (
                  <Text style={[styles.text, { marginTop: 56 }]}>
                    <Text style={styles.textBold}>L:</Text>{" "}
                    {door.netFrameWidthWithPocket}"{" "}
                    <Text style={styles.textBold}> R:</Text>{" "}
                    {door.netFrameWidthWithPocketRightCorner}"
                  </Text>
                ) : (door.sliderPocket === "With Pocket" &&
                    door.exactConfig.includes("-")) ||
                  (door.sliderPocket === "With Pocket" &&
                    !door.exactConfig.includes("-") &&
                    !door.addScreen?.includes("Double")) ? (
                  <Text />
                ) : pocketImg && door.netFrameWidthWithPocket && withScreen ? (
                  <Text style={[styles.text, { marginTop: 56 }]}>
                    {door.netFrameWidthWithPocket}"
                  </Text>
                ) : (
                  <Text />
                )}
              </View>
            </View>
          </View>
        </View>

        <View style={styles.colWithMargin}>
          <View style={styles.row}>
            <View style={styles.innerColumn1}>
              <View style={{ position: "relative", paddingTop: 20 }}>
                <Text
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    fontSize: 12,
                    width: 100,
                    textDecoration: "underline",
                    fontWeight: "bold",
                    fontFamily: "Roboto-Bold",
                  }}
                >
                  Measurements
                </Text>

                <Text style={styles.textBold}>Top Rail:</Text>

                <Text style={styles.textBold}>Bottom Rail:</Text>

                <Text style={styles.textBold}>Stiles:</Text>

                {door.doorType !== "Fixed Panel" ? (
                  <Text style={styles.textBold}>Lock Stile:</Text>
                ) : (
                  <Text />
                )}

                {(door.doorType === "Swing" || door.doorType === "Pivot") &&
                door.sideLite !== "none" ? (
                  <Text style={styles.textBold}>Sidelite Stile:</Text>
                ) : (
                  <Text />
                )}

                {door.doorType !== "Fixed Panel" &&
                door.doorType !== "Pivot" ? (
                  <Text style={styles.textBold}>Handle Height:</Text>
                ) : door.doorType === "Pivot" ? (
                  <Text style={styles.textBold}>Thumb Turn Height:</Text>
                ) : (
                  <Text />
                )}

                {door.doorType === "Pivot" ? (
                  <Text style={styles.textBold}>Pivot Axis Point:</Text>
                ) : (
                  <Text />
                )}

                {door.jamb ? (
                  <Text style={styles.textBold}>Jamb Width:</Text>
                ) : (
                  <Text />
                )}

                {kerf || kerfCustomSetback ? (
                  <Text style={styles.textBold}>Kerf Setback:</Text>
                ) : (
                  <Text />
                )}

                {door.doorType !== "Swing" && door.doorType !== "Pivot" ? (
                  <Text style={styles.textBold}>Glass Width(DLO):</Text>
                ) : (
                  <Text style={styles.textBold}>Panel Glass Width(DLO):</Text>
                )}

                {door.glassWidthDD &&
                door.doorType !== "Swing" &&
                door.doorType !== "Pivot" ? (
                  <Text style={styles.textBold}>
                    {door.doorType === "Bi-fold Window"
                      ? "Glass Width Daily Sash(DLO):"
                      : "Glass Width Daily Door(DLO):"}
                  </Text>
                ) : swingWithSidelite ? (
                  <Text style={styles.textBold}>Sidelite Glass Width DLO:</Text>
                ) : (
                  <Text />
                )}

                {door.doorType !== "Swing" && door.doorType !== "Pivot" ? (
                  <Text style={styles.textBold}>Glass Height(DLO):</Text>
                ) : (
                  <Text style={styles.textBold}>Panel Glass Height(DLO):</Text>
                )}
              </View>

              <View style={{ position: "relative", paddingTop: 20 }}>
                <Text
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    fontSize: 12,
                    textDecoration: "underline",
                    fontWeight: "bold",
                    fontFamily: "Roboto-Bold",
                  }}
                >
                  Options
                </Text>

                {door.doorType === "Pivot" ? (
                  <>
                    <Text style={styles.textBold}>Pivot Hardware:</Text>
                    <Text style={styles.textBold}>Cylinder Key:</Text>
                  </>
                ) : (
                  <Text />
                )}

                {door.doorType === "Pivot" &&
                door.handleStyle === "Dallas Lever" ? (
                  <Text style={styles.textBold}>Lever Hardware:</Text>
                ) : door.doorType === "Pivot" &&
                  door.handleStyle === "Handle Pull by Others" ? (
                  <Text style={styles.textBold}>Pull Hardware:</Text>
                ) : (
                  <Text />
                )}

                {door.doorType === "Pivot" &&
                door.handleStyle === "Dallas Lever" ? (
                  <Text style={styles.textBold}>Lever Finish:</Text>
                ) : (
                  <Text />
                )}

                {door.doorType === "Pivot" ? (
                  <Text style={styles.textBold}>{door.pivotBackPlate}:</Text>
                ) : (
                  <Text />
                )}

                {door.doorType === "Pivot" && door.pivotMagnet ? (
                  <Text style={styles.textBold}>Ghostop Magnet:</Text>
                ) : (
                  <Text />
                )}

                {noWindowAndNoEvenConfig &&
                door.doorType !== "Fixed Panel" &&
                door.doorType !== "Pivot" ? (
                  <Text style={styles.textBold}>Handle Key:</Text>
                ) : (
                  <Text />
                )}

                {noWindowAndNoEvenConfig &&
                door.doorType !== "Fixed Panel" &&
                door.doorType !== "Pivot" ? (
                  <Text style={styles.textBold}>Handle Style:</Text>
                ) : (
                  <Text />
                )}

                {noWindowAndNoEvenConfig &&
                door.doorType !== "Fixed Panel" &&
                door.doorType !== "Pivot" ? (
                  <Text style={styles.textBold}>Handle Set Finish:</Text>
                ) : (
                  <Text />
                )}

                {windowOrEvenConfig &&
                door.doorType !== "Fixed Panel" &&
                door.doorType !== "Pivot" ? (
                  <Text style={styles.textBold}>Handle Hardware:</Text>
                ) : (
                  <Text />
                )}

                {door.doorType.includes("Bi-fold") ? (
                  <Text style={styles.textBold}>Shoot-bolt Finish:</Text>
                ) : door.doorType === "Swing" ? (
                  <Text style={styles.textBold}>Drip Cap:</Text>
                ) : (
                  <Text />
                )}

                {door.doorType.includes("Bi-fold") ||
                door.exactConfig === "Fixed Bi-fold" ? (
                  <Text style={styles.textBold}>Head Track Finish:</Text>
                ) : (
                  <Text />
                )}

                <Text style={styles.textBold}>Sill Finish:</Text>

                {door.doorType !== "Slider" &&
                door.doorType !== "Fixed Panel" &&
                door.doorType !== "Pivot" ? (
                  <Text style={styles.textBold}>Hinge Finish:</Text>
                ) : door.doorType === "Pivot" ? (
                  <Text style={styles.textBold}>Hinge Plate Finish:</Text>
                ) : (
                  <Text />
                )}

                {door.doorType !== "Fixed Panel" ? (
                  <Text style={styles.textBold}>Weather Strip Color:</Text>
                ) : (
                  <Text />
                )}

                {door.doorType === "Pivot" &&
                door.dropseal &&
                (Number(door.swingPanelWidth) < 90 ||
                  (Number(door.swingPanelWidth) === 90 &&
                    !door.swingPanelFraction)) ? (
                  <Text style={styles.textBold}>Drop Seal:</Text>
                ) : (
                  <Text />
                )}

                <Text style={styles.textBold}>Interior Stops:</Text>

                {cladStops ? (
                  <Text />
                ) : (
                  <Text style={styles.textBold}>Exterior Stops:</Text>
                )}

                <Text style={styles.textBold}>Glass Type:</Text>

                <Text style={styles.textBold}>Glass Gas:</Text>

                <Text style={styles.textBold}>Glass Spacer:</Text>

                {door.i89coating ? (
                  <Text style={styles.textBold}>I89:</Text>
                ) : door.glassAddon ? (
                  <Text style={styles.textBold}>Glass Options:</Text>
                ) : (
                  <Text />
                )}

                {door.breatherTubes === "Yes" ? (
                  <Text style={styles.textBold}>Breather Tubes:</Text>
                ) : (
                  <Text />
                )}

                {door.doorType === "Slider" && door.automation ? (
                  <Text style={styles.textBold}>Prep for Automation:</Text>
                ) : (
                  <Text />
                )}
              </View>

              <View style={{ position: "relative", paddingTop: 20 }}>
                {withPaint ? (
                  <>
                    <Text style={styles.textBold}></Text>
                    <Text style={styles.textBold}>Paint:</Text>
                  </>
                ) : withPrime ? (
                  <>
                    <Text style={styles.textBold}>Primer:</Text>
                    <Text style={styles.textBold}></Text>
                  </>
                ) : withCustomPaint ? (
                  <Text style={styles.textBold}>Paint:</Text>
                ) : (
                  <Text />
                )}

                {sideliteDiffLites ? (
                  <>
                    {door.stain === "Add Standard Stain" ||
                    door.customPaintOrStain === "Add Custom Stain" ? (
                      <Text style={styles.textBold}>Stain:</Text>
                    ) : door.stain === "Add Clear Coat" &&
                      !door.customPaintOrStain ? (
                      <Text style={styles.textBold}>Clear Coat:</Text>
                    ) : (
                      <Text />
                    )}

                    {door.sillFinish &&
                    !door.sillStyle?.includes("Ultra") &&
                    (door.paintAndPrime ||
                      door.prime ||
                      door.stain ||
                      door.customPaintOrStain) ? (
                      <Text style={styles.textBold}>Sill Dam:</Text>
                    ) : (
                      <Text />
                    )}

                    {withDividedLites && door.liteConfig === '1 3/4"' ? (
                      <Text style={styles.textBold}>TDL Lites(A):</Text>
                    ) : withDividedLites && door.liteConfig !== '1 3/4"' ? (
                      <Text style={styles.textBold}>SDL Lites(A):</Text>
                    ) : (
                      <Text style={styles.textBold}>Lites(A):</Text>
                    )}

                    {withDividedLites && door.liteConfig === '1 3/4"' ? (
                      <Text style={styles.textBold}>
                        Sidelite TDL Lites(B):
                      </Text>
                    ) : withDividedLites && door.liteConfig !== '1 3/4"' ? (
                      <Text style={styles.textBold}>
                        Sidelite SDL Lites(B):
                      </Text>
                    ) : (
                      <Text />
                    )}

                    {withDividedLites ? (
                      <Text style={styles.textBold}>
                        Lite Configuration(A):
                      </Text>
                    ) : (
                      <Text />
                    )}

                    <Text style={styles.textBold}>
                      Sidelite Lite Configuration(B):
                    </Text>

                    {withDividedLites && door.liteConfig === '1 3/4"' ? (
                      <Text style={styles.textBold}>TDL Bars:</Text>
                    ) : withDividedLites && door.liteConfig !== '1 3/4"' ? (
                      <Text style={styles.textBold}>SDL Bars:</Text>
                    ) : (
                      <Text />
                    )}
                    {withDividedLites && door.liteConfig === '1 3/4"' ? (
                      <Text />
                    ) : withDividedLites && door.liteConfig !== '1 3/4"' ? (
                      <Text style={styles.textBold}>Shadow Bars:</Text>
                    ) : (
                      <Text />
                    )}
                  </>
                ) : (
                  <>
                    {door.stain === "Add Standard Stain" ||
                    door.customPaintOrStain === "Add Custom Stain" ? (
                      <Text style={styles.textBold}>Stain:</Text>
                    ) : door.stain === "Add Clear Coat" &&
                      !door.customPaintOrStain ? (
                      <Text style={styles.textBold}>Clear Coat:</Text>
                    ) : (
                      <Text />
                    )}

                    {door.sillFinish &&
                    !door.sillStyle?.includes("Ultra") &&
                    (door.paintAndPrime ||
                      door.prime ||
                      door.stain ||
                      door.customPaintOrStain) ? (
                      <Text style={styles.textBold}>Sill Dam:</Text>
                    ) : (
                      <Text />
                    )}

                    {withDividedLites && door.liteConfig === '1 3/4"' ? (
                      <Text style={styles.textBold}>TDL Lites:</Text>
                    ) : withDividedLites && door.liteConfig !== '1 3/4"' ? (
                      <Text style={styles.textBold}>SDL Lites:</Text>
                    ) : (
                      <Text style={styles.textBold}>Lites:</Text>
                    )}

                    {door.addDL && door.dlLayout ? (
                      <Text style={styles.textBold}>Lite Configuration:</Text>
                    ) : (
                      <Text />
                    )}

                    {withDividedLites &&
                    door.addDL === "Add Intermediate Rail" ? (
                      <Text style={styles.textBold}>
                        Intermediate Rail Size:
                      </Text>
                    ) : withDividedLites && door.liteConfig === '1 3/4"' ? (
                      <Text style={styles.textBold}>TDL Bars:</Text>
                    ) : withDividedLites && door.liteConfig !== '1 3/4"' ? (
                      <Text style={styles.textBold}>SDL Bars:</Text>
                    ) : (
                      <Text />
                    )}
                    {withDividedLites && door.liteConfig === '1 3/4"' ? (
                      <Text />
                    ) : withDividedLites && door.liteConfig !== '1 3/4"' ? (
                      <Text style={styles.textBold}>Shadow Bars:</Text>
                    ) : (
                      <Text />
                    )}
                  </>
                )}

                {door.kd ? <Text style={styles.textBold}>K.D:</Text> : <Text />}

                <Text style={styles.textBold}>Notes:</Text>
              </View>
            </View>

            <View style={styles.innerColumn2}>
              <View style={{ position: "relative", paddingTop: 20 }}>
                <Text style={styles.text}>
                  {door.stileCategory === "Traditional"
                    ? '4-3/4"'
                    : door.stileCategory === "Euro"
                      ? '3-7/16"'
                      : door.stileCategory === "Slimline" &&
                          door.pivotHardware === "Fritsjurgens"
                        ? '3-7/16"'
                        : door.stileCategory === "Slimline"
                          ? '2-1/2"'
                          : door.stileCategory === "Slimline Traditional"
                            ? '2-1/2"'
                            : "none"}
                </Text>

                <Text style={styles.text}>
                  {door.stileCategory === "Traditional" &&
                  door.stilesAndRails === 'Bottom rail 4-3/4"'
                    ? '4-3/4"'
                    : door.stileCategory === "Traditional" &&
                        door.stilesAndRails === 'Bottom rail 7"'
                      ? '7"'
                      : door.stileCategory === "Euro" &&
                          door.pivotHardware === "Fritsjurgens" &&
                          door.pivotAction === "Self Closing"
                        ? '4-3/4"'
                        : door.stileCategory === "Euro" &&
                            door.doorType === "Slider" &&
                            Number(door.sliderPanelSqft) > 50
                          ? '4-3/4"'
                          : door.stileCategory === "Euro"
                            ? '3-7/16"'
                            : door.stileCategory === "Slimline" &&
                                door.pivotHardware === "Fritsjurgens" &&
                                door.pivotAction === "Self Closing"
                              ? '4-3/4"'
                              : door.stileCategory === "Slimline" &&
                                  (door.doorType === "Slider" ||
                                    door.exactConfig === "Fixed Slider")
                                ? '3-7/16"'
                                : door.stileCategory ===
                                      "Slimline Traditional" &&
                                    (door.doorType === "Slider" ||
                                      door.exactConfig === "Fixed Slider")
                                  ? '3-7/16"'
                                  : door.stileCategory === "Slimline" ||
                                      door.stileCategory ===
                                        "Slimline Traditional"
                                    ? '2-1/2"'
                                    : "none"}
                </Text>

                <Text style={styles.text}>
                  {door.stileCategory === "Traditional"
                    ? '4-3/4"'
                    : door.stileCategory === "Euro"
                      ? '3-7/16"'
                      : door.stileCategory === "Slimline"
                        ? '2-1/2"'
                        : door.stileCategory === "Slimline Traditional"
                          ? '2-1/2"'
                          : door.stileCategory}
                </Text>

                {door.doorType === "Fixed Panel" ? (
                  <Text />
                ) : door.doorType === "Pivot" &&
                  door.stileCategory === "Slimline" ? (
                  <Text style={styles.text}>3-7/16"</Text>
                ) : door.lockRail ===
                  '2-1/2" Stiles and Rails with 4-3/4" Lock Stile' ? (
                  <Text style={styles.text}>2-1/2"</Text>
                ) : door.lockRail ===
                  '2-1/2" Stiles and Rails with 2-1/2" Lock Stile' ? (
                  <Text style={styles.text}>2-1/2"</Text>
                ) : door.lockRail ===
                  '2-1/2" Stiles and Rails with 3-7/16" Lock Stile' ? (
                  <Text style={styles.text}>3-7/16"</Text>
                ) : door.lockRail ===
                  '2-1/2" Stiles and Top Rails with 3-7/16" Lock Stile and Bottom Rails' ? (
                  <Text style={styles.text}>3-7/16"</Text>
                ) : !door.lockRail && door.stileCategory === "Euro" ? (
                  <Text style={styles.text}>3-7/16"</Text>
                ) : !door.lockRail && door.stileCategory === "Traditional" ? (
                  <Text style={styles.text}>4-3/4"</Text>
                ) : (
                  <Text />
                )}

                {(door.doorType === "Swing" || door.doorType === "Pivot") &&
                door.sideLite !== "none" ? (
                  <Text style={styles.text}>2 1/2"</Text>
                ) : (
                  <Text />
                )}

                {door.doorType === "Bi-fold Window" ? (
                  <Text style={styles.text}>12"</Text>
                ) : door.doorType !== "Fixed Panel" ? (
                  <Text style={styles.text}>
                    {door.handleHeight ? door.handleHeight : '36"'}
                  </Text>
                ) : (
                  <Text />
                )}

                {door.doorType === "Pivot" &&
                door.pivotPoint === "Middle Pivot Point" ? (
                  <Text style={styles.text}>
                    {convertDecimalToFraction(
                      fractionToInt(
                        door.swingPanelWidth || "",
                        door.swingPanelFraction || "",
                      ) / 2,
                    )}
                    Middle
                  </Text>
                ) : door.doorType === "Pivot" && door.pivotPoint ? (
                  <Text style={styles.text}>
                    {door.pivotPoint.replace(" Pivot Axis Point", "")}
                  </Text>
                ) : (
                  <Text />
                )}

                {door.jamb?.includes("Overall Jamb Width") ? (
                  <Text style={styles.text}>
                    {door.jamb
                      .replace("Standard", "")
                      .replace("Jamb Width", "")}
                  </Text>
                ) : (
                  <Text style={styles.textBold}>{door.jamb}"</Text>
                )}

                {kerfCustomSetback ? (
                  <Text style={styles.textBold}>{door.kerfSetback}"</Text>
                ) : kerf ? (
                  <Text style={styles.text}>3/8"</Text>
                ) : (
                  <Text />
                )}

                {door.doorType.includes("Corner") ? (
                  <Text style={styles.text}>
                    <Text style={styles.textBold}>L:</Text> {door.glassWidth}"{" "}
                    <Text style={styles.textBold}> R:</Text>{" "}
                    {door.glassWidthRightCorner}"
                  </Text>
                ) : (
                  <Text style={styles.text}>{door.glassWidth}"</Text>
                )}

                {door.doorType.includes("Corner") &&
                (door.glassWidthDD || door.glassWidthDDRightCorner) ? (
                  <>
                    {noAccess.includes(door.exactConfig) ? (
                      <Text style={styles.text}>
                        <Text style={styles.textBold}>R:</Text>{" "}
                        {door.glassWidthDDRightCorner}"
                      </Text>
                    ) : noAccess.includes(door.cornerConfigRight || "") ? (
                      <Text style={styles.text}>
                        <Text style={styles.textBold}>L:</Text>{" "}
                        {door.glassWidthDD}"{" "}
                      </Text>
                    ) : (
                      <Text style={styles.text}>
                        <Text style={styles.textBold}>L:</Text>{" "}
                        {door.glassWidthDD}"{" "}
                        <Text style={styles.textBold}> R:</Text>{" "}
                        {door.glassWidthDDRightCorner}"
                      </Text>
                    )}
                  </>
                ) : door.doorType !== "Swing" && door.glassWidthDD ? (
                  <Text style={styles.text}>{door.glassWidthDD}"</Text>
                ) : swingWithSidelite ? (
                  <Text style={styles.text}>{door.sideLiteDLO}"</Text>
                ) : (
                  <Text />
                )}

                <Text style={styles.text}>{door.glassHeight}"</Text>
              </View>

              <View style={{ position: "relative", paddingTop: 20 }}>
                {door.doorType === "Pivot" ? (
                  <>
                    <Text style={styles.text}>
                      {door.pivotHardware}-{door.pivotAction}
                    </Text>
                    <Text style={styles.text}>Yes</Text>
                  </>
                ) : (
                  <Text />
                )}

                {windowOrEvenConfig ||
                door.doorType === "Fixed Panel" ||
                door.doorType === "Pivot" ? (
                  <Text />
                ) : door.withKey === "Exterior Key" &&
                  door.withHandle !== "No Handle Set" ? (
                  <Text style={styles.text}>Yes</Text>
                ) : (
                  <Text style={styles.text}>No</Text>
                )}

                {windowOrEvenConfig ||
                door.doorType === "Fixed Panel" ||
                (door.doorType === "Pivot" && !door.handleStyle) ? (
                  <Text />
                ) : door.doorType === "Pivot" &&
                  door.handleStyle === "Dallas Lever" ? (
                  <Text style={styles.text}>Dallas</Text>
                ) : door.doorType === "Pivot" &&
                  door.handleStyle === "Handle Pull by Others" ? (
                  <Text style={styles.textBold}>By Others</Text>
                ) : withHandle ? (
                  <Text style={styles.text}>{door.handleStyle}</Text>
                ) : door.doorType === "Slider" ? (
                  <Text style={styles.text}>{door.handleStyle}</Text>
                ) : (
                  <Text style={styles.text}>Guts Only</Text>
                )}

                {door.doorType === "Pivot" &&
                door.handleStyle === "Dallas Lever" ? (
                  <Text style={styles.text}>{door.handleColor}</Text>
                ) : (
                  <Text />
                )}

                {door.doorType === "Pivot" ? (
                  <Text style={styles.text}>{door.pivotBackPlateColor}</Text>
                ) : (
                  <Text />
                )}

                {door.doorType === "Pivot" &&
                door.pivotMagnet &&
                door.pivotMagnetColor ? (
                  <Text style={styles.text}>{door.pivotMagnetColor}</Text>
                ) : (
                  <Text />
                )}

                {windowOrEvenConfig ? (
                  <Text style={styles.text}>Int. Shootbolts Only</Text>
                ) : (
                  <Text />
                )}

                {windowOrEvenConfig ||
                door.doorType === "Fixed Panel" ||
                door.doorType === "Pivot" ? (
                  <Text />
                ) : door.handleColor && withHandle ? (
                  <Text style={styles.text}>{door.handleColor}</Text>
                ) : door.doorType === "Slider" ? (
                  <Text style={styles.text}>{door.handleColor}</Text>
                ) : (
                  <Text style={styles.text}>No Handle</Text>
                )}

                {door.shootBoltColor && door.doorType.includes("Bi-fold") ? (
                  <Text style={styles.text}>{door.shootBoltColor}</Text>
                ) : door.doorType === "Swing" ? (
                  <Text style={styles.text}>Bronze</Text>
                ) : (
                  <Text />
                )}

                {door.headTrackColor &&
                (door.doorType.includes("Bi-fold") ||
                  door.exactConfig === "Fixed Bi-fold") ? (
                  <Text style={styles.text}>{door.headTrackColor}</Text>
                ) : (
                  <Text />
                )}

                {door.sillColor &&
                door.sillStyle !== "Stainless Steel Recessed *" ? (
                  <Text style={styles.text}>{door.sillColor}</Text>
                ) : door.doorType === "Pivot" ? (
                  <Text style={styles.text}>
                    {door.sillStyle?.includes("Aluminum")
                      ? door.sillStyle.replace(" Aluminum", "")
                      : "Flat Grain White Oak"}
                  </Text>
                ) : (
                  <Text style={styles.text}>None</Text>
                )}

                {door.hingeColor &&
                door.doorType !== "Slider" &&
                door.doorType !== "Fixed Panel" ? (
                  <Text style={styles.text}>{door.hingeColor}</Text>
                ) : !door.hingeColor &&
                  door.doorType !== "Slider" &&
                  door.doorType !== "Fixed Panel" ? (
                  <Text style={styles.text}>None</Text>
                ) : (
                  <Text />
                )}

                {door.doorType === "Fixed Panel" ? (
                  <Text />
                ) : door.weatherStripColor ? (
                  <Text style={styles.text}>{door.weatherStripColor}</Text>
                ) : (
                  <Text style={styles.text}>None</Text>
                )}

                {door.doorType === "Pivot" &&
                door.dropseal &&
                (Number(door.swingPanelWidth) < 90 ||
                  (Number(door.swingPanelWidth) === 90 &&
                    !door.swingPanelFraction)) ? (
                  <Text style={styles.text}>Yes</Text>
                ) : (
                  <Text />
                )}

                {cladStops ? (
                  <>
                    <Text style={styles.text}>{door.stickingStops}</Text>
                    <Text />
                  </>
                ) : woodStops ? (
                  <>
                    <Text style={styles.text}>
                      {door.stickingStopsInterior}
                    </Text>
                    <Text style={styles.text}>
                      {door.stickingStopsExterior}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text style={styles.text}>None</Text>
                    <Text style={styles.text}>None</Text>
                  </>
                )}

                {door.glassAddon && door.glassAddonSide && door.glassType ? (
                  <>
                    {door.customGlass ? (
                      <Text style={styles.textBold}>
                        Custom{" "}
                        <Text style={styles.text}>
                          {door.glassType}{" "}
                          {door.glassAddonSide === "A"
                            ? "(Int. Pane)"
                            : "(Ext. Pane)"}
                        </Text>
                      </Text>
                    ) : (
                      <Text style={styles.text}>
                        {door.glassType}{" "}
                        {door.glassAddonSide === "A"
                          ? "(Int. Pane)"
                          : "(Ext. Pane)"}
                      </Text>
                    )}
                  </>
                ) : door.glassType && door.i89coating ? (
                  <>
                    {door.customGlass ? (
                      <Text style={styles.textBold}>
                        Custom{" "}
                        <Text style={styles.text}>
                          {door.glassType} (Ext. Pane)
                        </Text>
                      </Text>
                    ) : (
                      <Text style={styles.text}>
                        {door.glassType} (Ext. Pane)
                      </Text>
                    )}
                  </>
                ) : door.glassType ? (
                  <>
                    {door.customGlass ? (
                      <Text style={styles.textBold}>
                        Custom <Text style={styles.text}>{door.glassType}</Text>
                      </Text>
                    ) : (
                      <Text style={styles.text}>{door.glassType}</Text>
                    )}
                  </>
                ) : (
                  <Text style={styles.text}>None</Text>
                )}

                {door.breatherTubes === "Yes" ? (
                  <Text style={styles.text}>Air</Text>
                ) : (
                  <Text style={styles.text}>Argon</Text>
                )}

                {door.spacerColor ? (
                  <Text style={styles.text}>{door.spacerColor}</Text>
                ) : (
                  <Text style={styles.text}>None</Text>
                )}

                {door.i89coating ? (
                  <Text style={styles.text}>Yes (Int. Pane)</Text>
                ) : door.glassAddon ? (
                  <Text style={styles.text}>
                    {door.glassAddon}{" "}
                    {door.glassAddonSide === "A"
                      ? "(Ext. Pane)"
                      : "(Int. Pane)"}
                  </Text>
                ) : (
                  <Text />
                )}

                {door.breatherTubes === "Yes" ? (
                  <Text style={styles.text}>Yes</Text>
                ) : (
                  <Text />
                )}

                {door.doorType === "Slider" && door.automation ? (
                  <Text style={styles.textBold}>Yes</Text>
                ) : (
                  <Text />
                )}
              </View>

              <View style={{ position: "relative", paddingTop: 20 }}>
                {withPaint ? (
                  <>
                    <Text />
                    <Text style={styles.text}>{door.paintColor}</Text>
                  </>
                ) : withPrime ? (
                  <>
                    <Text style={styles.text}>Yes</Text>
                    <Text />
                  </>
                ) : withCustomPaint && door.customPaintColor ? (
                  <Text style={styles.text}>
                    Custom ({door.customPaintColor})
                  </Text>
                ) : withCustomPaint ? (
                  <Text style={styles.text}>Custom</Text>
                ) : (
                  <>
                    <Text />
                  </>
                )}

                {door.stain === "Add Standard Stain" &&
                !door.customPaintOrStain ? (
                  <Text style={styles.text}>{door.stainColor}</Text>
                ) : door.stain === "Add Clear Coat" &&
                  !door.customPaintOrStain ? (
                  <Text style={styles.text}>Yes</Text>
                ) : door.customPaintOrStain === "Add Custom Stain" &&
                  door.customStainColor ? (
                  <Text style={styles.text}>
                    Custom ({door.customStainColor})
                  </Text>
                ) : door.customPaintOrStain === "Add Custom Stain" ? (
                  <Text style={styles.text}>Custom</Text>
                ) : (
                  <Text />
                )}

                {door.sillFinish &&
                !door.sillStyle?.includes("Ultra") &&
                (door.paintAndPrime ||
                  door.prime ||
                  door.stain ||
                  door.customPaintOrStain) ? (
                  <Text style={styles.text}>
                    {door.sillFinish === "No Finish"
                      ? "Unfinished"
                      : "Finished"}
                  </Text>
                ) : (
                  <Text />
                )}

                {withDividedLites && door.numberOfLites ? (
                  <Text style={styles.text}>{door.numberOfLites}</Text>
                ) : (
                  <Text style={styles.text}>1</Text>
                )}

                {sideliteDiffLites && door.numberOfLitesSidelite ? (
                  <Text style={styles.text}>{door.numberOfLitesSidelite}</Text>
                ) : (
                  <Text />
                )}

                {withDividedLites && door.dlLayout === "2W1H" ? (
                  <Text style={styles.text}>
                    {door.dlLayout} over 1 (DLO {door.daylightOpening})
                  </Text>
                ) : withDividedLites &&
                  (door.dlLayout === "3W1H" || door.dlLayout === "4W1H") ? (
                  <Text style={styles.text}>
                    {door.dlLayout} over 1 (DLO {door.daylightOpening})
                  </Text>
                ) : withDividedLites &&
                  (door.dlLayout === "3 Lite Marginal" ||
                    door.dlLayout === "6 Lite Marginal") ? (
                  <Text style={styles.text}>
                    {door.dlLayout} (DLO {door.daylightOpening})
                  </Text>
                ) : withDividedLites && door.dlLayout ? (
                  <Text style={styles.text}>{door.dlLayout}</Text>
                ) : (
                  <Text />
                )}

                {sideliteDiffLites && door.dlLayoutSidelite === "2W1H" ? (
                  <Text style={styles.text}>
                    {door.dlLayoutSidelite} over 1 (DLO {door.daylightOpening})
                  </Text>
                ) : sideliteDiffLites &&
                  (door.dlLayoutSidelite === "3W1H" ||
                    door.dlLayoutSidelite === "4W1H") ? (
                  <Text style={styles.text}>
                    {door.dlLayoutSidelite} over 1 (DLO {door.daylightOpening})
                  </Text>
                ) : sideliteDiffLites ? (
                  <Text style={styles.text}>{door.dlLayoutSidelite}</Text>
                ) : (
                  <Text />
                )}

                {withDividedLites ? (
                  <Text style={styles.text}>{door.liteConfig}</Text>
                ) : (
                  <Text />
                )}
                {withDividedLites && door.liteConfig !== '1 3/4"' ? (
                  <Text style={styles.text}>Black</Text>
                ) : (
                  <Text />
                )}

                {door.kd ? <Text style={styles.textBold}>Yes</Text> : <Text />}

                <Text style={[styles.text, { width: 150, height: 50 }]}>
                  {door.notes}
                </Text>

                {sideliteDiffLites ? (
                  <>
                    <View
                      style={[
                        styles.column,
                        {
                          alignItems: "center",
                          justifyContent: "center",
                          position: "absolute",
                          right: 25,
                          top: 10,
                        },
                      ]}
                    >
                      {withDividedLites ? (
                        <Image
                          style={{ height: 60, width: 30 }}
                          src={liteImg || ""}
                        />
                      ) : (
                        <Text />
                      )}
                      <Text style={[styles.text, { fontSize: 8 }]}>A</Text>
                    </View>

                    <View
                      style={[
                        styles.column,
                        {
                          alignItems: "center",
                          justifyContent: "center",
                          position: "absolute",
                          right: -10,
                          top: 10,
                        },
                      ]}
                    >
                      <Image
                        style={{ height: 60, width: 30 }}
                        src={sideliteImg || ""}
                      />
                      <Text style={[styles.text, { fontSize: 8 }]}>B</Text>
                    </View>
                  </>
                ) : (
                  <>
                    {withDividedLites ? (
                      <Image
                        style={{
                          height: 60,
                          width: 30,
                          position: "absolute",
                          right: 35,
                          // top: 10,
                        }}
                        src={liteImg || ""}
                      />
                    ) : (
                      <Text />
                    )}
                  </>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

export default UnitDetails;
