import { UnitObj } from "./quoteCartStorage";

export interface FixesObj {
  recommend?: string;
  recommend2?: string;
  recommend3?: string;
  label?: string;
  objKey?: string;
  choice?: string;
  fix?: string[];
  label2?: string;
  objKey2?: string;
  choice2?: string;
  fix2?: string[];
  label3?: string;
  objKey3?: string;
  choice3?: string;
  fix3?: string[];
  label4?: string;
  objKey4?: string;
  choice4?: string;
  fix4?: string[];
  recommendList?: any;
}

export default function checkForCommonHardware(unit: UnitObj) {
  const {
    doorType,
    headTrackColor,
    sillColor,
    exteriorCladColor,
    weatherStripColor,
    withHandle,
    handleStyle,
    handleColor,
    shootBoltColor,
    hingeColor,
  } = unit;

  let nonMatches: FixesObj[] = [];

  if (doorType === "Pivot" && unit.pivotBackPlateColor && hingeColor) {
    const allValues = [unit.pivotBackPlateColor, hingeColor];

    if (handleStyle === "Dallas Lever" && handleColor) {
      allValues.push(handleColor);
    }

    if (unit.pivotMagnet && unit.pivotMagnetColor) {
      allValues.push(unit.pivotMagnetColor);
    }

    if (
      allValues.every((itm) => itm.includes("Black")) ||
      allValues.every((itm) => itm === "Stainless Steel")
    ) {
      nonMatches = [...nonMatches];
    } else {
      const fix = ["Stainless Steel", "Black"];

      let fixes: FixesObj = {
        recommend: "Match Finishes",
        label: "Back Plate",
        objKey: "pivotBackPlateColor",
        choice: unit.pivotBackPlateColor,
        fix: fix,
        label2: "Hinge Plate",
        objKey2: "hingeColor",
        choice2: hingeColor,
        fix2: fix,
      };

      if (handleStyle === "Dallas Lever" && handleColor) {
        fixes["label3"] = "Handle";
        fixes["objKey3"] = "handleColor";
        fixes["choice3"] = handleColor;
        fixes["fix3"] = ["Stainless Steel", "Matte Black"];
      }

      if (unit.pivotMagnet && unit.pivotMagnetColor) {
        if (fixes["label3"]) {
          fixes["label4"] = "Ghostop";
          fixes["objKey4"] = "pivotMagnetColor";
          fixes["choice4"] = unit.pivotMagnetColor;
          fixes["fix4"] = fix;
        } else {
          fixes["label3"] = "Ghostop";
          fixes["objKey3"] = "pivotMagnetColor";
          fixes["choice3"] = unit.pivotMagnetColor;
          fixes["fix3"] = fix;
        }
      }

      nonMatches.push(fixes);
    }
  }

  if (headTrackColor && sillColor) {
    if (
      headTrackColor.replace("Anodized", "").trim() !==
      sillColor.replace("Anodized", "").trim()
    ) {
      const headTrackBw = !headTrackColor.includes("Anodized")
        ? `${headTrackColor} Anodized`
        : headTrackColor;

      let fixes: FixesObj = {
        label: "Head Track",
        objKey: "headTrackColor",
        choice: headTrackBw,
        fix: ["Bronze Anodized", "Clear Anodized"],
        recommend: "Match Head Track and Sill Finish",
        label2: "Sill",
        objKey2: "sillColor",
        choice2: sillColor,
        fix2: ["Bronze Anodized", "Clear Anodized"],
      };

      nonMatches.push(fixes);
    }
  }

  if (
    exteriorCladColor &&
    !exteriorCladColor.includes("Custom") &&
    weatherStripColor
  ) {
    const isMatch =
      ((exteriorCladColor === "Carbon Black" ||
        exteriorCladColor === "Espresso Brown" ||
        exteriorCladColor === "Sandcastle Tan") &&
        weatherStripColor !== "Bronze") ||
      ((exteriorCladColor === "Linen White" ||
        exteriorCladColor === "Clear Anodized") &&
        weatherStripColor !== "White")
        ? false
        : true;

    if (!isMatch) {
      let fixes: FixesObj = {
        label: "Clad",
        objKey: "exteriorCladColor",
        choice: exteriorCladColor,
        fix: [
          "Linen White",
          "Clear Anodized",
          "Carbon Black",
          "Espresso Brown",
          "Sandcastle Tan",
        ],
        recommend:
          "Carbon Black, Espresso Brown, or Sandcastle Tan Clad Finish with Bronze Weather Strip",
        recommend2:
          "Linen White or Clear Anodized Clad Finish with White Weather Strip",
        label2: "Weather Strip",
        objKey2: "weatherStripColor",
        choice2: weatherStripColor,
        fix2: ["Bronze", "White"],
      };

      nonMatches.push(fixes);
    }
  }

  if (
    handleStyle &&
    handleColor &&
    doorType !== "Slider" &&
    withHandle &&
    withHandle === "Add Handle Set"
  ) {
    let recommendList = {
      "Rustic Umber":
        "Bronze Shoot-Bolt with Bronze Hinges to match Rustic Umber Handle",
      "Oil Rubbed Bronze *":
        "Bronze Shoot-Bolt with Bronze Hinges to match Oil Rubbed Bronze Handle",
      "Satin Nickel(Resista Finish)":
        "Brushed Chrome Shoot-Bolt with Stainless Steel Hinges to match Satin Nickel Handle",
      "Matte Black":
        "Black Shoot-Bolt with Bronze Hinges to match Matte Black Handle",
      White:
        "White Shoot-Bolt with Stainless Steel Hinges to match White Handle",
    };

    if (handleColor === "Satin Nickel(Resista Finish)") {
      let fixes: FixesObj = {
        label: "Handle",
        objKey: "handleColor",
        choice: handleColor,
        fix: [],
        recommendList,
      };

      if (handleStyle === "Verona") {
        fixes.fix = [
          "Rustic Umber",
          "Matte Black",
          "Satin Nickel(Resista Finish)",
          "White",
          "Oil Rubbed Bronze *",
        ];
      } else if (handleStyle !== "Atlanta *") {
        fixes.fix = [
          "Rustic Umber",
          "Matte Black",
          "Satin Nickel(Resista Finish)",
        ];
      } else {
        fixes.fix = ["Matte Black", "Satin Nickel(Resista Finish)"];
      }

      if (shootBoltColor && hingeColor) {
        const pairedShootBolt = "Brushed Chrome";
        const pairedHinge = "Stainless Steel";

        shootBoltHingeHandle(
          fixes,
          shootBoltColor,
          hingeColor,
          pairedShootBolt,
          pairedHinge,
        );
      }

      if (fixes["label2"]) nonMatches.push(fixes);
    } else if (
      handleColor === "Rustic Umber" ||
      handleColor === "Oil Rubbed Bronze *"
    ) {
      let fixes: FixesObj = {
        label: "Handle",
        objKey: "handleColor",
        choice: handleColor,
        fix: [],
        recommendList,
      };

      if (handleStyle === "Verona") {
        fixes.fix = [
          "Rustic Umber",
          "Matte Black",
          "Satin Nickel(Resista Finish)",
          "White",
          "Oil Rubbed Bronze *",
        ];
      } else if (handleStyle !== "Atlanta *") {
        fixes.fix = [
          "Rustic Umber",
          "Matte Black",
          "Satin Nickel(Resista Finish)",
        ];
      } else {
        fixes.fix = ["Matte Black", "Satin Nickel(Resista Finish)"];
      }

      if (shootBoltColor && hingeColor) {
        const pairedShootBolt = "Bronze";
        const pairedHinge = "Bronze";

        shootBoltHingeHandle(
          fixes,
          shootBoltColor,
          hingeColor,
          pairedShootBolt,
          pairedHinge,
        );
      }

      if (fixes["label2"]) nonMatches.push(fixes);
    } else if (handleColor === "Matte Black") {
      let fixes: FixesObj = {
        label: "Handle",
        objKey: "handleColor",
        choice: handleColor,
        fix: [],
        recommendList,
      };

      if (handleStyle === "Verona") {
        fixes.fix = [
          "Rustic Umber",
          "Matte Black",
          "Satin Nickel(Resista Finish)",
          "White",
          "Oil Rubbed Bronze *",
        ];
      } else if (handleStyle !== "Atlanta *") {
        fixes.fix = [
          "Rustic Umber",
          "Matte Black",
          "Satin Nickel(Resista Finish)",
        ];
      } else {
        fixes.fix = ["Matte Black", "Satin Nickel(Resista Finish)"];
      }

      if (shootBoltColor && hingeColor) {
        const pairedShootBolt = "Black";
        const pairedHinge = "Bronze";

        shootBoltHingeHandle(
          fixes,
          shootBoltColor,
          hingeColor,
          pairedShootBolt,
          pairedHinge,
        );
      }

      if (fixes["label2"]) nonMatches.push(fixes);
    } else if (handleColor === "White") {
      let fixes: FixesObj = {
        label: "Handle",
        objKey: "handleColor",
        choice: handleColor,
        fix: [],
        recommendList,
      };

      if (handleStyle === "Verona") {
        fixes.fix = [
          "Rustic Umber",
          "Matte Black",
          "Satin Nickel(Resista Finish)",
          "White",
          "Oil Rubbed Bronze *",
        ];
      } else if (handleStyle !== "Atlanta *") {
        fixes.fix = [
          "Rustic Umber",
          "Matte Black",
          "Satin Nickel(Resista Finish)",
        ];
      } else {
        fixes.fix = ["Matte Black", "Satin Nickel(Resista Finish)"];
      }

      if (shootBoltColor && hingeColor) {
        const pairedShootBolt = "White";
        const pairedHinge = "Stainless Steel";

        shootBoltHingeHandle(
          fixes,
          shootBoltColor,
          hingeColor,
          pairedShootBolt,
          pairedHinge,
        );
      }

      if (fixes["label2"]) nonMatches.push(fixes);
    }
  } else {
    let fixes: FixesObj = {};

    if (shootBoltColor && hingeColor) {
      let pairedMatches = false;

      if (
        hingeColor === "Stainless Steel" &&
        shootBoltColor !== "Brushed Chrome" &&
        shootBoltColor !== "White"
      ) {
        pairedMatches = true;
      } else if (
        hingeColor !== "Stainless Steel" &&
        (shootBoltColor === "Brushed Chrome" || shootBoltColor === "White")
      ) {
        pairedMatches = true;
      } else if (
        shootBoltColor !== "Bronze" &&
        shootBoltColor !== "Black" &&
        hingeColor === "Bronze"
      ) {
        pairedMatches = true;
      } else if (
        (shootBoltColor === "Bronze" || shootBoltColor === "Black") &&
        hingeColor !== "Bronze"
      ) {
        pairedMatches = true;
      }

      if (pairedMatches) {
        fixes = {
          label: "Shoot Bolt",
          objKey: "shootBoltColor",
          choice: shootBoltColor,
          fix: ["Brushed Chrome", "Bronze", "Black", "White"],
          label2: "Hinge",
          objKey2: "hingeColor",
          choice2: hingeColor,
          fix2: ["Stainless Steel", "Bronze"],
          recommend: "Brushed Chrome Shoot-Bolt with Stainless Steel Hinges",
          recommend2: "White Shoot-Bolt with Stainless Steel Hinges",
          recommend3: "Bronze or Black Shoot-Bolt with Bronze Hinges",
        };
      }
    }

    if (fixes && Object.keys(fixes).length) nonMatches.push(fixes);
  }

  return nonMatches;
}

function shootBoltHingeHandle(
  fixes: FixesObj,
  unitShootBolt: string,
  unitHinge: string,
  shootBolt: string,
  hinge: string,
) {
  const shootBoltOptions = ["Brushed Chrome", "Bronze", "Black", "White"];
  const hingeOptions = ["Stainless Steel", "Bronze"];

  if (unitShootBolt !== shootBolt || unitHinge !== hinge) {
    fixes["label2"] = "Shoot Bolt";
    fixes["objKey2"] = "shootBoltColor";
    fixes["choice2"] = unitShootBolt;
    fixes["fix2"] = shootBoltOptions;

    fixes["label3"] = "Hinge";
    fixes["objKey3"] = "hingeColor";
    fixes["choice3"] = unitHinge;
    fixes["fix3"] = hingeOptions;
  }

  return fixes;
}
