import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const headCells = [
  { id: "view", numeric: false, disablePadding: true, label: "" },
  { id: "date", numeric: false, disablePadding: true, label: "Date Created" },
  { id: "time", numeric: false, disablePadding: true, label: "Time Created" },
  { id: "name", numeric: false, disablePadding: false, label: "Customer Name" },
  {
    id: "quote number",
    numeric: true,
    disablePadding: false,
    label: "Quote Number",
  },
  {
    id: "job name",
    numeric: false,
    disablePadding: false,
    label: "Project Name",
  },
  {
    id: "po number",
    numeric: false,
    disablePadding: false,
    label: "PO Number",
  },
  {
    id: "num of units",
    numeric: true,
    disablePadding: false,
    label: "Items in Cart",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

const headCellsManager = [
  { id: "view", numeric: false, disablePadding: true, label: "" },
  { id: "date", numeric: false, disablePadding: true, label: "Date Created" },
  { id: "time", numeric: false, disablePadding: true, label: "Time Created" },
  { id: "name", numeric: false, disablePadding: false, label: "Customer Name" },
  {
    id: "quote number",
    numeric: true,
    disablePadding: false,
    label: "Quote Number",
  },
  {
    id: "dealerRep",
    numeric: false,
    disablePadding: false,
    label: "Dealer Rep",
  },
  {
    id: "job name",
    numeric: false,
    disablePadding: false,
    label: "Project Name",
  },
  {
    id: "po number",
    numeric: false,
    disablePadding: false,
    label: "PO Number",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Dealer Net Cost",
  },
  {
    id: "num of units",
    numeric: true,
    disablePadding: false,
    label: "Items in Cart",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

const dealerOrderheadCells = [
  { id: "date", numeric: false, disablePadding: true, label: "Sign-Off Date" },
  { id: "name", numeric: false, disablePadding: false, label: "Customer Name" },
  {
    id: "quote number",
    numeric: true,
    disablePadding: false,
    label: "Quote Number",
  },
  {
    id: "job name",
    numeric: false,
    disablePadding: false,
    label: "Project Name",
  },
  {
    id: "po number",
    numeric: false,
    disablePadding: false,
    label: "PO Number",
  },
  {
    id: "num of units",
    numeric: true,
    disablePadding: false,
    label: "Items in Cart",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

const managerOrderheadCells = [
  { id: "date", numeric: false, disablePadding: true, label: "Sign-Off Date" },
  { id: "name", numeric: false, disablePadding: false, label: "Customer Name" },
  {
    id: "quote number",
    numeric: true,
    disablePadding: false,
    label: "Quote Number",
  },
  {
    id: "dealerRep",
    numeric: false,
    disablePadding: false,
    label: "Dealer Rep",
  },
  {
    id: "job name",
    numeric: false,
    disablePadding: false,
    label: "Project Name",
  },
  {
    id: "po number",
    numeric: false,
    disablePadding: false,
    label: "PO Number",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Dealer Net Cost",
  },
  {
    id: "num of units",
    numeric: true,
    disablePadding: false,
    label: "Items in Cart",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

const headCellsOrder = [
  { id: "view", numeric: false, disablePadding: true, label: "" },
  { id: "date", numeric: false, disablePadding: false, label: "Sign-Off Date" },
  { id: "downloadOrder", numeric: false, disablePadding: true, label: "Order" },
  { id: "name", numeric: false, disablePadding: false, label: "Customer Name" },
  {
    id: "quote number",
    numeric: true,
    disablePadding: false,
    label: "Quote Number",
  },
  {
    id: "downloadProd",
    numeric: false,
    disablePadding: true,
    label: "Production",
  },
  { id: "dealer", numeric: false, disablePadding: false, label: "Dealer" },
  {
    id: "dealer rep",
    numeric: false,
    disablePadding: false,
    label: "Dealer Rep",
  },
  {
    id: "po number",
    numeric: false,
    disablePadding: false,
    label: "PO Number",
  },
  { id: "so number", numeric: false, disablePadding: false, label: "SO #" },
  {
    id: "invoice number",
    numeric: false,
    disablePadding: false,
    label: "Invoice #",
  },
  {
    id: "dealer cost",
    numeric: true,
    disablePadding: false,
    label: "Dealer Net Cost",
  },
  {
    id: "num of units",
    numeric: true,
    disablePadding: false,
    label: "Items in Cart",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

const headCellsAdmin = [
  { id: "view", numeric: false, disablePadding: true, label: "" },
  { id: "download", numeric: false, disablePadding: true, label: "Quote" },
  { id: "date", numeric: false, disablePadding: false, label: "Date Created" },
  { id: "time", numeric: false, disablePadding: false, label: "Time Created" },
  { id: "name", numeric: false, disablePadding: false, label: "Customer Name" },
  {
    id: "quote number",
    numeric: true,
    disablePadding: false,
    label: "Quote Number",
  },
  { id: "dealer", numeric: false, disablePadding: false, label: "Dealer" },
  {
    id: "dealer rep",
    numeric: false,
    disablePadding: false,
    label: "Dealer Rep",
  },
  {
    id: "po number",
    numeric: false,
    disablePadding: false,
    label: "PO Number",
  },
  {
    id: "dealer cost",
    numeric: true,
    disablePadding: false,
    label: "Dealer Net Cost",
  },
  {
    id: "num of units",
    numeric: true,
    disablePadding: false,
    label: "Items in Cart",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

const outsideSalesHeadOrder = [
  { id: "date", numeric: false, disablePadding: false, label: "Sign-Off Date" },
  { id: "downloadOrder", numeric: false, disablePadding: true, label: "Order" },
  { id: "name", numeric: false, disablePadding: false, label: "Customer Name" },
  {
    id: "quote number",
    numeric: true,
    disablePadding: false,
    label: "Quote Number",
  },
  { id: "dealer", numeric: false, disablePadding: false, label: "Dealer" },
  {
    id: "dealer rep",
    numeric: false,
    disablePadding: false,
    label: "Dealer Rep",
  },
  {
    id: "po number",
    numeric: false,
    disablePadding: false,
    label: "PO Number",
  },
  {
    id: "dealer cost",
    numeric: true,
    disablePadding: false,
    label: "Dealer Net Cost",
  },
  {
    id: "num of units",
    numeric: true,
    disablePadding: false,
    label: "Items in Cart",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

const outsideSalesHead = [
  { id: "view", numeric: false, disablePadding: true, label: "" },
  { id: "download", numeric: false, disablePadding: true, label: "Quote" },
  { id: "date", numeric: false, disablePadding: false, label: "Date Created" },
  { id: "time", numeric: false, disablePadding: false, label: "Time Created" },
  { id: "name", numeric: false, disablePadding: false, label: "Customer Name" },
  {
    id: "quote number",
    numeric: true,
    disablePadding: false,
    label: "Quote Number",
  },
  { id: "dealer", numeric: false, disablePadding: false, label: "Dealer" },
  {
    id: "dealer rep",
    numeric: false,
    disablePadding: false,
    label: "Dealer Rep",
  },
  {
    id: "po number",
    numeric: false,
    disablePadding: false,
    label: "PO Number",
  },
  {
    id: "dealer cost",
    numeric: true,
    disablePadding: false,
    label: "Dealer Net Cost",
  },
  {
    id: "num of units",
    numeric: true,
    disablePadding: false,
    label: "Items in Cart",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

const headCellsCompanyManager = [
  { id: "view", numeric: false, disablePadding: true, label: "" },
  { id: "date", numeric: false, disablePadding: true, label: "Date Created" },
  { id: "time", numeric: false, disablePadding: true, label: "Time Created" },
  { id: "name", numeric: false, disablePadding: false, label: "Customer Name" },
  {
    id: "quote number",
    numeric: true,
    disablePadding: false,
    label: "Quote Number",
  },
  { id: "dealer", numeric: false, disablePadding: false, label: "Dealer" },
  {
    id: "dealerRep",
    numeric: false,
    disablePadding: false,
    label: "Dealer Rep",
  },
  {
    id: "job name",
    numeric: false,
    disablePadding: false,
    label: "Project Name",
  },
  {
    id: "po number",
    numeric: false,
    disablePadding: false,
    label: "PO Number",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Dealer Net Cost",
  },
  {
    id: "num of units",
    numeric: true,
    disablePadding: false,
    label: "Items in Cart",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

const companyManagerOrderheadCells = [
  { id: "date", numeric: false, disablePadding: true, label: "Sign-Off Date" },
  { id: "name", numeric: false, disablePadding: false, label: "Customer Name" },
  {
    id: "quote number",
    numeric: true,
    disablePadding: false,
    label: "Quote Number",
  },
  { id: "dealer", numeric: false, disablePadding: false, label: "Dealer" },
  {
    id: "dealerRep",
    numeric: false,
    disablePadding: false,
    label: "Dealer Rep",
  },
  {
    id: "job name",
    numeric: false,
    disablePadding: false,
    label: "Project Name",
  },
  {
    id: "po number",
    numeric: false,
    disablePadding: false,
    label: "PO Number",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Dealer Net Cost",
  },
  {
    id: "num of units",
    numeric: true,
    disablePadding: false,
    label: "Items in Cart",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

const productionHeadCells = [
  {
    id: "downloadLabel",
    numeric: false,
    disablePadding: false,
    label: "Production",
  },
  {
    id: "quote number",
    numeric: true,
    disablePadding: false,
    label: "Quote Number",
  },
  { id: "dealer", numeric: false, disablePadding: false, label: "Dealer" },
  {
    id: "dealer rep",
    numeric: false,
    disablePadding: false,
    label: "Dealer Rep",
  },
  {
    id: "po number",
    numeric: false,
    disablePadding: false,
    label: "PO Number",
  },
  {
    id: "so number",
    numeric: false,
    disablePadding: false,
    label: "SO Number",
  },
  {
    id: "num of units",
    numeric: true,
    disablePadding: false,
    label: "Items in Cart",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

const headCellsInsights = [
  { id: "view", numeric: false, disablePadding: true, label: "" },
  {
    id: "dateCreated",
    numeric: false,
    disablePadding: true,
    label: "Date Created",
  },
  { id: "date", numeric: false, disablePadding: false, label: "Sign-Off Date" },
  { id: "downloadOrder", numeric: false, disablePadding: true, label: "PDF" },
  { id: "name", numeric: false, disablePadding: false, label: "Customer Name" },
  {
    id: "quote number",
    numeric: true,
    disablePadding: false,
    label: "Quote Number",
  },
  { id: "dealer", numeric: false, disablePadding: false, label: "Dealer" },
  {
    id: "dealer rep",
    numeric: false,
    disablePadding: false,
    label: "Dealer Rep",
  },
  {
    id: "po number",
    numeric: false,
    disablePadding: false,
    label: "PO Number",
  },
  { id: "so number", numeric: false, disablePadding: false, label: "SO #" },
  {
    id: "invoice number",
    numeric: false,
    disablePadding: false,
    label: "Invoice #",
  },
  {
    id: "dealer cost",
    numeric: true,
    disablePadding: false,
    label: "Dealer Net Cost",
  },
  {
    id: "num of units",
    numeric: true,
    disablePadding: false,
    label: "Items in Cart",
  },
  { id: "daysToClose", numeric: false, disablePadding: true, label: "Timing" },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

const headCellsInsightsOutsideSales = [
  { id: "view", numeric: false, disablePadding: true, label: "" },
  {
    id: "dateCreated",
    numeric: false,
    disablePadding: true,
    label: "Date Created",
  },
  { id: "date", numeric: false, disablePadding: false, label: "Sign-Off Date" },
  { id: "downloadOrder", numeric: false, disablePadding: true, label: "PDF" },
  { id: "name", numeric: false, disablePadding: false, label: "Customer Name" },
  {
    id: "quote number",
    numeric: true,
    disablePadding: false,
    label: "Quote Number",
  },
  { id: "dealer", numeric: false, disablePadding: false, label: "Dealer" },
  {
    id: "dealer rep",
    numeric: false,
    disablePadding: false,
    label: "Dealer Rep",
  },
  {
    id: "po number",
    numeric: false,
    disablePadding: false,
    label: "PO Number",
  },
  {
    id: "dealer cost",
    numeric: true,
    disablePadding: false,
    label: "Dealer Net Cost",
  },
  {
    id: "num of units",
    numeric: true,
    disablePadding: false,
    label: "Items in Cart",
  },
  { id: "daysToClose", numeric: false, disablePadding: true, label: "Timing" },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

const head = (role: string, tab: string) => {
  if (tab === "Insights") {
    if (role === "Outside Sales") {
      return headCellsInsightsOutsideSales;
    }
    return headCellsInsights;
  }

  if (role === "Dealer") {
    return tab === "Quotes" ? headCells : dealerOrderheadCells;
  }

  if (role === "Manager") {
    return tab === "Quotes" ? headCellsManager : managerOrderheadCells;
  }

  if (role === "Company Manager") {
    return tab === "Quotes"
      ? headCellsCompanyManager
      : companyManagerOrderheadCells;
  }

  if (role === "Admin" || role === "Inside Sales") {
    return tab === "Quotes" ? headCellsAdmin : headCellsOrder;
  }

  if (role === "Outside Sales") {
    return tab === "Quotes" ? outsideSalesHead : outsideSalesHeadOrder;
  }

  if (role === "Production") {
    return productionHeadCells;
  }
};

function EnhancedTableHead({ showTab }: { showTab: string }) {
  const role = JSON.parse(sessionStorage.getItem("role") || JSON.stringify(""));

  const headArray = head(role, showTab);

  return (
    <TableHead style={{ backgroundColor: "#4b4b4b" }}>
      <TableRow>
        <TableCell></TableCell>
        {headArray?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            style={{ color: "white" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
