import React, { memo } from "react";
import ReusableAccordian from "../ReusableAccordion";
import { useStore } from "../../store/store";
import { useAccordion } from "../../store/accordion";
import { dallas, byOthers } from "./defaults";
import Choices from "../Choices";
import { Image } from "cloudinary-react";
import { Select, MenuItem, Typography, SelectChangeEvent } from "@mui/material";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import InfoIcon from "@mui/icons-material/Info";
import { RowLayout, ColLayout } from "../../util/accordianStyles";

interface UpdateObj {
  handleStyle: string;
  exactConfigImg?: string;
}

function PivotHandleAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);
  const handleStyle = useStore((state) => state.quote.handleStyle);
  const handleColor = useStore((state) => state.quote.handleColor);

  const exactConfigImg = useStore((state) => state.quote.exactConfigImg);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[16].expanded);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleClick = (label: string) => {
    const checked = label === handleStyle;

    if (checked) {
      addToQuote({ handleStyle: "" }, "handleStyle");
    } else {
      let update: UpdateObj = { handleStyle: label };

      const configIMG = !exactConfigImg
        ? ""
        : label === "Dallas Lever" && !exactConfigImg.includes("LEVER")
          ? `${exactConfigImg.split(".jpg")[0]}_LEVER.jpg`
          : label === "Handle Pull by Others" &&
              exactConfigImg.includes("LEVER")
            ? exactConfigImg.replace("_LEVER.jpg", ".jpg")
            : "";

      if (configIMG) {
        update["exactConfigImg"] = configIMG;
      }

      addToQuote(update);
    }
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    addToQuote({ handleColor: value }, "handleColor");

    if (value) {
      if (findRequired("Handle Finish")) {
        removeRequired("Handle Finish");
      }
    }
  };

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="handle"
      heading="Pivot Handle"
      secondaryHeading="Select Pivot Handle"
      required={true}
      colOrRow="col"
    >
      <RowLayout style={{ paddingBottom: 20 }}>
        <InfoIcon color="primary" style={{ marginRight: 10 }} />

        <Typography>
          Comes equipped with multi-point locking hardware as standard.
        </Typography>
      </RowLayout>

      <RowLayout>
        <div onClick={() => handleClick(byOthers.label)}>
          <Choices
            label={
              <>
                <span>Handle Pull</span>{" "}
                <span style={{ fontWeight: "bold" }}>by Others</span>
              </>
            }
            variant={byOthers.label === handleStyle ? "filled" : "outlined"}
            checkMark={byOthers.label === handleStyle}
            wide={true}
          />
        </div>

        <div onClick={() => handleClick(dallas.label)}>
          <Choices
            label={dallas.label}
            variant={dallas.label === handleStyle ? "filled" : "outlined"}
            checkMark={dallas.label === handleStyle}
            wide={true}
          />
        </div>
      </RowLayout>

      {handleStyle === "Dallas Lever" && (
        <RowLayout style={{ paddingTop: 20 }}>
          <ColLayout style={{ marginRight: 20 }}>
            <Typography style={{ fontWeight: "bold" }}>
              Lever Finish{" "}
              <span style={{ color: "red", fontWeight: "bold", fontSize: 22 }}>
                *
              </span>
            </Typography>

            <Select
              onChange={handleChange}
              value={handleColor}
              style={{ minWidth: 150 }}
              size="small"
            >
              <MenuItem value="" />
              <MenuItem value="Stainless Steel">Stainless Steel</MenuItem>
              <MenuItem value="Matte Black">Matte Black</MenuItem>
            </Select>
          </ColLayout>

          <Image
            cloudName="ag-millworks"
            secure="true"
            publicId={`Handles/Pivot_Dallas_Lever_${
              handleColor === "Matte Black" ? "Black" : "Steel"
            }`}
          />
        </RowLayout>
      )}
    </ReusableAccordian>
  );
}

export default memo(PivotHandleAccordian);
