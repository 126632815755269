import React, { FC } from "react";
import { Chip } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { styled } from "@mui/material/styles";

const StyledDivRow = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  width: "50%",
});

const StyledErr = styled("span")({
  color: "red",
  postion: "absolute",
  right: 0,
  fontSize: 18,
});

interface DeliveryChipsProps {
  checkedDealer: boolean;
  checkedJobsite: boolean;
  handleCheck: (deliveryType: string) => void;
  handleDelete: () => void;
}

const DeliveryChips: FC<DeliveryChipsProps> = ({
  checkedDealer,
  checkedJobsite,
  handleCheck,
  handleDelete,
}) => {
  return (
    <StyledDivRow
      style={{ paddingBottom: checkedDealer ? 30 : checkedJobsite ? 10 : 0 }}
    >
      <div style={{ marginRight: 40 }}>
        {checkedDealer ? (
          <Chip
            label={
              <div style={{ position: "relative" }}>
                <span style={{ fontFamily: "Arial" }}>Ship to Dealer</span>
                <StyledErr> *</StyledErr>
              </div>
            }
            onClick={() => handleCheck("dealer")}
            color="primary"
            onDelete={handleDelete}
            deleteIcon={<DoneIcon />}
            variant={"filled"}
          />
        ) : (
          <Chip
            label={
              <div style={{ position: "relative" }}>
                <span style={{ fontFamily: "Arial" }}>Ship to Dealer</span>
                {!checkedJobsite && <StyledErr> *</StyledErr>}
              </div>
            }
            onClick={() => handleCheck("dealer")}
            color="primary"
            variant={"outlined"}
          />
        )}
      </div>

      <div style={{ marginLeft: 40 }}>
        {checkedJobsite ? (
          <Chip
            label={
              <div style={{ position: "relative" }}>
                <span style={{ fontFamily: "Arial" }}>Ship to Jobsite</span>
                <StyledErr> *</StyledErr>
              </div>
            }
            onClick={() => handleCheck("jobsite")}
            color="primary"
            onDelete={handleDelete}
            deleteIcon={<DoneIcon />}
            variant={"filled"}
          />
        ) : (
          <Chip
            label={
              <div style={{ position: "relative" }}>
                <span style={{ fontFamily: "Arial" }}>Ship to Jobsite</span>
                {!checkedDealer && <StyledErr> *</StyledErr>}
              </div>
            }
            onClick={() => handleCheck("jobsite")}
            color="primary"
            variant={"outlined"}
          />
        )}
      </div>
    </StyledDivRow>
  );
};

export default DeliveryChips;
