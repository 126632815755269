import React, { FC } from "react";
import { TableCell, Checkbox } from "@mui/material";
import StatusTooltip from "./StatusTooltip";
import ProductionPdf from "./pdf/ProductionPdf";
import { TableCellsProps } from "../RoleTableCells";
import { CutsheetDataObj, QuoteObj, UserObj, Overwrite } from "../../../../GlobalTypes";

type AdminQuoteObj = Overwrite<QuoteObj, { userId: UserObj }>;

interface ProductionCellsProps extends TableCellsProps {
  loadingCutsheet: boolean;
  cutsheetData: CutsheetDataObj[];
}

const ProductionCells: FC<ProductionCellsProps> = ({
  row,
  tab,
  viewPdfId,
  loadingCutsheet,
  isItemSelected,
  handleCheck,
  cutsheetData,
  handleViewPdf,
}) => {
  const labelId = `enhanced-table-checkbox-${row._id}`;

  return (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          onClick={(event: React.MouseEvent<any>) => handleCheck(event, row as any)}
          checked={isItemSelected}
          inputProps={{ "aria-labelledby": labelId }}
        />
      </TableCell>

      {tab === "Quotes" && (
        <>
          <TableCell align="center" scope="row" padding="none">
            {new Date(String(row.updatedAt)).toLocaleDateString("en-US")}
          </TableCell>
          <TableCell align="center" scope="row" padding="none">
            {row.updatedAt
              ? new Date(row.updatedAt).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : "N/A"}
          </TableCell>
        </>
      )}

      {tab === "Orders" && (
        <ProductionPdf
          row={row as AdminQuoteObj}
          handleViewPdf={handleViewPdf || null}
          viewPdfId={viewPdfId || ""}
          loadingCutsheet={loadingCutsheet}
          cutsheetData={cutsheetData}
        />
      )}

      <TableCell align="center">{row.quoteNumber}</TableCell>

      <TableCell align="center">{row.dealer}</TableCell>

      <TableCell align="center">{row.salesperson}</TableCell>

      <TableCell align="center">{row.poNumber || "None"}</TableCell>

      {tab === "Orders" && (
        <TableCell align="center">{row.salesOrder || "N/A"}</TableCell>
      )}

      <TableCell align="center">{row.totalUnits || "None"}</TableCell>

      <StatusTooltip role="Production" row={row as QuoteObj} tab={tab} />
    </>
  );
};

export default ProductionCells;
