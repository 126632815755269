import React, { memo } from "react";
import Choices from "../Choices";
import ReusableAccordian from "../ReusableAccordion";
import { useStore } from "../../store/store";
import { useAccordion } from "../../store/accordion";
import { material } from "./defaults";
import { RowLayout } from "../../util/accordianStyles";

interface UpdateObj {
  material: string;
  jamb: string;
  addScreen: string;
  screenPocket: string;
  screenColor: string;
  woodSpecies: string;
  stilesAndRails: string;
  stileCategory: string;
  lockRail: string;
  exteriorCladColor: string;
  addDL?: string;
  liteConfig?: string;
}

function MaterialAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[0].expanded);

  const materialChoice = useStore((state) => state.quote.material);
  const addDL = useStore((state) => state.quote.addDL);

  const handleClick = (label: string) => {
    const checked = checkMark(label);

    if (checked) return;

    let update: UpdateObj = {
      material: label,
      jamb: "",
      addScreen: "",
      screenPocket: "",
      screenColor: "",
      woodSpecies: "",
      stilesAndRails: "",
      stileCategory: "",
      lockRail: "",
      exteriorCladColor: "",
    };

    if (materialChoice === "All Wood" && addDL === "Add TDL") {
      update["addDL"] = "";
      update["liteConfig"] = "";
    }

    addToQuote(update);
  };

  const variant = (label: string) =>
    label === materialChoice ? "filled" : "outlined";

  const checkMark = (label: string) => label === materialChoice;

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="material"
      heading="Material"
      secondaryHeading="Select material"
      required={false}
      required5={true}
    >
      <RowLayout>
        {material.map((state) => (
          <div key={state.id} onClick={() => handleClick(state.label)}>
            <Choices
              label={state.label}
              variant={variant(state.label)}
              checkMark={checkMark(state.label)}
            />
          </div>
        ))}
      </RowLayout>
    </ReusableAccordian>
  );
}

export default memo(MaterialAccordian);
