import React, { useState, useEffect, FC } from "react";
import {
  Modal,
  Typography,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import getModalStyle from "../../../util/getModalStyle";
import { useQueryClient } from "react-query";
import round from "../../../util/round";
import { postAdminCharge } from "../../../api/OrderSummaryApi";
import {
  StyledModalDiv,
  RowLayout,
  ColLayout,
} from "../../../util/accordianStyles";

interface AdminCostModalProps {
  open: boolean;
  handleClose: () => void;
  charge: string;
  withMargin: boolean | "";
  chargeLabel: string;
  quoteId: string;
  userId: string;
}

const AdminCostModal: FC<AdminCostModalProps> = ({
  open,
  handleClose,
  charge,
  withMargin,
  chargeLabel,
  quoteId,
  userId,
}) => {
  const queryClient = useQueryClient();

  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const [cost, setCost] = useState({ charge: "", label: "" });

  const [err, setErr] = useState("");

  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!charge && !chargeLabel) return;

    setCost({ charge, label: chargeLabel });

    if (typeof withMargin === "boolean") {
      setValue(withMargin ? "Dealer/Customer Price" : "Dealer Price");
    }
  }, []);

  const handleCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCost({ ...cost, [name]: value });
  };

  const handleChange = (event: SelectChangeEvent<string>) =>
    setValue(event.target.value || "");

  const addAdminCost = async () => {
    if ((!cost.charge && cost.label) || (!cost.label && cost.charge)) {
      return setErr(
        "Please Fill Out Both Fields Or Leave Both Empty to Remove the Charge",
      );
    }

    if (!value) {
      return setErr("Please make a selection from the dropdown");
    }

    setErr("");
    setLoading(true);

    const response = await postAdminCharge(
      cost.charge ? round(parseFloat(cost.charge)) : 0,
      cost.label,
      value === "Dealer/Customer Price" ? true : false,
      quoteId,
      userId,
    );

    if (response.data === "Admin Charge Added") {
      queryClient.invalidateQueries("Cart");
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
      setErr("Error: A Problem Occurred when Adding the Charge");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if(event.key === "Enter"){
      return addAdminCost()
    }
  }

  const preventEnterEffects = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(e.key === "Enter"){
      e.preventDefault()
    }
  }

  const body = (
    <StyledModalDiv style={modalStyle} onKeyDown={handleKeyDown}>
      <ColLayout>
        <h2>Add a Charge</h2>

        <RowLayout style={{ paddingBottom: 20 }}>
          <input
            name="label"
            placeholder="Type of Charge"
            autoComplete="off"
            value={cost.label}
            onChange={handleCostChange}
            style={{ padding: 5, width: 150, marginRight: 20 }}
            onKeyDown={preventEnterEffects}
          />

          <input
            step="any"
            type="number"
            name="charge"
            autoComplete="off"
            placeholder="Amount of Charge"
            value={cost.charge}
            onChange={handleCostChange}
            style={{ padding: 5, width: 150 }}
            onKeyDown={preventEnterEffects}
          />
        </RowLayout>

        <ColLayout>
          <Typography
            style={{ color: "black", fontWeight: "bold", paddingBottom: 5 }}
          >
            Include In:
          </Typography>
          <Select
            onChange={handleChange}
            style={{ width: 250 }}
            value={value}
            size="small"
          >
            <MenuItem value=""></MenuItem>
            <MenuItem value="Dealer/Customer Price">
              Dealer/Customer Price
            </MenuItem>
            <MenuItem value="Dealer Price">Dealer Price</MenuItem>
          </Select>
        </ColLayout>

        {err && (
          <Typography style={{ color: "red", paddingTop: 20 }}>
            {err}
          </Typography>
        )}

        <RowLayout style={{ paddingTop: 20 }}>
          <Button
            onClick={handleClose}
            variant="contained"
            style={{ marginRight: 10 }}
            color="inherit"
          >
            Cancel
          </Button>

          {!loading ? (
            <Button
              onClick={addAdminCost}
              color="primary"
              variant="contained"
              style={{ marginLeft: 10 }}
            >
              Add
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              style={{ marginLeft: 10 }}
            >
              <CircularProgress style={{ color: "white" }} />
            </Button>
          )}
        </RowLayout>
      </ColLayout>
    </StyledModalDiv>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

export default AdminCostModal;
