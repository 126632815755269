import React, { useEffect, useState, useMemo } from "react";
import "./required.css";
import { useStore } from "../../store/store";
import { WrapperOver240, WrapperUnder240 } from "./Wrappers";

function RequiredDisplayPivot() {
  const material = useStore((state) => state.quote.material);
  const woodSpecies = useStore((state) => state.quote.woodAbbrev);
  const unitSize = useStore((state) => state.quote.unitSize);
  const unitSizeFraction = useStore((state) => state.quote.unitSizeFraction);
  const height = useStore((state) => state.quote.height);
  const heightFraction = useStore((state) => state.quote.heightFraction);
  const exactConfig = useStore((state) => state.quote.exactConfig);
  const pivotHardware = useStore((state) => state.quote.pivotHardware);
  const pivotAction = useStore((state) => state.quote.pivotAction);

  const [scrollPosition, setScrollPosition] = useState<number | null>(null);

  const listenScrollEvent = () => setScrollPosition(window.scrollY);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  });

  const header = useMemo(() => {
    return (
      <div className="header">
        <p>
          {material ? (
            <>
              <span style={{ color: "#4b4b4b" }}>Material - </span>
              {material}
            </>
          ) : (
            "Material"
          )}
        </p>
        <p>
          {woodSpecies ? (
            <>
              <span style={{ color: "#4b4b4b" }}>Wood - </span>
              {woodSpecies}
            </>
          ) : (
            "Wood Species"
          )}
        </p>
        <p>
          {unitSize ? (
            <>
              <span style={{ color: "#4b4b4b" }}>Width - </span> {unitSize}{" "}
              {unitSizeFraction} inches
            </>
          ) : (
            "Width"
          )}
        </p>
        <p>
          {height ? (
            <>
              <span style={{ color: "#4b4b4b" }}>Height - </span> {height}{" "}
              {heightFraction} inches
            </>
          ) : (
            "Height"
          )}
        </p>
        <p>
          {exactConfig ? (
            <>
              <span style={{ color: "#4b4b4b" }}>Configuration -</span>{" "}
              {exactConfig}
            </>
          ) : (
            "Configuration"
          )}
        </p>
        <p>
          {pivotHardware && pivotAction ? (
            <>
              <span style={{ color: "#4b4b4b" }}>Hardware -</span>{" "}
              {pivotHardware} {pivotAction}
            </>
          ) : (
            "Hardware"
          )}
        </p>
      </div>
    );
  }, [
    material,
    woodSpecies,
    unitSize,
    unitSizeFraction,
    height,
    heightFraction,
    exactConfig,
    pivotHardware,
    pivotAction,
  ]);

  return (
    <>
      {scrollPosition && scrollPosition >= 240 ? (
        <WrapperOver240>{header}</WrapperOver240>
      ) : (
        <WrapperUnder240>{header}</WrapperUnder240>
      )}
    </>
  );
}

export default RequiredDisplayPivot;
