import React, { FC } from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import AccountDropdown from "../AccountDropdown";
import { AppBar, useMediaQuery, Toolbar, Button } from "@mui/material";
import QuoteNumber from "../QuoteNumber";
import { HistoryState } from "../AccountDropdown";
import { styled } from "@mui/material/styles";

const StyledAppBar = styled(AppBar)({
  marginBottom: "5vh",
});

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const StyledDivFlexEnd = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
});

const StyledAGLogo = styled("img")({
  height: 80,
  width: 300,
});

const StyledAGLogoSMall = styled("img")({
  height: 80,
  width: 60,
});

const StyledDivFlexEnd2 = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
});

interface NavbarProps {
  goBackFunction?: () => void;
  btnText?: string;
  quoteNumber?: number | null;
  returnedPath?: string;
  historyState: HistoryState;
}

const Navbar: FC<NavbarProps> = ({
  goBackFunction,
  btnText,
  quoteNumber,
  returnedPath,
  historyState,
}) => {
  const matches = useMediaQuery("(min-width:1000px)", { noSsr: true });

  const changeLogo = useMediaQuery("(min-width:700px)", { noSsr: true });

  return (
    <StyledAppBar color="customGrey" position="fixed">
      <StyledToolbar>
        <Button
          variant="contained"
          color="cyan"
          style={{ color: "white" }}
          onClick={goBackFunction}
        >
          <NavigateBeforeIcon style={{ display: "inline" }} />{" "}
          {matches && btnText}
        </Button>
        <StyledDivFlexEnd>
          {changeLogo ? (
            <StyledAGLogo
              src="https://res.cloudinary.com/ag-millworks/image/upload/AG_Logos/AGlogoSmall.png"
              alt="Company Logo"
            />
          ) : (
            <StyledAGLogoSMall
              src="https://res.cloudinary.com/ag-millworks/image/upload/v1640825559/doorlogo.png"
              alt=""
            />
          )}
        </StyledDivFlexEnd>

        <StyledDivFlexEnd2>
          {quoteNumber && <QuoteNumber quoteNumber={quoteNumber} />}
          <AccountDropdown
            color="white"
            resumeLaterBtn={false}
            returnedPath={returnedPath}
            historyState={historyState}
          />
        </StyledDivFlexEnd2>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default Navbar;
