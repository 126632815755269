import { useState, useEffect } from "react";

function useSessionState(key: string, defaultValue: any) {
  const [state, setState] = useState(
    () =>
      JSON.parse(sessionStorage.getItem(key) || JSON.stringify("")) ||
      defaultValue,
  );

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}

export default useSessionState;
