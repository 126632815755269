import round from "./round";
import formatIntWithCommas from "./formatIntWithCommas";

export function calcWithAdminCharge(
  price: string,
  adminCharge: number,
): string {
  const priceAsInt: number = parseFloat(price.replace(/,/g, ""));
  const priceWithCharge: number = round(priceAsInt + adminCharge);

  return formatIntWithCommas(priceWithCharge);
}
