import React, {
  useEffect,
  useRef,
  useState,
  FC,
  Dispatch,
  SetStateAction,
} from "react";
import { useStore } from "../../store/store";
import fractionToInt from "../../util/fractionToInt";
import { calculatePanelWidth } from "../../util/pdfFormulas/calculatePanelWidth";
import { calculatePanelHeight } from "../../util/pdfFormulas/calculatePanelHeight";
import { calculateCornerPanelWidth } from "../../util/pdfFormulas/calculateCornerPanelWidth";
import { convertDecimalToFraction } from "../../util/pdfFormulas/convertDecimalToFraction";
import { woodImgs } from "../Wood/defaults";
import {
  pocketSliderCalc,
  findHandlePosition,
  findNumPanelsBifold,
  findNumPanelsSlider,
  calculateDLO,
  findNumPanelsSwing,
  frameDimensions,
  findNumPanelsPivot,
  handleImage,
  getScale,
  getGaps,
  findHandlePositionSwing,
  findHandlePositionSlider,
  pivotBackPlateImage,
} from "./helpers";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: 20,
  paddingTop: 0,
});

const cladColors = {
  "Carbon Black": "#141615",
  "Espresso Brown": "#3f3931",
  "Sandcastle Tan": "#998d76",
  "Linen White": "#eeeeee",
  "Clear Anodized": "#b0b4b4",
};

const cloudinaryWoodURL =
  "https://res.cloudinary.com/ag-millworks/image/upload/a_90/WoodSpecies";

const cloudinaryWoodURL2 =
  "https://res.cloudinary.com/ag-millworks/image/upload/WoodSpecies";

type FillRectArgs = [number, number, number, number];

interface CanvasBoxProps {
  canvasWidth: number;
  canvasHeight: number;
  containerWindow?: Window;
  setSize: Dispatch<SetStateAction<{ width: number; height: number }>>;
}

const CanvasBox: FC<CanvasBoxProps> = ({
  canvasWidth,
  canvasHeight,
  containerWindow,
  setSize,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasRefPortal = useRef<HTMLCanvasElement>(null);

  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (containerWindow) {
      const handleResize = () => {
        const newWidth = containerWindow.innerWidth - 70;
        const newHeight = containerWindow.innerHeight - 70;

        setSize({
          width: newWidth,
          height: newHeight,
        });
      };

      containerWindow.addEventListener("resize", handleResize);
      handleResize(); // Initial size update

      return () => {
        containerWindow.removeEventListener("resize", handleResize);
      };
    }
  }, [containerWindow]);

  const clearCanvas = (ctx: React.RefObject<HTMLCanvasElement>) => {
    const canvas = ctx.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
    }
  };

  const doorType = useStore((state) => state.quote.doorType);

  const isCorner = doorType?.includes("Corner");

  const material = useStore((state) => state.quote.material);
  const woodSpecies = useStore((state) => state.quote.woodSpecies);

  const sillStyle = useStore((state) => state.quote.sillStyle);

  const stilesAndRails = useStore((state) => state.quote.stilesAndRails);
  const lockRail = useStore((state) => state.quote.lockRail);

  const unitSize = useStore((state) => state.quote.unitSize);
  const unitSizeFraction = useStore((state) => state.quote.unitSizeFraction);

  const cornerWidthRight = useStore((state) => state.quote.cornerWidthRight);
  const cornerWidthFractionRight = useStore(
    (state) => state.quote.cornerWidthFractionRight,
  );

  const heightSize = useStore((state) => state.quote.height);
  const heightFraction = useStore((state) => state.quote.heightFraction);

  const cornerType = useStore((state) => state.quote.cornerType);
  const cornerConfigRight = useStore((state) => state.quote.cornerConfigRight);

  const netWidth = fractionToInt(unitSize, unitSizeFraction);
  const netHeight = fractionToInt(heightSize, heightFraction);

  const cornerFrameWidthLeft = useStore(
    (state) => state.quote.cornerFrameWidthLeft,
  );
  const cornerFrameWidthRight = useStore(
    (state) => state.quote.cornerFrameWidthRight,
  );

  const netWidthCornerRight = !cornerWidthRight
    ? 0
    : fractionToInt(cornerWidthRight, cornerWidthFractionRight);

  const exactConfig = useStore((state) => state.quote.exactConfig);
  const primaryDoor = useStore((state) => state.quote.primaryDoor);

  const addDL = useStore((state) => state.quote.addDL);
  const liteConfig = useStore((state) => state.quote.liteConfig);
  const sdl = useStore((state) => state.quote.dlLayout);
  const daylight = useStore((state) => state.quote.daylightOpening);
  const dlLayoutSidelite = useStore((state) => state.quote.dlLayoutSidelite);
  const diffSideliteSdl = useStore((state) => state.quote.diffSideliteSdl);
  const stiles = useStore((state) => state.quote.stileCategory);

  const withHandle = useStore((state) => state.quote.withHandle);
  const handleStyle = useStore((state) => state.quote.handleStyle);
  const handleHeight = useStore((state) => state.quote.handleHeight);
  const handleColor = useStore((state) => state.quote.handleColor);

  const shootBoltColor = useStore((state) => state.quote.shootBoltColor);

  const hasHandle = doorType?.includes("Window")
    ? false
    : handleStyle === "Handle Pull by Others"
      ? false
      : withHandle === "Add Handle Set" || !withHandle
        ? true
        : false;

  const sliderPocket = useStore((state) => state.quote.sliderPocket);

  const swingPanelWidth = useStore((state) => state.quote.swingPanelWidth);
  const swingPanelFraction = useStore(
    (state) => state.quote.swingPanelFraction,
  );
  const screenType = useStore((state) => state.quote.screenType);
  const addScreen = useStore((state) => state.quote.addScreen);

  const swingDirection = useStore((state) => state.quote.swingDirection);
  const swingType = useStore((state) => state.quote.swingType);
  const sideLite = useStore((state) => state.quote.sideLite);
  const sideLiteWidth = useStore((state) => state.quote.sideLiteWidth);
  const exactConfigImg = useStore((state) => state.quote.exactConfigImg);

  const pivotHardware = useStore((state) => state.quote.pivotHardware);
  const pivotAction = useStore((state) => state.quote.pivotAction);
  const pivotPoint = useStore((state) => state.quote.pivotPoint);
  const pivotBackPlate = useStore((state) => state.quote.pivotBackPlate);
  const pivotBackPlateColor = useStore(
    (state) => state.quote.pivotBackPlateColor,
  );

  const exteriorCladColor = useStore((state) => state.quote.exteriorCladColor);

  const widthErr = useStore((state) => state.widthErr);
  const widthErrCornerRight = useStore((state) => state.widthErrCornerRight);
  const heightErr = useStore((state) => state.heightErr);
  const swingWidthErr = useStore((state) => state.swingWidthErr);
  const sideliteWidthErr = useStore((state) => state.sideliteWidthErr);

  const errorArray = [widthErr, heightErr];
  const required = [stilesAndRails, unitSize, heightSize, exactConfig];

  if (doorType === "Swing") {
    required.push(swingDirection);
    required.push(swingPanelWidth);

    errorArray.push(swingWidthErr);
    errorArray.push(sideliteWidthErr);
  }

  if (doorType === "Pivot") {
    required.push(swingPanelWidth);

    errorArray.push(swingWidthErr);
    errorArray.push(sideliteWidthErr);
  }

  if (material === "All Wood") {
    required.push(woodSpecies);
  }

  if (isCorner) {
    required.push(cornerWidthRight);
    required.push(cornerConfigRight);

    errorArray.push(widthErrCornerRight);
  }

  const showPreview = required.every((itm) => itm);
  const hasErrors = errorArray.some((itm) => itm);

  const extClad = 
    material?.includes("Clad") &&
    exteriorCladColor &&
    !exteriorCladColor.includes("Custom")
      ? exteriorCladColor
      : null

  const cladColor =
    extClad 
      ? cladColors[extClad]
      : "#888886";

  const strokeStyle = extClad?.includes("Black") || extClad?.includes("Brown") ? "white" : "black"

  const lockSideSidelite = exactConfigImg?.includes("LOCK");

  const sideliteSide =
    (doorType !== "Swing" && doorType !== "Pivot") || sideLite === "none"
      ? null
      : swingType === "Pair Swing"
        ? "both"
        : sideLite === "Add Double Sidelite"
          ? "both"
          : exactConfig.includes("LH") && lockSideSidelite
            ? "right"
            : exactConfig.includes("LH") && !lockSideSidelite
              ? "left"
              : exactConfig.includes("RH") && lockSideSidelite
                ? "left"
                : exactConfig.includes("RH") && !lockSideSidelite
                  ? "right"
                  : null;

  const numPanels = doorType?.includes("Bi-fold")
    ? findNumPanelsBifold(exactConfig)
    : doorType === "Slider"
      ? findNumPanelsSlider(exactConfig)
      : doorType === "Swing"
        ? findNumPanelsSwing(swingType || "", sideLite || "none")
        : doorType === "Pivot"
          ? findNumPanelsPivot(sideLite || "none")
          : 1;

  const numPanelsRightCorner = isCorner
    ? findNumPanelsBifold(cornerConfigRight || "")
    : 0;

  const panelWidth = isCorner
    ? calculateCornerPanelWidth(
        numPanels,
        material,
        Number(cornerFrameWidthLeft || "0"),
        primaryDoor === "Left",
      )
    : calculatePanelWidth(
        doorType,
        netWidth,
        numPanels,
        material,
        exactConfig,
        stilesAndRails,
        addScreen && screenType ? screenType : null,
        swingPanelWidth,
        swingPanelFraction,
        sliderPocket,
        handleStyle,
      );

  const panelWidthRightCorner = isCorner
    ? calculateCornerPanelWidth(
        numPanelsRightCorner || 0,
        material,
        Number(cornerFrameWidthRight || "0"),
        primaryDoor === "Right",
      )
    : 0;

  const panelHeight = calculatePanelHeight(
    exactConfig,
    doorType,
    sillStyle || "",
    netHeight,
    addScreen && screenType ? screenType : "",
    material,
    swingDirection,
  );

  const glass = calculateDLO(
    stiles || "",
    stilesAndRails || "",
    lockRail || "",
    panelWidth || 0,
    panelHeight,
    doorType,
  );

  const glassRightCorner = isCorner
    ? calculateDLO(
        stiles || "",
        stilesAndRails || "",
        lockRail || "",
        panelWidthRightCorner || 0,
        panelHeight,
        doorType,
      )
    : { glassHeight: "", glassWidth: "", glassWidthDD: "" };

  const pocketSlider =
    doorType === "Slider" && sliderPocket === "With Pocket"
      ? pocketSliderCalc(exactConfig.includes("-"), netWidth, panelWidth || 0)
      : { pocketSize: 0, netWidth: 0 };

  const scale = getScale(
    doorType,
    netWidthCornerRight || 0,
    netWidth,
    netHeight,
    canvasWidth,
    canvasHeight,
    sliderPocket || "",
    pocketSlider.netWidth || 0,
  );

  const doorWidthPixels = netWidth * scale;

  const doorHeightPixels = netHeight * scale;

  const frame = frameDimensions(
    doorType === "Fixed Panel" ? exactConfig : doorType,
    scale,
    swingDirection || "",
    doorType === "Slider" && sliderPocket === "With Pocket" ? true : false,
    addScreen && screenType?.includes("Centor") ? true : false,
    exactConfig?.includes("-") || false,
    false,
    primaryDoor || "",
    cornerType || "",
  );

  const frameRightCorner = isCorner
    ? frameDimensions(
        doorType,
        scale,
        "",
        false,
        addScreen && screenType?.includes("Centor") ? true : false,
        false,
        true,
        primaryDoor || "",
        cornerType || "",
      )
    : { jambLeg: 0, headJamb: 0, sill: 0 };

  const gaps = getGaps(scale, doorType, exactConfig, exactConfigImg || "");

  const pocketSize = pocketSlider.pocketSize * scale;

  const furthestXPos = doorType.includes("Corner")
    ? 120 + doorWidthPixels + (150 + (netWidthCornerRight || 0) * scale)
    : doorType === "Slider" && sliderPocket === "With Pocket"
      ? pocketSlider.netWidth * scale + 120
      : 120 + doorWidthPixels;

  const distanceToMiddle = (canvasWidth - furthestXPos) / 2;

  const woodImg = woodImgs.find((itm) => itm.label === woodSpecies) || {
    imgLabel: "",
  };

  const drawDoor = (
    width: number,
    height: number,
    configChoice: string,
    numberOfPanels: number,
    stileCategory: string,
    isRightCorner: boolean,
    canvasElem: React.RefObject<HTMLCanvasElement>,
  ) => {
    const canvas = canvasElem?.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");

    if (!ctx) return;

    if (isInitialRender && containerWindow) {
      clearCanvas(canvasElem); // Clear the canvas before drawing to avoid overlapping drawings
      setIsInitialRender(false);
    }

    const hasSliderPocket =
      doorType === "Slider" && configChoice.includes("P") ? true : false;

    ctx.strokeStyle = strokeStyle;
    ctx.lineWidth = 0.5;
    ctx.font = "12px Arial";

    // Calculate individual panel dimensions
    let panelWidthPixels = isRightCorner
      ? (panelWidthRightCorner || 0) * scale
      : (panelWidth || 0) * scale;
    let panelHeightPixels = panelHeight * scale;

    const sideliteWidthPixels =
      (doorType === "Swing" || doorType === "Pivot") &&
      exactConfig.includes("Sidelite")
        ? fractionToInt(
            sideLiteWidth?.split(" ")[0] || "",
            sideLiteWidth?.split(" ")[1] || "",
          ) * scale
        : 0;

    const rightCornerDistance = 200;

    const xStart = isRightCorner
      ? rightCornerDistance + doorWidthPixels + distanceToMiddle
      : 50 + distanceToMiddle;
    const yStart = 50;

    const stileThickness = stileCategory?.includes("Slimline")
      ? 2.5 * scale
      : stileCategory === "Euro"
        ? 3.4375 * scale
        : 4.75 * scale;

    const slim = 2.5 * scale;

    const slimLockStile = 3.4375 * scale;

    const frameXStart =
      isRightCorner && cornerType === "Inside"
        ? xStart
        : isRightCorner
          ? xStart - frameRightCorner.jambLeg
          : xStart - frame.jambLeg;
    const frameYStart = yStart - frame.headJamb;

    const frameWidth =
      cornerType === "Inside" && !isRightCorner
        ? (cornerFrameWidthLeft || 0) * scale
        : cornerType === "Inside" && isRightCorner
          ? (cornerFrameWidthRight || 0) * scale
          : width;
    const frameHeight = height;

    let panelWidthX = 0;

    if (!isRightCorner) {
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas
    } else {
      ctx.clearRect(
        frameXStart,
        frameYStart,
        (netWidthCornerRight || 0) * scale,
        canvas.height,
      );
    }

    function canvasDraw(
      fillStyle: string | CanvasPattern,
      railsWoodImg?: CanvasPattern,
    ) {
      if (!ctx) return;
      ctx.fillStyle = fillStyle; // Set fill color for the stiles and rails

      if (hasSliderPocket) {
        const args: FillRectArgs = [
          frameXStart,
          frameYStart,
          pocketSlider.netWidth * scale,
          frameHeight,
        ];
        ctx.fillRect(...args);
        ctx.strokeRect(...args);
      } else {
        const args: FillRectArgs = [
          frameXStart,
          frameYStart,
          frameWidth,
          frameHeight,
        ];
        ctx.fillRect(...args);
        ctx.strokeRect(...args);
      }

      const handleObj = doorType.includes("Bi-fold")
        ? findHandlePosition(configChoice, numberOfPanels)
        : doorType.includes("Swing")
          ? findHandlePositionSwing(exactConfig, exactConfigImg || "")
          : doorType === "Pivot"
            ? findHandlePositionSwing(
                exactConfig.replace("Pivot", "Single"),
                exactConfigImg || "",
              )
            : doorType === "Slider"
              ? findHandlePositionSlider(
                  exactConfig,
                  sliderPocket === "With Pocket",
                )
              : null;

      function drawHandle(handleSide: string, i: number, panelX: number) {
        if (!ctx) return;
        if (!handleColor) return;

        const twoHandles =
          typeof handleObj === "object" && handleObj?.numHandles === 2
            ? true
            : false;

        const thinHandle =
          handleStyle?.includes("Truth") ||
          (doorType === "Slider" && handleStyle === "Dallas")
            ? true
            : false;

        const backset = 1.375 * scale;

        let backplateWidth =
          doorType === "Slider" && thinHandle
            ? slimLockStile / 2
            : doorType === "Slider"
              ? slimLockStile
              : doorType === "Pivot"
                ? slimLockStile * 2.4
                : slimLockStile * 2;
        let backplateHeight =
          doorType === "Slider"
            ? panelHeightPixels / 7
            : doorType === "Pivot"
              ? panelHeightPixels / 25
              : panelHeightPixels / 8;
        let backplateX = 0;

        if (handleSide === "Left") {
          if (doorType === "Slider") {
            backplateX = xStart + panelX + backset / 2;
          } else {
            if (doorType === "Pivot") {
              backplateX = xStart + panelX + slimLockStile / 12;
            } else {
              backplateX = xStart + panelX + backset / 2;
            }
          }
        } else {
          if (doorType === "Slider") {
            if (thinHandle) {
              backplateX =
                xStart +
                panelX +
                panelWidthPixels -
                slimLockStile +
                slimLockStile -
                backplateWidth -
                backset / 2;
            } else {
              backplateX =
                xStart +
                panelX +
                panelWidthPixels -
                slimLockStile -
                backset / 2;
            }
          } else {
            const sub = doorType === "Pivot" ? 0 : backset / 2;
            backplateX =
              xStart +
              panelX +
              panelWidthPixels -
              slimLockStile -
              backplateWidth +
              slimLockStile -
              sub;
          }
        }

        if (isRightCorner) {
          backplateX =
            handleSide === "Left"
              ? rightCornerDistance +
                distanceToMiddle +
                doorWidthPixels +
                backset / 2
              : rightCornerDistance +
                doorWidthPixels +
                40 +
                panelWidthPixels +
                i * panelWidthPixels;
        }

        const handleHeightPixels =
          doorType === "Bi-fold Window"
            ? 12 * scale
            : handleHeight === '42"'
              ? 42 * scale
              : 36 * scale;

        let backplateY = yStart + panelHeightPixels - handleHeightPixels; // Center backplate vertically

        let handleHeightOffset = 5;
        let handleX =
          handleSide === "Left" ? backplateX : backplateX + backplateWidth;
        let handleY = backplateY + backplateHeight / 2 - handleHeightOffset / 2; // Center handle vertically on backplate

        if (hasHandle && handleStyle) {
          ctx.save();
          const canvasImg = new Image();
          canvasImg.src = handleImage(
            doorType,
            handleStyle,
            handleSide,
            handleColor || "",
          );

          canvasImg.onload = () => {
            ctx.drawImage(
              canvasImg,
              backplateX,
              doorType === "Pivot"
                ? backplateY - backplateHeight - backplateHeight / 2
                : thinHandle
                  ? backplateY - backplateHeight / 3
                  : backplateY,
              backplateWidth,
              backplateHeight,
            );
          };
          ctx.restore();

          if (doorType === "Pivot" && pivotBackPlate && pivotBackPlateColor) {
            ctx.save();
            const backplateImg = new Image();
            backplateImg.src = pivotBackPlateImage(
              pivotBackPlate || "",
              pivotBackPlateColor || "",
            );
            backplateImg.onload = () => {
              ctx.drawImage(
                backplateImg,
                handleSide === "Left"
                  ? backplateX
                  : backplateX + slimLockStile * 1.5,
                backplateY,
                backplateWidth / 3,
                backplateWidth / 3,
              );
            };
            ctx.restore();
          }

          if (twoHandles && handleSide === "Left") return;
          ctx.strokeStyle = "black"

          // handle height dimension line
          ctx.beginPath();
          const handleDimX =
            handleSide === "Left" && doorType === "Slider"
              ? handleX + backplateWidth * (thinHandle ? 3 : 2)
              : handleSide === "Left"
                ? handleX + backplateWidth
                : doorType === "Slider"
                  ? handleX - backplateWidth * (thinHandle ? 3 : 2)
                  : handleX - backplateWidth * 1.1;
          ctx.moveTo(handleDimX, handleY);
          ctx.lineTo(handleDimX, yStart + panelHeightPixels);
          ctx.stroke();

          ctx.save(); // Save current state
          ctx.fillStyle = "black";
          ctx.font = "12px Arial";

          ctx.translate(
            handleSide === "Left"
              ? handleDimX + 5 * scale
              : handleDimX - 2 * scale,
            (yStart + height + handleY) / 2,
          );
          ctx.rotate(-Math.PI / 2); // Rotate counter-clockwise
          const handleHeigthText =
            doorType === "Bi-fold Window"
              ? '12"'
              : handleHeight === '42"'
                ? '42"'
                : '36"';
          ctx.fillText(handleHeigthText, 0, 0); // Draw text
          ctx.restore();
        } else if (
          doorType === "Pivot" &&
          pivotBackPlate &&
          pivotBackPlateColor
        ) {
          ctx.save();
          const backplateImg = new Image();
          backplateImg.src = pivotBackPlateImage(
            pivotBackPlate || "",
            pivotBackPlateColor || "",
          );
          backplateImg.onload = () => {
            ctx.drawImage(
              backplateImg,
              handleSide === "Left"
                ? backplateX
                : backplateX + slimLockStile * 1.5,
              backplateY,
              backplateWidth / 3,
              backplateWidth / 3,
            );
          };
          ctx.restore();

          // handle height dimension line
          ctx.beginPath();
          const handleDimX =
            handleSide === "Left"
              ? handleX + backplateWidth
              : handleX - backplateWidth * 1.5;
          ctx.moveTo(handleDimX, handleY);
          ctx.lineTo(handleDimX, yStart + panelHeightPixels);
          ctx.stroke();

          ctx.save(); // Save current state
          ctx.fillStyle = "black";
          ctx.font = "12px Arial";

          ctx.translate(handleDimX + 15, (yStart + height + handleY) / 2);
          ctx.rotate(-Math.PI / 2); // Rotate counter-clockwise
          const handleHeigthText = handleHeight === '42"' ? '42"' : '36"';
          ctx.fillText(handleHeigthText, 0, 0); // Draw text
          ctx.restore();
        }
      }

      function drawShootbolt(i: number, panelX: number) {
        if (!ctx) return;

        const twoHandles =
          typeof handleObj === "object" && handleObj?.numHandles === 2
            ? true
            : false;

        let handlePanels: number[] = [];

        if (typeof handleObj === "object" && !doorType.includes("Window")) {
          if (twoHandles) {
            if (handleObj?.panelWithHandleLeft) {
              handlePanels.push(handleObj.panelWithHandleLeft);
            }

            if (handleObj?.panelWithHandleRight) {
              handlePanels.push(handleObj.panelWithHandleRight);
            }
          } else if (!twoHandles) {
            if (handleObj?.panelWithHandle) {
              handlePanels.push(handleObj.panelWithHandle);
            }
          }
        }

        const currentPanel = i + 1;

        if (handlePanels.includes(currentPanel)) {
          return;
        }

        let backplateWidth = slim / 2;
        let backplateHeight = backplateWidth * 6;
        let backplateX = 0;
        let panelRight = 0;

        if (isRightCorner) {
          const numLeft = Number(exactConfig.split("L")[0]);
          const numRight = Number(configChoice.split("R")[0]);

          panelRight = numRight - (currentPanel + numLeft - numLeft - 1);

          const isOddPanel = panelRight % 2 !== 0;

          if (panelRight && isOddPanel) {
            backplateX =
              rightCornerDistance +
              doorWidthPixels +
              distanceToMiddle +
              panelX +
              stileThickness / 3;
          } else {
            return;
          }
        } else {
          const numLeft = configChoice.includes("L")
            ? Number(configChoice.split("L")[0])
            : 0;
          const numRight =
            configChoice.includes("R") && !numLeft
              ? Number(configChoice.split("R")[0])
              : numLeft && configChoice.includes("R")
                ? Number(configChoice.split("L")[1].split("R")[0])
                : 0;

          panelRight =
            currentPanel > numLeft
              ? numRight - (currentPanel - numLeft - 1)
              : 0;

          const isOddPanel =
            currentPanel > numLeft
              ? panelRight % 2 !== 0
              : currentPanel % 2 !== 0;

          if (numLeft && numLeft >= currentPanel && isOddPanel) {
            //if odd
            backplateX = xStart + panelX + panelWidthPixels - stileRight / 1.5;
          } else if (panelRight && isOddPanel) {
            backplateX = xStart + panelX + stileLeft / 3;
          } else {
            return;
          }
        }

        const handleHeightPixels =
          doorType === "Bi-fold Window"
            ? 12 * scale
            : handleHeight === '42"'
              ? 42 * scale
              : 36 * scale;

        let backplateY = yStart + panelHeightPixels - handleHeightPixels; // Center backplate vertically

        ctx.save();
        const canvasImg = new Image();
        const sbColor = shootBoltColor || "Brushed Chrome";
        canvasImg.src = `https://res.cloudinary.com/ag-millworks/image/upload/Canvas/handles/Bifold_Swings/Shootbolt_${sbColor?.replace(
          " ",
          "_",
        )}.svg`;

        canvasImg.onload = () => {
          ctx.drawImage(
            canvasImg,
            backplateX,
            backplateY,
            backplateWidth,
            backplateHeight,
          );
        };
        ctx.restore();

        ctx.save();
        ctx.strokeStyle = "black";
        ctx.fillStyle = "black";
        const font = 3;
        ctx.font = `${font * scale > 10 ? 10 : font * scale}px Arial`;

        if (panelRight || isRightCorner) {
          ctx.beginPath();
          ctx.moveTo(backplateX, backplateY - 5);
          ctx.lineTo(backplateX, backplateY - 30);
          ctx.stroke();

          const xLength = 5 * scale > 20 ? 20 : 5 * scale;

          ctx.beginPath();
          ctx.moveTo(backplateX, backplateY - 30);
          ctx.lineTo(backplateX + xLength, backplateY - 30);
          ctx.stroke();

          // const xInitial = 2 * scale;
          const xInitial = panelWidthPixels / 4;

          ctx.fillText("SHOOTBOLT", backplateX + xInitial, backplateY - 30);
          ctx.fillText("FROM INT.", backplateX + xInitial, backplateY - 20);
          ctx.restore();
        } else {
          ctx.beginPath();
          ctx.moveTo(backplateX, backplateY - 5);
          ctx.lineTo(backplateX, backplateY - 30);
          ctx.stroke();

          const xLength = 5 * scale > 85 ? 85 : 5 * scale;

          ctx.beginPath();
          ctx.moveTo(backplateX, backplateY - 30);
          ctx.lineTo(backplateX - xLength, backplateY - 30);
          ctx.stroke();

          const xInitial = panelWidthPixels / 1.5;

          ctx.fillText("SHOOTBOLT", backplateX - xInitial, backplateY - 30);
          ctx.fillText("FROM INT.", backplateX - xInitial, backplateY - 20);
          ctx.restore();
        }
      }

      function drawDloDD(innerStileWidth: number, i: number) {
        if (!ctx) return;
        if (
          !doorType.includes("Bi-fold") ||
          !stileCategory.includes("Slimline")
        )
          return;

        if (doorType.includes("Window")) {
          return;
        }
        // Draw dlo dd glass width
        ctx.save();
        ctx.strokeStyle = "black";
        ctx.fillStyle = "black";
        ctx.beginPath();
        const start = xStart + i * panelWidthPixels + stileLeft;
        const end = start + innerStileWidth;
        ctx.moveTo(start, frameYStart - 10);
        ctx.lineTo(end, frameYStart - 10);
        ctx.stroke();
        if (isRightCorner) {
          const glassText = glassRightCorner.glassWidthDD
            ? `${glassRightCorner.glassWidthDD}"`
            : `${glassRightCorner.glassWidth}"`;
          const xPosDlo = (start + end) / 2 - 10;
          ctx.fillText("DLO", xPosDlo, frameYStart - 30);
          ctx.fillText(glassText, xPosDlo, frameYStart - 15);
        } else {
          const glassText = glass.glassWidthDD
            ? `${glass.glassWidthDD}"`
            : `${glass.glassWidth}"`;
          const xPosDlo = (start + end) / 2 - 10;
          ctx.fillText("DLO", xPosDlo, frameYStart - 30);
          ctx.fillText(glassText, xPosDlo, frameYStart - 15);
        }

        ctx.restore();
      }

      function drawSliderPocket(x: number, y: number) {
        if (!ctx) return;
        ctx.save();
        ctx.strokeStyle = "black"
        ctx.beginPath(); // Start a new path for the "X"
        ctx.moveTo(x, y); // Move to the top-left corner of the panel
        const xPos = x + pocketSize;
        const yPos = y + panelHeightPixels;
        ctx.lineTo(xPos, yPos); // Draw line to the bottom-right corner
        ctx.moveTo(xPos, y); // Move to the top-right corner of the panel
        ctx.lineTo(x, yPos); // Draw line to the bottom-left corner
        ctx.stroke(); // Stroke the path, which draws the "X"
        ctx.restore();
      }

      function drawHashMarks(isSidelite: boolean) {
        if (!ctx) return;
        ctx.save();
        const adder = isSidelite ? sideliteWidthPixels : panelWidthPixels;
        const x = xStart + panelWidthX + adder / 2 - 3 * scale;
        const y = yStart + panelHeightPixels / 3;

        ctx.moveTo(x, y);
        ctx.lineTo(x + 3 * scale, y + 3 * scale);

        const x2 = x - 1 * scale;
        ctx.moveTo(x2, y - 2 * scale);
        ctx.lineTo(x2 + 7 * scale, y + 5 * scale);

        const x3 = x + 2 * scale;
        ctx.moveTo(x3, y);
        ctx.lineTo(x3 + 3 * scale, y + 3 * scale);

        ctx.stroke();
        ctx.restore();
      }

      let dloPanel =
        handleObj && handleObj.numHandles === 2
          ? [...Array(numberOfPanels).keys()].find(
              (num) =>
                num + 1 !== handleObj.panelWithHandleLeft &&
                num + 1 !== handleObj.panelWithHandleRight,
            )
          : handleObj && handleObj.numHandles === 1
            ? [...Array(numberOfPanels).keys()].find(
                (num) => num + 1 !== handleObj.panelWithHandle,
              )
            : 0;

      let stileLeft: number = 0;
      let stileRight: number = 0;
      let railTop = stileCategory?.includes("Slimline")
        ? 2.5 * scale
        : stileCategory === "Euro"
          ? 3.4375 * scale
          : 4.75 * scale;
      let railBtm =
        stileCategory?.includes("Slimline") && doorType === "Slider"
          ? 3.4375 * scale
          : stileCategory?.includes("Slimline")
            ? 2.5 * scale
            : stileCategory === "Euro"
              ? 3.4375 * scale
              : stilesAndRails === 'Bottom rail 7"'
                ? 7 * scale
                : 4.75 * scale;

      const isFritsjurgen =
        doorType === "Pivot" && pivotHardware === "Fritsjurgens" ? true : false;

      if (isFritsjurgen) {
        if (pivotAction === "Free Swing") {
          railTop = stileCategory?.includes("Slimline")
            ? 3.4375 * scale
            : stileCategory === "Euro"
              ? 3.4375 * scale
              : 4.75 * scale;
        } else {
          railTop = stileCategory === "Euro" ? 3.4375 * scale : 4.75 * scale;
          railBtm =
            stilesAndRails === 'Bottom rail 7"' ? 7 * scale : 4.75 * scale;
        }
      }

      const XSlider = configChoice.split("").every((letter) => letter === "X");

      for (let i = 0; i < numberOfPanels; i++) {
        // Draw and fill the outer rectangle (stile) with gray
        ctx.fillStyle = fillStyle; // Set fill color for the stiles and rails

        let panelArgs: FillRectArgs;

        const withPocket =
          doorType === "Slider" && configChoice.replace("-", "")[i] === "P"
            ? true
            : false;

        const isSidelite =
          (doorType === "Swing" || doorType === "Pivot") &&
          sideLite !== "none" &&
          ((i === 0 && sideliteSide === "left") ||
            (i + 1 === numberOfPanels && sideliteSide === "right") ||
            ((i === 0 || i + 1 === numberOfPanels) && sideliteSide === "both"))
            ? true
            : false;

        const gapKey = `panel${i + 1}`;
        const gap = gaps[gapKey];

        if (doorType === "Slider") {
          const panelBeforeDash = configChoice.indexOf("-") - 1 === i;
          const panelAfterDash = configChoice.indexOf("-") === i;

          const overlappedPanelWidth = panelWidthPixels - stileThickness;

          if (
            hasSliderPocket &&
            configChoice[0] === "P" &&
            configChoice.split("").findIndex((itm) => itm === "P") + 1 === i
          ) {
            panelWidthX += gap;
            panelWidthX += pocketSize;
          } else if (i !== 0 && panelAfterDash) {
            panelWidthX += gap;
            panelWidthX += panelWidthPixels;
          } else if (i !== 0) {
            panelWidthX += gap;
            panelWidthX += overlappedPanelWidth;
          }

          const ovWidth =
            (hasSliderPocket &&
              i + 1 === numberOfPanels - 1 &&
              (configChoice.includes("-") ||
                (!configChoice.includes("-") && configChoice[0] !== "P"))) ||
            (hasSliderPocket &&
              i + 1 === numberOfPanels &&
              !configChoice.includes("-") &&
              configChoice[0] === "P") ||
            (!hasSliderPocket && i + 1 === numberOfPanels) ||
            panelBeforeDash
              ? panelWidthPixels
              : overlappedPanelWidth;
          panelArgs = [
            xStart + panelWidthX,
            yStart,
            ovWidth,
            panelHeightPixels,
          ];
        } else {
          if (
            (doorType === "Swing" || doorType === "Pivot") &&
            sideLite !== "none" &&
            (sideliteSide === "left" || sideliteSide === "both") &&
            i === 1
          ) {
            panelWidthX += gap;
            panelWidthX += sideliteWidthPixels;

            panelArgs = [
              xStart + panelWidthX,
              yStart,
              panelWidthPixels,
              panelHeightPixels,
            ];
          } else if (isSidelite) {
            panelWidthX += gap;
            if (i !== 0) panelWidthX += panelWidthPixels;

            panelArgs = [
              xStart + panelWidthX,
              yStart,
              sideliteWidthPixels,
              panelHeightPixels,
            ];
          } else {
            panelWidthX += gap;
            if (i !== 0) panelWidthX += panelWidthPixels;

            panelArgs = [
              xStart + panelWidthX,
              yStart,
              panelWidthPixels,
              panelHeightPixels,
            ];
          }
        }

        if (withPocket) {
          ctx.save();
          ctx.strokeStyle = "black"
          ctx.fillStyle = "white";
          const pocketSide = i === 0 ? "left" : "right";
          const x =
            pocketSide === "left" ? panelWidthX : panelWidthX + stileThickness;
          ctx.fillRect(xStart + x, frameYStart, pocketSize, doorHeightPixels);
          ctx.strokeRect(xStart + x, frameYStart, pocketSize, doorHeightPixels);
          ctx.restore();
          drawSliderPocket(xStart + x, frameYStart);
        } else {
          if (isRightCorner && i === 0 && cornerType === "Outside") {
            ctx.save();
            const xPos = frameXStart;
            ctx.fillStyle = "white";
            ctx.fillRect(
              xPos + frameRightCorner.jambLeg,
              yStart,
              -(frameRightCorner.jambLeg + 2),
              panelHeightPixels,
            );
            ctx.restore();
          }

          ctx.fillRect(...panelArgs); // Fill outer rectangle
          ctx.strokeRect(...panelArgs); // Draw outer rectangle border

          if (
            isCorner &&
            !isRightCorner &&
            i === numPanels - 1 &&
            cornerType === "Outside"
          ) {
            ctx.save();
            ctx.fillStyle = "white";
            ctx.strokeStyle = "white";
            ctx.fillRect(
              xStart + panelWidthX + panelWidthPixels,
              yStart,
              frameXStart +
                doorWidthPixels -
                (xStart + panelWidthX + panelWidthPixels) +
                2,
              panelHeightPixels,
            );
            ctx.restore();
          }
        }

        let innerStileWidth =
          panelWidthPixels - stileThickness - stileThickness;
        let innerStileHeight = panelHeightPixels - railTop - railBtm;

        if (doorType === "Slider") {
          innerStileWidth = panelWidthPixels - stileThickness;
        } else if (isSidelite) {
          innerStileWidth = sideliteWidthPixels - slim - slim;
        }

        const singleHandle =
          handleObj &&
          handleObj.numHandles === 1 &&
          i + 1 === handleObj.panelWithHandle
            ? true
            : false;

        const doubleHandle =
          handleObj && handleObj.numHandles === 2 ? true : false;

        if (singleHandle && stileCategory?.includes("Slimline")) {
          innerStileWidth = panelWidthPixels - slimLockStile - stileThickness;

          if (
            typeof handleObj === "object" &&
            handleObj?.handleSide === "Left"
          ) {
            stileRight = stileThickness;
            stileLeft = slimLockStile;
          }

          if (
            typeof handleObj === "object" &&
            handleObj?.handleSide === "Right"
          ) {
            stileRight = slimLockStile;
            stileLeft = stileThickness;
          }
        } else if (doubleHandle && stileCategory?.includes("Slimline")) {
          if (
            typeof handleObj === "object" &&
            i + 1 === handleObj?.panelWithHandleLeft
          ) {
            if (XSlider) {
              stileLeft = slimLockStile;
              stileRight = stileThickness;
            } else {
              innerStileWidth =
                panelWidthPixels - slimLockStile - stileThickness;
              stileLeft = stileThickness;
              stileRight = slimLockStile;
            }
          } else if (
            typeof handleObj === "object" &&
            i + 1 === handleObj?.panelWithHandleRight
          ) {
            if (XSlider) {
              stileRight = slimLockStile;
              stileLeft = stileThickness;
            } else {
              stileRight = stileThickness;
              stileLeft = slimLockStile;
              innerStileWidth =
                panelWidthPixels - slimLockStile - stileThickness;
            }
          } else {
            stileLeft = stileThickness;
            stileRight = stileThickness;
          }
        } else {
          if (isSidelite) {
            stileLeft = slim;
            stileRight = slim;
          } else {
            stileLeft = stileThickness;
            stileRight = stileThickness;
          }
        }

        // Draw and fill the glass section with the specified color
        ctx.fillStyle = "#e6fffb"; // Set fill color for the glass
        let glassPanelArgs: FillRectArgs;

        const glassX = xStart + panelWidthX + stileLeft;
        const glassY = yStart + railTop;

        if (doorType === "Slider") {
          const ovWidth = !stileCategory?.includes("Slimline")
            ? innerStileWidth - stileThickness
            : XSlider &&
                typeof handleObj === "object" &&
                i + 1 === handleObj?.panelWithHandleRight
              ? innerStileWidth - slimLockStile
              : singleHandle &&
                  typeof handleObj === "object" &&
                  i + 1 === handleObj?.panelWithHandle
                ? innerStileWidth
                : doubleHandle &&
                    typeof handleObj === "object" &&
                    (i + 1 === handleObj?.panelWithHandleRight ||
                      i + 1 === handleObj?.panelWithHandleLeft)
                  ? innerStileWidth
                  : innerStileWidth - stileThickness;

          glassPanelArgs = [glassX, glassY, ovWidth, innerStileHeight];
        } else {
          if (
            (doorType === "Swing" || doorType === "Pivot") &&
            sideLite !== "none" &&
            (sideliteSide === "left" || sideliteSide === "both") &&
            i === 1
          ) {
            glassPanelArgs = [
              glassX,
              glassY,
              panelWidthPixels - stileLeft - stileRight,
              innerStileHeight,
            ];
          } else if (isSidelite) {
            glassPanelArgs = [
              glassX,
              glassY,
              sideliteWidthPixels - stileLeft - stileRight,
              innerStileHeight,
            ];
          } else {
            glassPanelArgs = [
              glassX,
              glassY,
              panelWidthPixels - stileLeft - stileRight,
              innerStileHeight,
            ];
          }
        }

        if (!withPocket) {
          ctx.fillRect(...glassPanelArgs); // Fill inner rectangle
          ctx.strokeRect(...glassPanelArgs); // Draw inner rectangle border

          if (material === "All Wood") {
            ctx.save();
            ctx.fillStyle = fillStyle;

            if (!isRightCorner) {
              ctx.clearRect(
                frameXStart,
                frameYStart,
                frame.jambLeg,
                doorHeightPixels,
              );
              ctx.fillRect(
                frameXStart,
                frameYStart,
                frame.jambLeg,
                doorHeightPixels,
              );
            }

            const argsLeft: [number, number, number, number] = [
              xStart + panelWidthX,
              yStart,
              stileLeft,
              panelHeightPixels,
            ];

            const argsRight: [number, number, number, number] = [
              xStart + panelWidthX + panelArgs[2] - stileRight,
              yStart,
              stileRight,
              panelHeightPixels,
            ];

            if (doorType === "Slider") {
              const panelBeforeDash = configChoice.indexOf("-") - 1 === i;
              const panelAfterDash = configChoice.indexOf("-") === i;

              if (panelBeforeDash) {
                ctx.clearRect(...argsLeft);
                ctx.clearRect(...argsRight);
                ctx.fillRect(...argsLeft);
                ctx.fillRect(...argsRight);
              } else if (panelAfterDash) {
                ctx.clearRect(...argsLeft);
                ctx.fillRect(...argsLeft);
              } else if (
                i + 1 ===
                  configChoice.replace("P", "").replace("-", "").length ||
                i === numPanels - 1
              ) {
                ctx.clearRect(...argsLeft);
                ctx.fillRect(...argsLeft);
                if (configChoice[0] === "P" && !configChoice.includes("-")) {
                } else {
                  ctx.clearRect(...argsRight);
                  ctx.fillRect(...argsRight);
                }
              } else {
                ctx.clearRect(...argsLeft);
                ctx.fillRect(...argsLeft);
              }
            } else {
              ctx.clearRect(...argsLeft);
              ctx.clearRect(...argsRight);
              ctx.fillRect(...argsLeft);
              ctx.fillRect(...argsRight);
            }

            if (isCorner && cornerType === "Outside" && !isRightCorner) {
            } else {
              const widthToUse =
                isCorner && cornerType === "Inside" && !isRightCorner
                  ? (cornerFrameWidthLeft || 0) * scale
                  : isCorner && cornerType === "Inside" && isRightCorner
                    ? (cornerFrameWidthRight || 0) * scale
                    : hasSliderPocket && configChoice[0] === "P"
                      ? width + pocketSize
                      : width;

              const jamb = isRightCorner
                ? frameRightCorner.jambLeg
                : hasSliderPocket &&
                    !configChoice.includes("-") &&
                    configChoice[0] === "P"
                  ? stileThickness
                  : frame.jambLeg;

              ctx.clearRect(
                frameXStart + widthToUse - jamb,
                frameYStart,
                jamb,
                doorHeightPixels,
              );

              ctx.fillRect(
                frameXStart + widthToUse - jamb,
                frameYStart,
                jamb,
                doorHeightPixels,
              );
            }

            ctx.restore();

            ctx.save();
            ctx.fillStyle = railsWoodImg as CanvasPattern;

            const pocketSide = configChoice[0] === "P" ? "left" : "right";
            ctx.clearRect(
              hasSliderPocket && pocketSide === "left"
                ? frameXStart + pocketSize
                : frameXStart,
              frameYStart,
              frameWidth,
              frame.headJamb,
            );
            ctx.fillRect(
              hasSliderPocket && pocketSide === "left"
                ? frameXStart + pocketSize
                : frameXStart,
              frameYStart,
              frameWidth,
              frame.headJamb,
            );
            ctx.clearRect(
              xStart + panelWidthX + stileLeft,
              yStart,
              glassPanelArgs[2],
              railTop,
            );
            ctx.fillRect(
              xStart + panelWidthX + stileLeft,
              yStart,
              glassPanelArgs[2],
              railTop,
            );
            ctx.clearRect(
              xStart + panelWidthX + stileLeft,
              yStart + panelHeightPixels - railBtm,
              glassPanelArgs[2],
              railBtm,
            );
            ctx.fillRect(
              xStart + panelWidthX + stileLeft,
              yStart + panelHeightPixels - railBtm,
              glassPanelArgs[2],
              railBtm,
            );
            ctx.clearRect(
              hasSliderPocket && pocketSide === "left"
                ? frameXStart + pocketSize
                : frameXStart,
              yStart + panelHeightPixels,
              frameWidth,
              frame.sill,
            );
            ctx.fillRect(
              hasSliderPocket && pocketSide === "left"
                ? frameXStart + pocketSize
                : frameXStart,
              yStart + panelHeightPixels,
              frameWidth,
              frame.sill,
            );

            ctx.restore();
          }

          if (singleHandle) {
            drawHandle(
              typeof handleObj === "object" && handleObj?.handleSide
                ? handleObj.handleSide
                : "",
              i,
              panelWidthX,
            );
            drawDloDD(innerStileWidth, i);

            if (
              doorType === "Pivot" &&
              pivotPoint &&
              typeof handleObj === "object"
            ) {
              ctx.save();
              ctx.strokeStyle = "red";
              const pivotAxis = pivotPoint.includes("Middle")
                ? panelWidthPixels / 2
                : pivotPoint.split(" ")[0] === '4-3/4"'
                  ? 4.75 * scale
                  : Number(pivotPoint.split(" ")[0].replace('"', "")) * scale;
              let xPos = xStart + pivotAxis;

              if (sideliteSide === "left" || sideliteSide === "both") {
                xPos =
                  handleObj?.handleSide === "Right"
                    ? xStart + panelWidthX + pivotAxis
                    : xStart + panelWidthX + panelWidthPixels - pivotAxis;
              } else {
                xPos =
                  handleObj?.handleSide === "Right"
                    ? xStart + pivotAxis
                    : xStart + panelWidthPixels - pivotAxis;
              }
              ctx.beginPath();
              ctx.setLineDash([20, 10]);
              ctx.moveTo(xPos, yStart - 8 * scale);
              ctx.lineTo(xPos, yStart + panelHeightPixels + 8 * scale);
              ctx.stroke();
              ctx.restore();

              if (!pivotPoint.includes("Middle")) {
                ctx.save();
                ctx.strokeStyle = "black";
                ctx.fillStyle = "black";
                ctx.font = "10px Arial";
                let endPos =
                  handleObj?.handleSide === "Right"
                    ? xPos - pivotAxis
                    : xPos + pivotAxis;

                ctx.beginPath();
                ctx.moveTo(xPos, yStart - 8 * scale);
                ctx.lineTo(endPos, yStart - 8 * scale);
                ctx.stroke();
                const text = pivotPoint.includes("Middle")
                  ? "Middle"
                  : pivotPoint.split(" ")[0];
                ctx.fillText(
                  `${text} Axis`,
                  endPos < xPos ? endPos : xPos,
                  yStart - 9 * scale,
                );
                ctx.restore();
              }
            }
          } else if (doubleHandle) {
            if (
              typeof handleObj === "object" &&
              i + 1 === handleObj?.panelWithHandleLeft
            ) {
              drawHandle(handleObj.handleSideLeft, i, panelWidthX);

              if (primaryDoor === "Left") {
                drawDloDD(innerStileWidth, i);
              }
            } else if (
              typeof handleObj === "object" &&
              i + 1 === handleObj?.panelWithHandleRight
            ) {
              drawHandle(handleObj.handleSideRight, i, panelWidthX);

              if (primaryDoor === "Right") {
                drawDloDD(innerStileWidth, i);
              }
            }
          }

          if (doorType.includes("Bi-fold")) {
            drawShootbolt(i, panelWidthX);
          }

          //add the rail lines
          ctx.beginPath();
          ctx.moveTo(glassPanelArgs[0], yStart);
          ctx.lineTo(glassPanelArgs[0], yStart + panelHeightPixels);
          ctx.stroke();

          ctx.beginPath();
          ctx.moveTo(glassPanelArgs[0] + glassPanelArgs[2], yStart);
          ctx.lineTo(
            glassPanelArgs[0] + glassPanelArgs[2],
            yStart + panelHeightPixels,
          );
          ctx.stroke();

          drawHashMarks(isSidelite);
        }

        if (addDL && sdl && !withPocket) {
          let sdlChoice = sdl;
          if (isSidelite && diffSideliteSdl) {
            if (!dlLayoutSidelite) continue;
            sdlChoice = dlLayoutSidelite;
          }
          const wide = Number(sdlChoice[0]);
          const high = Number(sdlChoice[2]);

          ctx.save(); // Save the current state of the context
          const widthOfLite =
            liteConfig === '5 1/4"'
              ? 5.25 * scale
              : liteConfig === '2 1/2"'
                ? 2.5 * scale
                : liteConfig === '1 3/4"'
                  ? 1.75 * scale
                  : liteConfig === '1 1/8"'
                    ? 1.125 * scale
                    : liteConfig === '7/8"'
                      ? 0.875 * scale
                      : 0.625 * scale;
          ctx.lineWidth = widthOfLite;
          ctx.strokeStyle = fillStyle;

          if (sdlChoice === "9 Lite Marginal") {
            const dloInt = fractionToInt(
              glass.glassWidth.split(" ")[0],
              glass.glassWidth.split(" ")[1] || "",
            );
            const horSpacing = (dloInt * scale) / 4;

            const x = xStart + panelWidthX + stileLeft;

            if (material === "All Wood") {
              ctx.strokeStyle = railsWoodImg as CanvasPattern;
            }

            for (let j = 0; j < 2; j++) {
              const space =
                j === 0
                  ? railTop + horSpacing
                  : railTop + innerStileHeight - horSpacing;
              const notch = yStart + space;
              ctx.beginPath();
              ctx.moveTo(x, notch);
              ctx.lineTo(x + innerStileWidth, notch);
              ctx.stroke();
            }

            ctx.strokeStyle = fillStyle;

            for (let j = 0; j < 3; j++) {
              if (j === 1) continue;
              const notch =
                xStart + panelWidthX + stileLeft + (j + 1) * horSpacing;
              ctx.beginPath();
              ctx.moveTo(notch, yStart + railTop);
              ctx.lineTo(notch, yStart + railTop + innerStileHeight);
              ctx.stroke();
            }
          } else if (high === 1) {
            if (!daylight) continue;
            //Cottage Style
            const dlo = Number(daylight.replace('"', "")) * scale;

            const notch = yStart + railTop + dlo;

            const x = xStart + panelWidthX + stileLeft;

            if (material === "All Wood") {
              ctx.strokeStyle = railsWoodImg as CanvasPattern;
            }

            ctx.beginPath();
            ctx.moveTo(x, notch);
            ctx.lineTo(x + innerStileWidth, notch);
            ctx.stroke();

            ctx.strokeStyle = fillStyle;

            const verSpacing = innerStileWidth / wide;

            for (let j = 0; j < wide - 1; j++) {
              const notch =
                xStart + panelWidthX + stileLeft + (j + 1) * verSpacing;
              ctx.beginPath();
              ctx.moveTo(notch, yStart + railTop);
              ctx.lineTo(notch, yStart + railTop + dlo);
              ctx.stroke();
            }
          } else {
            const horSpacing = innerStileHeight / high;

            const x = xStart + panelWidthX + stileLeft;

            if (material === "All Wood") {
              ctx.strokeStyle = railsWoodImg as CanvasPattern;
            }

            for (let j = 0; j < high - 1; j++) {
              const notch = yStart + railTop + (j + 1) * horSpacing;
              ctx.beginPath();
              ctx.moveTo(x, notch);
              ctx.lineTo(x + innerStileWidth, notch);
              ctx.stroke();
            }

            ctx.strokeStyle = fillStyle;

            const verSpacing = innerStileWidth / wide;

            for (let j = 0; j < wide - 1; j++) {
              const notch =
                xStart + panelWidthX + stileLeft + (j + 1) * verSpacing;
              ctx.beginPath();
              ctx.moveTo(notch, yStart + railTop);
              ctx.lineTo(notch, yStart + railTop + innerStileHeight);
              ctx.stroke();
            }
          }

          ctx.restore(); // Restore the saved state, reverting lineWidth and strokeStyle
        }
      }

      ctx.strokeStyle = "black";
      ctx.fillStyle = "black";

      // Draw overall dimension lines
      // Overall width
      if (hasSliderPocket) {
        const doubleSided = configChoice.includes("-");

        const startOfFrameY = 100 + height;
        const frameWidthText = unitSizeFraction
          ? `DF.Width  ${unitSize} ${unitSizeFraction}"`
          : `DF.Width  ${unitSize}"`;

        const pocketSide = configChoice[0] === "P" ? "left" : "right";

        if (doubleSided) {
          const startOfFrameX = frameXStart + pocketSize;

          ctx.beginPath();
          ctx.moveTo(startOfFrameX, startOfFrameY);
          ctx.lineTo(startOfFrameX + width, startOfFrameY);
          ctx.stroke();

          const textX =
            (xStart + pocketSize + (xStart + pocketSize + width)) / 2;
          ctx.fillText(frameWidthText, textX - 20, 115 + height);
        } else {
          if (pocketSide === "left") {
            //left pocket
            ctx.beginPath();
            ctx.moveTo(frameXStart + pocketSize, startOfFrameY);
            ctx.lineTo(frameXStart + pocketSize + width, startOfFrameY);
            ctx.stroke();

            const textX =
              (xStart + pocketSize + (xStart + pocketSize + width)) / 2;
            ctx.fillText(frameWidthText, textX - 20, 115 + height);
          } else {
            //right pocket
            ctx.beginPath();
            ctx.moveTo(frameXStart, startOfFrameY);
            ctx.lineTo(frameXStart + width, startOfFrameY);
            ctx.stroke();

            const textX = (xStart + (xStart + width)) / 2;
            ctx.fillText(frameWidthText, textX - 20, 115 + height);
          }
        }

        const netWidthPixels =
          typeof pocketSlider === "object" && pocketSlider?.netWidth
            ? pocketSlider.netWidth * scale
            : 0;
        const netWidthStr = convertDecimalToFraction(
          typeof pocketSlider === "object" && pocketSlider?.netWidth
            ? pocketSlider.netWidth
            : 0,
        );

        ctx.beginPath();
        ctx.moveTo(frameXStart, 125 + height);
        ctx.lineTo(frameXStart + netWidthPixels, 125 + height);
        ctx.stroke();
        const unitSizeText = `NFW  ${netWidthStr}"`;
        ctx.fillText(
          unitSizeText,
          (frameXStart - 15 + netWidthPixels) / 2,
          140 + height,
        );
      } else {
        ctx.beginPath();
        ctx.moveTo(frameXStart, 100 + height);
        ctx.lineTo(frameXStart + frameWidth, 100 + height);
        ctx.stroke();
        let frameWidthText: string;
        if (isRightCorner) {
          frameWidthText = cornerWidthFractionRight
            ? `NFW  ${cornerWidthRight} ${cornerWidthFractionRight}"`
            : `NFW  ${cornerWidthRight}"`;
          ctx.fillText(
            frameWidthText,
            150 + doorWidthPixels + 30 + frameWidth / 2,
            120 + height,
          );
        } else {
          frameWidthText = unitSizeFraction
            ? `NFW  ${unitSize} ${unitSizeFraction}"`
            : `NFW  ${unitSize}"`;
          ctx.fillText(
            frameWidthText,
            xStart + frameWidth / 2 - 20,
            110 + height + 10,
          );
        }
      }

      // Overall height panel
      if (!isRightCorner) {
        const doubleSided = configChoice.includes("-");
        ctx.beginPath();
        const panelHeightStart = xStart + 20 + width;
        const xPanelHeight =
          hasSliderPocket && doubleSided
            ? panelHeightStart + pocketSize * 2
            : hasSliderPocket && !doubleSided
              ? panelHeightStart + pocketSize
              : panelHeightStart;
        ctx.moveTo(xPanelHeight, yStart);
        ctx.lineTo(xPanelHeight, yStart + panelHeightPixels);
        ctx.stroke();

        // Overall height glass
        ctx.beginPath();
        const xGlassHeight = frameXStart - 20;
        ctx.moveTo(xGlassHeight, yStart + railBtm);
        ctx.lineTo(xGlassHeight, yStart + panelHeightPixels - railBtm);
        ctx.stroke();

        // Overall height frame
        ctx.beginPath();
        const panelHeightFrameStart = xStart + 50 + width;
        const xHeight =
          hasSliderPocket && doubleSided
            ? panelHeightFrameStart + pocketSize * 2
            : hasSliderPocket && !doubleSided
              ? panelHeightFrameStart + pocketSize
              : panelHeightFrameStart;
        ctx.moveTo(xHeight, frameYStart);
        ctx.lineTo(xHeight, frameYStart + height);
        ctx.stroke();

        //draw panel height text
        ctx.save(); // Save current state
        let panelHeightTextStartX = 85 + width + distanceToMiddle;
        let panelHeightTextStartY = 80 + height / 2;

        hasSliderPocket && doubleSided
          ? ctx.translate(
              panelHeightTextStartX + pocketSize * 2,
              panelHeightTextStartY,
            )
          : hasSliderPocket && !doubleSided
            ? ctx.translate(
                panelHeightTextStartX + pocketSize,
                panelHeightTextStartY,
              )
            : ctx.translate(panelHeightTextStartX, panelHeightTextStartY);
        ctx.rotate(-Math.PI / 2); // Rotate counter-clockwise
        const panelHeightText = `P.Height  ${convertDecimalToFraction(
          panelHeight,
        )}"`;
        ctx.fillText(panelHeightText, 0, 0); // Draw text
        ctx.restore(); // Restore to previous state

        const heightText = heightFraction
          ? `NFH  ${heightSize} ${heightFraction}"`
          : `NFH  ${heightSize}"`;

        //draw glass height text
        ctx.save(); // Save current state
        ctx.translate(frameXStart - 30, 20 + yStart + height / 2);
        ctx.rotate(-Math.PI / 2); // Rotate counter-clockwise
        ctx.fillText("DLO  " + glass.glassHeight + '"', 0, 0); // Draw text
        ctx.restore(); // Restore to previous state

        //draw frame height text
        ctx.save(); // Save current state
        let frameHeightTextX = 120 + width + distanceToMiddle;
        const frameHeightTextY = 80 + height / 2;

        hasSliderPocket && doubleSided
          ? ctx.translate(frameHeightTextX + pocketSize * 2, frameHeightTextY)
          : hasSliderPocket && !doubleSided
            ? ctx.translate(
                frameHeightTextX + panelWidthPixels,
                frameHeightTextY,
              )
            : ctx.translate(frameHeightTextX, frameHeightTextY);
        ctx.rotate(-Math.PI / 2); // Rotate counter-clockwise
        ctx.fillText(heightText, 0, 0); // Draw text
        ctx.restore(); // Restore to previous state
      } else {
        // Overall height glass
        ctx.beginPath();
        const xGlassHeight = frameXStart - 20;
        ctx.moveTo(xGlassHeight, yStart + railBtm);
        ctx.lineTo(xGlassHeight, yStart + panelHeightPixels - railBtm);
        ctx.stroke();

        //draw glass height text
        ctx.save(); // Save current state
        ctx.translate(frameXStart - 30, 20 + yStart + height / 2);
        ctx.rotate(-Math.PI / 2); // Rotate counter-clockwise
        ctx.fillText("DLO  " + glass.glassHeight + '"', 0, 0); // Draw text
        ctx.restore(); // Restore to previous state
      }

      // Draw individual glass width
      const glassWidthY = frameYStart - 10;

      if (hasSliderPocket) {
        ctx.beginPath();
        const innerStileWidth =
          panelWidthPixels - stileThickness - stileThickness;
        const start =
          configChoice[0] === "P"
            ? xStart + pocketSize + stileLeft
            : xStart + stileLeft;
        const end = start;

        ctx.moveTo(end, glassWidthY);
        ctx.lineTo(end + innerStileWidth, glassWidthY);
        ctx.stroke();

        const xPosDlo = (end + (end + end + innerStileWidth) / 2) / 2;
        ctx.fillText("DLO", xPosDlo, glassWidthY - 25);
        ctx.fillText(glass.glassWidth + '"', xPosDlo, glassWidthY - 10);
      } else {
        ctx.beginPath();
        let innerStileWidth =
          panelWidthPixels - stileThickness - stileThickness;
        let start =
          doorType === "Slider" && !hasSliderPocket && dloPanel
            ? xStart + (dloPanel || 0) * panelWidthPixels
            : xStart + (dloPanel || 0) * panelWidthPixels + stileThickness;
        let end = start + innerStileWidth;

        if (
          (doorType === "Swing" || doorType === "Pivot") &&
          exactConfig?.includes("Sidelite") &&
          (sideliteSide === "left" || sideliteSide === "both")
        ) {
          innerStileWidth = sideliteWidthPixels - slim - slim;
          start = xStart + slim;
          end = start + innerStileWidth;
          const dayLightsidelite = sideLiteWidth?.split(" ")[1]
            ? `${Number(sideLiteWidth?.split(" ")[0]) - 5} ${
                sideLiteWidth?.split(" ")[1]
              }`
            : `${Number(sideLiteWidth?.split(" ")[0]) - 5}`;

          ctx.moveTo(start, glassWidthY);
          ctx.lineTo(end, glassWidthY);
          ctx.stroke();

          const xPosDloSl = (start + end) / 2 - 15;
          ctx.fillText("DLO", xPosDloSl, glassWidthY - 20);
          ctx.fillText(dayLightsidelite + '"', xPosDloSl, glassWidthY - 5);

          const gapKey = `panel2`;
          const gap = gaps[gapKey];

          const xActiveStart = end + gap + slim + stileThickness;
          const xActiveEnd =
            xActiveStart + panelWidthPixels - stileThickness - stileThickness;

          ctx.moveTo(xActiveStart, glassWidthY);
          ctx.lineTo(xActiveEnd, glassWidthY);
          ctx.stroke();

          const xPosDlo = (xActiveStart + xActiveEnd) / 2 - 15;
          ctx.fillText("DLO", xPosDlo, glassWidthY - 20);
          ctx.fillText(glass.glassWidth + '"', xPosDlo, glassWidthY - 5);
        } else if (
          (doorType === "Swing" || doorType === "Pivot") &&
          exactConfig?.includes("Sidelite") &&
          sideliteSide === "right"
        ) {
          innerStileWidth = panelWidthPixels - stileThickness - stileThickness;
          start = xStart + panelWidthX + slim;
          end = start + sideliteWidthPixels - slim - slim;
          const dayLightsidelite = sideLiteWidth?.split(" ")[1]
            ? `${Number(sideLiteWidth.split(" ")[0]) - 5} ${
                sideLiteWidth.split(" ")[1]
              }`
            : `${Number(sideLiteWidth?.split(" ")[0]) - 5}`;

          ctx.moveTo(start, glassWidthY);
          ctx.lineTo(end, glassWidthY);
          ctx.stroke();

          const xPosDloSl = (start + end) / 2 - 15;
          ctx.fillText("DLO", xPosDloSl, glassWidthY - 20);
          ctx.fillText(dayLightsidelite + '"', xPosDloSl, glassWidthY - 10);

          start = xStart + stileThickness;
          end = start + innerStileWidth;

          ctx.moveTo(start, glassWidthY);
          ctx.lineTo(end, glassWidthY);
          ctx.stroke();

          const xPosDlo = (start + end) / 2 - 15;
          ctx.fillText("DLO", xPosDlo, glassWidthY - 20);
          ctx.fillText(glass.glassWidth + '"', xPosDlo, glassWidthY - 10);
        } else {
          ctx.moveTo(start, glassWidthY);
          ctx.lineTo(end, glassWidthY);
          ctx.stroke();
          if (isRightCorner) {
            const xPosCorner = (start + end) / 2 - 15;
            ctx.fillText("DLO", xPosCorner, glassWidthY - 20);
            ctx.fillText(
              (glassRightCorner?.glassWidth || 0) + '"',
              xPosCorner,
              glassWidthY - 5,
            );
          } else {
            const xPosDlo = (start + end) / 2 - 15;
            ctx.fillText("DLO", xPosDlo, glassWidthY - 20);
            ctx.fillText(glass.glassWidth + '"', xPosDlo, glassWidthY - 5);
          }
        }
      }

      // Draw individual panel width dimension (for first panel only for simplicity)
      const panelWidthY = 15 + frameYStart + height;

      if (hasSliderPocket) {
        const pocketSide = configChoice[0] === "P" ? "left" : "right";
        if (pocketSide === "left") {
          const x = frameXStart;
          const x2 = frameXStart + pocketSize;

          ctx.beginPath();
          ctx.moveTo(x, panelWidthY);
          ctx.lineTo(x2, panelWidthY);
          ctx.stroke();
          ctx.fillText("Pocket", (x + x2) / 2 - 15, panelWidthY + 15);
          ctx.fillText(
            convertDecimalToFraction(pocketSize / scale) + '"',
            (x + x2) / 2 - 15,
            panelWidthY + 30,
          );
        } else {
          const pocketX = frameXStart + pocketSize + frameWidth;
          const pocketX2 = pocketX - pocketSize;

          ctx.beginPath();
          ctx.moveTo(pocketX, panelWidthY);
          ctx.lineTo(pocketX2, panelWidthY);
          ctx.stroke();
          ctx.fillText(
            "Pocket",
            (pocketX2 + pocketX) / 2 - 15,
            panelWidthY + 15,
          );
          ctx.fillText(
            convertDecimalToFraction(pocketSize / scale) + '"',
            (pocketX2 + pocketX) / 2 - 15,
            panelWidthY + 30,
          );
        }

        const x = configChoice[0] === "P" ? frameXStart + pocketSize : xStart;
        const x2 = x + panelWidthPixels;

        ctx.beginPath();
        ctx.moveTo(x, panelWidthY);
        ctx.lineTo(x2, panelWidthY);
        ctx.stroke();
        ctx.fillText("P.Width ", (x + x2) / 2 - 15, panelWidthY + 15);
        ctx.fillText(
          convertDecimalToFraction(panelWidth || 0) + '"',
          (x + x2) / 2 - 15,
          panelWidthY + 30,
        );
      } else {
        if (
          (doorType === "Swing" || doorType === "Pivot") &&
          exactConfig.includes("Sidelite") &&
          (sideliteSide === "left" || sideliteSide === "both")
        ) {
          ctx.beginPath();
          ctx.moveTo(xStart, panelWidthY);
          ctx.lineTo(xStart + sideliteWidthPixels, panelWidthY);
          ctx.stroke();
          const xPosSidelite = (xStart + xStart + sideliteWidthPixels) / 2 - 15;

          ctx.fillText("SL.Width", xPosSidelite, panelWidthY + 15);
          ctx.fillText(sideLiteWidth + '"', xPosSidelite, panelWidthY + 30);

          const gap = gaps[`panel2`];

          const xActiveStart =
            sideliteSide === "both"
              ? xStart + sideliteWidthPixels + gap
              : xStart + panelWidthX;
          const xActiveEnd = xActiveStart + panelWidthPixels;

          ctx.beginPath();
          ctx.moveTo(xActiveStart, panelWidthY);
          ctx.lineTo(xActiveEnd, panelWidthY);
          ctx.stroke();

          const xPosPanel = (xActiveEnd + xActiveStart) / 2 - 15;

          ctx.fillText("P.Width", xPosPanel, panelWidthY + 15);
          ctx.fillText(
            convertDecimalToFraction(panelWidth || 0) + '"',
            xPosPanel,
            panelWidthY + 30,
          );
        } else if (
          (doorType === "Swing" || doorType === "Pivot") &&
          exactConfig.includes("Sidelite") &&
          sideliteSide === "right"
        ) {
          const sideliteXStart = xStart + panelWidthX;
          const sideliteXEnd = sideliteXStart + sideliteWidthPixels;

          const xPosSidelite = (sideliteXEnd + sideliteXStart) / 2 - 20;

          ctx.beginPath();
          ctx.moveTo(sideliteXStart, panelWidthY);
          ctx.lineTo(sideliteXEnd, panelWidthY);
          ctx.stroke();
          ctx.fillText("SL.Width ", xPosSidelite, panelWidthY + 15);
          ctx.fillText(sideLiteWidth + '"', xPosSidelite, panelWidthY + 30);

          const end = xStart + panelWidthPixels;
          const xPosPanel = (xStart + end) / 2 - 20;

          ctx.beginPath();
          ctx.moveTo(xStart, panelWidthY);
          ctx.lineTo(end, panelWidthY);
          ctx.stroke();
          ctx.fillText("P.Width ", xPosPanel, panelWidthY + 15);
          ctx.fillText(
            convertDecimalToFraction(panelWidth || 0) + '"',
            xPosPanel,
            panelWidthY + 30,
          );
        } else {
          ctx.beginPath();
          ctx.moveTo(xStart, panelWidthY);
          ctx.lineTo(xStart + panelWidthPixels, panelWidthY);
          ctx.stroke();
          if (isRightCorner) {
            const xPosCorner =
              150 + doorWidthPixels + 10 + panelWidthPixels / 2;
            ctx.fillText("P.Width  ", xPosCorner, panelWidthY + 15);
            ctx.fillText(
              convertDecimalToFraction(panelWidthRightCorner || 0) + '"',
              xPosCorner,
              panelWidthY + 30,
            );
          } else {
            const xPosPanel = (xStart + xStart + panelWidthPixels) / 2 - 10;
            ctx.fillText("P.Width  ", xPosPanel, panelWidthY + 15);
            ctx.fillText(
              convertDecimalToFraction(panelWidth || 0) + '"',
              xPosPanel,
              panelWidthY + 30,
            );
          }
        }
      }
    }

    if (material === "All Wood") {
      var stilesWood = new Image();
      var railsWood = new Image();

      const horizontalGrain = `${cloudinaryWoodURL2}/${woodImg.imgLabel}.jpg`;
      railsWood.src = horizontalGrain;
      const verticalGrain = `${cloudinaryWoodURL}/${woodImg.imgLabel}.jpg`;
      stilesWood.src = verticalGrain;

      var stilesPattern: CanvasPattern | null = null;
      var railsPattern: CanvasPattern | null = null;

      // Create a promise to handle the loading of both images
      Promise.all([
        new Promise<void>((resolve) => {
          stilesWood.onload = function () {
            stilesPattern = ctx.createPattern(
              this as CanvasImageSource,
              "repeat",
            );
            resolve();
          };
        }),
        new Promise<void>((resolve) => {
          railsWood.onload = function () {
            railsPattern = ctx.createPattern(
              this as CanvasImageSource,
              "repeat",
            );
            resolve();
          };
        }),
      ])
        .then(() => {
          // Once both images are loaded, proceed with drawing
          panelWidthX = 0;
          canvasDraw(
            stilesPattern as CanvasPattern,
            railsPattern as CanvasPattern,
          );
        })
        .catch((error) => {
          console.error("Error loading images", error);
        });
    } else {
      panelWidthX = 0;
      canvasDraw(cladColor);
    }
  };

  useEffect(() => {
    const canvasElem = containerWindow ? canvasRefPortal : canvasRef;

    if (isInitialRender && containerWindow) {
      // Clear the canvas on first render in the portal
      clearCanvas(canvasElem);
    }

    if (!showPreview) return;
    if (hasErrors) return;

    drawDoor(
      doorWidthPixels,
      doorHeightPixels,
      exactConfig.includes("Primary") ? exactConfig.split(" ")[0] : exactConfig,
      numPanels,
      stiles || "",
      false,
      canvasElem,
    );

    if (isCorner) {
      const rightWidthPixels = (netWidthCornerRight || 0) * scale;
      const isRightCorner = true;

      drawDoor(
        rightWidthPixels,
        doorHeightPixels,
        cornerConfigRight || "",
        numPanelsRightCorner || 0,
        stiles || "",
        isRightCorner,
        canvasElem,
      );
    }
  }, [
    canvasWidth,
    canvasHeight,
    doorWidthPixels,
    doorHeightPixels,
    // swingPanelWidth,
    // swingPanelFraction,
    sideLiteWidth,
    exactConfig,
    exactConfigImg,
    cornerConfigRight,
    netWidthCornerRight,
    sillStyle,
    stilesAndRails,
    sdl,
    liteConfig,
    daylight,
    exteriorCladColor,
    material,
    woodSpecies,
    handleHeight,
    handleStyle,
    handleColor,
    hasHandle,
    shootBoltColor,
    pivotPoint,
    pivotBackPlate,
    pivotBackPlateColor,
    cornerFrameWidthLeft,
    cornerFrameWidthRight,
    addScreen,
    hasErrors,
    diffSideliteSdl,
    dlLayoutSidelite,
  ]);

  if (!showPreview) {
    return (
      <StyledDiv>
        <Typography style={{ color: "black" }}>
          Missing Required Fields:
        </Typography>
        <ul>
          {material === "All Wood" && !woodSpecies ? (
            <li style={{ color: "red" }}>Wood Species</li>
          ) : null}
          {isCorner && (!unitSize || !cornerWidthRight) ? (
            <li style={{ color: "red" }}>Unit Size</li>
          ) : !doorWidthPixels ? (
            <li style={{ color: "red" }}>Unit Size</li>
          ) : null}
          {(doorType === "Swing" || doorType === "Pivot") &&
          !swingPanelWidth ? (
            <li style={{ color: "red" }}>Panel Width</li>
          ) : null}
          {!doorHeightPixels && <li style={{ color: "red" }}>Height</li>}
          {isCorner && !cornerConfigRight ? (
            <li style={{ color: "red" }}>Configuration</li>
          ) : !exactConfig ? (
            <li style={{ color: "red" }}>Configuration</li>
          ) : null}
          {!stilesAndRails && (
            <li style={{ color: "red" }}>Stiles and Rails</li>
          )}
        </ul>
      </StyledDiv>
    );
  }

  if (hasErrors) {
    return (
      <StyledDiv>
        <Typography style={{ color: "black" }}>
          Fix the Following Errors:
        </Typography>
        <ul>
          {widthErr && <li style={{ color: "red" }}>NFW: {widthErr}</li>}
          {isCorner && widthErrCornerRight && (
            <li style={{ color: "red" }}>NFW: {widthErrCornerRight}</li>
          )}
          {heightErr && <li style={{ color: "red" }}>{heightErr}</li>}
          {(doorType === "Swing" || doorType === "Pivot") && swingWidthErr && (
            <li style={{ color: "red" }}>Panel Width: {swingWidthErr}</li>
          )}
          {(doorType === "Swing" || doorType === "Pivot") &&
            sideliteWidthErr && (
              <li style={{ color: "red" }}>{sideliteWidthErr}</li>
            )}
        </ul>
      </StyledDiv>
    );
  }

  return (
    <StyledDiv>
      {containerWindow ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: 10,
            }}
          >
            <Typography variant="caption" style={{ fontSize: 10 }}>
              As viewed from exterior
            </Typography>
            <Typography variant="caption" style={{ fontSize: 10 }}>
              All proportions and colors are best approximations
            </Typography>
          </div>

          <canvas
            ref={canvasRefPortal}
            key={"portalCanvas"}
            width={`${canvasWidth}`}
            height={`${canvasHeight}`}
          />
        </>
      ) : (
        <canvas
          ref={canvasRef}
          key={"mainCanvas"}
          width={`${canvasWidth}`}
          height={`${canvasHeight}`}
        />
      )}
    </StyledDiv>
  );
};

export default CanvasBox;
