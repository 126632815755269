import React, { useState, lazy, Suspense } from "react";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import api from "../../api/api";
import formatIntWithCommas from "../../util/formatIntWithCommas";
import { useQuery } from "react-query";
import { styled } from "@mui/material/styles";

const StyledDivWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // width: 250,
  // height: 150,
  width: 300,
  height: 200,
  // paddingTop: 10,
  marginRight: 20,
  marginTop: 50,
  [theme.breakpoints.down("lg")]: {
    width: 200,
    height: 100,
  },
}));

const StyledDivLoader = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledDivText = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: 10,
});

const StyledTypographyCP = styled(Typography)({
  color: "rgb(77, 174, 79, 1)",
});

const StyledTypographyPP = styled(Typography)({
  color: "rgba(54, 162, 235, 1)",
});

const StyledDivTextWrap = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  flexDirection: "column",
  alignItems: "flex-start",
});

const Doughnut = lazy(() => import("./DefaultExports/Doughnut"));

const backgroundColor = ["rgb(77, 174, 79, .5)", "rgba(54, 162, 235, .5)"];
const borderColor = ["rgb(77, 174, 79, 1)", "rgba(54, 162, 235, 1)"];
const borderWidth = 1;
const hoverOffset = 4;

interface ChartData {
  _id: string;
  totalRevenue: number;
}

const Chart = () => {
  const [totalAccepted, setTotalAccepted] = useState(0);
  const [totalAwaiting, setTotalAwaiting] = useState(0);

  const { isFetching, isError } = useQuery(
    "ChartRevenue",
    async () => {
      const res = await api.get("/api/fetchAllForChart");

      let tAccept = 0;
      let tAwait = 0;

      res.data.forEach((q: ChartData) => {
        if (q.totalRevenue && q._id !== "Archived") {
          q._id === "Quote"
            ? (tAwait += q.totalRevenue)
            : (tAccept += q.totalRevenue);
        }
      });

      setTotalAccepted(Math.round(tAccept));
      setTotalAwaiting(Math.round(tAwait));
    },
    { refetchOnWindowFocus: false },
  );

  const acceptedWithComma = totalAccepted
    ? formatIntWithCommas(totalAccepted)
    : 0;

  const awaitingWithComma = totalAwaiting
    ? formatIntWithCommas(totalAwaiting)
    : 0;

  return (
    <StyledDivWrapper>
      {!isFetching && (totalAccepted || totalAwaiting) ? (
        <>
          <StyledDivTextWrap>
            <StyledDivText>
              <StyledTypographyCP variant="h4">
                ${acceptedWithComma}
              </StyledTypographyCP>
              <StyledTypographyCP style={{ fontSize: 13 }} variant="body2">
                Your Current Revenue
              </StyledTypographyCP>
            </StyledDivText>
            <StyledDivText>
              <StyledTypographyPP variant="h4">
                ${awaitingWithComma}
              </StyledTypographyPP>
              <StyledTypographyPP style={{ fontSize: 13 }} variant="body2">
                Your Potential Revenue
              </StyledTypographyPP>
            </StyledDivText>
          </StyledDivTextWrap>
          <Suspense
            fallback={
              <Typography variant="subtitle1">Creating Chart...</Typography>
            }
          >
            <Doughnut
              data={{
                datasets: [
                  {
                    label: "Projected Revenue",
                    data: [totalAccepted, totalAwaiting],
                    backgroundColor,
                    borderColor,
                    borderWidth,
                    hoverOffset,
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: true,
                animation: false,
              }}
            />
          </Suspense>
        </>
      ) : isFetching ? (
        <StyledDivLoader>
          <Typography style={{ paddingRight: 20 }} variant="body1">
            Updating Chart
          </Typography>
          <CircularProgress />
        </StyledDivLoader>
      ) : (
        <Typography>No Revenue Data Found.</Typography>
      )}

      {isError && !isFetching && <Typography>No Quotes Found.</Typography>}
    </StyledDivWrapper>
  );
};

export default Chart;
