export function calculatePanelWidth(
  doorType: string,
  netWidth: number | undefined,
  numberOfPanels?: number | undefined,
  material?: string | null,
  exactConfig?: string | null,
  stilesAndRails?: string | null,
  screenType?: string | null,
  swingPanelWidth?: string | null,
  swingPanelFraction?: string | null,
  sliderPocket?: string | null,
  handleStyle?: string | null,
): number | null {
  var panelWidth: number = 0;

  if (!netWidth) return 0;

  if (doorType === "Fixed Panel") {
    if (exactConfig === "Fixed Bi-fold") {
      const jambLeg = material === "All Wood" ? 1.75 : 1.875;
      panelWidth = netWidth - jambLeg;
    } else if (exactConfig === "Fixed Slider") {
      panelWidth = netWidth - 1.75;
    } else if (exactConfig === "Fixed Full Bound") {
      panelWidth = netWidth - 1.75;
    } else if (exactConfig === "Fixed Inswing") {
      const jambLeg = material === "All Wood" ? 1.75 : 1.875;
      panelWidth = netWidth - jambLeg;
    } else if (exactConfig === "Fixed Outswing") {
      panelWidth = netWidth - 1.75;
    }
  } else if (doorType.includes("Bi-fold")) {
    if (!numberOfPanels) return null;

    const panelsInt = numberOfPanels;

    const extra = findPanelExtra(panelsInt);

    const jambLeg = screenType?.includes("Centor")
      ? 8
      : material === "All Wood"
        ? 1.75
        : 1.875;

    const pw =
      (netWidth - jambLeg - ((panelsInt + 1) * 0.125 + extra)) / panelsInt;

    panelWidth = pw - 0.015;
  } else if (doorType === "Swing" || doorType === "Pivot") {
    if (!swingPanelWidth) return 0;
    let pw = parseInt(swingPanelWidth);

    let pwFraction = swingPanelFraction
      ? parseInt(swingPanelFraction.split("/")[0]) /
        parseInt(swingPanelFraction.split("/")[1])
      : 0;

    panelWidth = pw + pwFraction;
  } else if (doorType === "Slider") {
    if (!exactConfig) return 0;

    var stiles =
      stilesAndRails === "Euro | Contemporary"
        ? 3.4375
        : stilesAndRails === "Slimline" ||
            stilesAndRails === "Slimline Traditional"
          ? 2.5
          : stilesAndRails === 'Bottom rail 7"' ||
              stilesAndRails === 'Bottom rail 4-3/4"'
            ? 4.75
            : 0;

    var panels = exactConfig.replace(/[^X^A^O]/g, "").length;

    const doubleSided = exactConfig.includes("-");

    let stileMultiply = doubleSided
      ? (exactConfig.split("-")[0].replace(/[^X^A^O]/g, "").length - 1) * 2
      : panels - 1;

    if (sliderPocket === "With Pocket") {
      const jambLeg =
        screenType?.includes("Centor") && !doubleSided ? 3.125 : 0;

      if (
        exactConfig === "PX" ||
        exactConfig === "XP" ||
        exactConfig === "PX-AP" ||
        exactConfig === "PA-XP"
      ) {
        panelWidth = !doubleSided
          ? netWidth - jambLeg - 0.875 - 0.1875 + 1.125
          : (netWidth - jambLeg - 0.1875 + 2.25) / 2;
      } else {
        const isDoubleWood = doubleSided && material === "All Wood";

        let pwAdder = isDoubleWood
          ? netWidth + 1.4375 + 0.1875
          : doubleSided && !isDoubleWood
            ? netWidth + 1.4375
            : netWidth - 0.25;

        panelWidth = (pwAdder - jambLeg + stiles * stileMultiply) / panels;
      }
    } else {
      const jambLeg = screenType?.includes("Centor") ? 6.25 : 0;

      let pwAdder = 0;
      if (exactConfig.includes("Primary")) {
        pwAdder = 2.125;
      } else {
        const singleDoor = ["XO", "OX", "OA-XO", "OX-AO"];

        if (
          singleDoor.includes(exactConfig) &&
          handleStyle !== "One Piece Truth (Flush Mount)"
        ) {
          pwAdder = material === "All Wood" && doubleSided ? 1.75 : 1.9375;
        } else if (!doubleSided) {
          pwAdder = 2.4375;
        } else if (doubleSided) {
          pwAdder = material === "All Wood" ? 2.75 : 2.9375;
        }
      }

      panelWidth =
        (netWidth - jambLeg - pwAdder + stiles * stileMultiply) / panels;
    }
  }

  return panelWidth;
}

export function findPanelExtra(panels: number): number {
  const p1 = [1, 2, 3];
  const p2 = [4, 5];
  if (p1.includes(panels)) {
    return 0.125;
  } else if (p2.includes(panels)) {
    return 0.1875;
  } else {
    return 0.25;
  }
}
