const paintImages = [
  { label: "Carbon Black", img: "CladColors/carbon" },
  { label: "Espresso Brown", img: "CladColors/espresso" },
  { label: "Sandcastle Tan", img: "CladColors/sand" },
  { label: "Linen White", img: "CladColors/linen" },
  { label: "Clear Anodized", img: "CladColors/standard" },
];

const paints = [
  { id: 1, type: "paintColor", label: "Carbon Black" },
  { id: 2, type: "paintColor", label: "Espresso Brown" },
  { id: 3, type: "paintColor", label: "Sandcastle Tan" },
  { id: 4, type: "paintColor", label: "Linen White" },
];

const paintPrime = [
  {
    id: 1,
    type: "prime",
    label: "Add Primer",
  },
  {
    id: 2,
    type: "paintAndPrime",
    label: "Add Standard Paint",
  },
  {
    id: 3,
    type: "customPaintOrStain",
    label: "Add Custom Paint",
  },
];

export { paintImages, paintPrime, paints };
