import React, { memo, FC } from "react";
import Choices from "../Choices";
import ReusableAccordian from "../ReusableAccordion";
import { useStore } from "../../store/store";
import { useAccordion } from "../../store/accordion";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import { hinge, hingePivot } from "./defaults";
import { Image } from "cloudinary-react";
import findHingePlateImg from "./findHingePlateImg";
import InfoIcon from "@mui/icons-material/Info";
import { Typography } from "@mui/material";
import { RowLayout } from "../../util/accordianStyles";

interface HingeProps {
  doorType: string;
}

const HingeAccordian: FC<HingeProps> = ({ doorType }) => {
  const addToQuote = useStore((state) => state.addToQuote);

  const hingeColor = useStore((state) => state.quote.hingeColor);
  const pivotHardware = useStore((state) => state.quote.pivotHardware);
  const dropseal = useStore((state) => state.quote.dropseal);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[20].expanded);

  const handleClick = (label: string) => {
    const checked = checkMark(label);

    if (checked) {
      addToQuote({ hingeColor: "" });
    } else {
      addToQuote({ hingeColor: label });
      if (findRequired("Hinge Color")) removeRequired("Hinge Color");
    }
  };

  const variant = (label: string) =>
    label === hingeColor ? "filled" : "outlined";

  const checkMark = (label: string) => label === hingeColor;

  const heading = doorType === "Pivot" ? "Hinge Plate Color" : "Hinge Color";

  const hingeOptions = doorType === "Pivot" ? hingePivot : hinge;

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="hinge"
      heading={heading}
      secondaryHeading="Select hinge color"
      colOrRow="col"
    >
      {doorType === "Pivot" && (
        <RowLayout style={{ paddingBottom: 20 }}>
          <InfoIcon color="primary" style={{ marginRight: 10 }} />

          <Typography>
            The hinge plate is positioned at the pivot axis point between the
            door's bottom and the sill.
          </Typography>
        </RowLayout>
      )}
      <RowLayout>
        {pivotHardware === "Fritsjurgens" && dropseal
          ? hingeOptions
              .filter((itm) => itm.id === 2)
              .map((state) => (
                <div key={state.id}>
                  <Choices
                    label={state.label}
                    variant="filled"
                    checkMark={true}
                    wide={true}
                  />
                </div>
              ))
          : hingeOptions.map((state) => (
              <div key={state.id} onClick={() => handleClick(state.label)}>
                <Choices
                  label={state.label}
                  variant={variant(state.label)}
                  checkMark={checkMark(state.label)}
                  wide={true}
                />
              </div>
            ))}
      </RowLayout>

      {hingeColor && pivotHardware && (
        <Image
          cloudName="ag-millworks"
          secure="true"
          publicId={findHingePlateImg(
            pivotHardware,
            hingeColor,
            dropseal || "",
          )}
        />
      )}
    </ReusableAccordian>
  );
};

export default memo(HingeAccordian);
