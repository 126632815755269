function changeOldInswingToCustom(jamb: string) {
  const format = jamb.replace('" Overall Jamb Width', "");

  const sepFraction = format.split("-")[1];
  if (sepFraction) {
    const wholeNum = format.split("-")[0];
    return `${wholeNum} ${sepFraction}`;
  }

  return format;
}

export default changeOldInswingToCustom;
