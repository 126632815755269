function getPivotEVImage(
  sidelite: string,
  handle: string,
  hinge: string,
  sideliteSide: string,
  equalPanels: boolean,
) {
  const URL =
    "https://res.cloudinary.com/ag-millworks/image/upload/pdfDrawings/elevationView/pivots";

  let sl =
    sidelite === "Add Double Sidelite"
      ? "_2SL"
      : sidelite === "Add Single Sidelite"
        ? "_1SL"
        : "";

  const singleWithLockSidelite =
    sidelite === "Add Single Sidelite" && sideliteSide === "LOCK"
      ? true
      : false;

  let lock = "";

  if (singleWithLockSidelite) {
    lock = "_LOCK";
  }

  const handleLbl =
    handle === "Dallas Lever"
      ? "_LEVER"
      : // : handle === "Handle Pull by Others"
        // ? "_HANDLE_BAR"
        "";

  const even = equalPanels ? "_EVEN" : "";

  return `${URL}/${hinge}${sl}${lock}${handleLbl}${even}`;
}

function getPivotPVImage(
  sidelite: string,
  hinge: string,
  sideliteSide: string,
  equalPanels: boolean,
) {
  const URL =
    "https://res.cloudinary.com/ag-millworks/image/upload/pdfDrawings/planView/pivots";

  let sl =
    sidelite === "Add Double Sidelite"
      ? "_2SL"
      : sidelite === "Add Single Sidelite"
        ? "_1SL"
        : "";

  const singleWithLockSidelite =
    sidelite === "Add Single Sidelite" && sideliteSide === "LOCK"
      ? true
      : false;

  let lock = "";

  if (singleWithLockSidelite) {
    lock = "_LOCK";
  }

  const even = equalPanels ? "_EVEN" : "";
  return `${URL}/${hinge}${sl}${lock}${even}`;
}

export { getPivotEVImage, getPivotPVImage };
