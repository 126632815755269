import React, { useState, FC } from "react";
import getModalStyle from "../../../../util/getModalStyle";
import {
  Button,
  Modal,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { StyledModalDiv } from "../../../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const StyledDivRow = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  paddingTop: 20,
});

const StyledDivRowCenter = styled("div")({
  display: "flex",
  justifyContent: "center",
  padding: 20,
});

interface SuccessModalProps {
  handleClose: () => void;
  open: boolean;
  isUpdated: boolean;
  modalEmailErr: string;
  toggleEmail: boolean;
}

export const SuccessModal: FC<SuccessModalProps> = ({
  handleClose,
  open,
  isUpdated,
  modalEmailErr,
  toggleEmail,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const h3Text =
    modalEmailErr === "Can not Update a Service with a Completed Status."
      ? "Your Service Request has NOT been"
      : "Your Service Request has been";

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      return handleClose();
    }
  };

  const body = (
    <StyledModalDiv style={modalStyle} onKeyDown={handleKeyDown}>
      <h3>
        {h3Text} {isUpdated ? "Updated" : "Submitted"}.
      </h3>
      {!toggleEmail && isUpdated ? null : !modalEmailErr ? (
        <p>You will recieve a confirmation email shortly.</p>
      ) : (
        <p style={{ color: "red" }}>{modalEmailErr}</p>
      )}
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          size="large"
          onClick={handleClose}
          variant="contained"
          color="primary"
        >
          Ok
        </Button>
      </div>
    </StyledModalDiv>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

interface UpdateWarningModalProps {
  handleClose: () => void;
  open: boolean;
  srFunction: () => void;
  toggle: boolean;
  handleToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
}

export const UpdateWarningModal: FC<UpdateWarningModalProps> = ({
  handleClose,
  open,
  srFunction,
  toggle,
  handleToggle,
  loading,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const body = (
    <StyledModalDiv style={modalStyle}>
      <h3 id="simple-modal-description">
        Are you sure you would like to continue?
      </h3>

      <StyledDivRowCenter>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={toggle}
                onChange={handleToggle}
              />
            }
            label="Send Email"
          />
        </FormGroup>
      </StyledDivRowCenter>

      <StyledDivRow>
        <Button
          size="large"
          onClick={handleClose}
          variant="contained"
          color="inherit"
        >
          Cancel
        </Button>
        <Button
          size="large"
          onClick={srFunction}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          Continue
        </Button>
      </StyledDivRow>
    </StyledModalDiv>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};
