import React, { useState, FC, Dispatch, SetStateAction } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { ErrIconSidebar } from "../ErrIconSidebar";
import { Typography, Tooltip, Checkbox } from "@mui/material";
import { useQueryClient } from "react-query";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import { useStore } from "../../store/store";
import { useAccordion } from "../../store/accordion";
import api from "../../api/api";
import { postCustomGlass } from "../../api/SidebarApi";
import letters from "../../util/letters";
import DotsModal from "./DotsModal";
import Dots from "./Dots";
import KdModal from "./KdModal";
import "./EditBtn.css";
import { UnitObj } from "../../util/quoteCartStorage";
import { styled } from "@mui/material/styles";

const StyledCheckboxHover = styled(Checkbox)({
  "&:hover": {
    backgroundColor: "transparent",
  },
});

const StyledSpanCheck = styled("span")({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  // backgroundColor: '#f5f8fa',
  // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  "$root.Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
  backgroundColor: "red",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#C12807",
  },
});

const StyledSpan = styled("span")({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  "$root.Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
});

const StyledTypography = styled(Typography)({
  position: "absolute",
  top: 0,
  left: 0,
  fontWeight: "bold",
});

const StyledEditDiv = styled("div")({
  position: "absolute",
  top: 25,
  left: -5,
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledRemoveDiv = styled("div")({
  position: "absolute",
  top: 45,
  left: -20,
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledDivCol = styled("div")({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  paddingRight: 25,
});

const StyledDivRow = styled("div")({
  display: "flex",
  flexDirection: "row",
});

function StyledCheckbox(props: any) {
  return (
    <StyledCheckboxHover
      color="default"
      size="small"
      checkedIcon={<StyledSpanCheck />}
      icon={<StyledSpan />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      {...props}
    />
  );
}

interface RemoveAndEditBtnProps {
  setWindowOrDoor: Dispatch<SetStateAction<string>>;
  loadDuplicate: boolean;
  duplicateUnit: (unitId: string) => void;
  index: number;
  unit: UnitObj;
  setEditQuote: Dispatch<SetStateAction<boolean>>;
  quoteId: string;
  handleDeleteCheck: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => void;
  showChecks: boolean;
  hoverId: string;
  status: string;
}

const RemoveAndEditBtn: FC<RemoveAndEditBtnProps> = ({
  setWindowOrDoor,
  loadDuplicate,
  duplicateUnit,
  index,
  unit,
  setEditQuote,
  quoteId,
  handleDeleteCheck,
  showChecks,
  hoverId,
  status,
}) => {
  const queryClient = useQueryClient();

  const isEdit = useStore((state) => state.isEdit);

  const setRequired = useRequiredMsgs((state) => state.setRequired);

  const setExpandedFromEdit = useAccordion(
    (state) => state.setExpandedFromEdit,
  );

  const setStoreFromEdit = useStore((state) => state.setStoreFromEdit);

  const defaultLites = !unit.numberOfLites ? "1 Lite " : "";

  const [switchChecked, setSwitchChecked] = useState(
    unit?.customGlass || false,
  );

  const [open, setOpen] = useState(false);
  const [openKD, setOpenKD] = useState(false);

  const [loading, setLoading] = useState(false);

  const [modalType, setModalType] = useState("");

  const [error, setError] = useState(false);

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSwitchChecked(e.target.checked);

  const handleOpen = (type: string) => {
    setModalType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setModalType("");
    setOpen(false);
  };

  const handleOpenKD = () => setOpenKD(true);

  const handleCloseKD = () => setOpenKD(false);

  const getSpecificQuote = () => {
    sessionStorage.setItem("unitId", JSON.stringify(unit._id));
    setLoading(true);
    api
      .get("/api/history/getUnit", {
        params: { quoteId, unitId: unit._id },
      })
      .then((res) => {
        if (unit.doorType.includes("Bi-fold")) {
          unit.doorType === "Bi-fold"
            ? setWindowOrDoor("Bi-Fold")
            : unit.doorType === "Bi-fold Zero Corner"
              ? setWindowOrDoor("Bi-Fold Zero Corner")
              : setWindowOrDoor("Bi-Fold Window");
        }

        const localKeys = Object.keys(JSON.parse(res.data.localStorageState));
        const local = JSON.parse(res.data.localStorageState);

        const accordionControl = res.data.accordionControl;
        const requiredArr = res.data.required;
        const unitInfo = res.data.unitInfo;

        if (unitInfo.addScreen && !unitInfo.screenType) {
          unitInfo["screenType"] = "Brio Accordion Screen";
        }

        setStoreFromEdit(unitInfo);

        for (var key of localKeys) {
          if (key === "quoteCart") continue;

          if (key !== "accordionControl" && key !== "store") {
            localStorage.setItem(key, local[key]);
          }
        }

        setExpandedFromEdit(accordionControl);

        setEditQuote((prevEdit) => !prevEdit);
        setRequired(requiredArr);
      })
      .then((data) => {
        setLoading(false);
        setError(false);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  };

  const addCustomGlass = async () => {
    const res = await postCustomGlass(quoteId, unit._id || "", switchChecked);

    if (res.data.error) {
      setError(true);
      return;
    }

    handleClose();
    setError(false);
    return queryClient.invalidateQueries("QuoteCart");
  };

  const InEditState = () => alert("Please Finish Editing Current Unit.");

  return (
    <>
      <DotsModal
        handleClose={handleClose}
        loading={loading}
        clickFn={modalType === "edit" ? getSpecificQuote : addCustomGlass}
        open={open}
        modalType={modalType}
        error={error}
        checked={switchChecked}
        handleCheck={handleCheck}
      />

      <KdModal
        open={openKD}
        handleClose={handleCloseKD}
        unitId={unit._id || ""}
        doorType={unit.doorType}
        quoteId={quoteId}
        kd={unit.kd || false}
      />

      <StyledDivRow>
        <StyledDivCol>
          <StyledTypography>{letters[index]}</StyledTypography>

          <Tooltip title="Edit Unit" enterDelay={500} placement="top">
            <StyledEditDiv>
              <EditIcon
                onClick={
                  !isEdit ? () => handleOpen("edit") : () => InEditState()
                }
                style={{ color: "#57d5f2" }}
                id={unit.required?.length ? "editBtn" : ""}
              />
            </StyledEditDiv>
          </Tooltip>

          <StyledRemoveDiv>
            <Dots
              openModal={handleOpen}
              duplicateUnit={duplicateUnit}
              loading={loadDuplicate}
              unitId={unit._id || ""}
              doorType={unit.doorType}
              openModalKD={handleOpenKD}
              kd={unit.kd || false}
              status={status}
            />
          </StyledRemoveDiv>

          {(hoverId === unit._id || showChecks) && (
            <div style={{ position: "absolute", bottom: -90, left: -12 }}>
              <StyledCheckbox
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleDeleteCheck(e, unit._id || "")
                }
              />
            </div>
          )}
        </StyledDivCol>
        {unit.material === "Clad Exterior/Wood Interior"
          ? ` ${defaultLites} Clad/Wood`
          : `${defaultLites} ${unit.material}`}{" "}
        {unit.doorType === "Swing"
          ? unit.swingDirection
          : unit.doorType === "Bi-fold Window"
            ? `Window ${defaultLites ? "BF" : "Bi-Fold"}`
            : unit.doorType === "Bi-fold Zero Corner"
              ? `Corner ${defaultLites ? "BF" : "Bi-Fold"}`
              : unit.doorType}
        {unit.required?.length ? <ErrIconSidebar /> : null}
      </StyledDivRow>
    </>
  );
};

export default RemoveAndEditBtn;
