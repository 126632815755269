import React, { memo, FC } from "react";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import { styled } from "@mui/material/styles";

const StyledChip = styled(Chip)(({ theme }) => ({
  "&&:hover": {
    cursor: "pointer",
  },
  "& > *": {
    margin: theme.spacing(0.3),
  },
  margin: 5,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

interface ChipProps {
  disabled?: boolean;
  label: string | React.ReactElement;
  variant: "filled" | "outlined";
  checkMark: boolean;
  wide?: boolean;
}

const Choices: FC<ChipProps> = ({
  disabled,
  label,
  variant,
  checkMark,
  wide,
}) => {
  const handleDelete = (id?: string) => console.log("delete clicked");

  if (checkMark) {
    return (
      <StyledChip
        disabled={disabled}
        style={{ width: wide ? 200 : "" }}
        label={label}
        color="primary"
        variant={variant}
        onDelete={handleDelete}
        deleteIcon={<DoneIcon />}
      />
    );
  }

  return (
    <StyledChip
      disabled={disabled}
      style={{ width: wide ? 200 : "" }}
      label={label}
      color="primary"
      variant={variant}
    />
  );
};

Choices.defaultProps = {
  wide: false,
  disabled: false,
};

export default memo(Choices);
