const group1Sill = ["OX", "XO", "OA-XO", "OX-AO"];
const group2Sill = ["OXX", "XXO", "OXA-XXO", "OXX-AXO"];
const group3Sill = ["OXXX", "XXXO", "OXXA-XXXO", "OXXX-AXXO"];
const group4Sill = ["OXXXX", "XXXXO", "OXXXA-XXXXO", "OXXXX-AXXXO"];
const group5Sill = ["PX", "XP", "PA-XP", "PX-AP"];
const group6Sill = [
  "XX Left Primary",
  "XX Right Primary",
  "PXX",
  "XXP",
  "PXA-XXP",
  "PXX-AXP",
];
const group7Sill = [
  "XXX Left Primary",
  "XXX Right Primary",
  "PXXX",
  "XXXP",
  "PXXA-XXXP",
  "PXXX-AXXP",
];
const group8Sill = [
  "XXXX Left Primary",
  "XXXX Right Primary",
  "PXXXX",
  "XXXXP",
  "PXXXA-XXXXP",
  "PXXXX-AXXXP",
];

const group1SS = ["PX", "XP", "PA-XP", "PX-AP"];
const group2SS = [
  "XX Left Primary",
  "XX Right Primary",
  "PXX",
  "XXP",
  "PXA-XXP",
  "PXX-AXP",
  "OX",
  "XO",
  "OA-XO",
  "OX-AO",
];
const group3SS = [
  "XXX Left Primary",
  "XXX Right Primary",
  "PXXX",
  "XXXP",
  "PXXA-XXXP",
  "PXXX-AXXP",
  "OXX",
  "XXO",
  "OXA-XXO",
  "OXX-AXO",
];
const group4SS = [
  "XXXX Left Primary",
  "XXXX Right Primary",
  "PXXXX",
  "XXXXP",
  "PXXXA-XXXXP",
  "PXXXX-AXXXP",
  "OXXX",
  "XXXO",
  "OXXA-XXXO",
  "OXXX-AXXO",
];
const group5SS = ["OXXXX", "XXXXO", "OXXXA-XXXXO", "OXXXX-AXXXO"];

const sillColor = [
  {
    id: 1,
    type: "sillColor",
    label: "Bronze Anodized",
  },
  {
    id: 2,
    type: "sillColor",
    label: "Clear Anodized",
  },
];

const sliderSills = [
  {
    id: 1,
    type: "sillStyle",
    label: "Raised SILLution",
  },
  {
    id: 2,
    type: "sillStyle",
    label: "Stainless Steel Recessed *",
  },
];

const slidingScrSills = [
  {
    id: 1,
    type: "sillStyle",
    label: "Raised SILLution",
  },
];

export {
  sliderSills,
  slidingScrSills,
  sillColor,
  group1SS,
  group1Sill,
  group2SS,
  group2Sill,
  group3SS,
  group3Sill,
  group4SS,
  group4Sill,
  group5SS,
  group5Sill,
  group6Sill,
  group7Sill,
  group8Sill,
};
