import React, { FC } from "react";
import { TextField, Typography } from "@mui/material";
import Choices from "../Choices";
import ReusableAccordian from "../ReusableAccordion";
import { useStore } from "../../store/store";
import { useAccordion } from "../../store/accordion";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import { Image, Transformation } from "cloudinary-react";
import { paintImages, paintPrime, paints } from "./defaults";
import Video from "../Video";
import { styled } from "@mui/material/styles";
import { RowLayout } from "../../util/accordianStyles";

const StyledROwLayoutTopDivider = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  borderTop: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
}));

interface UpdateObj {
  prime?: string;
  customPaintOrStain?: string;
  paintAndPrime?: string;
}

const PaintPrimeAccordian: FC<UpdateObj> = () => {
  const addToQuote = useStore((state) => state.addToQuote);

  const materialChoice = useStore((state) => state.quote.material);

  const paintPrimeChoice = useStore((state) => state.quote.paintAndPrime);
  const primeChoice = useStore((state) => state.quote.prime);
  const customPaintChoice = useStore((state) => state.quote.customPaintOrStain);
  const customPaintColor = useStore((state) => state.quote.customPaintColor);

  const btnChoice = paintPrimeChoice || primeChoice || customPaintChoice || "";

  const paintColor = useStore((state) => state.quote.paintColor);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[10].expanded);
  const require = useAccordion((state) => state.expanded[10].require);

  const handleClick = (label: string, type: string) => {
    const checked = label === btnChoice;

    if (checked) {
      addToQuote({ [type]: "" }, type);
    } else {
      let updateObj = { [type]: label };
      let ignoreResetKey = "";

      switch (type) {
        case "paintAndPrime":
          updateObj["prime"] = "";
          updateObj["customPaintOrStain"] = "";

          if (primeChoice) {
            ignoreResetKey = "prime";
          } else if (customPaintChoice) {
            ignoreResetKey = "customPaintOrStain";
          }
          break;
        case "prime":
          updateObj["paintAndPrime"] = "";
          updateObj["customPaintOrStain"] = "";

          if (paintPrimeChoice) {
            ignoreResetKey = "paintAndPrime";
          } else if (customPaintChoice) {
            ignoreResetKey = "customPaintOrStain";
          }
          break;
        case "customPaintOrStain":
          updateObj["prime"] = "";
          updateObj["paintAndPrime"] = "";

          if (paintPrimeChoice) {
            ignoreResetKey = "paintAndPrime";
          } else if (primeChoice) {
            ignoreResetKey = "prime";
          }
          break;
        default:
          return;
      }

      addToQuote(updateObj, ignoreResetKey);
    }
  };

  const handleClickColor = (label: string) => {
    const checked = label === paintColor;

    addToQuote({
      paintColor: !checked ? label : "",
    });

    if (!checked && findRequired("Paint Color")) {
      removeRequired("Paint Color");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value.length <= 15) {
      addToQuote({ customPaintColor: value });

      if (findRequired("Custom Paint Color"))
        removeRequired("Custom Paint Color");
    }
  };

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="paintPrime"
      heading={`${
        materialChoice.includes("Clad") ? "Interior" : ""
      } Paint/Prime`}
      secondaryHeading="Select paint and prime option"
      colOrRow="col"
      required={require ? true : false}
    >
      {customPaintChoice && (
        <Typography variant="subtitle1">
          *For custom paint please provide sample to AGM for proper pricing
        </Typography>
      )}
      <Typography variant="subtitle1">
        **A shade variation in color is expected in production line.
      </Typography>
      <RowLayout>
        {paintPrime.map((state) => (
          <div
            key={state.id}
            onClick={() => handleClick(state.label, state.type)}
          >
            <Choices
              label={state.label}
              variant={state.label === btnChoice ? "filled" : "outlined"}
              checkMark={state.label === btnChoice}
            />
          </div>
        ))}
      </RowLayout>
      {paintPrimeChoice && (
        <StyledROwLayoutTopDivider>
          {paints.map((state) => (
            <div key={state.id} onClick={() => handleClickColor(state.label)}>
              <Choices
                label={state.label}
                variant={state.label === paintColor ? "filled" : "outlined"}
                checkMark={state.label === paintColor}
              />
            </div>
          ))}
        </StyledROwLayoutTopDivider>
      )}

      {customPaintChoice ? (
        <TextField
          error={!customPaintColor ? true : false}
          helperText={
            !customPaintColor ? "Please Enter a Custom Paint Color" : ""
          }
          variant="outlined"
          margin="dense"
          size="small"
          onChange={handleChange}
          value={customPaintColor}
        />
      ) : (
        paintPrimeChoice &&
        paintColor &&
        paintImages
          .filter((image) => image.label === paintColor)
          .map((imageShown) => (
            <RowLayout key={imageShown.img}>
              <Image
                cloudName="ag-millworks"
                secure="true"
                publicId={imageShown.img}
              >
                <Transformation width="150" height="150" crop="scale" />
              </Image>
            </RowLayout>
          ))
      )}

      <Video src="https://youtu.be/fHxhDt6DbMM" />
    </ReusableAccordian>
  );
};

export default PaintPrimeAccordian;
