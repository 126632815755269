import React, { memo } from "react";
import {
  Typography,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import Choices from "../Choices";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Title24 from "../Title24";
import { useStore } from "../../store/store";
import { useAccordion } from "../../store/accordion";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import ReusableAccordion from "../ReusableAccordion";
import { glass } from "./defaults";
import { Image } from "cloudinary-react";
import Grow from "@mui/material/Grow";
import { ColLayout } from "../../util/accordianStyles";
import { styled } from "@mui/material/styles";

interface UpdateObj {
  glassType: string;
  glassAddon?: string;
}

const RowLayoutSpace = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  padding: theme.spacing(1, 2),
}));

const RowLayoutLeftDivide = styled("div")(({ theme }) => ({
  marginBottom: 10,
  marginTop: 20,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  borderLeft: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
}));

const StyledRowDiv = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
});

const StyledColDiv = styled("div")({
  alignSelf: "center",
  marginBottom: 20,
  display: "flex",
  flexDirection: "column",
  position: "relative",
});

const StyledTypography = styled(Typography)({
  position: "absolute",
  right: 20,
  bottom: -5,
});

function GlassAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);

  const glassChoice = useStore((state) => state.quote.glassType);
  const i89 = useStore((state) => state.quote.i89coating);
  const breatherTubes = useStore((state) => state.quote.breatherTubes);
  const glassAddon = useStore((state) => state.quote.glassAddon);
  const glassAddonSide = useStore((state) => state.quote.glassAddonSide);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const checked = breatherTubes === "Yes";

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[9].expanded);

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    addToQuote({
      breatherTubes: event.target.checked === true ? "Yes" : "None",
    });
  };

  const handleAddonSideChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    addToQuote({ glassAddonSide: value });

    if (findRequired("Glass Surface Side")) {
      removeRequired("Glass Surface Side");
    }
  };

  const handleAddonChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;

    if (value.includes("i89")) {
      return addToQuote({
        i89coating: value,
        glassAddon: "",
      });
    }

    addToQuote({
      i89coating: "",
      glassAddon: value,
    });
  };

  const handleClick = (label: string) => {
    const checked = label === glassChoice;

    if (checked) return;

    let update: UpdateObj = { glassType: label };

    if (label === "LoE 272") {
      update["glassAddon"] = "";
    }

    addToQuote(update);
  };

  const selectValue = i89 ? i89 : glassAddon;

  return (
    <ReusableAccordion
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="glass"
      heading="Type of Glass"
      secondaryHeading="Select a type of glass (argon included)"
      colOrRow="col"
    >
      <Typography variant="caption">* Upcharge Applies</Typography>
      <StyledRowDiv>
        <StyledColDiv>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleCheck}
                name="checked"
                color="primary"
              />
            }
            label="Jobsite above 5,000ft elevation"
          />
          <StyledTypography variant="caption">
            (Removes Argon, Adds Breather Tubes)
          </StyledTypography>
        </StyledColDiv>
        <RowLayoutSpace>
          {glass.map((state) => (
            <div key={state.id} onClick={() => handleClick(state.label)}>
              <Choices
                label={state.label}
                variant={state.label === glassChoice ? "filled" : "outlined"}
                checkMark={state.label === glassChoice}
              />
            </div>
          ))}
        </RowLayoutSpace>

        <RowLayoutLeftDivide>
          <ColLayout style={{ alignSelf: "flex-start" }}>
            <ColLayout style={{ marginTop: 0 }}>
              <Typography variant="subtitle2" style={{ paddingBottom: 5 }}>
                Additional Options *
              </Typography>

              <FormControl size="small">
                <Select
                  value={selectValue}
                  onChange={handleAddonChange}
                  margin="dense"
                  variant="outlined"
                  style={{ minWidth: 120 }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="Add i89 Coating *">i89 Coating</MenuItem>
                  <MenuItem value="Grey Tint">Grey Tint</MenuItem>
                  <MenuItem value="Bronze Tint">Bronze Tint</MenuItem>
                  <MenuItem value="Clear Lami">Clear Lami</MenuItem>
                  <MenuItem value="White Lami">White Lami</MenuItem>
                  <MenuItem value="Satin Etched">Satin Etched</MenuItem>
                </Select>
              </FormControl>
            </ColLayout>

            {glassAddon && (
              <ColLayout style={{ marginTop: 5 }}>
                <Typography
                  variant="subtitle2"
                  style={{
                    paddingBottom: 5,
                    color: !glassAddonSide ? "red" : "black",
                  }}
                >
                  Select Surface
                </Typography>

                <FormControl size="small" error>
                  <Select
                    value={glassAddonSide}
                    onChange={handleAddonSideChange}
                    margin="dense"
                    variant="outlined"
                    style={{ minWidth: 100 }}
                    error={!glassAddonSide ? true : false}
                  >
                    <MenuItem value=""> </MenuItem>
                    <MenuItem value="A">A</MenuItem>
                    <MenuItem value="B">B</MenuItem>
                  </Select>
                </FormControl>
              </ColLayout>
            )}
          </ColLayout>

          {glassAddon && (
            <ColLayout>
              <Grow in={true} timeout={1000}>
                <div>
                  <Image
                    cloudName="ag-millworks"
                    secure="true"
                    publicId="/Glass/GLASS_DETAIL_1"
                    style={{ paddingLeft: 20 }}
                  />
                </div>
              </Grow>

              <Typography variant="caption">
                NOTE: {glassChoice} will be added to opposite surface
              </Typography>
            </ColLayout>
          )}
        </RowLayoutLeftDivide>
      </StyledRowDiv>

      <Title24 />
    </ReusableAccordion>
  );
}

export default memo(GlassAccordian);
