const sillImgs = [
  {
    label: "Rounded Dam",
    imgLabel: "Sills/bifolds/bifold-sill-round",
    imgLabelScreen: "Sills/bifolds/bifold-sill-round",
    imgLabelCentor: "Sills/bifolds/bifold-sill-round-centor",
  },
  {
    label: "Squared Dam",
    imgLabel: "Sills/bifolds/bifold-sill-square",
    imgLabelScreen: "Sills/bifolds/bifold-sill-screen",
    imgLabelCentor: "Sills/bifolds/bifold-sill-square-centor",
  },
  {
    label: "Ultra Guide",
    imgLabel: "Sills/bifolds/ultra-guide",
    imgLabelScreen: "Sills/bifolds/ultra-guide_screen",
    imgLabelCentor: "Sills/bifolds/ultra-guide_centor",
  },
  {
    label: "ADA Ultra Guide",
    imgLabel: "Sills/bifolds/ada-ultra",
    imgLabelScreen: "Sills/bifolds/ada-ultra_screen",
    imgLabelCentor: "Sills/bifolds/ada-ultra_centor",
  },
];

const inswingSills = {
  id: 1,
  type: "sillStyle",
  label: "Inswing Sill",
  disabled: true,
};

const outswingSills = {
  id: 2,
  type: "sillStyle",
  label: "Outswing Sill",
  disabled: true,
};

const sillColor = [
  {
    id: 1,
    type: "sillColor",
    label: "Bronze Anodized",
    disabled: false,
  },
  {
    id: 2,
    type: "sillColor",
    label: "Clear Anodized",
    disabled: false,
  },
];

const raisedSills = [
  {
    id: 1,
    type: "sillStyle",
    label: "Rounded Dam",
    disabled: false,
  },
  {
    id: 2,
    type: "sillStyle",
    label: "Squared Dam",
    disbaled: false,
  },
];

const sills = [
  {
    id: 1,
    type: "sillStyle",
    label: "Raised Sill",
    disabled: false,
  },
  {
    id: 2,
    type: "sillStyle",
    label: "Ultra Guide",
    disabled: false,
  },
  {
    id: 3,
    type: "sillStyle",
    label: "ADA Ultra Guide",
    disabled: false,
  },
];

export { sillColor, sillImgs, raisedSills, sills, outswingSills, inswingSills };
