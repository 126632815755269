import React, { memo, FC } from "react";
import Typography from "@mui/material/Typography";
import Choices from "../Choices";
import ReusableAccordian from "../ReusableAccordion";
import { useStore } from "../../store/store";
import { useAccordion } from "../../store/accordion";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import { spacerColor, spacerColorSdl } from "./defaults";
import { RowLayout, ColLayout } from "../../util/accordianStyles";

const SpacerAccordian: FC = () => {
  const addToQuote = useStore((state) => state.addToQuote);

  const addDL = useStore((state) => state.quote.addDL);
  const isSDL = addDL?.includes("SDL");

  const spacerColorChoice = useStore((state) => state.quote.spacerColor);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[15].expanded);

  const handleClick = (label: string) => {
    const checked = checkMark(label);

    if (checked) {
      addToQuote({ spacerColor: "" });
    } else {
      addToQuote({ spacerColor: label });

      if (findRequired("IG Spacer Color")) removeRequired("IG Spacer Color");
    }
  };

  const variant = (label: string) =>
    label === spacerColorChoice ? "filled" : "outlined";

  const checkMark = (label: string) => label === spacerColorChoice;

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="spacer"
      heading="IG Spacer Color"
      secondaryHeading="Select color"
    >
      <ColLayout>
        {isSDL && (
          <Typography variant="subtitle1">
            *Only available in Black with SDL
          </Typography>
        )}
        <RowLayout>
          {!isSDL
            ? spacerColor.map((state) => (
                <div key={state.id} onClick={() => handleClick(state.label)}>
                  <Choices
                    label={state.label}
                    disabled={false}
                    variant={variant(state.label)}
                    checkMark={checkMark(state.label)}
                    wide={true}
                  />
                </div>
              ))
            : spacerColorSdl.map((state) => (
                <div key={state.id}>
                  <Choices
                    label={state.label}
                    disabled={true}
                    variant={"filled"}
                    checkMark={true}
                    wide={true}
                  />
                </div>
              ))}
        </RowLayout>
      </ColLayout>
    </ReusableAccordian>
  );
};

export default memo(SpacerAccordian);
