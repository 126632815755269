import React, { FC } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Divider,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { blueGrey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

const HeadingText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
}));

const SecondaryHeadingText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
  color: theme.palette.text.secondary,
}));

const StyledDiv = styled("div")({
  flexBasis: "33.33%",
});

const styles = {
  details: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  details2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};

interface ReusableAccordianProps {
  children: React.ReactNode;
  expanded: boolean;
  handleAccordClick: (name: string) => void;
  accordionName: string;
  heading: string;
  secondaryHeading: string;
  colOrRow?: string;
  required?: boolean;
  required5?: boolean;
  padding?: boolean;
}

const ReusableAccordian: FC<ReusableAccordianProps> = ({
  children,
  expanded,
  handleAccordClick,
  accordionName,
  heading,
  secondaryHeading,
  colOrRow,
  required,
  required5,
  padding,
}) => {
  const details = colOrRow === "col" ? styles.details2 : styles.details;

  const isRequired =
    required && !required5 ? (
      <span style={{ color: "red", fontSize: 22 }}>*</span>
    ) : required5 ? (
      <span style={{ color: "red", fontSize: 22 }}>**</span>
    ) : null;

  return (
    <Accordion
      expanded={expanded}
      style={{
        backgroundColor: required5 ? blueGrey[50] : "",
        borderBottom: "1px solid #dcdcdc",
      }}
      onChange={(e) => handleAccordClick(accordionName)}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1c-content"
        id="panel1c-header"
      >
        <StyledDiv>
          <HeadingText>
            {heading} {isRequired}
          </HeadingText>
        </StyledDiv>
        <StyledDiv>
          <SecondaryHeadingText>{secondaryHeading}</SecondaryHeadingText>
        </StyledDiv>
      </AccordionSummary>
      <AccordionDetails
        style={{ paddingBottom: padding ? 40 : "", ...details }}
      >
        {children}
      </AccordionDetails>
      <Divider />
    </Accordion>
  );
};

ReusableAccordian.defaultProps = {
  required: true,
  required5: false,
  colOrRow: "row",
  padding: false,
};

export default ReusableAccordian;
