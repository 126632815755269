import React, { FC } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import getParams from "../hooks/useParams";
import GenerateShipLabels from "../quotePDF/GenerateShipLabels";
import axios from "axios";

async function getInstallData(quoteId: string) {
  if (!quoteId) return;
  return await axios.get(`/install/${quoteId}`);
}

const ShippingLabel: FC = () => {
  const location = useLocation();
  const quoteId = getParams(location.pathname);

  const { data, isFetching, error } = useQuery(
    ["ShipLabels", quoteId],
    async () => {
      if (!quoteId) throw Error;

      const response = await getInstallData(quoteId);
      return response?.data.data;
    },
    { refetchOnWindowFocus: false, refetchOnMount: false },
  );

  if (!isFetching && !data) return <div>No Quote Found</div>;

  if (error) return <div>Problem Occurred</div>;

  return (
    <>
      {!isFetching && data ? (
        <GenerateShipLabels data={data} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 100,
          }}
        >
          Loading ...
        </div>
      )}
    </>
  );
};

export default ShippingLabel;
