import React, { FC } from "react";
import { Image, Text } from "@react-pdf/renderer";
import {
  swingJambLegImg,
  bifoldJambLegImg,
  fixedJambLeg,
  pivotJambLeg,
} from "../../Drawings/CAD";
import { sliderJambLegsKey } from "../../Drawings/sliderCAD";
import { styles } from "./Styles";
import { UnitObj } from "../../util/quoteCartStorage";

const findSliderStyle = (
  sliderPocket: string,
  exactConfig: string,
  addScreen: string,
  screenPocket: string,
) => {
  if (
    sliderPocket === "With Pocket" &&
    (exactConfig.includes("-") ||
      !addScreen?.includes("Double Screen") ||
      screenPocket)
  ) {
    return styles.jambSectionSliderWide;
  }

  if (
    sliderPocket !== "With Pocket" &&
    !exactConfig.includes("Primary") &&
    exactConfig.includes("-")
  ) {
    return styles.jambSectionSliderWide;
  }

  return styles.jambSectionSlider;
};

interface JambLegProps {
  item: UnitObj;
}

const JambLegSection: FC<JambLegProps> = ({ item }) => {
  if (item.doorType === "Slider") {
    const sliderImg = sliderJambLegsKey(
      item.material,
      item.exactConfig,
      item.sliderPocket || "",
      item.screenType || "",
      item.addScreen || "",
      item.addSlidingScreen || "",
      item.screenLayout || "",
      item.screenPocket || "",
      item.handleStyle || "",
    );

    const sliderStyle = findSliderStyle(
      item.sliderPocket || "",
      item.exactConfig,
      item.addScreen || "",
      item.screenPocket || "",
    );

    return <Image src={sliderImg} style={sliderStyle} />;
  }

  if (item.doorType === "Pivot") {
    const pivotImg = pivotJambLeg(
      item.exactConfig.includes("LH") ? "LH" : "RH",
      item.sideLite || "",
      item.exactConfigImg?.includes("LOCK") ? "LOCK" : "",
    );

    return <Image src={pivotImg} style={{ width: "auto", height: 200 }} />;
  }

  if (item.doorType === "Swing") {
    const swingImg = swingJambLegImg(
      item.swingDirection || "",
      item.material,
      item.swingType || "",
      item.sideLite || "",
      item.exactConfig.slice(-2),
      item.screenType || "",
      item.addScreen || "",
      item.screenLayout || "",
      item.screenPocket || "",
      item.exactConfigImg?.replace(".jpg", "").slice(-4) || "",
    );

    return <Image src={swingImg} style={styles.sectionImgSwing} />;
  }

  if (item.doorType.includes("Bi-fold")) {
    const bifoldImg = bifoldJambLegImg(
      item.material,
      item.screenType || "",
      item.addScreen || "",
      item.screenLayout || "",
      item.screenPocket || "",
    );

    const bifoldStyle =
      !item.addScreen || (item.addScreen && !item.screenPocket)
        ? { width: "auto", height: "auto", maxWidth: 200, maxHeight: 200 }
        : styles.sectionImg;

    return <Image src={bifoldImg} style={bifoldStyle} />;
  }

  if (item.doorType === "Fixed Panel") {
    const fixedPanelImg = fixedJambLeg(item.material, item.exactConfig);

    return <Image src={fixedPanelImg} style={styles.sectionImg} />;
  }

  return <Text />;
};

export default JambLegSection;
