import { UnitObj } from "../../util/quoteCartStorage";

export interface PartData {
  description: string;
  qty: number;
}

function findPartsData(units: UnitObj[]): {
  units: (UnitObj & { partsData?: PartData[] })[];
  totalItems: number;
  totalLineItems: number;
} {
  var totalItems = 0;
  var totalLineItems = 0;

  for (let unit of units) {
    unit["partsData"] = [];

    var totalUnitItems = !unit.kd
      ? Number(unit.numberOfPanels) + 2
      : unit.doorType.includes("Corner")
      ? Number(unit.numberOfPanels) + 7
      : Number(unit.numberOfPanels) + 5;

    if (unit.kd && unit.addScreen) {
      if (unit.addScreen.includes("Double")) totalUnitItems += 2;
      else totalUnitItems++;

      if (unit.doorType === "Slider" && unit.sliderPocket === "With Pocket") {
        if (unit.exactConfig.includes("-")) totalUnitItems += 2;
        else totalUnitItems++;
      }
    }

    if (unit.doorType === "Slider") {
      if (unit.sillStyle?.includes("Steel")) totalUnitItems++;

      if (unit.sliderPocket === "With Pocket") {
        if (unit.exactConfig.includes("-")) totalUnitItems += 6;
        else totalUnitItems += 3;
      } else {
        if (unit.addSlidingScreen && unit.addSlidingScreen.includes("Double"))
          totalUnitItems += 2;
        else if (unit.addSlidingScreen) totalUnitItems++;
      }
    }

    totalLineItems += totalUnitItems

    function addPartsData(qty: number, description: string) {
      unit["partsData"].push({
        description,
        qty,
      });
    }

    let itmNum = 0;
    const qty = Number(unit.quantity);

    const totalPanelQty = Number(unit.numberOfPanels) * qty;
    addPartsData(totalPanelQty, "Panel");
    itmNum += totalPanelQty;

    if (unit.kd) {
      if (unit.doorType.includes("Corner")) {
        itmNum += 6 * qty;
        addPartsData(qty, "Left Head Jamb");
        addPartsData(qty, "Left Sill");
        addPartsData(qty, "Left Jamb Leg");
        addPartsData(qty, "Right Head Jamb");
        addPartsData(qty, "Right Sill");
        addPartsData(qty, "Right Jamb Leg");
      } else {
        itmNum += 4 * qty;
        addPartsData(qty, "Head Jamb");
        addPartsData(qty, "Sill");
        addPartsData(qty, "Left Jamb Leg");
        addPartsData(qty, "Right Jamb Leg");
      }

      if (unit.addScreen) {
        if (unit.addScreen.includes("Double")) {
          itmNum += 2 * qty;
          addPartsData(2 * qty, "Retractable Screen");
        } else {
          itmNum += qty;
          addPartsData(qty, "Retractable Screen");
        }

        if (unit.doorType === "Slider" && unit.sliderPocket === "With Pocket") {
          if (unit.exactConfig.includes("-")) {
            itmNum += 2 * qty;
            addPartsData(2, "Screen Pocket");
          } else {
            itmNum += qty;
            addPartsData(qty, "Screen Pocket");
          }
        }
      }
    } else {
      itmNum += qty;
      addPartsData(qty, "Frame");
    }

    if (unit.doorType === "Slider") {
      if (unit.sillStyle?.includes("Steel")) {
        itmNum += qty;
        addPartsData(qty, "Stainless Steel Track");
      }

      if (unit.sliderPocket === "With Pocket") {
        const side = unit.exactConfig.includes("-")
          ? "both"
          : unit.exactConfig[0] === "P"
          ? "left"
          : "right";

        switch (side) {
          case "both":
            itmNum += 6 * qty;
            addPartsData(qty, "Ext. Pocket Stop Left");
            addPartsData(qty, "Int. Pocket Stop Left");
            addPartsData(qty, "Left Concealer");
            addPartsData(qty, "Ext. Pocket Stop Right");
            addPartsData(qty, "Int. Pocket Stop Right");
            addPartsData(qty, "Right Concealer");
            break;
          case "left":
            itmNum += 3 * qty;
            addPartsData(qty, "Ext. Pocket Stop Left");
            addPartsData(qty, "Int. Pocket Stop Left");
            addPartsData(qty, "Left Concealer");
            break;
          case "right":
            itmNum += 3;
            addPartsData(qty, "Ext. Pocket Stop Right");
            addPartsData(qty, "Int. Pocket Stop Right");
            addPartsData(qty, "Right Concealer");
            break;
          default:
            console.log("default switch");
        }
      } else if (unit.addSlidingScreen) {
        if (unit.addSlidingScreen.includes("Double")) {
          itmNum += 2 * qty;
          addPartsData(2 * qty, "Sliding Screen");
        } else {
          itmNum += qty;
          addPartsData(qty, "Sliding Screen");
        }
      }
    }

    itmNum += qty;
    addPartsData(qty, "Parts Box");
    unit["partsItemAmount"] = itmNum;

    totalItems += itmNum;
  }

  return { units, totalItems, totalLineItems };
}

export default findPartsData;
