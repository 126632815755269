import React, { useState, useEffect, FC } from "react";
import {
  Typography,
  CircularProgress,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { uploadSaberis } from "../../../../api/DzoneApi";
import { green } from "@mui/material/colors";
import { Done as DoneIcon } from "@mui/icons-material";
import { Image } from "cloudinary-react";
import { RowLayout } from "../../../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const StyledCircularProgress = styled(CircularProgress)({
  color: "white",
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: "-12px",
  marginLeft: "-12px",
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 8,
  top: 8,
  color: theme.palette.grey[500],
}));

interface SaberisModalProps {
  handleClose: () => void;
  open: boolean;
  quoteId: string;
}

const SaberisModal: FC<SaberisModalProps> = ({
  handleClose,
  open,
  quoteId,
}) => {
  const [username, setUsername] = useState("");

  const [password, setPassword] = useState("");

  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(false);
    setError("");
    setSuccess(false);
  }, [open]);

  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleSaberisClick = async () => {
    if (!quoteId) return;

    if (!username || !password) {
      setSuccess(false);
      return setError("Missing Username or Password");
    }
    setLoading(true);
    try {
      const res = await uploadSaberis(quoteId, username, password);

      if (res?.data?.status === "RequestQueued") {
        setLoading(false);
        setError("");
        return setSuccess(true);
      }
    } catch (err) {
      console.log(err);
      setSuccess(false);
      setLoading(false);
      setError("A Problem Occurred");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if(event.key === "Enter"){
      return handleSaberisClick()
    }
  }

  const buttonSx = {
    ...(success
      ? {
          bgcolor: green[700],
          "&:hover": {
            bgcolor: green[800],
          },
        }
      : { bgcolor: "#2b3f82" }),
  };

  return (
    <Dialog onClose={handleClose} open={open} onKeyDown={handleKeyDown}>
      <DialogTitle style={{ backgroundColor: "#2b3f82" }} sx={{ m: 0, p: 2 }}>
        <Image
          secure="true"
          cloudName="ag-millworks"
          publicId="saberis_logo"
          width="250"
          height="50"
        />
      </DialogTitle>
      <StyledIconButton onClick={handleClose}>
        <CloseIcon />
      </StyledIconButton>
      <DialogContent dividers>
        <RowLayout>
          <Typography style={{ paddingRight: 10 }} gutterBottom>
            Username:
          </Typography>
          <TextField
            value={username}
            onChange={handleChangeUsername}
            size="small"
          />
        </RowLayout>

        <RowLayout>
          <Typography style={{ paddingRight: 10 }} gutterBottom>
            Password:
          </Typography>
          <TextField
            type="password"
            value={password}
            onChange={handleChangePassword}
            size="small"
          />
        </RowLayout>

        <RowLayout>
          <Typography variant="caption">
            By clicking "Upload to Saberis," you give us permission to send your
            information to a third party through their API, which may require
            you to set up credentials with them. AG Millworks is not liable for
            the transmission of credentials, quotes, or any other data. You also
            confirm that you have the authority to grant this permission.
          </Typography>
        </RowLayout>

        {error && (
          <RowLayout>
            <Typography style={{ color: "red", fontWeight: "bold" }}>
              {error}
            </Typography>
          </RowLayout>
        )}
      </DialogContent>
      <DialogActions>
        <Box sx={{ m: 1, position: "relative" }}>
          <Button
            variant="contained"
            sx={buttonSx}
            disabled={loading}
            onClick={handleSaberisClick}
            startIcon={success && <DoneIcon />}
          >
            Upload to Saberis
          </Button>
          {loading && <StyledCircularProgress size={24} />}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SaberisModal;
