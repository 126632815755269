import React, { memo } from "react";
import ReusableAccordian from "../ReusableAccordion";
import { useStore } from "../../store/store";
import { useAccordion } from "../../store/accordion";
import { magnets } from "./defaults";
import Choices from "../Choices";
import { Image, Transformation } from "cloudinary-react";
import findImages from "./findImages";
import {
  Typography,
  Select,
  MenuItem,
  Alert,
  SelectChangeEvent,
} from "@mui/material";
import { useRequiredMsgs } from "../../store/requiredMsgs";
import InfoIcon from "@mui/icons-material/Info";
import { RowLayout, ColLayout } from "../../util/accordianStyles";

function PivotMagnetAccordian() {
  const addToQuote = useStore((state) => state.addToQuote);

  const pivotHardware = useStore((state) => state.quote.pivotHardware);
  const pivotAction = useStore((state) => state.quote.pivotAction);

  const pivotMagnet = useStore((state) => state.quote.pivotMagnet);
  const pivotMagnetColor = useStore((state) => state.quote.pivotMagnetColor);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expanded = useAccordion((state) => state.expanded[37].expanded);

  const findRequired = useRequiredMsgs((state) => state.findRequired);
  const removeRequired = useRequiredMsgs((state) => state.removeRequired);

  const handleClick = (label: string) => {
    const checked = label === pivotMagnet;

    if (checked) {
      addToQuote({ pivotMagnet: "" }, "pivotMagnet");
    } else {
      addToQuote({ pivotMagnet: label });
    }
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    addToQuote({ pivotMagnetColor: value });
    if (value && findRequired("Magnet Finish")) removeRequired("Magnet Finish");
  };

  return (
    <ReusableAccordian
      expanded={expanded}
      handleAccordClick={handleAccordClick}
      accordionName="pivotMagnet"
      heading="Magnet Catch"
      secondaryHeading="Select Magnet Catch"
      required={false}
      colOrRow="col"
    >
      {pivotHardware === "Fritsjurgens" && pivotAction === "Self Closing" ? (
        <Alert severity="error">
          Ghostop Magnet not available with Fritsjurgens Self Closing hardware
        </Alert>
      ) : (
        <>
          <RowLayout style={{ paddingBottom: 20 }}>
            <InfoIcon color="primary" style={{ marginRight: 10 }} />

            <Typography>
              The Ghostop magnet is embedded in the finished floor, assisting in
              keeping the door open.
            </Typography>
          </RowLayout>

          <RowLayout>
            {magnets.map((state) => (
              <div key={state.id} onClick={() => handleClick(state.label)}>
                <Choices
                  label={state.label}
                  variant={state.label === pivotMagnet ? "filled" : "outlined"}
                  checkMark={state.label === pivotMagnet}
                  wide={true}
                />
              </div>
            ))}
          </RowLayout>

          {pivotMagnet && (
            <RowLayout style={{ paddingTop: 20 }}>
              <ColLayout style={{ marginRight: 20 }}>
                <Typography style={{ fontWeight: "bold" }}>
                  Magnet Finish{" "}
                  <span
                    style={{ color: "red", fontWeight: "bold", fontSize: 22 }}
                  >
                    *
                  </span>
                </Typography>

                <Select
                  onChange={handleChange}
                  value={pivotMagnetColor}
                  style={{ minWidth: 150 }}
                  size="small"
                >
                  <MenuItem value="" />
                  <MenuItem value="Stainless Steel">Stainless Steel</MenuItem>
                  <MenuItem value="Black">Black</MenuItem>
                </Select>
              </ColLayout>

              <Image
                cloudName="ag-millworks"
                secure="true"
                publicId={findImages(pivotMagnetColor || "")}
                style={{ paddingLeft: 20 }}
              >
                <Transformation width="200" crop="scale" />
              </Image>

              <Image
                cloudName="ag-millworks"
                secure="true"
                publicId="PivotHardware/ghostop_floor"
                style={{ paddingLeft: 20 }}
              >
                <Transformation width="200" crop="scale" />
              </Image>
            </RowLayout>
          )}
        </>
      )}
    </ReusableAccordian>
  );
}

export default memo(PivotMagnetAccordian);
