import React, { memo, FC } from "react";
import { Fab } from "@mui/material";
import PlayIcon from "@mui/icons-material/PlayArrow";
import { styled } from "@mui/material/styles";

const StyledFab = styled(Fab)({
  color: "white",
  backgroundColor: "#4b4b4b",
});

interface VideoProps {
  src: string;
}

const Video: FC<VideoProps> = ({ src }) => {
  return (
    <div style={{ paddingTop: 40 }}>
      <a
        href={src}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <StyledFab variant="extended" color="customGrey" size="small">
          <PlayIcon sx={{ mr: 0.5 }} />
          Watch Video
        </StyledFab>
      </a>
    </div>
  );
};

export default memo(Video);
