import React from "react";
import { windowRails, cladRails, woodRails } from "./defaults";
import Choices from "../Choices";

export function showStilesOptions(
  doorType: string,
  materialChoice: string,
  stileCategory: string,
  handleClick: (label: string, type: string) => void,
  chosenConfig: string,
  panelErr: { slimline?: string; euro?: string; euroTrad?: string },
) {
  if (doorType === "Bi-fold Window") {
    return (
      <>
        {windowRails.map((state) => (
          <div
            key={state.id}
            onClick={() => handleClick(state.label, state.type)}
          >
            <Choices
              label={state.label}
              variant={
                state.label?.split(" ")[0] === stileCategory ||
                state.label?.split(" ")[0] === stileCategory?.split(" ")[0]
                  ? "filled"
                  : "outlined"
              }
              checkMark={state.label?.split(" ")[0] === stileCategory}
            />
          </div>
        ))}
      </>
    );
  }

  if (doorType === "Pivot") {
    const rails = materialChoice === "wood" ? woodRails : cladRails;

    return (
      <>
        {rails
          .filter((item) =>
            panelErr.slimline && panelErr.euro
              ? item.label.includes("Traditional")
              : panelErr.slimline
                ? !item.label.includes("Slimline")
                : item,
          )
          .map((state) => (
            <div
              key={state.id}
              onClick={() => handleClick(state.label, state.type)}
            >
              <Choices
                label={state.label}
                variant={
                  state.label?.split(" ")[0] === stileCategory ||
                  state.label?.split(" ")[0] === stileCategory?.split(" ")[0]
                    ? "filled"
                    : "outlined"
                }
                checkMark={state.label?.split(" ")[0] === stileCategory}
              />
            </div>
          ))}
      </>
    );
  }

  if (
    materialChoice === "clad" &&
    doorType !== "Bi-fold Window" &&
    doorType !== "Slider"
  ) {
    return (
      <>
        {cladRails.map((state) => (
          <div
            key={state.id}
            onClick={() => handleClick(state.label, state.type)}
          >
            <Choices
              label={state.label}
              variant={
                state.label?.split(" ")[0] === stileCategory ||
                state.label?.split(" ")[0] === stileCategory?.split(" ")[0]
                  ? "filled"
                  : "outlined"
              }
              checkMark={state.label?.split(" ")[0] === stileCategory}
            />
          </div>
        ))}
      </>
    );
  }

  if (materialChoice === "clad" && doorType === "Slider") {
    return (
      <>
        {cladRails
          .filter((item) =>
            ["PX", "XP", "PX-AP", "PA-XP"].includes(chosenConfig)
              ? !item.label.includes("Slimline")
              : panelErr.slimline && !panelErr.euroTrad
                ? !item.label.includes("Slimline")
                : !panelErr.slimline && panelErr.euroTrad
                  ? item.label.includes("Slimline")
                  : panelErr.slimline && panelErr.euroTrad
                    ? item.label === null
                    : item,
          )
          .map((state) => (
            <div
              key={state.id}
              onClick={() => handleClick(state.label, state.type)}
            >
              <Choices
                label={state.label}
                variant={
                  state.label?.split(" ")[0] === stileCategory ||
                  state.label?.split(" ")[0] === stileCategory?.split(" ")[0]
                    ? "filled"
                    : "outlined"
                }
                checkMark={state.label?.split(" ")[0] === stileCategory}
              />
            </div>
          ))}
      </>
    );
  }

  if (
    materialChoice === "wood" &&
    doorType !== "Bi-fold Window" &&
    doorType !== "Slider"
  ) {
    return (
      <>
        {woodRails.map((state) => (
          <div
            key={state.id}
            onClick={() => handleClick(state.label, state.type)}
          >
            <Choices
              label={state.label}
              variant={
                state.label?.split(" ")[0] === stileCategory
                  ? "filled"
                  : "outlined"
              }
              checkMark={state.label?.split(" ")[0] === stileCategory}
            />
          </div>
        ))}
      </>
    );
  }

  if (materialChoice === "wood" && doorType === "Slider") {
    return (
      <>
        {woodRails
          .filter((item) =>
            ["PX", "XP", "PX-AP", "PA-XP"].includes(chosenConfig)
              ? !item.label.includes("Slimline")
              : panelErr.slimline && !panelErr.euroTrad
                ? !item.label.includes("Slimline")
                : !panelErr.slimline && panelErr.euroTrad
                  ? item.label.includes("Slimline")
                  : panelErr.slimline && panelErr.euroTrad
                    ? item.label === null
                    : item,
          )
          .map((state) => (
            <div
              key={state.id}
              onClick={() => handleClick(state.label, state.type)}
            >
              <Choices
                label={state.label}
                variant={
                  state.label?.split(" ")[0] === stileCategory
                    ? "filled"
                    : "outlined"
                }
                checkMark={state.label?.split(" ")[0] === stileCategory}
              />
            </div>
          ))}
      </>
    );
  }

  return null;
}
