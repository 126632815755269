import React, { FC } from "react";
import DealerCells from "./Cells/DealerCells";
import AdminInsideSalesCells from "./Cells/AdminInsideSalesCells";
import OutsideSalesCells from "./Cells/OutsideSalesCells";
import ProductionCells from "./Cells/ProductionCells";
import { QuoteObj, Overwrite, UserObj } from "../../../GlobalTypes";
import { CutsheetDataObj } from "../../../GlobalTypes";

type AdminQuoteObj = Overwrite<QuoteObj, { userId: UserObj }>

interface QuoteFetchTableAdmin extends AdminQuoteObj {
  daysToClose?: string;
}

interface QuoteFetchTable extends QuoteObj {
  daysToClose?: string;
}

export interface TableCellsProps {
  tab: string;
  row: QuoteFetchTable | QuoteFetchTableAdmin;
  handleViewClick?: (quote: QuoteObj) => void;
  viewPdfId?: string;
  handleViewPdf?: (id: string, user: string, pdfLabel: string) => void;
  numSelected?: number;
  hoverId?: string;
  handleCheck: (event: React.MouseEvent<any>, quote: QuoteObj) => void;
  isItemSelected: boolean;
}

interface RoleTableCellsProps extends TableCellsProps {
  role: string;
  loadingCutsheet: boolean;
  cutsheetData: CutsheetDataObj[];
}

const RoleTableCells: FC<RoleTableCellsProps> = ({
  role,
  tab,
  row,
  handleViewClick,
  viewPdfId,
  loadingCutsheet,
  isItemSelected,
  handleCheck,
  cutsheetData,
  handleViewPdf,
  numSelected,
  hoverId,
}) => {
  if (role === "Dealer" || role === "Manager" || role === "Company Manager")
    return (
      <DealerCells
        row={row as QuoteFetchTable}
        handleViewClick={handleViewClick}
        tab={tab}
        role={role}
        handleCheck={handleCheck}
        isItemSelected={isItemSelected}
        numSelected={numSelected}
        hoverId={hoverId}
      />
    );

  if (role === "Admin" || role === "Inside Sales")
    return (
      <AdminInsideSalesCells
        row={row as QuoteFetchTableAdmin}
        handleViewClick={handleViewClick}
        viewPdfId={viewPdfId}
        loadingCutsheet={loadingCutsheet}
        tab={tab}
        isItemSelected={isItemSelected}
        handleCheck={handleCheck}
        handleViewPdf={handleViewPdf}
        cutsheetData={cutsheetData}
        numSelected={numSelected}
        hoverId={hoverId}
      />
    );

  if (role === "Outside Sales")
    return (
      <OutsideSalesCells
        row={row as QuoteFetchTableAdmin}
        handleViewClick={handleViewClick}
        viewPdfId={viewPdfId}
        tab={tab}
        handleViewPdf={handleViewPdf}
        handleCheck={handleCheck}
        isItemSelected={isItemSelected}
        numSelected={numSelected}
        hoverId={hoverId}
      />
    );

  if (role === "Production")
    return (
      <ProductionCells
        row={row as QuoteFetchTableAdmin}
        viewPdfId={viewPdfId}
        loadingCutsheet={loadingCutsheet}
        tab={tab}
        isItemSelected={isItemSelected}
        handleCheck={handleCheck}
        cutsheetData={cutsheetData}
        handleViewPdf={handleViewPdf}
      />
    );

  return null;
};

export default RoleTableCells;
