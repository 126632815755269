import React, { FC } from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)({
  color: "white",
  marginBottom: 60,
  paddingTop: -20,
});

interface CreateQuoteBtnProps {
  isBtnDisabled: boolean;
  handleCreateQuote: () => void;
}

const CreateQuoteBtn: FC<CreateQuoteBtnProps> = ({
  isBtnDisabled,
  handleCreateQuote,
}) => {
  return (
    <StyledButton
      disabled={isBtnDisabled}
      onClick={handleCreateQuote}
      size="large"
      color="customGrey"
      variant="contained"
    >
      Create Quote
    </StyledButton>
  );
};

export default CreateQuoteBtn;
