import React, { memo } from "react";
import { Tooltip, Typography, Hidden } from "@mui/material";
import { Image } from "cloudinary-react";
import { styled } from "@mui/material/styles";

// const HtmlTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .MuiTooltip-tooltip`]: {
//     backgroundColor: "#f5f5f9",
//     color: "rgba(0, 0, 0, 0.87)",
//     maxWidth: 220,
//     fontSize: theme.typography.pxToRem(12),
//     border: "1px solid #dadde9",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   }
// }));

const HtmlTooltip = styled(Tooltip)(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const StyledRowCenter = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  zIndex: 5000,
  marginRight: 10,
});

const StyledTypography = styled(Typography)({
  color: "white",
  paddingLeft: 5,
});

const OtsmIcon = () => {
  return (
    <StyledDiv>
      <HtmlTooltip
        title="Prices are hidden, disable OTSM in Account Settings to view prices"
        placement="bottom"
        enterTouchDelay={0}
      >
        <StyledRowCenter>
          <Image cloudName="ag-millworks" secure={true} publicId="OTSM" />

          <Hidden mdDown>
            <StyledTypography variant="caption">OTSM</StyledTypography>
          </Hidden>
        </StyledRowCenter>
      </HtmlTooltip>
    </StyledDiv>
  );
};

export default memo(OtsmIcon);
