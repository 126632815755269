const hardware = [
  { id: 1, type: "pivotHardware", label: "Dorma" },
  { id: 2, type: "pivotHardware", label: "Fritsjurgens" },
];

const actionDorma = [{ id: 1, type: "pivotAction", label: "Free Swing" }];

const actionFrits = [
  { id: 1, type: "pivotAction", label: "Free Swing" },
  { id: 2, type: "pivotAction", label: "Self Closing" },
];

export { hardware, actionDorma, actionFrits };
