import React, { useState, useEffect, FC } from "react";
import { blueGrey } from "@mui/material/colors";
import { Typography, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  getFolderData,
  signOffStatusChange,
  downloadEsignDoc,
} from "../api/SignSuccessApi";
import { styled } from "@mui/material/styles";

const StyledParentDiv = styled("div")({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledChildDiv = styled("div")({
  backgroundColor: blueGrey[50],
  border: "3px solid grey",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: 600,
  borderRadius: 20,
  position: "fixed",
  top: "30%",
});

const StyledInfoDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  paddingBottom: 20,
  paddingTop: 10,
});

const SignSuccessPage: FC = () => {
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("user") || JSON.stringify(""));
  const [dloadURL, setDloadURL] = useState("");
  const [fileName, setFileName] = useState("");
  const location = useLocation();

  const handleClick = () => navigate(`/dealerZone/${user}`);

  useEffect(() => {
    const folderID = location.search.replace("?folderId=", "").split("&")[0];

    async function getEsignData() {
      const response = await getFolderData(folderID);

      if (!response.data) {
        console.log("no folder data found");
      } else {
        setFileName(response.data.folder.folderName);

        const res = await downloadEsignDoc(folderID);

        var blob = new Blob([res.data], { type: "application/pdf" });

        var url = window.URL || window.webkitURL;

        var downloadUrl = url.createObjectURL(blob);

        setDloadURL(downloadUrl);
      }
    }

    getEsignData();
  }, []);

  useEffect(() => {
    async function postStatus() {
      return await signOffStatusChange();
    }

    if (!location.search) return;
    if (location.search.split("=")[2] === "signing_success") {
      postStatus();
    }
  }, [location.search]);

  return (
    <StyledParentDiv>
      <StyledChildDiv>
        {user ? (
          <>
            <div style={{ borderBottom: "1px solid black" }}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Thank You! Your Order has been Submitted.
              </Typography>
            </div>
            <StyledInfoDiv>
              <img
                style={{ height: 100, width: 400 }}
                src="https://res.cloudinary.com/ag-millworks/image/upload/AG_Logos/AGlogoBlack.png"
                alt="logo"
              />
              <Typography style={{ padding: 20 }}>
                You will recieve an email with the signed documents.
              </Typography>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <a
                  href={dloadURL}
                  download={fileName}
                  style={{ textDecoration: "none", marginRight: 5 }}
                >
                  <Button variant="contained" color="primary">
                    <GetAppIcon />
                    Download Signed PDF
                  </Button>
                </a>
                <Button
                  style={{ marginLeft: 5 }}
                  variant="contained"
                  color="cyan"
                  onClick={handleClick}
                >
                  Return to Dealer Zone
                </Button>
              </div>
            </StyledInfoDiv>
          </>
        ) : (
          <Typography style={{ color: "red" }}>
            An Error Occurred. Please Try Refreshing the Page.
          </Typography>
        )}
      </StyledChildDiv>
    </StyledParentDiv>
  );
};

export default SignSuccessPage;
