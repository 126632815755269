import React, { FC } from "react";
import { Dialog, AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const StyledBodyDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  marginTop: 50,
  marginLeft: 20,
  marginRight: 20,
});

const StyledColDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const StyledSectionTitle = styled("h2")({
  fontSize: 18,
  fontWeight: "bold",
  marginBottom: 10,
});

const StyledSectionContent = styled("div")({
  marginLeft: 20,
});

interface PrivacyPolicyProps {
  show: boolean;
  setShow: (toShow: boolean) => void;
}

const PrivacyPolicy: FC<PrivacyPolicyProps> = ({ show, setShow }) => {
  return (
    <Dialog fullScreen open={show} onClose={() => setShow(false)}>
      <AppBar color="customGrey">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setShow(false)}
            aria-label="close"
            size="large"
          >
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
          <Typography style={{ color: "white" }} variant="h6">
            Privacy Policy
          </Typography>
        </Toolbar>
      </AppBar>
      <StyledBodyDiv>
        <StyledColDiv>
          <h1>Privacy Policy</h1>

          <i>This Privacy Policy was last revised on June 09, 2023</i>

          <p>
            At AG Millworks, we are committed to protecting your privacy and
            ensuring the security of your personal information. This Privacy
            Policy outlines how we collect, use, store, and disclose the
            information you provide when using our website. By accessing or
            using our website, you agree to the terms and practices described in
            this Privacy Policy.
          </p>
        </StyledColDiv>

        <div>
          <StyledSectionTitle>1. Information We Collect</StyledSectionTitle>
          <StyledSectionContent>
            <p>1.1 Personal Information</p>
            <p>
              When you visit our website or engage in certain activities on our
              website we may collect personal information from you. This
              personal information may include:
            </p>
            <ul>
              <li>
                Full name: We collect your full name to personalize our
                interactions with you.
              </li>
              <li>
                Email address: We collect your email address to communicate with
                you, send you information, and provide customer support.
              </li>
              <li>
                Business address and phone number: If you provide your business
                address or phone number, we may use it for specific purposes
                related to your business interactions with us.
              </li>
            </ul>
            <p>1.2 IP Address and Usage Data</p>
            <p>
              We may collect your IP address and gather usage data when you
              visit our website. This information helps us analyze trends,
              administer the website, track user movements, and gather
              demographic information for aggregate use. We do not use this
              information to personally identify you unless required by law.
            </p>
          </StyledSectionContent>
        </div>

        <div>
          <StyledSectionTitle>
            2. Use of Collected Information
          </StyledSectionTitle>
          <StyledSectionContent>
            <p>We use the collected information for the following purposes:</p>
            To provide and improve our services: We may use your personal
            information to deliver the products or services you have requested
            and enhance your overall experience with our website. To communicate
            with you: We may use your email address to send you relevant
            information, updates, newsletters, marketing materials, and respond
            to your inquiries. To personalize your experience: We may use your
            personal information to understand your preferences and tailor our
            website's content, offerings, and promotions to suit your interests.
            To ensure the security of our website: We may use your IP address
            and usage data to detect and prevent fraudulent activities,
            unauthorized access, and other security issues. To comply with legal
            obligations: We may disclose your personal information if required
            by law, government request, court order, or to protect our rights,
            property, or safety, or that of our users or others.
          </StyledSectionContent>
        </div>

        <div>
          <StyledSectionTitle>
            3. Information Sharing and Disclosure
          </StyledSectionTitle>
          <StyledSectionContent>
            We are committed to maintaining the privacy of your personal
            information. We will not sell, trade, rent, or share your personal
            information with third parties for their promotional purposes
            without your explicit consent, except as described in this Privacy
            Policy. We may share your personal information with trusted
            third-party service providers who assist us in operating our
            website, conducting our business, or servicing you, as long as they
            agree to keep the information confidential and use it only for the
            purposes outlined in this Privacy Policy. We may also disclose your
            personal information if we believe that such disclosure is necessary
            to enforce our website's policies, protect our rights or property,
            comply with legal obligations, or ensure the safety of our users or
            others.
          </StyledSectionContent>
        </div>

        <div>
          <StyledSectionTitle>
            4. Cookies and Tracking Technologies
          </StyledSectionTitle>
          <StyledSectionContent>
            We use cookies and similar tracking technologies to enhance your
            experience on our website. These technologies enable us to recognize
            your browser, remember your preferences, and provide personalized
            content. You can manage your cookie preferences through your browser
            settings.
          </StyledSectionContent>
        </div>

        <div>
          <StyledSectionTitle>5. Data Security</StyledSectionTitle>
          <StyledSectionContent>
            We implement reasonable security measures to protect the personal
            information we collect from unauthorized access, disclosure,
            alteration, or destruction. However, no method of transmission or
            electronic storage is 100% secure. Therefore, while we strive to use
            commercially acceptable means to protect your personal information,
            we cannot guarantee its absolute security.
          </StyledSectionContent>
        </div>

        <div>
          <StyledSectionTitle>6. Third-Party Links</StyledSectionTitle>
          <StyledSectionContent>
            Our website may contain links to third-party websites or services
            that are not operated by us. We have no control over the content,
            privacy policies, or practices of these third-party websites or
            services. We encourage you to review their privacy policies before
            providing any personal information.
          </StyledSectionContent>
        </div>

        <div>
          <StyledSectionTitle>
            7. Changes to this Privacy Policy
          </StyledSectionTitle>
          <StyledSectionContent>
            We reserve the right to update or modify this Privacy Policy at any
            time. If we make any material changes to this Privacy Policy, we
            will notify you by posting the updated version on our website or by
            sending you an email. Your continued use of our website after any
            changes indicates your acceptance of the revised Privacy Policy.
          </StyledSectionContent>
        </div>

        <div>
          <StyledSectionTitle>8. Contact Us</StyledSectionTitle>
          <StyledSectionContent>
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or the handling of your personal information, please
            contact us at (805) 644-4494.
          </StyledSectionContent>
        </div>

        <StyledColDiv style={{ width: "100%", margin: 20 }}>
          <p>
            By using our website, you acknowledge that you have read,
            understood, and agreed to this Privacy Policy.
          </p>
        </StyledColDiv>
      </StyledBodyDiv>
    </Dialog>
  );
};

export default PrivacyPolicy;
