const baseURL =
  "https://res.cloudinary.com/ag-millworks/image/upload/pdfDrawings";

function fixedEVPV(config: string, isProduction?: boolean) {
  const pathEV = "elevationView/fixedPanels";
  let pathPV = "planView/fixedPanels";
  if(isProduction) pathPV += '/production'
  let label = "";

  switch (config) {
    case "Fixed Bi-fold":
      label = "bifold";
      break;
    case "Fixed Slider":
      label = "slider";
      break;
    case "Fixed Inswing":
      label = "inswing";
      break;
    case "Fixed Outswing":
      label = "outswing";
      break;
    case "Fixed Full Bound":
      label = "fullbound";
      break;
    default:
      label = "";
  }

  const ev = `${baseURL}/${pathEV}/${label}`;
  const pv = `${baseURL}/${pathPV}/fixed_v2.png`;
  return { ev, pv };
}

export { fixedEVPV };
