import { woodImgs } from "../Wood/defaults";
import {
  specialOrder,
  nonPocketHandles,
  handles,
  sliderImgs,
  handleImages,
} from "../Handle/defaults";
import { cladColorImgs } from "../ExteriorClad/defaults";

function findHandleImg(doorType: string, handleStyle: string) {
  const images = doorType === "Slider" ? sliderImgs : handleImages;

  return images.find((img) => img.label === handleStyle)?.imgLabel || "";
}

function findCladColorImg(label: string) {
  return cladColorImgs.find((img) => img.label === label)?.imgLabel || "";
}

function findWoodImg(woodAbbrev: string) {
  const obj = woodImgs.find((img) => img.abbrev === woodAbbrev);
  if (!obj) return "";

  return obj.imgLabel;
}

function getHeaderTitle(key: string) {
  let header = "";

  switch (key) {
    case "breatherTubes":
      header = "Breather Tubes";
      break;
    case "glass":
      header = "Glass";
      break;
    case "handle":
      header = "Handle";
      break;
    case "handleHeight":
      header = "Handle Height";
      break;
    case "kerf":
      header = "Kerf";
      break;
    case "screenColor":
      header = "Screen Color";
      break;
    case "spacer":
      header = "Spacer";
      break;
    case "wood":
      header = "Wood";
      break;
    case "cladColor":
      header = "Clad Color";
      break;
    default:
      header = "";
  }

  return header;
}

function getHandleArray(doorType: string, exactConfig: string) {
  let handleChoice: { id: number; type: string; label: string }[];

  if (doorType === "Slider") {
    if (exactConfig.includes("P")) {
      handleChoice = [
        {
          id: 1,
          type: "handleStyle",
          label: "One Piece Truth (Flush Mount)",
        },
      ];
    } else {
      handleChoice = nonPocketHandles;
    }
  } else {
    handleChoice = handles.concat(specialOrder);
  }

  return handleChoice;
}

function getCornerNumPanels(configLeft: string, configRight: string) {
  const numPanelsLeft = Number(configLeft.split("")[0]);
  const numPanelsRight = Number(configRight.split("")[0]);

  return numPanelsLeft + numPanelsRight;
}

export {
  getCornerNumPanels,
  getHandleArray,
  getHeaderTitle,
  findWoodImg,
  findCladColorImg,
  findHandleImg,
};
