function fractionToInt(wholeNum: string, fraction?: string) {
  let splitFraction =
    fraction && parseInt(fraction) !== 0 ? fraction.split("/") : null;
  let fractionAsDecimal = splitFraction
    ? parseInt(splitFraction[0]) / parseInt(splitFraction[1])
    : 0;

  const int = parseInt(wholeNum) + fractionAsDecimal;

  return int;
}

export default fractionToInt;
