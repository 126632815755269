import React, { useState, FC } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";
import getModalStyle from "../../../util/getModalStyle";
import { StyledModalDiv } from "../../../util/accordianStyles";

interface ConfirmSignModalProps {
  handleClose: () => void;
  open: boolean;
  handleSign: () => void;
  loading: boolean;
  cantSign: string;
}

const ConfirmSignModal: FC<ConfirmSignModalProps> = ({
  handleClose,
  open,
  handleSign,
  loading,
  cantSign,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if(event.key === "Enter"){
      return handleSign()
    }
  }

  const body = (
    <StyledModalDiv style={modalStyle} onKeyDown={handleKeyDown}>
      <Typography
        variant="h6"
        style={{ fontWeight: "bold" }}
        id="simple-modal-description"
      >
        Are you sure you want to Sign?
      </Typography>

      <Typography variant="body2">
        Note: Once signed, this job will be sent to production and changes
        cannot be made
      </Typography>

      {cantSign === "jobsite and po" ? (
        <Typography style={{ color: "red" }} variant="body2">
          *Must Enter a PO Number and Jobsite Info to Submit Order
        </Typography>
      ) : cantSign === "jobsite" ? (
        <Typography style={{ color: "red" }} variant="body2">
          *Must Enter Jobsite Info to Submit Order
        </Typography>
      ) : cantSign === "po" ? (
        <Typography style={{ color: "red" }} variant="body2">
          *Must Enter a PO Number to Submit Order
        </Typography>
      ) : null}

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          paddingTop: 20,
        }}
      >
        <Button
          onClick={handleClose}
          variant="contained"
          color="inherit"
          disabled={loading}
        >
          Cancel
        </Button>

        {!loading ? (
          <Button
            disabled={cantSign ? true : false}
            onClick={handleSign}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        ) : (
          <Button variant="contained" color="primary">
            <CircularProgress style={{ color: "white" }} />
          </Button>
        )}
      </div>

      {loading && (
        <Typography style={{ color: "blue", paddingTop: 20 }} variant="body2">
          This may take a moment...
        </Typography>
      )}
    </StyledModalDiv>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default ConfirmSignModal;
