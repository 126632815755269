const hinge = [
  {
    id: 1,
    type: "hingeColor",
    label: "Bronze",
  },
  {
    id: 2,
    type: "hingeColor",
    label: "Stainless Steel",
  },
];

const hingePivot = [
  {
    id: 1,
    type: "hingeColor",
    label: "Black",
  },
  {
    id: 2,
    type: "hingeColor",
    label: "Stainless Steel",
  },
];

export { hinge, hingePivot };
