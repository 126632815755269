import React, { useState, useEffect, FC } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  Divider,
  TextField,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  IconButton,
  useMediaQuery,
  useTheme,
  SelectChangeEvent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Image, Transformation } from "cloudinary-react";
import letters from "../../util/letters";
import { postIgnoreCartCheck } from "../../api/SidebarApi";
import { woodForWood, wood } from "../Wood/defaults";
import { cladColor } from "../ExteriorClad/defaults";
import { cartCheckUpdate } from "../../api/SidebarApi";
import { useQueryClient } from "react-query";
import KerfCustomOptions from "../Kerf/KerfCustomOptions";
import getCornerImgWidth from "../../util/getCornerImgWidth";
import {
  findCladColorImg,
  findHandleImg,
  findWoodImg,
  getCornerNumPanels,
  getHandleArray,
  getHeaderTitle,
} from "./helpers";
import "./CartCheck.css";
import { LineItem } from "../../util/checkCart";
import { styled } from "@mui/material/styles";

const StickyTableCell = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-head": {
    backgroundColor: "white",
    color: theme.palette.common.white,
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 2,
  },
  "&.MuiTableCell-body": {
    backgroundColor: "white",
    minWidth: "50px",
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1,
  },
}));

const StyledRowDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 10,
  width: "100%",
});

const StyledRow = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

const StyledCol = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  paddingLeft: 10,
  paddingRight: 10,
  position: "relative",
});

const StyledColEnd = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-end",
  width: 110,
});

const StyledSelect = styled(Select)({
  "& .MuiOutlinedInput-input": {
    padding: "5px 10px",
  },
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: "5px 10px",
  },
});

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: "#fff",
}));

const StyledDivColCenter = styled("div")({
  padding: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: 10,
});

const StyledDialogTitle = styled(DialogTitle)({
  fontWeight: "bold",
  fontSize: 20,
  backgroundColor: "#ecedf1",
  borderBottom: "2px solid black",
});

const StyledDivColSpace = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-evenly",
  width: "100%",
  paddingBottom: 10,
});

const StyledDivRowSpace = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  width: "100%",
  paddingTop: 10,
});

export interface ChoiceObj {
  breatherTubes?: string;
  cladColor?: string;
  customCladColor?: string;
  glass?: string;
  handle?: string;
  handleHeight?: string;
  doorType?: string;
  doorHeight?: number;
  kerf?: string;
  screenColor?: string;
  spacer?: string;
  wood?: string;
}

interface ChoicesObj {
  [key: string]: ChoiceObj;
}

interface CartCheckProps {
  open: boolean;
  handleClose: () => void;
  cartCheck: LineItem[];
  routeToOrderSummary: (check: boolean, checked: boolean) => void;
  quoteId: string;
  userId: string;
}

const CartCheckModal: FC<CartCheckProps> = ({
  open,
  handleClose,
  cartCheck,
  routeToOrderSummary,
  quoteId,
  userId,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const queryClient = useQueryClient();

  const [choices, setChoices] = useState<ChoicesObj>({});

  const [checked, setChecked] = useState(false);

  const [loading, setLoading] = useState(false);

  const [disable, setDisable] = useState(true);

  const [error, setError] = useState(false);

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) =>
    setChecked(e.target.checked);

  const keys = Object.keys(cartCheck[0]).sort();

  const headers = keys.filter(
    (key) =>
      key !== "configImg" &&
      key !== "exactConfigRight" &&
      key !== "configImgRight" &&
      key !== "exactConfig" &&
      key !== "material" &&
      key !== "doorType" &&
      key !== "dl" &&
      key !== "swingDirection" &&
      key !== "id" &&
      key !== "customCladColor" &&
      key !== "doorHeight",
  );

  const handleChange = (
    event:
      | SelectChangeEvent<string>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: string | undefined,
    material?: string,
  ) => {
    const { name, value } = event.target;
    setDisable(false);

    let obj = JSON.parse(JSON.stringify(choices));
    if (name === "wood") {
      if (material?.includes("Clad")) {
        const woodLabel = wood.find((w) => w.abbrev === value)?.label;
        obj[id as keyof ChoicesObj]["woodSpecies"] = woodLabel;
      } else {
        const woodLabel = woodForWood.find((w) => w.abbrev === value)?.label;
        obj[id as keyof ChoicesObj]["woodSpecies"] = woodLabel;
      }
    }
    obj[id as keyof ChoicesObj][name] = value;
    setChoices(obj);
  };

  const onSubmit = async () => {
    setLoading(true);

    const res = await cartCheckUpdate(quoteId, choices, userId);

    if (res.data === "Success") {
      if (checked) {
        await postIgnoreCartCheck(quoteId);
      }
      setError(false);
      queryClient.invalidateQueries("QuoteCart");
      routeToOrderSummary(true, checked);
      setLoading(false);
      return;
    }

    setError(true);
    setLoading(false);
  };

  useEffect(() => {
    const obj: ChoicesObj = {};

    cartCheck.forEach((item) => {
      obj[`${item.id}`] = {
        ...item,
      };
    });
    setChoices(obj);
  }, []);

  const body = (
    <>
      <StyledDialogTitle>
        <StyledRow
          style={{
            position: "relative",
            flexWrap: fullScreen ? "wrap" : "nowrap",
          }}
        >
          <IconButton
            style={{ position: "absolute", right: -10, top: -10 }}
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
          {!fullScreen && (
            <StyledRow style={{ flexBasis: "45%" }}>
              <Image
                cloudName="ag-millworks"
                publicId="millie_75"
                secure="true"
              />
              <StyledDivColCenter>
                <Typography variant="h6">Hi I'm Millie,</Typography>
                <Typography variant="h6">your AG Assistant...</Typography>
              </StyledDivColCenter>
            </StyledRow>
          )}
          <StyledCol>
            <Typography
              style={{ fontWeight: "bold" }}
              variant={headers.length > 2 && !fullScreen ? "h5" : "h6"}
            >
              Some options in your cart do not match,
            </Typography>
            <Typography
              style={{ fontWeight: "bold" }}
              variant={headers.length > 2 && !fullScreen ? "h5" : "h6"}
            >
              would you like to continue with the following choices?
            </Typography>
          </StyledCol>
        </StyledRow>
      </StyledDialogTitle>

      <TableContainer className="scroll">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StickyTableCell style={{ padding: 8 }} padding="none" />
              {headers.length
                ? headers.map((head) => (
                    <TableCell
                      key={head}
                      padding="none"
                      style={{ padding: 8 }}
                      align="center"
                    >
                      <Typography
                        color="primary"
                        style={{ fontWeight: "bold" }}
                      >
                        {getHeaderTitle(head)}
                      </Typography>
                    </TableCell>
                  ))
                : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {cartCheck.length
              ? cartCheck.map((item, index) => (
                  <TableRow key={index} style={{ padding: 10, paddingTop: 10 }}>
                    <StickyTableCell>
                      <StyledRow style={{ paddingLeft: 5 }}>
                        <StyledRow style={{ width: 250, height: 150 }}>
                          {item.doorType?.includes("Corner") ? (
                            <StyledRow>
                              <img
                                src={item.configImg}
                                alt="config left"
                                style={{
                                  width:
                                    getCornerNumPanels(
                                      item.exactConfig || "",
                                      item.exactConfigRight || "",
                                    ) >= 10
                                      ? getCornerImgWidth(
                                          60,
                                          item.exactConfig || "",
                                        )
                                      : getCornerImgWidth(
                                          80,
                                          item.exactConfig || "",
                                        ),
                                  height:
                                    getCornerNumPanels(
                                      item.exactConfig || "",
                                      item.exactConfigRight || "",
                                    ) >= 10
                                      ? 60
                                      : 80,
                                  paddingRight: 10,
                                }}
                              />
                              <img
                                src={item.configImgRight}
                                alt="config right"
                                style={{
                                  width:
                                    getCornerNumPanels(
                                      item.exactConfig || "",
                                      item.exactConfigRight || "",
                                    ) >= 10
                                      ? getCornerImgWidth(
                                          60,
                                          item.exactConfigRight || "",
                                        )
                                      : getCornerImgWidth(
                                          80,
                                          item.exactConfigRight || "",
                                        ),
                                  height:
                                    getCornerNumPanels(
                                      item.exactConfig || "",
                                      item.exactConfigRight || "",
                                    ) >= 10
                                      ? 60
                                      : 80,
                                }}
                              />
                            </StyledRow>
                          ) : item.doorType === "Fixed Panel" ? (
                            <img
                              src={item.configImg}
                              alt="config"
                              style={{
                                width: 50,
                                height: 120,
                              }}
                            />
                          ) : (
                            <img
                              src={item.configImg}
                              alt="config"
                              style={{
                                width: 200,
                                height:
                                  item.doorType === "Bi-fold Window" ? 80 : 120,
                              }}
                            />
                          )}
                        </StyledRow>

                        <StyledColEnd>
                          <StyledCol>
                            {item.doorType?.includes("Bi-fold") ? (
                              <Typography>Bi-fold</Typography>
                            ) : (
                              <Typography>{item.doorType}</Typography>
                            )}

                            {item.doorType?.includes("Window") ? (
                              <Typography>Window</Typography>
                            ) : item.doorType?.includes("Corner") ? (
                              <Typography>Corner</Typography>
                            ) : null}

                            <Typography style={{ fontWeight: "bold" }}>
                              Unit {letters[index]}
                            </Typography>
                          </StyledCol>
                        </StyledColEnd>
                      </StyledRow>
                    </StickyTableCell>

                    {item.breatherTubes &&
                      choices[item.id as keyof typeof choices]
                        ?.breatherTubes && (
                        <TableCell>
                          <StyledCol>
                            <StyledSelect
                              value={
                                choices[item.id as keyof typeof choices]
                                  .breatherTubes
                              }
                              name="breatherTubes"
                              onChange={(event: SelectChangeEvent<unknown>) =>
                                handleChange(
                                  event as SelectChangeEvent<string>,
                                  item.id,
                                )
                              }
                              variant="outlined"
                              style={{ height: 30, width: 100 }}
                              defaultValue={item.breatherTubes}
                            >
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="None">No</MenuItem>
                            </StyledSelect>
                          </StyledCol>
                        </TableCell>
                      )}

                    {item.cladColor &&
                      choices[item.id as keyof typeof choices]?.cladColor && (
                        <TableCell>
                          <StyledCol>
                            {item.material?.includes("Clad") &&
                            choices &&
                            !choices[
                              item.id as keyof typeof choices
                            ]?.cladColor?.includes("Custom") ? (
                              <StyledRowDiv>
                                <StyledCol>
                                  <StyledSelect
                                    value={
                                      choices[item.id as keyof typeof choices]
                                        .cladColor
                                    }
                                    name="cladColor"
                                    onChange={(
                                      event: SelectChangeEvent<unknown>,
                                    ) =>
                                      handleChange(
                                        event as SelectChangeEvent<string>,
                                        item.id,
                                      )
                                    }
                                    variant="outlined"
                                    style={{ height: 30, width: 150 }}
                                    defaultValue={item.handle}
                                  >
                                    {cladColor.map((clad) => (
                                      <MenuItem
                                        key={clad.label}
                                        value={clad.label}
                                      >
                                        {clad.label}
                                      </MenuItem>
                                    ))}
                                  </StyledSelect>
                                </StyledCol>

                                {item.cladColor !== "None" &&
                                  choices[item.id as keyof typeof choices]
                                    ?.cladColor && (
                                    <Image
                                      cloudName="ag-millworks"
                                      secure="true"
                                      publicId={findCladColorImg(
                                        choices[item.id as keyof typeof choices]
                                          ?.cladColor || "",
                                      )}
                                    >
                                      <Transformation
                                        width="70"
                                        height="70"
                                        radius="10"
                                        crop="scale"
                                      />
                                    </Image>
                                  )}
                              </StyledRowDiv>
                            ) : item.material?.includes("Clad") &&
                              choices[
                                item.id as keyof typeof choices
                              ]?.cladColor?.includes("Custom") ? (
                              <StyledCol>
                                <StyledCol>
                                  <StyledSelect
                                    value={
                                      choices[item.id as keyof typeof choices]
                                        .cladColor
                                    }
                                    name="cladColor"
                                    onChange={(
                                      event: SelectChangeEvent<unknown>,
                                    ) =>
                                      handleChange(
                                        event as SelectChangeEvent<string>,
                                        item.id,
                                      )
                                    }
                                    variant="outlined"
                                    style={{ height: 30, width: 150 }}
                                    defaultValue={item.handle}
                                  >
                                    {cladColor.map((clad) => (
                                      <MenuItem
                                        key={clad.label}
                                        value={clad.label}
                                      >
                                        {clad.label.includes("Custom")
                                          ? "Custom Color"
                                          : clad.label}
                                      </MenuItem>
                                    ))}
                                  </StyledSelect>
                                </StyledCol>

                                <StyledCol>
                                  <StyledTextField
                                    error={
                                      !choices[item.id as keyof typeof choices]
                                        .customCladColor
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      !choices[item.id as keyof typeof choices]
                                        .customCladColor
                                        ? "Enter Custom Color"
                                        : ""
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    onChange={(event) =>
                                      handleChange(event, item.id)
                                    }
                                    value={
                                      choices[item.id as keyof typeof choices]
                                        .customCladColor
                                    }
                                    name="customCladColor"
                                    style={{ width: 150 }}
                                  />
                                </StyledCol>
                              </StyledCol>
                            ) : (
                              <Typography style={{ fontWeight: "bold" }}>
                                Wood Selected
                              </Typography>
                            )}
                          </StyledCol>
                        </TableCell>
                      )}

                    {item.glass &&
                      choices[item.id as keyof typeof choices]?.glass && (
                        <TableCell>
                          <StyledCol>
                            <StyledSelect
                              value={
                                choices[item.id as keyof typeof choices].glass
                              }
                              name="glass"
                              onChange={(event: SelectChangeEvent<unknown>) =>
                                handleChange(
                                  event as SelectChangeEvent<string>,
                                  item.id,
                                )
                              }
                              variant="outlined"
                              style={{ height: 30, width: 100 }}
                              defaultValue={item.glass}
                            >
                              <MenuItem value="LoE 366">LoE 366</MenuItem>
                              <MenuItem value="LoE 272">LoE 272</MenuItem>
                            </StyledSelect>
                          </StyledCol>
                        </TableCell>
                      )}

                    {item.handle &&
                      choices[item.id as keyof typeof choices]?.handle && (
                        <TableCell>
                          <StyledCol>
                            <StyledRowDiv>
                              <StyledCol>
                                {item.handle !== "None" &&
                                item.handle !== "N/A" &&
                                item.doorType !== "Pivot" ? (
                                  <StyledSelect
                                    value={
                                      choices[item.id as keyof typeof choices]
                                        .handle
                                    }
                                    name="handle"
                                    onChange={(
                                      event: SelectChangeEvent<unknown>,
                                    ) =>
                                      handleChange(
                                        event as SelectChangeEvent<string>,
                                        item.id,
                                      )
                                    }
                                    variant="outlined"
                                    style={{ height: 30, width: 150 }}
                                    defaultValue={item.handle}
                                  >
                                    {getHandleArray(
                                      item.doorType || "",
                                      item.exactConfig || "",
                                    ).map((handle) => (
                                      <MenuItem
                                        key={handle.label}
                                        value={handle.label}
                                      >
                                        {handle.label.includes("Truth")
                                          ? "Truth"
                                          : handle.label}
                                      </MenuItem>
                                    ))}
                                  </StyledSelect>
                                ) : item.handle === "None" ? (
                                  <Typography style={{ fontWeight: "bold" }}>
                                    No Handle
                                  </Typography>
                                ) : item.handle === "N/A" ? (
                                  <Typography style={{ fontWeight: "bold" }}>
                                    N/A
                                  </Typography>
                                ) : (
                                  <Typography style={{ fontWeight: "bold" }}>
                                    {item.handle}
                                  </Typography>
                                )}
                              </StyledCol>

                              {item.handle !== "None" &&
                                item.handle !== "N/A" &&
                                item.doorType !== "Pivot" &&
                                choices[item.id as keyof typeof choices]
                                  ?.handle && (
                                  <Image
                                    cloudName="ag-millworks"
                                    secure="true"
                                    publicId={findHandleImg(
                                      item.doorType || "",
                                      choices[item.id as keyof typeof choices]
                                        ?.handle || "",
                                    )}
                                  >
                                    <Transformation height="80" crop="scale" />
                                  </Image>
                                )}
                            </StyledRowDiv>
                          </StyledCol>
                        </TableCell>
                      )}

                    {item.handleHeight &&
                      choices[item.id as keyof typeof choices]
                        ?.handleHeight && (
                        <TableCell>
                          <StyledCol>
                            {item.handleHeight !== "None" &&
                            item.handleHeight !== "N/A" ? (
                              <>
                                {(choices[item.id as keyof typeof choices]
                                  .doorType === "Pivot" &&
                                  Number(
                                    choices[item.id as keyof typeof choices]
                                      .doorHeight,
                                  ) >= 96) ||
                                (choices[item.id as keyof typeof choices]
                                  .doorType !== "Pivot" &&
                                  Number(
                                    choices[item.id as keyof typeof choices]
                                      .doorHeight,
                                  ) >= 108) ? (
                                  <StyledSelect
                                    value={
                                      choices[item.id as keyof typeof choices]
                                        .handleHeight
                                    }
                                    name="handleHeight"
                                    onChange={(
                                      event: SelectChangeEvent<unknown>,
                                    ) =>
                                      handleChange(
                                        event as SelectChangeEvent<string>,
                                        item.id,
                                      )
                                    }
                                    variant="outlined"
                                    style={{ height: 30, width: 70 }}
                                    defaultValue={item.handleHeight || '36"'}
                                  >
                                    <MenuItem value={'36"'}>36"</MenuItem>
                                    <MenuItem value={'42"'}>42"</MenuItem>
                                  </StyledSelect>
                                ) : (
                                  <StyledSelect
                                    value={
                                      choices[item.id as keyof typeof choices]
                                        .handleHeight
                                    }
                                    name="handleHeight"
                                    onChange={(
                                      event: SelectChangeEvent<unknown>,
                                    ) =>
                                      handleChange(
                                        event as SelectChangeEvent<string>,
                                        item.id,
                                      )
                                    }
                                    variant="outlined"
                                    style={{ height: 30, width: 70 }}
                                    defaultValue={item.handleHeight || '36"'}
                                  >
                                    <MenuItem value={'36"'}>36"</MenuItem>
                                  </StyledSelect>
                                )}
                              </>
                            ) : (
                              <Typography style={{ fontWeight: "bold" }}>
                                N/A
                              </Typography>
                            )}
                          </StyledCol>
                        </TableCell>
                      )}

                    {item.kerf &&
                      choices[item.id as keyof typeof choices]?.kerf && (
                        <TableCell>
                          <StyledCol>
                            <StyledSelect
                              value={
                                choices[item.id as keyof typeof choices].kerf
                              }
                              name="kerf"
                              onChange={(event: SelectChangeEvent<unknown>) =>
                                handleChange(
                                  event as SelectChangeEvent<string>,
                                  item.id,
                                )
                              }
                              variant="outlined"
                              style={{ height: 30, width: 150 }}
                              defaultValue={item.kerf}
                            >
                              <MenuItem value="None">None</MenuItem>
                              <MenuItem value="3/8">3/8</MenuItem>
                              <KerfCustomOptions
                                door={
                                  item.doorType === "Swing"
                                    ? (item.swingDirection as string)
                                    : (item.doorType as string)
                                }
                                material={item.material}
                              />
                            </StyledSelect>
                          </StyledCol>
                        </TableCell>
                      )}

                    {item.screenColor &&
                      choices[item.id as keyof typeof choices]?.screenColor && (
                        <TableCell>
                          <StyledCol>
                            {item.screenColor !== "None" ? (
                              <StyledSelect
                                value={
                                  choices[item.id as keyof typeof choices]
                                    .screenColor
                                }
                                name="screenColor"
                                onChange={(event: SelectChangeEvent<unknown>) =>
                                  handleChange(
                                    event as SelectChangeEvent<string>,
                                    item.id,
                                  )
                                }
                                variant="outlined"
                                style={{ height: 30, width: 150 }}
                                defaultValue={item.screenColor}
                              >
                                <MenuItem value="Black Extrusion">
                                  Black Extrusion
                                </MenuItem>
                                <MenuItem value="White Extrusion">
                                  White Extrusion
                                </MenuItem>
                              </StyledSelect>
                            ) : (
                              <Typography style={{ fontWeight: "bold" }}>
                                No Screen
                              </Typography>
                            )}
                          </StyledCol>
                        </TableCell>
                      )}

                    {item.spacer &&
                      choices[item.id as keyof typeof choices]?.spacer && (
                        <TableCell>
                          <StyledCol>
                            {item.dl === "Add TDL" || !item.dl ? (
                              <StyledSelect
                                value={
                                  choices[item.id as keyof typeof choices]
                                    .spacer
                                }
                                name="spacer"
                                onChange={(event: SelectChangeEvent<unknown>) =>
                                  handleChange(
                                    event as SelectChangeEvent<string>,
                                    item.id || "",
                                  )
                                }
                                variant="outlined"
                                style={{ height: 30, width: 100 }}
                                defaultValue={item.spacer}
                              >
                                <MenuItem value="Black">Black</MenuItem>
                                <MenuItem value="Mill">Mill</MenuItem>
                              </StyledSelect>
                            ) : (
                              <Typography style={{ fontWeight: "bold" }}>
                                {item.spacer}
                              </Typography>
                            )}
                          </StyledCol>
                        </TableCell>
                      )}

                    {item.wood &&
                      choices[item.id as keyof typeof choices]?.wood && (
                        <TableCell>
                          <StyledCol>
                            <StyledRowDiv>
                              <StyledCol>
                                {item.material?.includes("Clad") ? (
                                  <StyledSelect
                                    value={
                                      choices[item.id as keyof typeof choices]
                                        .wood
                                    }
                                    name="wood"
                                    onChange={(
                                      event: SelectChangeEvent<unknown>,
                                    ) =>
                                      handleChange(
                                        event as SelectChangeEvent<string>,
                                        item.id,
                                        item.material,
                                      )
                                    }
                                    variant="outlined"
                                    style={{
                                      height: 30,
                                      marginRight: 5,
                                      width: 170,
                                    }}
                                    defaultValue={item.wood}
                                  >
                                    {wood.map((w) => (
                                      <MenuItem key={w.id} value={w.abbrev}>
                                        {w.abbrev}
                                      </MenuItem>
                                    ))}
                                  </StyledSelect>
                                ) : (
                                  <StyledSelect
                                    value={
                                      choices[item.id as keyof typeof choices]
                                        .wood
                                    }
                                    name="wood"
                                    onChange={(
                                      event: SelectChangeEvent<unknown>,
                                    ) =>
                                      handleChange(
                                        event as SelectChangeEvent<string>,
                                        item.id,
                                        item.material,
                                      )
                                    }
                                    variant="outlined"
                                    style={{
                                      height: 30,
                                      marginRight: 5,
                                      width: 170,
                                    }}
                                    defaultValue={item.wood}
                                  >
                                    {woodForWood.map((w) => (
                                      <MenuItem key={w.id} value={w.abbrev}>
                                        {w.abbrev}
                                      </MenuItem>
                                    ))}
                                  </StyledSelect>
                                )}
                              </StyledCol>

                              {choices[item.id as keyof typeof choices]
                                ?.wood && (
                                <Image
                                  cloudName="ag-millworks"
                                  secure="true"
                                  publicId={`WoodSpecies/${findWoodImg(
                                    choices[item.id as keyof typeof choices]
                                      .wood || "",
                                  )}`}
                                >
                                  <Transformation
                                    width="50"
                                    height="50"
                                    crop="scale"
                                    radius="5"
                                  />
                                </Image>
                              )}
                            </StyledRowDiv>
                          </StyledCol>
                        </TableCell>
                      )}
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>

      <DialogActions>
        <StyledDivColSpace>
          <Divider style={{ width: "100%" }} />
          <StyledDivRowSpace
            style={{
              flexDirection: fullScreen ? "column" : "row",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheck}
                  color="primary"
                />
              }
              label="Ignore Check for All Units"
              style={{ paddingBottom: fullScreen ? 10 : 0 }}
            />
            <Button
              variant="contained"
              onClick={() => routeToOrderSummary(true, checked)}
              style={{ marginBottom: fullScreen ? 10 : 0 }}
            >
              Continue Without Changing
            </Button>

            <Button
              color="primary"
              variant="contained"
              onClick={onSubmit}
              disabled={disable}
            >
              Save Changes and Continue
            </Button>

            {loading && (
              <StyledBackdrop open={loading}>
                <CircularProgress color="inherit" />
              </StyledBackdrop>
            )}
          </StyledDivRowSpace>

          {error && (
            <Typography style={{ color: "red", paddingTop: 10 }}>
              A Problem Occurred. Please Contact Customer Service at (805)
              644-4494 for Technical Assistance.
            </Typography>
          )}
        </StyledDivColSpace>
      </DialogActions>
    </>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={headers.length > 2 ? "xl" : "lg"}
      fullScreen={fullScreen}
      scroll="paper"
    >
      {body}
    </Dialog>
  );
};

export default CartCheckModal;
