export const initialWindowOptions = [
  {
    min: 38.375,
    max: 56.5,
    options: ["2L OR 2R"],
  },
  {
    min: 56.5625,
    max: 74.6875,
    options: ["2L OR 2R", "3L OR 3R", "2L1R OR 1L2R"],
  },
  {
    min: 74.75,
    max: 92.75,
    options: [
      "2L OR 2R",
      "3L OR 3R",
      "2L1R OR 1L2R",
      "2L2R",
      "3L1R OR 1L3R",
      "4L OR 4R",
    ],
  },
  {
    min: 92.8125,
    max: 98.375,
    options: [
      "2L OR 2R",
      "3L OR 3R",
      "2L1R OR 1L2R",
      "2L2R",
      "4L OR 4R",
      "3L1R OR 1L3R",
      "5L OR 5R",
      "4L1R OR 1L4R",
      "3L2R OR 2L3R",
    ],
  },
  {
    min: 98.4375,
    max: 110.9375,
    options: [
      "3L OR 3R",
      "2L1R OR 1L2R",
      "2L2R",
      "4L OR 4R",
      "3L1R OR 1L3R",
      "5L OR 5R",
      "4L1R OR 1L4R",
      "3L2R OR 2L3R",
    ],
  },
  {
    min: 111,
    max: 146.5,
    options: [
      "3L OR 3R",
      "2L1R OR 1L2R",
      "2L2R",
      "4L OR 4R",
      "3L1R OR 1L3R",
      "5L OR 5R",
      "4L1R OR 1L4R",
      "3L2R OR 2L3R",
      "3L3R",
      "5L1R OR 1L5R",
      "4L2R OR 2L4R",
      "6L OR 6R",
    ],
  },
  {
    min: 146.5625,
    max: 194.6875,
    options: [
      "2L2R",
      "4L OR 4R",
      "3L1R OR 1L3R",
      "5L OR 5R",
      "4L1R OR 1L4R",
      "3L2R OR 2L3R",
      "3L3R",
      "5L1R OR 1L5R",
      "4L2R OR 2L4R",
      "6L OR 6R",
    ],
  },
  {
    min: 194.75,
    max: 242.8125,
    options: [
      "5L OR 5R",
      "4L1R OR 1L4R",
      "3L2R OR 2L3R",
      "3L3R",
      "5L1R OR 1L5R",
      "4L2R OR 2L4R",
      "6L OR 6R",
    ],
  },
  {
    min: 242.875,
    max: 255,
    options: ["3L3R", "5L1R OR 1L5R", "4L2R OR 2L4R", "6L OR 6R"],
  },
];

export const initialConfigOptions = [
  {
    min: 38.375,
    max: 56.5,
    options: ["2L OR 2R"],
  },
  {
    min: 56.5625,
    max: 74.6875,
    options: ["2L OR 2R", "3L OR 3R", "2L1R OR 1L2R"],
  },
  {
    min: 74.75,
    max: 92.75,
    options: [
      "2L OR 2R",
      "3L OR 3R",
      "2L1R OR 1L2R",
      "2L2R",
      "3L1R OR 1L3R",
      "4L OR 4R",
    ],
  },
  {
    min: 92.8125,
    max: 98.375,
    options: [
      "2L OR 2R",
      "3L OR 3R",
      "2L1R OR 1L2R",
      "2L2R",
      "3L1R OR 1L3R",
      "4L OR 4R",
      "5L OR 5R",
      "4L1R OR 1L4R",
      "3L2R OR 2L3R",
    ],
  },
  {
    min: 98.4375,
    max: 110.9375,
    options: [
      "3L OR 3R",
      "2L1R OR 1L2R",
      "2L2R",
      "3L1R OR 1L3R",
      "4L OR 4R",
      "5L OR 5R",
      "4L1R OR 1L4R",
      "3L2R OR 2L3R",
    ],
  },
  {
    min: 111,
    max: 146.5,
    options: [
      "3L OR 3R",
      "2L1R OR 1L2R",
      "2L2R",
      "3L1R OR 1L3R",
      "4L OR 4R",
      "5L OR 5R",
      "4L1R OR 1L4R",
      "3L2R OR 2L3R",
      "3L3R",
      "5L1R OR 1L5R",
      "4L2R OR 2L4R",
      "6L OR 6R",
    ],
  },
  {
    min: 146.5625,
    max: 194.6875,
    options: [
      "2L2R",
      "3L1R OR 1L3R",
      "4L OR 4R",
      "5L OR 5R",
      "4L1R OR 1L4R",
      "3L2R OR 2L3R",
      "3L3R",
      "5L1R OR 1L5R",
      "4L2R OR 2L4R",
      "6L OR 6R",
    ],
  },
  {
    min: 194.75,
    max: 218.5,
    options: [
      "5L OR 5R",
      "4L1R OR 1L4R",
      "3L2R OR 2L3R",
      "3L3R",
      "5L1R OR 1L5R",
      "4L2R OR 2L4R",
      "6L OR 6R",
    ],
  },
  {
    min: 218.5625,
    max: 242.5,
    options: [
      "5L OR 5R",
      "4L1R OR 1L4R",
      "3L2R OR 2L3R",
      "3L3R",
      "5L1R OR 1L5R",
      "4L2R OR 2L4R",
      "6L OR 6R",
      "4L3R OR 3L4R",
      "5L2R OR 2L5R",
      "7L OR 7R",
      "6L1R OR 1L6R",
    ],
  },
  {
    min: 242.5625,
    max: 242.8125,
    options: [
      "5L OR 5R",
      "4L1R OR 1L4R",
      "3L2R OR 2L3R",
      "3L3R",
      "5L1R OR 1L5R",
      "4L2R OR 2L4R",
      "6L OR 6R",
      "5L2R OR 2L5R",
      "4L3R OR 3L4R",
      "7L OR 7R",
      "6L1R OR 1L6R",
      "8L OR 8R",
      "7L1R OR 1L7R",
      "6L2R OR 2L6R",
      "5L3R OR 3L5R",
      "4L4R",
    ],
  },
  {
    min: 242.875,
    max: 290.125,
    options: [
      "3L3R",
      "5L1R OR 1L5R",
      "4L2R OR 2L4R",
      "6L OR 6R",
      "5L2R OR 2L5R",
      "4L3R OR 3L4R",
      "7L OR 7R",
      "6L1R OR 1L6R",
      "8L OR 8R",
      "7L1R OR 1L7R",
      "6L2R OR 2L6R",
      "5L3R OR 3L5R",
      "4L4R",
    ],
  },
  //can't do 7 panel because tracks are 24 ft long
  {
    min: 290.1875,
    max: 291,
    options: [
      "3L3R",
      "5L1R OR 1L5R",
      "4L2R OR 2L4R",
      "6L OR 6R",
      "5L2R OR 2L5R",
      "4L3R OR 3L4R",
      "8L OR 8R",
      "7L1R OR 1L7R",
      "6L2R OR 2L6R",
      "5L3R OR 3L5R",
      "4L4R",
    ],
  },
  {
    min: 291.0625,
    max: 339.25,
    options: [
      "5L2R OR 2L5R",
      "4L3R OR 3L4R",
      "7L1R OR 1L7R",
      "6L2R OR 2L6R",
      "5L3R OR 3L5R",
      "4L4R",
    ],
  },
];

export const initialCornerOptions = [
  {
    min: 30,
    max: 50.25,
    left: ["1L", "2L"],
    right: ["1R", "2R"],
  },
  {
    min: 50.3125,
    max: 56.5,
    left: ["2L"],
    right: ["2R"],
  },
  {
    min: 56.5625,
    max: 74.6875,
    left: ["2L", "3L"],
    right: ["2R", "3R"],
  },
  {
    min: 74.75,
    max: 92.75,
    left: ["2L", "3L", "4L"],
    right: ["2R", "3R", "4R"],
  },
  {
    min: 92.8125,
    max: 98.375,
    left: ["2L", "3L", "4L", "5L"],
    right: ["2R", "3R", "4R", "5R"],
  },
  {
    min: 98.4375,
    max: 110.9375,
    left: ["3L", "4L", "5L"],
    right: ["3R", "4R", "5R"],
  },
  {
    min: 111,
    max: 146.5,
    left: ["3L", "4L", "5L", "6L"],
    right: ["3R", "4R", "5R", "6R"],
  },
  {
    min: 146.5625,
    max: 194.6875,
    left: ["4L", "5L", "6L"],
    right: ["4R", "5R", "6R"],
  },
  {
    min: 194.75,
    max: 218.5,
    left: ["5L", "6L"],
    right: ["5R", "6R"],
  },
  {
    min: 218.5625,
    max: 242.5,
    left: ["5L", "6L", "7L"],
    right: ["5R", "6R", "7R"],
  },
  {
    min: 242.5625,
    max: 242.8125,
    left: ["5L", "6L", "7L", "8L"],
    right: ["5R", "6R", "7R", "8R"],
  },
  {
    min: 242.875,
    max: 290.125,
    left: ["6L", "7L", "8L"],
    right: ["6R", "7R", "8R"],
  },
  {
    min: 290.1875,
    max: 291,
    left: ["6L", "8L"],
    right: ["6R", "8R"],
  },
];

export const sliderPocketOptions = [
  {
    minWidth: 24,
    maxWidth: 48.5,
    options: ["PX OR XP"],
  },
  {
    minWidth: 48.5625,
    maxWidth: 72.5,
    options: ["PX OR XP", "PXX OR XXP", "PX-XP"],
  },
  {
    minWidth: 72.5625,
    maxWidth: 96.375,
    options: ["PX OR XP", "PXX OR XXP", "PXXX OR XXXP", "PX-XP"],
  },
  {
    minWidth: 96.4375,
    maxWidth: 96.5,
    options: ["PXX OR XXP", "PXXX OR XXXP", "PX-XP"],
  },
  {
    minWidth: 96.5625,
    maxWidth: 144.5,
    options: [
      "PXX OR XXP",
      "PXXX OR XXXP",
      "PXXXX OR XXXXP",
      "PX-XP",
      "PXX-XXP",
    ],
  },
  {
    minWidth: 144.5625,
    maxWidth: 188.9375,
    options: [
      "PXX OR XXP",
      "PXXX OR XXXP",
      "PXXXX OR XXXXP",
      "PX-XP",
      "PXX-XXP",
      "PXXX-XXXP",
    ],
  },
  {
    minWidth: 189,
    maxWidth: 190.8125,
    options: [
      "PXXX OR XXXP",
      "PXXXX OR XXXXP",
      "PX-XP",
      "PXX-XXP",
      "PXXX-XXXP",
    ],
  },
  {
    minWidth: 190.875,
    maxWidth: 192.5,
    options: ["PXXX OR XXXP", "PXXXX OR XXXXP", "PXX-XXP", "PXXX-XXXP"],
  },
  {
    minWidth: 192.5625,
    maxWidth: 212.5,
    options: [
      "PXXX OR XXXP",
      "PXXXX-XXXXP",
      "PXX-XXP",
      "PXXX-XXXP",
      "PXXXX OR XXXXP",
    ],
  },
  {
    minWidth: 212.5625,
    maxWidth: 288,
    options: ["PXXXX OR XXXXP", "PXX-XXP", "PXXX-XXXP", "PXXXX-XXXXP"],
  },
  {
    minWidth: 288.0625,
    maxWidth: 375.9375,
    options: ["PXX-XXP", "PXXX-XXXP", "PXXXX-XXXXP"],
  },
  {
    minWidth: 376,
    maxWidth: 425,
    options: ["PXXX-XXXP", "PXXXX-XXXXP"],
  },
  {
    minWidth: 425.0625,
    maxWidth: 452,
    options: ["PXXXX-XXXXP"],
  },
];

export const sliderNonPocketOptions = [
  {
    minWidth: 48,
    maxWidth: 72.5,
    options: ["OX OR XO", "XX"],
  },
  {
    minWidth: 72.5625,
    maxWidth: 96.5,
    options: ["OX OR XO", "OXX OR XXO", "XX", "XXX"],
  },
  {
    minWidth: 96.5625,
    maxWidth: 120,
    options: [
      "OX OR XO",
      "OXX OR XXO",
      "OXXX OR XXXO",
      "OX-XO",
      "XX",
      "XXX",
      "XXXX",
    ],
  },
  {
    minWidth: 120.0625,
    maxWidth: 144.5,
    options: [
      "OX OR XO",
      "OXX OR XXO",
      "OXXX OR XXXO",
      "OXXXX OR XXXXO",
      "OX-XO",
      "XX",
      "XXX",
      "XXXX",
    ],
  },
  {
    minWidth: 144.5625,
    maxWidth: 190.6875,
    options: [
      "OX OR XO",
      "OXX OR XXO",
      "OXXX OR XXXO",
      "OXXXX OR XXXXO",
      "OX-XO",
      "OXX-XXO",
      "XX",
      "XXX",
      "XXXX",
    ],
  },
  {
    minWidth: 190.75,
    maxWidth: 191,
    options: [
      "OX OR XO",
      "OXX OR XXO",
      "OXXX OR XXXO",
      "OXXXX OR XXXXO",
      "OX-XO",
      "OXX-XXO",
      "XXX",
      "XXXX",
    ],
  },
  {
    minWidth: 191.0625,
    maxWidth: 192.5,
    options: [
      "OXX OR XXO",
      "OXXX OR XXXO",
      "OXXXX OR XXXXO",
      "OX-XO",
      "OXX-XXO",
      "XXX",
      "XXXX",
    ],
  },
  {
    minWidth: 192.5625,
    maxWidth: 240,
    options: [
      "OXX OR XXO",
      "OXXX OR XXXO",
      "OXXXX OR XXXXO",
      "OX-XO",
      "OXX-XXO",
      "OXXX-XXXO",
      "XXX",
      "XXXX",
    ],
  },
  {
    minWidth: 240.0625,
    maxWidth: 283,
    options: [
      "OXX OR XXO",
      "OXXX OR XXXO",
      "OXXXX OR XXXXO",
      "OX-XO",
      "OXX-XXO",
      "OXXX-XXXO",
      "OXXXX-XXXXO",
      "XXX",
      "XXXX",
    ],
  },
  {
    minWidth: 283.0625,
    maxWidth: 283.25,
    options: [
      "OXXX OR XXXO",
      "OXXXX OR XXXXO",
      "OX-XO",
      "OXX-XXO",
      "OXXX-XXXO",
      "OXXXX-XXXXO",
      "XXX",
      "XXXX",
    ],
  },
  {
    minWidth: 283.3125,
    maxWidth: 288,
    options: [
      "OXXX OR XXXO",
      "OXXXX OR XXXXO",
      "OX-XO",
      "OXX-XXO",
      "OXXX-XXXO",
      "OXXXX-XXXXO",
      "XXXX",
    ],
  },
  {
    minWidth: 288.0625,
    maxWidth: 380.0625,
    options: ["OX-XO", "OXX-XXO", "OXXX-XXXO", "OXXXX-XXXXO"],
  },
  {
    minWidth: 380.125,
    maxWidth: 565.1875,
    options: ["OXX-XXO", "OXXX-XXXO", "OXXXX-XXXXO"],
  },
  {
    minWidth: 565.25,
    maxWidth: 576,
    options: ["OXXX-XXXO", "OXXXX-XXXXO"],
  },
];

export interface InitialExpandItm {
  name: string;
  expanded: boolean;
  require?: boolean;
}

export const initialExpand = [
  { name: "material", expanded: false },
  { name: "wood", expanded: false },
  { name: "unitSize", expanded: false },
  { name: "height", expanded: false },
  { name: "configuration", expanded: false },
  { name: "quantity", expanded: false, require: false },
  { name: "exteriorClad", expanded: false, require: false }, //6
  { name: "swingDirection", expanded: false },
  { name: "stilesRails", expanded: false, require: false },
  { name: "glass", expanded: false, require: false },
  { name: "paintPrime", expanded: false, require: false },
  { name: "stain", expanded: false, require: false },
  { name: "headTrack", expanded: false, require: false },
  { name: "sill", expanded: false, require: false },
  { name: "strip", expanded: false, require: false },
  { name: "spacer", expanded: false, require: false },
  { name: "handle", expanded: false, require: false },
  { name: "handleHeight", expanded: false, require: false },
  { name: "shootBolt", expanded: false, require: false },
  { name: "stickStops", expanded: false, require: false },
  { name: "hinge", expanded: false, require: false },
  { name: "ebm", expanded: false },
  { name: "fin", expanded: false, require: false },
  { name: "lite", expanded: false, require: false },
  { name: "dl", expanded: false, require: false },
  { name: "screen", expanded: false, require: false },
  { name: "screenPocket", expanded: false },
  { name: "jamb", expanded: false, require: false }, //27
  { name: "sliderConfig", expanded: false, require: false },
  { name: "sliderWidth", expanded: false, require: false },
  { name: "automation", expanded: false, require: false },
  { name: "screenType", expanded: false, require: false },
  { name: "slidingScreen", expanded: false, require: false },
  { name: "kerf", expanded: false, require: false },
  { name: "corner", expanded: false, require: false },
  { name: "pivot", expanded: false, require: false },
  { name: "pivotHardware", expanded: false, require: false },
  { name: "pivotMagnet", expanded: false, require: false },
  { name: "dropseal", expanded: false, require: false },
];
