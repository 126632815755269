import { findPanelExtra } from "./calculatePanelWidth";

export function calculateCornerPanelWidth(
  numberOfPanels: number,
  material: string,
  width: number,
  isPrimary?: boolean,
) {
  const extra = findPanelExtra(numberOfPanels);

  const sub = material === "All Wood" ? 0.0625 : 0;
  const cornerJamb = 0.9375;
  const panelAdder = isPrimary ? 1 : 0;

  const pw =
    (width -
      (cornerJamb - sub) -
      ((numberOfPanels + panelAdder) * 0.125 + extra)) /
    numberOfPanels;

  return pw - 0.015;
}
