const CLOUDINARY_URL =
  "https://res.cloudinary.com/ag-millworks/image/upload/Pivot_Configs";

export const taggedPivotConfigs = [
  {
    swingDirection: "Inswing",
    SwingType: "Single Swing",
    Sidelites: "Add Single Sidelite",
    label: "Pivot 1 Sidelite",
    img1: `${CLOUDINARY_URL}/ISLH_1SL.jpg`,
    img2: `${CLOUDINARY_URL}/ISRH_1SL.jpg`,
    img3: `${CLOUDINARY_URL}/ISLH_1SL_LOCK.jpg`,
    img4: `${CLOUDINARY_URL}/ISRH_1SL_LOCK.jpg`,
    img1Lever: `${CLOUDINARY_URL}/ISLH_1SL_LEVER.jpg`,
    img2Lever: `${CLOUDINARY_URL}/ISRH_1SL_LEVER.jpg`,
    img3Lever: `${CLOUDINARY_URL}/ISLH_1SL_LOCK_LEVER.jpg`,
    img4Lever: `${CLOUDINARY_URL}/ISRH_1SL_LOCK_LEVER.jpg`,
    img1M: `${CLOUDINARY_URL}/measurements/ISLH_1SL.jpg`,
    img2M: `${CLOUDINARY_URL}/measurements/ISRH_1SL.jpg`,
    img3M: `${CLOUDINARY_URL}/measurements/ISLH_1SL_LOCK.jpg`,
    img4M: `${CLOUDINARY_URL}/measurements/ISRH_1SL_LOCK.jpg`,
  },
  {
    swingDirection: "Inswing",
    SwingType: "Single Swing",
    Sidelites: "Add Double Sidelite",
    label: "Pivot 2 Sidelite",
    img1: `${CLOUDINARY_URL}/ISLH_2SL.jpg`,
    img2: `${CLOUDINARY_URL}/ISRH_2SL.jpg`,
    img1Lever: `${CLOUDINARY_URL}/ISLH_2SL_LEVER.jpg`,
    img2Lever: `${CLOUDINARY_URL}/ISRH_2SL_LEVER.jpg`,
    img1M: `${CLOUDINARY_URL}/measurements/ISLH_2SL.jpg`,
    img2M: `${CLOUDINARY_URL}/measurements/ISRH_2SL.jpg`,
  },
  {
    swingDirection: "Inswing",
    SwingType: "Single Swing",
    Sidelites: "none",
    label: "Pivot",
    img1: `${CLOUDINARY_URL}/ISLH.jpg`,
    img2: `${CLOUDINARY_URL}/ISRH.jpg`,
    img1Lever: `${CLOUDINARY_URL}/ISLH_LEVER.jpg`,
    img2Lever: `${CLOUDINARY_URL}/ISRH_LEVER.jpg`,
    img1M: `${CLOUDINARY_URL}/measurements/ISLH.jpg`,
    img2M: `${CLOUDINARY_URL}/measurements/ISRH.jpg`,
  },
];

export const equalPanelsPivot = [
  {
    swingDirection: "Inswing",
    SwingType: "Single Swing",
    Sidelites: "Add Single Sidelite",
    label: "Pivot 1 Sidelite",
    img1: `${CLOUDINARY_URL}/ISLH_1SL_EVEN.jpg`,
    img2: `${CLOUDINARY_URL}/ISRH_1SL_EVEN.jpg`,
    img3: `${CLOUDINARY_URL}/ISLH_1SL_LOCK_EVEN.jpg`,
    img4: `${CLOUDINARY_URL}/ISRH_1SL_LOCK_EVEN.jpg`,
    img1Lever: `${CLOUDINARY_URL}/ISLH_1SL_EVEN_LEVER.jpg`,
    img2Lever: `${CLOUDINARY_URL}/ISRH_1SL_EVEN_LEVER.jpg`,
    img3Lever: `${CLOUDINARY_URL}/ISLH_1SL_LOCK_EVEN_LEVER.jpg`,
    img4Lever: `${CLOUDINARY_URL}/ISRH_1SL_LOCK_EVEN_LEVER.jpg`,
    img1M: `${CLOUDINARY_URL}/measurements/ISLH_1SL.jpg`,
    img2M: `${CLOUDINARY_URL}/measurements/ISRH_1SL.jpg`,
    img3M: `${CLOUDINARY_URL}/measurements/ISLH_1SL_LOCK.jpg`,
    img4M: `${CLOUDINARY_URL}/measurements/ISRH_1SL_LOCK.jpg`,
  },
  {
    swingDirection: "Inswing",
    SwingType: "Single Swing",
    Sidelites: "Add Double Sidelite",
    label: "Pivot 2 Sidelite",
    img1: `${CLOUDINARY_URL}/ISLH_2SL_EVEN.jpg`,
    img2: `${CLOUDINARY_URL}/ISRH_2SL_EVEN.jpg`,
    img1Lever: `${CLOUDINARY_URL}/ISLH_2SL_EVEN_LEVER.jpg`,
    img2Lever: `${CLOUDINARY_URL}/ISRH_2SL_EVEN_LEVER.jpg`,
    img1M: `${CLOUDINARY_URL}/measurements/ISLH_2SL.jpg`,
    img2M: `${CLOUDINARY_URL}/measurements/ISRH_2SL.jpg`,
  },
];
