import React from "react";
import { cladTradBtm, slimline } from "./defaults";
import Choices from "../Choices";

export function showStilesOptions2ndLevel(
  doorType: string,
  materialChoice: string,
  slimOrTrad: string,
  handleClick: (label: string, type: string) => void,
  stiles: string,
) {
  if (
    slimOrTrad === "slim" &&
    (materialChoice === "clad" || doorType === "Bi-fold Window")
  ) {
    return (
      <>
        {slimline.map((state) => (
          <div
            key={state.id}
            onClick={() => handleClick(state.label, state.type)}
          >
            <Choices
              label={state.label}
              variant={state.label === stiles ? "filled" : "outlined"}
              checkMark={state.label === stiles}
            />
          </div>
        ))}
      </>
    );
  }

  if (slimOrTrad === "trad") {
    return (
      <>
        {cladTradBtm.map((state) => (
          <div
            key={state.id}
            onClick={() => handleClick(state.label, state.type)}
          >
            <Choices
              label={state.label}
              variant={state.label === stiles ? "filled" : "outlined"}
              checkMark={state.label === stiles}
            />
          </div>
        ))}
      </>
    );
  }

  return null;
}
