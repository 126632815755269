import React from "react";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const StyledDiv = styled("div")({
  position: "absolute",
  right: 10,
  top: 15,
});

export const ErrIconSidebar = () => {
  return (
    <StyledDiv>
      <Tooltip title="Edit Unit to Complete Missing Info" enterTouchDelay={0}>
        <div>
          <ErrorRoundedIcon style={{ color: "tomato" }} />
        </div>
      </Tooltip>
    </StyledDiv>
  );
};
