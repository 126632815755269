function pocketHeight(width: number) {
  if (width <= 48) return "4 3/4";
  if (width <= 96) return "6";
  if (width <= 120) return "6 1/2";
  if (width <= 144) return "7";
  if (width <= 177) return "7 1/2";
  if (width <= 192) return "8";
}

function outerPocketHeight(width: number) {
  if (width <= 48) return "1 1/4";
  if (width <= 96) return "2 1/2";
  if (width <= 120) return "3";
  if (width <= 144) return "3 1/2";
  if (width <= 177) return "4";
  if (width <= 192) return "4 1/2";
}

function outerPocketHeightLong(width: number) {
  if (width <= 48) return "2 1/4";
  if (width <= 96) return "3 1/2";
  if (width <= 120) return "4";
  if (width <= 144) return "4 1/2";
  if (width <= 177) return "5";
  if (width <= 192) return "5 1/2";
}

const stileAdjusted = (stile: string) =>
  stile.includes("Bottom")
    ? "2 3/4"
    : stile.includes("Slimline")
      ? "7/8"
      : "1 3/8";

const stileAdjusted2 = (stile: string) =>
  stile.includes("Bottom")
    ? "2 3/4"
    : stile.includes("Slimline")
      ? "7/8"
      : "1 1/2";

const stileWidth = (stile: string) =>
  stile.includes("Bottom")
    ? "4 3/4"
    : stile.includes("Slimline")
      ? "2 1/2"
      : "3 7/16";

const checkRail = (stile: string) =>
  stile.includes("Slimline") ? "3/4" : "1 1/8";

const checkRailXX = (stile: string) =>
  stile.includes("Slimline") ? "9/16" : "5/8";

const checkRailXX2 = (stile: string) =>
  stile.includes("Slimline") ? "3/4" : "1 3/8";

const checkRailXX3 = (stile: string) =>
  stile.includes("Slimline") ? "13/16" : "1 1/8";

const checkRailWoodCentor = (stile: string) =>
  stile.includes("Slimline") ? "13/16" : "1 5/16";

export {
  pocketHeight,
  outerPocketHeight,
  outerPocketHeightLong,
  stileAdjusted,
  stileAdjusted2,
  stileWidth,
  checkRail,
  checkRailXX,
  checkRailXX2,
  checkRailXX3,
  checkRailWoodCentor,
};
