const slidingScreenLeft = [
  { id: 1, type: "addSlidingScreen", label: "Add Screen Open Left" },
];

const slidingScreenRight = [
  { id: 1, type: "addSlidingScreen", label: "Add Screen Open Right" },
];

const slidingScreenDbl = [
  { id: 1, type: "addSlidingScreen", label: "Add Double Screen" },
];

const screenColors = [
  {
    id: 1,
    type: "screenColor",
    label: "Black Extrusion",
  },
  {
    id: 2,
    type: "screenColor",
    label: "White Extrusion",
  },
];

export {
  screenColors,
  slidingScreenDbl,
  slidingScreenLeft,
  slidingScreenRight,
};
