import React, { FC } from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const headCells = [
  { id: "date", numeric: false, disablePadding: true, label: "Date Created" },
  { id: "time", numeric: false, disablePadding: true, label: "Time Created" },
  { id: "name", numeric: false, disablePadding: false, label: "Customer Name" },
  {
    id: "service number",
    numeric: true,
    disablePadding: false,
    label: "Service Number",
  },
  {
    id: "po number",
    numeric: false,
    disablePadding: false,
    label: "PO Number",
  },
  {
    id: "sales order number",
    numeric: true,
    disablePadding: false,
    label: "SO Number",
  },
  {
    id: "appointment",
    numeric: true,
    disablePadding: false,
    label: "Appointment",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

const headCellsAdmin = [
  { id: "check", numeric: false, disablePadding: true, label: "" },
  { id: "date", numeric: false, disablePadding: true, label: "Date Created" },
  { id: "time", numeric: false, disablePadding: true, label: "Time Created" },
  { id: "name", numeric: false, disablePadding: false, label: "Customer Name" },
  { id: "dealer", numeric: false, disablePadding: false, label: "Dealer" },
  {
    id: "service number",
    numeric: true,
    disablePadding: false,
    label: "Service Number",
  },
  {
    id: "po number",
    numeric: false,
    disablePadding: false,
    label: "PO Number",
  },
  {
    id: "sales order number",
    numeric: true,
    disablePadding: false,
    label: "SO Number",
  },
  {
    id: "appointment",
    numeric: true,
    disablePadding: false,
    label: "Appointment",
  },
  { id: "team", numeric: true, disablePadding: false, label: "Service Team" },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

const headCellsOutsideSales = [
  { id: "date", numeric: false, disablePadding: true, label: "Date Created" },
  { id: "time", numeric: false, disablePadding: true, label: "Time Created" },
  { id: "name", numeric: false, disablePadding: false, label: "Customer Name" },
  { id: "dealer", numeric: false, disablePadding: false, label: "Dealer" },
  {
    id: "service number",
    numeric: true,
    disablePadding: false,
    label: "Service Number",
  },
  {
    id: "po number",
    numeric: false,
    disablePadding: false,
    label: "PO Number",
  },
  {
    id: "sales order number",
    numeric: true,
    disablePadding: false,
    label: "SO Number",
  },
  {
    id: "appointment",
    numeric: true,
    disablePadding: false,
    label: "Appointment",
  },
  { id: "team", numeric: true, disablePadding: false, label: "Service Team" },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

const ServiceTableHead: FC = () => {
  const role = JSON.parse(sessionStorage.getItem("role") || JSON.stringify(""));

  const hasAdminAccess = role === "Admin" || role === "Inside Sales";

  return (
    <TableHead style={{ backgroundColor: "#4b4b4b" }}>
      <TableRow>
        <TableCell></TableCell>
        {role === "Outside Sales"
          ? headCellsOutsideSales.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={"center"}
                padding={headCell.disablePadding ? "none" : "normal"}
                style={{ color: "white" }}
              >
                {headCell.label}
              </TableCell>
            ))
          : !hasAdminAccess
            ? headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={"center"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  style={{ color: "white" }}
                >
                  {headCell.label}
                </TableCell>
              ))
            : headCellsAdmin.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={"center"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  style={{ color: "white" }}
                >
                  {headCell.label}
                </TableCell>
              ))}
      </TableRow>
    </TableHead>
  );
};

export default ServiceTableHead;
