import { create } from "zustand";
import { persist } from "zustand/middleware";

interface RequiredMsgsStore {
  required: string[];
  findRequired: (item: string) => boolean;
  setRequired: (requireds: string[]) => void;
  removeRequired: (reqMsg: string, reqMsg2?: string) => void;
}

export const useRequiredMsgs = create<RequiredMsgsStore>()(
  persist(
    (set, get) => ({
      required: [],
      findRequired: (item) => {
        const req = get().required;
        if (!req.length) return false;

        return req.some((val) => val === item);
      },
      setRequired: (newRequiredArray) =>
        set((state) => ({ ...state, required: newRequiredArray })),
      removeRequired: (reqMsg, reqMsg2) =>
        set((state) => {
          const msgs = state.required;
          const removeMsg = !reqMsg2
            ? msgs.filter((msg) => msg !== reqMsg)
            : msgs.filter((msg) => msg !== reqMsg && msg !== reqMsg2);

          return { ...state, required: removeMsg };
        }),
    }),
    {
      name: "requiredMsgs",
    },
  ),
);
