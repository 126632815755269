import React, { memo, FC } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";

interface Props {
  windowOrDoor: string;
  handleChange: (event: SelectChangeEvent<string>) => void;
  fontSize: number | string;
  hover?: string;
}

const BifoldTypeSelect: FC<Props> = ({
  windowOrDoor,
  handleChange,
  fontSize,
  hover,
}) => {
  return (
    <FormControl size="small">
      <Select
        value={windowOrDoor}
        onChange={handleChange}
        style={{ fontSize, color: hover === "Bi-Fold Door" ? "#57d5f2" : "" }}
        disableUnderline
        inputProps={{
          classes: {
            icon: {
              fill: "blue",
            },
          },
        }}
        sx={{ height: 35 }}
        margin="dense"
        variant="outlined"
      >
        <MenuItem value="Bi-Fold">Bi-Fold Door</MenuItem>
        <MenuItem value="Bi-Fold Window">Bi-Fold Window</MenuItem>
        <MenuItem value="Bi-Fold Zero Corner">Bi-Fold Zero Corner</MenuItem>
      </Select>
    </FormControl>
  );
};

export default memo(BifoldTypeSelect);
