const CLOUDINARY_URL =
  "https://res.cloudinary.com/ag-millworks/image/upload/Bifold_Corner_Configs/configs";

const taggedCornerConfigImages = [
  {
    config: "1L",
    image: `${CLOUDINARY_URL}/1L.jpg`,
    image2: `${CLOUDINARY_URL}/1L_Secondary.jpg`,
  },
  {
    config: "1R",
    image: `${CLOUDINARY_URL}/1R.jpg`,
    image2: `${CLOUDINARY_URL}/1R_Secondary.jpg`,
  },
  {
    config: "2L",
    image: `${CLOUDINARY_URL}/2L.jpg`,
  },
  {
    config: "2R",
    image: `${CLOUDINARY_URL}/2R.jpg`,
  },
  {
    config: "3L",
    image: `${CLOUDINARY_URL}/3L.jpg`,
    image2: `${CLOUDINARY_URL}/3L_Secondary.jpg`,
  },
  {
    config: "3R",
    image: `${CLOUDINARY_URL}/3R.jpg`,
    image2: `${CLOUDINARY_URL}/3R_Secondary.jpg`,
  },
  {
    config: "4L",
    image: `${CLOUDINARY_URL}/4L.jpg`,
  },
  {
    config: "4R",
    image: `${CLOUDINARY_URL}/4R.jpg`,
  },
  {
    config: "5L",
    image: `${CLOUDINARY_URL}/5L.jpg`,
    image2: `${CLOUDINARY_URL}/5L_Secondary.jpg`,
  },
  {
    config: "5R",
    image: `${CLOUDINARY_URL}/5R.jpg`,
    image2: `${CLOUDINARY_URL}/5R_Secondary.jpg`,
  },
  {
    config: "6L",
    image: `${CLOUDINARY_URL}/6L.jpg`,
  },
  {
    config: "6R",
    image: `${CLOUDINARY_URL}/6R.jpg`,
  },
  {
    config: "7L",
    image: `${CLOUDINARY_URL}/7L.jpg`,
    image2: `${CLOUDINARY_URL}/7L_Secondary.jpg`,
  },
  {
    config: "7R",
    image: `${CLOUDINARY_URL}/7R.jpg`,
    image2: `${CLOUDINARY_URL}/7R_Secondary.jpg`,
  },
  {
    config: "8L",
    image: `${CLOUDINARY_URL}/8L.jpg`,
  },
  {
    config: "8R",
    image: `${CLOUDINARY_URL}/8R.jpg`,
  },
];

export default taggedCornerConfigImages;
