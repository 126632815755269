import React, { memo, FC } from "react";
import AdminFactorSelect from "./AdminFactorSelect";
import { Typography, ListItem, ListItemText } from "@mui/material";
import formatIntWithCommas from "../util/formatIntWithCommas";

function getTotal(
  text: string,
  suggRetailPrice: string,
  delivery: number,
  customClad: number,
  dealerTax: number | null,
  grandTotal: string,
  adminCharge: number | null,
) {
  if (text === "Total" && suggRetailPrice) {
    return `$${suggRetailPrice}`;
  }

  if (text === "Delivery Charge: ") {
    return `$${delivery ? `${delivery}.00` : 0}`;
  }

  if (text === "Custom Clad Color: " && customClad !== 0) {
    return `$${formatIntWithCommas(customClad)}.00`;
  }

  if (text === "Sales Tax: " && dealerTax) {
    return `${dealerTax}%`;
  }

  if (text === "Dealer Net Cost: " && grandTotal) {
    return `$${grandTotal}`;
  }

  if (adminCharge) {
    return `$${adminCharge}`;
  }

  return "";
}

interface SidebarTotalsProps {
  text: string;
  factor: number;
  canEditFactor: boolean;
  suggRetailPrice: string;
  grandTotal: string;
  handleAdminFactor: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  adminFactor: string;
  customClad: number;
  deliveryCharge: number;
  dealerTax: number;
  adminCharge: number;
}

const SidebarTotals: FC<SidebarTotalsProps> = ({
  text,
  factor,
  canEditFactor,
  suggRetailPrice,
  grandTotal,
  handleAdminFactor,
  adminFactor,
  customClad,
  deliveryCharge,
  dealerTax,
  adminCharge,
}) => {
  return (
    <>
      {text === "Custom Clad Color: " && customClad === 0 ? null : (
        <ListItem>
          <ListItemText
            style={{
              position: "relative",
              borderTop: text === "Dealer Net Cost: " ? "1px solid black" : "",
              height: text !== "Dealer Net Cost: " ? 10 : "",
            }}
            primary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                  style={{
                    fontWeight: text === "Dealer Net Cost: " ? "bold" : "",
                  }}
                >
                  {text === "Total" && canEditFactor ? (
                    <>
                      {text} (Factor
                      <AdminFactorSelect
                        handleChange={handleAdminFactor}
                        adminFactor={adminFactor}
                      />
                      ) :
                    </>
                  ) : text === "Total" ? (
                    `Total (Factor ${factor}):`
                  ) : (
                    text
                  )}
                </Typography>

                <Typography
                  style={{
                    position: "absolute",
                    right: 0,
                    fontWeight: text === "Dealer Net Cost: " ? "bold" : "",
                  }}
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  {getTotal(
                    text,
                    suggRetailPrice,
                    deliveryCharge,
                    customClad,
                    dealerTax || null,
                    grandTotal,
                    adminCharge || null,
                  )}
                </Typography>
              </>
            }
          />
        </ListItem>
      )}
    </>
  );
};

export default memo(SidebarTotals);
