import React, { FC } from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { blueGrey } from "@mui/material/colors";

const StyledCustomerInfoDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  flexWrap: "wrap",
});

const StyledCustomerInputDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: 20,
});

const StyledInput = styled("input")(({ theme }) => ({
  backgroundColor: blueGrey[50],
  border: "1px solid grey",
  borderRadius: "4px",
  height: "30px",
  width: 300,
  [theme.breakpoints.down("sm")]: {
    width: 150,
    marginLeft: 5,
  },
}));

interface CustomerInfoProps {
  signInRole: string;
  handleAllChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  customerName: string;
  customerEmail: string;
  customerPhone: string;
}

const CustomerInfo: FC<CustomerInfoProps> = ({
  signInRole,
  handleAllChange,
  customerName,
  customerEmail,
  customerPhone,
}) => {
  return (
    <StyledCustomerInfoDiv>
      <StyledCustomerInputDiv>
        <Typography style={{ flexBasis: "30%" }} variant="body2">
          Customer Name:
          {(signInRole === "Dealer" ||
            signInRole === "Manager" ||
            signInRole === "Company Manager") && (
            <span style={{ color: "red", fontSize: 22 }}>* </span>
          )}
        </Typography>
        <StyledInput
          maxLength={40}
          onChange={handleAllChange}
          name="customerName"
          value={customerName}
          placeholder="Customer Name"
        />
      </StyledCustomerInputDiv>

      <StyledCustomerInputDiv>
        <Typography style={{ flexBasis: "30%" }} variant="body2">
          Email:{" "}
        </Typography>
        <StyledInput
          maxLength={40}
          onChange={handleAllChange}
          name="customerEmail"
          value={customerEmail}
          placeholder="Email"
        />
      </StyledCustomerInputDiv>

      <StyledCustomerInputDiv style={{ paddingBottom: 0 }}>
        <Typography style={{ flexBasis: "30%" }} variant="body2">
          Phone:{" "}
        </Typography>
        <StyledInput
          maxLength={20}
          onChange={handleAllChange}
          name="customerPhone"
          value={customerPhone}
          placeholder="Phone"
        />
      </StyledCustomerInputDiv>
    </StyledCustomerInfoDiv>
  );
};

export default CustomerInfo;
