import React from "react";
import { Typography } from "@mui/material";
import { useStore } from "../store/store";
import { styled } from "@mui/material/styles";

const StyledDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: 10,
});

const StyledSpan = styled("span")({
  color: "red",
  fontWeight: "bold",
});

const ErrorMsgs = () => {
  const widthErr = useStore((state) => state.widthErr);
  const widthErrCornerRight = useStore((state) => state.widthErrCornerRight);
  const heightErr = useStore((state) => state.heightErr);
  const jambErr = useStore((state) => state.jambErr);
  const configErr = useStore((state) => state.configErr);
  const primaryDoorErr = useStore((state) => state.primaryDoorErr);
  const swingWidthErr = useStore((state) => state.swingWidthErr);
  const pivotPointErr = useStore((state) => state.pivotPointErr);
  const sideliteWidthErr = useStore((state) => state.sideliteWidthErr);

  return (
    <StyledDiv>
      {widthErr && <ErrorBody err={widthErr} />}
      {widthErrCornerRight && <ErrorBody err={widthErrCornerRight} />}
      {heightErr && <ErrorBody err={heightErr} />}
      {jambErr && <ErrorBody err={`Jamb ${jambErr}`} />}
      {configErr && <ErrorBody err={configErr} />}
      {primaryDoorErr && <ErrorBody err={primaryDoorErr} />}
      {swingWidthErr && <ErrorBody err={swingWidthErr} />}
      {sideliteWidthErr && <ErrorBody err={sideliteWidthErr} />}
      {pivotPointErr && <ErrorBody err={`Pivot Point ${pivotPointErr}`} />}
    </StyledDiv>
  );
};

const ErrorBody: React.FC<{ err: string }> = ({ err }) => {
  return (
    <Typography style={{ color: "red" }} variant="body1">
      <StyledSpan>Fix Error: </StyledSpan> {err}
    </Typography>
  );
};

export default ErrorMsgs;
