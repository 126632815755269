import { useState, useEffect } from "react";
import { convertDecimalToFraction } from "../util/pdfFormulas/convertDecimalToFraction";
import calcSwingWidths from "../util/pdfFormulas/calculateSwingWidths";
import { UnitObj } from "../util/quoteCartStorage";

interface UpdateObj {
  swingPanelWidth: string;
  swingPanelFraction: string;
  sideLiteWidth?: string;
  sideLiteDLO?: string;
  dropseal?: string;
}

const useSwingWidth = (
  width: string,
  widthFraction: string,
  direction: string,
  swingType: string,
  sideLite: string,
  pWidth: string,
  pWidthFraction: string,
  addToQuote: (
    updateObj: Partial<UnitObj>,
    ignoreKey?: string | undefined,
  ) => void,
  handleErrMsgChange: (errKey: string, err: string) => void,
  editBtn: boolean,
  equalPanels: boolean,
  material: string,
  isPivot: boolean,
  screenType: string,
) => {
  const [panelWidth, setPanelWidth] = useState(pWidth);
  const [panelFraction, setPanelFraction] = useState(pWidthFraction);
  const [sideliteWidth, setSideliteWidth] = useState("");

  const [equalP, setEqualP] = useState("");

  const findSwingWidths = () => {
    const { panelW, slw, equalized } = calcSwingWidths(
      width,
      widthFraction,
      direction,
      swingType,
      sideLite,
      pWidth,
      pWidthFraction,
      equalPanels,
      material,
      isPivot,
      screenType,
    );

    if (equalized) {
      const fraction = convertDecimalToFraction(equalized);

      setEqualP(fraction);

      const sidelitedlo = fraction.split(" ")[1]
        ? `${parseInt(fraction.split(" ")[0]) - 5} ${fraction.split(" ")[1]}`
        : `${parseInt(fraction) - 5}`;

      let update: UpdateObj = {
        swingPanelWidth: fraction.split(" ")[0],
        swingPanelFraction: fraction.split(" ")[1] || "",
        sideLiteWidth: fraction,
        sideLiteDLO: sidelitedlo,
      };

      if (isPivot && equalized > 90) {
        update["dropseal"] = "";
      }

      addToQuote(update);

      const widthErr =
        isPivot && (equalized > 60 || equalized < 28)
          ? "Panel/Sidelite width must be between 28 and 60 inches"
          : !isPivot && (equalized > 42.0625 || equalized < 24)
            ? "Panel/Sidelite width must be between 24 and 42 inches"
            : "";

      handleErrMsgChange("sideliteWidthErr", widthErr);
    } else {
      if (panelW && sideLite === "none") {
        const sepByDecimal = panelW.toString().split(".");
        const panelDecimal = convertDecimalToFraction(
          parseFloat(`.${sepByDecimal[1]}`),
        );
        const wholeNumber = sepByDecimal[0];

        setPanelWidth(wholeNumber);
        setPanelFraction(panelDecimal.split(" ")[1]);

        let update: UpdateObj = {
          swingPanelWidth: wholeNumber,
          swingPanelFraction: panelDecimal.split(" ")[1],
        };

        if (isPivot && panelW > 90) {
          update["dropseal"] = "";
        }

        addToQuote(update);
      } else if (sideLite) {
        const converted = convertDecimalToFraction(slw);
        setSideliteWidth(converted);

        const sidelitedlo = converted.split(" ")[1]
          ? `${parseInt(converted.split(" ")[0]) - 5} ${converted.split(" ")[1]}`
          : `${parseInt(converted) - 5}`;

        addToQuote({
          sideLiteWidth: converted,
          sideLiteDLO: sidelitedlo,
        });

        const widthErr =
          isPivot && (slw > 60 || slw < 11)
            ? "Sidelite width must be between 11 and 60 inches"
            : !isPivot && (slw > 43 || slw < 11)
              ? "Sidelite width must be between 11 and 43 inches"
              : "";

        handleErrMsgChange("sideliteWidthErr", widthErr);
      }
    }
  };

  useEffect(() => {
    if (!direction) return;

    if (
      !width ||
      !swingType ||
      width.length < 2 ||
      (!equalPanels && sideLite !== "none" && pWidth.length < 2)
    ) {
      setPanelWidth("");
      setPanelFraction("");
      setSideliteWidth("");
      setEqualP("");
      return;
    }

    findSwingWidths();
  }, [
    width,
    widthFraction,
    direction,
    swingType,
    sideLite,
    pWidth,
    pWidthFraction,
    editBtn,
    equalPanels,
    material,
    isPivot,
    screenType,
  ]);

  return { panelWidth, panelFraction, sideliteWidth, equalP };
};

export default useSwingWidth;
