import React, { memo, FC } from "react";
import Typography from "@mui/material/Typography";
import usePersistedState from "../hooks/usePersistedState";
import debounce from "../util/debounce";
import { useStore } from "../store/store";
import { styled } from "@mui/material/styles";

const StyledDiv = styled("div")({
  flexBasis: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 15,
  borderRight: "inset",
  borderLeft: "outset",
});

interface LocationProps {
  editQuote: boolean;
  submitPressed: boolean;
}

const Location: FC<LocationProps> = ({ editQuote, submitPressed }) => {
  const addToQuote = useStore((state) => state.addToQuote);

  const [location, setLocation] = usePersistedState(
    "location",
    "",
    submitPressed,
    editQuote,
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setLocation(value);
    debounce(value, addToQuote, "location", 400);
  };

  return (
    <StyledDiv>
      <Typography style={{ marginRight: 20 }} variant="body2">
        Enter Unit Location:{" "}
      </Typography>
      <input
        maxLength={18}
        value={location}
        onChange={handleChange}
        style={{ backgroundColor: "#eceff1" }}
        placeholder="Location"
      />
    </StyledDiv>
  );
};

export default memo(Location);
