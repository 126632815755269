import React, { useState, useEffect, FC } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { SelectChangeEvent } from "@mui/material";
import { AppBar, Toolbar, useMediaQuery } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import AccountDropdown from "../components/AccountDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import GenerateDealer from "../quotePDF/GenerateDealer";
import GenerateCustomer from "../quotePDF/GenerateCustomer";
import MarginInput from "../components/orderSummary/MarginInput";
import AdditionalCosts from "../components/orderSummary/AdditionalCosts";
import { useQuery, useQueryClient } from "react-query";
import Backdrop from "@mui/material/Backdrop";
import getParams from "../hooks/useParams";
import QuoteNumber from "../components/QuoteNumber";
import SalesTaxTooltip from "../components/orderSummary/SalesTaxTooltip";
import OtsmIcon from "../components/OtsmIcon";
import round from "../util/round";
import Alert from "@mui/material/Alert";
import CustomSalesTax from "../components/orderSummary/CustomSalesTax";
import AdminCostModal from "../components/orderSummary/modals/AdminCostModal";
import DoorList from "../components/orderSummary/DoorList";
import formatIntWithCommas from "../util/formatIntWithCommas";
import priceWithZeros from "../util/priceWithZeros";
import usePersistedState from "../hooks/usePersistedState";
import Settings from "../components/orderSummary/Settings";
import PoJobsite from "../components/orderSummary/PoJobsite";
import Copyright from "../components/Copyright";
import {
  postSummaryData,
  postPO,
  getQuote,
  getUser,
  postJobsiteDetails,
} from "../api/OrderSummaryApi";
import { blueGrey } from "@mui/material/colors";
import {
  taxableCostsTotal,
  calcSalesTaxDollar,
  calcDealerTaxDollar,
} from "../util/calcSalesTaxDollar";
import { AddCostObj } from "../GlobalTypes";
import { UnitObj } from "../util/quoteCartStorage";
import { styled } from "@mui/material/styles";

function allDealerActAccess(role: string) {
  return role === "Dealer" || role === "Manager" || role === "Company Manager";
}

const StyledDivBg = styled("div")({
  backgroundColor: blueGrey[50],
  backgroundSize: "cover",
  minHeight: "100vh",
  position: "relative",
});

const StyledDivContentWrap = styled("div")({
  paddingBottom: "2.5rem",
});

const StyledAppbar = styled(AppBar)({
  marginBottom: "5vh",
});

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const StyledNavBtn = styled(Button)({
  color: "white",
});

const StyledDivCenter = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const StyledImgLarg = styled("img")({
  height: 80,
  width: 300,
});

const StyledLogoSmall = styled("img")({
  height: 60,
  width: 45,
  marginLeft: 10,
});

const StyledSpanPO = styled("span")({
  color: "red",
  fontSize: 22,
});

const StyledDivColCenter = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  border: "2px solid grey",
  backgroundColor: "white",
});

const StyledTypographyTitle = styled(Typography)({
  fontWeight: "bold",
  borderBottom: "1px solid black",
  alignSelf: "center",
  marginBottom: 10,
});

const StyledDivRowCenter = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const StyledDivRowBtwn = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: 10,
  paddingRight: 10,
});

const StyledDivRowSpace = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: 10,
  paddingRight: 10,
});

const StyledDivRowSpacePad = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid black",
  paddingLeft: 10,
  paddingRight: 10,
  paddingBottom: 10,
});

const StyledDivColStart = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

const StyledDivColEnd = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  flexDirection: "column",
  alignItems: "flex-end",
});

const StyledDivAddCosts = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  borderBottom: "1px solid black",
  paddingBottom: 20,
});

const StyledCalcButton = styled(Button)({
  textAlign: "center",
  color: "#21c7f5",
  borderRadius: 5,
  fontWeight: "bold",
  backgroundColor: "#eceff1",
  marginLeft: 20,
  marginRight: 20,
  marginTop: 5,
});

const StyledDivRowSpaceBtw = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 20,
  position: "relative",
});

const StyledGrandDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginTop: 10,
  marginBottom: 30,
  paddingLeft: 10,
  paddingRight: 10,
  borderBottom: "1px solid black",
  paddingBottom: 10,
});

const StyledDivCustomPaint = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginTop: 10,
  textAlign: "center",
  backgroundColor: "white",
});

const StyledBackdrop = styled(Backdrop)({
  backgroundColor: "#5b6467",
  backgroundImage: "linear-gradient(315deg, #5b6467 0%, #8b939a 74%)",
});

const StyledLoadDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const StyledNavbarLeftDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "50%",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "flex-start",
    width: "40%",
  },
}));

const StyledNavbarRightDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  width: "30%",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "space-between",
    width: "60%",
  },
}));

const StyledTitleDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingTop: -20,
  [theme.breakpoints.down("lg")]: {
    paddingTop: 100,
    justifyContent: "center",
  },
}));

const StyledTitleAndUnitsDIv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  paddingLeft: 100,
  [theme.breakpoints.down("sm")]: {
    padding: 0,
    alignItems: "center",
  },
}));

const StyledOSContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  paddingLeft: 30,
  paddingRight: 30,
  marginTop: 10,
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    padding: 20,
  },
  [theme.breakpoints.down("sm")]: {
    padding: 0,
  },
}));

const StyledPricingDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  flexBasis: "25%",
  marginTop: -100,
  backgroundColor: blueGrey[50],
  [theme.breakpoints.down("lg")]: {
    padding: 40,
    margin: 0,
  },
  [theme.breakpoints.down("sm")]: {
    height: "80%",
    flexBasis: "100%",
    padding: 10,
    justifyContent: "flex-start",
  },
}));

const StyledPoContainerDiv = styled("div")(({ theme }) => ({
  marginBottom: 10,
  border: "2px solid grey",
  padding: 5,
  textAlign: "center",
  paddingTop: 10,
  paddingBottom: 10,
  backgroundColor: "white",
  [theme.breakpoints.down("sm")]: {
    padding: 5,
  },
}));

const StyledTitleText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: 30,
    fontWeight: "400",
  },
}));

const StyledButtonsLayout = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "space-around",
  position: "relative",
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function getDealerTax(
  status: string,
  dealerTax: number,
  dateOrdered: string | Date,
) {
  if (
    status !== "Quote" &&
    status !== "Archived" &&
    dealerTax &&
    dateOrdered &&
    new Date(dateOrdered) < new Date(2024, 1, 19)
  ) {
    return null;
  }

  return dealerTax || null;
}

const OrderSummary: FC = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const matches = useMediaQuery("(min-width:1000px)", { noSsr: true });

  const changeLogo = useMediaQuery("(min-width:700px)", { noSsr: true });

  const shouldReload = JSON.parse(
    sessionStorage.getItem("refresh") || JSON.stringify(""),
  );

  useEffect(() => {
    if (shouldReload) {
      sessionStorage.setItem("refresh", JSON.stringify(false));
      window.location.reload();
    }
  }, []);

  const location = useLocation();
  const id = getParams(location.pathname);

  const historyState = location.state;

  const quoteNumber = JSON.parse(
    sessionStorage.getItem("quoteNumber") || JSON.stringify(""),
  );

  const [grandTotal, setGrandTotal] = useState("");

  const [marginInput, setMarginInput] = useState("");

  const [po, setPo] = useState("");

  const [fetch, setFetch] = useState(false);

  const { data: userData, isFetching: isFetchingUser } = useQuery(
    ["User", shouldReload],
    async () => {
      if (!shouldReload) {
        const response = await getUser(historyState.userId);
        if (response.data.signInRole === "Production") {
          const user = JSON.parse(
            sessionStorage.getItem("user") || JSON.stringify(""),
          );
          navigate(`/dealerZone/${user}`);
        }
        return response.data;
      }
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: () => setFetch(true),
    },
  );

  const { isLoading, error, data, isFetching } = useQuery(
    fetch ? ["Cart", shouldReload] : [],
    async () => {
      if (!shouldReload) {
        const response = await getQuote(historyState.userId, quoteNumber);

        const { quote } = response.data;

        quote.poNumber && setPo(quote.poNumber);

        let marginUsed = "";

        if (quote.status !== "Quote" && quote.status !== "Archived") {
          marginUsed = quote.margin || "";
        } else {
          marginUsed =
            userData?.otsm && userData?.otsmDefault === "Fixed Margin"
              ? userData?.fixedMargin
              : userData?.otsm && userData?.otsmDefault === "Fixed Markup"
                ? userData?.fixedMarkup
                : quote.overrideFixedMargin &&
                    userData?.fixedMargin &&
                    !quote.withMarkup
                  ? quote.margin
                  : quote.overrideFixedMarkup &&
                      userData?.fixedMarkup &&
                      quote.withMarkup
                    ? quote.margin
                    : userData?.fixedMargin && !quote.withMarkup
                      ? userData?.fixedMargin
                      : userData?.fixedMarkup && quote.withMarkup
                        ? userData?.fixedMarkup
                        : quote.margin
                          ? quote.margin
                          : "";
        }

        quote.overrideFixedMargin && setOverride(true);
        quote.overrideFixedMarkup && setOverrideMarkup(true);

        if (userData.otsm && userData.otsmDefault === "Fixed Margin") {
          setWithMarkup(false);
        } else if (userData.otsm && userData.otsmDefault === "Fixed Markup") {
          setWithMarkup(true);
        } else {
          quote.withMarkup && setWithMarkup(true);
        }

        marginUsed && setMarginInput(marginUsed);

        const adminCharge =
          quote.adminChargeMargined && quote.adminCharge
            ? quote.adminCharge
            : "";

        const grandTotalWithMarkup =
          userData.otsm &&
          userData.otsmDefault === "Fixed Markup" &&
          userData.fixedMarkup
            ? true
            : userData.otsm &&
                userData.otsmDefault === "Fixed Margin" &&
                userData.fixedMargin
              ? false
              : quote.withMarkup
                ? quote.withMarkup
                : false;

        if (quote.checkedJobsite) {
          setJobsite({
            contactName: quote.jobsiteContact || "",
            contactNumber: quote.jobsiteContactNumber || "",
            address: quote.address || "",
            city: quote.city || "",
            zip: quote.zip || "",
            state: quote.state || ""
          });
        }

        const dealerTax = getDealerTax(
          quote.status,
          userData.dealerTax || null,
          quote.dateOrdered || null,
        );

        const addCosts: AddCostObj[] =
          quote.additionalCostsPrice && quote.additionalCostsPriceNoTax
            ? [
                {
                  title: quote.additionalCostsTitle || "",
                  price: quote.additionalCostsPrice,
                  taxable: dealerTax ? false : true,
                },
                {
                  title: quote.additionalCostsTitleNoTax || "",
                  price: quote.additionalCostsPriceNoTax,
                  taxable: false,
                },
              ]
            : quote.additionalCostsPrice
              ? [
                  {
                    title: quote.additionalCostsTitle || "",
                    price: quote.additionalCostsPrice,
                    taxable: dealerTax ? false : true,
                  },
                ]
              : quote.additionalCostsPriceNoTax
                ? [
                    {
                      title: quote.additionalCostsTitleNoTax || "",
                      price: quote.additionalCostsPriceNoTax,
                      taxable: false,
                    },
                  ]
                : quote.addCosts
                  ? quote.addCosts
                  : [];

        calcGrandTotal(
          marginUsed,
          grandTotalWithMarkup,
          addCosts,
          false,
          dealerTax ? 0 : quote.salesTax ? quote.salesTax : 0,
          quote.deliveryCharge || 0,
          quote.grandTotal,
          adminCharge,
          userData.deliveryMarkup,
          true,
          dealerTax ? true : false
        );

        quote.hideCustomerPricing &&
          quote.hideCustomerPricing !== "Show All" &&
          setHideCustomerPricing(quote.hideCustomerPricing);

        quote.seriesOverride && setSeries(quote.seriesOverride);

        setViewPdf(false);
        return response.data;
      }
    },
    { refetchOnWindowFocus: false },
  );

  const dealerTax =
    isFetching || isFetchingUser
      ? null
      : getDealerTax(
          data.quote.status,
          userData.dealerTax || null,
          data.quote.dateOrdered || null,
        );

  const addCosts: AddCostObj[] = isFetching
    ? []
    : data.quote.additionalCostsPrice && data.quote.additionalCostsPriceNoTax
      ? [
          {
            title: data.quote.additionalCostsTitle || "",
            price: data.quote.additionalCostsPrice,
            taxable: dealerTax ? false : true,
          },
          {
            title: data.quote.additionalCostsTitleNoTax || "",
            price: data.quote.additionalCostsPriceNoTax,
            taxable: false,
          },
        ]
      : data.quote.additionalCostsPrice
        ? [
            {
              title: data.quote.additionalCostsTitle || "",
              price: data.quote.additionalCostsPrice,
              taxable: dealerTax ? false : true,
            },
          ]
        : data.quote.additionalCostsPriceNoTax
          ? [
              {
                title: data.quote.additionalCostsTitleNoTax || "",
                price: data.quote.additionalCostsPriceNoTax,
                taxable: false,
              },
            ]
          : data?.quote.addCosts
            ? data?.quote.addCosts
            : [];

  const [series, setSeries] = useState("Default");

  const [overrideFixedMargin, setOverride] = useState(false);

  const [overrideFixedMarkup, setOverrideMarkup] = useState(false);

  const [hideCustomerPricing, setHideCustomerPricing] = useState("Show All");

  const adminChargeMargined =
    !isFetching && data?.quote.adminChargeMargined && data.quote?.adminCharge
      ? true
      : false;

  const disableChanges =
    !isFetching && data?.quote.status !== "Quote" ? true : false;

  const disableMargin =
    disableChanges ||
    (!isFetchingUser &&
      userData.fixedMargin &&
      !isFetching &&
      !overrideFixedMargin);

  const disableMarkup =
    disableChanges ||
    (!isFetchingUser &&
      userData.fixedMarkup &&
      !isFetching &&
      !overrideFixedMarkup);

  var custom = (unit: UnitObj) =>
    unit.customPaintOrStain && unit.customPaintOrStain !== "";

  const [errMessageMargin, setErrMessage] = usePersistedState(
    "errMessageMargin",
    "",
  );

  const [priceWithMargin, setPriceWithMargin] = useState(true); //if true customer price was calculated with margin

  const [changesMade, setChangesMade] = useState(false);

  const [pdfErr, setPdfErr] = useState(false);

  const [margin, setMargin] = useState(0);

  const [withMarkup, setWithMarkup] = useState(false); //margin flase, markup true

  const [taxResults, setTaxResults] = useState([]);
  const [chosenTax, setChosenTax] = useState("");

  const handleTaxChange = (event: SelectChangeEvent<string>) =>
    setChosenTax(event.target.value);

  const [jobsite, setJobsite] = useState({
    contactName: "",
    contactNumber: "",
    city: "",
    zip: "",
    state: "",
    address: "",
  });

  const [openJobsite, setOpenJobsite] = useState(false);

  const [loadJobsite, setLoadJobsite] = useState(false);

  const [openPO, setOpenPO] = useState(false);

  const handleOpenJobsite = () => {
    setTaxResults([]);
    setChosenTax("");
    setLoadJobsite(false);
    setOpenJobsite(true);
  };

  const handleCloseJobsite = () => {
    setOpenJobsite(false);
    setLoadJobsite(false);

    setJobsite({
      contactName: data.quote.jobsiteContact || "",
      contactNumber: data.quote.jobsiteContactNumber || "",
      address: data.quote.address || "",
      city: data.quote.city || "",
      zip: data.quote.zip || "",
      state: data.quote.state || ""
    });
  };

  const handleOpenPO = () => {
    console.log("open po called")
    setOpenPO(true);
  }

  const handleClosePO = () => setOpenPO(false);

  const [modalDetailsErr, setModalDetailsErr] = useState("");

  const handleJobsiteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setJobsite((prev) => ({ ...prev, [name]: value }));
  };

  const handleModalDetails = async () => {
    setLoadJobsite(true);
    const res = await postJobsiteDetails(
      data.quote._id,
      jobsite,
      chosenTax || null,
      data.quote.salesTax,
    );

    if (res.data === "Problem") {
      setModalDetailsErr("Problem Occurred");
      setLoadJobsite(false);
      return;
    }

    if (res.data.error) {
      setTaxResults([]);
      setChosenTax("");
      setModalDetailsErr("No Sales Tax Found for that City and Zip");
      setLoadJobsite(false);
      return;
    }

    if (res.data.results) {
      setTaxResults(res.data.results);
      setLoadJobsite(false);
      return;
    }

    setTaxResults([]);
    setChosenTax("");
    setModalDetailsErr("");
    setOpenJobsite(false);
    setLoadJobsite(false);
    return queryClient.invalidateQueries("Cart");
  };

  const handlePoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPo(value);
    setViewPdf(false);
    await postPO(data.quote._id, value);
  };

  const [viewPdf, setViewPdf] = useState(false);

  const handleGenerateClick = () => setViewPdf(true);

  const calcGrandTotal = async (
    marginUsed: string,
    markup: boolean,
    addCosts: AddCostObj[],
    shouldMutate: boolean,
    salesTax: number,
    deliveryCharge: number,
    grandTotalPrice: string,
    adminCharge: number,
    deliveryMarkup?: boolean,
    initialCalc?: boolean,
    hasDealerTax?: boolean
  ) => {
    if (initialCalc) setChangesMade(false);

    const GTP = grandTotalPrice || data.quote.grandTotal;
    if (!marginUsed && !addCosts.length) {
      const total = !adminCharge
        ? parseFloat(GTP.replaceAll(",", ""))
        : hasDealerTax && adminCharge < 0
        ? round(parseFloat(GTP.replaceAll(",", "")))
        : round(parseFloat(GTP.replaceAll(",", "")) + adminCharge);

      if (salesTax !== 0 && salesTax) {
        const grandWithSalesTax = round(total * (1 + salesTax));
        setGrandTotal(formatIntWithCommas(grandWithSalesTax));
      } else {
        setGrandTotal(formatIntWithCommas(total));
      }

      setMargin(0);
      if (shouldMutate) {
        await postSummaryData(data.quote._id, "", withMarkup);
        setChangesMade(false);
      }
      return;
    }
    const delivery = deliveryCharge;
    var p = GTP.replace(/\,/g, "");
    var pInt = parseFloat(p) - delivery;
    var gt = 0;

    if (adminCharge) pInt += adminCharge;

    const taxableCosts = addCosts.filter((itm) => itm.taxable);
    const nontaxableCosts = addCosts.filter((itm) => !itm.taxable);

    if (!marginUsed && taxableCosts.length) {
      const costsTotal = taxableCostsTotal(taxableCosts);
      gt = pInt + costsTotal;
    } else if (marginUsed && !taxableCosts.length) {
      gt += !markup
        ? pInt / (1 - parseFloat(marginUsed) / 100)
        : pInt * (parseFloat(marginUsed) / 100 + 1);
    } else if (taxableCosts.length && marginUsed) {
      const costsTotal = taxableCostsTotal(taxableCosts);

      gt += !markup
        ? pInt / (1 - parseFloat(marginUsed) / 100)
        : pInt * (parseFloat(marginUsed) / 100 + 1);
      gt += costsTotal;
    } else {
      gt += pInt;
    }

    if (salesTax !== 0 && salesTax) gt = gt * (1 + salesTax);

    if (deliveryMarkup && marginUsed) {
      gt += !markup
        ? delivery / (1 - parseFloat(marginUsed) / 100)
        : delivery * (parseFloat(marginUsed) / 100 + 1);
    } else {
      gt += delivery;
    }

    if (nontaxableCosts.length) {
      const costsTotal = taxableCostsTotal(nontaxableCosts);
      gt += costsTotal;
    }

    const roundTwoDecimal = round(gt);

    var grand = formatIntWithCommas(roundTwoDecimal);

    grand = priceWithZeros(grand);

    setGrandTotal(grand);

    markup ? setPriceWithMargin(false) : setPriceWithMargin(true);

    if (shouldMutate) {
      setChangesMade(false);
      await postSummaryData(data.quote._id, marginUsed, markup);
    }

    marginUsed && setMargin(1 - parseFloat(marginUsed) / 100);
  };

  const handleReturn = () =>
    navigate(`/config/${id}`, {
      state: { isQuoteCreated: true, userId: historyState.userId },
    });

  const handleReturnDealerOrder = () => navigate(`/dealerZone/${id}`);

  const handleRL = () => {
    const loggedInUser = JSON.parse(
      sessionStorage.getItem("user") || JSON.stringify(""),
    );
    navigate(`/dealerZone/${loggedInUser}`);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const dealerCostWithAdminCharge =
    !isFetchingUser && !isFetching && data?.quote.adminCharge && !dealerTax
      ? formatIntWithCommas(
          round(
            parseFloat(data.quote.grandTotal.replace(/\,/g, "")) +
              data.quote.adminCharge,
          ),
        )
      : null;

  if (error) return <div>An Error Occured. Try Refreshing the Page.</div>;

  return (
    <>
      {!isFetching && !isLoading && !isFetchingUser ? (
        <StyledDivBg>
          <StyledDivContentWrap>
            {(userData.signInRole === "Inside Sales" ||
              userData.signInRole === "Admin") && (
              <AdminCostModal
                open={openModal}
                handleClose={handleClose}
                charge={data.quote.adminCharge || ""}
                chargeLabel={data.quote.adminChargeLabel || ""}
                withMargin={
                  typeof data.quote.adminChargeMargined === "boolean"
                    ? data.quote.adminChargeMargined
                    : ""
                }
                quoteId={data.quote._id}
                userId={historyState.userId}
              />
            )}

            <div style={{ position: "relative" }}>
              <StyledAppbar
                color="customGrey"
                position={matches ? "static" : "fixed"}
              >
                <StyledToolbar>
                  <StyledNavbarLeftDiv>
                    <StyledNavBtn
                      variant="contained"
                      color="cyan"
                      onClick={
                        disableChanges &&
                        (userData.signInRole === "Dealer" ||
                          userData.signInRole === "Manager" ||
                          userData.signInRole === "Company Manager" ||
                          userData.signInRole === "Outside Sales")
                          ? handleReturnDealerOrder
                          : handleReturn
                      }
                      size="small"
                    >
                      <NavigateBeforeIcon style={{ display: "inline" }} />{" "}
                      {matches &&
                      disableChanges &&
                      (userData.signInRole === "Dealer" ||
                        userData.signInRole === "Manager" ||
                        userData.signInRole === "Company Manager" ||
                        userData.signInRole === "Outside Sales")
                        ? `Return to ${
                            data.quote?.status === "Archived"
                              ? "Quotes"
                              : "Orders"
                          }`
                        : matches
                          ? "Return to Cart"
                          : ""}
                    </StyledNavBtn>
                    <StyledDivCenter>
                      {changeLogo ? (
                        <StyledImgLarg
                          src="https://res.cloudinary.com/ag-millworks/image/upload/AG_Logos/AGlogoSmall.png"
                          alt="Company Logo"
                        />
                      ) : (
                        <StyledLogoSmall
                          src="https://res.cloudinary.com/ag-millworks/image/upload/v1640825559/doorlogo.png"
                          alt=""
                        />
                      )}
                    </StyledDivCenter>
                  </StyledNavbarLeftDiv>

                  <StyledNavbarRightDiv>
                    {userData.otsm &&
                      (userData.signInRole === "Dealer" ||
                        userData.signInRole === "Manager" ||
                        userData.signInRole === "Company Manager") && (
                        <OtsmIcon />
                      )}

                    <QuoteNumber quoteNumber={data.quote.quoteNumber} />

                    <Button
                      onClick={handleRL}
                      variant="contained"
                      size="small"
                      color="cyan"
                      style={{
                        color: "white",
                        fontSize: !matches ? 8 : "",
                      }}
                    >
                      Resume Later
                    </Button>

                    <AccountDropdown
                      color="white"
                      resumeLaterBtn={true}
                      returnedPath="orderSummary"
                      historyState={historyState}
                    />
                  </StyledNavbarRightDiv>
                </StyledToolbar>
              </StyledAppbar>
            </div>

            <StyledTitleDiv>
              <StyledTitleAndUnitsDIv>
                <StyledTitleText variant="h3">
                  {data.quote?.status === "Order" ? "ORDER" : "QUOTE"} SUMMARY
                </StyledTitleText>
                <Typography variant="body1">
                  {data.quote.totalUnits}{" "}
                  {data.quote.totalUnits === 1
                    ? "Item in Quote"
                    : "Items In Quote"}
                </Typography>
              </StyledTitleAndUnitsDIv>
            </StyledTitleDiv>

            <StyledOSContainer>
              <DoorList
                otsm={userData.otsm}
                signInRole={userData.signInRole}
                quote={data.quote}
              />

              {!isFetchingUser && !isFetching && (
                <StyledPricingDiv>
                  {!data.quote.checkedJobsite ? (
                    <StyledPoContainerDiv>
                      <label style={{ fontWeight: "bold" }}>
                        PO #: <StyledSpanPO> *</StyledSpanPO>
                      </label>
                      <input
                        maxLength={20}
                        style={{ width: 150, marginLeft: 10 }}
                        value={po}
                        onChange={(e) => handlePoChange(e)}
                        name="po"
                        type="text"
                        placeholder="PO #"
                        disabled={disableChanges}
                      />
                    </StyledPoContainerDiv>
                  ) : (
                    <PoJobsite
                      po={po}
                      jobsite={jobsite}
                      handlePoChangeModal={handlePoChange}
                      disableChanges={disableChanges}
                      handleJobsiteChange={handleJobsiteChange}
                      handleModalDetails={handleModalDetails}
                      loadJobsite={loadJobsite}
                      openJobsite={openJobsite}
                      openPO={openPO}
                      handleCloseJobsite={handleCloseJobsite}
                      handleOpenJobsite={handleOpenJobsite}
                      handleClosePO={handleClosePO}
                      handleOpenPO={handleOpenPO}
                      err={modalDetailsErr}
                      taxResults={taxResults}
                      handleTaxChange={handleTaxChange}
                      chosenTax={chosenTax}
                    />
                  )}

                  <StyledDivColCenter>
                    <StyledDivRowCenter>
                      <div />

                      <StyledTypographyTitle variant="body1">
                        {disableChanges ? "ORDER" : "QUOTE"} SUMMARY
                      </StyledTypographyTitle>

                      {userData.signInRole !== "Outside Sales" &&
                        userData.signInRole !== "Inside Sales" && (
                          <Settings
                            setChangesMade={setChangesMade}
                            setViewPdf={setViewPdf}
                            markupChecked={withMarkup}
                            fixedMargin={userData.fixedMargin || false}
                            fixedMarkup={userData.fixedMarkup || false}
                            setMarginInput={setMarginInput}
                            marginInput={marginInput}
                            otsm={userData.otsm}
                            setOverride={
                              !withMarkup ? setOverride : setOverrideMarkup
                            }
                            override={
                              !withMarkup
                                ? overrideFixedMargin
                                : overrideFixedMarkup
                            }
                            setHideCustomer={setHideCustomerPricing}
                            hideCustomer={hideCustomerPricing}
                            quoteId={data.quote._id}
                            series={series}
                            setSeries={setSeries}
                          />
                        )}
                    </StyledDivRowCenter>

                    {(!userData.otsm ||
                      (userData.signInRole !== "Dealer" &&
                        userData.signInRole !== "Manager" &&
                        userData.signInRole !== "Company Manager")) && (
                      <>
                        <StyledDivRowBtwn>
                          <Typography variant="body2">Factor:</Typography>
                          <Typography variant="body2">
                            {data.quote.factor}
                          </Typography>
                        </StyledDivRowBtwn>

                        <StyledDivRowBtwn>
                          <Typography variant="body2">Total:</Typography>
                          <Typography variant="body2">
                            ${data.quote.suggestedRetailPrice}
                          </Typography>
                        </StyledDivRowBtwn>

                        {data.quote.customCladPrice &&
                        data.quote.customCladPrice !== 0 ? (
                          <StyledDivRowBtwn>
                            <Typography variant="body2">
                              Custom Clad Color Charge:
                            </Typography>
                            <Typography variant="body2">
                              ${formatIntWithCommas(data.quote.customCladPrice)}
                              .00
                            </Typography>
                          </StyledDivRowBtwn>
                        ) : null}

                        {data.quote.adminCharge !== 0 &&
                          data.quote.adminChargeLabel && (
                            <StyledDivRowBtwn>
                              <Typography variant="body2">
                                {data.quote.adminChargeLabel}:
                              </Typography>
                              <Typography variant="body2">
                                {data.quote.adminCharge
                                  .toString()
                                  .includes("-") && "-"}{" "}
                                $
                                {formatIntWithCommas(
                                  priceWithZeros(
                                    Math.abs(data.quote.adminCharge),
                                  ),
                                )}
                              </Typography>
                            </StyledDivRowBtwn>
                          )}

                        {userData && dealerTax && (
                          <StyledDivRowSpace
                            style={{ position: "relative", paddingBottom: 20 }}
                          >
                            <Typography variant="body2">Sales Tax:</Typography>
                            <Typography variant="body2">
                              {userData.dealerTax}%{" "}
                            </Typography>
                            <div
                              style={{
                                position: "absolute",
                                right: 10,
                                top: 10,
                              }}
                            >
                              <Typography
                                style={{ fontSize: 10 }}
                                variant="caption"
                              >
                                (
                                {calcDealerTaxDollar(
                                  userData.dealerTax,
                                  data.quote.suggestedRetailPrice,
                                  data.quote.customCladPrice,
                                  data.quote.adminCharge || 0,
                                )}
                                )
                              </Typography>
                            </div>
                          </StyledDivRowSpace>
                        )}

                        <StyledDivRowSpace>
                          <Typography variant="body2">
                            Delivery Charge:
                          </Typography>
                          <Typography variant="body2">
                            $
                            {data.quote.deliveryCharge
                              ? `${data.quote.deliveryCharge}.00`
                              : 0}
                          </Typography>
                        </StyledDivRowSpace>

                        <StyledDivRowSpacePad>
                          <StyledDivColStart
                            style={{
                              justifyContent:
                                userData.signInRole === "Inside Sales" ||
                                userData.signInRole === "Admin"
                                  ? "flex-end"
                                  : "flex-start",
                            }}
                          >
                            <Typography
                              style={{ fontWeight: "bold" }}
                              variant="body2"
                            >
                              Dealer Net Cost:
                            </Typography>

                            {(userData.signInRole === "Inside Sales" ||
                              userData.signInRole === "Admin") && (
                              <button
                                onClick={handleOpen}
                                style={{
                                  backgroundColor: "white",
                                  color: "black",
                                  borderRadius: 4,
                                }}
                              >
                                Add a Charge
                              </button>
                            )}
                          </StyledDivColStart>

                          <StyledDivColEnd>
                            <Typography
                              style={{ fontWeight: "bold" }}
                              variant="body2"
                            >
                              $
                              {!data.quote.adminCharge || dealerTax
                                ? data.quote.grandTotal
                                : priceWithZeros(
                                    dealerCostWithAdminCharge || "",
                                  )}
                            </Typography>
                            {data?.quote?.status === "Quote" ||
                            data?.quote?.status === "Archived" ? (
                              <Typography
                                style={{ fontWeight: "bold" }}
                                variant="caption"
                              >
                                Price is valid for 14 days
                              </Typography>
                            ) : null}
                          </StyledDivColEnd>
                        </StyledDivRowSpacePad>
                      </>
                    )}

                    {(userData.signInRole === "Dealer" ||
                      userData.signInRole === "Manager" ||
                      userData.signInRole === "Company Manager" ||
                      userData.signInRole === "Admin") && (
                      <>
                        {(!userData.otsm ||
                          (userData.signInRole !== "Dealer" &&
                            userData.signInRole !== "Manager" &&
                            userData.signInRole !== "Company Manager")) && (
                          <MarginInput
                            setChangesMade={setChangesMade}
                            marginInput={marginInput}
                            setMarginInput={setMarginInput}
                            errMessageMargin={errMessageMargin}
                            setErrMessage={setErrMessage}
                            setViewPdf={setViewPdf}
                            disableMargin={
                              !withMarkup ? disableMargin : disableMarkup
                            }
                            withMarkup={withMarkup}
                            setWithMarkup={setWithMarkup}
                            fixedMargin={userData.fixedMargin}
                            fixedMarkup={userData.fixedMarkup}
                            quoteStatus={data.quote.status}
                          />
                        )}

                        <StyledDivAddCosts>
                          <AdditionalCosts
                            disableChanges={disableChanges}
                            costsArray={addCosts}
                            quoteId={data.quote._id}
                            margin={marginInput}
                            withMarkup={
                              userData.otsm &&
                              userData.otsmDefault === "Fixed Markup" &&
                              userData.fixedMarkup
                                ? true
                                : userData.otsm &&
                                    userData.otsmDefault === "Fixed Margin" &&
                                    userData.fixedMargin
                                  ? false
                                  : withMarkup
                            }
                            hasDealerTax={dealerTax ? true : false}
                          />

                          <StyledCalcButton
                            onClick={() =>
                              calcGrandTotal(
                                marginInput,
                                userData.otsm &&
                                  userData.otsmDefault === "Fixed Markup" &&
                                  userData.fixedMarkup
                                  ? true
                                  : userData.otsm &&
                                      userData.otsmDefault === "Fixed Margin" &&
                                      userData.fixedMargin
                                    ? false
                                    : withMarkup,
                                addCosts,
                                true,
                                dealerTax
                                  ? 0
                                  : data.quote.salesTax
                                    ? data.quote.salesTax
                                    : 0,
                                data.quote.deliveryCharge || 0,
                                "",
                                adminChargeMargined
                                  ? data.quote.adminCharge
                                  : "",
                                userData.deliveryMarkup,
                              )
                            }
                            disabled={
                              errMessageMargin || disableChanges ? true : false
                            }
                            color="primary"
                            variant="outlined"
                            size="small"
                            style={{ opacity: errMessageMargin ? 0.5 : 1 }}
                            id={
                              priceWithMargin && withMarkup
                                ? "calcBtn"
                                : !priceWithMargin && !withMarkup
                                  ? "calcBtn"
                                  : changesMade
                                    ? "calcBtn"
                                    : ""
                            }
                          >
                            Calculate
                          </StyledCalcButton>
                        </StyledDivAddCosts>

                        {!dealerTax && (
                          <StyledDivRowSpaceBtw>
                            {data.quote.salesTax !== 0 &&
                            data.quote.salesTax ? (
                              <>
                                <Typography variant="body2">
                                  Sales Tax:
                                </Typography>
                                <div style={{ position: "relative" }}>
                                  <div
                                    style={{ position: "absolute", left: -30 }}
                                  >
                                    <CustomSalesTax quoteId={data.quote._id} />
                                  </div>
                                  <Typography variant="body2">
                                    {data.quote.salesTax !== 0 &&
                                    data.quote.salesTax
                                      ? `${(data.quote.salesTax * 100).toFixed(
                                          3,
                                        )}%`
                                      : ""}
                                  </Typography>
                                  {!userData.otsm && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        bottom: -15,
                                        right: 0,
                                      }}
                                    >
                                      <Typography
                                        variant="caption"
                                        style={{ fontSize: 10 }}
                                      >
                                        (
                                        {calcSalesTaxDollar(
                                          data.quote.salesTax,
                                          data.quote.suggestedRetailPrice,
                                          addCosts,
                                          marginInput,
                                          withMarkup,
                                          adminChargeMargined
                                            ? data.quote.adminCharge
                                            : 0,
                                          data.quote.customCladPrice || 0,
                                        )}
                                        )
                                      </Typography>
                                    </div>
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  style={{ position: "absolute", right: 50 }}
                                >
                                  <CustomSalesTax quoteId={data.quote._id} />
                                </div>

                                <SalesTaxTooltip />
                              </>
                            )}
                          </StyledDivRowSpaceBtw>
                        )}

                        <StyledGrandDiv
                          style={{
                            paddingTop:
                              data.quote.salesTax !== 0 && data.quote.salesTax
                                ? 10
                                : 0,
                          }}
                        >
                          <Typography
                            style={{ fontWeight: "bold" }}
                            variant="h6"
                          >
                            {userData.otsm &&
                              (userData.signInRole === "Dealer" ||
                                userData.signInRole === "Manager" ||
                                userData.signInRole === "Company Manager") &&
                              "Customer"}{" "}
                            Grand Total:
                          </Typography>
                          <Typography
                            style={{ fontWeight: "bold" }}
                            variant="body1"
                          >
                            ${grandTotal}
                          </Typography>
                        </StyledGrandDiv>
                      </>
                    )}

                    <StyledButtonsLayout>
                      {(priceWithMargin && withMarkup) ||
                      (!priceWithMargin && !withMarkup) ? (
                        <Typography
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          Press Calcuate to Update Grand Total
                        </Typography>
                      ) : viewPdf && !pdfErr ? (
                        <>
                          <GenerateDealer
                            setPdfErr={setPdfErr}
                            data={data.quote}
                            viewAllState={
                              userData.signInRole === "Dealer" ||
                              userData.signInRole === "Manager" ||
                              userData.signInRole === "Company Manager" ||
                              userData.signInRole === "Admin"
                            }
                            isTable={false}
                            isOrder={
                              data.quote.status !== "Quote" &&
                              data.quote.status !== "Archived"
                            }
                            codOrTerms={
                              data.quote.adminCOD
                                ? data.quote.adminCOD
                                : userData.codOrTerms
                                  ? userData.codOrTerms
                                  : ""
                            }
                            cantSign={
                              data.quote.checkedJobsite &&
                              !po &&
                              (!jobsite.address ||
                                !jobsite.city ||
                                !jobsite.zip ||
                                !jobsite.contactName ||
                                !jobsite.contactNumber)
                                ? "jobsite and po"
                                : data.quote.checkedJobsite &&
                                    po &&
                                    (!jobsite.address ||
                                      !jobsite.city ||
                                      !jobsite.zip ||
                                      !jobsite.contactName ||
                                      !jobsite.contactNumber)
                                  ? "jobsite"
                                  : data.quote.checkedJobsite &&
                                      !po &&
                                      jobsite.address &&
                                      jobsite.city &&
                                      jobsite.zip &&
                                      jobsite.contactName &&
                                      jobsite.contactNumber
                                    ? "po"
                                    : !data.quote.checkedJobsite && !po
                                      ? "po"
                                      : ""
                            }
                            poNumber={po || ""}
                            grandTotal={data.quote.grandTotal}
                            suggestedRetail={
                              data.quote.suggestedRetailPrice || ""
                            }
                            factor={data.quote.factor}
                            region={historyState.region || userData.region}
                            dealerTax={dealerTax || undefined}
                            series={series}
                          />

                          {(allDealerActAccess(userData.signInRole) ||
                            userData.signInRole === "Admin") && (
                            <GenerateCustomer
                              hidePricing={hideCustomerPricing}
                              setPdfErr={setPdfErr}
                              data={data.quote}
                              salesTax={
                                !dealerTax && data.quote.salesTax
                                  ? data.quote.salesTax
                                  : 0
                              }
                              isOrder={
                                data.quote.status !== "Quote" &&
                                data.quote.status !== "Archived"
                              }
                              poNumber={po || ""}
                              margin={margin}
                              withMarkup={withMarkup}
                              addCosts={addCosts}
                              grandTotal={grandTotal}
                              suggestedRetail={
                                data.quote.suggestedRetailPrice || ""
                              }
                              dealerLogo={userData.dealerLogo || ""}
                              deliveryMarkup={userData.deliveryMarkup}
                              dealerTax={dealerTax || null}
                            />
                          )}
                        </>
                      ) : !viewPdf && !changesMade && !pdfErr ? (
                        <Button
                          onClick={handleGenerateClick}
                          variant="contained"
                          style={{
                            backgroundColor: "#21c7f5",
                            color: "black",
                            marginBottom:
                              allDealerActAccess(userData.signInRole) ||
                              userData.signInRole === "Admin"
                                ? 10
                                : 0,
                            marginTop:
                              !allDealerActAccess(userData.signInRole) &&
                              userData.signInRole !== "Admin"
                                ? 10
                                : 0,
                          }}
                        >
                          Generate Full Details
                        </Button>
                      ) : !pdfErr ? (
                        <Typography
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          Press Calcuate to Update Grand Total
                        </Typography>
                      ) : (
                        <Alert severity="error">
                          A Problem Occurred Generating the PDFs. Please Contact
                          Technical Assistance at (805) 644-4494
                        </Alert>
                      )}
                    </StyledButtonsLayout>
                    <StyledDivCustomPaint>
                      {data.quote.units.some(custom) && (
                        <Typography style={{ color: "red" }} variant="body2">
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            Custom Paint/Stain Selected:
                          </span>{" "}
                          Price May Change Once Sample is Recieved
                        </Typography>
                      )}
                    </StyledDivCustomPaint>
                  </StyledDivColCenter>
                </StyledPricingDiv>
              )}
            </StyledOSContainer>

            <Copyright position="right" />
          </StyledDivContentWrap>
        </StyledDivBg>
      ) : (
        <StyledBackdrop open={isFetching || isLoading || isFetchingUser}>
          <StyledLoadDiv>
            <img
              src="https://res.cloudinary.com/ag-millworks/image/upload/Calculating_doorlogo.png"
              alt="door logo"
            />
            <Typography
              variant="h5"
              style={{
                color: "white",
                paddingTop: 10,
              }}
            >
              Loading Quote Summary...
            </Typography>
          </StyledLoadDiv>
        </StyledBackdrop>
      )}
    </>
  );
};

export default OrderSummary;
