import React, { FC } from "react";
import { Typography, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import FormNotes from "../FormNotes";
import { styled } from "@mui/material/styles";
import { blueGrey } from "@mui/material/colors";

const StyledDivCol = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
});

const StyledTypography = styled(Typography)({
  flexBasis: "30%",
});

const StyledSpanErr = styled("span")({
  color: "red",
  fontSize: 22,
});

const StyledInputDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: 20,
});

const StyledInput = styled("input")(({ theme }) => ({
  backgroundColor: blueGrey[50],
  border: "1px solid grey",
  borderRadius: "4px",
  height: "30px",
  width: 300,
  [theme.breakpoints.down("sm")]: {
    width: 150,
    marginLeft: 5,
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: blueGrey[50],
  border: "1px solid grey",
  borderRadius: "4px",
  height: "30px",
  width: 300,
  [theme.breakpoints.down("sm")]: {
    width: 150,
    marginLeft: 5,
  },
}));

interface ProjectNameProps {
  handleAllChange: (
    event:
      | SelectChangeEvent<unknown>
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  role: string;
  jobName: string;
  formNotes: string;
  agSalesperson: string;
}

const ProjectName: FC<ProjectNameProps> = ({
  handleAllChange,
  role,
  jobName,
  formNotes,
  agSalesperson,
}) => {
  const agSales = [
    "George Robles",
    "Adam Martinez",
    "Arnold Montes",
    "OC",
    "Laura Downey",
  ];

  return (
    <StyledDivCol>
      <StyledInputDiv>
        <StyledTypography variant="body2">
          Project Name:
          <StyledSpanErr>* </StyledSpanErr>
        </StyledTypography>
        <StyledInput
          maxLength={20}
          onChange={handleAllChange}
          name="jobName"
          value={jobName}
          placeholder="Project Name"
        />
      </StyledInputDiv>

      <StyledInputDiv style={{ paddingBottom: 0 }}>
        <StyledTypography variant="body2">
          AG Sales Rep:
          <StyledSpanErr>* </StyledSpanErr>
        </StyledTypography>
        {role !== "Dealer" &&
        role !== "Manager" &&
        role !== "Company Manager" &&
        role !== "Outside Sales" ? (
          <StyledSelect
            onChange={handleAllChange}
            name="agSalesperson"
            value={agSalesperson}
            placeholder="Ag Sales Rep"
          >
            {agSales.map((salesperson) => (
              <MenuItem value={salesperson} key={salesperson}>
                {salesperson}
              </MenuItem>
            ))}
          </StyledSelect>
        ) : (
          <StyledInput
            disabled={true}
            onChange={handleAllChange}
            name="agSalesperson"
            value={agSalesperson}
            placeholder="Ag Sales Rep"
          />
        )}
      </StyledInputDiv>

      <FormNotes formNotes={formNotes} handleChange={handleAllChange} />
    </StyledDivCol>
  );
};

export default ProjectName;
