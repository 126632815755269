import React, { useMemo, FC } from "react";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import usePersistedState from "../hooks/usePersistedState";
import { styled } from "@mui/material/styles";

const StyledIconButton = styled(IconButton)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

const StyledDiv = styled("div")({
  position: "absolute",
  right: 0,
  top: 5,
});

interface CollapseProps {
  children: React.ReactNode;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}

const CollapsePriceInfo: FC<CollapseProps> = ({
  children,
  handleChange,
  checked,
}) => {
  const [openPI, setOpenPI] = usePersistedState("openPI", false);

  const handleOpen = () => setOpenPI(true);
  const handleClose = () => setOpenPI(false);

  const unitPricing = useMemo(() => {
    return (
      <>
        {!openPI ? (
          <StyledIconButton onClick={handleOpen} size="large">
            <ExpandLessIcon color="primary" />
            <Typography style={{ flex: 2 }} variant="overline">
              Pricing Details
            </Typography>
          </StyledIconButton>
        ) : (
          <div style={{ position: "relative" }}>
            <IconButton onClick={handleClose} size="large">
              <ExpandMoreIcon color="primary" />
            </IconButton>
            <StyledDiv>
              <Typography variant="overline">
                {checked ? "Hide" : "Show"} Unit Pricing
              </Typography>
              <Switch
                checked={checked}
                onChange={handleChange}
                color="primary"
              />
            </StyledDiv>
          </div>
        )}
      </>
    );
  }, [checked, openPI]);

  return (
    <div style={{ border: "1px solid #57d5f2" }}>
      {unitPricing}
      <Collapse in={openPI}>{children}</Collapse>
    </div>
  );
};

export default CollapsePriceInfo;
